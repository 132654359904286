import { Box, Grid } from "@mui/material";
import SwitchWithLabel from "../../component-library/SwitchWithLabel";
import { DataModes } from "../../models/DataModes";
import HeaderDetailsUserInfo from "./HeaderDetailsUserInfo";
import { PersonStatus } from "../../models/admin/PersonStatusConst";
import { UserService } from "../../services/user/UserService";
import { UpdateUserStatusResponse } from "../../models/admin/UpdateUserStatusResponse";
import { useCallback, useMemo } from "react";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { useTranslation } from "react-i18next";
import getUserFullName from "../../utils/getUserFullName";
import { setAdminResults, setSelectedUser } from "../../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { setToast } from "../../redux/reducers/toastSlice";
import { ToastTypes } from "../../models/toast/ToastTypes";

export default function HeaderDetails() {
    const dispatch = useAppDispatch();
    const { adminResults, selectedUser, dataMode } = useAppSelector((state) => state.user);
    const { t } = useTranslation();
    const userProfile = AuthLibrary.getUserProfile();
    const userService = useMemo(() => new UserService(), []);

    const translations = {
        newUser: t("New User"),
        error: t("Error getting user details."),
        statusNotUpdated: t("Status not updated."),
        editSuccessful: t("User has been edited successfully."),
    };

    const switchHandler = async (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
        const updateValue = event.target.checked ? PersonStatus.active.id : PersonStatus.inactive.id;

        userService
            .updateUserStatus(selectedUser?.personId ? +selectedUser?.personId : 0, updateValue)
            .then((response: UpdateUserStatusResponse) => {
                const newSelectedUser = {
                    ...selectedUser,
                    personStatusId: response.personStatusId,
                    personStatus:
                        response.personStatusId === PersonStatus.active.id
                            ? PersonStatus.active.name
                            : PersonStatus.inactive.name,
                };
                dispatch(setSelectedUser(newSelectedUser));

                //Force an update of the grid in Admin.tsx.
                let admins = adminResults.map((a) => ({ ...a })); //make a copy of our stated array.
                const idx = admins.findIndex((a) => a.personId === response.personId);
                if (idx !== -1) {
                    admins[idx]!.personStatusId = response.personStatusId;
                    admins[idx]!.personStatus =
                        response.personStatusId === PersonStatus.active.id
                            ? PersonStatus.active.name
                            : PersonStatus.inactive.name;
                    dispatch(setAdminResults(admins));
                }
                dispatch(
                    setToast({
                        toastMessage: translations.editSuccessful,
                        toastType: ToastTypes.Success,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    setToast({
                        toastMessage: translations.statusNotUpdated,
                        toastType: ToastTypes.Error,
                    })
                );
            });
    };

    /**
     * Allows the ability to enable or disable accounts, only allow "STERIS Administrator" to enable accounts.
     */
    const disableSwitch = useCallback((): boolean => {
        if (dataMode === DataModes.Edit && userProfile.roles?.includes("STERIS Administrator")) {
            return false;
        }
        return true; // DataModes.Create and DataModes.View will return true
    }, [dataMode, userProfile.roles]);

    /**
     * Gets the full name of the selected user or "new user"
     */
    const getName = () => {
        //selected user is being defaulted to an empty object in state. wth.
        return selectedUser?.firstName && selectedUser?.lastName
            ? getUserFullName(selectedUser.firstName, selectedUser.lastName)
            : translations.newUser;
    };

    return (
        <>
            <Grid
                data-testid="header-details-grid"
                item
                xs={8}
                lg={6}>
                <Box
                    display="flex"
                    alignItems="center">
                    <HeaderDetailsUserInfo
                        name={getName()}
                        personType={selectedUser?.personType ?? ""}
                    />
                </Box>
            </Grid>
            <Grid
                item
                xs={4}
                lg={6}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    pr={4}>
                    <SwitchWithLabel
                        disabled={disableSwitch()}
                        checked={
                            dataMode === DataModes.Create
                                ? false
                                : selectedUser.personStatusId === PersonStatus.active.id
                        }
                        handleOnChange={switchHandler}
                        labelColor={selectedUser?.personStatusId === PersonStatus.active.id ? "green" : "red"}
                        label={selectedUser?.personStatus ?? PersonStatus.inactive.name}
                    />
                </Box>
            </Grid>
        </>
    );
}
