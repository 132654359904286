import { IFacilityService } from "./IFacilityService";
import { ccLocalstorage } from "../../config/data";
import { Facility } from "../../models/facility/Facility";
import { requestFacility, requestConnectCareCustomers } from "../apiPaths";
import { DepartmentResponse } from "../../models/admin/AccountDepartment";
import { AccountSearchResponseDto } from "../../models/admin/AccountSearchResponseDto";

export class FacilityService implements IFacilityService {
    /**
     * Updates selected facilities in the database for selectedFacilties.accountId.
     * @param {Facility[]} selectedFacilities The list of facilties to update.
     * @returns The saved response from the database.
     */
    async updateSelectedFacilities(selectedFacilities: Facility[]): Promise<Facility[]> {

        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uriFacilities = `${requestFacility.UpdateFacilities}`;
        let requestBody = {
            accountIds: selectedFacilities.map(x => {
                return x.accountId
            })
        };

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `bearer ${token} ` },
            body: JSON.stringify(requestBody)
        };

        return await fetch(uriFacilities, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error();
                }
                return response.json();
            })
            .catch(() => {
                throw new Error();
            });
    }

    /**
     * Search facilties.
     * @param {string} searchString - The search string. Can be customer number or fuzzy search of customer name.
     * @param {number} personId - The person id.
     * @param {boolean} includeSelected - Expand the search to include already selected accounts.
     * @returns {AccountSearchResponseDto}
     */
    async searchFacilities(searchString: string, personId: number, personAccountId: number, includeSelected: boolean = false): Promise<AccountSearchResponseDto[]> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareCustomers.SearchFacilities}/${personId}/${personAccountId}?searchString=${searchString}&includeSelected=${includeSelected}`;      
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `bearer ${token} ` },
        };

        return await fetch(uri, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error();
                }
                return response.json();
            })
            .catch(() => {
                throw new Error();
            });
    }

    async getDepartments(accountId: number): Promise<DepartmentResponse[]> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareCustomers.GetDepartments}/${accountId}`;

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `bearer ${token} ` },
        };

        return await fetch(uri, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error();
                }
                return response.json();
            })
            .catch(() => {
                throw new Error();
            });
    }
}
