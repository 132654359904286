import { ccLocalstorage } from "../../config/data";
import { User } from "../../models/admin/User";
import { requestConnectCareUsers, requestConnectCareMenuItems } from "../apiPaths";
import { IUserService } from "./IUserService";
import { UpdateUserStatusResponse } from "../../models/admin/UpdateUserStatusResponse";
import { CreateEditUser } from "../../models/admin/CreateEditUser";
import { PersonType } from "../../models/admin/PersonType";
import { PersonAccountResponse } from "../../models/admin/PersonAccountResponse";
import { AppMenuItemResponse } from "../../models/navigation/AppMenuItemResponse";
import { handleErrorResponse } from "../../utils/fetchErrorHandler";
import { CreateUserResponse } from "../../models/admin/CreateUserResponse";
import { AccountSubscriptionClaims } from "../../models/authorization/AccountSubscriptionClaims";

export class UserService implements IUserService {
    async searchUsers(search: string): Promise<User[]> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.UserSearch}?searchString=${search}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token}`,
            },
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    async updateUserStatus(personId: number, statusId: number): Promise<UpdateUserStatusResponse> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.UserStatusUpdate}/${personId}`;
        const requestBody = {
            personStatus: statusId,
        };

        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    async getUserDetail(personId: number): Promise<User> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.UserDetail}/${personId}`;

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    async createUser(user: CreateEditUser): Promise<CreateUserResponse> {
        let uri = `${requestConnectCareUsers.CreateUser}`;
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
            body: JSON.stringify(user),
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    async getPersonTypes(): Promise<PersonType[]> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.PersonTypes}`;

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    async addCustomerAccount(
        personId: number,
        accountId: number,
        departmentIds: number[]
    ): Promise<{ personAccountIds: number[] }> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.AddCustomerAccount}/${personId}`;
        const requestBody = {
            accountId: accountId,
            accountDepartmentIds: departmentIds,
        };

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
            body: JSON.stringify(requestBody),
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    async getLeftNavigation(selectedFacilityIds: number[]): Promise<AppMenuItemResponse> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareMenuItems.LeftNavigation}`;
        let returnValue: AppMenuItemResponse = { menuItems: [], statusCode: 0, hasIndirectAccounts: false };

        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: selectedFacilityIds.toString(),
            },
        };

        const response = await fetch(uri, requestOptions);
        //review: await handleErrorResponse(response); //we have to better look at this before handling the error here.
        returnValue.statusCode = response.status; //we're returning status and handling 401 here to help with #17895.
        if (response.ok) {
            const data = await response.json();
            returnValue.menuItems = data?.menuItems; //if menuItems is blank here you'll get a WSOD.
            returnValue.hasIndirectAccounts = data?.hasIndirectAccounts;
            return returnValue;
        }
        return {} as AppMenuItemResponse;
    }

    async getAccountSubscriptionClaims(selectedFacilityIds: number[]): Promise<AccountSubscriptionClaims[]> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.GetSelectedAccountClaims}`;
        let returnValue: AccountSubscriptionClaims[] = [];

        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: selectedFacilityIds.toString(),
            },
        };

        const response = await fetch(uri, requestOptions);
        if (response.ok) {
            const data = await response.json();
            returnValue = data;
        }
        return returnValue;
    }

    async editUser(user: CreateEditUser): Promise<boolean> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.UserDetail}/${user.personId}`;
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
            body: JSON.stringify(user),
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            //.then(ok => { return ok.json(); });
            .then(() => {
                return true;
            }); //The caller is expecting a hard coded boolean rather than the UpdateUserDetailDto response the api is returning.
    }

    async getCustomerAccounts(personId: number): Promise<PersonAccountResponse[]> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.GetCustomerAccounts}/${personId}`;

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    async removeCustomerAccount(personId: number, personAccountId: number): Promise<boolean> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.RemoveCustomerAccount}/${personId}/customer-account/${personAccountId}`;
        const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            //.then(ok => { return ok.json(); });
            .then(() => {
                return true;
            }); //The caller is expecting a hard coded boolean rather than the UpdateUserDetailDto response the api is returning.
    }

    async getRolesByPersonType(personTypeId: number): Promise<string[]> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);

        const uri = `${requestConnectCareUsers.PersonTypeRoles}?personTypeId=${personTypeId}`;

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `bearer ${token} ` },
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }

    async createCustomerSupportRequest(note: string, selectedFacilityIds: number[]): Promise<number> {
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareUsers.CreateSupportRequest}?note=${note}`;

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: selectedFacilityIds.toString(),
            },
        };

        return await fetch(uri, requestOptions)
            .then((response) => {
                return handleErrorResponse(response);
            })
            .then((ok) => {
                return ok.json();
            });
    }
}
