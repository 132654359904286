import { useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom'
import { PersonTypeByInt } from "../config/PersonTypes";
import ReactGA from "react-ga4";
import { useAppSelector } from "../hooks/useReduxHooks";

/**
 * Provides user telemetry to external providers.
 * @see {@link https://dev.azure.com/imsrepair/ConnectCare/_wiki/wikis/ConnectCare.wiki/256/Telemetrics} 
 */
export const TelemetricsProvider = () => {
    const { currentUser, isAuth, telemetry } = useAppSelector((state) => state.auth);
    const [isPendo, setIsPendo] = useState<boolean>(false);
    const [isGoogle, setIsGoogle] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        if (
            process.env.REACT_APP_USE_GA4 === "true" && //if google analytics is turned on
            isAuth && //If your are logged in
            currentUser?.userId && //and your user id is set
            process.env.REACT_APP_GA4_TRACKING_ID !== undefined //and you have a google tracking id
        ) {
            ReactGA.set({
                userId: currentUser.userId, //send back current user as user to v4
            });
            ReactGA.send({
                hitType: "pageview",
                page: window.location.pathname, //send path as page name to analytics for tracking
            });
        }
    }, [location, currentUser, isAuth])

    useEffect(() => {
        const domain = currentUser?.email.substring(currentUser?.email.indexOf("@") + 1); //Get the user domain without the @ symbol.        
        if (
            process.env.REACT_APP_USE_PENDO === "true" && //if pendo is turned on
            isAuth && //If your are logged in
            currentUser?.userId //and your user id is set
        ) {
            setIsPendo(true); //Tell the rest of the app pendo is enabled and turned on. Once this is set on it'll be never turned off.           
            // Then, add this once your users are logged in/authenticated:
            // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
            // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
            // Call this function in your authentication promise handler or callback when your visitor and account id values are available
            // Please use Strings, Numbers, or Bools for value types.
            (window as any).pendo.initialize({
                visitor: {
                    id: currentUser?.userId, // Required if user is logged in, default creates anonymous ID
                    email: currentUser?.email, // Recommended if using Pendo Feedback, or NPS Email
                    full_name: currentUser?.displayName, // Recommended if using Pendo Feedback
                    // role:         // Optional
                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                    userid: currentUser?.userId,
                    //Steris id
                    sterisid_userId: telemetry?.sterisIdUserId,
                    sterisid_populationId: telemetry?.sterisIdPopulationId,
                    //user data                   
                    cc_role: telemetry?.roles,
                    cc_personType: PersonTypeByInt.get(telemetry?.personType ?? 0),
                    cc_emailDomain: domain,
                    // Subscription
                    cc_subscriptionName: telemetry?.subscriptionName,
                    cc_subscriptionStartDate: telemetry?.subscriptionStartDate,
                    cc_subscriptionEndDate: telemetry?.subscriptionEndDate,
                    cc_subscriptionStatus: telemetry?.subscriptionStatus === 1 ? "Active" : "Inactive",
                    //GPO
                    cc_primaryGPO: telemetry?.primaryGPO,
                    cc_gpoMemberLevel: telemetry?.gpoMemberLevel,
                    cc_salesChannel: telemetry?.salesChannel,
                    cc_customerClass: telemetry?.customerClassCodeOracle
                },

                account: {
                    id: domain, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                    name: currentUser?.displayName, // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                },
            });
        }
        if (
            process.env.REACT_APP_USE_GA4 === "true" && //if google analytics is turned on
            isAuth && //If your are logged in
            currentUser?.userId && //and your user id is set
            process.env.REACT_APP_GA4_TRACKING_ID !== undefined //and you have a google tracking id
        ) {
            setIsGoogle(true);
            ReactGA.initialize([
                {
                    trackingId: process.env.REACT_APP_GA4_TRACKING_ID, //Google tracking id for the API
                },
            ]);
        }
    }, [setIsPendo,
        currentUser,
        isAuth,
        setIsGoogle,
        telemetry]);
    useMemo(() => ({ isPendo, isGoogle }), [isPendo, isGoogle]);
    return false;
};
