import { Grid } from "@mui/material";
import { InsightMetricsBarChart } from "../../component-library/InsightMetricsBarChart";
import { useTranslation } from "react-i18next";
import { requestConnectCareOrders } from "../../services/apiPaths";

export default function TransitInsights() {
    const { t } = useTranslation();
    const translations = {
        category: t("By Category"),
        department: t("By Department"),
        manufacturer: t("By Manufacturer"),
        chartSubTitle: t("Excluding Case Carts and Instrument Trays"),
        remainingCategories: t("items in transit for repair are not assigned a category"),
        remainingDepartments: t("items in transit for repair are not assigned a department"),
        errorMessage: t("Data is unavailable or there is not enough data to display this metric."),
    };
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            data-testid="in-transit-metrics-insights">
            <InsightMetricsBarChart
                title={translations.category}
                subtitle={translations.chartSubTitle}
                errorMessage={translations.errorMessage}
                remainingMetricsTitle={translations.remainingCategories}
                apiUrl={requestConnectCareOrders.TransitForRepairMetricsByCategory}
                showAll={true}
                includeUnkownItem={false}
            />
            <InsightMetricsBarChart
                title={translations.department}
                errorMessage={translations.errorMessage}
                remainingMetricsTitle={translations.remainingDepartments}
                apiUrl={requestConnectCareOrders.TransitForRepairMetricsByDepartment}
                showAll={true}
                includeUnkownItem={false}
            />
        </Grid>
    );
}
