import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { requestConnectCareReports } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { ReportVisibilityResponseDto } from "../../models/reports/ReportVisibilityResponseDto";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { ReportsCard } from "../../components/reports/ReportsCard";
import { reportsCardData, titles } from "../../models/reports/ReportCardData";

/**
 * Reporting is made up of two sections. Reports and Analytics.
 * Each section contains a list of MUI cards and card content. The MUI card content has buttons that will redirect the user to a report.
 */
export const Reports = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [reportVisibility, setReportVisibility] = useState<ReportVisibilityResponseDto>();
    const { get } = useFetch();
    const dispatch = useDispatch();

    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.reports)}`,
        analyticsInsights: t("Analytics & Insights"),
        analyticalDashboard: t("Below are interactive, analytical dashboards available for your selected facilities."),
        openDashboard: t("Open Dashboard"),
        reportsAvailable: t("Below are the reports available for your selected facilities."),
        reports: t("Reports"),
        reportsErrorMessage: t("Failed to get report visibility"),
    };

    const reportCards = {
        repairHistory: reportsCardData.find((a) => a.title === titles.repairHistory),
        equipmentPlannerTray: reportsCardData.find((a) => a.title === titles.equipmentPlannerTray),
        equipmentPlannerDevices: reportsCardData.find((a) => a.title === titles.equipmentPlannerDevices),
    };

    const getReportVisibility = useCallback(async () => {
        setLoading(true);
        const response = await get<ReportVisibilityResponseDto>(requestConnectCareReports.GetReportsVisiblity, true);
        if (response) {
            setReportVisibility(response);
        } else {
            dispatch(
                setToast({
                    toastMessage: translations.reportsErrorMessage,
                    toastType: ToastTypes.Error,
                })
            );
        }
        setLoading(false);
    }, [dispatch, get, translations.reportsErrorMessage]);

    useEffect(() => {
        document.title = translations.documentTitle;
        getReportVisibility();
    }, [getReportVisibility, translations.documentTitle]);

    return (
        <Box data-testid="reports-component">
            <BreadCrumb breadCrumbs={BreadCrumbList.reports} />
            {/* Report Cards */}
            <Box padding={2}>
                <Box>
                    <Typography
                        variant="h1"
                        color="font.darkBlue">
                        {translations.reports}
                    </Typography>
                </Box>
                <hr />
                {loading && <CircularProgress />}
                {!loading && (
                    <Box marginBottom={2}>
                        <Typography variant="body2">{translations.reportsAvailable}</Typography>
                    </Box>
                )}
                <Grid
                    container
                    spacing={2}>
                    {reportVisibility?.canSeeRepairHistory && (
                        <Grid item>
                            <ReportsCard 
                                reportsCardData={reportCards.repairHistory}/>  
                        </Grid>    
                    )}
                    {reportVisibility?.canSeeEquipmentPlannerAEMTray && (
                        <Grid item>
                            <ReportsCard 
                                reportsCardData={reportCards.equipmentPlannerTray}/> 
                        </Grid>
                    )}
                    {reportVisibility?.canSeeEquipmentPlannerAEMDevices && (
                        <Grid item>
                            <ReportsCard 
                                reportsCardData={reportCards.equipmentPlannerDevices}/> 
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );
};