export const PersonTypes = new Map([
    ["SYSTEM", 1],
    ["Employee", 2],
    ["Customer", 3],
    ["Vendor", 4],
])

export const PersonTypeByInt = new Map([
    [0, "Unknown"],
    [1, "SYSTEM"],
    [2, "Employee"],
    [3, "Customer"],
    [4, "Vendor"],
])