import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SectionField, SectionFieldValue } from "../../theme/theme";
import { text } from "../../../src/theme/colors";
import { AccountCircle } from "@mui/icons-material";
import { ChangeEvent, useCallback, useState } from "react";
import { CurrentUser } from "../../models/authorization/CurrentUser";
import { requestConnectCareUsers } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import { UserProfileEditForm } from "./UserProfileEditForm";

const UserProfile = ({
    userProfileDetails,
    getDetails,
}: {
    userProfileDetails: CurrentUser;
    getDetails: () => void;
}) => {
    const { t } = useTranslation();
    const { put } = useFetch();
    const [updatedUserProfile, setUpdatedUserProfile] = useState<CurrentUser>(userProfileDetails);
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const translations = {
        name: t("Name"),
        title: t("Title"),
        email: t("Email"),
        firstName: t("First Name"),
        lastName: t("Last Name"),
        jobTitle: t("Title"),
        profilePhoto: t("Profile Photo"),
        edit: t("edit"),
        cancel: t("Cancel"),
        save: t("Save"),
        success: t("Success! Changes Saved."),
        failure: t("Failed to save the changes."),
        firstNameValidationError: t("First Name cannot be blank"),
        lastNameValidationError: t("Last Name cannot be blank"),
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
        setUpdatedUserProfile({
            ...updatedUserProfile,
            email: userProfileDetails.email,
            firstName: newValue === "firstName" ? event.target.value : updatedUserProfile.firstName?.trim(),
            lastName: newValue === "lastName" ? event.target.value : updatedUserProfile.lastName?.trim(),
            jobTitle: newValue === "jobTitle" ? event.target.value : updatedUserProfile.jobTitle?.trim(),
        });
    };

    const updateUserProfileData = useCallback(async () => {
        if (!updatedUserProfile?.firstName || !updatedUserProfile?.lastName) {
            return;
        }

        const uri = `${requestConnectCareUsers.UpdateUserProfile}/${userProfileDetails.personId}`;
        const response = await put<CurrentUser>(uri, updatedUserProfile, true, () => {
            setUpdatedUserProfile(userProfileDetails);
            dispatch(
                setToast({
                    toastMessage: translations.failure,
                    toastType: ToastTypes.Error,
                })
            );
        });

        // Even when there is no error, but still need to check CurrentUser object existence
        if ((response as CurrentUser)?.personId) {
            getDetails();
            setUpdatedUserProfile(response as CurrentUser);
            dispatch(
                setToast({
                    toastMessage: translations.success,
                    toastType: ToastTypes.Success,
                })
            );
        }
        setEditMode(false);
    }, [dispatch, getDetails, put, translations.failure, translations.success, updatedUserProfile, userProfileDetails]);

    const resetDefaultState = () => {
        setUpdatedUserProfile(userProfileDetails);
        setEditMode(false);
    };

    return (
        <Stack mb={2}>
            {!editMode && (
                <Stack
                    data-testid="user-profile-view-mode-stack"
                    direction={"row"}
                    paddingLeft={8}
                    paddingTop={5}
                    spacing={{ xs: 1, sm: 2, md: 4, ls: 16 }}
                    useFlexGap
                    flexWrap="wrap">
                    <Box>
                        <SectionField sx={{ color: text.textgray }}>{translations.name + "\n"}</SectionField>
                        <SectionFieldValue>{`${updatedUserProfile.firstName} ${updatedUserProfile.lastName}`}</SectionFieldValue>
                    </Box>
                    <Box>
                        <SectionField sx={{ color: text.textgray }}>{translations.title}</SectionField>
                        <SectionFieldValue data-testid="jobTitle">{updatedUserProfile.jobTitle}</SectionFieldValue>
                    </Box>
                    <Box>
                        <SectionField sx={{ color: text.textgray }}>{translations.email}</SectionField>
                        <SectionFieldValue>{userProfileDetails.email}</SectionFieldValue>
                    </Box>
                    <Button
                        data-testid="edit-button"
                        aria-label={translations.edit}
                        size="small"
                        variant="contained"
                        onClick={() => setEditMode(true)}
                        sx={{ width: 130, padding: "4px", borderRadius: 8 }}>
                        {translations.edit}
                    </Button>
                </Stack>
            )}
            {editMode && (
                <UserProfileEditForm
                    handleChange={handleChange}
                    updatedUserProfile={updatedUserProfile}
                    userProfileDetails={userProfileDetails}
                    resetDefaultState={resetDefaultState}
                    updateUserProfileData={updateUserProfileData}
                />
            )}
            <Box
                paddingLeft={8}
                marginTop={2}>
                <SectionField sx={{ color: text.textgray }}>{translations.profilePhoto}</SectionField>
                <AccountCircle sx={{ color: text.textgray2, fontSize: "80px", mr: 2, cursor: "pointer" }} />
            </Box>
        </Stack>
    );
};

export default UserProfile;
