import { Box } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageKeysRecommendedForService } from "../../config/data";
import { useTranslation } from "react-i18next";
import { RecommendedForServiceProps } from "../../models/serviceSummary/RecommendedForService";
import RecommendedForServiceGrid from "./RecommendedForServiceGrid";
import GridHeader from "../../component-library/GridHeader";
import { BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor,theme } from "../../theme/theme";

function RecommendedForServiceList(recommendedForServiceList: RecommendedForServiceProps) {
    const { recommendedForServiceData, isLoading, setPageKey, responseStatusCode, refreshData } = recommendedForServiceList;

    const params = useParams<{ id?: string; srn?: string }>();
    const { t } = useTranslation();
    const translations = {
        recommendedForService: t("Recommended for Service"),
        apiError: t("System Error: API is not available at this time!"),
    };

    useEffect(() => {
        setPageKey(pageKeysRecommendedForService.RecommendedForServiceList);
    }, [params, setPageKey]);

    return (
        <Box
            style={GridBackgroundColor}
            data-testid="recommended-for-service-list">
            <BreadCrumb breadCrumbs={BreadCrumbList.recommendedservice} />
            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.recommendedForService}
                    hasError={[404, 500].includes(responseStatusCode)}
                />
                <Box
                    py={theme.spacing(2)}
                    data-testid="recommended-for-service-grid-box">
                    {/* *** Page Header ** */}
                    <RecommendedForServiceGrid
                        recommendedForService={recommendedForServiceData}
                        loading={isLoading}
                        refreshData={refreshData}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default RecommendedForServiceList;
