import { Alert, Box, Button, Grid, LinearProgress, Link, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../services/useFetch";
import { EstimateDetail } from "../../models/estimate/EstimateDetail";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { SectionField, SectionFieldValue } from "../../theme/theme";
import { text } from "../../theme/colors";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { useNavigate } from "react-router";
import { validators } from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { OrderStatuses } from "../../models/orders/OrderStatuses";
import { StoreState } from "../../redux/store";

type EstimateDetailsTabProps = {
    lineId: string;
    orderNumber: string;
    customeraccountId: string;
    headerId: string | undefined;
    estimateDetailData: EstimateDetail | undefined;
    isLoading: boolean;
    isError: boolean;
    isEditMode: boolean;
    setDetailsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    getEstimateDetail: () => Promise<void>;
};

export default function EstimateDetailsTab({
    lineId,
    orderNumber,
    customeraccountId,
    headerId,
    estimateDetailData,
    isLoading,
    isError,
    getEstimateDetail,
    isEditMode,
    setDetailsEditMode,
}: Readonly<EstimateDetailsTabProps>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { put } = useFetch();
    const [estimateDetail, setEstimateDetail] = useState<EstimateDetail | undefined>(estimateDetailData);
    const [disableForm, setDisableForm] = useState(false);
    const [editMode, setEditMode] = useState(isEditMode);
    const [isPoNumberError, setIsPoNumberError] = useState<boolean>(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const dispatch = useDispatch();
    const { hasIndirectAccounts } = useSelector((state: StoreState) => state.facility);

    useEffect(() => {
        setEditMode(isEditMode);
    }, [isEditMode]);

    useEffect(() => {
        setEstimateDetail(estimateDetailData);
    }, [estimateDetailData]);

    const translations = {
        reference: t("Reference"),
        comments: t("Comments"),
        order: t("Order"),
        poNumber: t("PO Number"),
        edit: t("Edit"),
        apiError: t("System Error: API is not available at this time!"),
        cancel: t("Cancel"),
        save: t("Save"),
        success: t("Estimate details saved successfully."),
        failure: t("Estimate details failed to save, please try again."),
        poErrorMessage: t("PO number cannot exceed 50 characters or contain special characters such as &, #, and +"),
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
        if (newValue === "po" && event.target.value) {
            let validationResult = validators.pONumber(event.target.value);
            setIsPoNumberError(!validationResult);
        }

        if (estimateDetail) {
            setEstimateDetail({
                ...estimateDetail,
                reference: newValue === "reference" ? event.target.value : estimateDetail.reference,
                comments: newValue === "comments" ? event.target.value : estimateDetail.comments,
                purchaseOrder: newValue === "po" ? event.target.value : estimateDetail.purchaseOrder,
            });
        }
    };

    const updateEstimateDetail = useCallback(async () => {
        if (!estimateDetail) {
            return;
        }

        if (isPoNumberError) {
            return;
        }

        setDisableForm(true);
        const uriOrder = `${requestConnectCareOrders.UpdateEstimateStatus}?custAccountId=${customeraccountId}&headerId=${headerId}`;
        let payload: EstimateDetail = {
            ...estimateDetail,
            reference: estimateDetail.reference ?? estimateDetailData?.reference!,
            purchaseOrder: estimateDetail.purchaseOrder ?? estimateDetailData?.purchaseOrder,
            comments: estimateDetail.comments ?? estimateDetailData?.comments!,
            lineId: Number(lineId),
            status: estimateDetail.status ?? estimateDetailData?.status,
        };

        const response: EstimateDetail = (await put<EstimateDetail>(uriOrder, payload)) as EstimateDetail;

        if (response.lineId) {
            setEstimateDetail(response);
            dispatch(
                setToast({
                    showToast: true,
                    toastType: ToastTypes.Success,
                    toastMessage: translations.success,
                })
            );
            getEstimateDetail();
            setEditMode(false);
        } else {
            dispatch(
                setToast({
                    showToast: true,
                    toastType: ToastTypes.Error,
                    toastMessage: translations.failure,
                })
            );
            setEditMode(true);
        }
        setDisableForm(false);
    }, [
        customeraccountId,
        estimateDetail,
        estimateDetailData?.comments,
        estimateDetailData?.purchaseOrder,
        estimateDetailData?.reference,
        estimateDetailData?.status,
        getEstimateDetail,
        headerId,
        isPoNumberError,
        lineId,
        put,
        dispatch,
        translations.failure,
        translations.success,
    ]);

    const editButtonVisibility = (): string => {
        if (
            ["rejected", "cancelled", "approved"].includes(estimateDetailData?.status?.toLowerCase() || "") ||
            estimateDetailData?.orderStatus === OrderStatuses.Closed ||
            hasIndirectAccounts
        ) {
            return "none";
        } else {
            return "";
        }
    };

    return (
        <Box
            bgcolor="bg.white"
            minHeight="31vh"
            data-testid="estimate-detail"
            sx={{ top: "336px", left: "128px", width: "100%" }}>
            {!isLoading && isError && (
                <Grid
                    item
                    xs={12}
                    mx={0}
                    mb={2}>
                    <br />
                    <Alert severity="error">{translations.apiError}</Alert>
                </Grid>
            )}
            {isLoading && <LinearProgress></LinearProgress>}
            {!isLoading && estimateDetail && (
                <>
                    {!editMode && (
                        <Box data-testid="estimate-details-labels">
                            <Grid
                                container
                                sx={{ pl: 11 }}>
                                <Grid
                                    container
                                    sx={{ pt: 2 }}>
                                    <Box paddingRight={10}>
                                        <SectionField>{translations.order}</SectionField>
                                        <SectionFieldValue>
                                            <Link
                                                variant="body2"
                                                color="blue.connectCare2"
                                                underline="none"
                                                onClick={() => {
                                                    navigate(`/orders/${headerId}`);
                                                }}
                                                target="_blank">
                                                {orderNumber}
                                            </Link>
                                        </SectionFieldValue>
                                    </Box>
                                    {!estimateDetailData?.isIndirectFiltered && ( //Do not show this if indirect filtering has been applied.
                                        <Box paddingRight={10}>
                                            <SectionField>{translations.poNumber}</SectionField>
                                            <SectionFieldValue>{estimateDetailData?.purchaseOrder}</SectionFieldValue>
                                        </Box>
                                    )}
                                    <Box>
                                        <SectionField>{translations.reference}</SectionField>
                                        <SectionFieldValue>{estimateDetailData?.reference}</SectionFieldValue>
                                    </Box>
                                </Grid>
                            </Grid>
                            {!estimateDetailData?.isIndirectFiltered && (
                                <Grid
                                    container
                                    sx={{ pl: 11 }}>
                                    <Grid
                                        container
                                        sx={{ pt: 3 }}>
                                        <Box>
                                            <SectionField>{translations.comments}</SectionField>
                                            <SectionFieldValue>{estimateDetailData?.comments}</SectionFieldValue>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                container
                                sx={{ pl: 11, pt: 6, pb: 3, display: editButtonVisibility }}>
                                <Button
                                    variant="contained"
                                    onClick={() => setEditMode(true)}
                                    disabled={
                                        estimateDetailData?.orderStatus === OrderStatuses.Closed ||
                                        hasIndirectAccounts ||
                                        disableForm
                                    }>
                                    {translations.edit}
                                </Button>
                            </Grid>
                        </Box>
                    )}
                    {editMode && (
                        <Box data-testid="estimate-details-edit">
                            <Grid
                                container
                                sx={{ pl: 11 }}>
                                <Grid
                                    container
                                    sx={{ pt: 2 }}>
                                    <Box paddingRight={10}>
                                        <SectionField>{translations.order}</SectionField>
                                        <SectionFieldValue>{orderNumber}</SectionFieldValue>
                                    </Box>
                                    {!estimateDetail.isIndirectFiltered && ( //Do not show this if indirect filtering has been applied.
                                        <Box
                                            paddingRight={10}
                                            maxWidth={200}>
                                            <SectionField>{translations.poNumber}</SectionField>
                                            <TextField
                                                inputProps={{
                                                    "data-testid": "po-number-input",
                                                }}
                                                onChange={(e) => handleChange(e, "po")}
                                                size="small"
                                                variant="outlined"
                                                sx={{ bgcolor: "bg.white" }}
                                                value={
                                                    estimateDetail?.purchaseOrder ?? estimateDetailData?.purchaseOrder
                                                }
                                                disabled={disableForm}
                                                error={isPoNumberError}
                                                helperText={isPoNumberError ? translations.poErrorMessage : ""}
                                            />
                                        </Box>
                                    )}
                                    <Box paddingRight={10}>
                                        <SectionField>{translations.reference}</SectionField>
                                        <TextField
                                            inputProps={{
                                                "data-testid": "reference-input",
                                            }}
                                            onChange={(e) => handleChange(e, "reference")}
                                            size="small"
                                            variant="outlined"
                                            sx={{ bgcolor: "bg.white" }}
                                            value={estimateDetail?.reference ?? estimateDetailData?.reference}
                                            disabled={!!estimateDetailData?.reference || disableForm}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            {!estimateDetailData?.isIndirectFiltered && (
                                <Grid
                                    container
                                    sx={{ pl: 11 }}>
                                    <Grid
                                        container
                                        sx={{ pt: 3 }}>
                                        <Box
                                            paddingRight={10}
                                            sx={{ width: matches ? "50%" : "auto" }}>
                                            <SectionField>{translations.comments}</SectionField>
                                            <TextField
                                                inputProps={{
                                                    "data-testid": "comments-input",
                                                    style: { resize: "both" },
                                                }}
                                                onChange={(e) => handleChange(e, "comments")}
                                                size="small"
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                fullWidth
                                                sx={{ bgcolor: "bg.white" }}
                                                value={estimateDetail?.comments ?? estimateDetailData?.comments}
                                                disabled={disableForm}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                container
                                sx={{ pl: 11, pt: 6, pb: 3 }}>
                                <Button
                                    aria-label={translations.cancel}
                                    variant="contained"
                                    style={{
                                        color: "#FFFFFF",
                                        backgroundColor: text.textgray2,
                                    }}
                                    onClick={() => {
                                        setEditMode(false);
                                        setDetailsEditMode(false);
                                    }}
                                    disabled={disableForm}>
                                    {translations.cancel}
                                </Button>
                                <Box
                                    component="span"
                                    sx={{ minWidth: "1vw", maxWidth: "1vw" }}
                                />
                                <Button
                                    aria-label={translations.save}
                                    variant="contained"
                                    onClick={() => {
                                        updateEstimateDetail();
                                    }}
                                    disabled={
                                        estimateDetailData?.orderStatus === OrderStatuses.Closed ||
                                        hasIndirectAccounts ||
                                        disableForm
                                    }>
                                    {translations.save}
                                </Button>
                            </Grid>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
}
