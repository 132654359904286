import { useCallback, useEffect, useMemo } from "react";
import { ccLocalstorage } from "../config/data";
import { setToast } from "../redux/reducers/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastTypes } from "../models/toast/ToastTypes";
import { getCustAccountIds } from "../utils/getCustAccountIds";
import { handleErrorResponse } from "../utils/fetchErrorHandler"
import { StoreState } from "../redux/store";

interface IUseFetch {
    get: <T>(
        resourcePath: string,
        includeAccountIdsHeader?: boolean,
        onError?: (error: any) => void
    ) => Promise<T | void>;
    getBlob: (
        resourcePath: string,
        includeAccountIdsHeader?: boolean,
        onError?: (error: any) => void
    ) => Promise<Blob | void>;
    post: <T>(
        resourcePath: string,
        body: T,
        includeAccountIdsHeader?: boolean,
        onError?: (error: any) => void
    ) => Promise<T | Response>;
    /**
     * Post to a resource with a body and optional header and error callback.
     * This will return void and call the onError callback if the response is not ok.
     * This will return the body of the type provided as TResponse or,
     * if no body, it will return the full Response object.
     * @param resourcePath
     * @param body
     * @param includeAccountIdsHeader
     * @param onError
     */
    postUnique: <TBody, TResponse>(
        resourcePath: string,
        body: TBody,
        includeAccountIdsHeader?: boolean,
        onError?: (error: any) => void
    ) => Promise<TResponse | Response | void>;
    postFormData: <TResponse>(
        path: string,
        formData: FormData,
        includeAccountIdsHeader?: boolean,
        onError?: (error: any) => void
    ) => Promise<TResponse | void>;
    put: <T>(
        resourcePath: string,
        body: T,
        includeAccountIdsHeader?: boolean,
        onError?: (error: any) => void
    ) => Promise<T | Response>;
    putUnique: <TBody, TResponse>(
        resourcePath: string,
        body: TBody,
        includeAccountIdsHeader?: boolean,
        onError?: (error: any) => void
    ) => Promise<TResponse | void>;
    del: (
        resourcePath: string,
        includeAccountIdsHeader?: boolean,
        onError?: (error: any) => void
    ) => Promise<boolean | void>;
}

enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
}

const isContentTypeJson = (response: Response): boolean => {
    const contentType = response.headers.get("content-type");
    return !!contentType && contentType.includes("json");
};

const resolveContent = async <T>(response: Response): Promise<T | Response> => {
    return isContentTypeJson(response) ? await response.json().then((content: T) => content) : response;
};

const isContentT = <T>(content: Response | T): content is T => {
    if (content && content instanceof Response) {
        return false;
    }
    return true;
};

export const useFetch = (): IUseFetch => {
    const dispatch = useDispatch();
    /**
     * Please dont remove this useEffect.
     * This will help to hide unwanted toast messages when redirecting from one page to other page
     */
    useEffect(() => {
        return () => {
            dispatch(
                setToast({
                    showToast: false,
                })
            );
        };
    }, [dispatch]);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);

    const getHeaders = useCallback(
        (includeAccountIdsHeader: boolean = false): HeadersInit => {
            if (includeAccountIdsHeader) {
                return {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token}`,
                    SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
                };
            }

            return {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            };
        },
        [selectedFacilities, token]
    );

    const fetchRequest = useCallback(
        async (
            resource: string,
            method: Method,
            includeAccountIdsHeader: boolean = false,
            body?: any
        ): Promise<Response> => {
            const requestBody = !body ? null : JSON.stringify(body);
            const options: RequestInit = {
                method: method,
                headers: getHeaders(includeAccountIdsHeader),
                body: requestBody,
            };

            //Check includeAccountIdsHeader and selectedfacilityids.length?
            const response = await fetch(resource, options);
            if (!response.ok) {                
                await handleErrorResponse(response);            
            }

            return response;
        },
        [getHeaders]
    );

    const handleError = useCallback(
        async (error: any) => {
            dispatch(
                setToast({
                    toastMessage: error?.message || "There was a problem processing that request.",
                    toastType: ToastTypes.Error,
                    showToast: true,
                })
            );
        },
        [dispatch]
    );

    const get = useCallback(
        async <T>(
            path: string,
            includeAccountIdsHeader: boolean = false,
            onError?: (error: any) => void //Todo: We should return ErrorResponse
        ): Promise<T | void> => {
            try {
                const response = await fetchRequest(path, Method.GET, includeAccountIdsHeader);
                const content = await resolveContent<T>(response);
                if (isContentT(content)) {
                    return content;
                }
            } catch (error) {
                onError ? onError(error) : handleError(error);
            }
        },
        [fetchRequest, handleError]
    );

    const getBlob = useCallback(
        async (
            path: string,
            includeAccountIdsHeader: boolean = false,
            onError?: (error: any) => void
        ): Promise<Blob | void> => {
            try {
                const response = await fetchRequest(path, Method.GET, includeAccountIdsHeader);
                if (!response.ok) {
                    throw {
                        statusCode: response.status,
                        message: response.statusText,
                    };
                }
                return response.blob();
            } catch (error) {
                onError ? onError(error) : await handleError(error);
            }
        },
        [fetchRequest, handleError]
    );

    const post = useCallback(
        async <T>(
            path: string,
            body: T,
            includeAccountIdsHeader: boolean = false,
            onError?: (error: any) => void
        ): Promise<T | Response> => {
            try {
                const response = await fetchRequest(path, Method.POST, includeAccountIdsHeader, body);
                const content = await resolveContent<T>(response);
                if (isContentT(content)) {
                    return content;
                } else {
                    return response;
                }
            } catch (error: any) {
                onError ? onError(error) : handleError(error);
                return error;
            }
        },
        [fetchRequest, handleError]
    );

    const postUnique = useCallback(
        async <TBody, TResponse>(
            path: string,
            body: TBody,
            includeAccountIdsHeader: boolean = false,
            onError?: (error: any) => void
        ): Promise<TResponse | Response | void> => {
            try {
                const response = await fetchRequest(path, Method.POST, includeAccountIdsHeader, body);
                const content = await resolveContent<TResponse>(response);
                if (isContentT(content)) {
                    return content;
                } else {
                    return response;
                }
            } catch (error) {
                onError ? onError(error) : handleError(error);
            }
        },
        [fetchRequest, handleError]
    );

    const postFormData = useCallback(
        async <TResponse>(
            path: string,
            formData: FormData,
            includeAccountIdsHeader: boolean = false,
            onError?: (error: any) => void
        ): Promise<TResponse | void> => {
            const headers: HeadersInit = includeAccountIdsHeader
                ? {
                      Authorization: `Bearer ${token}`,
                      SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
                  }
                : {
                      Authorization: `Bearer ${token}`,
                  };

            try {
                const response = await fetch(path, {
                    method: Method.POST,
                    headers,
                    body: formData,
                });
                const content = await resolveContent<TResponse>(response);
                if (isContentT(content)) {
                    return content;
                }
            } catch (error) {
                onError ? onError(error) : handleError(error);
            }
        },
        [selectedFacilities, token, handleError]
    );

    const put = useCallback(
        async <T>(
            path: string,
            body: T,
            includeAccountIdsHeader: boolean = false,
            onError?: (error: any) => void
        ): Promise<T | Response> => {
            try {
                const response = await fetchRequest(path, Method.PUT, includeAccountIdsHeader, body);
                const content = await resolveContent<T>(response);
                if (isContentT(content)) {
                    return content;
                } else {
                    return response;
                }
            } catch (error: any) {
                onError ? onError(error) : handleError(error);
                return error;
            }
        },
        [fetchRequest, handleError]
    );

    const putUnique = useCallback(
        async <TBody, TResponse>(
            path: string,
            body: TBody,
            includeAccountIdsHeader: boolean = false,
            onError?: (error: any) => void
        ): Promise<TResponse | void> => {
            try {
                const response = await fetchRequest(path, Method.PUT, includeAccountIdsHeader, body);
                const content = await resolveContent<TResponse>(response);
                if (isContentT(content)) {
                    return content;
                }
            } catch (error: any) {
                onError ? onError(error) : handleError(error);
            }
        },
        [fetchRequest, handleError]
    );

    const del = useCallback(
        async (
            path: string,
            includeAccountIdsHeader: boolean = false,
            onError?: (error: any) => void
        ): Promise<boolean | void> => {
            try {
                const response = await fetchRequest(path, Method.DELETE, includeAccountIdsHeader);
                if (response) {
                    return true;
                }
            } catch (error) {
                onError ? onError(error) : handleError(error);
            }
        },
        [fetchRequest, handleError]
    );

    return useMemo(
        () => ({
            get,
            getBlob,
            post,
            postUnique,
            postFormData,
            put,
            putUnique,
            del,
        }),
        [get, getBlob, post, postUnique, postFormData, put, putUnique, del]
    );
};
