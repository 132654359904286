import { Autocomplete, debounce, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { FacilityService } from "../../services/facility/FacilityService";
import { AccountSearchResponseDto } from "../../models/admin/AccountSearchResponseDto";

export default function AccountAutocomplete({ selectedAccount, personId }: any) {
    const { t } = useTranslation();
    const [accounts, setAccounts] = useState<AccountSearchResponseDto[]>([]);

    const facilityService = useMemo(() => new FacilityService(), []);

    const translations = {
        autoCompleteLabel: t("Start typing a Customer Number or Name."),
    };

    const handleSearch = (searchTerm: string) => {
        facilityService
            .searchFacilities(searchTerm, personId, 0)
            .then((response: AccountSearchResponseDto[]) => {               
                setAccounts(response)
            });
    };

    const onInputChange = (searchTerm: string) => {
        if (searchTerm.length < 3) {
            setAccounts([]);
            return;
        }
        debouncedSearch(searchTerm);
    };

    const debouncedSearch: (searchTerm: string) => void = debounce(handleSearch, 300);

    return (
        <Grid container>
            <Grid
                item
                xs={12}>
                <Autocomplete
                    disablePortal
                    size={"small"}
                    options={accounts}
                    getOptionLabel={(option) => `${option.name} [${option.number}]`}
                    filterOptions={(options) => options}
                    onInputChange={(_event, value) => onInputChange(value)}
                    onChange={(_event, value) => selectedAccount(value)}
                    renderInput={(params) => (
                        <TextField 
                            {...params}
                            inputProps={{ ...params.inputProps, "data-testid": "cust-acct-autocomplete" }}
                            InputLabelProps={{
                                shrink: undefined,
                            }} /* this is a hack due to a change in mui 5.14.2, where InputLabelProps are not compatable with TextField? See #17334 
                            ...params.InputLabelProps will duplicate the build error.
                            */
                            label={translations.autoCompleteLabel}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
