import { createSvgIcon } from '@mui/material/utils';
import { xmlns } from '../svgxmlns';

/**
 * Forceps/Scissors icon facing up. 
 * @example
 * As a button icon using theme colors.
 * <Button                                
 *   startIcon={<SterisInstrumentIcon sx={{ color: text.textdark } } /> }                                              
 *   <Typography>{`${translations.someText}`}</Typography>
 *  </Button>
 * @example
 *  As a standalone icon using built in colors.
 *  <SterisInstrumentIcon color="primary" sx={{ fontSize: 45 }} />    
 * @returns The Steris Instrument Icon as a component that renders the SVG.
 * @see /images/SterisInstrumentIcon.svg
 */
export const SterisInstrumentIcon = createSvgIcon(
    <svg
        xmlns={xmlns}
        viewBox="0 0 70.44 126.86"
        fill="none"
        strokeWidth={1.5}
        stroke="currentColor"
    >
        <path d="m42.55,98.43h-4.98c-.98,0-1.77.8-1.77,1.77s.8,1.77,1.77,1.77h4.98c.98,0,1.77-.79,1.77-1.77s-.8-1.77-1.77-1.77Z" />
        <path d="m33.08,98.43h-5.23c-.98,0-1.77.8-1.77,1.77s.8,1.77,1.77,1.77h5.23c.98,0,1.77-.79,1.77-1.77s-.8-1.77-1.77-1.77Z" />
        <path d="m56.23,98.43c-4.72,0-8.89,2.32-11.48,5.87-1.4-16.06-4.11-48.23-4.11-55.31,0-.98-.8-1.77-1.77-1.77s-1.77.8-1.77,1.77c0,9.7,4.73,62.55,4.93,64.8,0,.08.05.15.06.22.69,7.19,6.77,12.84,14.14,12.84,7.84,0,14.21-6.37,14.21-14.21s-6.37-14.21-14.21-14.21Zm10.67,14.21c0,5.88-4.79,10.67-10.67,10.67s-10.67-4.78-10.67-10.67,4.79-10.67,10.67-10.67,10.67,4.79,10.67,10.67Z" />
        <path d="m39.84,30.2c-.02-7.26-.24-28.23-.25-28.43-.01-.97-.8-1.75-1.79-1.75-.98.01-1.76.81-1.75,1.79,0,.2.25,23.64.25,29.49,0,1.55-.32,1.72-1.5,2.34-2.36,1.24-4.47,2.85-4.47,8.86,0,7.21-3.13,44.29-4.64,61.81-2.59-3.55-6.76-5.86-11.48-5.86-7.84,0-14.21,6.38-14.21,14.21s6.37,14.21,14.21,14.21c7.38,0,13.45-5.65,14.14-12.85.02-.08.06-.15.06-.23.45-5.09,3.73-42.3,4.97-61.39.03-.11.08-.2.09-.32.27-3.66,1.63-4.65,3.21-5.79,1.54-1.11,3.46-2.5,3.46-6.04,0-5.43-.27-9.53-.3-10.05Zm-14.96,82.45c0,5.88-4.79,10.67-10.67,10.67s-10.67-4.78-10.67-10.67,4.79-10.67,10.67-10.67,10.67,4.79,10.67,10.67Zm11.72-72.4c0,1.73-.62,2.18-1.99,3.16-.25.18-.5.37-.77.57.02-.57.03-1.09.03-1.49,0-4.37,1.14-4.96,2.58-5.72.04-.02.08-.04.11-.06.02,1.09.04,2.27.04,3.54Z" />
        <path d="m34.92,1.79c.01-.98-.78-1.78-1.75-1.79h0c-.97,0-1.77.78-1.78,1.75,0,.24-.26,23.6-.07,29.6.03.96.82,1.72,1.77,1.72h.06c.47-.01.91-.21,1.24-.56s.49-.8.48-1.27c-.17-5.38.03-25.38.07-29.34v-.11Z" />
    </svg>,
    "SterisInstrument"
)