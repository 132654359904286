import { Box, FormControl, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ItemsDisinfectedOptions } from "../../models/serviceRequest/ItemsDisinfectedOptions";
import { EstimateRequiredOptions } from "../../models/serviceRequest/EstimateRequiredOptions";
import HoverTooltip from "../../component-library/HoverTooltip";
import { HoverTooltipText } from "../../component-library/HoverTooltipData";
import { addEmptyEntryIfMultiple } from "../../utils/addEmptyEntryIfMultiple";
import getItemDisinfectedValue from "../../utils/getItemDisinfectedValue";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { SrnCreationContext } from "../../contexts/SrnCreationContext";
import { ISrnShipment } from "../../models/serviceRequest/CreateSrnModels";
import { blue } from "../../theme/colors";
import { EntitlementType } from "../../config/data";

export interface IReviewShipmentsProps {
    updateShipment: (shipment: ISrnShipment, index: number) => void;
    reference_max: number;
    isApplyToAllChecked: boolean;
    setIsApplyToAllChecked: Dispatch<SetStateAction<boolean>>;
}

export const VerifyShipping = (props: IReviewShipmentsProps) => {
    const { serviceRequestShipment } = useContext(SrnCreationContext);
    const processedReturnAddressOptions = addEmptyEntryIfMultiple(
        serviceRequestShipment?.returnShippingAddressOptions!
    );
    const processedShippingAccountOptions = addEmptyEntryIfMultiple(serviceRequestShipment?.shippingAccountOptions!);
    const processedShippingMethodOptions = addEmptyEntryIfMultiple(serviceRequestShipment?.shippingMethodOptions!);
    const { t } = useTranslation();
    const [disableApplyToAllShipment, setDisableApplyToAllShipment] = useState<boolean>(false);
    const [shipments, setShipments] = useState<ISrnShipment[] | undefined>([]);
    const translations = {
        department: t("Department"),
        contact: t("Contact"),
        po: t("Po"),
        itemsDisinfected: t("Items Disinfected"),
        estimateRequired: t("Estimate Required"),
        reference: t("Reference (optional)"),
        returnShippingAddress: t("Return Shipping Address"),
        shippingAccount: t("Shipping Account"),
        shippingMethod: t("Shipping Method"),
        referenceError: t("50 Characters Max."),
        selectOne: t("Select One"),
        shipment: t("Shipment"),
        of: t("of"),
        applyToAll: t("Apply to all shipments"),
        shippingDetails: t("Shipping Details"),
    };

    /**
     * Here we are checking for the entitlement type throughout all the items in individual shipment and
     * if we found multiple entitlement type (null or any other value),
     * then we need to disable Apply To All Shipments switch button and by default its value will be SWITCHED OFF.
     */
    useEffect(() => {
        //Set is a collection of unique values. Each value can only occur once in a Set. A Set can hold any value of any data type.
        //each shipment have multiple items and each items will have the same entitlementTypeMeaning as we have done a group by in the backend api.
        //so we just need to check the first item's entitlementTypeMeaning of every shipment.
        const uniqueEntitlementType = [
            ...new Set(serviceRequestShipment?.shipments?.map((item) => item.items[0]?.entitlementTypeMeaning)),
        ];

        if (uniqueEntitlementType.length > 1) {
            props.setIsApplyToAllChecked(false);
            setDisableApplyToAllShipment(true);
        }
    }, [props, serviceRequestShipment?.shipments]);

    /**
     * Here we are checking for the entitlement type in shipment items and
     * if we found atleast one non-nullable entitlement type for each shipment item, we will disable the Estimate Required Selection and
     * by default we will set the value of estimateRequired to "No, Service is Pre-Approved"
     */
    useEffect(() => {
        shipments?.forEach(function (shipment, index) {
            //each shipment have multiple items and each items will have the same entitlementTypeMeaning as we have done a group by in the backend api.
            //so we just need to check the first item's entitlementTypeMeaning of every shipment.
            const result =
                shipment?.items[0]?.entitlementTypeCode === EntitlementType.PriorityRepair || shipment?.items[0]?.isCap;

            if (result && shipment?.estimateRequired === null) {
                shipment.estimateRequired = false;
                let updatedShipment = { ...shipment };
                updatedShipment.estimateRequired = false;
                props.updateShipment(updatedShipment, index);
            }
        });
        // props is not needed in dependency array.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipments]);

    const getValueForEstimateRequired = (estimateRequired: boolean | null | undefined) => {
        if (estimateRequired === null) {
            return EstimateRequiredOptions.Select;
        }
        return estimateRequired ? EstimateRequiredOptions.Yes : EstimateRequiredOptions.No;
    };

    useEffect(() => {
        if (props.isApplyToAllChecked && serviceRequestShipment?.shipments) {
            setShipments([serviceRequestShipment?.shipments[0]!]);
        } else {
            setShipments(serviceRequestShipment?.shipments);
        }
    }, [props.isApplyToAllChecked, serviceRequestShipment?.shipments]);

    return (
        <Box data-testid="verify-shipping">
            <Typography
                variant="h4"
                pl={0}
                color={blue.darker}>
                {translations.shippingDetails}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                <Switch
                    checked={props.isApplyToAllChecked}
                    onChange={() => props.setIsApplyToAllChecked((prev) => !prev)}
                    color="primary"
                    disabled={disableApplyToAllShipment}
                />
                <Typography
                    pl={0}
                    variant="h4">
                    {translations.applyToAll}
                </Typography>
            </Box>
            {shipments?.map((shipment, index) => (
                <Box key={index}>
                    {!props.isApplyToAllChecked && (
                        <Typography
                            pb={1}
                            variant="subtitle1">
                            {translations.shipment} {index + 1} {translations.of}{" "}
                            {serviceRequestShipment?.shipments.length}
                        </Typography>
                    )}
                    <Box sx={{ flexGrow: 1, pr: 2, pt: props.isApplyToAllChecked ? 2 : 0, pb: 2 }}>
                        <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                            error={shipment.returnAddressId === 0 || shipment.returnAddressId === null}>
                            <InputLabel id="return-shipping-address">{translations.returnShippingAddress}</InputLabel>
                            <Select
                                data-testid="shipping-address-dropdown"
                                labelId="return-shipping-address-select"
                                value={shipment.returnAddressId ?? 0}
                                label={translations.returnShippingAddress}
                                onChange={(e) => {
                                    let updatedShipment = { ...shipment };
                                    updatedShipment.returnAddressId = +e.target.value;
                                    updatedShipment.returnAddress =
                                        processedReturnAddressOptions.find(
                                            (x) => x.addressId === updatedShipment.returnAddressId
                                        )?.display ?? "";
                                    props.updateShipment(updatedShipment, index);
                                }}
                                readOnly={processedReturnAddressOptions.length === 1}>
                                {processedReturnAddressOptions.map((address, index) => (
                                    <MenuItem
                                        key={index}
                                        value={address.addressId ?? 0}>
                                        {address.display ?? translations.selectOne}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                        }}>
                        <Box sx={{ flexGrow: 1, pr: 2, pb: 2 }}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                error={shipment.shippingMethodId === "0" || shipment.shippingMethodId === null}>
                                <InputLabel id="shipping-method">{translations.shippingMethod}</InputLabel>
                                <Select
                                    data-testid="shipping-method-dropdown"
                                    labelId="shipping-method-select"
                                    value={shipment.shippingMethodId ?? "0"}
                                    label={translations.shippingMethod}
                                    onChange={(e) => {
                                        let updatedShipment = { ...shipment };
                                        updatedShipment.shippingMethodId = String(e.target.value);
                                        updatedShipment.shippingMethod =
                                            processedShippingMethodOptions.find(
                                                (x) => x.value === updatedShipment.shippingMethodId
                                            )?.description ?? "";
                                        props.updateShipment(updatedShipment, index);
                                    }}
                                    readOnly={processedShippingMethodOptions.length === 1}>
                                    {processedShippingMethodOptions.map((method, index) => (
                                        <MenuItem
                                            key={index}
                                            value={method.value ?? 0}>
                                            {method.description ?? translations.selectOne}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ flexGrow: 1, pr: 2, pb: 2 }}>
                            {processedShippingAccountOptions.length === 1 ? (
                                <>
                                    <Typography variant="detailLabel">{translations.shippingAccount}</Typography>
                                    <Typography variant="detailValue">{`${
                                        processedShippingAccountOptions.find((x) => x.value === shipment.fedexAccount)
                                            ?.description ?? ""
                                    } 
                                        (${shipment.fedexAccount})`}</Typography>
                                </>
                            ) : (
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    error={shipment.fedexAccount === "0" || shipment.fedexAccount === null}>
                                    <InputLabel id="shipping-account">{translations.shippingAccount}</InputLabel>
                                    <Select
                                        data-testid="shipping-account-dropdown"
                                        labelId="shipping-account-select"
                                        value={shipment.fedexAccount ?? "0"}
                                        label={translations.shippingAccount}
                                        onChange={(e) => {
                                            let updatedShipment = { ...shipment };
                                            updatedShipment.fedexAccount = String(e.target.value);
                                            updatedShipment.shippingAccount =
                                                processedShippingAccountOptions.find(
                                                    (x) => x.value === updatedShipment.fedexAccount
                                                )?.description ?? "";
                                            props.updateShipment(updatedShipment, index);
                                        }}
                                        readOnly={true}>
                                        {processedShippingAccountOptions.map((account, index) => (
                                            <MenuItem
                                                key={index}
                                                value={account.value ?? 0}>
                                                {account.description ?? translations.selectOne}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexGrow: 1,
                                pr: 2,
                                pb: 2,
                            }}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                error={shipment.itemsDisinfected === null}>
                                <InputLabel id="items-disinfected">{translations.itemsDisinfected}</InputLabel>
                                <Select
                                    data-testid="items-disinfected-select"
                                    inputProps={{ "data-testid": "items-disinfected-select" }}
                                    labelId="items-disinfected-select"
                                    value={getItemDisinfectedValue(shipment.itemsDisinfected)}
                                    label={translations.itemsDisinfected}
                                    onChange={(e) => {
                                        let updatedShipment = { ...shipment };
                                        const val = e.target.value;
                                        updatedShipment.itemsDisinfected =
                                            val === ItemsDisinfectedOptions.Select
                                                ? null
                                                : val === ItemsDisinfectedOptions.Yes;

                                        props.updateShipment(updatedShipment, index);
                                    }}>
                                    {Object.keys(ItemsDisinfectedOptions).map((key) => (
                                        <MenuItem
                                            key={key}
                                            value={
                                                ItemsDisinfectedOptions[key as keyof typeof ItemsDisinfectedOptions]
                                            }>
                                            {ItemsDisinfectedOptions[key as keyof typeof ItemsDisinfectedOptions]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <HoverTooltip
                                data-testid="items-disinfect-tooltip"
                                tooltip={HoverTooltipText.ItemsDisinfected}
                                placement="right"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexGrow: 1,
                                pr: 2,
                                pb: 2,
                            }}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                error={shipment.estimateRequired === null}>
                                <InputLabel id="estimate-required">{translations.estimateRequired}</InputLabel>
                                <Select
                                    inputProps={{ "data-testid": "estimate-required-select" }}
                                    labelId="estimate-required-select"
                                    disabled={shipment.items?.every((a) => a.isCap)}
                                    value={getValueForEstimateRequired(shipment.estimateRequired)}
                                    label={translations.estimateRequired}
                                    onChange={(e) => {
                                        let updatedShipment = { ...shipment };
                                        const val = e.target.value;
                                        updatedShipment.estimateRequired =
                                            val === EstimateRequiredOptions.Select
                                                ? null
                                                : val === EstimateRequiredOptions.Yes;
                                        props.updateShipment(updatedShipment, index);
                                    }}>
                                    {Object.keys(EstimateRequiredOptions).map((key) => (
                                        <MenuItem
                                            key={key}
                                            value={
                                                EstimateRequiredOptions[key as keyof typeof EstimateRequiredOptions]
                                            }>
                                            {EstimateRequiredOptions[key as keyof typeof EstimateRequiredOptions]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <HoverTooltip
                                data-testid="estimate-required-tooltip"
                                tooltip={HoverTooltipText.EstimateRequired}
                                placement="right-start"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1, pr: 2, pb: 2 }}>
                        <FormControl
                            fullWidth
                            variant="outlined">
                            <TextField
                                inputProps={{ "data-testid": "reference-input" }}
                                label={translations.reference}
                                size="small"
                                value={shipment.reference ?? ""}
                                error={
                                    shipment.reference === null
                                        ? false
                                        : shipment.reference!.length > props.reference_max
                                }
                                onChange={(e) => {
                                    let updatedShipment = { ...shipment };
                                    updatedShipment.reference = e.target.value;
                                    props.updateShipment(updatedShipment, index);
                                }}
                            />
                        </FormControl>
                        {shipment.reference && shipment.reference.length > props.reference_max && (
                            <Typography
                                data-testid={"reference-input-error"}
                                variant="errorMessage">
                                {translations.referenceError}
                            </Typography>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
