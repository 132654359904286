import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../../common/BaseGridState";
import { BaseApiState } from "../../common/BaseApiState";
import { RepairCredits } from "../../../models/invoices/RepairCredits";
import { baseGridReducers, baseGridInitialState } from "../baseGridsSlice";
import { setDataLoaded, setDataReceivedWithError, setDataRequested } from "../sharedReducers";

export interface RepairCreditState extends BaseGridState, BaseApiState<RepairCredits[]> {}

const initialState: RepairCreditState = {
    ...baseGridInitialState,
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
};

export const repairCreditSlice = createSlice({
    name: "repairCredit",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDataRequested,
        setDataReceivedWithError,
        setDataLoaded,
        dataReceived: (state, action: PayloadAction<RepairCredits[]>) => {
            state.data = action.payload;
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.isLoading = false;
        },
    },
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = repairCreditSlice.actions;

export default repairCreditSlice.reducer;
