import { CheckCircle } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ccLocalstorage } from "../../config/data";
import { Report } from "../../models/reports/Report";
import { ParameterState, ReportParameter } from "../../models/reports/ReportParameter";
import { SsrsReport } from "../../models/reports/SsrsReport";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { setToast } from "../../redux/reducers/toastSlice";
import { requestConnectCareReports } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { ReportParameterControl } from "./ReportParameterControl";
import { StoreState } from "../../redux/store";
import { styledCheckCircle } from "../../theme/theme";

import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { dateRangeUntilNow } from "../../utils/dateRange";


export default function ReportParameters({ reportId }: { reportId: SsrsReport }) {
    const { t } = useTranslation();
    const [apiReport, setApiReport] = useState<Report>();
    const [localReport, setLocalReport] = useState<Report>();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const defaultIndex = -1;
    const [changedIndex, setChangedIndex] = useState(defaultIndex);
    const { currentUser } = useSelector((state: StoreState) => state.auth);
    const { get, post } = useFetch();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const DEFAULT_VIEW_PRICING_VALUE = "Y";
    const DEFAULT_START_DATE_DAYS_FILTER_VALUE = "-1";
    const [viewPricing, setViewPricing] = useState(DEFAULT_VIEW_PRICING_VALUE);
    const [startDateDaysFilter, setStartDateDaysFilter] = useState(DEFAULT_START_DATE_DAYS_FILTER_VALUE);
    const REPORT_PARAM = {
        CUSTOMER_NUMBER: 'CUSTOMER_NUMBER',
        START_DATE: 'StartDate',
        VIEW_PRICING: 'ViewPricing',
        PERSON_TYPE: 'PersonType',
    }
    const VIEW_REPORT = {
        VIEW_PRICING: 'View Pricing',
        VIEW_START_DATE_DAYS_FILTER: 'Start Date Days Filter',
    }


    const normalizeParameterValues = useCallback(
        (report: Report) => {
            report?.reportParameters?.forEach((param: ReportParameter) => {
                if (param.defaultValues) {
                    param.defaultValues = Array.from(new Set(param.defaultValues));
                }
                if (!param.selectedValues && param.defaultValues) {
                    param.selectedValues = param.defaultValues;
                }
                if (param.defaultValues?.length === 1) {
                    param.value = param.defaultValues[0] ?? "";
                }
                if (param.name === REPORT_PARAM.PERSON_TYPE && currentUser) {
                    syncParameterValues(param, currentUser.personType.toString());
                }
                if ((param.defaultValues?.length === param.validValues?.map((v) => v.value).length - 1) && (param.name != REPORT_PARAM.VIEW_PRICING)) {
                    param.selectedValues = param.validValues.map((v) => v.value);
                }
            });
            return report;
        },
        [currentUser]
    );

    const getInitReport = useCallback(async () => {
        setIsLoading(true);
        const uri = `${requestConnectCareReports.ReportModel(reportId)}`;
        const response = await get<Report>(uri);
        if (response) {
            setApiReport(normalizeParameterValues(response));
            setIsLoading(false);
        } else {
            setIsLoading(false);
            dispatch(
                setToast({
                    showToast: true,
                    toastType: ToastTypes.Error,
                    toastMessage: t("Error retrieving report parameters."),
                })
            );
            navigate("/reports");
        }
    }, [reportId, dispatch, t, navigate, get, normalizeParameterValues]);

    /**
     * This is the logic to set the appropriate values for the report parameter
     * @param reportParam
     * @param eventValue
     */
    const syncParameterValues = (reportParam: ReportParameter, eventValue: string | string[]) => {
        if (!Array.isArray(eventValue)) {
            // set the "value" property and sync defaultValues
            reportParam.value = eventValue;
            reportParam.defaultValues = [eventValue];
            return;
        }
        if (!reportParam.selectedValues?.includes("%") && eventValue?.includes("%")) {
            reportParam.selectedValues = reportParam.validValues?.map((validValue) => validValue.value);
            // User checked "Select All", check all options
        } else if (reportParam.selectedValues?.includes("%") && !eventValue?.includes("%")) {
            reportParam.selectedValues = [];
            // User unchecked "Select All", uncheck all options
        } else if (reportParam.selectedValues?.includes("%") && eventValue?.includes("%")) {
            reportParam.selectedValues = eventValue.filter((selectedValue) => selectedValue !== "%");
            // User checked another option while "Select All" was checked, uncheck "Select All"
        } else {
            reportParam.selectedValues = eventValue;
        }
        if (eventValue?.length === 1 && eventValue[0] === "%" && reportParam.selectedValues?.length > 0) {
            reportParam.selectedValues = reportParam.validValues.map((validValue) => validValue.value);
        } else if (
            reportParam.multiValue &&
            reportParam.selectedValues?.length >=
                reportParam.validValues?.map((validValue) => validValue.value).length - 1 &&
            !reportParam.selectedValues?.includes("%")
        ) {
            reportParam.selectedValues.unshift("%");
        }

        if (!reportParam.multiValue) {
            reportParam.value = eventValue[0] ?? "";
        }
        // defaultValues is the source of truth for the report parameter values from API - Keep it in sync
        reportParam.defaultValues = eventValue;
    };

    useEffect(() => {
        getInitReport().catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        setLocalReport(apiReport);
        setChangedIndex(defaultIndex);
    }, [apiReport, defaultIndex]);

    const updateReportParameters = useCallback(async () => {
        setIsLoading(true);
        const uri = `${requestConnectCareReports.ReportParameters(changedIndex.toString())}`;
        let response: Report | Response = {} as Report;

        if (apiReport) {
            response = await post<Report>(uri, apiReport, false, (error) => {
                setIsError(true);

                dispatch(
                    setToast({
                        toastType: ToastTypes.Error,
                        toastMessage:
                            error.statusCode === 401
                                ? t("Subscription expired. Contact Customer Care at {{tel}} for assistance.", {
                                      tel: "1-800-783-9251",
                                  })
                                : t("Error retrieving report parameters."),
                    })
                );
            });
        }
        setIsLoading(false);

        return response;
    }, [apiReport, changedIndex, dispatch, post, t]);

    useEffect(() => {
        if (changedIndex !== defaultIndex && !isError) {
            updateReportParameters().then((response) => {
                if (response) {
                    setApiReport(normalizeParameterValues(response as Report));
                }
            });
        }
    }, [changedIndex, defaultIndex, isError, normalizeParameterValues, updateReportParameters]);


    const getReportName = (reportId: SsrsReport) => {
        switch(reportId) {
            case SsrsReport.repairHistory:
                return "Repair History";
            case SsrsReport.equipmentPlannerAemTray:
                return "";
            case SsrsReport.equipmentPlannerAemDevices:
                return "";
            default:
                return "";
        }
    }

    const setDefaultViewReportSettings = () => {
        setViewPricing(DEFAULT_VIEW_PRICING_VALUE);
        setStartDateDaysFilter(DEFAULT_START_DATE_DAYS_FILTER_VALUE);
    }


    const setViewPricingValue = (newViewPricing: string, viewPricingReportParam: ReportParameter) => {
        setViewPricing(newViewPricing);
        console.log(viewPricing);
        syncParameterValues(viewPricingReportParam, newViewPricing);
    }

    const setStartDateValue = (newStartDate: string) => {
        setStartDateDaysFilter(newStartDate);
        const startDate = localReport?.reportParameters.find((x) => x.name === REPORT_PARAM.START_DATE);
        const dateSelected = handleDateValidation(new Date(startDate?.value!), startDate!);
        handleDate(dateSelected!, startDate!);
    }

    const parseReportSetting = (settings: string, viewPricingReportParam: ReportParameter) => {
        if (settings) {
            const viewReportsValue = JSON.parse(settings);
            try {
                const reportName = getReportName(reportId);
                Object.keys(viewReportsValue.Reports).map((key) => {
                    if (viewReportsValue.Reports[key].ReportName == reportName) {
                        setDefaultViewReportSettings();
                        Object.keys(viewReportsValue.Reports[key].Settings).map((index) => {
                            if (viewReportsValue.Reports[key].Settings[index].Name === VIEW_REPORT.VIEW_PRICING) {
                                setViewPricingValue(viewReportsValue.Reports[key].Settings[index].Value, viewPricingReportParam);
                            }
                            else if (viewReportsValue.Reports[key].Settings[index].Name === VIEW_REPORT.VIEW_START_DATE_DAYS_FILTER) {
                                setStartDateValue(viewReportsValue.Reports[key].Settings[index].Value);
                            }
                        })
                    }
                });
            } catch {
                setDefaultViewReportSettings();
            }
        }

    }

    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string[]>) => {
        setLocalReport((prevState: Report | undefined) => {
            const newState: Report | undefined = prevState ? { ...prevState } : undefined;

            const reportParam = newState?.reportParameters.find((x) => x.name === event.target.name);

            if (reportParam) {
                if (event.target.name === REPORT_PARAM.CUSTOMER_NUMBER) {
                    const facility = selectedFacilities.filter(f => f.number === Number(event.target.value));
                    const viewReportsValue = AuthLibrary.getClaimValuesByAccount(facility[0]?.custAccountId, claimTypes.ViewReports) as string;
                    const viewPricingReportParam = newState?.reportParameters.find((x) => x.name === REPORT_PARAM.VIEW_PRICING);
                    parseReportSetting(viewReportsValue, viewPricingReportParam!);
                }
                syncParameterValues(reportParam, event.target.value);
            }
            return newState;
        });
        sleep(500).then(() =>
            setChangedIndex(
                localReport
                    ? localReport.reportParameters.findIndex((x: ReportParameter) => x.name === event.target.name)
                    : defaultIndex
            )
        );
    };

    const handleDateChange = (selectedDate: Date | null, param: ReportParameter) => {
        const dateSelected = handleDateValidation(selectedDate, param);
        handleDate(dateSelected!, param);

        sleep(500).then(() =>
            setChangedIndex(
                localReport
                    ? localReport.reportParameters.findIndex((x: ReportParameter) => x.name === param.name)
                    : defaultIndex
            )
        );
    };

    const handleDateValidation = (selectedDate: Date | null, param: ReportParameter) => {
        if (param.name === REPORT_PARAM.START_DATE && startDateDaysFilter != DEFAULT_START_DATE_DAYS_FILTER_VALUE) {
            const startDateFilter = dateRangeUntilNow(parseInt(startDateDaysFilter));
            const minDate = startDateFilter[0]?.toDate();
            if (selectedDate && minDate! > selectedDate) {
                return minDate;
            }
        }
        return selectedDate;
    }

    const handleDate = (selectedDate: Date | null, param: ReportParameter) => {
        setLocalReport((prevState: Report | undefined) => {
            const newState: Report | undefined = prevState ? { ...prevState } : undefined;

            const reportParam = newState?.reportParameters.find((x) => x.name === param.name);

            if (reportParam) {
                reportParam.value = selectedDate?.toISOString() ?? "";
                // defaultValues is the source of truth for the report parameter values from API - Keep it in sync
                reportParam.defaultValues = [selectedDate?.toISOString() ?? ""];
            }

            return newState;
        });
    }

    const handleMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
        setLocalReport((prevState: Report | undefined) => {
            const newState: Report | undefined = prevState ? { ...prevState } : undefined;

            const reportParam = newState?.reportParameters.find((x) => x.name === event.target.name);

            if (reportParam) {
                syncParameterValues(reportParam, event.target.value);
            }

            return newState;
        });
    };

    const handleMultiSelectSelection = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setChangedIndex(
            localReport
                ? localReport.reportParameters.findIndex((x: ReportParameter) => x.name === event.target.name)
                : defaultIndex
        );
    };

    const handleSubmit = () => {
        setIsSubmitLoading(true);

        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareReports.RunReport}`;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token} `,
            },
            body: JSON.stringify(apiReport),
        };
        fetch(uri, requestOptions)
            .then((response) => response.blob())
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                window.open(file);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsSubmitLoading(false);
            });
    };
    const getReportParameters = localReport?.reportParameters?.map((reportParameter) => {
        return (
            <Box
                marginY={2}
                key={reportParameter.name}>
                {(reportParameter.name != REPORT_PARAM.PERSON_TYPE && reportParameter.name != REPORT_PARAM.VIEW_PRICING) && (
                    <Grid
                        container
                        spacing={2}
                        data-testid="report-parameters-control">
                        <Grid
                            item
                            xs={11}>
                            <ReportParameterControl
                                reportParameter={reportParameter}
                                selectedFacilities={selectedFacilities}
                                handleDateChange={handleDateChange}
                                handleMultiSelectChange={handleMultiSelectChange}
                                handleMultiSelectSelection={handleMultiSelectSelection}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}>
                            {reportParameter.parameterStateName === ParameterState.HasValidValue && (
                                <CheckCircle sx={styledCheckCircle} />
                            )}
                        </Grid>
                    </Grid>
                )}
            </Box>
        );
    });
    if (!localReport) {
        return (
            <Box
                data-testid="report-parameters-loading"
                marginTop={5}
                display="flex"
                justifyContent="center">
                {isLoading && <CircularProgress />}
            </Box>
        );
    }
    return (
        <Box
            data-testid={"report-parameters-container"}
            padding={3}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                {localReport && (
                    <Typography variant="reportTitle">
                        {localReport?.reportTitle}
                        {isLoading && <CircularProgress />}
                    </Typography>
                )}
            </Box>
            <Divider />
            <Typography
                ml={1}
                variant={"overline"}
                color={"font.gray"}
                textTransform={"none"}>
                {localReport?.reportDescription}
            </Typography>
            <Box marginY={1}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={10}
                        md={8}
                        lg={6}
                        xl={4}>
                        {getReportParameters}
                    </Grid>
                </Grid>
            </Box>
            <Box
                marginY={4}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}>
                <Button
                    aria-label={t("Cancel")}
                    variant="Cancel"
                    onClick={() => navigate("/analytics/reports")}>
                    Cancel
                </Button>
                <Box marginX={2}>
                    <Button
                        aria-label={t("Get Report")}
                        disabled={
                            !isError &&
                            !apiReport?.reportParameters?.every(
                                (x) => x.parameterStateName === ParameterState.HasValidValue
                            )
                        }
                        variant="contained"
                        onClick={handleSubmit}>
                        Get Report
                    </Button>
                </Box>
                {isSubmitLoading && <CircularProgress size={20} />}
            </Box>
        </Box>
    );
}
