export interface PmResultsSurgicalRecords {
    criticalPriorityCount: number;
    immediateServiceCount: number;
    preventiveServiceCount: number;
    okCount: number;
    inventoryOnlyCount: number;
}

export const defaultPmResultsSurgicalRecords : PmResultsSurgicalRecords  = {
    criticalPriorityCount: 0,
    immediateServiceCount: 0,
    preventiveServiceCount: 0,
    okCount: 0,
    inventoryOnlyCount: 0,
}