import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSearchString } from "../../redux/reducers/orders/ordersSlice";
import { StoreState } from "../../redux/store";

export default function OrderSearch() {
    const { t } = useTranslation();
    const translations = {
        label: t("Order Search"),
        placeholder: t("Search by Order #, SRN, PO, or Serial #..."),
    };
    const dispatch = useDispatch();
    const searchString = useSelector((state: StoreState) => state.orders.searchString);

    return (
        <TextField
            inputProps={{
                "data-testid": "order-search",
                "aria-label": `${translations.label}`,
            }}
            fullWidth
            aria-label={translations.label}
            placeholder={translations.placeholder}
            size="small"
            id="orderSearch"
            variant="outlined"
            autoComplete="off"
            value={searchString}
            onChange={(e) => dispatch(setSearchString(e.target.value))}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }}
        />
    );
}
