import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams, GridFilterModel } from "@mui/x-data-grid-pro";
import { Box, LinearProgress, Tooltip, Link, Grid } from "@mui/material";
import { FormatDate } from "../../common/Formatters";
import { ccLocalstorage, educationEventType } from "../../config/data";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useCallback, useEffect, useState } from "react";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { useNavigate, useLocation } from "react-router-dom";
import { NoRowsOverlayWithIcon } from "../../component-library/NoRowsOverlay";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { Claims } from "../../models/authorization/AccountSubscriptionClaims";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { setGridColumns, setGridSort, setGridFilter } from "../../redux/reducers/education/educationSlice";
import { customSortComparators } from "../../utils/customSortComparators";

const TopicCell = ({ row }: GridRenderCellParams) => {
    const [, saveEventNumberToLocalStorage] = useLocalStorage(ccLocalstorage.connectCareCustomerEventId, "");

    const setEventNumber = (pkId: number) => {
        saveEventNumberToLocalStorage(pkId.toString());
    };

    const navigate = useNavigate();
    return (
        <Tooltip
            title={row.topicName}
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <Link
                onClick={() => {
                    setEventNumber(row.id);
                    navigate(`/events/${row.id}`);
                }}>
                {row.topicName}
            </Link>
        </Tooltip>
    );
};

const CustomerCell = ({ row }: GridRenderCellParams) => {
    return (
        <Tooltip
            title={row.customer}
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <Box component="span">{row.customer}</Box>
        </Tooltip>
    );
};

export default function EducationListGrid() {
    const { t } = useTranslation();
    const location = useLocation();
    const [fileName, setFileName] = useState<string>(exportExcelFileName.educationEvents);
    const [isFilterLocked, setIsFilterLocked] = useState(false);
    const dispatch = useDispatch();

    const { isLoading, data, gridColumns, gridFilter, gridSort } = useSelector((state: StoreState) => state.education);

    const translations = {
        educationGridNoData: t("You have no events for the Facilities selected."),
        eventDate: t("Event Date"),
        topic: t("Topic"),
        dept: t("Department"),
        customer: t("Customer"),
        type: t("Type"),
        status: t("Status"),
        credits: t("Credits"),
        requestedBy: t("Requested By"),
        sponsor: t("Sponsor"),
        instructor: t("Instructor"),
        expectedAttendees: t("Expected Attendees"),
        registration: t("Registration"),
        createdDate: t("Created Date"),
        updatedDate: t("Updated Date"),
        noMatch: t("We found no matches for the selected Date Range."),
        editYourSearch: t("Please edit your search and try again."),
    };

    const educationsColumn: GridColDef[] = [
        {
            field: "eventDate",
            renderHeader: () => translations.eventDate,
            headerName: translations.eventDate,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            headerAlign: "left",
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "topicName",
            renderHeader: () => translations.topic,
            headerName: translations.topic,
            minWidth: 150,
            flex: 2,
            sortable: true,
            type: "string",
            renderCell: (params: GridRenderCellParams) => <TopicCell {...params} />,
            headerAlign: "left",
            cellClassName: "break-into-multi-lines",
            headerClassName: "text-transform-header",
        },
        {
            field: "eventType",
            renderHeader: () => translations.type,
            headerName: translations.type,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "customer",
            renderHeader: () => translations.customer,
            headerName: translations.customer,
            minWidth: 150,
            flex: 2,
            sortable: true,
            type: "string",
            renderCell: (params: GridRenderCellParams) => <CustomerCell {...params} />,
            headerAlign: "left",
        },
        {
            field: "departmentName",
            renderHeader: () => translations.dept,
            headerName: translations.dept,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "statusDescription",
            renderHeader: () => translations.status,
            headerName: translations.status,
            minWidth: 120,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "ceCredits",
            renderHeader: () => "CE " + translations.credits,
            headerName: "CE " + translations.credits,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "requestedBy",
            renderHeader: () => translations.requestedBy,
            headerName: translations.requestedBy,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "sponsorName",
            renderHeader: () => translations.sponsor,
            headerName: translations.sponsor,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "sterisSponsorName",
            renderHeader: () => "STERIS " + translations.sponsor,
            headerName: "STERIS " + translations.sponsor,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "instructorName",
            renderHeader: () => translations.instructor,
            headerName: translations.instructor,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "expectedAttendees",
            renderHeader: () => translations.expectedAttendees,
            headerName: translations.expectedAttendees,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "registration",
            renderHeader: () => translations.registration,
            headerName: translations.registration,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "string",
            headerAlign: "left",
        },
        {
            field: "created",
            renderHeader: () => translations.createdDate,
            headerName: translations.createdDate,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            headerAlign: "left",
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "updated",
            renderHeader: () => translations.updatedDate,
            headerName: translations.updatedDate,
            minWidth: 150,
            flex: 1,
            sortable: true,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            headerAlign: "left",
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
    ];

    /**
     * Handles changing of the grid filter if not locked.
     * */
    const changeGridFilter = (filterModel: GridFilterModel) => {
        if (!isFilterLocked) {
            dispatch(setGridFilter(filterModel));
        }
    };

    const setFilter = useCallback(
        (claimType: string, filterValue: string, exportFileName: string) => {
            const claimValues = AuthLibrary.GetAccountSubscriptionClaimValues(claimType);
            const values = claimValues.map((claim: Claims) => claim.value);
            let filter: GridFilterModel = {
                items: [{ id: 1, field: "eventType", operator: "equals", value: filterValue }],
            };

            if (values.includes("Complete")) {
                filter.items.push({ id: 2, field: "statusDescription", operator: "equals", value: "Complete" });
            }

            dispatch(setGridFilter(filter));
            setFileName(exportFileName);
            setIsFilterLocked(true);
        },
        [dispatch]
    );

    /**
     * Adds additional filters and locks the filter based on claim and url pathname.
     */
    useEffect(() => {
        const hasAccessToBusinessReviews = AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewBusinessReviews);
        const hasAccessToDepartmentEvaluation = AuthLibrary.AccountSubscriptionHasClaim(
            claimTypes.ViewDepartmentEvaluations
        );

        if (hasAccessToBusinessReviews && location.pathname === educationEventType.BusinessReviews) {
            setFilter(claimTypes.ViewBusinessReviews, "Business Review", exportExcelFileName.businessReviews);
        } else if (hasAccessToDepartmentEvaluation && location.pathname === educationEventType.Evaluation) {
            setFilter(claimTypes.ViewDepartmentEvaluations, "Evaluation", exportExcelFileName.departmentEvaluation);
        } else {
            const filter: GridFilterModel = { items: [] };
            dispatch(setGridFilter(filter));
            setIsFilterLocked(false);
            setFileName(exportExcelFileName.educationEvents);
        }
    }, [location, dispatch, setFilter]);

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridFilter,
        },
        sorting: {
            sortModel: gridSort,
        },
    };

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="education-grid-box">
                    <StripedDataGrid
                        initialState={initialGridState}
                        rows={data}
                        columns={educationsColumn}
                        paginationModel={{ page: 1, pageSize: 10 }}
                        pageSizeOptions={[10]}
                        columnBuffer={15}
                        density="compact"
                        slots={{
                            toolbar: () => CustomToolbar(fileName),
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () =>
                                NoRowsOverlayWithIcon({
                                    primaryText: translations.noMatch,
                                    secondaryText: translations.editYourSearch,
                                }),
                            filterPanel: StyledFilterPanel,
                        }}
                        slotProps={{
                            columnsPanel: { sx: { textTransform: "capitalize" } },
                        }}
                        loading={isLoading}
                        filterModel={gridFilter}
                        onFilterModelChange={(model) => changeGridFilter(model)}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                        columnVisibilityModel={gridColumns}
                        onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
