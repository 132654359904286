import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { srnTypeKeys } from "../config/data";
import { useCallback, useContext, useEffect, useState } from "react";
import { useFetch } from "../services/useFetch";
import { Delete } from "@mui/icons-material";
import { requestConnectCareOrders } from "../services/apiPaths";
import ServiceRequestItemQuantityInput from "../components/serviceRequest/ServiceRequestItemQuantityInput";
import { useTranslation } from "react-i18next";
import { ICustomerContact, IOtherItemListOfItems, IServiceRequestItem } from "../models/serviceRequest/CreateSrnModels";
import { text, widget } from "../theme/colors";
import { StoreState } from "../redux/store";
import { SrnCreationContext } from "../contexts/SrnCreationContext";
import { SrnAddItemGrid } from "../theme/theme";
import { useSelector } from "react-redux";

export const AddOtherItem = () => {
    const { t } = useTranslation();
    const { currentUser } = useSelector((state: StoreState) => state.auth);
    const translations = {
        contactRequired: t("Contact is required."),
        contact: t("Contact"),
        describeProblem: t("Describe Problem"),
        departmentRequired: t("Department is required."),
        delete: t("Delete"),
        department: t("Department"),
        enterPoNumber: t("Enter PO Number"),
        item: t("Item"),
        itemRequired: t("Item is required."),
        maxLengthError: t("Maximum 50 characters."),
        otherItem: t("Other Item"),
        problemMaxLength: t("Maximum 240 Characters."),
    };

    const {
        departmentList,
        setDepartmentList,
        contactList,
        setContactList,
        getServiceRequestContacts,
        getServiceRequestDepartments,
        addOtherItem,
        isLoading,
        updateItem,
        removeItem,
        singleFacility,
    } = useContext(SrnCreationContext);

    const [listOfItems, setListOfItems] = useState<IOtherItemListOfItems[]>([]);
    const { get } = useFetch();

    const getOtherItemListOfItems = useCallback(async () => {
        const uri = `${requestConnectCareOrders.OtherItemListOfItems}`;
        const response = await get<IOtherItemListOfItems[]>(uri);
        if (response) {
            setListOfItems(response ?? []);
        }
    }, [get]);

    const loadOtherItemData = useCallback(async () => {
        const [departments, contacts] = await Promise.all([
            getServiceRequestDepartments(singleFacility?.custAccountId!),
            getServiceRequestContacts(singleFacility?.custAccountId!),
        ]);
        setDepartmentList(departments ?? []);
        setContactList(contacts ?? []);
    }, [
        getServiceRequestContacts,
        getServiceRequestDepartments,
        setContactList,
        setDepartmentList,
        singleFacility?.custAccountId,
    ]);

    useEffect(() => {
        if (!departmentList.length || !contactList.length) {
            loadOtherItemData();
        }
        if (!listOfItems.length) {
            getOtherItemListOfItems();
        }
    }, [loadOtherItemData, departmentList.length, contactList.length, listOfItems.length, getOtherItemListOfItems]);

    const getContactIdDropdownValue = (currentItem: IServiceRequestItem, index: number) => {
        if (currentItem.contactId) {
            return currentItem.contactId;
        }

        let currentUserOption = contactList.find((x) => x.fullName === currentUser?.displayName);
        if (currentUserOption) {
            let updatedItem = { ...currentItem };
            updatedItem.contactId = currentUserOption.contactId;
            updateItem(updatedItem, index);
            return currentUserOption.contactId;
        }

        return 0;
    };

    return (
        <>
            {!isLoading &&
                !!listOfItems.length &&
                addOtherItem?.map((item, index) => (
                    <Box
                        key={item.id}
                        sx={{ margin: 3, border: `1px solid ${text["textgray2"]}` }}>
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{ paddingLeft: 3, color: !item.productType ? widget.border : "initial" }}>
                                {!item.productType ? translations.otherItem : item.productType}
                            </Typography>
                            {!!addOtherItem.length && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                    }}>
                                    <Button
                                        aria-label={translations.delete}
                                        data-testid="removeButton"
                                        variant="text"
                                        size="small"
                                        onClick={() => removeItem(index, srnTypeKeys.Unknown)}>
                                        <Delete />
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Grid
                            container
                            sx={{ paddingLeft: 3 }}>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={1}
                                md={2}
                                sm={6}
                                lg={2}>
                                <ServiceRequestItemQuantityInput
                                    index={index}
                                    item={item}
                                    updateItem={updateItem}
                                />
                            </SrnAddItemGrid>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={2}
                                md={5}
                                sm={6}
                                lg={3}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={item?.inventoryItemId === 0}>
                                    <InputLabel id="item-label">{translations.item}</InputLabel>
                                    <Select
                                        labelId="item-label"
                                        data-testid="item-dropdown-list"
                                        inputProps={{
                                            "data-testid": "item-dropdown",
                                            "aria-labelledby": "item-label",
                                        }}
                                        value={item?.inventoryItemId ?? 0}
                                        label={translations.item}
                                        onChange={(e) => {
                                            let updatedItem: IServiceRequestItem = { ...item };
                                            let selectedItem = listOfItems.find(
                                                (x) => x.relatedInventoryItemId === e.target.value
                                            );
                                            updatedItem.inventoryItemId = +e.target.value;
                                            updatedItem.productType = selectedItem?.itemDescription!;
                                            updateItem(updatedItem, index);
                                        }}>
                                        <MenuItem value={0}>{t("Select Item Type")}</MenuItem>
                                        {listOfItems?.map((list) => (
                                            <MenuItem
                                                value={list.relatedInventoryItemId}
                                                key={list.id}>
                                                {list.itemDescription}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {item.inventoryItemId === 0 && (
                                        <FormHelperText>{translations.itemRequired}</FormHelperText>
                                    )}
                                </FormControl>
                            </SrnAddItemGrid>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={3}
                                md={5}
                                sm={6}
                                lg={3}>
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    error={item.custDeptId === 0}>
                                    <InputLabel id="department-label">{translations.department}</InputLabel>
                                    <Select
                                        data-testid="department-dropdown-list"
                                        inputProps={{
                                            "data-testid": "department-dropdown",
                                            "aria-labelledby": "department-label",
                                        }}
                                        labelId="department-label"
                                        value={item?.custDeptId ?? 0}
                                        label={translations.department}
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.custDeptId = +e.target.value;
                                            updateItem(updatedItem, index);
                                        }}>
                                        <MenuItem value={0}>{t("Select a Department")}</MenuItem>
                                        {departmentList?.map((dept) => (
                                            <MenuItem
                                                key={dept.custDeptId}
                                                value={dept.custDeptId}>
                                                {dept.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {item.custDeptId === 0 && (
                                        <FormHelperText>{translations.departmentRequired}</FormHelperText>
                                    )}
                                </FormControl>
                            </SrnAddItemGrid>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={3}
                                md={6}
                                sm={6}
                                lg={4}>
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    error={getContactIdDropdownValue(item, index) === 0}>
                                    <InputLabel id="contact-label">{translations.contact}</InputLabel>
                                    <Select
                                        data-testid="contact-dropdown-list"
                                        inputProps={{
                                            "data-testid": "contact-dropdown",
                                            "aria-labelledby": "contact-label",
                                        }}
                                        labelId="contact-label"
                                        value={getContactIdDropdownValue(item, index)}
                                        label={translations.contact}
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.contactId = +e.target.value;
                                            updateItem(updatedItem, index);
                                        }}>
                                        <MenuItem value={0}>{t("Select a Contact")}</MenuItem>
                                        {contactList?.map((contact: ICustomerContact) => (
                                            <MenuItem
                                                key={contact.contactId}
                                                value={contact.contactId}>
                                                {contact.fullName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {getContactIdDropdownValue(item, index) === 0 && (
                                        <FormHelperText>{translations.contactRequired}</FormHelperText>
                                    )}
                                </FormControl>
                            </SrnAddItemGrid>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={3}
                                md={6}
                                sm={12}
                                lg={12}>
                                <FormControl
                                    fullWidth
                                    variant="outlined">
                                    <TextField
                                        inputProps={{ "data-testid": "poNumber-input" }}
                                        label={translations.enterPoNumber}
                                        size="small"
                                        error={item.poNumber.length > 50}
                                        helperText={item.poNumber.length > 50 ? translations.maxLengthError : ""}
                                        value={item.poNumber}
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.poNumber = e.target.value;
                                            updateItem(updatedItem, index);
                                        }}
                                    />
                                </FormControl>
                            </SrnAddItemGrid>
                        </Grid>

                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                paddingLeft: 3,
                            }}>
                            <Box sx={{ flexGrow: 5, paddingRight: 2, paddingBottom: 3 }}>
                                <FormControl
                                    fullWidth
                                    variant="outlined">
                                    <TextField
                                        inputProps={{
                                            "data-testid": "describe-problem-input",
                                            "aria-label": `${translations.describeProblem}`,
                                        }}
                                        variant="outlined"
                                        label={translations.describeProblem}
                                        size="small"
                                        multiline
                                        rows={2}
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.describeProblem = e.target.value;
                                            updateItem(updatedItem, index);
                                        }}
                                        error={item.describeProblem.length > 240}
                                        helperText={
                                            item.describeProblem.length > 240 ? translations.problemMaxLength : ""
                                        }
                                        value={item?.describeProblem}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                ))}
        </>
    );
};
