import { useCallback, useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { DeviceTurnTimeRecords } from "../../models/serviceDelivery/DeviceTurnTimeRecords";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareCustomers } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { blue, widget } from "../../theme/colors";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Box, Divider, Typography } from "@mui/material";
import { LineChartData } from "../../models/serviceDelivery/LineChartData";
import ErrorMessage from "./ErrorMessage";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function DeviceTurnTime() {
    const options = useMemo(() => {
        return {
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
            responsive: true,
            maintainAspectRatio: false,
        };
    }, []);

    const initialDataVal = {
        labels: [],
        datasets: [],
    };

    const { t } = useTranslation();
    const { get } = useFetch();
    const [renderChart, setRenderChart] = useState<boolean>(false);
    const [deviceTurnTimeRecord, setDeviceTurnTimeRecord] = useState<DeviceTurnTimeRecords>();
    const [lineChartData, setLineChartData] = useState<LineChartData>(initialDataVal);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);

    const translations = {
        DeviceTurnTimeAverageTurnTime: t("average turn time"),
        DeviceTurnTime: t("device turn time"),
        DeviceTurnTimeDays: t("days"),
        DeviceTurnTimeDepotRepair: t("depot repair"),
        ErrorMessage: t("Data is unavailable or there is not enough data to display this metric."),
    };

    /**
     *  Takes the api response and renders the respone in a chart
     * @param {DeviceTurnTimeRecords} deviceTurnTimeRecord - the typed api reponse.
     */
    const populateLineChartData = useCallback((deviceTurnTimeRecord: DeviceTurnTimeRecords) => {
        const lineChartData: LineChartData = {
            labels: deviceTurnTimeRecord.turnTime.map(() => ""),
            datasets: [
                {
                    data: deviceTurnTimeRecord.turnTime,
                    backgroundColor: widget.blue,
                    borderColor: widget.blue,
                    fill: true,
                    tension: 0.1,
                    borderWidth: 5,
                    label: " ",
                },
            ],
        };
        setDeviceTurnTimeRecord(deviceTurnTimeRecord); //allows us to render the api data in the chart.
        setLineChartData(lineChartData); //Sets the data to be drawn in html
        setRenderChart(true);
    }, []);

    /**
     * Gets the data from the api.
     */
    const getDeviceTurnTimeRecords = useCallback(async () => {
        const response = await get<DeviceTurnTimeRecords>(requestConnectCareCustomers.DeviceTurnTime, true);
        if (response && response.totalTurnTime > 0) {
            populateLineChartData(response);
        } else {
            setRenderChart(false);
        }
    }, [get, populateLineChartData]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getDeviceTurnTimeRecords(); //Get the data from the api.
            setInitialDataLoaded(true); //Once the page loads rely on selectedFacilitiesChanged to update our charts.
        }
    }, [getDeviceTurnTimeRecords, initialDataLoaded]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities]);

    let renderedComponent;
    if (renderChart) {
        renderedComponent = (
            <Box height={"170px"}>
                <Typography
                    component={"p"}
                    variant="overline"
                    textTransform={"uppercase"}
                    color={widget.grey}
                    my={"2px"}>
                    {translations.DeviceTurnTimeDepotRepair}
                </Typography>
                <Line
                    data={lineChartData}
                    options={options}
                />
                <Typography
                    variant="h5"
                    color={widget.darkerblue}
                    mt={"25px"}
                    textTransform={"uppercase"}>
                    {`${deviceTurnTimeRecord?.totalTurnTime} ${translations.DeviceTurnTimeDays}`}
                </Typography>
                <Typography
                    variant="body2"
                    color={widget.lightblue}
                    textTransform={"uppercase"}
                    mt={"8px"}
                    mb={"30px"}>
                    {translations.DeviceTurnTimeAverageTurnTime}
                </Typography>
                <Divider />
            </Box>
        );
    } else {
        renderedComponent = (
            <Box mt={20}>
                <ErrorMessage message={translations.ErrorMessage} />
            </Box>
        );
    }

    return (
        <Box
            data-testid="device-turntime-graph"
            border={1}
            color={blue.lightGrayishBlue}
            textAlign={"center"}
            height={"90%"}
            my={2}
            p={1}>
            <Typography
                variant="detailLabel"
                color={widget.blackishblue}
                mt={"8px"}>
                {translations.DeviceTurnTime}
            </Typography>
            {renderedComponent}
        </Box>
    );
}
