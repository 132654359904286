import { ValidValues } from "./ValidValues";

export interface ReportParameter {
    name: string;
    type: ParameterType;
    typeSpecialField: boolean;
    nullable: string;
    nullableFieldSpecified: boolean;
    allowBlank: boolean;
    allowBlankSpecialField: boolean;
    multiValue: boolean;
    multiValueFieldSpecified: boolean;
    queryParameter: boolean;
    queryParameterFieldSpecified: boolean;
    prompt: string;
    promptUser: boolean;
    promptUserFieldSpecified: boolean;
    dependencies: string[];
    defaultValuesQueryBased: boolean;
    defaultValuesQueryBasedFieldSpecified: boolean;
    defaultValues: string[];
    state: ParameterState;
    stateFieldSpecified: boolean;
    parameterStateName: string;
    parameterTypeName: string;
    errorMessage: string;
    validValues: ValidValues[];
    validValuesQueryBased: boolean;
    validValuesQueryBasedFieldSpecified: boolean;
    value: string;
    isCustomerParameter: boolean;
    selectedValues: string[];
}

export enum ParameterType {
    Boolean = "Boolean",
    DateTime = "DateTime",
    Integer = "Integer",
    Float = "Float",
    String = "String",
}

export enum ParameterState {
    HasValidValue = "HasValidValue",
    MissingValidValue = "MissingValidValue",
    HasOutstandingDependencies = "HasOutstandingDependencies",
    DynamicValuesUnavailable = "DynamicValuesUnavailable",
}
