import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../common/SiteMap";
import { useTranslation } from "react-i18next";
import { ErrorOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function AssetItemNotFound() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translations = {
        documentTitle: t(`${process.env.REACT_APP_STERIS_TITLE} - Item Not In Inventory`),
        pageTitle: t("Item Not Found"),
        message: t("This item is not in your asset inventory."),
        goBack: t("Go Back"),
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <Box bgcolor="bg.white">
            <BreadCrumb breadCrumbs={BreadCrumbList.assetItemNotFound} />
            <Grid
                container
                justifyContent={"center"}
                mt={15}
                px={1}
                pb={2}>
                <Grid
                    item
                    textAlign={"center"}
                    sm={8}
                    md={5}
                    lg={4}
                    xl={3}>
                    <Typography
                        p={0}
                        fontSize={80}
                        fontWeight={200}
                        color={"blue.darker"}>
                        <ErrorOutline
                            color={"inherit"}
                            fontSize={"inherit"}
                            fontWeight={"inherit"}
                        />
                    </Typography>
                    <Typography
                        variant="h1"
                        my={5}
                        fontSize={44}
                        color={"blue.darker"}>
                        {translations.pageTitle}
                    </Typography>
                    <Typography
                        variant="body1"
                        mb={2}
                        mt={4}>
                        {translations.message}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => navigate(-1)}>
                        {translations.goBack}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AssetItemNotFound;
