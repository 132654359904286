import ConfirmationModal from "../../component-library/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useIdleTimerContext } from 'react-idle-timer'
export default function SessionTimeoutDialog({
    open,
    onLogout,
}: {
    open: boolean;
    countdown: number;
    onLogout: () => void;
}) {

    const [remaining, setRemaining] = useState<number>(0);
    const { activate, getRemainingTime } = useIdleTimerContext()

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const { t } = useTranslation();
    const translations = {
        sessionTimeout: t("Session Timeout"),
        sessionTimeoutMessage: t("The current session is about to expire in {{remaining}} seconds. Would you like to continue the session?", { remaining: remaining }),
        noLogOut: t("No, Log out"),
        yesContinue: t("Yes, Continue"),
    };

    return (
        <ConfirmationModal
            show={open}
            title={translations.sessionTimeout}
            contentMessage={`${translations.sessionTimeoutMessage}`}
            secondaryButtonText={translations.noLogOut}
            primaryButtonText={translations.yesContinue}
            onSecondaryButtonClick={onLogout}
            onPrimaryButtonClick={activate}
        />
    );
}