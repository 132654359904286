import { useState, useEffect, useCallback, } from "react";
import { Grid, Typography, Box } from "@mui/material";
import DashboardCard from "./DashboardCard";
import { useNavigate } from "react-router-dom";
import { DashboardCardData } from "../../models/orders/DashboardCardData";
import { useFetch } from "../../services/useFetch";

import { IUserSettings } from "../../models/user/UserSettings";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

interface IDashboardCardSection {
    cardData: DashboardCardData[];
    headerText: string;
    userSettings: IUserSettings;
}

export default function DashboardCardSection({ headerText, cardData, userSettings }: Readonly<IDashboardCardSection>) {
    const navigate = useNavigate();
    const { get } = useFetch();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cards, setCards] = useState<DashboardCardData[]>(cardData);

    /**
     * Asyncronously loads the card data into state the method param, then destructure that into state to be consumed.
     * Toggles loading animation.
     * @params {DashboardCardData} cardMetaData - The list of cards to load
     *
     * This function is really unclear on how it works.
     * 1) async get all the responses, which is just a number.
     * 2) because the responses are pushed into an array in order
     * 3) We can loop through those responses an safely assume the values are in order.
     */
    const loadCardData = useCallback(
        async (cardMetaData: DashboardCardData[]) => {
            setIsLoading(true);

            const promises: Promise<number | void>[] = [];

            for (const card of cardMetaData) {
                const response = get<number>(card.uri, true);
                promises.push(response);
            }
            const responses = await Promise.all(promises);

            responses.forEach((response, i) => {
                cardMetaData[i]!.value = isNaN(Number(response)) ? 0 : Number(response);
            });

            setCards([...cardMetaData.filter((a) => showCard(a.title, a.value, userSettings))]);
            setIsLoading(false);
        },
        [get]
    );

    useEffect(() => {
        if (!initialDataLoaded) {
            loadCardData(cardData);
            setInitialDataLoaded(true);
        }
    }, [loadCardData, cardData, initialDataLoaded]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities]);

    /**
    Hides the dashboard card if {title} is "LOANERS DUE FOR RETURN" and there's no data with it. 
    @param {string} title - The title of the card.
    @param {number} value - The count corresponding to the selected facilties and the card title.
    @returns {boolean} Used for validation.
    */
    const showCard = (title: string, value: number, settings: IUserSettings): boolean => {
        switch (title) {
            case "APPROVAL NEEDED":
                return settings.dashboard?.approvalNeeded.isOn ?? false;
            case "PURCHASE ORDER NUMBER REQUIRED":
                return settings.dashboard?.poRequired.isOn ?? false;
            case "RECOMMENDED FOR SERVICE":
                return settings.dashboard?.recommendedForService.isOn ?? false;
            /**
            Don't show 'LOANERS DUE FOR RETURN' tile, if we don't have any value on that
            */
            case "LOANERS DUE FOR RETURN":
                return (settings.dashboard?.loanersDueForReturn.isOn && value > 0) ?? false;
            case "IN TRANSIT FOR REPAIR":
                return settings.dashboard?.inTransitForReport.isOn ?? false;
            case "IN REPAIR":
                return settings.dashboard?.inRepair.isOn ?? false;
            case "SHIPMENTS FROM STERIS":
                return settings.dashboard?.shipmentsFromSteris.isOn ?? false;
            default:
                return title !== "LOANERS DUE FOR RETURN" || value !== 0;
        }
    };

    return (
        <Box data-testid="dashboard-card-section">
            <Typography
                variant={"subtitle1"}
                color={"font.gray"}
                textTransform={"uppercase"}
                mb={1}>
                {headerText}
            </Typography>
            <Grid
                container
                spacing={2}
                mb={1}>
                {cards.map((card) => (
                    <Grid
                        item
                        key={card.id}
                        xs={6}
                        sm={4}
                        md={4}>
                        <DashboardCard
                            id={card.id}
                            value={card.value}
                            type={card.type}
                            title={card.title}
                            Icon={card.icon}
                            onClick={() => navigate(card.path)}
                            isLoading={isLoading}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
