import { Box, Button, Grid, Typography } from "@mui/material";
import { FormEvent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GridHeader from "../../component-library/GridHeader";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor, theme } from "../../theme/theme";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    selectedDateRange,
    setInitialDataLoaded,
    setSelectedDateRange,
} from "../../redux/reducers/assets/replacedInstrumentsSlice";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { ReplacedInstrument } from "../../models/assets/ReplacedInstrument";
import ReplacedInstrumentsGrid from "../../components/assets/replacedInstruments/ReplacedInstrumentsGrid";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";

export default function ReplacedInstruments() {
    const { t } = useTranslation();
    const { initialDataLoaded, startDate, endDate, isDateRangeValid } = useAppSelector((state) => state.replacedInstruments);
    const dateRange = useAppSelector(selectedDateRange);    
    const dispatch = useAppDispatch();
    
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const { get } = useFetch();
    const translations = {
        replacedInstruments: t("Replaced Instruments"),
        search: t("Search"),
        invalidDate: t("Replaced Instruments End Date must be after Replaced Instruments Start Date"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.replacedInstruments)}`,
    };

    const getReplacedInstruments = useCallback(async (): Promise<void> => {     
        const uri = `${requestConnectCareInventory.GetReplacedInstruments}?from=${startDate}&to=${endDate}`;
        dispatch(dataRequested());
        const response = (await get<ReplacedInstrument[]>(uri, true)) as ReplacedInstrument[];
        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [startDate, endDate, dispatch, get]);

    useEffect(() => {
        if (selectedFacilities.length) {
            dispatch(setInitialDataLoaded(false));
        }
    }, [dispatch, selectedFacilities]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getReplacedInstruments();
            dispatch(setInitialDataLoaded(true));
        }
    }, [dispatch, getReplacedInstruments, initialDataLoaded]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getReplacedInstruments();
    };

    useEffect(() => {
        document.title = translations.documentTitle;
        return () => {
            dispatch(setInitialDataLoaded(false));
        };
    }, [dispatch, translations.documentTitle]);

    return (
        <Box style={GridBackgroundColor}>
            <BreadCrumb breadCrumbs={BreadCrumbList.replacedInstruments} />
            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.replacedInstruments}
                    onFormSubmit={handleSearchSubmit}>
                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <HeaderDateRangePicker
                            dateRange={dateRange}
                            onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                    </Grid>

                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <Button
                            aria-label={translations.search}
                            type="submit"
                            variant="contained"
                            size="small">
                            {translations.search}
                        </Button>
                    </Grid>
                    {!isDateRangeValid && (
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <Typography
                                pl={2}
                                variant="body2"
                                color={"error.dark"}>
                                {translations.invalidDate}
                            </Typography>
                        </Grid>
                    )}
                </GridHeader>

                <Box py={theme.spacing(2)}>
                    <ReplacedInstrumentsGrid />
                </Box>
            </Box>
        </Box>
    );
}
