import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { Alert, Card, CardContent, CardHeader, CardMedia, Grid, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { EstimatePhotoCard } from "../../models/estimate/EstimatePhotoCard";
import { FormatDate } from "../../common/Formatters";
import { MissingPhoto } from "../photos/MissingPhoto";

interface IEstimatePhotosProps {
    lineNumber?: string;
    custAccountId: string;
}

export default function EstimatePhotos(props: Readonly<IEstimatePhotosProps>) {
    const { t } = useTranslation();
    const { get } = useFetch();
    const [estimatePhotoCards, setEstimatePhotoCards] = useState<EstimatePhotoCard[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    /** Sets the UI to display a no photos message if there are no results returned from the api. */
    const [isNoResult, setIsNoResult] = useState<boolean>(false);
    const translations = {
        apiError: t("System Error: API is not available at this time!"),
        photosNotFound: t("No photos found."),
    };
    const getEstimatePhotoCards = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        //If the line number is undefined this query shall fail.
        if (!props.lineNumber || !props.custAccountId) {
            setIsError(true);
            return;
        }
        setIsError(false); //Clear any previous errors.

        const uri = `${requestConnectCareOrders.EstimatePhotos}?lineId=${encodeURIComponent(props.lineNumber)}&custAccountId=${encodeURIComponent(props.custAccountId)}`;
        const response = await get<EstimatePhotoCard[]>(uri, true);
        const result = response as EstimatePhotoCard[];
        if (result?.length) {
            setIsNoResult(false);
            setEstimatePhotoCards(result);
        } else {
            setIsNoResult(true);
        }
        setIsLoading(false);
    }, [props.lineNumber, props.custAccountId, get]);

    useEffect(() => {
        getEstimatePhotoCards();
    }, [getEstimatePhotoCards]);

    return (
        <Box
            data-testid="estimate-photos-tab"
            p={2}
            width="100%"
            height={estimatePhotoCards && estimatePhotoCards.length > 0 ? "575px" : "inherit"}
            display={"flex"}>
            {isLoading && <LinearProgress />}
            {isError && <Alert severity="error">{translations.apiError}</Alert>}
            {isNoResult && <Alert severity="info">{translations.photosNotFound}</Alert>}
            {!isLoading && !isError && !isNoResult && (
                <Grid
                    paddingLeft="20px"
                    paddingTop="20px"
                    paddingBottom="10px"
                    sx={{ flexGrow: 1, overflowY: "auto" }}
                    container
                    spacing={2}
                    data-testid="estimate-photo-card-list">
                    {estimatePhotoCards?.map((photo) => {
                        return (
                            <Card
                                key={`photoCard_${photo.id}`}
                                variant="elevation">
                                <CardHeader
                                    color="text.textdarkgray"
                                    title={FormatDate(photo.creationDate)}
                                />
                                {photo.photoUrl.includes("/images/noimage") && <MissingPhoto />}
                                {!photo.photoUrl.includes("/images/noimage") && (
                                    <CardMedia
                                        height="250px"
                                        data-testid="card-media"
                                        onClick={() => window.open(photo.photoUrl, "_blank", "noopener,noreferrer")}
                                        component="img"
                                        image={photo.photoUrl}
                                    />
                                )}
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        component="h3">
                                        {photo.comments}
                                    </Typography>
                                </CardContent>
                            </Card>
                        );
                    })}
                </Grid>
            )}
        </Box>
    );
}
