import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import getItemDisinfectedValue from "../utils/getItemDisinfectedValue";
import { ItemsDisinfectedOptions } from "../models/serviceRequest/ItemsDisinfectedOptions";
import HoverTooltip from "./HoverTooltip";
import { HoverTooltipText } from "./HoverTooltipData";
import { useTranslation } from "react-i18next";

export interface IItemsDisinfectedControlProps {
    value: boolean | null;
    onChange: (val: boolean | null) => void;
}

const ItemsDisinfectedControl = (props: IItemsDisinfectedControlProps) => {
    const { value, onChange } = props;

    const { t } = useTranslation();
    const translations = {
        itemsDisinfected: t("items disinfected"),
    };

    return (
        <Box
            data-testid={"items-disinfected-control"}
            display={"flex"}
            alignItems={"center"}>
            <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={value === null}>
                <InputLabel id="items-disinfected">
                    <Typography textTransform={"capitalize"}>{translations.itemsDisinfected}</Typography>
                </InputLabel>
                <Select
                    inputProps={{ "data-testid": "items-disinfected-select" }}
                    labelId="items-disinfected-select"
                    value={getItemDisinfectedValue(value)}
                    label={translations.itemsDisinfected}
                    onChange={(e) => {
                        const val = e.target.value;
                        onChange(val === ItemsDisinfectedOptions.Select ? null : val === ItemsDisinfectedOptions.Yes);
                    }}>
                    {Object.keys(ItemsDisinfectedOptions).map((key) => (
                        <MenuItem
                            key={key}
                            value={ItemsDisinfectedOptions[key as keyof typeof ItemsDisinfectedOptions]}>
                            {ItemsDisinfectedOptions[key as keyof typeof ItemsDisinfectedOptions]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <HoverTooltip
                data-testid="items-disinfect-tooltip"
                tooltip={HoverTooltipText.ItemsDisinfected}
                placement="right"
            />
        </Box>
    );
};
export default ItemsDisinfectedControl;
