import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentUser } from "../../models/authorization/CurrentUser";
import { Telemetrics } from "../../models/authorization/Telemetrics";
import { ccLocalstorage } from "../../config/data";
import { AppMenuItemResponse } from "../../models/navigation/AppMenuItemResponse";

export interface AuthState {
    currentUser: CurrentUser | undefined;
    telemetry: Telemetrics | undefined;
    isAuth: boolean | undefined;
    isRefreshingToken: boolean | undefined;
    leftNavigation: AppMenuItemResponse;
}

const initialState: AuthState = {
    currentUser: undefined,
    telemetry: undefined,
    isAuth: !!localStorage.getItem(ccLocalstorage.connectCareAuthToken),
    isRefreshingToken: false,
    leftNavigation: { menuItems: [], statusCode: 0, hasIndirectAccounts: false },
};

export const Auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setTelemetry: (state, action: PayloadAction<Telemetrics>) => {
            const { payload } = action;
            state = { ...state, telemetry: payload };
            return state;
        },
        setCurrentUser: (state, action: PayloadAction<CurrentUser>) => {
            const { payload } = action;
            state = { ...state, currentUser: payload };
            return state;
        },
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state = { ...state, isAuth: payload };
            return state;
        },
        setIsRefreshingToken: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state = { ...state, isRefreshingToken: payload };
            return state;
        },
        setLeftNavigation: (state, action: PayloadAction<AppMenuItemResponse>) => {
            const { payload } = action;
            state = { ...state, leftNavigation: payload };
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCurrentUser, setTelemetry, setIsRefreshingToken, setIsAuth, setLeftNavigation } = Auth.actions;

export default Auth.reducer;
