import { Box, useMediaQuery, useTheme } from "@mui/material";
import OrderList from "../../components/orders/OrderList";
import OrderDetails from "../../components/orders/OrderDetails";
import { Routes, Route } from "react-router";
import Approvals from "./Approvals";
import InRepair from "./InRepair";
import OrderItems from "./OrderItems";
import Loaner from "./Loaner";
import PORequired from "./PORequired";
import Shipped from "./Shipped";
import Transit from "./Transit";
import NotFound from "../notfound/NotFound";
import OnLocationVisits from "./OnLocationVisits";
import { GridBackgroundColor } from "../../theme/theme";
import ServiceRequestDetails from "../../components/serviceRequest/ServiceRequestDetails";
import { EquipmentRequest } from "./EquipmentRequest";
import { AuthorizeRoute } from "../../components/navigation/AuthorizeRoute";

function Orders() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Box
            data-testid={"connect-care-orders-container"}
            style={GridBackgroundColor}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <Routes>
                <Route
                    index
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<OrderList />}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<OrderDetails />}
                        />
                    }
                />
                <Route
                    path="srn/:serviceRequestId"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<ServiceRequestDetails />}
                        />
                    }
                />
                <Route
                    path="orderitems"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<OrderItems />}
                        />
                    }
                />
                <Route
                    path="intransit"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<Transit />}
                        />
                    }
                />
                <Route
                    path="inrepair"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<InRepair />}
                        />
                    }
                />
                <Route
                    path="shipped"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<Shipped />}
                        />
                    }
                />
                <Route
                    path="loaners"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<Loaner />}
                        />
                    }
                />
                <Route
                    path="approvalneeded"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<Approvals />}
                        />
                    }
                />
                <Route
                    path="porequired"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<PORequired />}
                        />
                    }
                />
                <Route
                    path="onlocationvisits"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<OnLocationVisits />}
                        />
                    }
                />
                <Route
                    path="equipmentrequest"
                    element={
                        <AuthorizeRoute
                            menuItemId={200}
                            element={<EquipmentRequest />}
                        />
                    }
                />
                <Route
                    path="*"
                    element={<NotFound />}
                />
            </Routes>
        </Box>
    );
}

export default Orders;
