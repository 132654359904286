import { CircularProgress } from "@mui/material";

interface ITabLoaderProps {
    isLoading: boolean;
    labelText: string;
    isApiError: boolean;
    count: number;
}

/**
 * This generic component is used in MuiTabs to show either Spinner or Count along with Tab Text
 * For e.g., While we're fetching photos count, initially we'll show - Photos ({spinner})
 * and once API responded with corresponding count value, we'll show - Photos ({count value})
 *
 * @param {boolean} isLoading - Indicates API call initiated and waiting for response
 * @param {string} labelText - Text on the Mui Tab
 * @param {boolean} isApiError - API failed or not
 * @param {number} count - API responded count value
 *
 */
export const TabLoader = ({ isLoading, labelText, isApiError, count }: ITabLoaderProps) => {
    const tabCount = () => {
        if (!isApiError) {
            return `${labelText} (${count})`;
        } else return labelText;
    };
    return isLoading ? (
        <>
            {labelText}
            <CircularProgress
                data-testid="tab-spinner"
                size={15}
                sx={{
                    marginLeft: 1,
                }}
            />
        </>
    ) : (
        tabCount()
    );
};
