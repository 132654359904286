import { Box, Button, ListItemIcon, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AppMenuItem } from "../../models/navigation/AppMenuItemResponse";
import { AppMenuListData } from "../../config/menus";

export interface DynamicSideNavButtonProps {
    menuItem: AppMenuItem;
    onClick: (selectedMenuItem: AppMenuItem) => void;
}

export const DynamicSideNavButton = (props: DynamicSideNavButtonProps) => {
    const location = useLocation();
    const { menuItem, onClick } = props;

    const menuData = AppMenuListData.find((x) => x.id === menuItem.id)!;

    const currentNavIncludes = location.pathname.includes(menuData?.nav);
    const childNavSplit = location.pathname.includes(menuData?.nav.split("/")[1]!);

    return (
        <Box>
            <Button
                aria-label={menuItem.name}
                data-testid={`nav-button-${props.menuItem.id}`}
                variant="leftMenu"
                onClick={() => onClick(menuItem)}
                component={menuItem.children ? "div" : RouterLink}
                to={menuItem.children ? undefined : menuData?.nav}
                sx={{
                    borderLeft: currentNavIncludes ? 4 : 0,
                    backgroundColor: currentNavIncludes ? "white" : "",
                    borderColor: currentNavIncludes ? "blue.connectCare2" : "transparent",
                }}>
                <Box>
                    <ListItemIcon
                        sx={{
                            display: "block",
                            textAlign: "center",
                            color: childNavSplit ? "blue.connectCare2" : "",
                        }}>
                        {menuData?.icon}
                    </ListItemIcon>
                    <Typography
                        fontSize="10px"
                        display="block"
                        textAlign="center"
                        sx={{
                            color: childNavSplit ? "blue.connectCare2" : "",
                        }}>
                        {menuItem.name}
                    </Typography>
                </Box>

                <Box>
                    {props.menuItem.children && (
                        <ArrowForwardIosIcon
                            fontSize="small"
                            sx={{
                                color: childNavSplit ? "blue.connectCare2" : "font.gray",
                                marginRight: "-19px",
                            }}
                        />
                    )}
                </Box>
            </Button>
        </Box>
    );
};
