import { Grid, Link, Paper, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InvoiceHeader } from "../../models/invoices/InvoiceAging";
import { FormatDate } from "../../common/Formatters";
import { validators } from "../../utils/validators";
import { useNavigate } from "react-router-dom";
import { StyledTableCellBlack, StyledTableCellGray } from "../../theme/theme";

function InvoiceAgingDetails({ headerLine, }: { headerLine: InvoiceHeader | null | undefined; }) {
    const { t } = useTranslation();
    const translations = {
        PONumber: t("PO Number"),
        instructions: t("Instructions"),
        salesPerson: t("Sales Person"),
        dueDate: t("Due Date"),
        terms: t("Terms"),
        srn: t("SRN"),
        invoiceDate: t("Invoice Date"),
    };

    const navigate = useNavigate();

    return (headerLine && (
        <Grid sx={{ width: "35%" }} padding={3}>
            <TableContainer component={Paper} sx={{ paddingLeft: "32px" }}>
                <Table aria-label="customized table" size="small">
                    <TableBody >
                        <TableRow>
                            <StyledTableCellGray>{translations.PONumber}</StyledTableCellGray>
                            <StyledTableCellBlack>{headerLine?.customerPO}</StyledTableCellBlack>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellGray>{translations.srn}</StyledTableCellGray>
                            <StyledTableCellBlack>
                                {headerLine?.srn != null &&
                                    !validators.srnLink(headerLine.srn) && (
                                        <>{headerLine?.srn}</>
                                    )}
                                {headerLine?.srn != null &&
                                    validators.srnLink(headerLine.srn) && (
                                        <Link style={{ fontWeight: "bold" }}
                                            onClick={() => {
                                                navigate(`/orders/srn/${headerLine?.srn}`);
                                            }}>
                                            {headerLine?.srn}
                                        </Link>
                                    )}
                            </StyledTableCellBlack>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellGray>{translations.invoiceDate}</StyledTableCellGray>
                            <StyledTableCellBlack>{FormatDate(headerLine?.invoiceDate)}</StyledTableCellBlack>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellGray>{translations.dueDate}</StyledTableCellGray>
                            <StyledTableCellBlack>{FormatDate(headerLine?.dueDate)}</StyledTableCellBlack>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellGray>{translations.terms}</StyledTableCellGray>
                            <StyledTableCellBlack>{headerLine?.terms}</StyledTableCellBlack>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellGray>{translations.salesPerson}</StyledTableCellGray>
                            <StyledTableCellBlack>{headerLine?.salesPerson}</StyledTableCellBlack>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellGray>{translations.instructions}</StyledTableCellGray>
                            <StyledTableCellBlack>{headerLine?.instructions}</StyledTableCellBlack>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    ));
}

export default InvoiceAgingDetails;
