/**
 * Adds an empty entry to the beginning of an array if the array has more than one element.
 * @param arr The array to which an empty element needs to be added to
 * @returns array
 */
export function addEmptyEntryIfMultiple<T>(arr: T[]): T[] {

    if (arr.length > 1) {
        const emptyElement: any = { ...arr[0] };
        for (const key in emptyElement) {
            emptyElement[key as keyof typeof emptyElement] = undefined;
        }
        return [emptyElement, ...arr];
    }
    return arr;
}