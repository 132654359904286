import { IServiceRequestItem } from "../models/serviceRequest/CreateSrnModels";
import { srnTypeKeys } from "../config/data";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { AddAssetItem } from "../models/assets/AddAssetItem";
import { MarkAsServicedItem } from "../models/serviceSummary/MarkAsServicedItem";

/**
 * Validators
 */
export const validators = {
    /**
     * Validates an email address on a loose interpretation of an email address.
     * Don't modify this. Regex for email address is silly beyond a basic test.
     * @param {string} emailAddress The email address.
     * @returns {boolean} true if valid.
     */
    email: (emailAddress: string): boolean => {
        const emailValidator = /^\S+@\S+\.\S+$/;
        return emailValidator.test(emailAddress);
    },

    /**
     * Validates an srnNumber begins with a int or the letter R to display a link.
     * @param {string} srnNumber The email address.
     * @returns {boolean} true if valid.
     */
    srnLink: (srnNumber: string): boolean => {
        const srnvalidator = /^[R|\d]\d/;
        return srnvalidator.test(srnNumber);
    },

    /**
     * Check whether to show Not Found page when status is 400 or 404 or 500
     * @param {number} status The API status
     * @returns {boolean} true if status is 400 or 404 or 500 and false if status is not 400 or 404 or 500
     */
    showNotFound: (status: number): boolean => {
        const errorStatuses = [400, 404, 500];
        return errorStatuses.includes(status);
    },

    srnSummary: (srnItem: IServiceRequestItem): boolean => {
        const srnSummaryValidator = !(
            srnItem.contactId === 0 ||
            srnItem.custDeptId === 0 ||
            srnItem.poNumber.length > 50 ||
            srnItem.describeProblem.length > 240 ||
            srnItem.quantity < 1 ||
            srnItem.quantity > 999 ||
            (srnItem.srnType === srnTypeKeys.Nonserialized && srnItem.productType.length === 0) ||
            srnItem.productType.length > 100 ||
            srnItem.serial.length > 50 ||
            srnItem.manufacturer.length > 50 ||
            srnItem.model.length > 100
        );
        return srnSummaryValidator;
    },

    /**
     * Validator for form used when adding an asset to a customers inventory
     * @param {AddAssetItem} newAsset The asset being added
     * @returns {boolean} true if form is valid and false if form is not valid
     */
    addAssetToInventory: (newAsset: AddAssetItem): boolean => {
        const isValid = !(
            !newAsset.selectedItem ||
            newAsset.serial === "" ||
            newAsset.serial.length > 50 ||
            newAsset.customerAccountId === 0 ||
            newAsset.departmentId === 0
        );
        return isValid;
    },

    /**
     * Validator for form used when marking item as serviced
     * @param {MarkAsServicedItem} markAsServiceItem The item being marked as serviced
     * @returns {boolean} true if form is valid and false if form is not valid
     */
    markAsServiced: (markAsServiceItem: MarkAsServicedItem): boolean => {
        const isValid = !(
            markAsServiceItem.externalRepairDate === null ||
            (markAsServiceItem.reference != null && markAsServiceItem.reference.length > 100) ||
            (markAsServiceItem.problem != null && markAsServiceItem.problem.length > 240)
        );
        return isValid;
    },

    /**
     * Check dateRange is valid and From-To date validation.
     * @param {DateRange<Dayjs>} dateRangePickerFromToDate The From and To Date (DateRange)
     * @returns {boolean} return true if valid.
    */
    isDateRangeValid: (dateRangePickerFromToDate: DateRange<Dayjs>): boolean => {
        const fromDate = dateRangePickerFromToDate[0];
        const toDate = dateRangePickerFromToDate[1];

        if (fromDate == null || !fromDate.isValid()) {
            console.error("from date is invalid");
            return false;
        }
        if (toDate == null || !toDate.isValid()) {
            console.error("to date is invalid");
            return false;
        }
        if (fromDate.diff(toDate) > 0) {
            console.error("date range is invalid");
            return false;
        }
        return true;
    },

    /**
     * Validates the PO number.
     * @param {string} inputPONumber The PO number.
     * @returns {boolean} true if valid.
     */
    pONumber: (inputPoNumber: string): boolean => {
        const regexExpression = /^[^&#+°]{1,50}$/;
        return regexExpression.test(inputPoNumber);
    },

    /**
     * Validates the Equipment Form details on submit click.
     * @param {string} customerPONumber The PO number.
     * * @param {number} customerContactId Contact Id.
     * * @param {number} shipToOrgId Shipping Address Id.
     * @returns {boolean} true if valid.
     */
    srnConfirmationEquipmentRequest: (
        customerPONumber?: string,
        customerContactId?: number,
        shipToOrgId?: number,
        poNumberRequired?: boolean
    ): boolean => {
        const isCustomerContactIdValid = customerContactId && customerContactId > 0;
        const isCustomerPONumberValid = poNumberRequired ? customerPONumber && customerPONumber.length <= 50 : true;
        const isShipToOrgIdValid = shipToOrgId && shipToOrgId > 0;

        return !!isCustomerContactIdValid && !!isCustomerPONumberValid && !!isShipToOrgIdValid;
    },
};
