import { Grid } from "@mui/material";
import { InsightMetricsBarChart } from "../../component-library/InsightMetricsBarChart";
import { useTranslation } from "react-i18next";
import { requestConnectCareInventory } from "../../services/apiPaths";

export default function AssetInsights() {
    const { t } = useTranslation();
    const translations = {
        category: t("By Category"),
        department: t("By Department"),
        manufacturer: t("By Manufacturer"),
        chartSubTitle: t("Excluding Case Carts and Instrument Trays"),
        remainingCategories: t("assets are not assigned a category"),
        remainingDepartments: t("assets are not assigned a department"),
        remainingManufacturer: t("assets are not assigned a manufacturer"),
        errorMessage: t("Data is unavailable or there is not enough data to display this metric."),
    };
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            data-testid="assets-metrics-insights">
            <InsightMetricsBarChart
                title={translations.category}
                subtitle={translations.chartSubTitle}
                errorMessage={translations.errorMessage}
                remainingMetricsTitle={translations.remainingCategories}
                apiUrl={requestConnectCareInventory.GetAssetsMetricsByCategory}
                showAll={false}
                includeUnkownItem={false}
            />
            <InsightMetricsBarChart
                title={translations.department}
                errorMessage={translations.errorMessage}
                remainingMetricsTitle={translations.remainingDepartments}
                apiUrl={requestConnectCareInventory.GetAssetsMetricsByDepartment}
                showAll={false}
                includeUnkownItem={false}
            />
            <InsightMetricsBarChart
                title={translations.manufacturer}
                errorMessage={translations.errorMessage}
                remainingMetricsTitle={translations.remainingManufacturer}
                apiUrl={requestConnectCareInventory.GetAssetsMetricsByManufacturer}
                showAll={false}
                includeUnkownItem={true}
            />
        </Grid>
    );
}
