import { Box, Grid, Typography, Alert, CircularProgress, LinearProgress } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Key, Person, Settings } from "@mui/icons-material";
import { UserAccessDetailAndClaims } from "../../models/user/UserAccessDetailAndClaims";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareUsers } from "../../services/apiPaths";
import UserAccessDetailAndClaimsGrid from "./UserAccessDetailAndClaimsGrid";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../common/SiteMap";
import { GridBackgroundColor } from "../../theme/theme";
import { UserAccountDetail } from "../../models/user/UserAccountDetail";
import UserProfile from "./UserProfile";
import { CurrentUser } from "../../models/authorization/CurrentUser";
import { useLocation } from "react-router-dom";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";
import UserSettings from "./UserSettings";
import { IUserSettings, defaultSettings } from "../../models/user/UserSettings";
import { ccLocalstorage } from "../../config/data";

export default function UserDetails() {
    const { t } = useTranslation();
    const location = useLocation();
    const { get } = useFetch();
    const [isUserDetailsLoading, setIsUserDetailsLoading] = useState<boolean>(false);
    const [isAccessDetailAndClaimsLoading, setAccessDetailAndClaimsLoading] = useState<boolean>(false);
    const [userAccessDetailAndClaims, setUserAccessDetailAndClaims] = useState<UserAccessDetailAndClaims | undefined>(
        undefined
    );
    const [userAccountDetail, setUserAccountDetail] = useState<UserAccountDetail | undefined>(undefined);
    const [myProfile, setMyProfile] = useState<CurrentUser>();
    const [userSettings, setUserSettings] = useState<IUserSettings>(defaultSettings);
    const tabIds = { myAccess: "My Access", myProfile: "My Profile", mySettings: "My Settings" };
    const translations = {
        documentTitle: t(`${process.env.REACT_APP_STERIS_TITLE} - User Account Details`),
        myAccess: t("My Access"),
        myProfile: t("My Profile"),
        mySettings: t("My Settings"),
        errorLoadingUserDetails: t("There was a problem loading user details"),
    };

    const tabs: ITab[] = [
        {
            tabId: tabIds.myProfile,
            icon: <Person />,
            children: <>{translations.myProfile}</>,
        },
        {
            tabId: tabIds.myAccess,
            icon: <Key />,
            children: <>{translations.myAccess}</>,
        },
        {
            tabId: tabIds.mySettings,
            icon: <Settings />,
            children: <>{translations.mySettings}</>,
        },
    ];

    /**
     * Below state is to set up the initial tab to show while landing into User Details page
     * if we're redirecting from any other page to this user details page,
     * location.state.tab will decide which tab to show,
     * if location.state.tab doesn't have any value on it, the default configured tab will get loaded
     */
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(
        tabs.find((x) => x.tabId?.toUpperCase() === location.state?.tab?.toUpperCase()) ?? tabs[0]
    );

    const getUserAccessDetails = useCallback(async () => {
        setAccessDetailAndClaimsLoading(true);
        const uri = `${requestConnectCareUsers.GetUserAccessDetails}`;
        const response = await get<UserAccessDetailAndClaims>(uri);
        if (response) {
            setUserAccessDetailAndClaims(response);
        }
        setAccessDetailAndClaimsLoading(false);
    }, [get]);

    const getUserAccountDetails = useCallback(async () => {
        setIsUserDetailsLoading(true);
        const uri = `${requestConnectCareUsers.GetUserAccountDetails}`;
        const response = await get<UserAccountDetail>(uri);
        if (response) {
            setUserAccountDetail(response);
        }
        setIsUserDetailsLoading(false);
    }, [get]);

    const getMyProfile = useCallback(async () => {
        const uri = `${requestConnectCareUsers.GetMyProfile}`;
        const response = await get<CurrentUser>(uri);
        if (response) {
            setMyProfile(response);
        }
    }, [get]);

    const getMySettings = useCallback(async () => {
        const localStorageSettings = localStorage.getItem(ccLocalstorage.connectCareUserSettings);
        if (localStorageSettings === null) {
            const uri = `${requestConnectCareUsers.GetMySettings}`;
            const response = await get<any>(uri);
            if (response) {
                localStorage.setItem(ccLocalstorage.connectCareUserSettings, JSON.stringify(response));
                setUserSettings(response);
            }
        } else {
            setUserSettings(JSON.parse(localStorageSettings));
        }
    }, [get]);

    const getDetails = useCallback(async () => {
        getMyProfile();
        getUserAccountDetails();
    }, [getMyProfile, getUserAccountDetails]);

    useEffect(() => {
        document.title = translations.documentTitle;
        getUserAccountDetails();
        getUserAccessDetails();
        getMyProfile();
        getMySettings();
    }, [translations.documentTitle, getUserAccountDetails, getUserAccessDetails, getMyProfile, getMySettings]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(tabs.find((x) => x.tabId === newValue));
    };

    const userAccountDetailsHeader = (
        <Grid
            paddingLeft={4}
            paddingRight={4}>
            {isUserDetailsLoading && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <CircularProgress />
                </Box>
            )}

            {!isUserDetailsLoading && (
                <Grid
                    container
                    mb={2}>
                    {userAccountDetail && (
                        <Grid
                            container
                            display="flex"
                            justifyContent="between"
                            my={1}>
                            <Grid
                                item
                                xs={12}>
                                <Typography
                                    variant="body2"
                                    color="font.gray"
                                    marginBottom={2}>
                                    {userAccountDetail.email}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <Typography
                                    variant="h1"
                                    color="font.darkBlue"
                                    marginBottom={1}>
                                    {userAccountDetail.firstName} {userAccountDetail.lastName}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <Typography
                                    variant="body2"
                                    color="font.darkBlue"
                                    marginBottom={1}>
                                    {userAccountDetail.role}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {!userAccountDetail && <Alert severity="error">{translations.errorLoadingUserDetails}</Alert>}
                </Grid>
            )}
        </Grid>
    );

    const userAccessDetailsTabs = (
        <Grid data-testid="user-detail-tabs">
            <ConnectCareTabs
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                tabs={tabs}
            />
            {isAccessDetailAndClaimsLoading && currentTab?.tabId === tabIds.myAccess && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <LinearProgress />
                </Box>
            )}
            {userAccessDetailAndClaims && currentTab?.tabId === tabIds.myAccess && (
                <UserAccessDetailAndClaimsGrid userAccessDetailAndClaims={userAccessDetailAndClaims} />
            )}

            {myProfile && currentTab?.tabId === tabIds.myProfile && (
                <UserProfile
                    userProfileDetails={myProfile}
                    getDetails={getDetails}
                />
            )}

            {userSettings && currentTab?.tabId === tabIds.mySettings && (
                <UserSettings
                    getMySettings={getMySettings}
                    settings={userSettings}
                />
            )}

            {!isAccessDetailAndClaimsLoading && !userAccessDetailAndClaims && (
                <Alert severity="error">{translations.errorLoadingUserDetails}</Alert>
            )}
        </Grid>
    );

    return (
        <Box
            style={GridBackgroundColor}
            padding={2}
            overflow={"auto"}>
            <BreadCrumb breadCrumbs={BreadCrumbList.userAccountDetails} />

            <Box
                data-testid="user-account-details"
                bgcolor="bg.light"
                border="1px solid #DFE0EB"
                borderRadius={2}
                paddingTop={"12px"}
                marginTop="20px"
                overflow={"hidden"}>
                {userAccountDetailsHeader}
                {userAccessDetailsTabs}
            </Box>
        </Box>
    );
}
