import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FormEvent, useEffect, useState, useCallback } from "react";
import GridHeader from "../../component-library/GridHeader";
import EducationEventSearch from "./EducationEventSearch";
import EducationGrid from "./EducationGrid";
import { claimTypes } from "../../config/claimTypes";
import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor, InsightGrid, InsightLink, theme } from "../../theme/theme";
import BarChartIcon from "@mui/icons-material/BarChart";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { requestConnectCareEducations } from "../../services/apiPaths";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    setSelectedDateRange,
    selectedDateRange,
} from "../../redux/reducers/education/educationSlice";
import { EducationGridModel } from "../../models/education/Education";
import { useFetch } from "../../services/useFetch";
import { dateRangeUntilNow } from "../../utils/dateRange";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";

export default function EducationList() {
    const dispatch = useAppDispatch();
    const { get } = useFetch();
    const { startDate, endDate, isDateRangeValid, data, searchString, isError } = useAppSelector(
        (state) => state.education
    );
    const dateRange = useAppSelector(selectedDateRange);
    const { t } = useTranslation();
    const hasViewEducationEvents = AuthLibrary.checkClaim(claimTypes.ViewAllEvents);
    const [viewInsights, setViewInsights] = useState<boolean>(false);
    const [showViewInsights, setShowViewInsights] = useState<boolean>(false);

    const translations = {
        backToHomePage: t("DASHBOARD"),
        educationEvents: t("Events"),
        search: t("Search"),
        invalidDate: t("Education Event End Date must be after Education Event Start Date"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.educationEvents)}`,
        viewInsights: "View Insights",
        hideInsights: "Hide Insights",
    };

    const getEducationEvents = useCallback(async (): Promise<void> => {
        let uri = `${requestConnectCareEducations.EducationSearch}?from=${startDate}&to=${endDate}`;
        if (searchString?.length) {
            uri += `&searchstring=${encodeURIComponent(searchString)}`;
        }

        dispatch(dataRequested());
        const response = (await get<EducationGridModel[]>(uri, true)) as EducationGridModel[];

        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [get, dispatch, startDate, endDate, searchString]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isDateRangeValid) {
            getEducationEvents();
        }
    };

    useEffect(() => {
        document.title = translations.documentTitle;
        /**
         * If there are no Education Events with Requested, In Planning, Planned, Scheduled, or Completed status for the last 90 days
         * for the selected Facility, do not show ‘View Insights’ button.
         */
        const filterRange = dateRangeUntilNow(90);
        const fromDate = filterRange[0]!;
        const statusDescriptions = ["Requested", "In-Process", "Planned", "Scheduled", "Complete"];
        /**
         * Getting rows count for the last 90 days that has status as Requested, In Planning, Planned, Scheduled, or Completed
         */
        const count = data.filter(
            (x) =>
                x?.eventDate &&
                new Date(x?.eventDate) > fromDate.toDate() &&
                x?.statusDescription &&
                statusDescriptions.includes(x.statusDescription)
        ).length;
        setShowViewInsights(count > 0);
    }, [translations.documentTitle, viewInsights, data]);

    /**
     * Displays insights link on the grid header
     * @param label The text to display on the link
     * @returns JSX Element for the insights grid.
     * This is disabled for some reason? sx = display:none?
     */
    const insightsGrid = (label: string) => {
        return (
            <InsightGrid
                item
                sx={{ display: "none" }}>
                <BarChartIcon sx={{ color: "blue.connectCare2" }}></BarChartIcon>
                <InsightLink
                    data-testid={"insights-link"}
                    onClick={() => {
                        setViewInsights(!viewInsights);
                    }}>
                    {label}
                </InsightLink>
            </InsightGrid>
        );
    };

    return (
        <Box
            data-testid={"education-list"}
            sx={{ minHeight: "80vh" }}
            style={GridBackgroundColor}>
            <BreadCrumb breadCrumbs={BreadCrumbList.educationEvents} />
            {!hasViewEducationEvents && <NotAuthorizeDisplay />}

            <Box p={theme.spacing(2)}>
                {showViewInsights && viewInsights && (
                    <Box
                        style={GridBackgroundColor}
                        px={2}>
                        <Grid
                            container
                            bgcolor="bg.light"
                            border={1}
                            borderColor="font.gray2"
                            borderRadius={1}
                            p={1}
                            mt={2}
                            display="flex"
                            alignItems="top"
                            flexWrap="wrap"
                            height="30vh">
                            <Grid
                                item
                                md="auto"
                                marginY={1}
                                paddingX={1}>
                                <Typography
                                    variant="h1"
                                    color="font.darkBlue">
                                    {translations.educationEvents}
                                </Typography>
                            </Grid>
                            {insightsGrid(translations.hideInsights)}
                        </Grid>
                    </Box>
                )}
                <GridHeader
                    title={!viewInsights ? translations.educationEvents : ""}
                    onFormSubmit={handleSearchSubmit}>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        marginY={1}
                        paddingX={1}>
                        <EducationEventSearch />
                    </Grid>
                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <HeaderDateRangePicker
                            dateRange={dateRange}
                            onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                    </Grid>

                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <Button
                            data-testid="education-search-button"
                            type="submit"
                            variant="contained"
                            disabled={!isDateRangeValid}
                            size="small">
                            {translations.search}
                        </Button>
                    </Grid>
                    {!isDateRangeValid && (
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <Typography
                                pl={2}
                                variant="body2"
                                color={"error.dark"}>
                                {translations.invalidDate}
                            </Typography>
                        </Grid>
                    )}
                    {!isError && showViewInsights && !viewInsights && insightsGrid(translations.viewInsights)}
                </GridHeader>
                <Box
                    data-testid="education-list-grid"
                    py={theme.spacing(2)}>
                    {!isError && <EducationGrid />}
                </Box>
            </Box>
        </Box>
    );
}
