import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../common/BaseGridState";
import { baseGridInitialState, baseGridReducers } from "./baseGridsSlice";
import { User } from "../../models/admin/User";
import { BaseApiState } from "../common/BaseApiState";
import { setDataRequested, setDataReceivedWithError, setDataLoaded } from "./sharedReducers";

/**
 * @see {@link ../../components/customers/customerDetail/CustomerUserList}
 */
export interface CustomerAdminState extends BaseGridState, BaseApiState<User[]> {}

const initialState: CustomerAdminState = {
    ...baseGridInitialState,
    initialDataLoaded: false,
    isLoading: false,
    isError: false,
    data: [],
};

export const customerAdminSlice = createSlice({
    name: "customerAdmin",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDataRequested,
        setDataReceivedWithError,
        setDataLoaded,
        dataReceived: (state, action: PayloadAction<User[]>) => {
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.data = action.payload;
            state.isLoading = false;
        },
    },
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = customerAdminSlice.actions;

export default customerAdminSlice.reducer;
