import { Close } from "@mui/icons-material";
import { Box, Grid, Divider, Button, Typography, ClickAwayListener, useTheme, useMediaQuery } from "@mui/material";
import TabbedFacilitiesPane from "../facility/TabbedFacilitiesPane";
import FacilitiesPane from "../facility/FacilitiesPane";
import { useTranslation } from "react-i18next";
import { headerHeight, headerHeightMobile } from "../../config/layout";
import { useDispatch, useSelector } from "react-redux";
import { handleFilter, setHasUnsavedChanges } from "../../redux/reducers/facility/facilitySlice";
import { StoreState } from "../../redux/store";

type IProp = {
    title: string;
    setSelectedActionMenuKey: any;
};

function AppSideMenuFacilities({ title, setSelectedActionMenuKey }: IProp) {
    const dispatch = useDispatch();
    const { showTabbedFacility } = useSelector((state: StoreState) => state.facility);
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const topPosition = matches ? headerHeight : headerHeightMobile;
    const translations = {
        close: t("Close Facilites Pane"),
        note: t(
            "Select facilities from either STERIS Services or Other Providers and click apply to update your session."
        ),
    };

    const handleCloseFacilityPane = () => {
        setSelectedActionMenuKey(0);
        dispatch(handleFilter(""));
        dispatch(setHasUnsavedChanges(false));
    };

    return (
        <Grid
            container
            justifyContent="flex-end"
            zIndex={1}
            mt={topPosition}
            position="absolute"
            data-testid="facilities-grid">
            <ClickAwayListener onClickAway={handleCloseFacilityPane}>
                <Grid
                    item
                    px={1}
                    height={`calc(100vh - ${topPosition})`}
                    boxShadow={3}
                    md={3}
                    display="flex"
                    sx={{ backgroundColor: "bg.lightGray", flexFlow: "column" }}>
                    {/* *** Header and close x *** */}
                    <Box
                        pt={2}
                        display="flex"
                        justifyContent="space-between">
                        <Box>
                            <Typography variant="h4">{t(title)}</Typography>
                            {showTabbedFacility && (
                                <Box
                                    fontWeight="light"
                                    margin="10px">
                                    <Typography variant="body2">{translations.note}</Typography>
                                </Box>
                            )}
                        </Box>
                        <Button
                            aria-label={translations.close}
                            data-testid="facilities-grid-close-btn"
                            variant="close"
                            onClick={() => handleCloseFacilityPane()}>
                            <Close />
                        </Button>
                    </Box>

                    <Divider sx={{ mb: 2 }} />

                    <Box
                        data-testid="facilities-pane"
                        overflow="auto">
                        {showTabbedFacility ? (
                            <TabbedFacilitiesPane closeFacilityPane={handleCloseFacilityPane} />
                        ) : (
                            <FacilitiesPane closeFacilityPane={handleCloseFacilityPane} />
                        )}
                    </Box>
                </Grid>
            </ClickAwayListener>
        </Grid>
    );
}

export default AppSideMenuFacilities;
