import { AccountCircle, Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import {
    Box,
    Button,
    Divider,
    Grid,
    Link,
    Typography,
    ClickAwayListener,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { text } from "../../theme/colors";
import { headerHeight, headerHeightMobile } from "../../config/layout";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { UserDetailsMenuData } from "../../config/data";
import { StyledAppSideMenuBox } from "../../theme/theme";

type IProp = {
    title: string;
    setSelectedActionMenuKey: any;
};

function AppSideMenuProfile({ setSelectedActionMenuKey }: Readonly<IProp>) {
    const { currentUser } = useSelector((state: StoreState) => state.auth);
    const { t } = useTranslation();
    const translations = {
        title: t("Profile"),
        close: t("Close Profile Pane"),
        myAccess: t("My Access"),
        mySterisTeam: t("My STERIS Team"),
    };
    const theme = useTheme();
    const navigate = useNavigate();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const topPosition = matches ? headerHeight : headerHeightMobile;
    const handleCloseProfilePane = () => {
        setSelectedActionMenuKey(0);
    };
    const handleUserNameClickEvent = () => {
        navigate(UserDetailsMenuData[0]!.nav, { state: { tab: "myProfile" } });
        setSelectedActionMenuKey(0);
    };

    return (
        <Grid
            container
            justifyContent="flex-end"
            zIndex={1}
            position="absolute"
            mt={topPosition}
            data-testid="contact-us-grid">
            <ClickAwayListener onClickAway={handleCloseProfilePane}>
                <Box
                    px={4}
                    height="104vh"
                    width="26rem"
                    boxShadow={3}
                    display="flex"
                    sx={{ backgroundColor: "bg.lightGray", flexFlow: "column" }}>
                    {/* *** Header and close x *** */}
                    <Box
                        pt={2}
                        display="flex"
                        justifyContent="space-between">
                        <Box>
                            <Typography variant="h4">{translations.title}</Typography>
                        </Box>
                        <Button
                            data-testid="profile-grid-close-btn"
                            aria-label={translations.close}
                            variant="close"
                            onClick={() => handleCloseProfilePane()}>
                            <Close />
                        </Button>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <StyledAppSideMenuBox
                        onClick={handleUserNameClickEvent}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            "&:hover": {
                                color: "white",
                                "& > div > *": {
                                    color: "white",
                                },
                            },
                        }}>
                        <AccountCircle sx={{ color: text.textgray2, fontSize: "40px", mr: 2 }} />
                        <Box>
                            <Typography
                                variant="body1"
                                color="initial">
                                {currentUser?.displayName}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="initial">
                                {currentUser?.email}
                            </Typography>
                        </Box>
                    </StyledAppSideMenuBox>

                    <Divider />
                    <Link
                        component={RouterLink}
                        to={UserDetailsMenuData[0]!.nav}
                        key={UserDetailsMenuData[0]!.id}
                        underline="none"
                        onClick={handleCloseProfilePane}>
                        <StyledAppSideMenuBox>{translations.myAccess}</StyledAppSideMenuBox>
                    </Link>
                    <Divider />
                    <Link
                        component={RouterLink}
                        to={UserDetailsMenuData[1]!.nav}
                        key={UserDetailsMenuData[1]!.id}
                        underline="none"
                        onClick={handleCloseProfilePane}>
                        <StyledAppSideMenuBox>{translations.mySterisTeam}</StyledAppSideMenuBox>
                    </Link>
                    <Divider />

                    {/* ** Manage Account (Link) /  Logout *** */}
                    <Box sx={{ mt: 0 }}>
                        <Box
                            sx={{
                                "&:hover": { backgroundColor: "#3E8EDE", color: "white" },
                                cursor: "pointer",
                                background: "",
                                p: 2,
                                color: "#1976d2",
                            }}
                            onClick={() => {
                                AuthLibrary.appLogout();
                            }}>
                            Logout
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener>
        </Grid>
    );
}

export default AppSideMenuProfile;
