import { Box, Grid, Button, TextField, InputAdornment, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState, useCallback, FormEvent } from "react";
import dayjs from "dayjs";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import GridHeader from "../../component-library/GridHeader";
import { useTranslation } from "react-i18next";
import { FindMyItemTabs, ITab } from "../../component-library/FindMyItemTabs";
import { ItemsInTransitToRepairLab } from "../../models/serviceSummary/ItemsInTransitToRepair";
import { Pending, Build, LocalShipping, Inventory, Search } from "@mui/icons-material";
import { TabLoader } from "../../component-library/TabLoader";
import {
    requestConnectCareItemsInTransitToRepair,
    requestConnectCareOrders,
    requestConnectCareInventory,
} from "../../services/apiPaths";
import { useLocation } from "react-router-dom";
import ItemsInTransitToRepairGrid from "../../components/serviceSummary/ItemsInTransitToRepairGrid";
import ItemsInRepairGrid from "../../components/serviceSummary/ItemsInRepairGrid";
import ShipmentFromSterisGrid from "../../components/serviceSummary/ShipmentFromSterisGrid";
import { FindMyItemAssetsGrid } from "../../components/assets/FindMyItemAssetsGrid";
import { ItemsInRepair } from "../../models/serviceSummary/ItemsInRepair";
import { ShipmentFromSteris } from "../../models/serviceSummary/ShipmentFromSteris";
import { Asset } from "../../models/assets/Asset";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { useFetch } from "../../services/useFetch";
import { GridAreaLayout } from "../../theme/stripedTable";
import { claimTypes } from "../../config/claimTypes";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { AuthLibrary } from "../../redux/actions/AuthRedux";

function FindMyItem() {
    const { searchString } = useSelector((state: StoreState) => state.orderItems);
    const { t } = useTranslation();
    const tabTexts = {
        intransitRepair: "IN TRANSIT FOR REPAIR",
        inRepair: "IN REPAIR",
        shipmentFromSteris: "SHIPMENTS FROM STERIS",
        assets: "ASSETS",
    };
    const location = useLocation();

    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [initialOrderItemsLoaded, setInitialOrderItemsLoaded] = useState<boolean>(false);
    const [isInTransitLoading, setIsInTransitLoading] = useState<boolean>(false);
    const [inTransitCount, setInTransitCount] = useState<number | null>(null);
    const [isInTransitApiError, setIsInTransitApiError] = useState(false);
    const [isInRepairLoading, setIsInRepairLoading] = useState<boolean>(false);
    const [inRepairCount, setInRepairCount] = useState<number | null>(null);
    const [isInRepairApiError, setIsInRepairApiError] = useState(false);
    const [isShipmentFromSterisLoading, setIsShipmentFromSterisLoading] = useState<boolean>(false);
    const [shipmentFromSterisCount, setShipmentFromSterisCount] = useState<number | null>(null);
    const [isShipmentFromSterisApiError, setIsShipmentFromSterisApiError] = useState(false);
    const [isAssetsLoading, setIsAssetsLoading] = useState<boolean>(false);
    const [assetsCount, setAssetsCount] = useState<number | null>(null);
    const [isAssetsApiError, setIsAssetsApiError] = useState(false);
    const [searchText, setSearchText] = useState<string>(searchString ?? ""); //Pull the existing order items search keywords and use that as a default for find my item search
    const [updatedSearchText, setUpdatedSearchText] = useState<string>(searchString ?? "");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [itemsInTransitToRepairData, setItemsInTransitToRepairData] = useState<ItemsInTransitToRepairLab[]>([]);
    const [itemsInRepairData, setItemsInRepairData] = useState<ItemsInRepair[]>([]);
    const [shipmentFromSterisData, setShipmentFromSterisData] = useState<ShipmentFromSteris[]>([]);
    const [assets, setAssets] = useState<Asset[]>([]);
    const defaultFromDateInDays = 30;
    const { get } = useFetch();
    const themew = useTheme();
    const matches = useMediaQuery(themew.breakpoints.up("sm"));
    const translations = {
        documentTitle: t(`${process.env.REACT_APP_STERIS_TITLE} - ${PageTitles.findMyItem}`),
        headerTitle: t("Find My Item"),
        search: t("Search"),
        placeholder: t("Search by SRN, Order #, PO #, Serial, Manufacturer or Model"),
        apiError: t("System Error: API is not available at this time!"),
        noSearchDataFound: t(
            "We found no matches for {{searchText}} for SRN, Order, Serial, PO, Manufacturer or Model.",
            {
                searchText: updatedSearchText,
            }
        ),
    };

    useEffect(() => {
        document.title = translations.documentTitle;
        return () => {};
    }, [translations.documentTitle]);

    /**
     * Below are the 4 Tabs to be displayed in Find My Item Page
     * ConnectCareTabs was not having isDisabledTab parameter, so we created new FindMyItemTabs
     */
    const tabs: ITab[] = [
        {
            tabText: tabTexts.intransitRepair,
            isDisabledTab: isTabDisabled(inTransitCount),
            icon: <Pending />,
            children: (
                <TabLoader
                    isLoading={isInTransitLoading}
                    labelText={tabTexts.intransitRepair}
                    isApiError={isInTransitApiError}
                    count={inTransitCount ?? 0}
                />
            ),
        },
        {
            tabText: tabTexts.inRepair,
            isDisabledTab: isTabDisabled(inRepairCount),
            icon: <Build />,
            children: (
                <TabLoader
                    isLoading={isInRepairLoading}
                    labelText={tabTexts.inRepair}
                    isApiError={isInRepairApiError}
                    count={inRepairCount ?? 0}
                />
            ),
        },
        {
            tabText: tabTexts.shipmentFromSteris,
            isDisabledTab: isTabDisabled(shipmentFromSterisCount),
            icon: <LocalShipping />,
            children: (
                <TabLoader
                    isLoading={isShipmentFromSterisLoading}
                    labelText={tabTexts.shipmentFromSteris}
                    isApiError={isShipmentFromSterisApiError}
                    count={shipmentFromSterisCount ?? 0}
                />
            ),
        },
        {
            tabText: tabTexts.assets,
            isDisabledTab: isTabDisabled(assetsCount),
            icon: <Inventory />,
            children: (
                <TabLoader
                    isLoading={isAssetsLoading}
                    labelText={tabTexts.assets}
                    isApiError={isAssetsApiError}
                    count={assetsCount ?? 0}
                />
            ),
        },
    ];

    /**
     * Based on the count value of the tab, below functions return true or false
     * if tabValueCount is less than or equal to 0 OR if isLoading variable is True - Return True
     * Else false would be returned - indicates that Tab can be clicked.
     */
    function isTabDisabled(tabValueCount: number | null): boolean {
        return tabValueCount === 0 || tabValueCount === null || isLoading;
    }

    /**
     * Below state is to set up the initial tab to show while landing into Find My Item page
     * if we're redirecting from any other page to this Find My Item page,
     * location.state.tab will decide which tab to show,
     * if location.state.tab doesn't have any value on it, the default configured tab will get loaded
     */
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(
        tabs.find((x) => x.tabText?.toUpperCase() === location.state?.tab?.toUpperCase()) ?? tabs[0]
    );

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(tabs.find((x) => x.tabText === newValue));
    };

    /**
     * On Click of Search button, the below function returns the data for In Transit Repair
     * based on data length property, the count beside Tab name gets displayed.
     *  PO#, Order# search doesnt exist in "In Transit Repair API". Need to address in future user stories
     */
    const getItemsInTransitToRepair = useCallback(async () => {
        setIsLoading(true);
        setIsInTransitLoading(true);
        const uri = `${requestConnectCareItemsInTransitToRepair.ItemsInTransitToRepairSearch}?SearchString=${encodeURIComponent(searchText)}`;
        const response = await get<ItemsInTransitToRepairLab[]>(uri, true);
        if (response) {
            setInTransitCount(response?.length);
            setIsInTransitApiError(false);
            setItemsInTransitToRepairData(response);
        } else {
            setIsInTransitApiError(true);
        }
        setIsLoading(false);
        setIsInTransitLoading(false);
    }, [searchText, get]);

    /**
     * On Click of Search button, the below function gets called returns the data for In Repair
     * based on data length property, the count beside Tab name gets displayed.
     * PO# search doesnt exist in "In Repair API". Need to address in future user stories
     */
    const getItemsInRepair = useCallback(async () => {
        setIsLoading(true);
        setIsInRepairLoading(true);
        const uri = `${requestConnectCareOrders.ItemsInRepair}?SearchString=${encodeURIComponent(searchText)}`;
        const response = await get<ItemsInRepair[]>(uri, true);
        if (response) {
            setInRepairCount(response?.length);
            setIsInRepairApiError(false);
            setItemsInRepairData(response);
        } else {
            setIsInRepairApiError(true);
        }
        setIsLoading(false);
        setIsInRepairLoading(false);
    }, [searchText, get]);

    /**
     * The function is triggered whenever user clicks search button and for fetching shipment from steris
     * Currently API doesnt support blank date search. Defaulted to 30 days and can modify in future user stories
     */
    const getShipmentsFromSteris = useCallback(async () => {
        let date = new Date();
        const toDate = dayjs(date);
        const fromDate = dayjs(date.setDate(date.getDate() - defaultFromDateInDays));
        const fDate = dayjs(fromDate, "MM/DD/YYYY").format("YYYY-MM-DD");
        const tDate = dayjs(toDate, "MM/DD/YYYY").format("YYYY-MM-DD");

        setIsLoading(true);
        setIsShipmentFromSterisLoading(true);
        const uri = `${
            requestConnectCareOrders.ShipmentFromSteris
        }?from=${fDate}&to=${tDate}&SearchString=${encodeURIComponent(searchText)}`;
        const response = await get<ShipmentFromSteris[]>(uri, true);
        if (response) {
            setShipmentFromSterisCount(response?.length);
            setIsShipmentFromSterisApiError(false);
            setShipmentFromSterisData(response);
        } else {
            setIsShipmentFromSterisApiError(true);
        }
        setIsLoading(false);
        setIsShipmentFromSterisLoading(false);
    }, [searchText, get]);

    /**
     * The function is triggered whenever user clicks search button and for fetching assets Tab
     * Currently API doesnt Order#, PO# search. Can modify in future user stories
     */
    const getAssetList = useCallback(async () => {
        setIsLoading(true);
        setIsAssetsLoading(true);
        const uri = `${requestConnectCareInventory.AssetList}?searchString=${encodeURIComponent(searchText)}`;
        const response = await get<Asset[]>(uri, true);
        if (response) {
            setAssetsCount(response?.length);
            setIsAssetsApiError(false);
            setAssets(response ?? []);
        } else {
            setIsAssetsApiError(true);
        }
        setIsLoading(false);
        setIsAssetsLoading(false);
    }, [searchText, get]);

    /**
     * The function is triggered whenever user clicks search button and inTransitCount variable gets updated
     * based on count value of each Tab, default tab gets set automatically
     * Index 0 - In Transit Repair, 1 - In Repair, 2 - Shipment From steris, 3- Assets
     */
    const handleTabChangeOnCount = () => {
        if (inTransitCount != null && inTransitCount > 0) {
            setCurrentTab(tabs[0]);
        } else if (inRepairCount != null && inRepairCount > 0) {
            setCurrentTab(tabs[1]);
        } else if (shipmentFromSterisCount != null && shipmentFromSterisCount > 0) {
            setCurrentTab(tabs[2]);
        } else if (assetsCount != null && assetsCount > 0) {
            setCurrentTab(tabs[3]);
        }
    };

    useEffect(() => {
        if (inTransitCount != null && inRepairCount != null && shipmentFromSterisCount != null && assetsCount != null) {
            handleTabChangeOnCount();
        }
    }, [inTransitCount, inRepairCount, shipmentFromSterisCount, assetsCount]);

    /**
     * The function is resets the value of Tab Count on click of search
     * count variable during first load would be -1 which handled by condition to show count as 0
     */
    const resetCounts = () => {
        setInTransitCount(null);
        setInRepairCount(null);
        setShipmentFromSterisCount(null);
        setAssetsCount(null);
    };

    /**
     * check hasSubscriptionAccessToClaim To Avoid 400 Bad Request API Error
     * Before hitting API, Validate if claim Types available for selected facility
     */
    const handleSearchItemSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        loadTabs();
    };

    function loadTabs() {
        resetCounts();
        const hasViewInTransitForRepair = AuthLibrary.hasAnyClaim([claimTypes.ViewInTransitForRepair]);
        const hasViewInRepair = AuthLibrary.hasAnyClaim([claimTypes.ViewInRepair]);
        const hasViewShipmentsFromSteris = AuthLibrary.hasAnyClaim([claimTypes.ViewShipmentsFromSteris]);
        const hasViewAllAssets = AuthLibrary.hasAnyClaim([claimTypes.ViewAllAssets]);
        if (hasViewInTransitForRepair) {
            getItemsInTransitToRepair();
        }
        if (hasViewInRepair) {
            getItemsInRepair();
        }
        if (hasViewShipmentsFromSteris) {
            getShipmentsFromSteris();
        }
        if (hasViewAllAssets) {
            getAssetList();
        }
        setUpdatedSearchText(searchText);
    }

    /**
     * If clicked on Find My Item Button from dashboard
     * On Page Load, trigger Search event with below
     */
    useEffect(() => {
        setInitialOrderItemsLoaded(false);
    }, [selectedFacilities]);

    useEffect(() => {
        if (!initialOrderItemsLoaded) {
            loadTabs();
            setInitialOrderItemsLoaded(true);
        }
    }, [initialOrderItemsLoaded]);

    /**
     * InTransitRepairGrid, ShipmentFromSterisGrid, InRepairGrid
     * In below 3 grids, No rows overlay message to be dynamically passed -TO DO
     */
    const findMyItemTabs = (
        <>
            <FindMyItemTabs
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                tabs={tabs}
            />
            {!!itemsInTransitToRepairData && currentTab?.tabText === tabTexts.intransitRepair && (
                <ItemsInTransitToRepairGrid
                    searchText={updatedSearchText}
                    itemsInTransitToRepair={itemsInTransitToRepairData}
                    loading={isLoading}
                />
            )}
            {!!itemsInRepairData && currentTab?.tabText === tabTexts.inRepair && (
                <ItemsInRepairGrid
                    isPoDisabled={itemsInRepairData.some((item) => item.isIndirectFiltered)}
                    itemsInRepair={itemsInRepairData}
                    searchText={updatedSearchText}
                    loading={isLoading}
                />
            )}
            {!!shipmentFromSterisData && currentTab?.tabText === tabTexts.shipmentFromSteris && (
                <ShipmentFromSterisGrid
                    searchText={updatedSearchText}
                    shipmentFromSteris={shipmentFromSterisData}
                    loading={isLoading}
                />
            )}
            {!!assets && currentTab?.tabText === tabTexts.assets && (
                <FindMyItemAssetsGrid
                    assetData={assets}
                    loading={isLoading}
                    noSearchDataText={translations.noSearchDataFound}
                />
            )}
        </>
    );

    return (
        <Box
            style={GridBackgroundColor}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <BreadCrumb breadCrumbs={BreadCrumbList.findmyItem} />
            <Box
                data-testid="find-my-item"
                p={theme.spacing(2)}>
                <GridHeader
                    title={translations.headerTitle}
                    hasError={isInTransitApiError}
                    errorText={translations.apiError}
                    onFormSubmit={handleSearchItemSubmit}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        marginY={1}
                        paddingX={1}>
                        <TextField
                            inputProps={{
                                "aria-label": `${translations.search}`,
                                "data-testid": "findmy-item-search",
                            }}
                            fullWidth
                            aria-label={translations.search}
                            placeholder={translations.placeholder}
                            size="small"
                            autoComplete="off"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <Button
                            aria-label={translations.search}
                            data-testid="searchBtn"
                            type="submit"
                            variant="contained"
                            size="small"
                            sx={{ marginTop: "5px" }}>
                            {translations.search}
                        </Button>
                    </Grid>
                </GridHeader>
                <GridAreaLayout>{findMyItemTabs}</GridAreaLayout>
            </Box>
        </Box>
    );
}

export default FindMyItem;
