import { Box, Typography } from "@mui/material";

export interface ITextAreaProps {
    color: string;
    label: string;
    value: any;
    valueTextTransform?: "capitalize" | "uppercase" | "lowercase" | "none";
}

export default function TextArea(props: ITextAreaProps) {
    const { color, label, value, valueTextTransform } = props;
    return (
        <Box>
            <Typography
                variant={"detailLabel"}
                color={color}
            >
                {label}
            </Typography>
            <Typography
                variant={"detailValue"}
                component={"p"}
                textTransform={valueTextTransform ?? "capitalize"}
            >
                {value ?? ""}
            </Typography>
        </Box>
    )
}