import { Box, LinearProgress } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import GridHeader from "../../component-library/GridHeader";
import { ServiceRequestCell } from "../../component-library/ServiceRequestCell";
import { LoanersDueProps } from "../../models/serviceSummary/LoanersDueProps";
import { StyledFilterPanel, StripedDataGrid } from "../../theme/stripedTable";
import LoanerReturnButton from "./LoanerReturnButton";
import LoanerReturnDialog from "./LoanerReturnDialog";
import { LoanersDue } from "../../models/serviceSummary/LoanersDue";
import { useFetch } from "../../services/useFetch";
import { LoanerReturnResponse } from "../../models/orders/LoanerReturnResponse";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { LoanerReturnRequest } from "../../models/orders/LoanerReturnRequest";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../common/SiteMap";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { setGridFilter, setGridSort, setGridColumns } from "../../redux/reducers/orders/loanersDueForReturnSlice";

const LoanersDueForReturnGrid = (props: LoanersDueProps) => {
    const { t } = useTranslation();
    const [showLoanerReturnDialog, setShowLoanerReturnDialog] = useState(false);
    const [selectedLoanerDue, setSelectedLoanerDue] = useState<LoanersDue>();
    const [isProcessingReturn, setIsProcessingReturn] = useState(false);
    const dispatch = useDispatch();
    const { postUnique } = useFetch();
    const { gridColumns, gridFilter, gridSort } = useSelector((state: StoreState) => state.loanersDueForReturn);
    const translations = {
        serial: t("Serial"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        category: t("Category"),
        type: t("Type"),
        customer: t("Customer"),
        department: t("Department"),
        reference: t("Reference"),
        purchaseOrder: t("Purchase Order"),
        srn: t("Repair SRN"),
        apiError: t("System Error: API is not available at this time!"),
        title: t("Loaners Due For Return"),
        documentTitle: t(`${process.env.REACT_APP_STERIS_TITLE} - Loaners Due`),
        actions: t("Actions"),
    };
    useEffect(() => {
        document.title = translations.documentTitle;
        return () => {};
    }, [translations.documentTitle]);

    /**
     * Hides the return button column in the column visibility panel
     * @param columns The grid columns.
     * @returns a list of toggleable columns.
     * @see {@link https://mui.com/x/react-data-grid/column-visibility/#customize-the-list-of-columns-in-panel}
     */
    const getTogglableColumns = (columns: GridColDef[]) => {
        // hide the column with field `buttonReturn` from list of togglable columns
        return columns.filter((column) => column.field !== "buttonReturn").map((column) => column.field);
    };

    /**
     * Calls {@link /servicerequest/loaner-return} to setup a return of the loaner.
     * @param {LoanersDue} loanerDue - The loaner that is due.
     * @param {boolean} isDisinfected - Is the loaner disinfected checkbox.
     */
    const handleProcessReturn = async (loanerDue: LoanersDue, isDisinfected: boolean) => {
        const requestBody: LoanerReturnRequest = {
            loaner: loanerDue,
            isDisinfected: isDisinfected,
        };
        setIsProcessingReturn(true);
        const result = await postUnique<LoanerReturnRequest, LoanerReturnResponse>(
            `${requestConnectCareOrders.LoanerReturnPost}`,
            requestBody,
            true,
            () => console.error("Error Occurred")
        ).finally(() => setIsProcessingReturn(false));
        if (result as LoanerReturnResponse) {
            props.handleLoanerChanged(result as LoanerReturnResponse);
        }
    };

    const handleLoanerReturnButtonClicked = (loanerDue: LoanersDue) => {
        setSelectedLoanerDue(loanerDue);
        setShowLoanerReturnDialog(true);
    };

    const handleDialogCancel = () => {
        setShowLoanerReturnDialog(false);
    };

    const columns: GridColDef[] = [
        {
            field: "buttonReturn",
            headerName: translations.actions,
            minWidth: 170,
            flex: 1,
            renderCell: (params) => (
                <LoanerReturnButton
                    loanerReturn={params.row}
                    handleOnClick={handleLoanerReturnButtonClicked}
                />
            ),
            renderHeader: () => translations.actions,
            filterable: false,
            disableExport: true,
            sortable: false,
            hideable: true,
            disableColumnMenu: true,
        },
        {
            field: "serialNumber",
            headerName: translations.serial,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.serial,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacturer,
            minWidth: 180,
            flex: 1,
            renderHeader: () => translations.manufacturer,
        },
        {
            field: "model",
            headerName: translations.model,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.model,
        },
        {
            field: "category",
            headerName: translations.category,
            minWidth: 150,
            flex: 1,
            renderHeader: () => translations.category,
        },
        {
            field: "productType",
            headerName: translations.type,
            minWidth: 250,
            flex: 2,
            renderHeader: () => translations.type,
        },
        {
            field: "customer",
            headerName: translations.customer,
            minWidth: 220,
            flex: 2,
            renderHeader: () => translations.customer,
        },
        {
            field: "departmentDescription",
            headerName: translations.department,
            minWidth: 150,
            flex: 1,
            renderHeader: () => translations.department,
        },
        {
            field: "customerPONumber",
            headerName: translations.purchaseOrder,
            minWidth: 170,
            flex: 1,
            renderHeader: () => translations.purchaseOrder,
        },
        {
            field: "reference",
            headerName: translations.reference,
            minWidth: 160,
            flex: 1,
            renderHeader: () => translations.reference,
        },
        {
            field: "srn",
            headerName: translations.srn,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.srn,
            renderCell: (params: GridRenderCellParams) => <ServiceRequestCell value={params.value} />,
        },
    ];

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridFilter,
        },
        sorting: {
            sortModel: gridSort,
        },
    };

    return (
        <>
            <Box style={GridBackgroundColor}>
                <BreadCrumb breadCrumbs={BreadCrumbList.loaners} />
                <Box p={theme.spacing(2)}>
                    <GridHeader
                        title={translations.title}
                        hasError={props.isError}></GridHeader>

                    <Box
                        py={theme.spacing(2)}
                        data-testid="loaners-due-grid-box"
                        height="67vh"
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}>
                        <StripedDataGrid
                            disableRowSelectionOnClick
                            initialState={initialGridState}
                            rows={props.loanersDue}
                            columns={columns}
                            paginationModel={{ page: 1, pageSize: 10 }}
                            pageSizeOptions={[11]}
                            density="compact"
                            loading={props.isLoading}
                            columnBuffer={11}
                            slots={{
                                toolbar: () => CustomToolbar(exportExcelFileName.loanersDue),
                                loadingOverlay: LinearProgress,
                                filterPanel: StyledFilterPanel,
                            }}
                            slotProps={{
                                columnsPanel: { getTogglableColumns },
                            }}
                            columnVisibilityModel={gridColumns}
                            onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                            onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                            onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                        />
                    </Box>
                </Box>
            </Box>
            {showLoanerReturnDialog && (
                <LoanerReturnDialog
                    data-testid="loaner-return-dialog"
                    show={showLoanerReturnDialog}
                    onCancelClick={handleDialogCancel}
                    onReturnClick={handleProcessReturn}
                    loanerDue={selectedLoanerDue}
                    isProcessingReturn={isProcessingReturn}
                />
            )}
        </>
    );
};

export default LoanersDueForReturnGrid;
