import { Box, Grid, Link, Typography } from "@mui/material";
import { legalData } from "../../config/data";
import { useTranslation } from "react-i18next";
import { text, widget } from "../../theme/colors";

function AppFooter({ isAuth }: { isAuth: boolean }) {
    const { t } = useTranslation();

    const copyrightYear = new Date().getFullYear().toString();

    return (
        <div>
            {!isAuth && (
                <Box
                    display="flex"
                    alignItems="center"
                    textAlign="center"
                    py={6}
                    sx={{ backgroundColor: "bg.white" }}>
                    <img
                        src="/images/Steris-Logo.png"
                        alt="STERIS Corporation logo"
                        width="200"
                    />
                    <Typography
                        fontSize="15px"
                        mt={1}
                        ml={1}
                        color="gray"
                        px="2">
                        {t("sterisFooter")}
                    </Typography>
                </Box>
            )}

            {/**
             * Hiding social data as part of user story #18273
                <Box
                    py={4}
                    px={10}
                    color={widget.white}
                    sx={{ backgroundColor: "#6699CC" }}>
                    <Grid
                        container
                        spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            display="flex"
                            justifyContent="flex-end">
                            <Box>
                                <Box gap={20}>
                                    {socialData.map((s) => (
                                        <AppSocial
                                            key={s.id}
                                            url={s.url}
                                            iconImage={s.iconImage}
                                            label={s.label}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )*/}

            <Grid
                container
                py={1}
                color={widget.white}
                sx={{
                    height: { xs: "100px", md: "50px" },
                    backgroundColor: "#7b7c7c",
                }}>
                <Grid
                    container
                    sx={{ marginX: { md: 10, xs: 0.5 } }}>
                    <Grid
                        item
                        md={5}
                        xs={12}
                        textAlign={"left"}>
                        <Typography
                            variant="body1"
                            textAlign={"left"}>
                            {t(`Copyright ${copyrightYear}, STERIS. All rights reserved.`)}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={7}
                        xs={12}
                        textAlign={"right"}>
                        <Grid
                            container
                            item
                            xs={0}
                            xl={12}>
                            {legalData.map((l, index) => (
                                <Grid
                                    item
                                    key={l.id}
                                    display="flex">
                                    <Link
                                        variant="body1"
                                        color={text.textWhite}
                                        href={l.url}
                                        target="_blank">
                                        {l.label}
                                    </Link>
                                    {index + 1 !== legalData.length && (
                                        <Typography
                                            variant="body1"
                                            color={text.textWhite}
                                            paddingX="10px">
                                            |
                                        </Typography>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default AppFooter;
