export enum OrderStatuses {
    Booked = "BOOKED",
    Cancelled = "CANCELLED",
    Closed = "CLOSED",
    Entered = "ENTERED",
    Invoiced = "INVOICED",
    InvoiceHold = "INVOICE_HOLD",
    Approved = "APPROVED",
    Rejected = "REJECTED"
}
