import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { dateRangeUntilNow } from "../../../utils/dateRange";
import { DateRangeSearch } from "../../../models/IDateRangeSearch";
import { BaseGridState } from "../../common/BaseGridState";
import { BaseApiState } from "../../common/BaseApiState";
import { baseGridInitialState, baseGridReducers } from "../baseGridsSlice";
import { AssetDevicePreventiveMaintenance } from "../../../models/assets/AssetDevicePreventiveMaintenance";
import { RootState } from "../../store";
import {
    setDataLoaded,
    setDataReceivedWithError,
    setDataRequested,
    setDateRange,
    setKeywordSearch,
} from "../sharedReducers";

export interface DevicePreventiveMaintenanceState
    extends DateRangeSearch,
        BaseGridState,
        BaseApiState<AssetDevicePreventiveMaintenance[]> {}

const initDate = dateRangeUntilNow(90);

const initialState: DevicePreventiveMaintenanceState = {
    ...baseGridInitialState,
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
    startDate: initDate[0]!.format("YYYY-MM-DD"),
    endDate: initDate[1]!.format("YYYY-MM-DD"),
    isDateRangeValid: true,
};

export const DevicePreventiveMaintenance = createSlice({
    name: "devicePreventiveMaintenance",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDateRange,
        setDataRequested,
        setDataReceivedWithError,
        setKeywordSearch,
        setDataLoaded,
        dataReceived: (state, action: PayloadAction<AssetDevicePreventiveMaintenance[]>) => {
            state.data = action.payload;
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.isLoading = false;
        },
    },
});

export const getStartDate = (state: RootState) => state.devicePreventiveMaintenance.startDate;
export const getEndDate = (state: RootState) => state.devicePreventiveMaintenance.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

// Action creators are generated for each case reducer function
export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setKeywordSearch: setSearchString,
    setDateRange: setSelectedDateRange,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = DevicePreventiveMaintenance.actions;

export default DevicePreventiveMaintenance.reducer;
