import { Box, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FacilityClassCode } from "../../models/facility/FacilityClassCode";
import AvailableFacilities from "./AvailableFacilities";
import SelectedFacilities from "./SelectedFacilities";
import { handleFilter } from "../../redux/reducers/facility/facilitySlice";
import { FacilityActions } from "../../redux/actions/facility/FacilityActions";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";

export interface IFacilitiesPaneProps {
    facilitiesClass?: FacilityClassCode;
    closeFacilityPane: () => void;
}

export default function FacilitiesPane(props: IFacilitiesPaneProps) {
    const dispatch = useAppDispatch();
    const { selectedTempFacilities } = useAppSelector((state) => state.facility);
    const { closeFacilityPane } = props;
    const { t } = useTranslation();

    const translations = {
        apply: t("Apply"),
        clearAll: t("Clear All"),
        facilitySelectionLimit: t(
            "The number of selected facilities has exceeded the maximum allowed. Please select 50 or less to continue."
        ),
        successToastMessage: "Selected facilities have been successfully saved.",
        errorToastMessage: "There was error saving selected facilities.",
        facilityChangeInProgressMessage: "Updating...",
    };

    useEffect(() => {
        FacilityActions.loadingFacilitiesPane(props.facilitiesClass ?? FacilityClassCode.DIRECT);
    }, [props.facilitiesClass]);

    const handleApplyClick = () => {
        dispatch(FacilityActions.syncFacilities(selectedTempFacilities));
        dispatch(handleFilter("")); //reset the state of the filter.
        closeFacilityPane();
    };

    const onSearchFacility = (value: string) => {
        dispatch(handleFilter(value));
        FacilityActions.loadingAvailableFacilities();
    };

    return (
        <>
            <SelectedFacilities />
            <section data-testid="facilities-pane">
                <Grid
                    container
                    spacing={2}
                    marginTop={0}>
                    <Grid
                        item
                        xs={6}>
                        <Box>
                            <Button
                                aria-label={translations.apply}
                                onClick={handleApplyClick}
                                size="small"
                                variant="contained"
                                disabled={selectedTempFacilities?.length < 1}
                                sx={{ width: 130, padding: "4px", borderRadius: 8 }}>
                                {translations.apply}
                            </Button>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={6}
                        mb={2}>
                        <Box sx={{ backgroundColor: "" }}>
                            <Button
                                aria-label={translations.clearAll}
                                size="small"
                                sx={{ textTransform: "capitalize", float: "right", fontSize: "12px" }}
                                onClick={() => FacilityActions.handleClearAll()}>
                                {translations.clearAll}
                            </Button>
                        </Box>
                    </Grid>
                    {selectedTempFacilities.length >= parseInt(`${process.env.REACT_APP_FACILTY_MAX_SELECTION}`) && (
                        <Typography
                            pl={2}
                            variant="body2"
                            color={"error.dark"}>
                            {translations.facilitySelectionLimit}
                        </Typography>
                    )}
                </Grid>
            </section>
            <Divider sx={{ my: 2 }} />

            {/* *** Search / Filter Box / *** */}
            <section>
                <Grid
                    item
                    xs={12}
                    sx={{ mb: 1 }}>
                    <TextField
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                        label="Start typing Facility Name or Number to filter"
                        id="outlined-size-small"
                        defaultValue=""
                        size="small"
                        onChange={(e) => onSearchFacility(e.target.value)}
                        disabled={
                            selectedTempFacilities.length >= parseInt(`${process.env.REACT_APP_FACILTY_MAX_SELECTION}`)
                        }
                    />
                </Grid>
            </section>

            {/* Available Facility list */}
            <AvailableFacilities />
        </>
    );
}
