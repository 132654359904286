import { createSvgIcon } from '@mui/material/utils';
import { xmlns } from '../svgxmlns';

/**
 * Steris logo.
 * @returns The Steris Logo Icon as a component that renders the SVG.
 * @see /images/SterisLogo.svg
 */
export const SterisLogoIcon = createSvgIcon(
	<svg xmlns={xmlns}
		viewBox="0 0 360.46 213.71"		
		fill="black"
		strokeWidth={0}		
		stroke="currentColor">

		<path d="M52.08,28.3c0,0,0.08-1.61,0.12-3.07c0.04-1.46,0.04-7.44,0.04-7.44s-4.45-5.36-14.47-5.16
	c-9.98,0.2-14.16,5.91-15.33,7.44c-1.08,1.42-3.67,5.99-2.73,12.37s4.79,10.12,10.68,13.9c6.08,3.9,10.33,5.12,14.39,9.18
	c4.06,4.06,3.31,11.39,0.3,14.88c-1.43,1.65-3.45,3.46-7.14,4.25c-3.49,0.75-8.07,0.18-11.9-2.92
	c-5.46-4.42-7.82-12.35-7.82-12.35l-0.27,12.21c0,0,6.28,6.74,17.27,7.13c11,0.39,18.55-8.7,19.54-15.17
	c0.78-5.11,0.44-8.69-2.57-13.18c-1.12-1.66-3.28-3.46-5.5-5.23c-5.12-4.08-17.69-9.19-19.45-14.83
	c-2.58-8.29,3.42-14.22,9.86-14.18c6.94,0.04,11.19,4.73,13.1,7.68C51.46,25.73,52.08,28.3,52.08,28.3z"/>
		<path d="M338.47,28.3c0,0,0.08-1.61,0.12-3.07c0.04-1.46,0.04-7.44,0.04-7.44s-4.45-5.36-14.47-5.16
	c-9.98,0.2-14.16,5.91-15.33,7.44c-1.08,1.42-3.67,5.99-2.73,12.37c0.94,6.38,4.79,10.12,10.69,13.9
	c6.08,3.9,10.33,5.12,14.39,9.18c4.06,4.06,3.31,11.39,0.3,14.88c-1.43,1.65-3.45,3.46-7.14,4.25c-3.49,0.75-8.07,0.18-11.9-2.92
	c-5.46-4.42-7.82-12.35-7.82-12.35l-0.27,12.21c0,0,6.28,6.74,17.28,7.13c11,0.39,18.55-8.7,19.54-15.17
	c0.78-5.11,0.44-8.69-2.57-13.18c-1.12-1.66-3.28-3.46-5.5-5.23c-5.12-4.08-17.69-9.19-19.45-14.83
	c-2.58-8.29,3.42-14.22,9.86-14.18c6.94,0.04,11.19,4.73,13.1,7.68C337.84,25.73,338.47,28.3,338.47,28.3z"/>
		<path d="M71.74,21.01c0,0-0.19-1.67-0.23-2.95c-0.04-1.3,0.1-4.29,0.12-4.65l43.77-0.04c0,0,0.1,3.94,0.1,5.12
	s-0.27,2.44-0.27,2.44s-1.48-2.52-3.31-3.11c-0.83-0.27-4.96-0.39-8.38-0.47c-2.13-0.05-3.98-0.12-4.33-0.12
	c-0.7,0-1.34,0.18-1.38,0.89c0,0-0.18,54.34-0.18,54.89c0,0.55,0.55,4.25,2.73,5.16h-13.8c0,0,2.69-1.22,2.73-5.12
	c0.04-3.9-0.08-54.63-0.08-54.91c0-0.28-0.12-0.71-0.86-0.79c-1.05-0.11-11.89,0.08-13.1,0.39
	C73.52,18.19,71.82,20.58,71.74,21.01z"/>
		<path d="M171.39,20.65c0,0,0.29-3.97,0.04-6.11c-0.12-0.98-0.78-1.14-1.01-1.14c-0.12,0-35.62,0-35.62,0s1.84,1.45,2.75,3.05
	c0.76,1.34,0.68,2.41,0.68,2.76c0,0.34,0.16,20.03,0.16,23.69s-0.03,27.87-0.39,31.32c-0.2,1.89-3,3.94-3,3.94l38.7-0.06
	c0,0,1.93-4.37,1.87-9.81c0,0-2.55,4.86-7.55,5.42c-4.08,0.45-14.06,0.89-20.26-0.3c0,0-1.03-0.04-1.03-0.91
	c0-6.22,0-19.36-0.23-25.9c-0.03-0.91,0.08-1.72,0.9-1.81c1.44-0.16,9.71-0.39,16.07-0.08c1.02,0.05,1.91,1.1,2.85,2.21
	c0,0,0.49-4.53-0.04-8.47c0,0-1.25,1.82-2.46,1.93c-4.15,0.37-16.16,0.24-16.44,0.24c-0.27,0-0.76-0.32-0.84-1.06
	c-0.06-0.59-0.09-20.64-0.08-20.8c0.02-0.41,0.23-1.22,1.05-1.3c0.7-0.07,13.61-0.12,19.15,0.43
	C169.37,18.17,171.39,20.65,171.39,20.65z"/>
		<path d="M195.94,78.16c0,0,2.49-1.66,2.53-3.52c0.04-1.85-0.22-57.03-0.22-57.66c0-0.63,0.05-2.14-3.11-3.56
	c0,0,20.03-0.03,21.05-0.03c1.09,0,19.23,0.59,19.42,15.91c0.07,5.39-3.18,12.95-12.74,16.17c0,0-0.41,0.21-0.41,0.44
	s0.1,0.49,0.2,0.56c0.18,0.12,2.15,1.69,4.72,4.96c2.57,3.27,14.69,22.24,15.21,22.89c0.66,0.83,1.64,2.09,5.42,3.82
	c0,0-2.34,0.35-9.05,0.08c-3.35-0.14-4.92-2.35-6.04-3.94c-1.95-2.76-12.5-20.27-15.25-23.79c-1.72-2.21-3.99-2.22-4.57-2.25
	c-0.39-0.02-4.83-0.06-5.5-0.09c-0.62-0.03-1.23,0.36-1.23,1.39c0,0.75,0.15,24.3,0.19,25.09c0.03,0.51,1.05,2.88,2.61,3.51
	H195.94z M206.24,42.98c0,0.35,0.2,1.51,0.7,1.57c0.5,0.06,6.55,0.32,9.51-0.3c3.39-0.71,11.52-4.7,11.08-15.42
	c-0.17-4.23-1.78-10.93-11.2-12.11c-4.14-0.52-7.98-0.3-9.01-0.27c-1.02,0.03-1.2,0.8-1.17,1.57S206.24,42.98,206.24,42.98z"/>
		<path d="M277.43,78.16c-1.09-0.55-2.35-2.28-2.43-3.07c-0.06-0.59-0.12-55.34,0-57.94c0.12-2.6,2.19-3.77,2.19-3.77l-12.18-0.01
	c0,0,2.03,1.06,2.14,3.66c0.12,2.6,0,57.14,0,57.85c0,0.79-1.25,2.76-2.34,3.31L277.43,78.16z"/>				
		<path d="M233.79,189.53c-0.34,0.03-7.52,0.17-9.24,0.06c-3.04-0.19-5.53-0.76-8.13-1.41
	c-4.61-1.15-6.74-3.42-9.74-4.51c-2.27-0.83-6.61-2.38-16.48-2.12l-20.95,0.01c-9.87-0.26-14.21,1.29-16.48,2.12
	c-3,1.09-5.15,3.35-9.77,4.5c-2.6,0.65-5.06,1.23-8.1,1.42c-1.72,0.11-8.87-0.05-9.21-0.08v-8.16c0.34,0.03,7.49-0.02,9.21-0.13
	c3.04-0.19,5.53-0.54,8.13-1.19c4.61-1.15,6.74-3.64,9.74-4.73c2.27-0.83,6.61-2.38,16.48-2.12l20.95-0.01
	c9.87-0.26,14.21,1.29,16.48,2.12c3,1.09,5.13,3.58,9.74,4.73c2.6,0.65,5.09,0.99,8.13,1.19c1.72,0.11,8.87,0.15,9.21,0.13
	L233.79,189.53z"/>
		<path d="M179.71,188.68l20.81,0.1c4.92,0.51,9.79,1.27,13.75,4.01c5.48,3.45,12.56,4.01,19.51,3.91v8.07
	c-7.56,0.15-15.45-0.51-21.18-5.07c-4.21-2.23-9.13-2.84-14.11-3.05l-18.74,0.05l-18.74-0.05c-4.97,0.2-9.89,0.81-14.11,3.05
	c-5.73,4.57-13.62,5.23-21.18,5.07v-8.07c6.95,0.1,14.03-0.46,19.51-3.91c3.96-2.74,8.83-3.5,13.75-4.01L179.71,188.68
	L179.71,188.68z"/>
		<path d="M191.63,157.83c4.97,0.36,9.84,0.91,13.95,3.25c7.66,5.53,18.29,4.57,28.19,4.72v8.12
	c-10,0.05-20.93,0.91-28.59-4.92c-4.26-2.33-9.29-2.84-14.41-3.04h-22.06c-5.13,0.2-10.15,0.71-14.41,3.04
	c-7.66,5.84-18.6,4.97-28.59,4.92v-8.12c9.89-0.15,20.52,0.81,28.19-4.72c4.11-2.33,8.98-2.89,13.95-3.25H191.63L191.63,157.83z
		M179.71,142.26c8.06-0.03,16.29-0.19,22.84,3.65c4.77,3.45,10.66,4.16,16.75,4.41l14.49,0.05v8.12c-5.58,0-10.83,0.2-16.31,0
	c-5.43-0.41-10.66-1.42-14.92-4.62c-4.62-2.59-10.05-3.2-15.58-3.4l-7.23,0.02l-7.23-0.02c-5.53,0.2-10.96,0.81-15.58,3.4
	c-4.26,3.2-9.49,4.21-14.92,4.62c-5.48,0.2-10.73,0-16.31,0v-8.12l14.49-0.05c6.09-0.25,11.98-0.96,16.75-4.41
	C163.48,142.07,171.65,142.23,179.71,142.26L179.71,142.26z M179.71,126.87c1.4,0.01,2.81,0.04,4.21,0.06
	c5.28,0.36,10.45,1.01,14.82,3.6c4.82,3.6,10.81,4.36,17,4.52l18.04,0.05v8.02l-20.42,0.05c-4.31-0.3-8.42-1.07-12.18-2.79
	c-3.15-2.23-6.6-3.7-10.4-4.36c-3.56-0.73-7.28-0.81-11.03-0.77c-3.75-0.03-7.47,0.04-11.03,0.77c-3.81,0.66-7.26,2.13-10.4,4.36
	c-3.75,1.73-7.87,2.49-12.18,2.79l-20.42-0.05v-8.02l18.04-0.05c6.19-0.15,12.18-0.91,17-4.52c4.36-2.59,9.54-3.25,14.82-3.6
	C176.96,126.9,178.3,126.88,179.71,126.87L179.71,126.87z M180.01,111.7c5.99,0.3,11.77,1.01,16.44,4.41
	c4.72,2.84,10.3,3.55,16.14,3.65l21.18,0.05v8.02H209.6c-4.52-0.36-8.83-1.17-12.58-3.25c-4.88-3.58-11.05-4.79-17.27-4.76
	c-6.22-0.03-12.39,1.17-17.27,4.76c-3.75,2.08-8.07,2.89-12.58,3.25H125.7v-8.02l21.18-0.05c5.84-0.1,11.42-0.81,16.14-3.65
	c4.67-3.4,10.45-4.11,16.44-4.41H180.01L180.01,111.7z"/>
	</svg>,
    "SterisLogo"
)