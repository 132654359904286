import { CheckBoxOutlineBlank } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { text, blue } from "../../theme/colors";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { FacilityActions } from "../../redux/actions/facility/FacilityActions";

export default function AvailableFacilities() {
    const { t } = useTranslation();
    const strings = {
        selectAll: t("Select All"),
        viewAll: t("View All"),
    };
    const { trimmedAvailableFacilities, showViewAll, selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const color =
        selectedFacilities.length >= parseInt(`${process.env.REACT_APP_FACILTY_MAX_SELECTION}`)
            ? text.textgray2
            : text.textgray;
    const textColor =
        selectedFacilities.length >= parseInt(`${process.env.REACT_APP_FACILTY_MAX_SELECTION}`)
            ? text.textgray2
            : blue.darker;

    return (
        trimmedAvailableFacilities && (
            <Box
                mb={2}
                display="flex"
                flexDirection="column">
                {trimmedAvailableFacilities?.map((f: any) => (
                    <Box
                        key={f.accountId}
                        display="flex"
                        alignItems="center"
                        mb={2}>
                        <CheckBoxOutlineBlank
                            sx={{ color: { color }, fontSize: "25px", cursor: "pointer" }}
                            onClick={() => FacilityActions.handleSelect(f.accountId)}
                        />
                        <Typography
                            color={textColor}
                            fontWeight="bold"
                            fontSize="12px"
                            pl={1}>
                            {f.displayName}
                        </Typography>
                    </Box>
                ))}

                {showViewAll && (
                    <Grid
                        item
                        xs={12}>
                        <Box display="flex">
                            <Button
                                aria-label={strings.viewAll}
                                size="small"
                                sx={{ fontSize: "11px", textTransform: "capitalize" }}
                                onClick={() => FacilityActions.handleViewAll()}>
                                {strings.viewAll}
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Box>
        )
    );
}
