import { Card, CardContent, Grid, Typography } from "@mui/material";
import { SystemItem } from "../../../models/assets/SystemItem";
import { useTranslation } from "react-i18next";

const SelectedAssetDetails = ({ item }: { item: SystemItem }) => {
    const { t } = useTranslation();
    const translations = {
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        deviceType: t("Device Type"),
    };

    return (
        <Card data-testid={"asset-detail-card"}>
            <CardContent>
                <Grid
                    container
                    rowSpacing={2}>
                    <Grid
                        item
                        xs={12}>
                        <Typography variant="detailLabel">{translations.manufacturer}</Typography>
                        <Typography variant="detailValue">{item.make}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <Typography variant="detailLabel">{translations.model}</Typography>
                        <Typography variant="detailValue">{item.model}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <Typography variant="detailLabel">{translations.deviceType}</Typography>
                        <Typography variant="detailValue">{item.type}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SelectedAssetDetails;
