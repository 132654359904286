/**
 * When a grid is exported to excel, these are the file names used when downloaded.
 */

const steriscc = "STERIS - ConnectCare -";

const exportExcelFileName = {
    invoices: `${steriscc} Invoices`,
    repairCredits: `${steriscc} Repair Credits`,
    itemsInTransitToRepairLab: `${steriscc} Items in Transit for Repair`,
    itemsInRepair: `${steriscc} Items in Repair`,
    orderItems: `${steriscc} Order Items`,
    onLocationVisit: `${steriscc} On Location Visits`,
    educationEvents: `${steriscc} Education Events`,
    educationInServices: `${steriscc} Education Events - In Service`,
    businessReviews: `${steriscc} Education Events - Business Reviews`,
    departmentEvaluation: `${steriscc} Education Events - Department Evaluations`,
    loanersDue: `${steriscc} Loaners Due for Return`,
    poRequired: `${steriscc} Purchase Orders Required`,
    shipmentsFromSteris: `${steriscc} Shipments from STERIS`,
    approvalNeeded: `${steriscc} Approval Needed`,
    /**
     * Inventory grid export file name.
     */
    customerAccounts: `${steriscc} Customer Accounts`,
    assets: `${steriscc} Assets`,
    dueForPreventiveMaintenance: `${steriscc} Due for Preventive Maintenance`,
    recommendedForService: `${steriscc} Recommended for Service`,
    equipmenetRequests: `${steriscc} Equipment Requests`,
    orders: `${steriscc} Orders`,
    users: `${steriscc} Users`,
    assetHistoryDetails: `${steriscc} Asset History Details`,
    devicePreventiveMaintenance: `${steriscc} Device Preventive Maintenance`,
    customers: `${steriscc} Customers`,
    trayServiced: `${steriscc} Trays Serviced`,
    beyondRepair: `${steriscc} Beyond Repair`,
    replacedInstruments: `${steriscc} Replaced Instruments`,
    customerSubscriptions: `${steriscc} Customer Subscriptions`,
    customerUsers: `${steriscc} Customers (Users)`,
};

export { exportExcelFileName };
