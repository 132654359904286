import { Box } from "@mui/material";
import PurchaseOrdersRequiredGrid from "./PurchaseOrdersRequiredGrid";
import { PurchaseOrdersRequired } from "../../models/serviceSummary/PurchaseOrdersRequired";
import { useTranslation } from "react-i18next";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../common/SiteMap";
import GridHeader from "../../component-library/GridHeader";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../services/useFetch";
import { requestConnectCarePurchaseOrdersRequired } from "../../services/apiPaths";
import { dataReceived, dataReceivedWithError, dataRequested } from "../../redux/reducers/orders/purchaseOrdersSlice";
import { StoreState } from "../../redux/store";

function PurchaseOrdersRequiredList() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const { get } = useFetch();
    const { isError } = useSelector((state: StoreState) => state.purchaseOrders);

    const translations = {
        poRequired: t("Purchase Orders Required"),
    };

    const getPurchaseOrdersRequired = useCallback(async () => {
        const uriPurchaseOrdersRequired = `${requestConnectCarePurchaseOrdersRequired.PurchaseOrdersRequiredSearch}`;
        dispatch(dataRequested());
        const response = await get<PurchaseOrdersRequired[]>(uriPurchaseOrdersRequired, true);
        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError());
        }
    }, [get, dispatch]);

    useEffect(() => {
        if (selectedFacilities.length) {
            getPurchaseOrdersRequired();
        }
    }, [selectedFacilities, getPurchaseOrdersRequired]);

    return (
        <Box style={GridBackgroundColor}>
            <BreadCrumb breadCrumbs={BreadCrumbList.porequired} />
            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.poRequired}
                    hasError={isError}></GridHeader>
                <Box
                    py={theme.spacing(2)}
                    data-testid="po-required-grid-container">
                    <PurchaseOrdersRequiredGrid />
                </Box>
            </Box>
        </Box>
    );
}

export default PurchaseOrdersRequiredList;
