import { useEffect, useState, useCallback } from "react";
import { Box, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, tableCellClasses, Paper, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useFetch } from "../../services/useFetch";
import { EducationDetailTopic } from "../../models/education/Education";
import { claimTypes } from "../../config/claimTypes";
import { requestConnectCareEducations } from "../../services/apiPaths";
import { AuthLibrary } from "../../redux/actions/AuthRedux";

export default function EducationDetailTopics({ educationId }: Readonly<{ educationId: Readonly<number> }>) {
    const { t } = useTranslation();
    const [educationDetailsTopics, setEducationDetailsTopics] = useState<EducationDetailTopic[]>([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hasViewEducationEvents = AuthLibrary.checkClaim(claimTypes.ViewAllEvents);
    const { get } = useFetch();

    const translations = {
        pageName: t("Education"),
        topic: t("Topic"),
        description: t("Description"),
        noTopics: t("There are no topics for this event."),
    };

    const getEducationDetailsTopics = useCallback(async (): Promise<void> => {
        setIsError(false);
        setIsLoading(true);
        const uriEducation = `${requestConnectCareEducations.EducationDetailTopics}?eventId=${educationId}`;

        const response = await get<EducationDetailTopic[]>(uriEducation, true);

        if (response) {
            setEducationDetailsTopics(response);
            setIsLoading(false);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [educationId, get]);

    useEffect(() => {
        if (hasViewEducationEvents) {
            getEducationDetailsTopics();
        }
    }, [hasViewEducationEvents, getEducationDetailsTopics]);

    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            paddingLeft: "80px",
            color: "#8E8E8E",
            lineHeight: "23px",
        },
        [`&.${tableCellClasses.body}`]: {
            width: "20%",
            paddingLeft: "80px",
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#F7F7F7",
            border: "1px solid #DFE0EB",
        },
    }));

    return (
        <Box
            bgcolor="bg.white"
            minHeight="31vh"
            sx={{ width: "100%" }}
            data-testid="education-detail-topics-grid">
            {isLoading && <LinearProgress></LinearProgress>}
            {!isLoading && !isError && educationDetailsTopics && educationDetailsTopics?.length > 0 && (
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell color="{font.gray}">{translations.topic}</StyledTableCell>
                                <StyledTableCell>{translations.description}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {educationDetailsTopics?.map((educationDetailsTopic) => (
                                <StyledTableRow
                                    key={educationDetailsTopic.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <StyledTableCell>{educationDetailsTopic.title}</StyledTableCell>
                                    <StyledTableCell>{educationDetailsTopic.description}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {!isLoading && (!educationDetailsTopics || educationDetailsTopics?.length === 0) && (
                <Grid
                    item
                    xs={12}
                    mx={0}
                    mb={2}
                    color={"font.gray"}
                    padding={4}>
                    <br />
                    {translations.noTopics}
                </Grid>
            )}
        </Box>
    );
}
