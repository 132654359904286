import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { TrayServicedModel } from "../../models/assets/TrayServiced";
import { DateRangeSearch } from "../../models/IDateRangeSearch";
import { KeywordSearch } from "../../models/IKeywordSearch";
import { BaseGridState } from "../common/BaseGridState";
import { BaseApiState } from "../common/BaseApiState";
import { baseGridInitialState, baseGridReducers } from "./baseGridsSlice";
import { dateRangeUntilNow } from "../../utils/dateRange";
import { RootState } from "../store";
import { setDateRange, setDataRequested, setDataReceivedWithError, setKeywordSearch, setDataLoaded } from "./sharedReducers";

export interface TrayServicedState
    extends DateRangeSearch,
        KeywordSearch,
        BaseGridState,
        BaseApiState<TrayServicedModel[]> {}

const initDate = dateRangeUntilNow(90);

const initialState: TrayServicedState = {
    ...baseGridInitialState,
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
    startDate: initDate[0]!.format("YYYY-MM-DD"),
    endDate: initDate[1]!.format("YYYY-MM-DD"),
    isDateRangeValid: true,
};

export const trayServicedSlice = createSlice({
    name: "trayServiced",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDateRange,
        setDataRequested,
        setDataReceivedWithError,
        setKeywordSearch,
        setDataLoaded,
        dataReceived: (state, action: PayloadAction<TrayServicedModel[]>) => {
            state.data = action.payload;
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.isLoading = false;
        },
    },
});
export const getStartDate = (state: RootState) => state.customers.startDate;
export const getEndDate = (state: RootState) => state.customers.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,  
    setDateRange: setSelectedDateRange,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = trayServicedSlice.actions;

export default trayServicedSlice.reducer;
