import { useSelector, useDispatch } from "react-redux";
import { Alert, Snackbar, SnackbarOrigin } from "@mui/material";
import { setToast } from '../redux/reducers/toastSlice'

/**
 * A toast is a alerting system that is a popup. See {@link https://mui.com/material-ui/react-snackbar MUI snackbar}.
 * This alerting system is a mui alert wrapped in a snackbar. See {@link https://mui.com/material-ui/react-alert/ MUI alerts}
 * @returns {SnackbarOrigin} Snackbar with alert based on {ToastTypes}
 */
export default function Toast() {
    const {showToast, toastType, toastMessage} = useSelector((state: any) => state.toast)
    const anchorOrigin: SnackbarOrigin = { vertical: 'top', horizontal: 'center' };
    const autoHideDuration: number | undefined = (toastType === 'success' || toastType === 'info') ? 3000 : undefined;
    const dispatch = useDispatch();
    const handleMessageClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setToast({
            showToast: false,
        }));
    };

    return (
        <Snackbar
            open={showToast}
            onClose={handleMessageClose}
            anchorOrigin={anchorOrigin}
            autoHideDuration={autoHideDuration}
        >
            <Alert
                onClose={handleMessageClose}
                severity={toastType}
                sx={{ width: '100%' }}>
                {toastMessage}
            </Alert>
        </Snackbar>
    )
}