import { Alert, Box, Grid, Typography } from "@mui/material";
import { FormEvent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { GridBackgroundColor } from "../theme/theme";

interface IGridHeader extends PropsWithChildren {
    title?: string;
    subtitle?: string;
    hasError?: boolean;
    errorText?: string;
    onFormSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}

/**
 * Grid header is used as a header on all the grid pages
 * @param {Object} props - The props.
 * @param {string} props.title - The H1 title of the grid. It's a dark blue color and a h1 tag.
 * @param {string} [props.subtitle] - This is a subtitle that can go below the title. It's smaller and a grey color.
 * @param {boolean} [props.hasError] - If the api is in error display an error message.
 * @param {string} [props.errorText] - If you want to display something other than System Error: API is not available at this time!
 * @param {Event} [props.onFormSubmit] - Event handler for when the form is submitted (TBD, there's no form submit button yet...)
 * @param {Object} [props.children] - Children html nodes you wish to display.
 * @returns The grid header component.
 * @description Creates the grid header component html.
 * If props.hasError is true, Display an alert System Error: API is not available at this time! or props.errorText
 * If props.onFormSubmit is set submit the attached form
 * props.title will change the H1 tag on the page.
 */
export default function GridHeader({
    title,
    subtitle,
    hasError,
    errorText,
    onFormSubmit,
    children,
    ...props
}: IGridHeader) {
    const { t } = useTranslation();

    return (
        <Box
            style={GridBackgroundColor}
            {...props}>
            <form onSubmit={onFormSubmit}>
                <Grid
                    container
                    bgcolor="bg.light"
                    border={1}
                    borderColor="font.gray2"
                    borderRadius={1}
                    p={1}
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap">
                    {(title || subtitle) && (
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            {title && (
                                <Typography
                                    variant="h1"
                                    color="font.darkBlue">
                                    {title}
                                </Typography>
                            )}
                            {subtitle && (
                                <Typography
                                    variant="caption"
                                    color="font.darkGray">
                                    {subtitle}
                                </Typography>
                            )}
                        </Grid>
                    )}
                    {children}

                    {hasError && (
                        <Grid
                            data-testid="grid-header-error"
                            item
                            xs={12}>
                            <Alert severity="error">
                                {errorText || t("System Error: API is not available at this time!")}
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </form>
        </Box>
    );
}
