import { Box, Button, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SectionField, SectionFieldValue } from "../../../theme/theme";
import { CustomerAccountDetail, FutureRepairCredit } from "../../../models/customers/CustomerAccountDetail";
import { blue } from "../../../theme/colors";
import { imsportal, requestConnectCareAdmin } from "../../../services/apiPaths";
import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../../services/useFetch";
import { FormatCurrency } from "../../../common/Formatters";

const CustomerDetailGrid = ({ customerDetail }: { customerDetail: CustomerAccountDetail }) => {
    const { t } = useTranslation();
    const translations = {
        physicalLocation: t("physical location"),
        billingAddress: t("billing address"),
        accountManager: t("account manager"),
        viewInPortal: t("view in portal"),
        futureRepairCredits: t("future repair credits"),
    };

    const { get } = useFetch();
    const [creditInfo, setcreditInfo] = useState<FutureRepairCredit>();

    const getFutureRepairs = useCallback(async () => {
        const uri = `${requestConnectCareAdmin.FutureRepairCredit}?customerAccountId=${customerDetail.custAccountId}`;
        const response = await get<FutureRepairCredit>(uri);
        if (response) {
            setcreditInfo(response);
        }
    }, [customerDetail.custAccountId, get]);

    useEffect(() => {
        getFutureRepairs();
    }, [getFutureRepairs]);
    return (
        <Grid
            container
            mb={2}
            sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
            }}>
            <Grid
                item
                md={9}>
                <Stack
                    data-testid="customer-detail-grid"
                    direction={"row"}
                    paddingLeft={8}
                    paddingTop={5}>
                    <Box>
                        <SectionField sx={{ color: blue.darker }}>{translations.physicalLocation + "\n"}</SectionField>
                        <SectionFieldValue>{customerDetail.physicalLocationAddressLine1}</SectionFieldValue>
                        <SectionFieldValue>{customerDetail.physicalLocationAddressLine2}</SectionFieldValue>
                        <SectionFieldValue>
                            {`${customerDetail.physicalLocationAddressLine3} ${customerDetail.physicalLocationAddressLine4}`}
                        </SectionFieldValue>
                    </Box>
                    <Box paddingLeft={8}>
                        <SectionField sx={{ color: blue.darker }}>{translations.billingAddress}</SectionField>
                        <SectionFieldValue>{customerDetail.billingAddressLine1}</SectionFieldValue>
                        <SectionFieldValue>{customerDetail.billingAddressLine2}</SectionFieldValue>
                        <SectionFieldValue>
                            {`${customerDetail.billingAddressLine3} ${customerDetail.billingAddressLine4}`}
                        </SectionFieldValue>
                    </Box>
                    <Box paddingLeft={8}>
                        <SectionField sx={{ color: blue.darker }}>{translations.accountManager}</SectionField>
                        <SectionFieldValue>{customerDetail.accountManager}</SectionFieldValue>
                    </Box>
                    <Box paddingLeft={8}>
                        <SectionField sx={{ color: blue.darker }}>{translations.futureRepairCredits}</SectionField>
                        <SectionFieldValue  sx={{ textAlign: "right" }}>
                            {FormatCurrency(creditInfo?.amountDueRemaining, "USD")}
                        </SectionFieldValue>
                    </Box>
                </Stack>
            </Grid>

            <Grid
                item
                md={3}
                sx={{ paddingTop: 2, paddingLeft: 7 }}>
                <Button
                    aria-label={translations.viewInPortal}
                    data-testid="view-portal"
                    variant="outlined3"
                    onClick={() =>
                        window.open(`${imsportal.CustomerDetail}/${customerDetail.custAccountId}`, "_blank", "noopener,noreferrer")
                    }>
                    {translations.viewInPortal}
                </Button>
            </Grid>
        </Grid>
    );
};

export default CustomerDetailGrid;
