/*
  PageTitle represent the text to be displayed in the breadcrumb
  Path represents the url path that is going be to navigated when clicking the breadcrumb text
*/
export interface BreadCrumbProps {
  pageTitle: string;
  path: string;
}

/*
Collection of each Page Component title
*/
export const PageTitles = {
    sterisTitle: process.env.REACT_APP_STERIS_TITLE!,
    ims: "Instrument Management Services",
    dashboard: "Dashboard",
    orders: "Orders",
    orderItems: "Order Items",
    intransit: "Items in Transit for Repair",
    inrepair: "Items in Repair",
    shipped: "Shipments from STERIS",
    loaners: "Loaners Due For Return",
    equipmentRequests: "Equipment Request",
    approvalneeded: "Estimate Approval Needed",
    porequired: "Purchase Orders Required",
    onLocationVisits: "On Location Visits",
    orderDetails: "Order Details",
    srnDetails: "SRN Details",
    assets: "Assets",
    assetDetails: "Asset Details",
    dueforpm: "Due for Preventive Maintenance",
    recommendedservice: "Recommended For Service",
    devicepm: "Device Preventive Maintenance",
    traysserviced: "Trays Serviced",
    beyondrepair: "Beyond Repair",
    replacedInstruments: "Replaced Instruments",
    inspectionDetails: "Inspection Details",
    inspectionEvent: "Inspection Event",
    invoices: "Invoices",
    mySterisTeam: "My STERIS Team",
    repairCredits: "Repair Credits",
    invoiceDetails: "Invoice Details",
    educationInServices: "Education",
    educationEvents: "Events",
    educationEventDetails: "Event Details",
    reports: "Reports",
    interactiveDashboard: "Interactive Dashboards",
    userAccountDetails: "User Account Details",
    support: "Technical Support",
    newServiceRequest: "New Service Request",
    adminUsers: "Admin Users",
    adminCustomers: "Admin Customers",
    adminUserDetails: "User Details",
    adminCustomerDetails: "Customer Details",
    estimateDetails: "Estimate Details",
    repairhistory: "Repair History",
    equipmentPlannerAEMTray: "Equipment Planner AEM Tray",
    equipmentPlannerAEMDevices: "Equipment Planner AEM Devices",
    profile: "Profile",
    educationEventTitle: "Event Not Found",
    notFound: "Not Found",
    assetItemNotFound: "Item Not Found",
    findMyItem: "Find My Item",
};

const dashboard: BreadCrumbProps = { pageTitle: PageTitles.dashboard, path: "/dashboard" };
const orders: BreadCrumbProps = { pageTitle: PageTitles.orders, path: "/orders" };
const assets: BreadCrumbProps = { pageTitle: PageTitles.assets, path: "/assets" };
const devicepm: BreadCrumbProps = { pageTitle: PageTitles.devicepm, path: "/assets/devicepm" };
const education: BreadCrumbProps = { pageTitle: PageTitles.educationEvents, path: "/events" };
const invoice: BreadCrumbProps = { pageTitle: PageTitles.invoices, path: "/invoices" };
const reports: BreadCrumbProps = { pageTitle: PageTitles.reports, path: "/analytics/reports" };
const adminUser: BreadCrumbProps = { pageTitle: PageTitles.adminUsers, path: "/admin/users" };
const adminCustomer: BreadCrumbProps = { pageTitle: PageTitles.adminCustomers, path: "/admin/customers" };

/*
Collection of breadcrumbs which is getting passed from each of specific Component/UI to 
Common Breadcrumb Component to construct the Mui Breadcrumb dynamically
*/
export const BreadCrumbList = {
  dashboard: [
      { pageTitle: PageTitles.ims, path: "" },
      { pageTitle: PageTitles.dashboard, path: "" },
  ],
  sterisTeam: [dashboard, { pageTitle: PageTitles.mySterisTeam, path: "" }],
  invoices: [dashboard, { pageTitle: PageTitles.invoices, path: "" }],
  invoiceDetails: [dashboard, invoice, { pageTitle: PageTitles.invoiceDetails, path: "" }],
  repairCredits: [dashboard, invoice, { pageTitle: PageTitles.repairCredits, path: "" }],
  educationEvents: [dashboard, { pageTitle: PageTitles.educationEvents, path: "" }],
  educationInServices: [dashboard, education, { pageTitle: PageTitles.educationInServices, path: "" }],
  educationEventDetails: [dashboard, education, { pageTitle: PageTitles.educationEventDetails, path: "" }],
  support: [dashboard, { pageTitle: PageTitles.support, path: "" }],
  newServiceRequest: [dashboard, { pageTitle: PageTitles.newServiceRequest, path: "" }],
  profile: [dashboard, { pageTitle: PageTitles.profile, path: "" }],
  educationEventTitle: [dashboard, { pageTitle: PageTitles.educationEventTitle, path: "" }],
  notFound: [dashboard, { pageTitle: PageTitles.notFound, path: "" }],
  assetItemNotFound: [dashboard, { pageTitle: PageTitles.assetItemNotFound, path: "" }],
  findmyItem: [dashboard,  { pageTitle: PageTitles.findMyItem, path: "" }],

    //Orders
    orders: [dashboard, { pageTitle: PageTitles.orders, path: "" }],
    orderItems: [dashboard, orders, { pageTitle: PageTitles.orderItems, path: "" }],
    intransit: [dashboard, orders, { pageTitle: PageTitles.intransit, path: "" }],
    inrepair: [dashboard, orders, { pageTitle: PageTitles.inrepair, path: "" }],
    shipped: [dashboard, orders, { pageTitle: PageTitles.shipped, path: "" }],
    loaners: [dashboard, orders, { pageTitle: PageTitles.loaners, path: "" }],
    equipmentRequest: [dashboard, orders, { pageTitle: PageTitles.equipmentRequests, path: "" }],
    approvalneeded: [dashboard, orders, { pageTitle: PageTitles.approvalneeded, path: "" }],
    porequired: [dashboard, orders, { pageTitle: PageTitles.porequired, path: "" }],
    onLocationVisits: [dashboard, orders, { pageTitle: PageTitles.onLocationVisits, path: "" }],
    orderDetails: [dashboard, orders, { pageTitle: PageTitles.orderDetails, path: "" }],
    srnDetails: [dashboard, orders, { pageTitle: PageTitles.srnDetails, path: "" }],
    estimateDetails: [dashboard, orders, { pageTitle: PageTitles.estimateDetails, path: "" }],

    //Assets
    assets: [dashboard, { pageTitle: PageTitles.assets, path: "" }],
    assetDetails: [dashboard, assets, { pageTitle: PageTitles.assetDetails, path: "" }],
    dueforpm: [dashboard, assets, { pageTitle: PageTitles.dueforpm, path: "" }],
    recommendedservice: [dashboard, assets, { pageTitle: PageTitles.recommendedservice, path: "" }],
    traysserviced: [dashboard, assets, { pageTitle: PageTitles.traysserviced, path: "" }],
    beyondrepair: [dashboard, assets, { pageTitle: PageTitles.beyondrepair, path: "" }],
    replacedInstruments: [dashboard, assets, { pageTitle: PageTitles.replacedInstruments, path: "" }],
    devicepm: [dashboard, assets, { pageTitle: PageTitles.devicepm, path: "" }],
    inspectionEvent: [dashboard, assets, devicepm, { pageTitle: PageTitles.inspectionEvent, path: "" }],
    inspectionDetails: [dashboard, assets, devicepm],

  //Admin
  adminUsers: [dashboard, { pageTitle: PageTitles.adminUsers, path: "" }],
  adminCustomers: [dashboard, { pageTitle: PageTitles.adminCustomers, path: "" }],
  adminUserDetails: [dashboard, adminUser, { pageTitle: PageTitles.adminUserDetails, path: "" }],
  adminCustomerDetails: [dashboard, adminCustomer, { pageTitle: PageTitles.adminCustomerDetails, path: "" }],

  //Reports
  reports: [dashboard, { pageTitle: PageTitles.reports, path: "" }],
  interactiveDashboard: [dashboard, { pageTitle: PageTitles.interactiveDashboard, path: "" }],
  repairhistory: [dashboard, reports, { pageTitle: PageTitles.repairhistory, path: "" }],
  equipmentPlannerAEMTray: [dashboard, reports, { pageTitle: PageTitles.equipmentPlannerAEMTray, path: "" }],
  equipmentPlannerAEMDevices: [dashboard, reports, { pageTitle: PageTitles.equipmentPlannerAEMDevices, path: "" }],

  //Users
  userAccountDetails: [dashboard, { pageTitle: PageTitles.userAccountDetails, path: "" }],
};