import { Alert, Box, Grid, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormatDate } from "../../common/Formatters";
import { useCallback, useEffect, useState } from "react";
import { AssetNote } from "../../models/assets/AssetNote";
import { ccLocalstorage } from "../../config/data";
import { requestConnectCareInventory } from "../../services/apiPaths";

import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

const AssetNotes = ({
    customerAccountId,
    serialNumber,
    inventoryItemId,
}: {
    customerAccountId?: string;
    serialNumber?: string;
    inventoryItemId?: string;
}) => {
    const { t } = useTranslation();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [assetNotes, setAssetNotes] = useState<AssetNote[]>();
    const [responseStatusCode, setResponseStatusCode] = useState(1);
    const translations = {
        id: t("Id"),
        date: t("Date"),
        note: t("Note"),
        apiError: t("System Error: API is not available at this time!"),
        noNotes: t("There are currently no Notes to display"),
    };

    const getAssetNote = useCallback(() => {
        setIsError(false);
        setIsLoading(true);
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareInventory.AssetNotes}?customerAccountId=${customerAccountId}&inventoryItemId=${inventoryItemId}&serialNumber=${encodeURIComponent(serialNumber ?? "")}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };
        fetch(uri, requestOptions)
            .then((response) => {
                setResponseStatusCode(response.status);
                return response.ok ? response.json() : [];
            })
            .then((data) => {
                setAssetNotes(data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsError(true);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [inventoryItemId, selectedFacilities]);

    useEffect(() => {
        getAssetNote();
    }, [getAssetNote]);

    return (
        <Box
            data-testid="asset-notes"
            sx={{ width: "100%" }}>
            {isLoading && <LinearProgress></LinearProgress>}
            {!isLoading && responseStatusCode === 401 && <NotAuthorizeDisplay />}
            {!isLoading && responseStatusCode === 200 && assetNotes && assetNotes?.length > 0 && (
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <colgroup>
                            <col width="1%" />
                            <col width="99%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{translations.date}</StyledTableCell>
                                <StyledTableCell sx={{ paddingLeft: "60px !important" }}>
                                    {translations.note}
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetNotes &&
                                assetNotes.map((assetNote) => (
                                    <StyledTableRow key={assetNote.id}>
                                        <StyledTableCell sx={{ paddingLeft: "80px !important" }}>
                                            {FormatDate(assetNote.date)}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ paddingLeft: "60px !important" }}>
                                            {assetNote.note}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {!isLoading && responseStatusCode === 200 && (!assetNotes || assetNotes?.length === 0) && (
                <Grid
                    item
                    xs={12}
                    mx={0}
                    mb={2}>
                    <br />
                    <Alert severity="info">{translations.noNotes}</Alert>
                </Grid>
            )}
            {!isLoading && (isError || responseStatusCode === 404 || responseStatusCode === 500) && (
                <Grid
                    item
                    xs={12}
                    mx={0}
                    mb={2}>
                    <br />
                    <Alert severity="error">{translations.apiError}</Alert>
                </Grid>
            )}
        </Box>
    );
};

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        paddingLeft: "80px",
        color: "#8E8E8E",
        lineHeight: "23px",
    },
    [`&.${tableCellClasses.body}`]: {
        width: "20%",
        paddingLeft: "80px",
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#F7F7F7",
        border: "1px solid #DFE0EB",
    },
}));

export default AssetNotes;
