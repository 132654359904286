import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ReportParameters from "../../components/reports/ReportParameters";
import { BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { useEffect, useState } from "react";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { useTranslation } from "react-i18next";
import { SsrsReport } from "../../models/reports/SsrsReport";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { claimTypes } from "../../config/claimTypes";
import NotAuthorizeDisplay from "../../components/auth/NotAuthorizeDisplay";

const ReportParameter = () => {
    const { t } = useTranslation();
    const [reportId, setReportId] = useState<SsrsReport>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hasViewEquipmentPlannerAEMTrayReport = AuthLibrary.HasSubscriptionAccessToClaim(
        claimTypes.ViewEquipmentPlannerAEMTrayReport
    );
    const params = useParams<{ name: string }>();

    const renderReportBreadcrumb = (param?: string) => {
        switch (param) {
            case SsrsReport[SsrsReport.repairHistory]:
                return BreadCrumbList.repairhistory; //Repair History
            case SsrsReport[SsrsReport.equipmentPlannerAemTray]:
                return BreadCrumbList.equipmentPlannerAEMTray; //Equipment Planner AEM Tray
            case SsrsReport[SsrsReport.equipmentPlannerAemDevices]:
                return BreadCrumbList.equipmentPlannerAEMDevices; //Equipment Planner AEM Devices
            default:
                return BreadCrumbList.reports;
        }
    };

    /**
     * Get the report id for a given report name parameter
     * @param reportName
     */
    const getReportId = (reportName?: string) => {
        switch (reportName?.toLowerCase()) {
            case SsrsReport[SsrsReport.repairHistory].toLowerCase():
                return SsrsReport.repairHistory;
            case SsrsReport[SsrsReport.equipmentPlannerAemTray].toLowerCase():
                return SsrsReport.equipmentPlannerAemTray;
            case SsrsReport[SsrsReport.equipmentPlannerAemDevices].toLowerCase():
                return SsrsReport.equipmentPlannerAemDevices;
            case SsrsReport[SsrsReport.PmEventSummary].toLowerCase():
                return SsrsReport.PmEventSummary;
            case SsrsReport[SsrsReport.PmEventDetails].toLowerCase():
                return SsrsReport.PmEventDetails;
            default:
                return undefined;
        }
    };

    useEffect(() => {
        if (params.name) {
            const repId = getReportId(params.name);
            if (repId === undefined) {
                dispatch(
                    setToast({
                        showToast: true,
                        toastType: ToastTypes.Error,
                        toastMessage: t("Report not found"),
                    })
                );
                navigate("/reports");
            }
            setReportId(repId);
        }
    }, [dispatch, navigate, params.name, t]);

    return (
        reportId && (
            <Box
                id="ConnectCareReportParameters"
                data-testid="ConnectCareReportParameter">
                {/* *** Bread Crumbs *** */}
                <BreadCrumb breadCrumbs={renderReportBreadcrumb(params.name)} />

                {/* * ** Page Body *** */}
                {params.name === SsrsReport[SsrsReport.equipmentPlannerAemTray] &&
                    !hasViewEquipmentPlannerAEMTrayReport && <NotAuthorizeDisplay />}
                {((params.name === SsrsReport[SsrsReport.equipmentPlannerAemTray] &&
                    hasViewEquipmentPlannerAEMTrayReport) ||
                    params.name !== SsrsReport[SsrsReport.equipmentPlannerAemTray]) && (
                    <Box id="ConnectCareReports">
                        <ReportParameters reportId={reportId} />
                    </Box>
                )}
            </Box>
        )
    );
};

export default ReportParameter;
