import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from "@mui/material";
import { blue, text, widget } from "../theme/colors";
import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../services/useFetch";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../components/dashboard/ErrorMessage";

export interface IProps {
    title: string;
    subtitle?: string;
    errorMessage: string;
    apiUrl: string;
    navigateUrl?: string;
}

export const InsightMetricsWithCount = (props: Readonly<IProps>) => {
    const { get } = useFetch();
    const [insightMetricsCount, setInsightMetricsCount] = useState<number>(0);
    const [isStatusError, setIsStatusError] = useState(false);
    const [isStatusLoading, setIsStatusLoading] = useState(true);
    const [renderMetrics, setRenderMetrics] = useState<boolean>(true);
    const navigate = useNavigate();

    const getInsightMetricsCount = useCallback(async () => {
        setIsStatusError(false);
        setIsStatusLoading(true);
        const response = await get<number>(props.apiUrl, true, () => {
            setIsStatusError(true);
        });
        if (!isStatusError) {
            setInsightMetricsCount(response as number);
            setRenderMetrics(true);
        }
        setIsStatusLoading(false);
    }, [get, isStatusError, props.apiUrl]);

    useEffect(() => {
        getInsightMetricsCount();
    }, [getInsightMetricsCount]);

    return (
        <Box
            data-testid={"insight-metrics-count"}
            textAlign={"left"}
            minWidth={"300px"}
            sx={{ marginLeft: "20px" }}
            my={2}
            p={1}>
            {isStatusLoading && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <CircularProgress />
                </Box>
            )}

            {isStatusError && (
                <Box mt={5}>
                    <ErrorMessage message={props.errorMessage} />
                </Box>
            )}
            {renderMetrics && !isStatusLoading && !isStatusError && insightMetricsCount >= 0 && (
                <Grid
                    data-testid="insight-metrics-count-grid"
                    container
                    textAlign={"center"}
                    my={2}>
                    <Grid
                        item
                        xs>
                        <Card
                            key="insight-metrics-count-card"
                            sx={{ border: "1px solid " + blue.lightGrayishBlue, boxShadow: 0 }}>
                            <CardHeader
                                sx={{ display: "flex", justifyContent: "center" }}
                                title={
                                    <Typography
                                        variant="detailLabel"
                                        color={widget.blackishblue}
                                        mt={"8px"}>
                                        {props.title}
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography
                                        sx={{ color: text.materialLightRed }}
                                        variant="dbcCount">
                                        {insightMetricsCount}
                                    </Typography>
                                </Box>
                                {props.navigateUrl && (
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            sx={{ color: text.materialLightRed, borderColor: text.materialLightRed }}
                                            aria-label={props.subtitle}
                                            data-testid="insight-metrics-count-view"
                                            variant="outlined2"
                                            onClick={() => {
                                                navigate(props.navigateUrl!);
                                            }}>
                                            {props.subtitle}
                                        </Button>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
