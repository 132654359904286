import { claimTypes } from "../../config/claimTypes";
import { ReportCard } from "./ReportCard";
import { SsrsReport } from "./SsrsReport";
import { RecommendedForServiceThumbnail } from "../../images/RecommendedForServiceThumbnail"
import { DeviceInspectionHistoryThumbnail } from "../../images/DeviceInspectionHistoryThumbnail"
import { RepairFrequencyThumbnail } from "../../images/RepairFrequencyThumbnail"

/**
 * A collection of the report MUI card content.
 */
export const descriptions = {
    viewCurrentStatus: "View current status of all items being serviced",
    viewEquipmentPlanner: "View the equipment planner for instruments in real-time",
    evaluateStatus:
        "Evaluates the status of serialized scopes and devices against the establish maintenance interval within equipment planner",
    deviceInspection:
        "Highlights device inspections within the past 90 days with a diagnosis recommending repair service but has yet to be serviced. Provides additional insight which specific devices are recommended for service by facility, manufacturer, and model.",

    pmInspection:
        "Highlights the results of the preventive maintenance inspection process conducted in your facility, this illustrates the condition of your flexible scopes and surgical devices at the time of inspection.",

    assetsRepaired:
        "Highlights assets that have been repaired frequently over the last 12 months. This can be used to drive potential recommendations for inventory optimization, identify/combat quality issues, and indicate a potential process breakdown within the facility.",
};

/**
 * A collection of the report MUI card titles.
 */
export const titles = {
    repairHistory: "Repair History",
    equipmentPlannerTray: "Equipment Planner AEM Tray",
    equipmentPlannerDevices: "Equipment Planner AEM Devices",
    recommendedForService: "Recommended For Service",
    deviceInspectionHistory: "Device Inspection History",
    repairFrequency: "Repair Frequency",
};

/**
 * A collection of all the possible reports.
 */
export const reportsCardData: ReportCard[] = [
    {
        title: titles.repairHistory,
        description: descriptions.viewCurrentStatus,
        href: `/analytics/reports/${SsrsReport[SsrsReport.repairHistory]}`,
    },
    {
        title: titles.equipmentPlannerTray,
        description: descriptions.viewEquipmentPlanner,
        href: `/analytics/reports/${SsrsReport[SsrsReport.equipmentPlannerAemTray]}`,
    },
    {
        title: titles.equipmentPlannerDevices,
        description: descriptions.evaluateStatus,
        href: `/analytics/reports/${SsrsReport[SsrsReport.equipmentPlannerAemDevices]}`,
    },
];

/**
 * A collection of all the possible analytical reports.
 */
export const analyticsInsightsData: ReportCard[] = [
    {
        title: titles.recommendedForService,
        description: descriptions.deviceInspection,
        href: "/analytics/recommendedforservice",
        imageSvg: RecommendedForServiceThumbnail,
        requiredClaim: claimTypes.ViewRecommendedNeverServicedDashboard,
    },
    {
        title: titles.deviceInspectionHistory,
        description: descriptions.pmInspection,
        href: "/analytics/deviceinspectionhistory",
        imageSvg: DeviceInspectionHistoryThumbnail,
        requiredClaim: claimTypes.ViewDeviceInspectionHistoryDashboard,
    },
    {
        title: titles.repairFrequency,
        description: descriptions.assetsRepaired,
        href: "/analytics/repairfrequency",
        imageSvg: RepairFrequencyThumbnail,
        requiredClaim: claimTypes.ViewRepairFrequencyDashboard,
    },
];
