import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

export interface IConfirmationModalProps {
    show: boolean;
    onSecondaryButtonClick: () => any;
    onPrimaryButtonClick: (data: any) => any;
    title?: string;
    contentMessage: string;
    secondaryButtonText: string;
    primaryButtonText: string;
}

export default function ConfirmationModal(props: IConfirmationModalProps) {
    const {
        title,
        contentMessage,
        secondaryButtonText,
        primaryButtonText,
        show,
        onPrimaryButtonClick,
        onSecondaryButtonClick,
    } = props;

    return (
        <Dialog
            id="confirmation-modal-dialog"
            aria-labelledby="confirmation-modal-dialog"
            open={show}
            fullWidth={true}>
            <DialogTitle id="confirmation-modal-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Typography>{contentMessage}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justifyContent={"flex-end"}
                    columnSpacing={2}>
                    <Grid item>
                        <Button
                            variant="secondary"
                            size="small"
                            onClick={onSecondaryButtonClick}
                            data-testid="secondary-button">
                            {secondaryButtonText}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            data-testid="primary-button"
                            onClick={onPrimaryButtonClick}
                            autoFocus>
                            {primaryButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
