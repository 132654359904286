
/**
 * Extensions to work with the FreshWorksWidget. 
 * The 3rd party tool that shows a helpbox in the lower right of the screen.
 * {@link https://dev.azure.com/imsrepair/ConnectCare/_workitems/edit/23015|User Story 23015: SUPPORT - FreshDesk Widget - Add Code Snippet}
 */
export const freshworks = {
    /**
     * Hides the freshworks widget.
     */
    hide: () => {
        (window as any).FreshworksWidget('hide');
    },
    /**
     * Shows the freshworks widget.
     */
    show: () => {
        (window as any).FreshworksWidget('show');
    }
}