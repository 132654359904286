import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { blue } from "../theme/colors";

/**
 * This component can be used to render MUI Photo Icon in cell value when we found photo(s) to relavant module
 * @param value: Target path to navigate
 * @returns Box component with PhotoLibraryIcon
 */
export function GridCellWithPhotoIcon({ path }: Readonly<{ path: string }>) {
    const navigate = useNavigate();
    return (
        <Box
            display={"flex"}
            alignItems="center">
            <IconButton
                aria-label="photos-icon"
                size="small"
                onClick={() => {
                    navigate(path, { state: { tab: "photos" } });
                }}>
                <PhotoLibraryIcon
                    fontSize="inherit"
                    htmlColor={blue.connectCare2}
                />
            </IconButton>
        </Box>
    );
}
