import { useTranslation } from "react-i18next";
import { Box, Grid, Button, Typography } from "@mui/material";
import OrderItemSearch from "../../components/orders/orderitems/OrderItemSearch";
import OrderItemsGrid from "../../components/orders/orderitems/OrderItemsGrid";
import GridHeader from "../../component-library/GridHeader";
import { FormEvent, useCallback, useEffect } from "react";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { GridBackgroundColor, theme } from "../../theme/theme";

import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    setInitialDataLoaded,
    setSelectedDateRange,
    selectedDateRange,
} from "../../redux/reducers/orders/ordersItemsSlice";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { OrderItem } from "../../models/orders/OrderItem";
import { useFetch } from "../../services/useFetch";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";

export default function OrderItems() {
    const dispatch = useAppDispatch();
    const dateRange = useAppSelector(selectedDateRange);
    const { get } = useFetch();
    const { initialDataLoaded, startDate, endDate, isDateRangeValid, searchString, isError } = useAppSelector(
        (state) => state.orderItems
    );
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.orderItems)}`,
        orders: t("Orders"),
        orderItems: t("Order Items"),
        search: t("Search"),
        apiError: t("System Error: API is not available at this time!"),
        invalidDate: t("Order Item Date End must be after Order Item Date Start"),
    };

    const getOrderItems = useCallback(async () => {
        let uri = `${requestConnectCareOrders.OrderItemsSearch}?from=${startDate}&to=${endDate}`;
        if (searchString?.length) {
            uri += `&searchstring=${encodeURIComponent(searchString)}`;
        }

        dispatch(dataRequested());
        const response = (await get<OrderItem[]>(uri, true)) as OrderItem[];

        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [dispatch, startDate, endDate, get, searchString]);

    const handleSearchItemSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getOrderItems();
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        dispatch(setInitialDataLoaded(false));
    }, [dispatch, selectedFacilities]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getOrderItems();
            dispatch(setInitialDataLoaded(true));
        }
    }, [dispatch, getOrderItems, initialDataLoaded]);

    return (
        <Box
            style={GridBackgroundColor}
            data-testid="order-items">
            <BreadCrumb breadCrumbs={BreadCrumbList.orderItems} />

            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.orderItems}
                    onFormSubmit={handleSearchItemSubmit}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        marginY={1}
                        paddingX={1}>
                        <OrderItemSearch />
                    </Grid>

                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <HeaderDateRangePicker
                            dateRange={dateRange}
                            onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                    </Grid>

                    <Grid
                        item
                        md="auto"
                        marginY={1}
                        paddingX={1}>
                        <Button
                            aria-label={translations.search}
                            type="submit"
                            variant="contained"
                            size="small"
                            disabled={!isDateRangeValid}
                            sx={{ marginTop: "5px" }}>
                            {translations.search}
                        </Button>
                    </Grid>
                    {!isDateRangeValid && (
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <Typography
                                pl={2}
                                variant="body2"
                                color={"error.dark"}>
                                {translations.invalidDate}
                            </Typography>
                        </Grid>
                    )}
                </GridHeader>

                <Box py={theme.spacing(2)}>{!isError && <OrderItemsGrid data-testid="orderitems-grid" />}</Box>
            </Box>
        </Box>
    );
}
