import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    FormControl,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import { MarkAsServicedItem } from "../../models/serviceSummary/MarkAsServicedItem";
import { RecommendedForService } from "../../models/serviceSummary/RecommendedForService";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SectionField } from "../../theme/theme";
import { validators } from "../../utils/validators";

export interface IMarkAsServicedProps {
    recommendedForServiceItem?: RecommendedForService;
    onCancelClick: () => void;
    onMarkAsServicedSubmit: () => void;
}

export const MarkAsServiced = (props: IMarkAsServicedProps) => {
    const { onCancelClick, onMarkAsServicedSubmit } = props;
    const { t } = useTranslation();
    const { postUnique } = useFetch();
    const translations = {
        markAsServiced: t("Mark as Serviced"),
        repairDate: t("Repair Date"),
        reference: t("Reference"),
        problem: t("Problem"),
        details: t("Details"),
        requiredError: t("Required"),
        cancel: t("cancel"),
        save: t("save"),
        yes: t("Yes"),
        no: t("No"),
        cancelMarkAsServiceMessage: t("Are you sure you want to cancel marking as serviced?"),
        saveFailed: t("An error occurred. The asset was not marked as serviced"),
        saveSuccess: t("The Item has been Marked As Serviced"),
        invalidDate: t("Date must be in the past."),
        maxNoteError: t("Note must be 100 characters maximum."),
        maxProblemError: t("Problem must be 240 characters maximum."),
    };
    const intialMarkAsServicedItem: MarkAsServicedItem = {
        customerAccountId: props.recommendedForServiceItem?.custAccountId as number,
        inventoryItemId: props.recommendedForServiceItem?.inventoryItemId as number,
        serialNumber: props.recommendedForServiceItem?.serialNumber,
        externalRepairDate: new Date(),
        reference: null,
        problem: null,
    };
    const [markAsServicedItem, setMarkAsServicedItem] = useState<MarkAsServicedItem>(intialMarkAsServicedItem);
    const dispatch = useDispatch();
    const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs(new Date()));

    const [isLoading, setIsLoading] = useState(false);

    const handleSaveError = () => {
        dispatch(
            setToast({
                toastType: ToastTypes.Error,
                toastMessage: translations.saveFailed,
            })
        );
    };

    const handleInvalidDate = () => {
        dispatch(
            setToast({
                toastType: ToastTypes.Error,
                toastMessage: translations.invalidDate,
            })
        );
    };

    const handleMarkForServiceClick = async (markAsServicedItem: MarkAsServicedItem) => {
        setIsLoading(true);
        markAsServicedItem.externalRepairDate = new Date(dayjs(dateValue, "DD-MMM-YYYY").format("MM/DD/YYYY"));
        let dateLocal = new Date(markAsServicedItem.externalRepairDate);
        markAsServicedItem.externalRepairDate = new Date(
            dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
        );
        const uri = `${requestConnectCareInventory.MarkAsServiced}`;
        if (!validateInputs()) {
            return;
        }
        const res = await postUnique<MarkAsServicedItem, void>(uri, markAsServicedItem, true, handleSaveError);
        if ((res as Response)?.ok) {
            setIsLoading(false);
            onMarkAsServicedSubmit();
        }
        setIsLoading(false);
    };

    const validateInputs = (): boolean => {
        return validators.markAsServiced(markAsServicedItem);
    };

    const isNoteMaxError = markAsServicedItem.reference !== null && markAsServicedItem.reference.length > 100;
    const isProblemMaxError = markAsServicedItem.problem !== null && markAsServicedItem.problem.length > 240;

    return (
        <Dialog
            open={true}
            fullWidth={true}>
            <Box
                data-testid="mark-as-serviced"
                p={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid
                            item
                            xs={12}
                            pr={2}
                            pb={2}>
                            <Typography
                                variant="h1"
                                color="blue.darker"
                                textTransform="uppercase">
                                {translations.markAsServiced}
                            </Typography>
                        </Grid>
                        <Box pb={2}>
                            <SectionField>{translations.repairDate}</SectionField>
                            <FormControl
                                variant="outlined"
                                data-testid="mark-as-serviced-date-picker"
                                fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label=""
                                        value={dateValue}
                                        disableFuture
                                        onError={handleInvalidDate}
                                        onChange={(newDate) => {
                                            setDateValue(newDate);
                                        }}
                                        format="MM-DD-YYYY"
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                        <Box pb={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth>
                                <TextField
                                    inputProps={{ "data-testid": "mark-as-serviced-reference-input" }}
                                    label={translations.reference}
                                    size="small"
                                    value={markAsServicedItem.reference}
                                    error={isNoteMaxError}
                                    onChange={(e) => {
                                        setMarkAsServicedItem((prev) => ({
                                            ...prev,
                                            reference: e.target.value,
                                        }));
                                    }}
                                />
                            </FormControl>
                            {isNoteMaxError && (
                                <Typography
                                    data-testid={"note-over-100-error"}
                                    variant="errorMessage">
                                    {translations.maxNoteError}
                                </Typography>
                            )}
                        </Box>
                        <Box pb={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth>
                                <TextField
                                    inputProps={{ "data-testid": "mark-as-serviced-problem-input" }}
                                    label={translations.problem}
                                    size="small"
                                    multiline
                                    rows={2}
                                    value={markAsServicedItem.problem}
                                    error={isProblemMaxError}
                                    onChange={(e) => {
                                        setMarkAsServicedItem((prev) => ({
                                            ...prev,
                                            problem: e.target.value,
                                        }));
                                    }}
                                />
                            </FormControl>
                            {isProblemMaxError && (
                                <Typography
                                    data-testid={"problem-over-240-error"}
                                    variant="errorMessage">
                                    {translations.maxProblemError}
                                </Typography>
                            )}
                        </Box>
                        <DialogActions>
                            <Grid
                                container
                                justifyContent={"flex-end"}
                                columnSpacing={2}>
                                <Grid item>
                                    <Button
                                        aria-label={translations.cancel}
                                        data-testid="cancel-button"
                                        variant="Cancel"
                                        size="small"
                                        onClick={onCancelClick}>
                                        {translations.cancel}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {markAsServicedItem && (
                                        <Button
                                            aria-label={translations.markAsServiced}
                                            data-testid="mark-as-serviced-button"
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            disabled={!validateInputs() || isLoading}
                                            onClick={() => handleMarkForServiceClick(markAsServicedItem)}
                                            autoFocus>
                                            {isLoading ? <CircularProgress size={20} /> : translations.markAsServiced}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    );
};
