import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function InactiveUser() {
    const { t } = useTranslation();
    const strings = {
        ccLogoAlt: t("ConnectCare Logo"),
        inactive: t("Your account is inactive."),
        contact: t("For further assistance, please call us: 1-800-783-9251"),
        close: t("Close"),
    };

    return (
        <Box
            bgcolor={"bg.lightGray"}
            p={2}
            data-testid="inactive-user">
            <Card
                variant="outlined"
                sx={{ height: "100%" }}>
                <CardContent>
                    <Grid
                        container
                        justifyContent={"center"}
                        pt={3}>
                        <DeleteForeverIcon fontSize={"large"} />
                        <Grid
                            item
                            xs={12}>
                            <Typography
                                variant="h4"
                                textAlign={"center"}
                                marginTop={1}
                                marginBottom={3}>
                                {strings.inactive}
                            </Typography>
                            <Typography
                                textAlign={"center"}
                                variant="body1">
                                {strings.contact}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            mt={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                href="/">
                                {strings.close}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}

export default InactiveUser;
