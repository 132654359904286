import { GridColDef, GridRenderCellParams, GridValidRowModel, GridState } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatDate, FormatCurrency } from "../../common/Formatters";
import { Alert, Box, Grid, LinearProgress, Link } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import { requestConnectCareInventory } from "../../services/apiPaths";
import { StyledFilterPanel, StripedDataGrid } from "../../theme/stripedTable";
import { CustomFooterTotal } from "./CustomFooterTotal";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../services/useFetch";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

export interface IAssetHistoryDetailsProp {
    customerAccountId: string | undefined;
    serialNumber: string;
    inventoryItemId: string | undefined;
}

export interface IAssetHistoryDetails {
    id: number;
    customerAccountId: number;
    tranDate: Date;
    lineType?: string;
    sumExtended?: string;
    description?: string;
    serialNumber?: string;
    headerId?: number;
    pmiId?: number;
    filteredPropertyNames: string[];
}

declare module "@mui/x-data-grid-pro" {
    interface FooterPropsOverrides {
        totalPrice: number;
        totalRows: string;
    }
}

/**
To transform the values of each cell in a row.
@param {IAssetHistoryDetails[]} rows- The datagrid rows to be transformed.
@example
rows={transformedRows(gridRows)} where gridRows is a collection of rows need to be displayed in the datagrid.
Here, the value of a column with type Date needs to be transformed in a valid date format so that IS BEFORE date filter should start working.
@returns {GridValidRowModel} The transformed rows.
*/
export const transformedRows = (rows: IAssetHistoryDetails[]): GridValidRowModel[] => {
    return rows.map((x) => {
        return {
            ...x,
            tranDate: x?.tranDate ? new Date(FormatDate(x?.tranDate)) : x?.tranDate,
        };
    });
};

export const TranDateCell = (row: { headerId?: number; pmiId?: number; lineType: string; tranDate: Date }) => {
    const navigate = useNavigate();
    let url = "";
    if (row.headerId) {
        url = "/orders/" + row.headerId;
    } else if (row.pmiId) {
        url = "/assets/devicepm/event/" + row.pmiId;
    }
    return (
        <Box data-testid="trandatecell">
            {row.lineType.toLowerCase() === "preventative maintenance" || row.lineType.toLowerCase() === "repair" ? (
                <Link
                    data-testid="trandatelink"
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                        navigate(url);
                    }}>
                    {FormatDate(row.tranDate)}
                </Link>
            ) : (
                FormatDate(row.tranDate)
            )}
        </Box>
    );
};

export const PriceCell = (row: { lineType: string; sumExtended?: number }) => {
    let price: string = "";
    if (row.lineType.toLowerCase() === "repair") {
        price = row.sumExtended ? FormatCurrency(row.sumExtended) : row.sumExtended?.toString() ?? "";
    }
    return <Box data-testid="priceCell">{price}</Box>;
};

const AssetHistoryDetails = (props: IAssetHistoryDetailsProp) => {
    const { t } = useTranslation();
    const { get } = useFetch();
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const translations = {
        id: t("Id"),
        date: t("Date"),
        type: t("Type"),
        description: t("Description"),
        price: t("Price"),
        itemServiceTotal: t("Item Service Total"),
        apiError: t("System Error: API is not available at this time!"),
        norRows: t("No Rows."),
    };
    const [gridRows, setGridRows] = useState<IAssetHistoryDetails[]>([]);
    const [isGridLoading, setIsGridLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [visibleRowsCount, setVisibleRowsCount] = useState<number>(0);
    const [gridCols, setGridCols] = useState<GridColDef[]>([]);
    const [showTotal, setShowTotal] = useState<boolean>(true);

    useEffect(() => {
        setIsGridLoading(false);
        let total = 0;
        if (gridRows.length > 0) {
            gridRows.forEach(function (e) {
                if (e.sumExtended && e.lineType?.toLowerCase() === "repair") {
                    total = total + parseFloat(e.sumExtended);
                }
            });
            setTotalPrice(total);
        }
    }, [gridRows, totalPrice]);

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "tranDate",
                headerName: translations.date,
                renderHeader: () => translations.date,
                minWidth: 120,
                renderCell: (params: GridRenderCellParams) => (
                    <TranDateCell
                        headerId={params.row.headerId}
                        pmiId={params.row.pmiId}
                        lineType={params.row.lineType}
                        tranDate={params.row.tranDate}
                    />
                ),
                type: "date",
                valueFormatter: (params) => {
                    return FormatDate(params.value);
                },
                sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
            },
            {
                field: "lineType",
                headerName: translations.type,
                renderHeader: () => translations.type,
                minWidth: 300,
            },
            {
                field: "description",
                headerName: translations.description,
                renderHeader: () => translations.description,
                minWidth: 400,
            },
        ],
        [translations.date, translations.description, translations.price, translations.type]
    );

    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);

    const getAssetHistoryDetails = useCallback(async () => {
        setIsError(false);
        setIsGridLoading(true);
        const uri = `${requestConnectCareInventory.AssetHistoryDetails}?customerAccountId=${
            props.customerAccountId
        }&inventoryItemId=${props.inventoryItemId}&serialNumber=${encodeURIComponent(props.serialNumber)}`;
        const response = await get<IAssetHistoryDetails[]>(uri, true);
        if (response) {
            setGridRows(response);
        } else {
            setIsError(true);
        }
        setIsGridLoading(false);
    }, [get, props.customerAccountId, props.inventoryItemId, props.serialNumber]);

    useEffect(() => {
        if (selectedFacilities.length) {
            getAssetHistoryDetails();
        }
    }, [selectedFacilities, getAssetHistoryDetails]);

    /**
     * It will validate sumExtended value and if its a blank/undefined/null then it will return 0 else it will return float value
     * @param {string} sumExtended - sumExtended is the price which need to be converted into proper numeric value.
     */
    const getSumExtendedPrice = (sumExtended?: string): number => {
        if (!sumExtended) {
            return 0;
        } else {
            return parseFloat(sumExtended);
        }
    };
    /**
     * Get the visible rows inside datagrid to show the total rows and the Item Service Total sum, that is visible at the footer
     * Based on visible rows id, we need to filter out row data from actual grid rows, to calculate item service total
     * @param {GridState} state - The current state of the DataGrid.
     */
    const totalSumAndRowsCount = (state: GridState) => {
        const visibleRows = state.visibleRowsLookup;
        if (visibleRows) {
            let visibleItems: number[] = [];
            for (const [id, value] of Object.entries(visibleRows)) {
                if (value) {
                    visibleItems.push(parseInt(id));
                }
            }
            setVisibleRowsCount(visibleItems.length);
            const res = gridRows.filter((item) => visibleItems.includes(item.id));
            const total = res.map((item) => getSumExtendedPrice(item.sumExtended)).reduce((a, b) => a + b, 0);
            setTotalPrice(total);
        }
    };

    useEffect(() => {
        // list of filterPropertyNames for each row
        const propertyNames = new Set<string>([]);

        gridRows.forEach((x) => x.filteredPropertyNames.forEach((y) => propertyNames.add(y.toUpperCase())));

        if (propertyNames.has("SUMEXTENDED")) {
            setShowTotal(false);
            columns.push({
                field: "sumExtended",
                renderHeader: () => translations.price,
                headerName: translations.price, //
                sortable: true,
                minWidth: 200,
                align: "right",
                type: "number",
                headerAlign: "left",
                renderCell: (params: GridRenderCellParams) => (
                    <PriceCell
                        lineType={params.row.lineType}
                        sumExtended={params.row.sumExtended}
                    />
                ),
            });
        }

        // We remove the columns if the whole column is filtered for all rows.
        let newColumns = columns.filter((x) => {
            return !propertyNames.has(x.field.toUpperCase());
        });

        setGridCols(newColumns);
    }, [columns, gridRows]);

    return (
        <Box
            bgcolor="bg.light"
            width="100%"
            sx={{ height: "70vh", "& .padding": { paddingLeft: "20px" } }}
            data-testid="asset-history-details-grid-box">
            {isError && (
                <Grid
                    item
                    xs={12}
                    sx={{ mx: 2, mb: 2 }}>
                    <Alert severity="error">{translations.apiError}</Alert>
                </Grid>
            )}
            <StripedDataGrid
                disableRowSelectionOnClick
                rows={transformedRows(gridRows)}
                columns={gridCols}
                density="compact"
                slots={{
                    toolbar: () => CustomToolbar(exportExcelFileName.assetHistoryDetails),
                    loadingOverlay: LinearProgress,
                    footer: showTotal ? CustomFooterTotal : undefined,
                    filterPanel: StyledFilterPanel,
                }}
                slotProps={{
                    footer: showTotal
                        ? { totalPrice, totalRows: visibleRowsCount + " of " + gridRows.length }
                        : undefined,
                }}
                loading={isGridLoading}
                columnBuffer={5}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                onStateChange={(state) => {
                    totalSumAndRowsCount(state);
                }}
            />
        </Box>
    );
};

export default AssetHistoryDetails;
