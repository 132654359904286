import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";

/**
 * Populates the pie chart center label, refer to {@link https://mui.com/x/react-charts/pie-demo/#piechartwithcenterlabel  Pie Chart with center label}.
 */

export default function PieCenterLabel({ children }: Readonly<{ children: React.ReactNode }> ) {
    const { width, height, left, top } = useDrawingArea();

    const StyledText = styled("text")(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: "middle",
        dominantBaseline: "central",
        fontSize: 20,
    }));

    return (
        <StyledText
            x={left + width / 2}
            y={top + height / 2}>
            {children}
        </StyledText>
    );
}
