import { Grid, LinearProgress, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { FormatDate } from "../../../common/Formatters";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel, getRowClassName } from "../../../theme/stripedTable";
import GridHeader from "../../../component-library/GridHeader";
import { NoRowsOverlay } from "../../../component-library/NoRowsOverlay";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../redux/store";
import { GridColDef } from "@mui/x-data-grid-pro";
import { setGridColumns, setGridFilter, setGridSort } from "../../../redux/reducers/assets/beyondRepairSlice";
import { customSortComparators } from "../../../utils/customSortComparators";

export default function BeyondRepairGrid() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, data, gridColumns, gridFilter, isError, initialGridState } = useSelector(
        (state: StoreState) => state.beyondRepair
    );
    const { t } = useTranslation();
    const translations = {
        id: t("Id"),
        equipment: t("Equipment"),
        qty: t("Qty"),
        repairDescription: t("Repair Description"),
        productLine: t("Product Line"),
        customer: t("Customer"),
        department: t("Department"),
        serviced: t("Serviced"),
        noSearchDataFound: t(`We found no matches for the selected date range.`),
        apiError: t("System Error: API is not available at this time!"),
    };
    const columns: GridColDef[] = [
        {
            field: "equipment",
            headerName: translations.equipment,
            minWidth: 200,
            flex: 1,
            renderHeader: () => translations.equipment,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.custAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.equipment}
                </Link>
            ),
        },
        {
            field: "productLine",
            headerName: translations.productLine,
            renderHeader: () => translations.productLine,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "repairDescription",
            headerName: translations.repairDescription,
            minWidth: 210,
            flex: 1,
            renderHeader: () => translations.repairDescription,
        },
        {
            field: "qty",
            headerName: translations.qty,
            renderHeader: () => translations.qty,
            minWidth: 110,
            maxWidth: 110,
            type: "number",
            align: "left",
            headerAlign: "left",
            flex: 1,
        },
        {
            field: "customerName",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "department",
            headerName: translations.department,
            minWidth: 160,
            flex: 1,
            renderHeader: () => translations.department,
        },
        {
            field: "orderDate",
            headerName: translations.serviced,
            minWidth: 150,
            flex: 1,
            renderHeader: () => translations.serviced,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
    ];

    return (
        <Grid container>
            <Grid
                item
                xs>
                <>
                    {!isLoading && isError && (
                        <GridHeader
                            title=""
                            hasError={isError}></GridHeader>
                    )}
                    {!isError && (
                        <GridAreaLayout data-testid="beyond-repair-grid-box">
                            <StripedDataGrid
                                disableRowSelectionOnClick
                                initialState={initialGridState}
                                rows={data}
                                columns={columns}
                                slots={{
                                    toolbar: () => CustomToolbar(exportExcelFileName.beyondRepair),
                                    loadingOverlay: LinearProgress,
                                    noRowsOverlay: () => NoRowsOverlay({ text: translations.noSearchDataFound }),
                                    filterPanel: StyledFilterPanel,
                                }}
                                slotProps={{
                                    columnsPanel: { sx: { textTransform: "capitalize" } },
                                }}
                                loading={isLoading}
                                columnBuffer={7}
                                density="compact"
                                filterModel={gridFilter}
                                onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                columnVisibilityModel={gridColumns}
                                onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                                getRowClassName={(params) => getRowClassName(params)}
                                editMode="cell"
                            />
                        </GridAreaLayout>
                    )}
                </>
            </Grid>
        </Grid>
    );
}
