import { Box, Typography, Grid } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

interface IProps {
    message: string;
}

export default function ErrorMessage({ message }: Readonly<IProps>) {
    return (
        <Grid
            maxWidth="sm"
            container
            justifyContent="center"
            data-testid="no-data">
            <Box
                maxWidth={"200px"}
                textAlign="center">
                <WarningRoundedIcon fontSize={"large"} />
                <Typography sx={{ wordBreak: "break-word" }}>{message}</Typography>
            </Box>
        </Grid>
    );
}
