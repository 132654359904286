import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomerList from "../../components/customers/CustomerList";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";

function Customers() {
    const { t } = useTranslation();
    const translations = {
        detailPageName: t("Admin"),
        pageName: t("Customers"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.adminCustomers)}`,
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <>
            <BreadCrumb breadCrumbs={BreadCrumbList.adminCustomers} />
            <CustomerList />
        </>
    );
}

export default Customers;
