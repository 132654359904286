import { useState, useEffect } from "react";
import { pageKeys } from "../config/data";
import ExternalLogin from "../forms/ExternalLogin";
import InactiveUser from "../components/auth/InactiveUser";
import getQueryStringParam from "../utils/getQueryStringParam";
import { ApiErrorCodes } from "../config/ApiErrorCodes";

function AppBaseAuth() {
    const [pageKey, setPageKey] = useState(pageKeys.AppExternalSignin);

    useEffect(() => {
        const errorCode = getQueryStringParam("errorCode");
        if (errorCode === ApiErrorCodes.AccountDeactivated.toString()) {
            //redirect to the inactive user page
            setPageKey(pageKeys.AppInactiveUser);
        }
    }, [setPageKey]);

    return (
        <div>
            <ExternalLogin setPageKey={setPageKey} />
            {pageKey === pageKeys.AppInactiveUser && <InactiveUser />}
        </div>
    );
}

export default AppBaseAuth;
