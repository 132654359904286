import Box from "@mui/material/Box";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { requestConnectCareUsers } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import { SettingSwitchWithLabel } from "../../component-library/SwitchWithLabel";
import { Dashboard } from "@mui/icons-material";
import { text } from "../../theme/colors";
import { ccLocalstorage } from "../../config/data";

const UserSettings = ({ getMySettings, settings }: { getMySettings: () => void; settings: any }) => {
    const { t } = useTranslation();
    const { post } = useFetch();
    const [userSettings, setUserSettings] = useState(settings);
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const translations = {
        dashboard: t("Dashboard"),
        servicesummary: t("Service Summary"),
        servicedelivery: t("Service Delivery"),
        edit: t("edit"),
        cancel: t("Cancel"),
        save: t("Save"),
        success: t("Success! Changes Saved."),
        failure: t("Failed to save the changes."),
    };
    const handleChangeSetting = (event: any, category: any) => {
        setUserSettings({
            dashboard: {
                ...userSettings.dashboard,
                [category]: { ...userSettings.dashboard[category], isOn: event.target.checked },
            },
        });
    };
    const updateSettings = useCallback(async () => {
        const uri = `${requestConnectCareUsers.GetMySettings}`;
        const response = await post<any>(uri, userSettings, false, () => {
            dispatch(
                setToast({
                    toastMessage: translations.failure,
                    toastType: ToastTypes.Error,
                })
            );
        });
        // Even when there is no error, but still need to check CurrentUser object existence
        if ((response as Response)?.ok) {
            localStorage.setItem(ccLocalstorage.connectCareUserSettings, JSON.stringify(userSettings));
            getMySettings();
            dispatch(
                setToast({
                    toastMessage: translations.success,
                    toastType: ToastTypes.Success,
                })
            );
        }
        setEditMode(false);
    }, [dispatch, getMySettings, post, translations.failure, translations.success, userSettings]);
    const resetDefaultState = () => {
        setUserSettings(settings);
        setEditMode(false);
    };
    const {
        approvalNeeded,
        flexScopeMaintenanceStatus,
        inRepair,
        inTransitForReport,
        loanersDueForReturn,
        poRequired,
        preventiveMaintenanceResults,
        recommendedForService,
        shipmentsFromSteris,
        surgicalDeviceMaintenanceStatus,
        trayMaintenanceStatus,
    } = userSettings.dashboard || {};
    const getButtons = () => {
        if (editMode) {
            return (
                <>
                    <Button
                        data-testid="cancel-button"
                        aria-label={translations.cancel}
                        size="small"
                        variant="contained"
                        onClick={resetDefaultState}
                        style={{
                            color: "font.white",
                            backgroundColor: text.textgray2,
                        }}
                        sx={{ width: 130, padding: "4px", borderRadius: 8, marginRight: 2 }}>
                        {translations.cancel}
                    </Button>
                    <Button
                        data-testid="save-button"
                        aria-label={translations.save}
                        size="small"
                        variant="contained"
                        onClick={updateSettings}
                        sx={{ width: 130, padding: "4px", borderRadius: 8 }}>
                        {translations.save}
                    </Button>
                </>
            );
        }
        return (
            <Button
                data-testid="edit-button"
                aria-label={translations.edit}
                size="small"
                variant="contained"
                onClick={() => setEditMode(true)}
                sx={{ width: 130, padding: "4px", borderRadius: 8 }}>
                {translations.edit}
            </Button>
        );
    };
    return (
        <Stack mb={2}>
            <Stack
                data-testid="user-settings-view-mode-stack"
                paddingLeft={6}
                paddingTop={5}
                spacing={{ xs: 1, sm: 2, md: 4, ls: 16 }}
                useFlexGap
                flexWrap="wrap">
                <Box
                    display={"flex"}
                    flexDirection={"row"}>
                    <Box
                        padding={3}
                        border={1}
                        flex={6}>
                        <Grid
                            container
                            alignItems={"center"}
                            marginBottom={2}>
                            <Grid item>
                                <Dashboard style={{ color: "#133674", marginRight: 8 }} />
                            </Grid>
                            <Grid
                                item
                                zIndex={2}>
                                <Typography
                                    variant="h1"
                                    color="font.darkBlue"
                                    marginBottom={1}>
                                    {translations.dashboard}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}>
                            <Typography
                                variant="body2"
                                color="font.darkBlue"
                                marginBottom={1}>
                                {translations.servicesummary}
                            </Typography>
                        </Grid>
                        <Box
                            paddingX={5}
                            marginBottom={5}>
                            <SettingSwitchWithLabel
                                data-testid="approval-needed"
                                editMode={editMode}
                                label="Approval Needed"
                                category="approvalNeeded"
                                checked={approvalNeeded?.isOn}
                                disabled={approvalNeeded?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="PO Required"
                                category="poRequired"
                                checked={poRequired?.isOn}
                                disabled={poRequired?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="Recommended for Service"
                                category="recommendedForService"
                                checked={recommendedForService?.isOn}
                                disabled={recommendedForService?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="Loaners Due for Return"
                                category="loanersDueForReturn"
                                checked={loanersDueForReturn?.isOn}
                                disabled={loanersDueForReturn?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="In Transit for Repair"
                                category="inTransitForReport"
                                checked={inTransitForReport?.isOn}
                                disabled={inTransitForReport?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="In Repair"
                                category="inRepair"
                                checked={inRepair?.isOn}
                                disabled={inRepair?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="Shipments from Steris"
                                category="shipmentsFromSteris"
                                checked={shipmentsFromSteris?.isOn}
                                disabled={shipmentsFromSteris?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                        </Box>
                        <Grid
                            item
                            xs={12}>
                            <Typography
                                variant="body2"
                                color="font.darkBlue"
                                marginBottom={1}>
                                {translations.servicedelivery}
                            </Typography>
                        </Grid>
                        <Box paddingX={5}>
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="Tray Maintenance Status"
                                category="trayMaintenanceStatus"
                                checked={trayMaintenanceStatus?.isOn}
                                disabled={trayMaintenanceStatus?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="Flex Scope Maintenance Status"
                                category="flexScopeMaintenanceStatus"
                                checked={flexScopeMaintenanceStatus?.isOn}
                                disabled={flexScopeMaintenanceStatus?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="Surgical Device Maintenance Status"
                                category="surgicalDeviceMaintenanceStatus"
                                checked={surgicalDeviceMaintenanceStatus?.isOn}
                                disabled={surgicalDeviceMaintenanceStatus?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                            <SettingSwitchWithLabel
                                editMode={editMode}
                                label="Preventive Maintenance Results"
                                category="preventiveMaintenanceResults"
                                checked={preventiveMaintenanceResults?.isOn}
                                disabled={preventiveMaintenanceResults?.isDisabled}
                                handleChangeSetting={handleChangeSetting}
                            />
                        </Box>
                    </Box>
                    <Box
                        flex={6}
                        display={"flex"}
                        justifyContent={"right"}
                        paddingX={2}>
                        {getButtons()}
                    </Box>
                </Box>
            </Stack>
        </Stack>
    );
};

export default UserSettings;
