import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { text } from "../../theme/colors";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { Facility } from "../../models/facility/Facility";
import { requestConnectCareCustomers, requestConnectCareEducations } from "../../services/apiPaths";
import { ICustomerContact, IOracleDepartment } from "../../models/serviceRequest/CreateSrnModels";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { CreateEducationEvent } from "../../models/education/CreateEducationEvent";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { claimTypes } from "../../config/claimTypes";
import { StoreState } from "../../redux/store";

export type IRequestEducationEventProps = {
    onClose: () => void;
};

export interface RequestEducation {
    custAccountId: number;
    accountId: number;
    departmentId: number;
    requestedBy: number;
    createdBy: number;
    agenda: string;
    eventMethod: string;
}

export enum DeliveryMethod {
    Virtual = "Virtual",
    InPerson = "In-Person",
    PhoneCall = "Phone Call",
}

export default function RequestEducationEventDialog(props: Readonly<IRequestEducationEventProps>) {
    const { onClose } = props;
    const { t } = useTranslation();
    const { get, post } = useFetch();
    const dispatch = useDispatch();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [departmentOptions, setDepartmentOptions] = useState<IOracleDepartment[]>([]);
    const [contactOptions, setContactOptions] = useState<ICustomerContact[]>([]);
    const initialFacilityValue: Facility = {
        custAccountId: 0,
        name: "",
        isSelected: false,
        customerClassCodeOracle: "",
        displayName: "",
        number: 0,
        accountId: 0,
        subscriptionId: 0,
    };
    const initialDepartment: IOracleDepartment = {
        custDeptId: 0,
        department: "",
        custAccountId: 0,
        description: "",
    };
    const initialContact: ICustomerContact = {
        contactId: 0,
        fullName: "",
    };
    const [selectedFacility, setSelectedFacility] = useState<Facility>(initialFacilityValue);
    const [selectedDepartment, setSelectedDepartment] = useState<IOracleDepartment>(initialDepartment);
    const [selectRequestedBy, setSelectRequestedBy] = useState<ICustomerContact>(initialContact);
    const [requestedAgenda, setRequestedAgenda] = useState<string>("");
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<DeliveryMethod>();
    const translations = {
        title: t("Request Education Event"),
        facility: t("Facility"),
        selectFacility: t("Select Facility"),
        requiredFacility: t("Please select Facility"),
        department: t("Department"),
        selectedDepartment: t("Select Department"),
        requiredDepartment: t("Please select department"),
        requestedBy: t("Requested By"),
        selectedRequestedBy: t("Select Requested By"),
        requiredRequestedBy: t("Please select Requested By"),
        requestedAgenda: t("Requested Agenda"),
        selectedDeliveryMethod: t("Select delivery method"),
        requestedDeliveryMethod: t("Requested Delivery Method"),
        requestedAgendaPlaceholder: t("Enter requested agenda and/or topic"),
        validationText: t("Minimum 3 characters required"),
        maximumValidation: t("Maximum 500 characters exceeded"),
        requiredRequestDeliveryMethod: t("Please select delivery method"),
        submit: t("Submit"),
        cancel: t("Cancel"),
        success: t("Success! Your request has been sent"),
        error: t("Your request could not be completed at this time. Please try again later."),
    };

    /** When user click oustide the modal dialog or user press ESC key, then dialog will be hidden */
    const handleClose = (_event: {}, reason: string) => {
        if ((reason && reason === "backdropClick") || reason === "escapeKeyDown") {
            onClose();
        }
    };

    const getDepartments = useCallback(async () => {
        if (selectedFacility.custAccountId) {
            const uri = `${requestConnectCareCustomers.OracleDepartments}/${selectedFacility.custAccountId}`;
            let response = await get<IOracleDepartment[]>(uri); // as DepartmentResponse[];
            if (response) {
                setDepartmentOptions(response);
            }
        }
    }, [get, selectedFacility.custAccountId]);

    const getContacts = useCallback(async () => {
        if (selectedFacility.custAccountId) {
            const uri = `${requestConnectCareCustomers.CustomerContacts}/${selectedFacility.custAccountId}`;
            let response = (await get<ICustomerContact[]>(uri)) as ICustomerContact[];
            if (response) {
                setContactOptions(response);
            }
        }
    }, [get, selectedFacility.custAccountId]);

    useEffect(() => {
        if (selectedFacility) {
            getDepartments();
            getContacts();
        }
    }, [selectedFacility, getDepartments, getContacts]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<RequestEducation>({
        defaultValues: {
            accountId: 0,
            custAccountId: 0,
            departmentId: 0,
            requestedBy: 0,
            agenda: "",
            eventMethod: "",
        },
    });

    /** When user update the facility from facility dropdown,
     * the relavant dropdowns (departments and requested by) will be loaded */
    const handleFacilityChange = (event: SelectChangeEvent<typeof selectedFacility.custAccountId>) => {
        setDepartmentOptions([]);
        setContactOptions([]);
        setSelectedDepartment(initialDepartment);
        setSelectRequestedBy(initialContact);
        const selectedFacilityAccountId = +event.target.value;
        if (selectedFacilityAccountId > 0) {
            setSelectedFacility(
                selectedFacilities.find(
                    (x: Facility) =>
                        AuthLibrary.HasAccountSubscriptionAccessToClaim(
                            claimTypes.RequestEducationEvent,
                            x.custAccountId
                        ) && x.custAccountId === selectedFacilityAccountId
                )!
            );
            setValue("custAccountId", selectedFacility.custAccountId);
        } else {
            setSelectedFacility(initialFacilityValue);
            setValue("custAccountId", 0);
        }
    };

    const handleDepartmentChange = (event: SelectChangeEvent<typeof selectedDepartment.custDeptId>) => {
        const department = +event.target.value;
        if (department) {
            setSelectedDepartment(departmentOptions.filter((x) => x.custDeptId === department)[0] ?? initialDepartment);
            setValue("departmentId", department);
        } else {
            setSelectedDepartment(initialDepartment);
            setValue("departmentId", 0);
        }
    };

    const handleContactChange = (event: SelectChangeEvent<typeof selectRequestedBy.contactId>) => {
        const requestedBy = +event.target.value;
        if (requestedBy > 0) {
            const requestedContact = contactOptions.find((x) => x.contactId === requestedBy);
            if (requestedContact) {
                setSelectRequestedBy(requestedContact);
                setValue("requestedBy", requestedBy);
            }
        } else {
            setSelectRequestedBy(initialContact);
            setValue("requestedBy", 0);
        }
    };

    const handleRequestedAgendaChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const requestedAgenda = event.target.value;
        setRequestedAgenda(requestedAgenda);
        setValue("agenda", requestedAgenda);
    };

    const handleRequestedDeliveryMethodChange = (event: SelectChangeEvent<typeof selectedDeliveryMethod>) => {
        const deliveryMethod = event.target.value as DeliveryMethod;
        setSelectedDeliveryMethod(deliveryMethod);
        setValue("eventMethod", deliveryMethod);
    };

    const onSubmit = async (data: RequestEducation) => {
        const requestPayload: CreateEducationEvent = {
            custAccountId: data.custAccountId,
            custDepartmentId: data.departmentId,
            requestedBy: data.requestedBy,
            createdBy: data.requestedBy,
            agenda: data.agenda,
            deliveryType: selectedDeliveryMethod as DeliveryMethod,
        };

        const response = await post<CreateEducationEvent>(
            requestConnectCareEducations.CreateEducationEvent,
            requestPayload,
            true,
            () => {
                dispatch(
                    setToast({
                        toastMessage: translations.error,
                        toastType: ToastTypes.Error,
                    })
                );
            }
        );

        if (response) {
            dispatch(
                setToast({
                    toastMessage: translations.success,
                    toastType: ToastTypes.Success,
                })
            );
        }
        onClose();
    };

    return (
        <Box>
            <Dialog
                id="request-education-modal"
                data-testid="request-education-modal"
                aria-labelledby="request-education-modal"
                open
                fullWidth={true}
                onClose={handleClose}>
                <DialogTitle
                    id="request-education-modal-title"
                    textTransform={"uppercase"}>
                    <Box>
                        <CalendarMonthOutlined sx={{ color: text.textgray2, float: "left", pt: 0.5 }} />
                        <Typography
                            component="span"
                            variant="subtitle2"
                            sx={{ paddingLeft: 2, color: "text.textBlack", fontSize: 20 }}>
                            {translations.title}
                        </Typography>
                    </Box>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Box
                            display={"grid"}
                            gap={3}>
                            <Grid container>
                                <Grid
                                    mt={1}
                                    item
                                    xs>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="facility-id"
                                            shrink>
                                            {translations.facility}
                                        </InputLabel>
                                        <Select
                                            data-testid="facility-dropdown-list"
                                            displayEmpty
                                            defaultValue={0}
                                            label={translations.facility}
                                            labelId="facility-id"
                                            {...register("custAccountId", { required: translations.requiredFacility })}
                                            error={!!errors.custAccountId && !selectedFacility.custAccountId}
                                            value={selectedFacility.custAccountId || ""}
                                            onChange={handleFacilityChange}>
                                            <MenuItem value="">{translations.selectFacility}</MenuItem>
                                            {selectedFacilities
                                                .filter((x) =>
                                                    AuthLibrary.HasAccountSubscriptionAccessToClaim(
                                                        claimTypes.RequestEducationEvent,
                                                        x.custAccountId
                                                    )
                                                )
                                                .map((fac) => (
                                                    <MenuItem
                                                        key={fac.custAccountId}
                                                        value={fac.custAccountId}>
                                                        {fac.displayName}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {errors.custAccountId && !selectedFacility.custAccountId && (
                                            <Typography variant="errorMessageBold">
                                                {errors.custAccountId?.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                gap={2}>
                                <Grid
                                    item
                                    xs>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="department-id"
                                            shrink>
                                            {translations.department}
                                        </InputLabel>
                                        <Select
                                            data-testid="department-dropdown-list"
                                            displayEmpty
                                            inputProps={{ "data-testid": "department-dropdown" }}
                                            label={translations.department}
                                            labelId="department-id"
                                            {...register("departmentId", {
                                                required: translations.requiredDepartment,
                                            })}
                                            error={!!errors.departmentId && !selectedDepartment.custDeptId}
                                            value={selectedDepartment.custDeptId || ""}
                                            onChange={handleDepartmentChange}>
                                            <MenuItem value="">{translations.selectedDepartment}</MenuItem>
                                            {departmentOptions.map((option) => (
                                                <MenuItem
                                                    key={option.custDeptId}
                                                    value={option.custDeptId}>
                                                    {option.department}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.departmentId && !selectedDepartment.custDeptId && (
                                            <Typography variant="errorMessageBold">
                                                {errors.departmentId?.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="requested-by"
                                            shrink>
                                            {translations.requestedBy}
                                        </InputLabel>
                                        <Select
                                            data-testid="requestedby-dropdown-list"
                                            displayEmpty
                                            label={translations.requestedBy}
                                            labelId="requested-by"
                                            {...register("requestedBy", {
                                                required: translations.requiredRequestedBy,
                                            })}
                                            error={!!errors.requestedBy && !selectRequestedBy.contactId}
                                            value={selectRequestedBy.contactId || ""}
                                            onChange={handleContactChange}>
                                            <MenuItem value="">{translations.selectedRequestedBy}</MenuItem>
                                            {contactOptions?.map((option) => (
                                                <MenuItem
                                                    key={option.contactId}
                                                    value={option.contactId}>
                                                    {option.fullName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.requestedBy && !selectRequestedBy.contactId && (
                                            <Typography variant="errorMessageBold">
                                                {errors.requestedBy?.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs>
                                <TextField
                                    label={translations.requestedAgenda}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    inputProps={{
                                        "data-testid": "requested-agenda",
                                    }}
                                    size="small"
                                    variant="outlined"
                                    rows={3}
                                    placeholder={translations.requestedAgendaPlaceholder}
                                    multiline
                                    sx={{ bgcolor: "bg.white" }}
                                    value={requestedAgenda}
                                    {...register("agenda", {
                                        required: translations.validationText,
                                        maxLength: { value: 500, message: translations.maximumValidation },
                                        validate: (value: string) => {
                                            let trimmedValue = value.trim();
                                            let errorMessage =
                                                (trimmedValue && trimmedValue.length >= 3) ||
                                                translations.validationText;
                                            return errorMessage;
                                        },
                                    })}
                                    error={!!errors.agenda && !requestedAgenda && requestedAgenda?.length >= 3}
                                    onChange={(e) => handleRequestedAgendaChange(e)}
                                />
                                {errors.agenda && (!requestedAgenda || requestedAgenda?.length < 3) && (
                                    <Typography variant="errorMessageBold">{errors.agenda?.message}</Typography>
                                )}
                            </Grid>
                            <Grid
                                container
                                gap={2}>
                                <Grid
                                    item
                                    xs>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="requested-delivery-method"
                                            shrink>
                                            {translations.requestedDeliveryMethod}
                                        </InputLabel>
                                        <Select
                                            displayEmpty
                                            data-testid="requested-delivery-method-dropdown"
                                            label={translations.requestedDeliveryMethod}
                                            labelId="requested-delivery-method"
                                            {...register("eventMethod", {
                                                required: translations.requiredRequestDeliveryMethod,
                                            })}
                                            error={!!errors.eventMethod && !selectedDeliveryMethod}
                                            value={selectedDeliveryMethod ?? ""}
                                            onChange={handleRequestedDeliveryMethodChange}>
                                            <MenuItem value="">{translations.selectedDeliveryMethod}</MenuItem>
                                            <MenuItem value={DeliveryMethod.Virtual}>{DeliveryMethod.Virtual}</MenuItem>
                                            <MenuItem value={DeliveryMethod.InPerson}>
                                                {DeliveryMethod.InPerson}
                                            </MenuItem>
                                            <MenuItem value={DeliveryMethod.PhoneCall}>
                                                {DeliveryMethod.PhoneCall}
                                            </MenuItem>
                                        </Select>
                                        {errors.eventMethod && !selectedDeliveryMethod && (
                                            <Typography variant="errorMessageBold">
                                                {errors.eventMethod?.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Grid
                            container
                            justifyContent={"flex-end"}
                            columnSpacing={2}>
                            <Grid item>
                                <Button
                                    aria-label={translations.cancel}
                                    variant="contained"
                                    style={{
                                        color: "#FFFFFF",
                                        backgroundColor: text.textgray2,
                                    }}
                                    size="small"
                                    onClick={onClose}
                                    data-testid="cancel-button">
                                    {translations.cancel}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    aria-label={translations.submit}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    autoFocus={true}
                                    data-testid="submit-button">
                                    {translations.submit}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}
