/**
 * This enum represents the types of person which can login to system. 
 * This replicates PersonTypeEnum.cs from backend C# side. 
 */
export enum PersonTypeEnum {
    SYSTEM = "1",
    Employee = "2",
    Customer = "3",
    StrategicPartner = "4"
}
