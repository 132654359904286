import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../../common/BaseGridState";
import { DateRangeSearch } from "../../../models/IDateRangeSearch";
import { KeywordSearch } from "../../../models/IKeywordSearch";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { BaseApiState } from "../../common/BaseApiState";
import { dateRangeUntilNow } from "../../../utils/dateRange";
import { Asset } from "../../../models/assets/Asset";
import { baseGridInitialState, baseGridReducers } from "../baseGridsSlice";
import { RootState } from "../../store";
import { setDateRange, setDataReceivedWithError, setDataRequested, setKeywordSearch, setDataLoaded } from "../sharedReducers";

export interface AssetsState extends DateRangeSearch, KeywordSearch, BaseGridState, BaseApiState<Asset[]> {
    /** Shows the AddAsset Component on the assets grid */
    showAddAsset: boolean;
}

const initDate = dateRangeUntilNow(90);

const initialState: AssetsState = {
    ...baseGridInitialState,
    initialGridState: {
        sorting: {
            sortModel: [
                { field: "plannerActive", sort: "desc" },
                { field: "serialNumber", sort: "asc" },
            ],
        },
    },
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
    searchString: "",
    showAddAsset: false,
    startDate: initDate[0]!.format("YYYY-MM-DD"),
    endDate: initDate[1]!.format("YYYY-MM-DD"),
    isDateRangeValid: true,
};

export const assetsSlice = createSlice({
    name: "assets",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDateRange,
        setDataRequested,
        setDataReceivedWithError,
        setKeywordSearch,
        setDataLoaded,
        setShowAddAsset: (state, action: PayloadAction<boolean>) => {
            state.showAddAsset = action.payload;
        },
        dataReceived: (state, action: PayloadAction<Asset[]>) => {           
            state.data = action.payload;
             if (!state.initialDataLoaded) {
                 state.initialDataLoaded = true;
             }
            state.isLoading = false;
        },
    },
});

export const getStartDate = (state: RootState) => state.assets.startDate;
export const getEndDate = (state: RootState) => state.assets.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setShowAddAsset,
    setKeywordSearch: setSearchString,
    setDateRange: setSelectedDateRange,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = assetsSlice.actions;

export default assetsSlice.reducer;
