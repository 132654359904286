import { Badge, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/store";

type IProps = {
    title: string;
    icon: any;
    isSelected: boolean;
};

function AppActionMenu({ title, icon, isSelected }: IProps) {
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);

    return (
        <Box
            data-testid={"app-action-menu"}
            sx={{ display: "block", width: "60px", pb: 1, pt: "6px", verticalAlign: "center" }}>
            <Box sx={{ display: "block", textAlign: "center", mb: 0, verticalAlign: "center" }}>
                {icon}
                {title === "Todo" && (
                    <Badge
                        sx={{ mb: "20px" }}
                        badgeContent="2"
                        variant="standard"
                        color="error"
                        invisible={title !== "Todo"}
                    />
                )}
                {title === "Facilities" && (
                    <Badge
                        sx={{ mb: "20px" }}
                        badgeContent={selectedFacilities.length}
                        variant="standard"
                        color="primary"
                        invisible={title !== "Facilities"}
                    />
                )}
                {title !== "Facilities" && title !== "Todo" && (
                    <Badge
                        sx={{ mb: "20px" }}
                        badgeContent="0"
                        variant="standard"
                        color="primary"
                        invisible={true}
                    />
                )}
            </Box>
            <Typography
                color="initial"
                sx={{
                    color: isSelected ? "#3E8EDE" : "",
                    fontSize: "10px",
                    mt: "-1px",
                    textAlign: "center",
                }}>
                {title}
            </Typography>
        </Box>
    );
}

export default AppActionMenu;
