import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PageTitles } from "../../common/SiteMap";
import AssetsDevicePMInspectionHeader from "../../components/assets/AssetsDevicePMInspectionHeader";
import AssetsDevicePreventiveMaintenance from "../../components/assets/AssetsDevicePreventiveMaintenance";
import NotAuthorizeDisplay from "../../components/auth/NotAuthorizeDisplay";
import { claimTypes } from "../../config/claimTypes";
import { pageKeysAssets } from "../../config/data";
import { AuthLibrary } from "../../redux/actions/AuthRedux";

function DevicePreventiveMaintenance() {
    const navigate = useNavigate();
    const [pageKey, setPageKey] = useState(pageKeysAssets.AssetDevicePM);
    const params = useParams<{ customerAccountId: string; pmiLineId: string }>();
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.devicepm)}`,
    };
    const hasDevicePM = AuthLibrary.HasSubscriptionAccessToClaim(claimTypes.ViewDevicePreventiveMaintenance);

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {        
        if (params?.customerAccountId && params?.pmiLineId) {            
            setPageKey(pageKeysAssets.AssetDevicePMInsepctionDetails);
        }
    }, [params]);

    return (
        <Box
            bgcolor={"bg.lightGray"}
            data-testid="connectcare-device-preventive-maintenance">
            {!hasDevicePM && (
                <NotAuthorizeDisplay
                    handleClick={() => navigate("/")}
                    label={t("Back to Home Page")}
                />
            )}
            {hasDevicePM && pageKey === pageKeysAssets.AssetDevicePM && <AssetsDevicePreventiveMaintenance />}
            {hasDevicePM && pageKey === pageKeysAssets.AssetDevicePMInsepctionDetails && (
                <AssetsDevicePMInspectionHeader /> //to access this you goto assets/devicepm/event/:custaccountid/:pmiLineId
            )}
        </Box>
    );
}

export default DevicePreventiveMaintenance;