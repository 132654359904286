import { Box, Button, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, FormEvent, useState } from "react";
import { claimTypes } from "../../config/claimTypes";
import dayjs from "dayjs";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareEducations } from "../../services/apiPaths";
import { EducationGridModel } from "../../models/education/Education";
import { useTranslation } from "react-i18next";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor, InsightGrid, InsightLink } from "../../theme/theme";
import GridHeader from "../../component-library/GridHeader";
import { EducationInServiceGrid } from "../../components/education/EducationInServicesGrid";
import HoverTooltip from "../../component-library/HoverTooltip";
import { HoverTooltipText } from "../../component-library/HoverTooltipData";
import { EducationInServiceSearch } from "../../components/education/EducationInServiceSearch";
import NotAuthorizeDisplay from "../../components/auth/NotAuthorizeDisplay";
import { setToast } from "../../redux/reducers/toastSlice";
import BarChartIcon from "@mui/icons-material/BarChart";
import EducationInsights from "../../components/education/EducationInsights";
import RequestEducationEventDialog from "./RequestEducationEventDialog";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    selectedDateRange,
    setSelectedDateRange,
} from "../../redux/reducers/education/educationInServicesSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";

export const EducationInServices = () => {
    
    const dispatch = useAppDispatch();
    const { startDate, endDate, isDateRangeValid, searchString } = useAppSelector((state) => state.educationInServices);
    const dateRange = useAppSelector(selectedDateRange);
    const { selectedFacilities } = useAppSelector((state) => state.facility);
    
    const [viewInsights, setViewInsights] = useState<boolean>(false);
    const educationType = "INSERVICE";
    const hasViewEducationEvents = AuthLibrary.checkClaim(claimTypes.ViewAllEvents);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const { get } = useFetch();

    const { t } = useTranslation();
    const [openRequestEducationDialog, setOpenRequestEducationDialog] = useState(false);
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.educationInServices)}`,
        educationInServices: t("Education"),
        search: t("Search"),
        invalidDate: t("Education Date End must be after Education Date Start"),
        backToHomePage: t("DASHBOARD"),
        viewInsights: "View Insights",
        hideInsights: "Hide Insights",
        requestEducation: "Request Education",
    };

    const getEducationInServices = async () => {
        const uri = `${requestConnectCareEducations.EducationSearch}?SearchString=${searchString}&from=${startDate}&to=${endDate}&EducationType=${educationType}`;
        dispatch(dataRequested());
        const response = (await get<EducationGridModel[]>(uri, true)) as EducationGridModel[];
        if (response) {
            dispatch(dataReceived(response));
            dispatch(
                setToast({
                    showToast: false,
                })
            );
        } else {
            dispatch(dataReceivedWithError(response));
        }
    };

    useEffect(() => {
        if (selectedFacilities.length && hasViewEducationEvents) {
            getEducationInServices();
        }
        /**
         * it needs only two dependency to get loaded initially i.e. whenever this page gets loaded
         * getEducationInServices should not be included here, as it makes api call on every date change or search field change
         */
        // eslint-disable-next-line
    }, [selectedFacilities, hasViewEducationEvents]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getEducationInServices();
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    const handleClose = () => {
        setOpenRequestEducationDialog(false);
        getEducationInServices();
    };

    return (
        <Box
            data-testid="education-container"
            sx={{ backgroundColor: "bg.lightGray" }}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <Box style={GridBackgroundColor}>
                <BreadCrumb breadCrumbs={BreadCrumbList.educationInServices} />
                {/* **** Headers *** */}
                {!hasViewEducationEvents && <NotAuthorizeDisplay />}
                {hasViewEducationEvents && dayjs(dateRange[0], "MM/DD/YYYY").format("YYYY-MM-DD") && (
                    <Box p={theme.spacing(2)}>
                        <GridHeader
                            title={translations.educationInServices}
                            onFormSubmit={handleSearchSubmit}>
                            <Grid sx={{ color: "font.claret", marginLeft: "-10px" }}>
                                <HoverTooltip tooltip={HoverTooltipText.EducationInService} />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                marginY={1}
                                paddingX={1}>
                                <EducationInServiceSearch />
                            </Grid>
                            <Grid
                                item
                                md="auto"
                                marginY={1}
                                paddingX={1}>
                                <HeaderDateRangePicker
                                    dateRange={dateRange}
                                    onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                            </Grid>

                            <Grid
                                item
                                md="auto"
                                marginY={1}
                                paddingX={1}
                                display={"flex"}
                                gap={1}>
                                <Button
                                    aria-label={translations.search}
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    disabled={!isDateRangeValid}>
                                    {translations.search}
                                </Button>
                                {AuthLibrary.AccountSubscriptionHasClaim(claimTypes.RequestEducationEvent) && (
                                    <Button
                                        aria-label={translations.requestEducation}
                                        variant="outlined3"
                                        sx={{ marginTop: 0, height: 30, padding: 1 }}
                                        onClick={() => setOpenRequestEducationDialog(true)}>
                                        {translations.requestEducation}
                                    </Button>
                                )}
                            </Grid>
                            {!isDateRangeValid && (
                                <Grid
                                    item
                                    md="auto"
                                    marginY={1}
                                    paddingX={1}>
                                    <Typography
                                        pl={2}
                                        variant="body2"
                                        color={"error.dark"}>
                                        {translations.invalidDate}
                                    </Typography>
                                </Grid>
                            )}
                            {AuthLibrary.checkClaim(claimTypes.ViewGridPageMetrics) && (
                                <InsightGrid
                                    item
                                    sx={{ textAlign: "left", justifyContent: "left", alignItems: "left" }}>
                                    <BarChartIcon sx={{ color: "blue.connectCare2" }}></BarChartIcon>
                                    <InsightLink
                                        sx={{ marginTop: "7px" }}
                                        onClick={() => {
                                            setViewInsights(!viewInsights);
                                        }}>
                                        {viewInsights ? translations.hideInsights : translations.viewInsights}
                                    </InsightLink>
                                </InsightGrid>
                            )}
                            {viewInsights && <Divider /> && (
                                <EducationInsights
                                    fromDate={startDate}
                                    toDate={endDate}
                                />
                            )}
                        </GridHeader>
                        <Box py={theme.spacing(2)}>
                            <EducationInServiceGrid />
                        </Box>
                    </Box>
                )}
            </Box>
            {openRequestEducationDialog && <RequestEducationEventDialog onClose={handleClose} />}
        </Box>
    );
};
