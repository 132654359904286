import { Box, FormControl, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddAssetItem } from "../../../models/assets/AddAssetItem";

const DetailSection = ({
    newAsset,
    onSerialUpdated,
    isSaveAttempted,
}: {
    newAsset: AddAssetItem;
    onSerialUpdated: (serial: string) => void;
    isSaveAttempted: boolean;
}) => {
    const SERIAL_MAX_SELECTION = 50;
    const isSerialMaxError = newAsset.serial.length > SERIAL_MAX_SELECTION;
    const { t } = useTranslation();
    const translations = {
        details: t("Details"),
        serial: t("Serial"),
        maxLengthError: t("Maximum 50 characters."),
        requiredError: t("Required"),
    };

    return (
        <Box data-testid={"detail-section"}>
            <FormControl
                variant="outlined"
                fullWidth>
                <TextField
                    inputProps={{ "data-testid": "asset-serial-input" }}
                    label={translations.serial}
                    size="small"
                    value={newAsset.serial}
                    error={isSerialMaxError || (isSaveAttempted && newAsset.serial === "")}
                    onChange={(e) => {
                        onSerialUpdated(e.target.value);
                    }}
                />
            </FormControl>
            {isSerialMaxError && (
                <Typography
                    data-testid="serial-over-50-error"
                    variant="errorMessage">
                    {translations.maxLengthError}
                </Typography>
            )}
            {isSaveAttempted && newAsset.serial === "" && (
                <Typography
                    data-testid="serial-blank-error"
                    variant="errorMessage">
                    {translations.requiredError}
                </Typography>
            )}
        </Box>
    );
};

export default DetailSection;
