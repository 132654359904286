import { Box, Card, CardContent, Grid, LinearProgress, Typography, Button, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ServiceRequestDetail } from "../../models/assets/ServiceRequestDetail";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { FormatDateTime } from "../../common/Formatters";
import { SrnDetailItem } from "./SrnDetailItem";
import { PageTitles } from "../../common/SiteMap";
import { IActionMenuItems } from "../../component-library/ConnectCareActionButton";
import ServiceRequestCustomerDetails from "./ServiceRequestCustomerDetails";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";

const ServiceRequestConfirmation = ({
    serviceRequestNumber,
    actionButtons,
    isPrintClickRunning,
    srnToBePrint,
}: {
    serviceRequestNumber?: string;
    actionButtons: IActionMenuItems[];
    isPrintClickRunning: boolean;
    srnToBePrint: string;
}) => {
    const { get } = useFetch();
    const { t } = useTranslation();
    const [serviceRequestDetails, setServiceRequestDetails] = useState<ServiceRequestDetail | undefined>(undefined);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const element = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);

    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.srnDetails)}`,
        pageName: t("Service Request Details"),
        serviceRequestNumber: t("SRN #"),
        estimate: t("Estimate Required"),
        item: t("Item "),
        serial: t("Serial"),
        description: t("Description"),
        quantity: t("Qty"),
        disinfected: t("Disinfected"),
        problem: t("Problem"),
        createdLabel: t("Created"),
        byLabel: t("by"),
        srnNotFound: t("SRN not found for your selected facilities"),
        actions: t("Actions"),
        track: t("Track"),
        enterPO: t("Enter PO"),
        viewOrder: t("View Order"),
        preapproved: t("Preapproved"),
        estimateRequired: t("Estimate Required"),
        notDisinfected: t("Not Disinfected"),
        requestEquipment: t("Request Equipment"),
        success: t("Successful Equipment Request Submitted"),
        viewEquipment: t("View Recent Requests"),
    };

    const loadData = useCallback(async () => {
        setIsLoading(true);
        const uri = `${requestConnectCareOrders.ServiceRequestDetails}/${serviceRequestNumber}`;
        const response = await get<ServiceRequestDetail>(uri, true);
        if (response) {
            setServiceRequestDetails(response);
            setIsLoading(false);
        } else {
            dispatch(
                setToast({
                    showToast: true,
                    toastType: ToastTypes.Error,
                    toastMessage: translations.srnNotFound,
                })
            );
            setIsLoading(false);
            setHasError(true);
        }

        // get is left out of the dependency array on purpose
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceRequestNumber]);

    useEffect(() => {
        loadData();
        document.title = translations.documentTitle;
        if (hasError) {
            navigate(-1);
        }
    }, [loadData, hasError, navigate, translations.documentTitle]);

    useEffect(() => {
        if (showSuccessToast) {
            dispatch(
                setToast({
                    toastMessage: translations.success,
                    toastType: ToastTypes.Success,
                })
            );
            setShowSuccessToast(false);
        }
    }, [showSuccessToast, translations.success]);

    useEffect(() => {
        /*
         * scrollIntoView() method scrolls an element into the visible area of the browser window.
         * whenever "see more" is clicked, then element ref is used as a reference to scroll to that element which get expanded.
         */
        element?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    });

    return (
        <Box>
            <Grid container>
                <Grid
                    data-testid="detail-card"
                    item
                    xs={12}>
                    {isLoading ? (
                        <Box
                            textAlign={"center"}
                            mb={15}>
                            <CircularProgress
                                data-testid="progress-spinner"
                                size={40}
                            />
                        </Box>
                    ) : (
                        <Grid>
                            <Card>
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap",
                                        }}>
                                        <Box>
                                            <Typography
                                                variant="h5"
                                                color={"font.darkBlue"}
                                                textTransform={"uppercase"}
                                                mb={0}
                                                pt={1}>
                                                {translations.serviceRequestNumber}
                                                {serviceRequestNumber}
                                            </Typography>
                                            <Button
                                                sx={{ textTransform: "capitalize", padding: 0 }}
                                                onClick={() => setExpanded(!expanded)}>
                                                {expanded === true ? "See Less" : "See More"}
                                            </Button>
                                        </Box>
                                        <Box>
                                            {/**
                                             * Previously for the spinners/linera progress, they all spin when either is selected.
                                             * To fix this, we are checking the SRN for which user clicked Actions -> Reprint to display linear progress
                                             * for that specific SRN only.
                                             */}
                                            {srnToBePrint === serviceRequestNumber && (
                                                <Box height={0.5}>{isPrintClickRunning && <LinearProgress />}</Box>
                                            )}
                                            <Button
                                                data-testid="cc-action-button"
                                                id="cc-action-button"
                                                variant="contained"
                                                disabled={actionButtons[0]?.disable}
                                                onClick={actionButtons[0]?.onClick}>
                                                {actionButtons[0]?.name}
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "flex-start",
                                        }}>
                                        {serviceRequestDetails?.items.map((item) => (
                                            <SrnDetailItem
                                                key={item.orderLineId}
                                                translations={translations}
                                                item={item}
                                                srnDetails={serviceRequestDetails}
                                                setShowSuccessToast={setShowSuccessToast}
                                            />
                                        ))}
                                    </Box>
                                    {expanded && (
                                        <Box
                                            pt={2}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px 0px",
                                            }}
                                            ref={element}>
                                            <ServiceRequestCustomerDetails srnDetails={serviceRequestDetails} />
                                        </Box>
                                    )}
                                    <br />
                                    <Typography
                                        variant="footerValue"
                                        textTransform={"uppercase"}
                                        paddingLeft={0}>
                                        {translations.createdLabel}:{" "}
                                        {FormatDateTime(serviceRequestDetails?.creationDate)} {translations.byLabel}{" "}
                                        {serviceRequestDetails?.createdBy}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ServiceRequestConfirmation;
