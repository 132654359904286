import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "../../models/customers/Customer";
import { DateRangeSearch } from "../../models/IDateRangeSearch";
import { KeywordSearch } from "../../models/IKeywordSearch";
import { BaseGridState } from "../common/BaseGridState";
import { BaseApiState } from "../common/BaseApiState";
import { dateRangeUntilNow } from "../../utils/dateRange";
import { baseGridReducers, baseGridInitialState } from "./baseGridsSlice";
import { GridFilterModel, GridSortModel, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../store";
import { validators } from "../../utils/validators";

export interface CustomersState extends DateRangeSearch, KeywordSearch, BaseGridState, BaseApiState<Customer[]> {
    gridCustomerSubscriptionsFilter: GridFilterModel | undefined;
    gridCustomerSubscriptionsSort: GridSortModel | undefined;
    gridCustomerSubscriptionsColumns: GridColumnVisibilityModel | undefined;
}

const initDate = dateRangeUntilNow(30);

const initialState: CustomersState = {
    ...baseGridInitialState,
    searchString: "",
    gridCustomerSubscriptionsFilter: undefined,
    gridCustomerSubscriptionsSort: undefined,
    gridCustomerSubscriptionsColumns: undefined,
    isLoading: false,
    isError: false,
    initialDataLoaded: false,
    data: [],
    startDate: initDate[0]!.format("YYYY-MM-DD"),
    endDate: initDate[1]!.format("YYYY-MM-DD"),
    isDateRangeValid: true,
};

export const Customers = createSlice({
    name: "customers",
    initialState,
    reducers: {
        ...baseGridReducers,
        setCustomerSearch: (state, action: PayloadAction<string>) => {
            state.searchString = action.payload;
        },
        setHasInvalidDate: (state, action: PayloadAction<boolean>) => {
            state.initialDataLoaded = action.payload;
        },
        setGridCustomerSubscriptionsFilter: (state, action: PayloadAction<GridFilterModel | undefined>) => {
            state.gridCustomerSubscriptionsFilter = action.payload;
        },
        setGridCustomerSubscriptionsSort: (state, action: PayloadAction<GridSortModel | undefined>) => {
            state.gridCustomerSubscriptionsSort = action.payload;
        },
        setGridCustomerSubscriptionsColumns: (state, action: PayloadAction<GridColumnVisibilityModel | undefined>) => {
            state.gridCustomerSubscriptionsColumns = action.payload;
        },
        /**
         * Sets the globals state of the order items date range search.
         */
        setSelectedDateRange: (state, action: PayloadAction<DateRange<Dayjs>>) => {            
             const dateRange = action.payload;

             state.isDateRangeValid = validators.isDateRangeValid(dateRange);
             if (state.isDateRangeValid) {
                 state.startDate = dateRange[0]!.format("YYYY-MM-DD");
                 state.endDate = dateRange[1]!.format("YYYY-MM-DD");
             }
        },
        dataRequested: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.data = [];
        },
        dataReceived: (state, action: PayloadAction<Customer[]>) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        dataReceivedWithError: (state) => {
            state.isLoading = false;
            state.isError = true;
        },
    },
});

export const getStartDate = (state: RootState) => state.customers.startDate;
export const getEndDate = (state: RootState) => state.customers.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

//***Action creators are generated for each case reducer function***
export const {
    setGridCustomerSubscriptionsSort,
    setGridCustomerSubscriptionsFilter,
    setGridCustomerSubscriptionsColumns,
    setCustomerSearch,
    setGridFilter,
    setGridSort,
    setGridColumns,
    dataRequested,
    dataReceived,
    dataReceivedWithError,
} = Customers.actions;
export default Customers.reducer;
