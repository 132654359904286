import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoadingSpinnerState {
    showSpinner: boolean;
    spinnerText: string;
}

const initialState: LoadingSpinnerState = {
    showSpinner: false,
    spinnerText: "Loading. Please wait...",
};

export const loadingSpinnerSlice = createSlice({
    name: "loadingSpinner",
    initialState,
    reducers: {
        showSpinner: (state, action: PayloadAction<string>) => {
            state.showSpinner = true;
            state.spinnerText = action.payload;
        },
        hideSpinner: (state, _action: PayloadAction) => {
            state.showSpinner = false;
        },
    },
});

export const { showSpinner, hideSpinner } = loadingSpinnerSlice.actions;

export default loadingSpinnerSlice.reducer;
