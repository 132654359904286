import { Box, Grid, Typography, Alert, Button } from "@mui/material";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { requestConnectCareAdmin, requestConnectCareUsers } from "../../../services/apiPaths";
import { useFetch } from "../../../services/useFetch";
import { FormatListBulletedRounded, EventRepeatRounded, PersonRounded, AddCircleOutline } from "@mui/icons-material";
import { CustomerAccountDetail } from "../../../models/customers/CustomerAccountDetail";
import CustomerDetailGrid from "./CustomerDetailGrid";
import CustomerSubscriptionList from "./CustomerSubscriptionList";
import CustomerUserList from "./CustomerUserList";
import WarningIcon from "@mui/icons-material/Warning";
import { StyledBoxAlert } from "../../../theme/theme";
import { Customer } from "../../../models/customers/Customer";
import { TabLoader } from "../../../component-library/TabLoader";
import { ConnectCareTabs, ITab } from "../../../component-library/ConnectCareTabs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "../../../redux/store";

export default function CustomerDetail({ customerAccountId }:{ customerAccountId: string }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { get } = useFetch();
    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);
    const [isUsersCountLoading, setIsUsersCountLoading] = useState<boolean>(false);
    const [customerDetail, setCustomerDetail] = useState<CustomerAccountDetail | undefined>(undefined);
    const [isUsersCountError, setIsUsersCountError] = useState(false);
    const [customerSearchResult, setCustomerSearchResult] = useState<Customer>();
    const [usersCount, setUsersCount] = useState<number>(0);
    const { data: customerSearchResults } = useSelector((state: StoreState) => state.customers);

    const tabIds = { details: "Details", subscriptions: "Subscriptions", users: "Users" };
    const translations = {
        documentTitle: t(`${process.env.REACT_APP_STERIS_TITLE} - Customer Detail`),
        details: t("Details"),
        subscriptions: t("Subscriptions"),
        users: t("Users"),
        errorLoadingCustomerDetail: t("There was a problem loading Customer Detail"),
        addUser: t("Add User"),
    };

    const tabs: ITab[] = useMemo(
        () => [
            {
                tabId: tabIds.details,
                icon: <FormatListBulletedRounded />,
                children: <>{translations.details}</>,
            },
            {
                tabId: tabIds.subscriptions,
                icon: <EventRepeatRounded />,
                children: <>{translations.subscriptions}</>,
            },
            {
                tabId: tabIds.users,
                icon: <PersonRounded />,
                children: (
                    <TabLoader
                        isLoading={isUsersCountLoading}
                        labelText={translations.users}
                        isApiError={isUsersCountError}
                        count={usersCount}
                    />
                ),
            },
        ],
        [
            isUsersCountError,
            isUsersCountLoading,
            tabIds.details,
            tabIds.subscriptions,
            tabIds.users,
            translations.details,
            translations.subscriptions,
            translations.users,
            usersCount,
        ]
    );

    // Below state is to set up the initial tab to show while landing into Customer Details page
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(tabs[0]);

    const getCustomerDetail = useCallback(async () => {
        setIsDetailLoading(true);
        const uri = `${requestConnectCareAdmin.CustomerDetail}?customerAccountId=${customerAccountId}`;
        const response = await get<CustomerAccountDetail>(uri);
        if (response) {
            setCustomerDetail(response);
            const searchResult = customerSearchResults.find((x) => x.custAccountId === response.custAccountId);
            setCustomerSearchResult(searchResult);
        }
        setIsDetailLoading(false);
    }, [customerAccountId, get, customerSearchResults]);

    const getUsersCount = useCallback(async () => {
        setIsUsersCountError(false);
        setIsUsersCountLoading(true);
        const uri = `${requestConnectCareUsers.GetUsersCount}/${customerAccountId}`;
        const response = await get<number>(uri, true);
        const result = response as number;
        if (result >= 0) {
            setUsersCount(result);
        } else {
            setIsUsersCountError(true);
        }
        setIsUsersCountLoading(false);
    }, [customerAccountId, get]);

    useEffect(() => {
        document.title = translations.documentTitle;
        getCustomerDetail();
        getUsersCount();
    }, [translations.documentTitle, getCustomerDetail, getUsersCount]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(tabs.find((x) => x.tabId === newValue));
    };

    const customerDetailTabs = (
        <Grid data-testid="customer-detail-tabs">
            <ConnectCareTabs
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                tabs={tabs}
            />
            {!!customerDetail && (
                <>
                    {currentTab?.tabId === tabIds.details && <CustomerDetailGrid customerDetail={customerDetail} />}
                    {currentTab?.tabId === tabIds.subscriptions && (
                        <CustomerSubscriptionList
                            customerNumber={customerDetail.customerNumber}
                            customerAccountId={customerDetail.custAccountId}
                        />
                    )}
                    {currentTab?.tabId === tabIds.users && (
                        <CustomerUserList customerAccountId={customerDetail.custAccountId} />
                    )}
                </>
            )}
            {!isDetailLoading && !customerDetail && (
                <Alert severity="error">{translations.errorLoadingCustomerDetail}</Alert>
            )}
        </Grid>
    );

    return (
        <Box
            bgcolor="bg.light"
            border="1px solid #DFE0EB"
            borderRadius={2}
            margin={3}
            minHeight="80vh"
            overflow={"hidden"}>
            <Grid>
                {!isDetailLoading && customerDetail && (
                    <>
                        {customerSearchResult && customerSearchResult.alertMessage && (
                            <StyledBoxAlert>
                                <WarningIcon
                                    fontSize="small"
                                    sx={{ transform: "scaleX(-1)", color: "#F4DB8F" }}
                                />
                                <Typography
                                    style={{ paddingLeft: "5px" }}
                                    variant="approvalText">
                                    {customerSearchResult.alertMessage}
                                </Typography>
                            </StyledBoxAlert>
                        )}
                        <Grid
                            container
                            pl={4}
                            pt={3}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                            <Grid item>
                                <Typography
                                    data-testid="customer-name-id"
                                    variant="h1"
                                    color="font.claret"
                                    marginBottom={1}>
                                    {`${customerDetail?.customerName} [${customerDetail?.customerNumber}]`}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                pl={5}
                                sm={5}>
                                <Button
                                    sx={{ height: "32px", mt: "0px" }}
                                    aria-label={translations.addUser}
                                    data-testid="add-user"
                                    variant="contained"
                                    onClick={() =>
                                        navigate("/admin/userdetails", {
                                            state: { accountId: customerSearchResult?.accountId },
                                        })
                                    }>
                                    <AddCircleOutline sx={{ mr: 1 }} />
                                    {translations.addUser}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            {customerDetailTabs}
        </Box>
    );
}
