import { Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { validators } from "../utils/validators";

/**
 * Displays srn number cell and Link logic
 *
 * @param {string} value type string, params.value or the srn number.
 */
export function ServiceRequestCell(props: { value: string }) {
    const navigate = useNavigate();
    const { value } = props;
    const shouldSrnLink = validators.srnLink(value); //check for starting int or R

    return (
        <>
            {value != null && value.length > 0 && !shouldSrnLink && (
                <Box
                    data-testid="notLinkId"
                    sx={{ fontSize: "inherit", display: "inline" }}>
                    {value}
                </Box>
            )}
            {value != null && value.length > 0 && shouldSrnLink && (
                <Box data-testid="linkId">
                    <Link
                        onClick={() => {
                            navigate(`/orders/srn/${encodeURIComponent(value)}`);
                        }}>
                        {value}
                    </Link>
                </Box>
            )}
        </>
    );
}
