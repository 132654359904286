import { Alert, Box, Grid, LinearProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { claimTypes } from "../../config/claimTypes";
import { ccLocalstorage } from "../../config/data";
import { useFetch } from "../../services/useFetch";
import { FormatCurrency, FormatDate } from "../../common/Formatters";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { InvoiceHeader, InvoiceSummary } from "../../models/invoices/InvoiceAging";
import { requestConnectCareInvoices } from "../../services/apiPaths";
import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import InvoiceAgingItemsLines from "./InvoiceAgingItemsLines";
import InvoiceAgingItemsSummary from "./InvoiceAgingItemsSummary";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { FormatListBulleted, StickyNote2 } from "@mui/icons-material";
import InvoiceAgingDetails from "./InvoiceAgingDetails";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

function InvoiceAgingDetailsGrid() {
    const [isHeaderLoading, setIsHeaderLoading] = useState(true);
    const [isFooterLoading, setIsFooterLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const params = useParams<{ id?: string; groupId?: string }>();
    const { t } = useTranslation();
    const hasViewInvoiceDetails = AuthLibrary.checkClaim(claimTypes.ViewOpenInvoices);
    const [daysPastDue, setDaysPastDue] = useState(0);
    const [headerLine, setHeaderLine] = useState<InvoiceHeader | null>();
    const [summary, setSummary] = useState<InvoiceSummary | null>();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const { get } = useFetch();
    const tabIds = { items: "Items", details: "Details" };

    const translations = {
        invoice: t("Invoice"),
        dueBy: t("Due By"),
        customer: t("Customer"),
        invoiceDate: t("Invoice Date"),
        dueDate: t("Due Date"),
        backToDashboard: t("DASHBOARD"),
        pastDue: t("Past Due"),
        apiError: t("System Error: API is not available at this time!"),
        items: t("Items"),
        details: t("Details"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.invoiceDetails)}`,
    };

    const tabs: ITab[] = useMemo(
        () => [
            {
                tabId: tabIds.items,
                icon: <StickyNote2 />,
                children: <>{translations.items}</>,
            },
            {
                tabId: tabIds.details,
                icon: <FormatListBulleted />,
                children: <>{translations.details}</>,
            },
        ],
        [tabIds.details, tabIds.items, translations.details, translations.items]
    );

    // Below state is to set up the initial tab to show while landing into Invoice Details page
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(tabs[0]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(tabs.find((x) => x.tabId === newValue));
    };

    const getInvoiceHeaderDetails = useCallback(() => {
        setIsError(false);
        setIsHeaderLoading(true);
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        const uriInvoice = `${requestConnectCareInvoices.InvoiceDetailsHeader}/${params.id}`;
        fetch(uriInvoice, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setHeaderLine(data);
                setDaysPastDue(new Date().getTime() - new Date(data.dueDate).getTime());
            })
            .catch(() => {                
                setIsError(true);
            })
            .finally(() => {
                setIsHeaderLoading(false);
            });
    }, [params.id, selectedFacilities]);

    const getInvoiceSummary = useCallback(async () => {
        setIsFooterLoading(true);
        const uriInvoice = `${requestConnectCareInvoices.InvoiceDetailsSummary}/${params.id}`;
        const result = await get<InvoiceSummary>(uriInvoice, true);

        if (result) {
            setSummary(result);
        }

        setIsFooterLoading(false);
    }, [params.id, get]);

    useEffect(() => {
        document.title = translations.documentTitle;
        hasViewInvoiceDetails && getInvoiceHeaderDetails();
        getInvoiceSummary();
        return () => {};
    }, [
        hasViewInvoiceDetails,
        getInvoiceSummary,
        getInvoiceHeaderDetails,
        selectedFacilities,
        translations.documentTitle,
    ]);

    return (
        <Box
            bgcolor="bg.lightblue"
            minHeight="20vh">
            <BreadCrumb breadCrumbs={BreadCrumbList.invoiceDetails} />
            {!hasViewInvoiceDetails && <NotAuthorizeDisplay />}
            {hasViewInvoiceDetails && (
                <Box
                    data-testid="invoice-aging-detail"
                    bgcolor="bg.light"
                    border="1px solid #DFE0EB"
                    borderRadius={2}
                    paddingTop={"12px"}
                    marginTop="20px"
                    overflow={"hidden"}>
                    <Grid
                        paddingLeft={4}
                        paddingRight={4}>
                        {isError && (
                            <Grid
                                item
                                xs={12}
                                margin={2}>
                                <Alert severity="error">{translations.apiError}</Alert>
                            </Grid>
                        )}
                        {isHeaderLoading && (
                            <Box
                                width="100%"
                                paddingTop={4}
                                marginX={1}>
                                <LinearProgress />
                            </Box>
                        )}
                        {!isHeaderLoading && (
                            <Grid
                                container
                                justifyContent={"space-between"}
                                my={1}>
                                <Grid
                                    item
                                    xs={"auto"}>
                                    <Typography
                                        variant="body2"
                                        color="font.gray"
                                        marginBottom={2}>
                                        {headerLine?.customer}
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        color="font.darkBlue"
                                        data-testid="invoice-number"
                                        marginBottom={1}>
                                        {translations.invoice} #{headerLine?.invoiceNumber}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={"auto"}>
                                    <Typography
                                        variant="body2"
                                        color="font.gray"
                                        marginBottom={2}>
                                        {translations.dueBy + " : " + FormatDate(headerLine?.dueDate)}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        color="font.darkBlue">
                                        {FormatCurrency(headerLine?.balance, headerLine?.transactionCurrencyCode)}
                                        {(headerLine?.balance === undefined ? 0 : headerLine?.balance) > 0 &&
                                            daysPastDue > 0 && (
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        color: "white",
                                                        backgroundColor: "#E45052",
                                                        border: "none",
                                                        marginLeft: 2,
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                        paddingRight: 1,
                                                        paddingLeft: 1,
                                                        marginRight: 2,
                                                    }}>
                                                    {translations.pastDue}
                                                </Box>
                                            )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid>
                        <ConnectCareTabs
                            currentTab={currentTab}
                            handleTabChange={handleTabChange}
                            tabs={tabs}
                        />
                        {currentTab?.tabId === tabIds.items && !isFooterLoading && (
                            <Grid container>
                                <InvoiceAgingItemsLines
                                    id={params.id}
                                    transactionCurrencyCode={headerLine?.transactionCurrencyCode}
                                    invoiceNumber={headerLine?.invoiceNumber}
                                />
                                <InvoiceAgingItemsSummary
                                    summary={summary}
                                    headerLine={headerLine}
                                />
                            </Grid>
                        )}
                        {currentTab?.tabId === tabIds.details && !isFooterLoading && (
                            <InvoiceAgingDetails headerLine={headerLine} />
                        )}
                    </Grid>
                </Box>
            )}
        </Box>
    );
}
export default InvoiceAgingDetailsGrid;
