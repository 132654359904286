import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { Alert, Card, CardContent, CardHeader, CardMedia, CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { OrderDetailPhotoCard } from "../../models/orders/OrderDetailPhotoCard";
import { MissingPhoto } from "../photos/MissingPhoto";

export default function OrderDetailsPhotos({
    headerId,
    srn,
}: {
    headerId: string | undefined;
    srn: string | undefined;
}) {
    const { t } = useTranslation();
    const { get } = useFetch();
    const [orderDetailPhotoCards, setOrderDetailPhotoCards] = useState<OrderDetailPhotoCard[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const translations = {
        apiError: t("System Error: API is not available at this time!"),
        photosNotFound: t("No Photos Found!"),
    };
    const getOrderDetailPhotoCards = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        const uri = `${requestConnectCareOrders.OrderDetailPhotos}?headerId=${headerId}&srn=${encodeURIComponent(
            srn ?? ""
        )}`;
        const response = await get<OrderDetailPhotoCard[]>(uri, true);
        const result = response as OrderDetailPhotoCard[];
        if (result) {
            setOrderDetailPhotoCards(result);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [headerId, srn, get]);

    useEffect(() => {
        getOrderDetailPhotoCards();
    }, [getOrderDetailPhotoCards]);

    return (
        <Box
            data-testid="order-details-photos-tab"
            p={2}
            width="100%"
            height={orderDetailPhotoCards && orderDetailPhotoCards.length > 0 ? "575px" : "inherit"}
            display={"flex"}>
            {isLoading && <CircularProgress />}
            {!isLoading && isError && <Alert severity="error">{translations.apiError}</Alert>}
            {!isLoading && !isError && (
                <>
                    {orderDetailPhotoCards?.length ? (
                        <Grid
                            paddingLeft="20px"
                            paddingTop="20px"
                            paddingBottom="10px"
                            sx={{ flexGrow: 1, overflowY: "auto" }}
                            container
                            spacing={2}>
                            {orderDetailPhotoCards?.map((photo) => {
                                return (
                                    <Card
                                        key={`photoCard_${photo.id}`}
                                        variant="elevation">
                                        <CardHeader
                                            sx={{ paddingBottom: 0 }}
                                            title={<Typography fontSize={12}>{photo.lineNumber}</Typography>}
                                        />
                                        <CardHeader
                                            sx={{ paddingTop: 0 }}
                                            title={
                                                <Typography
                                                    textTransform={"uppercase"}
                                                    fontSize={12}
                                                    fontWeight={600}>
                                                    {photo.description}
                                                </Typography>
                                            }
                                        />
                                        <Typography
                                            textTransform={"uppercase"}
                                            fontSize={15}
                                            fontWeight={700}
                                            align="center"
                                            gutterBottom
                                            component="h3">
                                            {photo.photoState}
                                        </Typography>
                                        {photo.photoUrl.includes("/images/noimage") && <MissingPhoto />}
                                        {!photo.photoUrl.includes("/images/noimage") && (
                                            <CardMedia
                                                height="185px"
                                                onClick={() =>
                                                    window.open(photo.photoUrl, "_blank", "noopener,noreferrer")
                                                }
                                                component="img"
                                                image={photo.photoUrl}
                                            />
                                        )}
                                        <CardContent>
                                            <Typography
                                                fontWeight={300}
                                                fontSize={12}
                                                gutterBottom
                                                component="h3">
                                                {photo.comments}
                                            </Typography>
                                        </CardContent>
                                        {photo.category && (
                                            <Typography
                                                variant="detailLabel"
                                                fontSize={10}
                                                align="left"
                                                gutterBottom
                                                component="h3">
                                                {photo.category}
                                            </Typography>
                                        )}
                                    </Card>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Alert severity="info">{translations.photosNotFound}</Alert>
                    )}
                </>
            )}
        </Box>
    );
}
