import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Alert, Grid, LinearProgress, TableHead, Link } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormatDateTime } from "../../common/Formatters";
import { useState, useEffect, useCallback } from "react";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { GridBackgroundColor } from "../../theme/theme";

export interface IOrderHistoryDetails {
    line: number;
    serialNumber: string;
    customerAccountId: number;
    inventoryItemId: number;
    description: string;
    orderDetailsHistoryLine: IOrderHistoryLineDetails[];
}

export interface IOrderHistoryLineDetails {
    creationDate: Date;
    status: string;
}

function Row({ row }: { row: IOrderHistoryDetails }) {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translations = {
        updated: t("Updated"),
        status: t("Status"),
    };

    return (
        <>
            <TableRow style={{ backgroundColor: open ? "#E8F2FC" : "" }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell
                    component="th"
                    scope="row">
                    {row.line}
                </TableCell>
                <TableCell>
                    <Link
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                            navigate(`/assets/${row.customerAccountId}/${row.inventoryItemId}/${row.serialNumber}`);
                        }}>
                        {row.serialNumber}
                    </Link>
                </TableCell>
                <TableCell>{row.description}</TableCell>
            </TableRow>
            {open && (
                <TableRow>
                    <TableCell colSpan={3}></TableCell>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={1}>
                        <Collapse
                            in={open}
                            timeout="auto"
                            unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{translations.updated}</TableCell>
                                            <TableCell>{translations.status}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.orderDetailsHistoryLine.map((lineDetailsRow, index) => (
                                            <TableRow key={index}>
                                                <TableCell
                                                    component="th"
                                                    scope="row">
                                                    {FormatDateTime(lineDetailsRow.creationDate)}
                                                </TableCell>
                                                <TableCell>{lineDetailsRow.status}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

function OrderHistoryDetails({ headerId }: { headerId: string }) {
    const { t } = useTranslation();
    const [orderHistoryDetail, setOrderHistoryDetail] = useState<IOrderHistoryDetails[]>([]);
    const { get } = useFetch();
    const translations = {
        line: t("Line"),
        serial: t("Serial"),
        description: t("Description"),
        updated: t("Updated"),
        status: t("Status"),
        apiError: t("System Error: API is not available at this time!"),
        noDetails: t("There are currently no details to display"),
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const getOrderHistoryDetails = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        const uriOrder = `${requestConnectCareOrders.OrderHistoryDetails}?headerId=${headerId}`;
        const response = await get<IOrderHistoryDetails[]>(uriOrder, true);
        if (response) {
            setOrderHistoryDetail(response);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [headerId, get]);

    useEffect(() => {
        getOrderHistoryDetails();
    }, [getOrderHistoryDetails]);

    return (
        <Box
            style={GridBackgroundColor}
            px={2}
            py={2}
            data-testid="order-history-details">
            <Box>
                <Grid
                    container
                    bgcolor="bg.light"
                    border={1}
                    borderColor="font.gray2"
                    borderRadius={1}
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap">
                    <Grid
                        item
                        width="100%">
                        {isLoading && <LinearProgress></LinearProgress>}
                        {!isLoading && isError && (
                            <Grid
                                marginX={4}
                                marginY={1}>
                                <Alert severity="error">{translations.apiError}</Alert>
                            </Grid>
                        )}
                        {!isLoading && !isError && orderHistoryDetail && orderHistoryDetail?.length > 0 && (
                            <TableContainer>
                                <Table aria-label="customized table">
                                    <TableHead style={{ borderBottom: "1px solid #DFE0EB" }}>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>{translations.line}</TableCell>
                                            <TableCell>{translations.serial}</TableCell>
                                            <TableCell>{translations.description}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderHistoryDetail &&
                                            orderHistoryDetail.map((a, index) => (
                                                <Row
                                                    key={index}
                                                    row={a}
                                                />
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        {!isLoading && !isError && (!orderHistoryDetail || orderHistoryDetail?.length === 0) && (
                            <Grid
                                item
                                xs={12}
                                mx={0}
                                mb={2}>
                                <br />
                                <Alert severity="info">{translations.noDetails}</Alert>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
export default OrderHistoryDetails;
