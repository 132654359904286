import { DateRange } from "@mui/x-date-pickers-pro";
import { PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";
import { validators } from "../../utils/validators";

interface State {
    [key: string]: any;
}

/**
 * Validates and sets the daterange search as formatted strings for grids.
 * @param state The state.
 * @param action The daterange payload to set in state.
 */
export const setDateRange = (state: State, action: PayloadAction<DateRange<Dayjs>>) => {
    const dateRange = action.payload;

    state.isDateRangeValid = validators.isDateRangeValid(dateRange);
    if (state.isDateRangeValid) {
        state.startDate = dateRange[0]!.format("YYYY-MM-DD");
        state.endDate = dateRange[1]!.format("YYYY-MM-DD");
    }
};

export const setDataLoaded = (state: State, action: PayloadAction<boolean>) => {
    state.initialDataLoaded = action.payload;
};

//Shared API reducers.
//When RTK hits we can remove these.
export const setDataRequested = (state: State) => {
    state.isLoading = true;
    state.isError = false;
    state.data = [];
};

export const setDataReceived = (state: State, action: PayloadAction<[]>) => {
    state.data = action.payload;
    if (!state.initialDataLoaded) {
        state.initialDataLoaded = true;
    }
    state.isLoading = false;
};

export const setDataReceivedWithError = (state: State) => {
    state.isLoading = false;
    state.isError = true;
};

/**
 * Sets the keyword search for grids.
 * @param state The state
 * @param action The keyword payload to set in state.
 */
export const setKeywordSearch = (state: State, action: PayloadAction<string>) => {
    state.searchString = action.payload;
};
