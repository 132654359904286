import { Box, Link, Typography } from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IRoundIconLookup, RoundIconLookupList } from "../common/IRoundIconLookup";
import { useTranslation } from "react-i18next";

/**
 * This component can be used to render MUI DataGridPro cell value that needs
 * FiberManualRecordRoundedIcon icon and value for the cell.
 * It determines color for FiberManualRecordRoundedIcon based on
 * GridRenderCellParams value provided and returns Box component containing
 * the icon and value
 * @param value: The cell value to display
 * @returns Box component containing FiberManualRecordRoundedIcon and GridRenderCellParams value
 */
export function GridCellValueWithRoundIcon({ row, value }: GridRenderCellParams) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const roundIcon: IRoundIconLookup = RoundIconLookupList.find((x) => x.displayText === value)!;

    return (
        value && (
            <Box>
                <Box
                    display={"flex"}
                    alignItems="center">
                    <FiberManualRecordRoundedIcon
                        fontSize="small"
                        sx={{
                            color: roundIcon ? roundIcon.iconColor : "",
                            verticalAlign: "bottom",
                        }}
                    />
                    {roundIcon?.showLink && (
                        <Link
                            underline="none"
                            onClick={() => {
                                navigate(`/assets/devicepm/event/${row.custAccountId}/${row.id}`); //This will navigate to DevicePreventiveMaintenanceEventDetails.tsx
                            }}>
                            {t(value)}
                        </Link>
                    )}
                    {!roundIcon?.showLink && (
                        <Typography
                            variant="detailValue"
                            textTransform="initial">
                            {t(value)}
                        </Typography>
                    )}
                </Box>
            </Box>
        )
    );
}
