import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ccLocalstorage } from "../../config/data";
import { requestConnectCareInventory } from "../../services/apiPaths";
import AssetsDueForPreventiveMaintenance from "../../components/assets/AssetsDueForPreventiveMaintenance";
import { AssetDueForPreventiveMaintenance } from "../../models/assets/AssetDueForPreventiveMaintenance";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { PageTitles } from "../../common/SiteMap";
import { GridBackgroundColor } from "../../theme/theme";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

function DueForPreventiveMaintenance() {
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [assets, setAssets] = useState<AssetDueForPreventiveMaintenance[]>([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responseStatusCode, setResponseStatusCode] = useState(0);
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.dueforpm)}`,
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const params = useParams<{ category?: string }>();
    const emptyFilterModel: GridFilterModel = { items: [] };
    const trayFilterModel: GridFilterModel = {
        items: [{ id: 1, field: "category", operator: "contains", value: "tray" }],
    };
    const flexScopeFilterModel: GridFilterModel = {
        items: [{ id: 1, field: "productLine", operator: "contains", value: "Flexible Scope" }],
    };
    const deviceFilterModel: GridFilterModel = {
        items: [
            { id: 1, field: "productLine", operator: "not", value: "Instrument" },
            { id: 2, field: "productLine", operator: "not", value: "Mobile Van" },
        ],
    };

    const getAssetDueForPreventiveMaintenanceList = useCallback(() => {
        setIsError(false);
        setIsLoading(true);

        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareInventory.AssetDueForPreventiveMaintenanceList}`;

        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        fetch(uri, requestOptions)
            .then((response) => {
                setResponseStatusCode(response.status);
                return response.status === 200 ? response.json() : [];
            })
            .then((data) => {
                setAssets(data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectedFacilities]);

    /**
     * Gets a grid filter model based on params
     */

    const getModel = (params: any): GridFilterModel => {
        if (params) {
            if (params.category === "tray") {
                return trayFilterModel;
            } else if (params.category === "flex-scope") {
                return flexScopeFilterModel;
            } else if (params.category === "device") {
                return deviceFilterModel;
            }
        }
        return emptyFilterModel;
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        selectedFacilities?.length && getAssetDueForPreventiveMaintenanceList();
    }, [selectedFacilities, getAssetDueForPreventiveMaintenanceList]);

    return (
        <Box
            data-testid="connectcare-assets-due-for-preventive-maintenance"
            style={GridBackgroundColor}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <AssetsDueForPreventiveMaintenance
                assetDueForPreventiveData={assets}
                isLoading={isLoading}
                isError={isError}
                responseStatusCode={responseStatusCode}
                filterModel={getModel(params)}
            />
        </Box>
    );
}

export default DueForPreventiveMaintenance;
