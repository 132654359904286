import { Box, Typography, Card, CardContent, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReportCard } from "../../models/reports/ReportCard";


/**
 * Mui Cards for the different reports on the Reports page.
 */

export interface IReportCardTileProps {
    reportsCardData?: ReportCard;
}

export const ReportsCard = (props: Readonly<IReportCardTileProps>) => {
    const { t } = useTranslation();

    const translations = {
        repairHistory: t("Repair History"),
        view: t("View")
    };

    return (
        <Box data-testid="Repair-History-Card">
            <Card
                variant="outlined"
                sx={{
                    width: 275,
                    height: "100%",
                    textAlign: "center",
                    border: 1,
                    boxShadow: 1,
                    borderColor: "white",
                }}>
                <CardContent>
                    <Typography
                        variant="h5"
                        color={"font.darkBlue"}
                        marginBottom={2}
                        sx={{minHeight: "50px"}}>
                        {props.reportsCardData?.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color={"font.darkBlue"}
                        sx={{minHeight: "100px"}}>
                        {props.reportsCardData?.description}
                    </Typography>
                    <Button
                        aria-label={translations.view}
                        sx={{ marginTop: "10px"}}
                        variant="contained"
                        size="small"
                        href={props.reportsCardData?.href}>
                        {translations.view}
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
};
