import { Typography, Tabs, Tab, Box } from "@mui/material";
import { blue, text } from "../theme/colors";
import React from "react";

export type ITab = { tabText: string; isDisabledTab: boolean; icon: JSX.Element; children?: JSX.Element };

export interface FindMyItemTabsProps {
    currentTab: ITab | undefined;
    handleTabChange: (_event: React.SyntheticEvent, newValue: string) => void;
    tabs: ITab[];
}

/**
 * This component can be used to render MUI Tabs
 * @param currentTab: MuiTab that is currently selected
 * @param handleTabChange: Event to trigger while the user is selecting the other tabs
 * @param tabs: Arrays of tabs with Text on MuiTab and MuiIcon and Spinner as optional
 * @returns MuiTabs
 */

export const FindMyItemTabs = (props: Readonly<FindMyItemTabsProps>) => {
    return (
        <Tabs
            value={props.currentTab?.tabText}
            variant="scrollable"
            aria-label="scrollable"
            onChange={props.handleTabChange}>
            {props.tabs?.map((tab: ITab) => (
                <Tab
                    sx={{
                        backgroundColor: props.currentTab?.tabText === tab.tabText ? blue.connectCare2 : text.textWhite,
                    }}
                    key={tab.tabText}
                    value={tab.tabText}
                    disabled={tab.isDisabledTab}
                    label={
                        <Typography variant={props.currentTab?.tabText === tab.tabText ? "activeTab" : "inActiveTab"}>
                            {tab.children}
                        </Typography>
                    }
                    icon={
                        <Box
                            sx={{
                                mr: 1,
                                mt: 0.5,
                                color: props.currentTab?.tabText === tab.tabText ? text.textWhite : text.textgray2,
                            }}>
                            {tab.icon}
                        </Box>
                    }
                />
            ))}
        </Tabs>
    );
};
