import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { setSearchString } from "../../redux/reducers/education/educationSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";

export default function EducationEventSearch() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const label = t("Education Search");
    const placeholder = t("Search");
    const searchString = useAppSelector((state) => state.education.searchString);

    return (
        <TextField
            inputProps={{ "data-testid": "education-event-search" }}
            fullWidth
            aria-label={label}
            placeholder={placeholder}
            size="small"
            id="educationEventSearch"
            variant="outlined"
            autoComplete="off"
            value={searchString}
            onChange={(e) => dispatch(setSearchString(e.target.value))}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }}
        />
    );
}
