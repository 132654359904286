import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ServiceRequestDetail } from "../../models/assets/ServiceRequestDetail";
import { SRNCustomerDetails } from "../../theme/theme";

const ServiceRequestCustomerDetails = ({ srnDetails }: { srnDetails: ServiceRequestDetail | undefined }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const { t } = useTranslation();
    const translations = {
        customer: t("Customer"),
        returnShippingAddress: t("Return Address"),
        shippingMethod: t("Shipping Method"),
        shippingAccount: t("Shipping Account"),
        department: t("Department"),
        po: t("PO Number"),
        contact: t("Contact"),
        reference: t("Reference"),
    };

    const checkDeviceWidthMatch = () => {
        return matches ? "100px" : "40px";
    };
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: "0px 60px",
                }}>
                <Typography
                    variant="labelNonBoldCapitalize"
                    minWidth={checkDeviceWidthMatch}
                    maxWidth={checkDeviceWidthMatch}>
                    {translations.customer}
                </Typography>
                <Typography variant="detailValueWithColor">{srnDetails?.customerName}</Typography>
            </Box>
            <SRNCustomerDetails>
                <Typography
                    variant="labelNonBoldCapitalize"
                    minWidth={checkDeviceWidthMatch}
                    maxWidth={checkDeviceWidthMatch}>
                    {translations.returnShippingAddress}
                </Typography>
                <Typography variant="detailValueWithColor">{srnDetails?.returnAddress}</Typography>
            </SRNCustomerDetails>
            <SRNCustomerDetails>
                <Typography
                    variant="labelNonBoldCapitalize"
                    minWidth={checkDeviceWidthMatch}
                    maxWidth={checkDeviceWidthMatch}>
                    {translations.department}
                </Typography>
                <Typography variant="detailValueWithColor">{srnDetails?.department}</Typography>
            </SRNCustomerDetails>
            <SRNCustomerDetails>
                <Typography
                    variant="labelNonBoldCapitalize"
                    minWidth={checkDeviceWidthMatch}
                    maxWidth={checkDeviceWidthMatch}>
                    {translations.contact}
                </Typography>
                <Typography variant="detailValueWithColor">{srnDetails?.contactName}</Typography>
            </SRNCustomerDetails>
            {srnDetails?.po && (
                <>
                    <SRNCustomerDetails>
                        <Typography
                            variant="labelNonBoldCapitalize"
                            minWidth={checkDeviceWidthMatch}
                            maxWidth={checkDeviceWidthMatch}>
                            {translations.po}
                        </Typography>
                        <Typography variant="detailValueWithColor">{srnDetails?.po}</Typography>
                    </SRNCustomerDetails>
                </>
            )}
            {srnDetails?.reference && (
                <SRNCustomerDetails>
                    <Typography
                        variant="labelNonBoldCapitalize"
                        minWidth={checkDeviceWidthMatch}
                        maxWidth={checkDeviceWidthMatch}>
                        {translations.reference}
                    </Typography>
                    <Typography variant="detailValueWithColor">{srnDetails?.reference}</Typography>
                </SRNCustomerDetails>
            )}
            <SRNCustomerDetails>
                <Typography
                    variant="labelNonBoldCapitalize"
                    minWidth={checkDeviceWidthMatch}
                    maxWidth={checkDeviceWidthMatch}>
                    {translations.shippingAccount}
                </Typography>
                <Typography variant="detailValueWithColor">{srnDetails?.shippingAccount}</Typography>
            </SRNCustomerDetails>
            <SRNCustomerDetails>
                <Typography
                    variant="labelNonBoldCapitalize"
                    minWidth={checkDeviceWidthMatch}
                    maxWidth={checkDeviceWidthMatch}>
                    {translations.shippingMethod}
                </Typography>
                <Typography variant="detailValueWithColor">{srnDetails?.shippingMethod}</Typography>
            </SRNCustomerDetails>
        </>
    );
};

export default ServiceRequestCustomerDetails;
