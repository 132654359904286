import { useEffect, useState, useCallback } from "react";
import {
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    tableCellClasses,
    Paper,
    LinearProgress,
    Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { EducationDetailAttendees } from "../../models/education/Education";
import { requestConnectCareEducations } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";

export default function EducationDetailAttendee({ educationId, message }: { educationId: string; message: any }) {
    const { t } = useTranslation();
    const [educationDetailsAttendees, seteducationDetailsAttendees] = useState<EducationDetailAttendees[]>([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { get } = useFetch();

    const translations = {
        name: t("Name"),
        title: t("Title"),
        email: t("Email"),
        noAttendees: t("There are no attendee names listed for this event."),
    };

    const geteducationDetailsAttendees = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);
        const uri = `${requestConnectCareEducations.EducationDetailAttendees}?eventId=${educationId}`;
        const response = await get<EducationDetailAttendees[]>(uri, true);
        if (response) {
            seteducationDetailsAttendees(response);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [educationId, get]);

    useEffect(() => {
        geteducationDetailsAttendees();
    }, [geteducationDetailsAttendees]);

    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            paddingLeft: "80px",
            color: "#8E8E8E",
            lineHeight: "23px",
        },
        [`&.${tableCellClasses.body}`]: {
            width: "20%",
            paddingLeft: "80px",
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#F7F7F7",
            border: "1px solid #DFE0EB",
        },
    }));

    return (
        <>
            <Box
                display={"flex"}
                flexDirection={"row-reverse"}>
                {message && <Alert severity="info">{message}</Alert>}
            </Box>
            <Box
                bgcolor="bg.white"
                border="1px solid #DFE0EB"
                minHeight="31vh"
                sx={{ width: "100%" }}
                data-testid="education-detail-attendees-grid">
                {isLoading && <LinearProgress></LinearProgress>}
                {!isLoading && !isError && educationDetailsAttendees && educationDetailsAttendees?.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell color="{font.gray}">{translations.name}</StyledTableCell>
                                    <StyledTableCell>{translations.title}</StyledTableCell>
                                    <StyledTableCell>{translations.email}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {educationDetailsAttendees &&
                                    educationDetailsAttendees.map((educationDetailsTopic) => (
                                        <StyledTableRow
                                            key={educationDetailsTopic.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <StyledTableCell>{educationDetailsTopic.name}</StyledTableCell>
                                            <StyledTableCell>{educationDetailsTopic.title}</StyledTableCell>
                                            <StyledTableCell>{educationDetailsTopic.email}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {!isLoading && (!educationDetailsAttendees || educationDetailsAttendees?.length === 0) && (
                    <Grid
                        item
                        xs={12}
                        mx={0}
                        mb={2}
                        color={"font.gray"}>
                        <br />
                        {translations.noAttendees}
                    </Grid>
                )}
            </Box>
        </>
    );
}
