import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoanersDue } from "../../models/serviceSummary/LoanersDue";
import ItemsDisinfectedControl from "../../component-library/ItemsDisinfectedControl";

export interface ILoanerReturnDialogContentProps {
    loanerDue: LoanersDue;
    itemsDisinfected: boolean | null;
    updateItemsDisinfected: (val: boolean | null) => void;
}

const LoanerReturnDialogContent = (props: ILoanerReturnDialogContentProps) => {
    const { loanerDue, updateItemsDisinfected, itemsDisinfected } = props;
    const { t } = useTranslation();

    const translations = {
        srn: t("srn"),
        itemsDisinfected: t("items disinfected"),
        serial: t("serial"),
        manufacturer: t("manufacturer"),
        model: t("model"),
    };

    return (
        <Grid
            data-testid="loaner-return-dialog-content"
            container
            rowSpacing={2}
            my={1}>
            <Grid
                item
                xs={12}>
                <Typography variant={"h4"}>{loanerDue.customer}</Typography>
            </Grid>
            <Grid
                item
                xs={5}>
                <Typography variant={"detailLabel"}>{translations.srn}</Typography>
                <Typography variant={"detailValue"}>{loanerDue.srn}</Typography>
            </Grid>
            <Grid
                item
                xs={7}>
                {!loanerDue.returnOrderId && (
                    <ItemsDisinfectedControl
                        value={itemsDisinfected}
                        onChange={updateItemsDisinfected}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={4}>
                <Typography variant={"detailLabel"}>{translations.serial}</Typography>
                <Typography variant={"detailValue"}>{loanerDue.serialNumber}</Typography>
            </Grid>
            <Grid
                item
                xs={4}>
                <Typography variant={"detailLabel"}>{translations.manufacturer}</Typography>
                <Typography variant={"detailValue"}>{loanerDue.manufacturer}</Typography>
            </Grid>
            <Grid
                item
                xs={4}>
                <Typography variant={"detailLabel"}>{translations.model}</Typography>
                <Typography variant={"detailValue"}>{loanerDue.model}</Typography>
            </Grid>
            <Grid
                item
                xs={7}></Grid>
        </Grid>
    );
};
export default LoanerReturnDialogContent;
