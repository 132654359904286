import { useTranslation } from "react-i18next";
import { PageTitles } from "../../common/SiteMap";
import { GridColDef, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-pro";
import { Box, Button, Grid, LinearProgress, Link } from "@mui/material";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { useDispatch, useSelector } from "react-redux";
import { setGridColumns, setGridFilter, setGridSort } from "../../redux/reducers/orders/equipmentRequestReducer";
import { StoreState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { SrnCreationProvider } from "../../contexts/SrnCreationContext";
import { EquipmentRequestForm } from "../../components/serviceRequest/EquipmentRequestForm";
import { useEffect, useState } from "react";
import { setToast } from "../../redux/reducers/toastSlice";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { EquipmentRequestFormDto } from "../../models/orders/EquipmentRequestFormDto";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { PoValidationStatusDto } from "../../models/orders/PoValidationStatusDto";
import { useFetch } from "../../services/useFetch";

export interface EquipmentRequestGridProps {
    loading: boolean;
    getEquipmentRequestRecords: () => Promise<void>;
}

export const EquipmentRequestGrid = (props: EquipmentRequestGridProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { get } = useFetch();
    const [equipmentRequestData, setEquipmentRequestData] = useState<EquipmentRequestFormDto>();
    const [openEquipmentRequestFormDialog, setOpenEquipmentRequestFormDialog] = useState<boolean>(false);
    const [equipmentRequested, setEquipmentRequested] = useState<boolean>(false);
    const [poValidationRequired, setPoValidationRequired] = useState<boolean>(true);

    const translations = {
        action: t("Action"),
        srn: t("SRN"),
        customer: t("Customer"),
        repairItemSerial: t("Repair Item Serial"),
        repairItemManufacturer: t("Repair Item Manufacturer"),
        repairItemModel: t("Repair Item Model"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.assets)}`,
        request: t("Request"),
        success: t("Successful Equipment Request Submitted"),
    };

    useEffect(() => {
        if (equipmentRequested) {
            dispatch(
                setToast({
                    toastMessage: translations.success,
                    toastType: ToastTypes.Success,
                })
            );
            setEquipmentRequested(false);
            props.getEquipmentRequestRecords();
        }
        //once user submit Equipment Reques Form, existence of props in dependency array is creating unwanted re-rendering of this component an infinite times
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipmentRequested, translations.success, dispatch]);

    const handleRequestEquipment = (row: GridRowModel<EquipmentRequestFormDto>) => {
        getPoValidationRequirement(row.custAccountId);
        setEquipmentRequestData(row);
        setOpenEquipmentRequestFormDialog(true);
    };

    const getPoValidationRequirement = async (custAccountId: number) => {
        const uri = `${requestConnectCareOrders.GetPoValidationStatus}?custAccountId=${custAccountId}`;
        const response = await get<PoValidationStatusDto>(uri);
        if (response) {
            setPoValidationRequired(response.isEnabled);
        }
    };

    const handleClose = () => {
        setOpenEquipmentRequestFormDialog(false);
    };

    const { gridColumns, gridFilter, gridSort, rows } = useSelector((state: StoreState) => state.equipmentRequest);

    const columns: GridColDef[] = [
        {
            field: "action",
            headerName: translations.action,
            renderHeader: () => translations.action,
            minWidth: 120,
            flex: 1,
            renderCell: (params: GridRenderCellParams) =>
                !params.row.equipmentRequestStatus &&
                AuthLibrary.HasAccountSubscriptionAccessToClaim(
                    claimTypes.RequestEquipment,
                    params.row.custAccountId
                ) ? (
                    <Button
                        variant="contained"
                        onClick={() => handleRequestEquipment(params.row)}>
                        {translations.request}
                    </Button>
                ) : (
                    <>{params.row.equipmentRequestStatus}</>
                ),
            filterable: false,
            disableExport: true,
            sortable: false,
            hideable: false,
        },
        {
            field: "srn",
            headerName: translations.srn,
            renderHeader: () => translations.srn,
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/orders/srn/${encodeURIComponent(params.row.srn)}`);
                    }}>
                    {params.row.srn}
                </Link>
            ),
        },
        {
            field: "customerAccountNameAndNumber",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "repairItemSerial",
            headerName: translations.repairItemSerial,
            renderHeader: () => translations.repairItemSerial,
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.custAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(params.row.repairItemSerial)}`
                        );
                    }}>
                    {params.row.repairItemSerial}
                </Link>
            ),
        },
        {
            field: "repairItemManufacturer",
            headerName: translations.repairItemManufacturer,
            renderHeader: () => translations.repairItemManufacturer,
            minWidth: 170,
            flex: 1,
        },
        {
            field: "repiarItemModel",
            headerName: translations.repairItemModel,
            renderHeader: () => translations.repairItemModel,
            minWidth: 180,
            flex: 1,
        },
    ];

    return (
        <Box data-testid="equipment-request-grid-box">
            <Grid container>
                <Grid
                    item
                    xs>
                    <GridAreaLayout data-testid="items-in-repair-grid-box">
                        <StripedDataGrid
                            disableRowSelectionOnClick
                            initialState={{
                                filter: {
                                    filterModel: gridFilter,
                                },
                                sorting: {
                                    sortModel: gridSort,
                                },
                            }}
                            columnVisibilityModel={gridColumns}
                            rows={rows}
                            columns={columns}
                            getRowId={(row) => row.orderLineId}
                            density="compact"
                            slots={{
                                toolbar: () => CustomToolbar(exportExcelFileName.equipmenetRequests),
                                loadingOverlay: LinearProgress,
                                filterPanel: StyledFilterPanel,
                            }}
                            slotProps={{
                                columnsPanel: {
                                    sx: {
                                        textTransform: "capitalize",
                                    },
                                },
                            }}
                            loading={props.loading}
                            onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                            onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                            onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                            columnBuffer={7}
                        />
                    </GridAreaLayout>
                </Grid>
            </Grid>
            {openEquipmentRequestFormDialog && (
                <SrnCreationProvider>
                    <EquipmentRequestForm
                        onClose={handleClose}
                        setEquipmentRequested={setEquipmentRequested}
                        equipmentRequest={equipmentRequestData}
                        poValidationRequired={poValidationRequired}
                    />
                </SrnCreationProvider>
            )}
        </Box>
    );
};
