import { LoanersDue } from "../../models/serviceSummary/LoanersDue";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const LoanerReturnButton = (props: {
    loanerReturn: LoanersDue;
    /**
     * Button clicked event emitted to the parent component
     * @param loanerDue - The row of loaners due.
     */
    handleOnClick: (loanerDue: LoanersDue) => void;
}) => {
    const { t } = useTranslation();
    const { loanerReturn, handleOnClick } = props;

    const translations = {
        return: t("return"),
        view: t("view"),
    };

    /**
     * Internal handler for the button click event
     */
    const handleClick = () => {
        handleOnClick(loanerReturn);
    };

    return loanerReturn.returnOrderId ? (
        <Button
            aria-label={translations.view}
            data-testid="view-button"
            variant="text"
            size={"small"}
            onClick={handleClick}>
            {translations.view}
        </Button>
    ) : (
        !loanerReturn.isIndirectFiltered &&
        <Button
            aria-label={translations.return}
            data-testid="return-button"
            variant="contained"
            size={"small"}
            onClick={handleClick}>
            {translations.return}
        </Button>
    );
};
export default LoanerReturnButton;
