import ServiceRequestConfirmation from "./ServiceRequestConfirmation";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { IActionMenuItems } from "../../component-library/ConnectCareActionButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { SrnCreationContext } from "../../contexts/SrnCreationContext";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";

const ConfirmedShipment = () => {
    const { confirmedShipments } = useContext(SrnCreationContext);
    const { getBlob } = useFetch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translations = {
        serviceRequestHeader: t("Service Request"),
        error: t("An error occurred."),
        reprint: t("Print"),
        successMessage: t("Successful Service Request Submitted"),
        viewServiceRequests: t("View Recent Service Requests"),
    };
    const [isPrintClickRunning, setIsPrintClickRunning] = useState<boolean>(false);
    const [srnToBePrint, setSrnToBePrint] = useState<string>("");
    const dispatch = useDispatch();
    const handlePrintError = () => {
        dispatch(
            setToast({
                showToast: true,
                toastType: ToastTypes.Error,
                toastMessage: translations.error,
            })
        );
    };

    useEffect(() => {
        if (!confirmedShipments?.length) {
            navigate("/servicerequest/add-items");
        }
    }, [confirmedShipments, navigate]);

    const handlePrintClick = async (orderId: string) => {
        setIsPrintClickRunning(true);
        setSrnToBePrint(orderId);
        const uri = `${requestConnectCareOrders.Print(orderId)}`; //Change;

        const res = await getBlob(uri, true, handlePrintError);
        if (res) {
            window.open(window.URL.createObjectURL(res));
        }
        setIsPrintClickRunning(false);
    };

    useEffect(() => {
        dispatch(
            setToast({
                showToast: true,
                toastType: ToastTypes.Success,
                toastMessage: translations.successMessage,
            })
        );
    }, [translations.successMessage, dispatch]);

    const actionButtons = (serviceRequestNumber: string): IActionMenuItems[] => {
        /**
         * serviceRequestNumber will come from Create SRN Confrimation Page and there we need only Reprint option in Action submenu
         */
        return [
            {
                name: translations.reprint,
                onClick: () => handlePrintClick(serviceRequestNumber),
                disable: isPrintClickRunning,
            },
        ];
    };

    return (
        <>
            {confirmedShipments?.length &&
                confirmedShipments.map((sr) => (
                    <Box
                        sx={{ mb: 2 }}
                        key={confirmedShipments.indexOf(sr)}>
                        <ServiceRequestConfirmation
                            serviceRequestNumber={sr.srn}
                            key={confirmedShipments.indexOf(sr)}
                            actionButtons={actionButtons(sr.srn!)}
                            isPrintClickRunning={isPrintClickRunning}
                            srnToBePrint={srnToBePrint}
                        />
                    </Box>
                ))}
        </>
    );
};

export default ConfirmedShipment;
