import { useState, Dispatch, SetStateAction, useCallback, useEffect, ChangeEvent } from "react";
import {
    Button,
    CircularProgress,
    TextField,
    Typography,
    Grid,
    Box,
    Link,
    Card,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { requestConnectCareExternalAuth } from "../services/apiPaths";
import { useTranslation } from "react-i18next";
import getQueryStringParam from "../utils/getQueryStringParam";
import { ApiErrorCodes } from "../config/ApiErrorCodes";
import { formWidget } from "../theme/colors";
import { validators } from "../utils/validators";
import AppFooter from "../components/navigation/AppFooter";
import FormNewUser from "./FormNewUser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { freshworks } from "../utils/freshworks";
import { SterisConnectCareLogo } from "../images/SterisConnectCareLogo";
import { SterisLogo } from "../images/SterisLogoIcon";
import { ANewDigitalExperience } from "../images/ANewDigitalExperience";

interface IProps {
    setPageKey: Dispatch<SetStateAction<number>>;
    showSpinner?: boolean;
}

export default function ExternalLogin({ setPageKey, showSpinner }: Readonly<IProps>) {
    const [emailAddress, setEmailAddress] = useState("");
    const [openSignUpForm, setOpenSignUpForm] = useState(false);
    const [emailAddressValidated, setEmailAddressValidated] = useState(false);
    const [emailAddressValid, setEmailAddressValid] = useState(false);
    const [disableButton, setDisableButton] = useState(true); //disable the button by default
    const [isLoading, setIsLoading] = useState(showSpinner); //Show the spinning animation in the login button.
    const [emailAddressValidationMessage, setEmailAddressValidationMessage] = useState("");
    const { t } = useTranslation();

    const translations = {
        emailRequired: t("* Email is required."),
        emailValid: t("* Please enter a valid email address."),
        welcomeMessage: t("Welcome to ConnectCare!"),
        loginSterisId: t("login"),
        documentTitle: t(`${process.env.REACT_APP_STERIS_TITLE} - Welcome to ConnectCare!`),
        signUp: t("Sign Up"),
        scheduleDemo: t("Schedule Demo"),
        help: t("Help"),
        instrumentManagement: t("Instrument Management Services"),
        ccForES: t("ConnectCare for Equipment Services"),
        requestAccess: t("Request Access"),
        connectingTechnologies: t(
            "Connecting innovative technologies with powerful data insights that we leverage together as partners in care."
        ),
        dataDriven: t("Data-Driven Decision Making"),
        dataDrivenMessage: t(
            "View advanced data analytics in real-time to assist with clinical decision making and or readiness"
        ),
        mitigatingRisk: t("Mitigating Risk"),
        mitigatingRiskMessage: t("Access robust reporting to help you identify and mitigate patient safety risk"),
        timeSavings: t("Time Savings"),
        timeSavingsMessage: t(
            "Easily place your service request and track repairs from your facility to the repair lab and back in just a couple of clicks"
        ),
        newDigital: t("A new digital experience"),
        poweringDepartment: t("powering your department."),
    };

    useEffect(() => {
        document.title = translations.documentTitle;
        freshworks.hide();
    }, [translations.documentTitle]);

    /**
     * Validates and sets the email validators, and login button state.
     * @returns {boolean} - true if the email address is valid
     */
    const validateEmail = useCallback(
        (emailAddress: string): boolean => {
            setEmailAddressValidated(true);

            if (emailAddress === "") {
                setDisableButton(true); //enable our button
                setEmailAddressValidationMessage(translations.emailRequired);
                return false;
            }

            const isEmailValid = validators.email(emailAddress); //check for validity
            if (!isEmailValid) {
                setDisableButton(true); //enable our button
                setEmailAddressValidationMessage(translations.emailValid);
            } else {
                setDisableButton(false); //enable our button
            }
            setEmailAddressValid(isEmailValid); //show the error message
            return isEmailValid;
        },
        [translations.emailRequired, translations.emailValid]
    );

    /**
     * Handles the return url for login postback.
     * @returns the return url.
     */
    const returnUrl = (): string => {
        //if the user is at root redirect them to the dashboard.
        return window.location.pathname === "/" ? "/dashboard" : window.location.pathname;
    };

    const handleFormVisibility = () => {
        setOpenSignUpForm(!openSignUpForm);
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const email = e.target.value;
        setEmailAddress(email);
        validateEmail(email);
    };

    useEffect(() => {
        //Get login hint
        const emailAddress = getQueryStringParam("login_hint");
        if (emailAddress?.length) {
            validateEmail(emailAddress);
        }
        setEmailAddress(emailAddress);

        //Get errorcode params
        const errorCode = getQueryStringParam("errorCode");
        if (errorCode?.length && errorCode === ApiErrorCodes.MissingIdentityUser.toString()) {
            setOpenSignUpForm(true); //open to the new user form and show the error message
        }

        const nonce = getQueryStringParam("nonce");
        if (nonce?.length) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [validateEmail, setPageKey]);

    return (
        <form
            data-testid="external-login-form"
            method="POST"
            noValidate
            autoComplete="off"
            action={`${
                requestConnectCareExternalAuth.ssoLoginUrl
            }?returnUrl=${returnUrl()}&emailAddress=${emailAddress}`}>
            <Box>
                <Grid
                    container
                    sx={{
                        backgroundImage: { md: `url('/images/LoginBackground.png')` },
                        backgroundRepeat: `no-repeat`,
                        backgroundPosition: `right top`,
                        backgroundSize: `auto 1100px`,
                    }}>
                    <Grid
                        container
                        sx={{ paddingLeft: { xs: 1, md: 10 } }}>
                        <Grid
                            container
                            spacing={2}
                            sx={{ display: { xs: "none", md: "flex" } }}>
                            <Grid
                                item
                                md
                                mt={5}>
                                <SterisConnectCareLogo />
                            </Grid>
                            <Grid
                                item
                                md="auto">
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        mr={10}
                                        textAlign={"right"}>
                                        <SterisLogo />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        mr={10}
                                        textAlign={"right"}>
                                        <Link
                                            data-testid="new-user-link"
                                            onClick={() => setOpenSignUpForm(true)}
                                            underline="none"
                                            mr={1}>
                                            {translations.signUp}
                                        </Link>
                                        |
                                        <Link
                                            data-testid="schedule-demo-link"
                                            href={`${process.env.REACT_APP_COGNITO_FORMS_URL}`}
                                            underline="none"
                                            ml={1}
                                            mr={1}>
                                            {translations.scheduleDemo}
                                        </Link>
                                        |
                                        <Link
                                            data-testid="need-help-link"
                                            href={`${process.env.REACT_APP_COGNITO_FORMS_URL}`}
                                            underline="none"
                                            ml={1}>
                                            {translations.help}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            mt={6}
                            md={6}>
                            <Box
                                sx={{ display: { xs: "flex", md: "none" } }}
                                mb={4}
                                justifyContent={"center"}>
                                <img
                                    src="/images/STERIS-ConnectCareLogo.png"
                                    alt="ConnectCare Logo"
                                />
                            </Box>
                            <Typography
                                variant="loginH1"
                                mb={1}
                                color={"font.claret"}
                                mt={5}>
                                {translations.instrumentManagement}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="blue.connectCare2"
                                paddingTop="5px"
                                mb={14}>
                                <Link
                                    href={`${process.env.REACT_APP_EQUIPMENT_SERVICES_URL}`}
                                    underline="none">
                                    {translations.ccForES}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={6}
                        />
                        <Grid
                            item
                            md={4}
                            mb={2}>
                            {isLoading && (
                                <Box>
                                    <Box
                                        textAlign={"center"}
                                        mb={15}>
                                        <CircularProgress
                                            data-testid="progress-spinner"
                                            size={40}
                                        />
                                    </Box>
                                </Box>
                            )}
                            {!isLoading && (
                                <Box mr={1}>
                                    <TextField
                                        size="small"
                                        sx={{ backgroundColor: formWidget.white }}
                                        inputProps={{
                                            sx: {
                                                backgroundColor: formWidget.blue,
                                            },
                                        }}
                                        fullWidth
                                        name="emailAddress"
                                        label="Email Address"
                                        value={emailAddress}
                                        data-testid="email-textfield"
                                        onChange={handleEmailChange}
                                        error={emailAddressValidated && !emailAddressValid}
                                        helperText={
                                            emailAddressValidated && !emailAddressValid
                                                ? emailAddressValidationMessage
                                                : " "
                                        }
                                        /* See https://mui.com/material-ui/api/form-helper-text/ */
                                        FormHelperTextProps={{
                                            filled: false,
                                            sx: {
                                                backgroundColor: formWidget.white,
                                                color: formWidget.errorText,
                                            },
                                        }}
                                    />
                                    <Box textAlign={"center"}>
                                        <Grid item>
                                            <Button
                                                aria-label={`${translations.loginSterisId}`}
                                                sx={{ textTransform: "uppercase", width: "175px" }}
                                                disabled={disableButton}
                                                data-testid="login-button"
                                                type="submit"
                                                name="provider"
                                                value="PingOne"
                                                title={`Login using Steris Id`}
                                                variant="contained">
                                                <Typography>{`${translations.loginSterisId}`}</Typography>
                                            </Button>
                                        </Grid>
                                    </Box>
                                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                                        <Grid
                                            container
                                            spacing={1}>
                                            <Grid
                                                item
                                                xs={6}>
                                                <Typography
                                                    variant="detailValue"
                                                    color="blue.connectCare2"
                                                    paddingTop="5px"
                                                    mb={1}>
                                                    {/* block for request access */}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                mb={12}>
                                                {/* block for language dropdown*/}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box
                                        justifyContent={"center"}
                                        mt={3}
                                        mb={3}
                                        sx={{ display: { xs: "flex", md: "none" } }}>
                                        <Grid item>
                                            <Link
                                                data-testid="new-user-link-mobile"
                                                onClick={() => setOpenSignUpForm(true)}
                                                underline="none"
                                                mr={1}>
                                                {translations.signUp}
                                            </Link>
                                            |
                                            <Link
                                                data-testid="schedule-demo-link-mobile"
                                                href={`${process.env.REACT_APP_COGNITO_FORMS_URL}`}
                                                underline="none"
                                                ml={1}
                                                mr={1}>
                                                {translations.scheduleDemo}
                                            </Link>
                                            |
                                            <Link
                                                data-testid="need-help-link-mobile"
                                                href={`${process.env.REACT_APP_COGNITO_FORMS_URL}`}
                                                underline="none"
                                                ml={1}>
                                                {translations.help}
                                            </Link>
                                        </Grid>
                                    </Box>
                                </Box>
                            )}

                            <Grid
                                item
                                md={8}
                            />
                            <Grid
                                item
                                md={9}>
                                <Typography
                                    fontSize="20px"
                                    color="blue.darker"
                                    fontWeight="bold"
                                    sx={{ textAlign: { xs: "center", md: "left" } }}>
                                    {translations.connectingTechnologies}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{ display: { xs: "none", md: "flex" } }}>
                            <Grid
                                item
                                md={4}
                                justifyContent={"space-between"}
                                alignItems={"center"}>
                                <Box padding={3}>
                                    <Card
                                        variant="outlined"
                                        sx={{ height: "350px", width: "350px", borderRadius: "30px" }}>
                                        <CardContent>
                                            <Box padding={2}>
                                                <Box textAlign={"right"}>
                                                    <img
                                                        src="/images/Data-Driven.svg"
                                                        alt="Data Driven"
                                                        width="100px"
                                                    />
                                                </Box>
                                                <Typography
                                                    variant={"loginH2"}
                                                    textTransform={"uppercase"}
                                                    color={"black"}>
                                                    {translations.dataDriven}
                                                </Typography>
                                                <Typography mt={1}>{translations.dataDrivenMessage}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                justifyContent={"space-between"}
                                alignItems={"center"}>
                                <Box padding={3}>
                                    <Card
                                        variant="outlined"
                                        sx={{ height: "350px", width: "350px", borderRadius: "30px" }}>
                                        <CardContent>
                                            <Box padding={2}>
                                                <Box textAlign={"right"}>
                                                    <img
                                                        src="/images/Mitigating-Risk.svg"
                                                        alt="Mitigating Risk"
                                                        width="100px"
                                                    />
                                                </Box>
                                                <Typography
                                                    variant={"loginH2"}
                                                    textTransform={"uppercase"}
                                                    color={"black"}
                                                    mb={1}>
                                                    {translations.mitigatingRisk}
                                                </Typography>
                                                <Typography mt={1}>{translations.mitigatingRiskMessage}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>

                            <Grid
                                item
                                md={4}
                                justifyContent={"space-between"}
                                alignItems={"center"}>
                                <Box padding={3}>
                                    <Card
                                        variant="outlined"
                                        sx={{ height: "350px", width: "350px", borderRadius: "30px" }}>
                                        <CardContent>
                                            <Box padding={2}>
                                                <Box textAlign={"right"}>
                                                    <img
                                                        src="/images/Time-Savings.svg"
                                                        alt="Time Savings"
                                                        width="100px"
                                                    />
                                                </Box>
                                                <Typography
                                                    variant={"loginH2"}
                                                    textTransform={"uppercase"}
                                                    color={"black"}
                                                    mb={1}>
                                                    {translations.timeSavings}
                                                </Typography>
                                                <Typography mt={1}>{translations.timeSavingsMessage}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            mr={1}
                            sx={{ display: { xs: "flex", md: "none" } }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1d-content"
                                    id="panel1d-header">
                                    <Box textAlign={"right"}>
                                        <img
                                            src="/images/Data-Driven.svg"
                                            alt="Data Driven"
                                            width="45px"
                                        />
                                    </Box>
                                    <Typography m={1.5}> {translations.dataDriven}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{translations.dataDrivenMessage}</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2d-content"
                                    id="panel2d-header">
                                    <Box textAlign={"right"}>
                                        <img
                                            src="/images/Mitigating-Risk.svg"
                                            alt="Mitigating Risk"
                                            width="45px"
                                        />
                                    </Box>
                                    <Typography m={1.5}> {translations.mitigatingRisk}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{translations.mitigatingRiskMessage}</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3d-content"
                                    id="panel3d-header">
                                    <Box textAlign={"right"}>
                                        <img
                                            src="/images/Time-Savings.svg"
                                            alt="Time Savings"
                                            width="45px"
                                        />
                                    </Box>
                                    <Typography m={1.5}> {translations.timeSavings}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{translations.timeSavingsMessage}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        lg={6}
                        textAlign={"right"}
                        sx={{ display: { xs: "none", md: "block" } }}>
                        <Typography
                            fontSize={"40px"}
                            fontWeight={"bold"}
                            pt={11}
                            pl={11}>
                            {translations.newDigital}
                        </Typography>
                        <Typography
                            fontSize={"35px"}
                            fontWeight={"medium"}
                            pl={11}>
                            {translations.poweringDepartment}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        mb={4}
                        sx={{ display: { xs: "none", md: "flex" } }}>
                        <ANewDigitalExperience />
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        textAlign={"left"}
                        sx={{ display: { xs: "block", md: "none" } }}>
                        <Typography
                            fontSize={"20px"}
                            fontWeight={"bold"}
                            pt={11}
                            pl={11}>
                            {translations.newDigital}
                        </Typography>
                        <Typography
                            fontSize={"17px"}
                            fontWeight={"medium"}
                            pl={11}
                            mb={2}>
                            {translations.poweringDepartment}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        mb={4}
                        sx={{ display: { xs: "flex", md: "none" } }}>
                        <Box justifyContent={"center"}>
                            <img
                                src="/images/A-new-digital-experience.png"
                                alt="A new digital experience"
                                width="95%"
                            />
                        </Box>
                    </Grid>
                </Grid>
                {/* keep the footer at the bottom of the screen */}
                <Box
                    display="flex"
                    flexGrow="1"
                />
                <AppFooter isAuth={true} />
            </Box>
            {openSignUpForm && (
                <FormNewUser
                    openSignUpForm
                    setOpenSignUpForm={handleFormVisibility}
                />
            )}
        </form>
    );
}
