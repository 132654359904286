import TableauViz from "../../components/tableau/TableauViz";
import { TableauConstants, TableauSettings } from "../../common/TableauConstants";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";

function RecommendedNeverServiced() {
    const { relativeUrl, friendlyReportName } = TableauSettings.recommendedNeverServiced;
    const url = `${TableauConstants.uriPrefix}${relativeUrl}`;

    return (
        AuthLibrary.hasAnyClaim([claimTypes.ViewRecommendedNeverServicedDashboard]) &&
        AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewRecommendedNeverServicedDashboard) && (
            <TableauViz
                url={url}
                relativeUrl={relativeUrl}
                reportName={friendlyReportName}
            />
        )
    );
}

export default RecommendedNeverServiced;
