import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { LOCALIZATION_BASE_URL } from "../services/apiPaths";

/**
 * See https://phrase.com/blog/posts/localizing-react-apps-with-i18next/
 */
i18n.use(initReactI18next).use(HttpBackend).init({
    fallbackLng: 'en',
    lng: localStorage.getItem('preferredLanguage') ?? 'en', //Get the preferred language from local storage to reload the previously selected item. See below where it is set
    ns: 'translations',
    defaultNS: 'translations',
    backend: {
        //To use the web api as the api: 
        loadPath: LOCALIZATION_BASE_URL + '/{{lng}}/{{ns}}',
        parse: (data : any) => {
            var parsed = JSON.parse(data);
            //console.log(parsed); // prints returned KVP
            let d : any = {};

            if (Array.isArray(parsed) && parsed.length) {
                parsed.map(kvp => {
                    return d[kvp.name] = kvp.value; //dynamically map the .net KVP to json object. Eg. [{"name": "1", "value": "2"},...], to {"1":"2", ...}
                });
            }
            return d;
        }
    }
}, () => {});

/**
 * Sets moment when the language has changed.
 * */
i18n.on('languageChanged', (lng) => {
    localStorage.setItem('preferredLanguage', lng); //set the preferred language on reload.   
});

export default i18n;