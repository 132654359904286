import { useMemo, useState, createContext, Dispatch, SetStateAction, useCallback } from "react";
import { ProvideChildren } from ".";
import {
    ICustomerContact,
    IOracleDepartment,
    IServiceRequest,
    IServiceRequestDetails,
    IServiceRequestItem,
} from "../models/serviceRequest/CreateSrnModels";
import { AssetHeaderDetail } from "../models/assets/AssetHeaderDetail";
import { authRequest, requestConnectCareCustomers, requestConnectCareInventory } from "../services/apiPaths";
import { useFetch } from "../services/useFetch";
import { useDispatch } from "react-redux";
import { setToast } from "../redux/reducers/toastSlice";
import { ToastTypes } from "../models/toast/ToastTypes";
import { useTranslation } from "react-i18next";
import { srnTypeKeys } from "../config/data";
import { v4 as uuid } from "uuid";
import { Facility } from "../models/facility/Facility";

export interface ISrnCreationContext {
    singleFacility: Facility | undefined;
    setSingleFacility: Dispatch<SetStateAction<Facility | undefined>>;
    serviceRequestShipment: IServiceRequest | undefined;
    setServiceRequestShipment: Dispatch<SetStateAction<IServiceRequest | undefined>>;
    departmentList: IOracleDepartment[];
    setDepartmentList: Dispatch<SetStateAction<IOracleDepartment[]>>;
    assetDetails: AssetHeaderDetail | undefined;
    setAssetDetails: Dispatch<SetStateAction<AssetHeaderDetail | undefined>>;
    contactList: ICustomerContact[];
    setContactList: Dispatch<SetStateAction<ICustomerContact[]>>;
    userContactId: number;
    setUserContactId: Dispatch<SetStateAction<number>>;
    addSerializedItem: IServiceRequestItem[];
    addInstrumentItem: IServiceRequestItem[];
    addOtherItem: IServiceRequestItem[];
    setAddSerializedItem: Dispatch<SetStateAction<IServiceRequestItem[]>>;
    setAddInstrumentItem: Dispatch<SetStateAction<IServiceRequestItem[]>>;
    setAddOtherItem: Dispatch<SetStateAction<IServiceRequestItem[]>>;
    showCancelDialog: boolean;
    setShowCancelDialog: Dispatch<SetStateAction<boolean>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    allItems: IServiceRequestItem[];
    setAllItems: Dispatch<SetStateAction<IServiceRequestItem[]>>;
    confirmedShipments: IServiceRequestDetails[] | undefined;
    setConfirmedShipments: Dispatch<SetStateAction<IServiceRequestDetails[] | undefined>>;
    getServiceRequestDepartments: (singleCustomerAccountId: number) => Promise<IOracleDepartment[] | undefined>;
    getServiceRequestContacts: (singleCustomerAccountId: number) => Promise<ICustomerContact[] | undefined>;
    getContactId: (singleCustomerAccountId: number) => Promise<number | undefined>;
    getAssetDetails: (
        singleCustomerAccountId: number,
        serial?: string,
        inventoryitemid?: number
    ) => Promise<AssetHeaderDetail | undefined>;
    handleAddInput: (srnType: srnTypeKeys) => Promise<void>;
    updateItem: (item: IServiceRequestItem, index: number) => Promise<void>;
    removeItem: (index: number, srnType: number) => Promise<void>;
    hasIndirectAccounts: () => Promise<boolean | void>;
}

export const SrnCreationContext = createContext<ISrnCreationContext>({} as ISrnCreationContext);

export const SrnCreationProvider = ({ children }: ProvideChildren) => {
    const [singleFacility, setSingleFacility] = useState<Facility>();
    const [confirmedShipments, setConfirmedShipments] = useState<IServiceRequestDetails[] | undefined>();
    const [serviceRequestShipment, setServiceRequestShipment] = useState<IServiceRequest | undefined>();
    const [departmentList, setDepartmentList] = useState<IOracleDepartment[]>([]);
    const [assetDetails, setAssetDetails] = useState<AssetHeaderDetail>();
    const [contactList, setContactList] = useState<ICustomerContact[]>([]);
    const [addSerializedItem, setAddSerializedItem] = useState<IServiceRequestItem[]>([]);
    const [addInstrumentItem, setAddInstrumentItem] = useState<IServiceRequestItem[]>([]);
    const [addOtherItem, setAddOtherItem] = useState<IServiceRequestItem[]>([]);
    const [userContactId, setUserContactId] = useState<number>(0);
    const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allItems, setAllItems] = useState<IServiceRequestItem[]>([]);
    const { get } = useFetch();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const translations = {
        assetNotFound: t("Asset not found."),
    };

    const handleErrorToast = useCallback(() => {
        dispatch(
            setToast({
                toastType: ToastTypes.Error,
                toastMessage: translations.assetNotFound,
            })
        );
    }, [dispatch, translations.assetNotFound]);

    const getAssetDetails = useCallback(
        async (singleCustomerAccountId: number, serial?: string, inventoryitemid?: number) => {
            const uriAsset = `${
                requestConnectCareInventory.AssetHeaderDetail
            }?customerAccountId=${singleCustomerAccountId}&serialNumber=${encodeURIComponent(
                serial ?? ""
            )}&inventoryItemId=${inventoryitemid}`;
            const res = await get<AssetHeaderDetail>(uriAsset, true, handleErrorToast);
            if (res) {
                return res;
            }
        },
        [get, handleErrorToast]
    );



    const getServiceRequestDepartments = useCallback(
        async (singleCustomerAccountId: number) => {
            const uri = `${requestConnectCareCustomers.OracleDepartments}/${singleCustomerAccountId}`;
            const response = await get<IOracleDepartment[]>(uri);
            if (response) {
                return response;
            }
        },
        [get]
    );

    const getServiceRequestContacts = useCallback(
        async (singleCustomerAccountId: number) => {
            const uri = `${requestConnectCareCustomers.CustomerContacts}/${singleCustomerAccountId}`;
            const response = await get<ICustomerContact[]>(uri);
            if (response) {
                return response;
            }
        },
        [get]
    );

    const getContactId = useCallback(
        async (singleCustomerAccountId: number) => {
            const uri = `${requestConnectCareCustomers.CustomerContactId}/${singleCustomerAccountId}`;
            const response = await get<number>(uri);
            if (response) {
                return response;
            }
        },
        [get]
    );

    /**
     * This method will help us to add the items, whenever user click on the Add Item button present in the side panel in the front-end
     * @param srnType This will take Serialized Or NonSerialized/Instruments or Other Items.
     */
    const handleAddInput = useCallback(
        async (srnType: srnTypeKeys) => {
            if (srnType === srnTypeKeys.Serialized) {
                setAddSerializedItem([
                    ...addSerializedItem,
                    {
                        id: uuid(),
                        manufacturer: "",
                        productType: "",
                        model: "",
                        category: "",
                        categoryCode: "",
                        description: "",
                        serial: "",
                        inventoryItemId: 0,
                        contactId: 0,
                        custDeptId: 0,
                        describeProblem: "",
                        isSummaryView: false,
                        quantity: 1,
                        poNumber: "",
                        srnType: srnTypeKeys.Serialized,
                        productLine: "",
                        subProductLine: "",
                    },
                ]);
            } else if (srnType === srnTypeKeys.Nonserialized) {
                setAddInstrumentItem([
                    ...addInstrumentItem,
                    {
                        id: uuid(),
                        manufacturer: "",
                        productType: "",
                        model: "",
                        category: "",
                        categoryCode: "IN",
                        description: "",
                        serial: "",
                        inventoryItemId: 32042,
                        contactId: 0,
                        custDeptId: 0,
                        describeProblem: "",
                        isSummaryView: false,
                        quantity: 1,
                        poNumber: "",
                        srnType: srnTypeKeys.Nonserialized,
                        productLine: "",
                        subProductLine: "",
                    },
                ]);
            } else if (srnType === srnTypeKeys.Unknown) {
                setAddOtherItem([
                    ...addOtherItem,
                    {
                        id: uuid(),
                        manufacturer: "",
                        productType: "",
                        model: "",
                        category: "",
                        categoryCode: "",
                        description: "",
                        serial: "",
                        inventoryItemId: 0,
                        contactId: 0,
                        custDeptId: 0,
                        describeProblem: "",
                        isSummaryView: false,
                        quantity: 1,
                        poNumber: "",
                        srnType: srnTypeKeys.Unknown,
                        productLine: "",
                        subProductLine: "",
                    },
                ]);
            }
        },
        [addSerializedItem, addInstrumentItem, addOtherItem]
    );

    /**
     * This method will help us to update the items, whenever user modify the input fields or dropdown fields in the front-end
     * @param item needs to be added to the list of items based on the srnType
     * @param index position for which we need to update the data
     */
    const updateItem = useCallback(async (item: IServiceRequestItem, index: number) => {
        if (item.srnType === srnTypeKeys.Serialized) {
            setAddSerializedItem((prev) => {
                const updatedItems = [...prev];
                updatedItems[index] = item;
                return updatedItems;
            });
        } else if (item.srnType === srnTypeKeys.Nonserialized) {
            setAddInstrumentItem((prev) => {
                const updatedItems = [...prev];
                updatedItems[index] = item;
                return updatedItems;
            });
        } else if (item.srnType === srnTypeKeys.Unknown) {
            setAddOtherItem((prev) => {
                const updatedItems = [...prev];
                updatedItems[index] = item;
                return updatedItems;
            });
        }
    }, []);

    /**
     * This method will help us to delete the specific items based on the index value, whenever user click on deleted icon in the front-end
     * @param index position that need to be removed from  the list when user clicks on delete button in UI
     * @param srnType based on this we will remove the item from that array list as we have different array list for different srnTypes
     */
    const removeItem = useCallback(
        async (index: number, srnType: number) => {
            /**
             * Before removing the item, we need to check whether the item to be removed, is the last one or not
             * If its the last item then we need to show cancel popup before we delete it.
             * We are calculating the total items count and if it is 1 then we will display popup and if user clicks on cancel then we will not delete the last item
             */
            let items_count = addSerializedItem.length + addInstrumentItem.length + addOtherItem.length;
            if (items_count === 1) {
                setShowCancelDialog(true);
            } else if (srnType === srnTypeKeys.Serialized) {
                const newArray = [...addSerializedItem];
                newArray.splice(index, 1);
                setAddSerializedItem(newArray);
            } else if (srnType === srnTypeKeys.Nonserialized) {
                const newArray = [...addInstrumentItem];
                newArray.splice(index, 1);
                setAddInstrumentItem(newArray);
            } else if (srnType === srnTypeKeys.Unknown) {
                const newArray = [...addOtherItem];
                newArray.splice(index, 1);
                setAddOtherItem(newArray);
            }
        },
        [addInstrumentItem, addOtherItem, addSerializedItem]
    );

    /**
    * Returns true or false if the user has indirect accounts selected and is a customer user.
    */
    //This is not really appropriate for this context. I don't have another use case for it other than here. We need to review this functionality later.
    const hasIndirectAccounts = useCallback(async () => {
        const response = await get<boolean>(authRequest.hasIndirectAccounts, true);
        return response;        
    }, [get]);

    const value = useMemo(
        () => ({
            serviceRequestShipment,
            setServiceRequestShipment,
            departmentList,
            setDepartmentList,
            assetDetails,
            setAssetDetails,
            contactList,
            setContactList,
            userContactId,
            setUserContactId,
            getAssetDetails,
            getContactId,
            getServiceRequestContacts,
            getServiceRequestDepartments,
            addSerializedItem,
            setAddSerializedItem,
            addInstrumentItem,
            setAddInstrumentItem,
            addOtherItem,
            setAddOtherItem,
            showCancelDialog,
            setShowCancelDialog,
            isLoading,
            setIsLoading,
            handleAddInput,
            updateItem,
            removeItem,
            singleFacility,
            setSingleFacility,
            allItems,
            setAllItems,
            confirmedShipments,
            setConfirmedShipments,
            hasIndirectAccounts
        }),
        [
            serviceRequestShipment,
            setServiceRequestShipment,
            departmentList,
            setDepartmentList,
            assetDetails,
            setAssetDetails,
            contactList,
            setContactList,
            userContactId,
            setUserContactId,
            getAssetDetails,
            getContactId,
            getServiceRequestContacts,
            getServiceRequestDepartments,
            addSerializedItem,
            setAddSerializedItem,
            addInstrumentItem,
            setAddInstrumentItem,
            addOtherItem,
            setAddOtherItem,
            showCancelDialog,
            setShowCancelDialog,
            isLoading,
            setIsLoading,
            handleAddInput,
            updateItem,
            removeItem,
            singleFacility,
            setSingleFacility,
            allItems,
            setAllItems,
            confirmedShipments,
            setConfirmedShipments,
            hasIndirectAccounts
        ]
    );

    return <SrnCreationContext.Provider value={value}>{children}</SrnCreationContext.Provider>;
};
