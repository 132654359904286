import { useState } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { AuthLibrary  } from "../../redux/actions/AuthRedux";

import SessionTimeoutDialog from "./SessionTimeoutDialog";
import { Box } from "@mui/system";

interface ISessionTimeout {
    /** Time interval in milliseconds to pop up user inactivity modal */
    timeout: number;
    /* Countdown timer in milliseconds in inactivity modal popup */
    countDownTimer: number;
}

export default function SessionTimeout({ timeout, countDownTimer }: ISessionTimeout) {
    const throttle = 250;
    const [timeoutModalOpen, setTimeoutModalOpen] = useState<boolean>(false);

    /**
     * When prompted set the state and open the session timeout dialog.
     */
    const onPrompt = () => {
        setTimeoutModalOpen(true);
    };

    /**
     * When the user has been prompted and the countdown timer has expired.
     */
    const onIdle = () => {
        handleLogout();
    };

    /** When the user has come out of prompted state */
    const onActive = () => {
        handleContinue();
    };

    /** Handles the logout scenario. */
    const handleLogout = () => {
        setTimeoutModalOpen(false);
        AuthLibrary.appLogout();
    };

    /** If the user clicks continue. */
    const handleContinue = () => {
        setTimeoutModalOpen(false);
        AuthLibrary.refreshAccessToken();
    };

    return (
        <Box data-testid="session-timeout-modal">
            <IdleTimerProvider
                timeout={timeout}
                promptBeforeIdle={countDownTimer}
                throttle={throttle}
                onPrompt={onPrompt}
                onIdle={onIdle}
                onActive={onActive}>
                <SessionTimeoutDialog
                    countdown={countDownTimer}
                    onLogout={handleLogout}
                    open={timeoutModalOpen}
                />
            </IdleTimerProvider>
        </Box>
    );
}
