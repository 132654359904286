import { Box, Grid, LinearProgress, Link } from "@mui/material";
import {
    GridColDef,
    GridFilterInputValue,
    GridFilterItem,
    getGridStringOperators,
    GridFilterModel,
} from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatDate } from "../../common/Formatters";
import { StripedDataGrid, GridAreaLayout, StyledFilterPanel } from "../../theme/stripedTable";
import { AssetDueForPreventiveMaintenanceProps } from "../../models/assets/AssetDueForPreventiveMaintenance";
import { useNavigate } from "react-router-dom";
import { GridCellValueWithRoundIcon } from "../../component-library/GridCellValueWithRoundIcon";
import GridHeader from "../../component-library/GridHeader";
import { useEffect, useState } from "react";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../common/SiteMap";
import { GridBackgroundColor, theme } from "../../theme/theme";

const AssetsDueForPreventiveMaintenance = (props: AssetDueForPreventiveMaintenanceProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translations = {
        id: t("Id"),
        serial: t("Serial"),
        category: t("Category"),
        description: t("Description"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        customer: t("Customer"),
        department: t("Department"),
        specialty: t("Specialty"),
        lastService: t("Last Service"),
        lastRepair: t("Last Repair"),
        lastInsepction: t("Last Inspection"),
        interval: t("Interval"),
        nextService: t("Next Service"),
        productLine: t("Product Line"),
        subProductLine: t("Subproduct Line"),
        apiError: t("System Error: API is not available at this time!"),
        title: t("Due for Preventive Maintenance"),
        maintenanceStatus: t("Maintenance Status"),
        dueForService: t("Due for Service"),
        neverServiced: t("Never Serviced"),
    };

    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    const columns: GridColDef[] = [
        {
            field: "maintenanceStatus",
            headerName: translations.maintenanceStatus,
            renderHeader: () => translations.maintenanceStatus,
            minWidth: 220,
            flex: 1,
            renderCell: (params) => <GridCellValueWithRoundIcon {...params} />,
        },
        {
            field: "nextServiceDate",
            renderHeader: () => translations.nextService,
            headerName: translations.nextService,
            minWidth: 160,
            sortable: true,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            flex: 1,
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
        },
        {
            field: "serialNumber",
            headerName: translations.serial,
            renderHeader: () => translations.serial,
            minWidth: 120,
            renderCell: (params) => (
                <Link
                    style={{ textDecoration: "none", color: "blue.connectCare2" }}
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.custAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.serialNumber}
                </Link>
            ),
            flex: 1,
            headerClassName: "padding",
        },
        {
            field: "manufacturer",
            headerName: translations.manufacturer,
            renderHeader: () => translations.manufacturer,
            minWidth: 180,
            flex: 1,
        },
        {
            field: "model",
            headerName: translations.model,
            renderHeader: () => translations.model,
            minWidth: 120,
            flex: 1,
        },
        {
            field: "customer",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "departmentDescription",
            headerName: translations.department,
            renderHeader: () => translations.department,
            minWidth: 160,
            flex: 1,
        },
        {
            field: "lastServiceDate",
            renderHeader: () => translations.lastService,
            headerName: translations.lastService,
            minWidth: 160,
            sortable: true,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            flex: 1,
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
        },
        {
            field: "interval",
            headerName: translations.interval,
            renderHeader: () => translations.interval,
            minWidth: 140,
            flex: 1,
        },
        {
            field: "lastRepairDate",
            renderHeader: () => translations.lastRepair,
            headerName: translations.lastRepair,
            minWidth: 160,
            sortable: true,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            flex: 1,
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
        },
        {
            field: "lastInspectionDate",
            renderHeader: () => translations.lastInsepction,
            headerName: translations.lastInsepction,
            minWidth: 185,
            sortable: true,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            flex: 1,
            sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
        },
        {
            field: "description",
            headerName: translations.description,
            renderHeader: () => translations.description,
            minWidth: 350,
            flex: 1,
        },
        {
            field: "category",
            headerName: translations.category,
            renderHeader: () => translations.category,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "productLine",
            headerName: translations.productLine,
            renderHeader: () => translations.productLine,
            minWidth: 170,
            flex: 1,
            filterOperators: [
                ...getGridStringOperators(),
                {
                    value: "not",
                    getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
                        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                            return null;
                        }

                        const filterRegex = new RegExp(filterItem.value, "i");
                        return (params): boolean => {
                            const rowValue = column.valueGetter ? column.valueGetter(params.value) : params.value;
                            return !filterRegex.test(rowValue?.toString() || "");
                        };
                    },
                    InputComponent: GridFilterInputValue,
                },
            ],
        },
        {
            field: "subProductLine",
            headerName: translations.subProductLine,
            renderHeader: () => translations.subProductLine,
            minWidth: 190,
            flex: 1,
        },
        {
            field: "speciality",
            headerName: translations.specialty,
            renderHeader: () => translations.specialty,
            minWidth: 150,
            flex: 1,
        },
    ];

    useEffect(() => {
        setFilterModel(props.filterModel);
    }, [setFilterModel, props.filterModel]);

    return (
        <Box
            style={GridBackgroundColor}
            data-testid="asset-due-for-pm-list">
            <BreadCrumb breadCrumbs={BreadCrumbList.dueforpm} />
            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.title}
                    hasError={[404, 500].includes(props.responseStatusCode)}
                />

                {/* * ** Grid *** */}
                <Box
                    py={theme.spacing(2)}
                    data-testid="asset-due-for-pm-list-grid-box">
                    <Grid container>
                        <Grid
                            item
                            xs>
                            <GridAreaLayout>
                                <StripedDataGrid
                                    disableRowSelectionOnClick
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                id: false,
                                                speciality: false,
                                                productLine: false,
                                                subProductLine: false,
                                                category: false,
                                                departmentDescription: false,
                                                interval: false,
                                                lastRepairDate: false,
                                                lastServiceDate: false,
                                                manufacturer: false,
                                                model: false,
                                            },
                                        },
                                        filter: { filterModel: props.filterModel ?? filterModel },
                                    }}
                                    rows={props.assetDueForPreventiveData}
                                    columns={columns}
                                    density="compact"
                                    slots={{
                                        toolbar: () => CustomToolbar(exportExcelFileName.dueForPreventiveMaintenance),
                                        loadingOverlay: LinearProgress,
                                        filterPanel: StyledFilterPanel,
                                    }}
                                    slotProps={{
                                        columnsPanel: { sx: { textTransform: "capitalize" } },
                                    }}
                                    loading={props.isLoading}
                                    localeText={{ filterOperatorNot: "does not contain" }}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                    }
                                    columnBuffer={16}
                                />
                            </GridAreaLayout>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default AssetsDueForPreventiveMaintenance;
