import { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Grid, LinearProgress, Typography, Alert, Chip, CircularProgress } from "@mui/material";
import { EducationDetail, EducationDetailAttendeesCount } from "../../models/education/Education";
import { ccLocalstorage } from "../../config/data";
import { claimTypes } from "../../config/claimTypes";
import { requestConnectCareEducations } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import { FormatDate } from "../../common/Formatters";
import { useTranslation } from "react-i18next";
import { FormatListBulleted, MenuBook, Person } from "@mui/icons-material";
import EducationEventNotFound from "./EducationEventNotFound";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import EducationDetailGrid from "./EducationDetailGrid";
import EducationDetailTopics from "./EducationDetailTopics";
import EducationDetailAttendee from "./EducationDetailAttendee";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { blue, detailStatus, text } from "../../theme/colors";
import { TabLoader } from "../../component-library/TabLoader";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

interface EducationDetailsProps {
    educationId: any;
}
export default function EducationDetails({ educationId }: Readonly<EducationDetailsProps>) {
    const { t } = useTranslation();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const { get } = useFetch();

    const [educationDetails, setEducationDetails] = useState<EducationDetail | undefined>(undefined);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEducationDetailTopicsCountApiError, setIsEducationDetailTopicsCountApiError] = useState(false);
    const [isEducationDetailTopicsCountLoading, setIsEducationDetailTopicsCountLoading] = useState(false);
    const [isEducationDetailAttendeesCountApiError, setIsEducationDetailAttendeesCountApiError] = useState(false);
    const [isEducationDetailAttendeesCountLoading, setIsEducationDetailAttendeesCountLoading] = useState(false);
    const [educationDetailTopicsCount, setEducationDetailTopicsCount] = useState<number>(0);
    const [educationDetailAttendeesCount, setEducationDetailAttendeesCount] = useState<EducationDetailAttendeesCount>();
    const hasViewEducationEvents = AuthLibrary.checkClaim(claimTypes.ViewAllEvents);
    const tabIds = { details: "Details", topics: "Topics", attendees: "Attendees" };

    const translations = {
        pageName: t("Education"),
        topics: t("Topics"),
        attendees: t("Attendees"),
        details: t("Details"),
        apiError: t("System Error: API is not available at this time!"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.educationEventDetails)}`,
    };

    const tabs: ITab[] = [
        {
            tabId: tabIds.details,
            icon: <FormatListBulleted />,
            children: <>{translations.details}</>,
        },
        {
            tabId: tabIds.topics,
            icon: <MenuBook />,
            children: (
                <TabLoader
                    isLoading={isEducationDetailTopicsCountLoading}
                    labelText={translations.topics}
                    isApiError={isEducationDetailTopicsCountApiError}
                    count={educationDetailTopicsCount}
                />
            ),
        },
        {
            tabId: tabIds.attendees,
            icon: <Person />,
            children: (
                <TabLoader
                    isLoading={isEducationDetailAttendeesCountLoading}
                    labelText={translations.attendees}
                    isApiError={isEducationDetailAttendeesCountApiError}
                    count={educationDetailAttendeesCount?.count!}
                />
            ),
        },
    ];

    // Below state is to set up the initial tab to show while landing into Education Details page
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(tabs[0]);

    const getEducationDetails = useCallback(() => {
        setIsError(false);
        setIsLoading(true);
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uriEducation = `${requestConnectCareEducations.EducationDetail}?eventId=${educationId}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };
        fetch(uriEducation, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setEducationDetails(data);
            })
            .catch((error) => {
                console.error("Fetch error: ", error);
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [educationId, selectedFacilities]);

    const getEducationDetailsTopicsCount = useCallback(async () => {
        setIsEducationDetailTopicsCountApiError(false);
        setIsEducationDetailTopicsCountLoading(true);
        const uri = `${requestConnectCareEducations.EducationDetailTopicsCount}?eventId=${educationId}`;
        const response = await get<number>(uri, true);
        const result = response as number;

        if (result >= 0) {
            setEducationDetailTopicsCount(result);
        } else {
            setIsEducationDetailTopicsCountApiError(true);
        }
        setIsEducationDetailTopicsCountLoading(false);
    }, [educationId, get]);

    const geteducationDetailsAttendeesCount = useCallback(async () => {
        setIsEducationDetailAttendeesCountApiError(false);
        setIsEducationDetailAttendeesCountLoading(true);
        const uri = `${requestConnectCareEducations.EducationDetailAttendeesCount}?eventId=${educationId}`;
        const response = await get<EducationDetailAttendeesCount>(uri, true);

        if (response) {
            setEducationDetailAttendeesCount(response);
        } else {
            setIsEducationDetailAttendeesCountApiError(true);
        }
        setIsEducationDetailAttendeesCountLoading(false);
    }, [educationId, get]);

    useEffect(() => {
        if (hasViewEducationEvents) {
            getEducationDetails();
        }
    }, [hasViewEducationEvents, getEducationDetails]);

    useEffect(() => {
        if (educationDetails) {
            getEducationDetailsTopicsCount();
        }
    }, [getEducationDetailsTopicsCount, educationDetails]);

    useEffect(() => {
        document.title = translations.documentTitle;
        geteducationDetailsAttendeesCount();
    }, [geteducationDetailsAttendeesCount, translations.documentTitle]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(tabs.find((x) => x.tabId === newValue));
    };

    const statusBgColor = useMemo((): string => {
        if (!educationDetails?.statusDescription) {
            return "";
        }
        switch (educationDetails.statusDescription.toLowerCase()) {
            case "planned":
                return blue.steris;
            case "scheduled":
                return detailStatus.yelloworange;
            case "complete":
                return detailStatus.mintgreen;
            case "cancelled":
                return text.textdarkgray;
            case "requested":
                return detailStatus.bluegrey;
            default:
                return "";
        }
    }, [educationDetails]);

    return (
        <Box sx={{ backgroundColor: "bg.lightGray" }}>
            <BreadCrumb breadCrumbs={BreadCrumbList.educationEventDetails} />

            {isLoading && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <CircularProgress />
                </Box>
            )}

            {!isLoading && educationDetails?.id === 0 && <EducationEventNotFound />}
            {!isLoading && educationDetails && educationDetails?.id > 0 && (
                <>
                    {!isLoading && !hasViewEducationEvents && <NotAuthorizeDisplay />}

                    <Box
                        data-testid="education-detail"
                        bgcolor="bg.light"
                        border="1px solid #DFE0EB"
                        borderRadius={2}
                        paddingTop={"12px"}
                        marginTop="20px"
                        overflow={"hidden"}>
                        <Grid
                            paddingLeft={4}
                            paddingRight={4}>
                            {hasViewEducationEvents && (
                                <Grid
                                    container
                                    display="flex"
                                    justifyContent="between">
                                    {isError && (
                                        <Grid
                                            item
                                            xs={12}
                                            margin={2}>
                                            <Alert severity="error">{translations.apiError}</Alert>
                                        </Grid>
                                    )}
                                    {isLoading || !educationDetails ? (
                                        <LinearProgress></LinearProgress>
                                    ) : (
                                        <Grid
                                            item
                                            flex={1}>
                                            <Typography
                                                variant={"overline"}
                                                color={"font.darkGray"}>
                                                {educationDetails?.customer}
                                            </Typography>
                                            <Grid
                                                container
                                                justifyContent={"space-between"}
                                                my={1}>
                                                <Grid
                                                    item
                                                    xs={"auto"}>
                                                    <Typography
                                                        variant={"h1"}
                                                        color={"font.darkBlue"}>
                                                        {educationDetails?.topicName}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={"auto"}>
                                                    <Chip
                                                        variant={"status"}
                                                        size={"small"}
                                                        sx={{ backgroundColor: statusBgColor }}
                                                        label={educationDetails?.statusDescription}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Typography
                                                component={"p"}
                                                variant="labelValue"
                                                textTransform="uppercase">
                                                {educationDetails?.subject}{" "}
                                            </Typography>
                                            <Typography
                                                component={"p"}
                                                variant="labelValue"
                                                textTransform="uppercase">
                                                {FormatDate(educationDetails?.eventDate)}
                                                {educationDetails?.startTime &&
                                                    `, ${new Date(educationDetails.startTime).toLocaleString("en-US", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })}`}{" "}
                                                {educationDetails?.endTime &&
                                                    `- ${new Date(educationDetails.endTime).toLocaleString("en-US", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })}`}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>

                        <Grid>
                            <ConnectCareTabs
                                currentTab={currentTab}
                                handleTabChange={handleTabChange}
                                tabs={tabs}
                            />
                            {currentTab?.tabId === tabIds.details && educationDetails && (
                                <EducationDetailGrid educationDetails={educationDetails} />
                            )}
                            {currentTab?.tabId === tabIds.topics && <EducationDetailTopics educationId={educationId} />}
                            {currentTab?.tabId === tabIds.attendees && (
                                <EducationDetailAttendee
                                    educationId={educationId}
                                    message={educationDetailAttendeesCount?.toastMessage}
                                />
                            )}
                        </Grid>
                    </Box>
                </>
            )}
        </Box>
    );
}
