import { Box, Link } from "@mui/material";

/**
 * Displays Tracking info cell and Link
 *
 * @param {any} row params.row
 * @param {any} value params.value
 * @param {any} translations translations from page
 */
export function TrackingCell(props: { row: any; value: any; }) {
    const row = props.row;
    const value = props.value;
    const handleTrackingNavigation = (tracking: string) => {
        window.open(
            `${process.env.REACT_APP_FEDEX_TRACKING_URL}?tracknumbers=${encodeURIComponent(tracking)}`,
            "_blank",
            "noopener,noreferrer"
        );
    };
    return (
        <Box data-testid="trackingCell">
            {value && (
                <Link
                onClick={() => handleTrackingNavigation(row.tracking)}>
                {row.tracking}
                </Link>
            )}
        </Box>
    );
}
