import { useTranslation } from "react-i18next";
import { ConnectCareActionButton, IActionMenuItems } from "./ConnectCareActionButton";
import { claimTypes } from "../config/claimTypes";
import { AuthLibrary } from "../redux/actions/AuthRedux";

const LoanerActionButton = (props: { printShipping: () => void; isPrinting: boolean }) => {
    const { t } = useTranslation();
    const translations = {
        print: t("Print Paperwork"),
        actions: t("Actions"),
    };

    const { printShipping, isPrinting } = props;
    const handlePrint = () => {
        printShipping();
    };

    const actionButtons: IActionMenuItems[] = [
        {
            name: translations.print,
            onClick: handlePrint,
        },
    ];

    return (
        <ConnectCareActionButton
            menuItems={actionButtons}
            disable={!AuthLibrary.hasAnyClaim([claimTypes.CreateSrn]) || isPrinting}
        />
    );
};
export default LoanerActionButton;
