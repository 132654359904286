export interface MaintenanceRecords {
    upToDateCount: number;
    dueForServiceCount: number;
    overDueForServiceCount: number;
    neverServicedCount: number;
}

/**
* This allows for the creation of the charts on the dashboard without any null values. This is used in the Tray Maintenance and Device Maintenance
*/
export const defaultMaintenanceRecordState: MaintenanceRecords = {
    upToDateCount: 0,
    dueForServiceCount: 0,
    overDueForServiceCount: 0,
    neverServicedCount: 0,
};
