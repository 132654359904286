import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LeftNavigationState {
    isLeftNavigationOpen: boolean;   
}

const initialState: LeftNavigationState = {
    isLeftNavigationOpen: false
};

export const leftNavigationSlice = createSlice({
    name: "leftnavigation",
    initialState,
    reducers: {
        /**
         * Sets the globals state of the left navigation.
         * @param state
         * @param action
         * @returns
         */
        setIsLeftNavigationOpen: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state = { ...state, isLeftNavigationOpen: payload };
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setIsLeftNavigationOpen } = leftNavigationSlice.actions;

export default leftNavigationSlice.reducer;
