import { Box, CircularProgress } from "@mui/material";
import { PieValueType } from "@mui/x-charts";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { claimTypes } from "../../config/claimTypes";
import { EducationByStatus, EducationStatus } from "../../models/education/Education";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { requestConnectCareEducations } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { blue, widget } from "../../theme/colors";
import ErrorMessage from "../dashboard/ErrorMessage";
import EventsPieChart from "../../component-library/EventsPieChart";

export default function EducationEventsByStatus({
    fromDate,
    toDate,
}: {
    readonly fromDate: string;
    readonly toDate: string;
}) {
    const { t } = useTranslation();
    const { get } = useFetch();

    const [educationByStatus, setEducationByStatus] = useState<EducationByStatus[] | []>([]);
    const [isStatusError, setIsStatusError] = useState(false);
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    const hasViewEducationEvents = AuthLibrary.checkClaim(claimTypes.ViewAllEvents);
    const statusDescriptions = [
        EducationStatus.Requested.toString(),
        EducationStatus.InPlanning.toString(),
        EducationStatus.Planned.toString(),
        EducationStatus.Scheduled.toString(),
        EducationStatus.Complete.toString(),
    ];
    let totalEvents = 0;

    const translations = {
        educationByStatus: t("Education By Status"),
        errorMessage: t("Data is unavailable or there is not enough data to display this metric."),
    };

    const width = 250;
    const height = 200;

    const getPieData = (): PieValueType[] => {
        let getPieData = educationByStatus.map((x: EducationByStatus, index: number) => {
            totalEvents += x.eventCount;
            let pieValueType: PieValueType = {
                id: index,
                label: x.statusDescription,
                value: x.eventCount,
                color: educationStatusColor(x.statusDescription),
            };
            return pieValueType;
        });

        return getPieData;
    };

    const educationStatusColor = (description?: string): string => {
        switch (description) {
            case EducationStatus.Requested:
                return widget.red;
            case EducationStatus.InPlanning:
                return widget.orange;
            case EducationStatus.Planned:
                return widget.yellow;
            case EducationStatus.Scheduled:
                return blue.connectCare2;
            case EducationStatus.Complete:
                return widget.green;
            default:
                return widget.red;
        }
    };

    const getEducationByStatus = useCallback(async () => {
        setIsStatusError(false);
        setIsStatusLoading(true);
        const uri = `${requestConnectCareEducations.EducationByStatus}?from=${fromDate}&to=${toDate}`;
        const response = await get<EducationByStatus[]>(uri, true);

        const result = response as EducationByStatus[];

        if (result && result.length > 0) {
            let filteredResults = result.filter(
                (x) => x?.statusDescription && statusDescriptions.includes(x.statusDescription)
            );

            filteredResults?.sort(function (a, b) {
                return (
                    statusDescriptions.indexOf(a.statusDescription) - statusDescriptions.indexOf(b.statusDescription)
                );
            });

            setEducationByStatus(filteredResults);
        } else {
            setIsStatusError(true);
        }

        setIsStatusLoading(false);
    }, [fromDate, toDate, get]);

    useEffect(() => {
        if (hasViewEducationEvents) {
            getEducationByStatus();
        }
    }, [hasViewEducationEvents, getEducationByStatus]);

    return (
        <Box
            data-testid="education-events-by-status"
            border={1}
            color={blue.lightGrayishBlue}
            textAlign={"left"}
            minHeight={"200px"}
            minWidth={"400px"}
            my={2}
            p={1}>
            {isStatusLoading && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <CircularProgress />
                </Box>
            )}

            {(!isStatusLoading || isStatusError) && educationByStatus?.length <= 0 && (
                <Box mt={5}>
                    <ErrorMessage message={translations.errorMessage} />
                </Box>
            )}
            {!isStatusLoading && educationByStatus && educationByStatus.length > 0 && (
                <EventsPieChart
                    heading={translations.educationByStatus}
                    data={getPieData()}
                    centerLabel={totalEvents.toString()}
                    width={width}
                    height={height}
                />
            )}
        </Box>
    );
}
