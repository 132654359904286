import { Box, Grid, LinearProgress, Link } from "@mui/material";
import {
    GridCellParams,
    GridColDef,
    GridEditInputCell,
    GridRenderCellParams,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatDate } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { ServiceRequestCell } from "../../component-library/ServiceRequestCell";
import { OrderDetailLinkCell } from "../../component-library/OrderDetailLinkCell";
import { ItemsInRepair } from "../../models/serviceSummary/ItemsInRepair";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { validators } from "../../utils/validators";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { UpsertPurchaseOrderNumberRequestDto } from "../../models/orders/UpsertPurchaseOrderNumberRequestDto";
import { PoUpdateLevel } from "../../models/orders/PoUpdateLevel";
import { useEffect, useMemo, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { requestConnectCarePurchaseOrdersRequired } from "../../services/apiPaths";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { OrderStatuses } from "../../models/orders/OrderStatuses";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";

export interface ItemsInRepairProps {
    searchText: string;
    itemsInRepair: ItemsInRepair[];
    loading?: boolean;
    isPoDisabled: boolean;
}

/**
To transform the values of each cell in a row.
@param {ItemsInRepair[]} rows- The datagrid rows to be transformed.
@example
rows={transformedRows(props.itemsInRepair)} where props.itemsInRepair is a colletion of rows need to be displayed in the datagrid.
Here, the value of a column with type Date needs to be transformed in a valid date format so that IS BEFORE date filter should start working.
@returns {GridValidRowModel} The transformed rows.
*/
export const transformedRows = (rows: ItemsInRepair[]): GridValidRowModel[] => {
    return rows.map((x) => {
        return {
            ...x,
            createdDate: x?.createdDate ? new Date(FormatDate(x?.createdDate)) : x?.createdDate,
            orderedDate: x?.orderedDate ? new Date(FormatDate(x?.orderedDate)) : x?.orderedDate,
            scheduledDate: x?.scheduledDate ? new Date(FormatDate(x?.scheduledDate)) : x?.scheduledDate,
        };
    });
};

export default function ItemsInRepairGrid(props: Readonly<ItemsInRepairProps>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { post } = useFetch();
    const [rows, setRows] = useState<GridValidRowModel[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();

    const translations = {
        id: t("Id"),
        srn: t("SRN"),
        serial: t("Serial"),
        equipId: t("Equip ID"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        type: t("Type"),
        customer: t("Customer"),
        department: t("Department"),
        purchaseOrder: t("Purchase Order"),
        order: t("Order"),
        ordered: t("Ordered"),
        scheduled: t("Scheduled"),
        status: t("Status"),
        created: t("Created"),
        addPo: t("Add PO (Double Click)"),
        enterValidPo: t("Enter a valid PO number."),
        poError: t("PO number cannot exceed 50 characters or contain special characters such as &, #, and +."),
        poFailure: t("Purchase Order saving failed."),
        poSuccess: t("Purchase Order successfully saved."),
        noSearchDataFound: t(
            "We found no matches for {{searchText}} for SRN, Order, Serial, Model, Manufacturer, or Type.",
            {
                searchText: props.searchText,
            }
        ),
        noRowsFound: t("No rows"),
    };

    const [gridCols, setGridCols] = useState<GridColDef[]>([]);

    const column: GridColDef[] = useMemo(
        () => [
            {
                field: "orderNumber",
                headerName: translations.order,
                flex: 1,
                minWidth: 120,
                renderHeader: () => translations.order,
                renderCell: (params: GridRenderCellParams) => (
                    <OrderDetailLinkCell
                        orderHeaderId={params.row.orderHeaderId}
                        orderNumber={params.row.orderNumber}
                    />
                ),
            },
            {
                field: "srn",
                headerName: translations.srn,
                flex: 1,
                minWidth: 120,
                renderHeader: () => translations.srn,
                renderCell: (params: GridRenderCellParams) => <ServiceRequestCell value={params.value} />,
            },
            {
                field: "serialNumber",
                headerName: translations.serial,
                flex: 1,
                minWidth: 120,
                renderHeader: () => translations.serial,
                renderCell: (params) => (
                    <Link
                        onClick={() => {
                            navigate(
                                `/assets/${params.row.custAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                    params.row.serialNumber
                                )}`
                            );
                        }}>
                        {params.row.serialNumber}
                    </Link>
                ),
            },
            {
                field: "equipmentId",
                headerName: translations.equipId,
                flex: 1,
                minWidth: 100,
                renderHeader: () => translations.equipId,
            },
            {
                field: "manufacturer",
                headerName: translations.manufacturer,
                flex: 1,
                minWidth: 140,
                renderHeader: () => translations.manufacturer,
            },
            {
                field: "model",
                headerName: translations.model,
                flex: 1,
                minWidth: 120,
                renderHeader: () => translations.model,
            },
            {
                field: "productType",
                headerName: translations.type,
                flex: 2,
                minWidth: 150,
                renderHeader: () => translations.type,
            },
            {
                field: "customer",
                headerName: translations.customer,
                width: 220,
                minWidth: 120,
                renderHeader: () => translations.customer,
            },
            {
                field: "departmentDescription",
                headerName: translations.department,
                flex: 1,
                minWidth: 120,
                renderHeader: () => translations.department,
            },
            {
                field: "customerPONumber",
                headerName: translations.purchaseOrder,
                flex: 1,
                minWidth: 160,
                editable: !props.isPoDisabled,
                renderHeader: () => translations.purchaseOrder,
                renderCell: (params) => {
                    return (
                        !params.row.isIndirectFiltered && AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && (
                            <Box
                                component="span"
                                sx={{ color: params.row.customerPONumber ?? "red" }}>
                                {params.row.customerPONumber != null ||
                                params.row.orderStatus === OrderStatuses.Closed ||
                                !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
                                    ? params.row.customerPONumber
                                    : translations.addPo}
                            </Box>
                        )
                    );
                },
                renderEditCell: (params) => {
                    return <GridEditInputCell {...params} />;
                },
            },
            {
                field: "orderedDate",
                headerName: translations.ordered,
                type: "date",
                flex: 1,
                minWidth: 130,
                renderHeader: () => translations.ordered,
                valueFormatter: ({ value }) => FormatDate(value),
                sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
            },
            {
                field: "scheduledDate",
                headerName: translations.scheduled,
                type: "date",
                flex: 1,
                minWidth: 130,
                renderHeader: () => translations.scheduled,
                valueFormatter: ({ value }) => FormatDate(value),
                sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
            },
            {
                field: "status",
                headerName: translations.status,
                flex: 1,
                minWidth: 150,
                renderHeader: () => translations.status,
            },
            {
                field: "createdDate",
                headerName: translations.created,
                type: "date",
                flex: 1,
                minWidth: 130,
                renderHeader: () => translations.created,
                valueFormatter: ({ value }) => FormatDate(value),
                sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
            },
        ],
        [
            navigate,
            props.isPoDisabled,
            translations.created,
            translations.customer,
            translations.department,
            translations.addPo,
            translations.equipId,
            translations.manufacturer,
            translations.model,
            translations.order,
            translations.ordered,
            translations.purchaseOrder,
            translations.scheduled,
            translations.serial,
            translations.srn,
            translations.status,
            translations.type,
        ]
    );

    const processRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
        if (newRow.customerPONumber !== oldRow.customerPONumber) {
            savePo(newRow.customerPONumber, newRow.orderNumber).then((result) => {
                if (result) {
                    const updatedRow = { ...newRow, isNew: false };
                    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
                    return updatedRow;
                }
            });
        }
        return oldRow;
    };

    // If the field is PO && isIndirectFiltered applied Or Order status is CLOSED, disable the edit action
    const isEditable = (param: GridCellParams) => {
        if (
            (param.field === "customerPONumber" && props.isPoDisabled) ||
            param.row.orderStatus === OrderStatuses.Closed ||
            !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
        ) {
            return false;
        } else {
            return true;
        }
    };

    const savePo = async (newPoNumber: string, orderNumber: number): Promise<boolean> => {
        if (!newPoNumber.trim()) {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.enterValidPo,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }

        let validationResult = validators.pONumber(newPoNumber);
        if (!validationResult) {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.poError,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }

        const requestData: UpsertPurchaseOrderNumberRequestDto = {
            OrderLineId: props.itemsInRepair.find((x) => x.orderNumber === orderNumber)?.id,
            PurchaseOrderNumber: newPoNumber.trim(),
            UpdateLevel: PoUpdateLevel.LINE,
            ShouldOverwriteNonMatchingHeader: false,
            OrderHeaderId: props.itemsInRepair.find((x) => x.orderNumber === orderNumber)?.orderHeaderId,
        };

        setIsSaving(true);
        const response = (await post<UpsertPurchaseOrderNumberRequestDto>(
            requestConnectCarePurchaseOrdersRequired.UpsertPurchaseOrderNumber,
            requestData,
            true
        )) as Response;
        setIsSaving(false);
        if (response.ok) {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.poSuccess,
                    toastType: ToastTypes.Success,
                })
            );
            return true;
        } else {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.poFailure,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }
    };

    useEffect(() => {
        setRows(transformedRows(props.itemsInRepair));
    }, [props.itemsInRepair]);

    /**
     *  This below logic can be make shared and reused across grid.
     *  But as of now keeping it here for time being, as it requires good abstraction
     *  on FE as well.
     */
    useEffect(() => {
        const needsFilteringOfColumns = props.itemsInRepair.every(
            (x) => x.filteredPropertyNames?.length === props.itemsInRepair[0]?.filteredPropertyNames?.length
        );

        if (needsFilteringOfColumns) {
            // list of filterPropertyNames for each row
            const propertyNames = new Set<string>([]);

            props.itemsInRepair?.forEach((x) => {
                x.filteredPropertyNames?.forEach((y) => {
                    let shouldHide = true;
                    const propName = y.toLowerCase();
                    if (Object.hasOwn(x, propName)) {
                        // Only decide to hide the column only if its value is null and part of filteredPropertyNames
                        // If we found any row where its not null, we don't hide that column although its part of filteredPropertyNames
                        if (x[propName as keyof ItemsInRepair] !== null) {
                            shouldHide = false;
                        }
                    }

                    if (shouldHide) {
                        propertyNames.add(y.toUpperCase());
                    }
                });
            });

            // We remove the columns if the whole column is filtered for all rows.
            let newColumns = column.filter((x) => {
                return !propertyNames.has(x.field.toUpperCase());
            });

            setGridCols(newColumns);
        } else {
            setGridCols(column);
        }
    }, [column, props.itemsInRepair]);

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="items-in-repair-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    equipmentId: false,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: "customerName", sort: "asc" }],
                            },
                        }}
                        apiRef={apiRef}
                        processRowUpdate={processRowUpdate}
                        isCellEditable={(param) => isEditable(param)}
                        rows={rows}
                        columns={gridCols}
                        paginationModel={{ page: 1, pageSize: 10 }}
                        pageSizeOptions={[10]}
                        density="compact"
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.itemsInRepair),
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () =>
                                NoRowsOverlay({
                                    text: props.searchText ? translations.noSearchDataFound : translations.noRowsFound,
                                }),
                            filterPanel: StyledFilterPanel,
                        }}
                        loading={props.loading ?? isSaving}
                        columnBuffer={14}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
