import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { EducationDetail } from "../../models/education/Education";
import { FormatDate } from "../../common/Formatters";
import { useTranslation } from "react-i18next";

const EducationDetailGrid = ({ educationDetails }: { educationDetails: EducationDetail }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const useMatches = useMediaQuery(theme.breakpoints.up("md"));

    const translations = {
        registration: t("Registration"),
        department: t("Department"),
        requested: t("Requested By"),
        sponsor: t("Sponsor"),
        instructor: t("Instructor"),
        created: t("Created"),
        last: t("Last Updated"),
        expected: t("Expected Attendees"),
        actual: t("Actual Attendees"),
        credits: t("CE Credits"),
        notes: t("Notes"),
        agenda: t("Agenda"),
    };

    return (
        <Grid
            container
            spacing={2}
            padding={4}
            columns={8}
            data-testid="education-detail-grid">
            <Grid item>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.registration}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.registration}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.department}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.departmentName}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.requested}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.requestedBy}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.sponsor}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.sponsorName}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    STERIS {translations.sponsor}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.sterisSponsorName}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.instructor}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.instructorName}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.created} DATE
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {FormatDate(educationDetails?.createdDate)}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}
                    minWidth={"10rem"}>
                    {translations.expected}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.expectedAttendees}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.actual}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.actualAttendees}
                </Typography>
            </Grid>
            <Grid
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.credits}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {educationDetails?.ceCredits}
                </Typography>
            </Grid>
            <Grid
                ml={!useMatches ? 0 : 60}
                item
                md={1}
                sm={2}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.last}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}>
                    {FormatDate(educationDetails?.updatedDate)}
                </Typography>
            </Grid>
            <Grid
                item
                md={6}
                sm={8}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.notes}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}
                    textTransform={"none"}>
                    {educationDetails?.notes}
                </Typography>
            </Grid>
            <Grid
                item
                md={8}>
                <Typography
                    component={"p"}
                    variant={"detailLabel"}>
                    {translations.agenda}
                </Typography>
                <Typography
                    component={"p"}
                    variant={"detailValue"}
                    textTransform={"none"}>
                    {educationDetails?.agenda}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default EducationDetailGrid;
