import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../../common/BaseGridState";
import { BaseApiState } from "../../common/BaseApiState";
import { baseGridInitialState, baseGridReducers } from "../baseGridsSlice";
import { PurchaseOrdersRequired } from "../../../models/serviceSummary/PurchaseOrdersRequired";
import { setDataRequested, setDataReceivedWithError, setDataLoaded } from "../sharedReducers";

export interface PurchaseOrdersState extends BaseGridState, BaseApiState<PurchaseOrdersRequired[]> {}

const initialState: PurchaseOrdersState = {
    ...baseGridInitialState,
    gridColumns: {
        id: false, //hide the id column by default.
        /* hide purchase order column purchaseOrderNumber: false */
    },
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
};

export const PurchaseOrders = createSlice({
    name: "purchaseOrders",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDataRequested,
        setDataReceivedWithError,
        setDataLoaded,
        dataReceived: (state, action: PayloadAction<PurchaseOrdersRequired[]>) => {
            state.data = action.payload;
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.isLoading = false;
        },
    },
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = PurchaseOrders.actions;

export default PurchaseOrders.reducer;
