import { Box, Grid, LinearProgress, Link, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowModel, GridValidRowModel } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatDate } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { GridCellValueWithRoundIcon } from "../../component-library/GridCellValueWithRoundIcon";
import { RecommendedForService } from "../../models/serviceSummary/RecommendedForService";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { useTranslation } from "react-i18next";
import { ConnectCareActionButton, IActionMenuItems } from "../../component-library/ConnectCareActionButton";
import { srnTypeKeys } from "../../config/data";
import { useState } from "react";
import { MarkAsServiced } from "./MarkAsServiced";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { customSortComparators } from "../../utils/customSortComparators";

export interface RecommendedForServiceProps {
    recommendedForService: RecommendedForService[];
    loading?: boolean;
    refreshData: () => void;
}

const DepartmentCell = ({ row }: GridRenderCellParams) => {
    return (
        <Tooltip
            title={row.departmentDescription}
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <Box component="span">{row.departmentDescription}</Box>
        </Tooltip>
    );
};

/**
To transform the values of each cell in a row.
@param {RecommendedForService[]} rows - The datagrid rows to be transformed.
@example
rows={transformedRows(recommendedForService)} where recommendedForService is a colletion of rows need to be displayed in the datagrid.
Here, the value of a column with type Date needs to be transformed in a valid date format so that IS BEFORE date filter should start working.
@returns {GridValidRowModel} The transformed rows.
*/
export const transformedRows = (rows: RecommendedForService[]): GridValidRowModel[] => {
    return rows.map((x) => {
        return {
            ...x,
            inspected: x?.inspected ? new Date(FormatDate(x?.inspected)) : x?.inspected,
        };
    });
};

export default function RecommendedForServiceGrid(props: RecommendedForServiceProps) {
    const navigate = useNavigate();
    const [showMarkAsServiced, setShowMarkAsServiced] = useState(false);
    const [selectedRecommendedForServiceItem, setSelectedRecommendedForServiceItem] = useState<RecommendedForService>();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const translations = {
        id: t("Id"),
        serial: t("Serial"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        category: t("Category"),
        type: t("Type"),
        customer: t("Customer"),
        department: t("Department"),
        inspectionResult: t("Inspection Result"),
        inspected: t("Inspected"),
        serviceRequest: t("Service Request"),
        sendForService: t("Send For Service"),
        markAsServiced: t("Mark as Serviced"),
        send: t("Send"),
        noDataFound: t("No Rows"),
        tooltipTitle: t("Inspection Result"),
        actions: t("Actions"),
        viewSRN: t("View SRN"),
        markAsServicedSuccess: t("The Item has been Marked As Serviced"),
    };

    const sendForService = (params: GridRowModel<RecommendedForService>) => {
        let url = "";
        if (!params.srn) {
            url = `/servicerequest/add-items/${srnTypeKeys.Serialized}/${params.custAccountId}/${encodeURIComponent(
                params.serialNumber
            )}/${params.inventoryItemId}`;
        } else {
            url = `/orders/srn/${params.srn}`;
        }

        navigate(url);
    };

    const handleMarkAsServiced = (item: GridRowModel<RecommendedForService>) => {
        setSelectedRecommendedForServiceItem(item);
        setShowMarkAsServiced(true);
    };

    const handleMarkAsServicedCancel = () => {
        setShowMarkAsServiced(false);
    };
    const handleMarkAsServicedSubmit = () => {
        setShowMarkAsServiced(false);
        handleSaveSuccessToast();
        props.refreshData();
    };

    const handleSaveSuccessToast = () => {
        dispatch(
            setToast({
                showToast: true,
                toastType: ToastTypes.Success,
                toastMessage: translations.markAsServicedSuccess,
            })
        );
    };

    const getMenuItems = (params: GridRowModel<RecommendedForService>): IActionMenuItems[] => {
        return [
            {
                name: params.srn ? translations.viewSRN : translations.sendForService,
                onClick: () => sendForService(params),
                hide: !params.srn && !params.canSendForService,
            },
            {
                name: translations.markAsServiced,
                onClick: () => handleMarkAsServiced(params),
                hide: params.srn !== null || !AuthLibrary.hasAnyClaim([claimTypes.CreateSrn]),
            },
        ];
    };

    const columns: GridColDef[] = [
        {
            field: "Actions",
            headerName: translations.actions,
            renderHeader: () => translations.actions,
            minWidth: 200,
            renderCell: (params: GridRenderCellParams) => (
                <>
                    {params.row.srn && <Link onClick={() => sendForService(params.row)}>{translations.viewSRN}</Link>}
                    {!params.row.srn && <ConnectCareActionButton menuItems={getMenuItems(params.row)} />}
                </>
            ),
            flex: 1,
            filterable: false,
            disableExport: true,
            sortable: false,
            hideable: false,
        },
        {
            field: "diagnosis",
            headerName: translations.inspectionResult,
            renderHeader: () => translations.inspectionResult,
            minWidth: 200,
            flex: 1,
            renderCell: (params) => <GridCellValueWithRoundIcon {...params} />,
        },
        {
            field: "inspected",
            headerName: translations.inspected,
            renderHeader: () => translations.inspected,
            minWidth: 150,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
            flex: 1,
        },
        {
            field: "serialNumber",
            headerName: translations.serial,
            minWidth: 150,
            renderHeader: () => translations.serial,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.custAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.serialNumber}
                </Link>
            ),
            flex: 1,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacturer,
            renderHeader: () => translations.manufacturer,
            minWidth: 180,
            flex: 1,
        },
        {
            field: "model",
            headerName: translations.model,
            renderHeader: () => translations.model,
            minWidth: 130,
            flex: 1,
        },
        {
            field: "category",
            headerName: translations.category,
            renderHeader: () => translations.category,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "productType",
            headerName: translations.type,
            renderHeader: () => translations.type,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "customer",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "deptDescription",
            headerName: translations.department,
            renderHeader: () => translations.department,
            renderCell: (params) => <DepartmentCell {...params} />,
            minWidth: 160,
            flex: 1,
        },
    ];

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="recommended-for-service-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        rows={transformedRows(props.recommendedForService)}
                        columns={columns}
                        columnBuffer={10}
                        density="compact"
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.recommendedForService),
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => NoRowsOverlay({ text: translations.noDataFound }),
                            filterPanel: StyledFilterPanel,
                        }}
                        slotProps={{
                            columnsPanel: { sx: { textTransform: "capitalize" } },
                        }}
                        loading={props.loading}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                </GridAreaLayout>
            </Grid>
            {showMarkAsServiced && (
                <MarkAsServiced
                    data-testid="mark-as-serviced-modal"
                    recommendedForServiceItem={selectedRecommendedForServiceItem}
                    onCancelClick={handleMarkAsServicedCancel}
                    onMarkAsServicedSubmit={handleMarkAsServicedSubmit}
                />
            )}
        </Grid>
    );
}
