import { Typography, Tabs, Tab, Box, SxProps } from "@mui/material";
import { blue, text } from "../theme/colors";
import React from "react";

/**
 * Represents custom created type of each MuiTab
 */
export type ITab = {
    /**
     * Name/Id of each tab
     */
    tabId: string;
    /**
     * Icon that we used to show on Mui Tab
     */
    icon: JSX.Element;
    /**
     * React Element type usually Text or Text along with Spinner
     */
    children?: JSX.Element;
    /**
     * Style for MuiTabs
     */
    sx?: SxProps;
    /**
     * when true hides the given tab
     */
    hide?: boolean;
};

export interface ConnectCareTabsProps {
    currentTab: ITab | undefined;
    handleTabChange: (_event: React.SyntheticEvent, newValue: string) => void;
    tabs: ITab[];
    sx?: SxProps;
}

/**
 * This component can be used to render MUI Tabs
 * @param currentTab: MuiTab that is currently selected
 * @param handleTabChange: Event to trigger while the user is selecting the other tabs
 * @param tabs: Arrays of tabs with Text on MuiTab and MuiIcon and Spinner as optional
 * @returns MuiTabs
 */

export const ConnectCareTabs = ({ currentTab, handleTabChange, tabs, sx }: ConnectCareTabsProps) => {
    return (
        <Tabs
            value={currentTab?.tabId}
            variant="scrollable"
            aria-label="scrollable"
            onChange={handleTabChange}
            sx={{ ...sx }}>
            {tabs?.map(
                (tab: ITab) =>
                    !tab.hide && (
                        <Tab
                            sx={{
                                backgroundColor: currentTab?.tabId === tab.tabId ? blue.connectCare2 : text.textWhite,
                            }}
                            key={tab.tabId}
                            value={tab.tabId}
                            label={
                                <Typography variant={currentTab?.tabId === tab.tabId ? "activeTab" : "inActiveTab"}>
                                    {tab.children}
                                </Typography>
                            }
                            icon={
                                <Box
                                    sx={{
                                        mr: 1,
                                        mt: 0.5,
                                        color: currentTab?.tabId === tab.tabId ? text.textWhite : text.textgray2,
                                    }}>
                                    {tab.icon}
                                </Box>
                            }
                        />
                    )
            )}
        </Tabs>
    );
};
