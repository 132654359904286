import { Box, Grid, Stack, Typography } from "@mui/material";
import { text } from "../theme/colors";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";


/**
 * Displays No rows found or any customize message when there is no records in the grid
 *
 * @param {string} text translations text from page
 */
export function NoRowsOverlay(props: { text: string }) {
    return (
        <Stack
            data-testid="noRowsOverlay"
            height="100%"
            alignItems="center"
            justifyContent="center">
            {props.text}
        </Stack>
    );
}

export const NoRowsOverlayWithIcon = (props: { primaryText: string, secondaryText: string }) => {
    return (
        <Grid
            data-testid="NoRowsOverlayWithIcon"
            borderRadius={1}
            bgcolor="bg.white"
            container
            spacing={0}
            direction="column"
            justifyContent="flex-start"
            paddingTop="64px"
            display="flex"
            alignItems="center"
            paddingLeft="16px">
            <ThunderstormIcon sx={{ color: "#DFE0EB" }}></ThunderstormIcon>
            <Typography
                color="font.darkBlue"
                fontSize="24px"
                fontWeight="400"
                letterSpacing="1px"
                paddingLeft={"12px"}>
                {props.primaryText}
            </Typography>
            <Box paddingTop="16px">
                <Typography
                    color={text.textdarkgray}
                    fontSize="16px">
                    {props.secondaryText}
                </Typography>
            </Box>
        </Grid>
    )
}
