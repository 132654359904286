export const TableauConstants = {
    uriPrefix: "https://prod-useast-a.online.tableau.com/#/site/connectcare/views/",
};

export const TableauSettings = {
    deviceInspectionHistory: {
        relativeUrl: "DeviceInspectionHistory-Facility/FilterPage",
        friendlyReportName: "Device Inspection History-Facility",
    },
    helloConnectCare: {
        relativeUrl: "HelloConnectCareCustomer/HelloConnectCare",
        friendlyReportName: "Hello ConnectCare Customer",
    },
    observedDamage: {
        relativeUrl: "ObservedDamage-Facility/PARAMETERS",
        friendlyReportName: "Observed Damage-Facility",
    },
    recommendedNeverServiced: {
        relativeUrl: "RecommendedNeverServicedCustomerFacing/FilterPage",
        friendlyReportName: "Recommended Never Serviced Customer Facing",
    },
    repairFrequency: {
        relativeUrl: "RepairFrequencyandSeverity/Main",
        friendlyReportName: "Repair Frequency and Severity",
    },
};
