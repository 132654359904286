import { Grid, LinearProgress, Link } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useTranslation } from "react-i18next";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatCurrency, FormatDate } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { setGridFilter, setGridSort, setGridColumns } from "../../redux/reducers/invoices/repairCreditSlice";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { customSortComparators } from "../../utils/customSortComparators";

export default function RepairCreditGrid() {
    const { t } = useTranslation();
    const dispatch = useDispatch();  

    const { isLoading, data, gridColumns, gridFilter, gridSort } = useSelector((state: StoreState) => state.repairCredit);
    const translations = {
        noRowsFound: t("You have no repair credits for the Facilities selected."),
        credited: t("Credited"),
        accountManager: t("Account Manager"),
        email: t("Email"),
        reason: t("Reason"),
        customer: t("Customer"),
        remainingAmount: t("Remaining Amount"),
        originalAmount: t("Original Amount"),
    };

    const repairCreditsColumn: GridColDef[] = [
        {
            field: "creditedDate",
            renderHeader: () => translations.credited,
            headerName: translations.credited,
            minWidth: 150,
            type: "date",
            flex: 1,
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
        },
        {
            field: "originalDueAmount",
            renderHeader: () => translations.originalAmount,
            headerName: translations.originalAmount,
            minWidth: 210,
            flex: 1,
            headerAlign: "left",
            align: "right",
            type: "number",
            sortComparator: (v1, v2) => customSortComparators.sortByAlphanumerics(v1, v2),
            renderCell: (cellParams: GridRenderCellParams) =>
                FormatCurrency(cellParams.row.originalDueAmount, cellParams.row.currencyCode),
            valueGetter: ({ row }) => {
                return row.originalDueAmount;
            },
        },
        {
            field: "amountDueRemaining",
            renderHeader: () => translations.remainingAmount,
            headerName: translations.remainingAmount,
            minWidth: 220,
            flex: 1,
            headerAlign: "left",
            align: "right",
            type: "number",
            sortComparator: (v1, v2) => customSortComparators.sortByAlphanumerics(v1, v2),
            renderCell: (cellParams: GridRenderCellParams) =>
                FormatCurrency(cellParams.row.amountDueRemaining, cellParams.row.currencyCode),
            valueGetter: ({ row }) => {
                return row.amountDueRemaining;
            },
        },
        {
            field: "customer",
            renderHeader: () => translations.customer,
            headerName: translations.customer,
            sortable: true,
            minWidth: 300,
            flex: 1,
        },
        {
            field: "comments",
            renderHeader: () => translations.reason,
            headerName: translations.reason,
            sortable: true,
            minWidth: 160,
            flex: 1,
        },
        {
            field: "accountManager",
            renderHeader: () => translations.accountManager,
            headerName: translations.accountManager,
            sortable: true,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "emailId",
            renderHeader: () => translations.email,
            headerName: translations.email,
            renderCell: (params: GridRenderCellParams) => (
                <Link
                    onClick={() => (window.location.href = `mailto:${params.value}`)}
                    sx={{
                        ml: 0.5,
                        fontSize: "14px",
                        color: "blue.connectCare2",
                        textDecoration: "none",
                    }}>
                    {params.value}
                </Link>
            ),
            sortable: true,
            minWidth: 170,
            flex: 1,
        },
    ];

    const handleGridColumnVisibility = (column: GridColumnVisibilityModel) => {
        dispatch(setGridColumns(column));
    };

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridFilter,
        },
        sorting: {
            sortModel: gridSort,
        },
    };
    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="repair-credit-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={initialGridState}
                        rows={data}
                        columns={repairCreditsColumn}
                        density="compact"
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.repairCredits),
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => NoRowsOverlay({ text: translations.noRowsFound }),
                            filterPanel: StyledFilterPanel,
                        }}
                        slotProps={{
                            columnsPanel: { sx: { textTransform: "capitalize" } },
                        }}
                        columnBuffer={10}
                        loading={isLoading}
                        columnVisibilityModel={gridColumns}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                        onColumnVisibilityModelChange={(columnModel) => handleGridColumnVisibility(columnModel)}
                        onFilterModelChange={(filterModel) => dispatch(setGridFilter(filterModel))}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
