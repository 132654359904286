import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";
import { FacilityService } from "../../services/facility/FacilityService";
import { DepartmentResponse } from "../../models/admin/AccountDepartment";

export default function DepartmentSelect({
    selectedDepartments,
    onDepartmentSelected,
    accountId,
}: Readonly<{ selectedDepartments: any; onDepartmentSelected: any; accountId: number }>) {
    const [departmentOptions, setDepartmentOptions] = useState<DepartmentResponse[]>([]);
    const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<string[]>(selectedDepartments);
    const { t } = useTranslation();

    const translations = {
        department: t("Department"),
        selectAll: t("Select All"),
    };

    const handleChange = (event: SelectChangeEvent<typeof selectedDepartmentIds>) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            if (selectedDepartmentIds.length === departmentOptions.length) {
                setSelectedDepartmentIds([]);
            } else {
                departmentOptions.forEach((option) => {
                    if (!selectedDepartmentIds.includes(option.id))
                        setSelectedDepartmentIds((prev) => [...prev, option.id]);
                });
            }
            return;
        }
        setSelectedDepartmentIds(typeof value === "string" ? value.split(",") : value);
    };

    const getDepartments = useCallback(
        (accountId: number) => {
            new FacilityService()
                .getDepartments(accountId)
                .then((response: DepartmentResponse[]) => setDepartmentOptions(response));
        },
        [setDepartmentOptions]
    );

    useEffect(() => {
        accountId && getDepartments(accountId);
    }, [accountId, getDepartments]);

    useEffect(() => {
        onDepartmentSelected(selectedDepartmentIds);
    }, [onDepartmentSelected, selectedDepartmentIds]);

    return (
        <Grid container>
            <Grid
                item
                xs={12}>
                <FormControl
                    fullWidth
                    size={"small"}
                    required>
                    <InputLabel>{translations.department}</InputLabel>
                    <Select
                        data-testid="departments-dropdown"
                        multiple
                        value={selectedDepartmentIds}
                        renderValue={(selected) => {
                            const strings = selected.map((x) => departmentOptions.find((y) => y.id === x)?.name);
                            return strings ? strings.join(", ") : "";
                        }}
                        label={translations.department}
                        onChange={handleChange}>
                        <MenuItem
                            key="all"
                            value="all">
                            <Checkbox
                                checked={
                                    departmentOptions.length > 0 &&
                                    selectedDepartmentIds.length === departmentOptions.length
                                }
                                indeterminate={
                                    selectedDepartmentIds.length > 0 &&
                                    selectedDepartmentIds.length < departmentOptions.length
                                }
                            />
                            <ListItemText
                                primary={translations.selectAll}
                                sx={{ textTransform: "uppercase" }}
                            />
                        </MenuItem>
                        {departmentOptions.map((option) => (
                            <MenuItem
                                key={option.id}
                                value={option.id}>
                                <Checkbox checked={selectedDepartmentIds.indexOf(option.id) > -1} />
                                <ListItemText primary={option.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
