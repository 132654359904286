import { useState } from "react";
import { useDispatch } from "react-redux";
import { setToast } from '../redux/reducers/toastSlice'
import { requestConnectCareReports } from "../services/apiPaths";
import { SsrsReport } from "../models/reports/SsrsReport";
import { useTranslation } from "react-i18next";
import { ToastTypes } from "../models/toast/ToastTypes";
import { useFetch } from "../services/useFetch";
import { claimTypes } from "../config/claimTypes";
import { ConnectCareActionButton, IActionMenuItems } from "./ConnectCareActionButton";
import { AuthLibrary } from "../redux/actions/AuthRedux";

interface IProps {
    pmiId: number,
    /** CustAccountId is used to determine if this customer has access to reports. If it doesn't disable the report button. */
    custAccountId: number
}

/**
 * Shows a report button on Preventive Maintenance Event.
 */
const PmReportButton = (props: Readonly<IProps>) => {
    const { t } = useTranslation();
    const translations = {
        pmEventSummary: t("PM Event Summary"),
        pmEventDetails: t("PM Event Details"),
        reports: t("Reports"),
        error: t("An error occurred retrieving the report."),
        subscriptionExpireed: t("Subscription expired. Some functions are limited. Contact Customer Care at {{tel}} for assistance.", { tel: "1-800-783-9251" })
    };
    const { getBlob } = useFetch();

    const { pmiId } = props;
    const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
    const handlePmEventSummarySelected = () => {
        setIsReportLoading(true);
        runReport(SsrsReport.PmEventSummary, pmiId).finally(() => setIsReportLoading(false));
    };
    const handlePmEventDetailsSelected = () => {
        setIsReportLoading(true);
        runReport(SsrsReport.PmEventDetails, pmiId).finally(() => setIsReportLoading(false));
    };
    const dispatch = useDispatch();

    //Disable these action buttons if the selected subscription is expired.
    const disableMenu = !AuthLibrary.HasAccountSubscriptionAccessToClaim(claimTypes.ViewPMEventSummaryReport, props.custAccountId)
   
    const handleErrorToast = () => {
        dispatch(setToast({
            toastMessage: translations.error,
            toastType: ToastTypes.Error,
            showToast: true,
        }));       
    };

    const runReport = async (reportId: SsrsReport, pmiId: number) => {
        const uri = `${requestConnectCareReports.RunReportById(SsrsReport[reportId])}?pmi_id=${pmiId}`;

        const res = await getBlob(uri, true, handleErrorToast);
        if (res) {
            window.open(window.URL.createObjectURL(res));
        }
    };

    const actionButtons: IActionMenuItems[] = [
        {
            name: translations.pmEventSummary,
            onClick: handlePmEventSummarySelected,
            disable: disableMenu
        },
        {
            name: translations.pmEventDetails,
            onClick: handlePmEventDetailsSelected,
            disable: disableMenu
        },
    ];

    return (
        <ConnectCareActionButton
            actionButtonName={translations.reports}
            menuItems={actionButtons}
            disable={isReportLoading}
        />
    );
};
export default PmReportButton;
