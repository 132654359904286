import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastTypes } from "../../models/toast/ToastTypes";
export interface ToastState {
    toastType?: ToastTypes;
    toastMessage?: string;
    showToast?: boolean;
}

const initialState: ToastState = {
    toastType: ToastTypes.Info,
    toastMessage: "",
    showToast: false,
};

export const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        setToast: (state, action: PayloadAction<ToastState>) => {
            const { payload } = action;
            state = { ...state, showToast: true, ...payload };
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setToast } = toastSlice.actions;

export default toastSlice.reducer;
