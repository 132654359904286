import { createSlice } from "@reduxjs/toolkit";
import { AccountSubscriptionClaims } from "../../models/authorization/AccountSubscriptionClaims";

export interface AccountSubscriptionClaimsState {
    accountSubscriptionClaims: AccountSubscriptionClaims[];
}

const initialState: AccountSubscriptionClaimsState = {
    accountSubscriptionClaims: [
        {
            custAccountId: 0,
            claims: [],
        },
    ],
};

export const accountSubscriptionClaimsSlice = createSlice({
    name: "accountSubscriptionClaims",
    initialState,
    reducers: {
        setAccountSubscriptionClaims: (state, action) => {
            state.accountSubscriptionClaims = action.payload;
        },
    },
});

export const { setAccountSubscriptionClaims } = accountSubscriptionClaimsSlice.actions;

export default accountSubscriptionClaimsSlice.reducer;
