import { DateRange } from "@mui/x-date-pickers-pro";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { DateRangeSearch } from "../../../models/IDateRangeSearch";
import { BaseGridState } from "../../common/BaseGridState";
import { EducationGridModel } from "../../../models/education/Education";
import { KeywordSearch } from "../../../models/IKeywordSearch";
import { BaseApiState } from "../../common/BaseApiState";
import { dateRangeUntilNow } from "../../../utils/dateRange";
import { baseGridInitialState, baseGridReducers } from "../baseGridsSlice";
import { RootState } from "../../store";
import {
    setDataLoaded,
    setDataReceivedWithError,
    setDataRequested,
    setDateRange,
    setKeywordSearch,
} from "../sharedReducers";

export interface EducationInServicesState
    extends DateRangeSearch,
        KeywordSearch,
        BaseGridState,
        BaseApiState<EducationGridModel[]> {}

const initDate = dateRangeUntilNow(366); //Expecting mm/DD/yyyy - mm/DD/yyyy+1

const initialState: EducationInServicesState = {
    ...baseGridInitialState,
    gridColumns: {
        eventType: false, // By default, eventType column should be hidden
    },
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
    searchString: "",
    startDate: initDate[0]!.format("YYYY-MM-DD"),
    endDate: initDate[1]!.format("YYYY-MM-DD"),
    isDateRangeValid: true,
};

export const educationInServicesSlice = createSlice({
    name: "educationInServices",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDateRange,
        setDataRequested,
        setDataReceivedWithError,
        setKeywordSearch,
        setDataLoaded,
        dataReceived: (state, action: PayloadAction<EducationGridModel[]>) => {
            state.data = action.payload;
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.isLoading = false;
        },
    },
});

export const getStartDate = (state: RootState) => state.educationInServices.startDate;
export const getEndDate = (state: RootState) => state.educationInServices.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

// Action creators are generated for each case reducer function
export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setKeywordSearch: setSearchString,
    setDateRange: setSelectedDateRange,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = educationInServicesSlice.actions;

export default educationInServicesSlice.reducer;
