export interface IHoverTooltipProps {
    tooltip: HoverTooltipText;
    customTooltip?: string;
    placement?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | "top"
        | "right";
    margin?: string;
}

export enum HoverTooltipText {
    Custom,
    ServiceDelivery,
    ServiceSummary,
    Assets,
    EstimateRequired,
    ItemsDisinfected,
    OrderItemsStandardRepair,
    RepairCredits,
    EducationInService,
    AddToAssetInventory,
    EquipmentRequestFormTitle,
}
