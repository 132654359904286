import { Box } from "@mui/system";
import { GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { GridBackgroundColor } from "../../theme/theme";
import { Alert, Grid, LinearProgress, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { BreadCrumbList } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { SterisTeam } from "../../models/Dashboard/SterisTeam";
import { NoExportToolbar } from "../../common/GridNoExportToolBar";
import { requestConnectCareUsers } from "../../services/apiPaths";

const MySterisTeam = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasApiError, setHasApiError] = useState<boolean>(false);
    const [mySterisTeamData, setMySterisTeamData] = useState<SterisTeam[]>([]);
    const { get } = useFetch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const translations = {
        name: t("Name"),
        title: t("Title"),
        email: t("Email"),
        mobile: t("Mobile"),
        customer: t("Customer"),
        mySteriTeam: t("My STERIS Team"),
        sterisGlobalServicesTeam: t("STERIS Global Services Team"),
        locateByPostalCode: t("Locate by Postal Code"),
        apiErrorText: t("System Error: API is not available at this time!"),
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: translations.name,
            renderHeader: () => translations.name,
            minWidth: 120,
            flex: 1,
        },
        {
            field: "title",
            headerName: translations.title,
            renderHeader: () => translations.title,
            minWidth: 120,
            flex: 1,
        },
        {
            field: "email",
            headerName: translations.email,
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        window.location.href = `mailto:${params.row.email}"`;
                    }}>
                    {params.row.email}
                </Link>
            ),
        },
        {
            field: "mobile",
            headerName: translations.mobile,
            renderHeader: () => translations.mobile,
            minWidth: 120,
            flex: 1,
        },
        {
            field: "customerName",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 120,
            sortable: true,
            flex: 2,
        },
    ];

    const getmySterisTeamData = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        setHasApiError(false);
        const uri = `${requestConnectCareUsers.MySteriTeam}`;
        const response = await get<SterisTeam[]>(uri, true);

        if (response) {
            setMySterisTeamData(response);
        } else {
            setHasApiError(true);
        }
        setIsLoading(false);
    }, [get]);

    useEffect(() => {
        document.title = translations.mySteriTeam;
        getmySterisTeamData();
    }, [getmySterisTeamData, translations.mySteriTeam]);

    return (
        <Box
            style={GridBackgroundColor}
            data-testid="my-steris-team"
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <BreadCrumb breadCrumbs={BreadCrumbList.sterisTeam} />
            <Box
                style={GridBackgroundColor}
                px={2}>
                <Grid
                    container
                    border={1}
                    borderColor="font.gray2"
                    borderRadius={1}
                    p={1}
                    mt={2}
                    display="flex"
                    justifyContent={"space-between"}>
                    <Grid
                        item
                        marginY={1}
                        paddingX={1}>
                        <Typography
                            variant="h1"
                            color="font.darkBlue">
                            {translations.sterisGlobalServicesTeam}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        marginY={1}
                        paddingX={1}>
                        <Link
                            data-testid="locate-postal-code"
                            underline="none"
                            onClick={() =>
                                window.open(
                                    `${process.env.REACT_APP_LOCATE_SERVICE_REPRESENTATIVES_URL}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                )
                            }>
                            {translations.locateByPostalCode}
                        </Link>
                    </Grid>
                    {hasApiError && (
                        <Grid
                            item
                            xs={12}>
                            <Alert severity="error">{translations.apiErrorText}</Alert>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Grid container>
                <Grid
                    item
                    xs>
                    <GridAreaLayout
                        sx={{ p: 2 }}
                        data-testid="my-steris-team-grid">
                        <StripedDataGrid
                            disableRowSelectionOnClick
                            getRowId={(row) => row.id}
                            rows={mySterisTeamData}
                            columns={columns}
                            paginationModel={{ page: 1, pageSize: 10 }}
                            pageSizeOptions={[10]}
                            density="compact"
                            slots={{
                                toolbar: () => NoExportToolbar(),
                                loadingOverlay: LinearProgress,
                            }}
                            slotProps={{
                                columnsPanel: { sx: { textTransform: "capitalize" } },
                            }}
                            loading={isLoading}
                            columnBuffer={5}
                            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                            sortingOrder={["desc", "asc"]}
                        />
                    </GridAreaLayout>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MySterisTeam;
