import { useCallback, useEffect, useState } from "react";
import {
    DeviceMaintenanceRecords,
    defaultDeviceMaintenanceRecordState,
} from "../../models/serviceDelivery/DeviceMaintenanceRecords";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareCustomers } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { blue, text, widget } from "../../theme/colors";
import ChartAnnotation from "chartjs-plugin-annotation";
import { Chart as ChartJS, Title, Tooltip, Legend } from "chart.js";
import { Divider, Typography, Button, Grid, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BarChart, AxisConfig } from "@mui/x-charts";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

ChartJS.register(Title, Tooltip, Legend, ChartAnnotation);

export default function DeviceMaintenance() {
    const { t, i18n } = useTranslation();
    const { get } = useFetch();
    const navigate = useNavigate();
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [totalCount, setTotalCount] = useState<string>();
    const [renderChart, setRenderChart] = useState<boolean>(true);

    const [deviceMaintenanceRecord, setDeviceMaintenanceRecord] = useState<DeviceMaintenanceRecords>(
        defaultDeviceMaintenanceRecordState
    );

    const translations = {
        ChartTitle: t("Surgical Device Maintenance Status"),
        UpToDate: t("Up to Date"),
        DueForInspection: t("Due for Inspection"),
        OverdueForInspection: t("Overdue for Inspection"),
        DeviceWithNoPreviousInspection: t("Devices with no previous inspection record"),
        subtitle: t("Excludes Flexible Endoscopes"),
        view: t("View"),
    };

    /**
     * Populates the bar chart data state variable, and renders our chart with api data.
     * @param {DeviceMaintenanceRecords} deviceMaintenanceRecord - Api response data.
     */
    const populateBarChartData = useCallback((deviceMaintenanceRecord: DeviceMaintenanceRecords) => {
        setDeviceMaintenanceRecord(deviceMaintenanceRecord);
        setTotalCount(
            Intl.NumberFormat(i18n.language).format(
                deviceMaintenanceRecord.upToDateCount +
                    deviceMaintenanceRecord.dueForInspectionCount +
                    deviceMaintenanceRecord.overDueForInspectionCount
            )
        );
        setRenderChart(true);
    }, []);

    /**
     * Calls the device mainenance records api.
     */
    const getDeviceMaintenanceRecords = useCallback(async () => {
        const response = await get<DeviceMaintenanceRecords>(requestConnectCareCustomers.DeviceMaintenance, true);
        if (response) {
            populateBarChartData(response);
        }
    }, [get, populateBarChartData]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getDeviceMaintenanceRecords(); //Get the data from the api.
            setInitialDataLoaded(true); //Once the page loads rely on selectedFacilitiesChanged to update our charts.
        }
    }, [getDeviceMaintenanceRecords, initialDataLoaded]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities]);

    const getSeries = () => [
        {
            label: translations.UpToDate,
            data: [deviceMaintenanceRecord.upToDateCount],
            color: widget.green,
        },
        {
            label: translations.DueForInspection,
            data: [deviceMaintenanceRecord.dueForInspectionCount],
            color: widget.yellow,
        },
        {
            label: translations.OverdueForInspection,
            data: [deviceMaintenanceRecord.overDueForInspectionCount],
            color: widget.red,
        },
    ];

    return (
        <Grid
            data-testid="device-maintenance-graph"
            container
            minHeight={"90%"}
            textAlign={"center"}
            border={1}
            color={blue.lightGrayishBlue}
            my={2}>
            {renderChart && (
                <Grid
                    item
                    xs>
                    <Card
                        key="device-maintenance"
                        sx={{ boxShadow: 0 }}>
                        <CardHeader
                            title={
                                <>
                                    <Typography
                                        variant="detailLabel"
                                        color={widget.blackishblue}
                                        mt={"8px"}>
                                        {translations.ChartTitle}
                                    </Typography>
                                    <Typography
                                        component="p"
                                        variant="overline"
                                        textTransform="uppercase"
                                        color={widget.grey}
                                        my="2px">
                                        {translations.subtitle}
                                    </Typography>
                                </>
                            }
                        />
                        <CardContent>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 0,
                                    bottom: 90,
                                }}
                                series={getSeries()}
                                xAxis={[
                                    {
                                        scaleType: "band",
                                        data: [`${translations.ChartTitle} (${totalCount})`],
                                        categoryGapRatio: 0,
                                    } as AxisConfig<"band">,
                                ]}
                                slotProps={{
                                    legend: {
                                        direction: "column",
                                        position: { vertical: "bottom", horizontal: "middle" },
                                        padding: 0,
                                        itemMarkWidth: 45,
                                        itemMarkHeight: 12,
                                        markGap: 5,
                                        labelStyle: {
                                            fontSize: 14,
                                            marginTop: 10,
                                        },
                                    },
                                }}
                                leftAxis={null}
                                bottomAxis={null}
                            />
                            <Typography
                                variant="body2"
                                color={text.textgray2}
                                my={2}>
                                {`${deviceMaintenanceRecord?.neverInspectedCount} ${translations.DeviceWithNoPreviousInspection}`}
                            </Typography>
                            <Divider />
                        </CardContent>
                        <CardActions
                            sx={{
                                justifyContent: "center",
                                mb: 1,
                            }}>
                            <Button
                                aria-label={t("View")}
                                data-testid="device-maintenance-view"
                                variant="outlined2"
                                onClick={() => {
                                    navigate(`/assets/dueforpm/device`);
                                }}>
                                {translations.view}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            )}
        </Grid>
    );
}
