import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { Alert, Grid, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";

interface EstimateRecommendedDetail {
    recommendedServices: string;
}

export default function EstimateRecommendedTab({ lineNumber }: { lineNumber: string | undefined }) {
    const { t } = useTranslation();
    const { get } = useFetch();
    const [estimateRecommendedDetails, setEstimateRecommendedDetails] = useState<EstimateRecommendedDetail>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const translations = {
        apiError: t("System Error: API is not available at this time!")
    };

    const getEstimateRecommendedDetails = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        const uriOrder = `${requestConnectCareOrders.EstimateRecommendedDetail}?lineId=${encodeURIComponent(
            lineNumber ?? ""
        )}`;
        const response = await get<EstimateRecommendedDetail>(uriOrder, true);
        if (response) {
            setEstimateRecommendedDetails(response);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [lineNumber, get]);

    useEffect(() => {
        getEstimateRecommendedDetails();
    }, [getEstimateRecommendedDetails]);

    return (
        <Box
            width="100%"
            data-testid="estimate-recommended-tab">
            {isLoading && <LinearProgress></LinearProgress>}
            {!isLoading && isError ? (
                <Grid
                    marginX={4}
                    marginY={1}>
                    <Alert severity="error">{translations.apiError}</Alert>
                </Grid>
            ) : (
                <Grid container>
                    <TableContainer
                        component={Paper}
                        sx={{ paddingLeft: "32px" }}>
                        <Table>
                            <TableBody sx={{ border: "none" }}>
                                <TableRow key={estimateRecommendedDetails?.recommendedServices}>
                                    <TableCell
                                        component="th"
                                        scope="row">
                                        {estimateRecommendedDetails?.recommendedServices
                                            ?.split("\r\n")
                                            .map((item) => <p key={item}>{item}</p>)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Box>
    );
}
