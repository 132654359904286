import {
    AssessmentOutlined,
    CalendarMonth,
    Dashboard,
    Inventory,
    ListAlt,
    RequestQuoteOutlined,
    TextsmsOutlined,
    VpnKeyOutlined,
} from "@mui/icons-material";
import { IAppMenuListData } from "../models/navigation/IAppMenuListData";

/**
 * An array of menu data used to map menu items to their corresponding navigation paths & menu icons.
 * Should match with backend menu data: AppMenuItems.cs
 */
export const AppMenuListData: IAppMenuListData[] = [
    { id: 100, icon: <Dashboard />, nav: "/dashboard" },

    { id: 200, icon: <ListAlt />, nav: "/orders" },
    { id: 201, nav: "/orders/orderitems" },
    { id: 202, nav: "/orders/intransit" },
    { id: 203, nav: "/orders/inrepair" },
    { id: 204, nav: "/orders/shipped" },
    { id: 205, nav: "/orders/loaners" },
    { id: 206, nav: "/orders" },
    { id: 207, nav: "/orders/approvalneeded" },
    { id: 208, nav: "/orders/porequired" },
    { id: 209, nav: "/orders/onlocationvisits" },
    { id: 210, nav: "/orders/equipmentrequest" },

    { id: 300, icon: <Inventory />, nav: "/assets" },
    { id: 301, nav: "/assets" },
    { id: 302, nav: "/assets/dueforpm" },
    { id: 303, nav: "/assets/recommendedservice" },
    { id: 304, nav: "/assets/devicepm" },
    { id: 305, nav: "/assets/traysserviced" },
    { id: 306, nav: "/assets/beyondrepair" },
    { id: 307, nav: "/assets/findmyitem" },
    { id: 308, nav: "/assets/replacedinstruments" },

    { id: 400, icon: <RequestQuoteOutlined />, nav: "/invoices" },
    { id: 401, nav: "/invoices" },
    { id: 402, nav: "/invoices/repaircredits" },

    { id: 500, icon: <CalendarMonth />, nav: "/events" },
    { id: 501, nav: "/events" },
    { id: 502, nav: "/events/education" },
    { id: 503, nav: "/events/businessreviews" },
    { id: 504, nav: "/assets/devicepm" },
    { id: 505, nav: "/events/evaluations" },

    { id: 600, icon: <AssessmentOutlined />, nav: "/analytics" },
    { id: 601, nav: "/analytics/interdashboards" },
    { id: 602, nav: "/analytics/reports" },

    { id: 700, icon: <TextsmsOutlined />, nav: "/support" },

    { id: 800, icon: <VpnKeyOutlined />, nav: "/admin" },
    { id: 801, nav: "/admin/users" },
    { id: 802, nav: "/admin/customers" },
];
