import { useState } from "react";
import { Box, ClickAwayListener, IconButton, List, Stack, useMediaQuery, useTheme } from "@mui/material";
import { headerHeight, headerHeightMobile, sideMenuWidth } from "../../config/layout";
import { DynamicSideNavButton } from "./DynamicSideNavButton";
import { DynamicSubMenu } from "./DynamicSubMenu";
import { SterisLogoIcon } from "../../icons/steris/SterisLogo";
import { sideBarMenu } from "../../theme/colors";
import { useTranslation } from "react-i18next";
import { AppMenuItem } from "../../models/navigation/AppMenuItemResponse";
import { useDispatch, useSelector } from "react-redux";
import { setIsLeftNavigationOpen } from "../../redux/reducers/leftNavigationSlice";
import { StoreState } from "../../redux/store";

export const DynamicAppSideMenu = () => {
    const { leftNavigation } = useSelector((state: StoreState) => state.auth);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const topPosition = isDesktop ? headerHeight : headerHeightMobile;
    const { t } = useTranslation();
    const [showChildNavLinks, setShowChildNavLinks] = useState<boolean>(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState<AppMenuItem>();
    const [currentActiveSideMenu, setCurrentActiveSideMenu] = useState<string>("");
    const dispatch = useDispatch();
    //We aren't interested in getting the left nav state from redux as it's drilled in.
    //We do this to allow the click away handler to work on mobile devices. Otherwise, the menu click in mobile will force the click away handler to handle.
    const leftNavState = useSelector((state: StoreState) => state.leftNavigation);

    const translations = {
        sterisLogo: t("STERIS Logo"),
    };

    const handleNavLinkClick = (menuItem: AppMenuItem) => {
        dispatch(setIsLeftNavigationOpen(true));

        if (currentActiveSideMenu !== menuItem.name) {
            setCurrentActiveSideMenu(menuItem.name);
            if (menuItem.children) {
                setShowChildNavLinks(true);
            } else {
                setShowChildNavLinks(false);
            }
        } else if (menuItem.children) {
            setShowChildNavLinks((prev) => !prev);
        }
        setSelectedMenuItem(menuItem);
    };

    return (
        <Box
            data-testid={"side-menu"}
            width={sideMenuWidth}
            bgcolor={sideBarMenu.backgroundGrey}
            position="fixed"
            zIndex="100"
            top={topPosition}
            height="100%">
            <Stack>
                <IconButton
                    href="https://www.steris.com"
                    target="_blank"
                    rel="noopener,noreferrer"
                    aria-label={translations.sterisLogo}
                    sx={{
                        color: sideBarMenu.black,
                        borderRadius: "0", //When hovering outside of the hitbox the default border radius is 50%, to fit the square on hover we have to remove that.
                        "&.MuiButtonBase-root:hover": { borderRadius: "0" }, //make the round background on hover a square.
                    }}>
                    <SterisLogoIcon sx={{ fontSize: "68px" }}></SterisLogoIcon>
                </IconButton>
            </Stack>
            {showChildNavLinks && (
                <DynamicSubMenu
                    submenuTitle={selectedMenuItem?.id}
                    childMenuItems={selectedMenuItem?.children ?? []}
                    closeMenu={() => setShowChildNavLinks(false)}
                    isSideNavClick={leftNavState.isLeftNavigationOpen}
                />
            )}
            <ClickAwayListener
                onClickAway={() => {
                    if (isDesktop) { //On mobile the user simply clicks the navigation in the right corner to close it.
                        dispatch(setIsLeftNavigationOpen(false));  //Here we'll still set the global state of the left nav, to allow the mobile handler to work.
                        setShowChildNavLinks(false);
                    }
                }}>
                <List disablePadding>
                    {leftNavigation.menuItems?.map((menuItem) => {
                        return (
                            <DynamicSideNavButton
                                key={`left-nav-${menuItem.id}`}
                                menuItem={menuItem}
                                onClick={handleNavLinkClick}
                            />
                        );
                    })}
                </List>
            </ClickAwayListener>
        </Box>
    );
};
