import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface NotAuthorizeDisplayProps {
    handleClick?: () => void;
    label?: string;
}

function NotAuthorizeDisplay({ handleClick, label = "DASHBOARD" }: Readonly<NotAuthorizeDisplayProps>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const strings = {
        ccLogoAlt: t("ConnectCare Logo"),
        notAuthorized: t("Not Authorized!"),
        noAccess: t("You do not have Access to this page or feature. If you think this is an error,"),
        contact: t("please click Contact Us in the top navigation to reach out for support"),
    };
    const handleClickLabel = () => {
        if (handleClick) {
            handleClick();
        } else {
            navigate("/dashboard");
        }
    };
    return (
        <Box
            data-testid="not-authorized-display"
            bgcolor={"bg.lightGray"}
            height="calc(100vh - 122px)"
            p={2}>
            <Card
                variant="outlined"
                sx={{ height: "100%" }}>
                <CardContent>
                    <Grid
                        container
                        justifyContent={"center"}
                        pt={15}>
                        <Grid
                            item
                            xs={12}>
                            <Typography textAlign={"center"}>
                                <img
                                    width="250"
                                    height="113"
                                    src="/images/STERIS-ConnectCareLogo.png"
                                    alt={strings.ccLogoAlt}
                                />
                            </Typography>
                            <Typography
                                variant="h4"
                                textAlign={"center"}
                                marginTop={1}
                                marginBottom={3}>
                                {strings.notAuthorized}
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign={"center"}>
                                {strings.noAccess}
                            </Typography>
                            <Typography
                                textAlign={"center"}
                                variant="body1">
                                {strings.contact}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            mt={3}>
                            <Button
                                data-testid="not-authorized-display-button"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleClickLabel}>
                                {label}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}

export default NotAuthorizeDisplay;
