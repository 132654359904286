import { MouseEvent, memo, useCallback, useMemo, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { Box, Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * Represents type of each action menu items
 */
export type IActionMenuItems = {
    /**
     * Name of action button to display
     */
    name: string;
    /**
     * Handler for action button
     * @returns void
     */
    onClick: () => void;
    /**
     * when true disables the action button
     */
    disable?: boolean;

    /**
     * when true hides the given menu item in action button
     */
    hide?: boolean;
};

/**
 * Represents prop type for ConnectCareActionButton
 */
export type IActionMenuProps = {
    /**
     * List of menu items for action button check
     * @type IActionMenuItems
     */
    menuItems: IActionMenuItems[];
    /**
     * Name of action button to show. Defaults to "Actions"
     */
    actionButtonName?: string;
    /**
     * Represents a flag for loading indicator
     */
    isProcessing?: boolean;
    /**
     * Disables the action buttons entirely
     */
    disable?: boolean;
};

/**
 * Implementation of ConnectCare action buttons
 * @param props
 * @returns
 */
const ConnectCareActionButtonCore = (props: IActionMenuProps) => {
    const { t } = useTranslation();

    const translations = {
        actions: t(props.actionButtonName ?? "Actions"),
    };

    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const open = !!anchorElement;

    const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorElement(null);
    };

    const handleItemClick = useCallback((item: IActionMenuItems) => {
        closeMenu();
        item.onClick();
    }, []);

    const renderMenuItems = useMemo(() => {
        return props.menuItems.map((item) => {
            return (!item.hide &&
                <MenuItem
                    key={item.name}
                    data-testid={`${item.name}-link`}
                    disabled={item.disable}
                    onClick={() => handleItemClick(item)}>
                    {item.name}
                </MenuItem>
            );
        });
    }, [handleItemClick, props.menuItems]);

    return (
        <>
            <Button
                data-testid="cc-action-button"
                id="cc-action-button"
                aria-controls={open ? "cc-action-button" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : "false"}
                variant="dropdown"
                disabled={props.isProcessing || props.disable}
                onClick={openMenu}>
                {translations.actions}
                <Box
                    ml={2}
                    display={"flex"}>
                    {props.isProcessing ? (
                        <CircularProgress
                            size={20}
                            data-testid={"cc-action-button-processing-spinner"}
                        />
                    ) : (
                        <ExpandMore />
                    )}
                </Box>
            </Button>
            <Menu
                anchorEl={anchorElement}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                    "aria-labelledby": "cc-action-button",
                }}>
                {renderMenuItems}
            </Menu>
        </>
    );
};

export const ConnectCareActionButton = memo(ConnectCareActionButtonCore);
