/**
 * Enum for mui alerts.
 * These toast types are 1:1 ion alignment with mui alerts. {@link https://mui.com/material-ui/react-alert/#basic-alerts MUI Alerts}
 * @readonly
 * @enum {string} 
 */
export enum ToastTypes {
    Success = "success",
    Error = "error",
    Info = "info",
    Warning = "warning"
}