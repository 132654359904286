import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StoreState } from "../../redux/store";
import TextArea from "../../component-library/TextArea";
import { DataModes } from "../../models/DataModes";
import { FormatDate } from "../../common/Formatters";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { setDataMode, setHasUnsavedChanges } from "../../redux/reducers/userSlice";
import { useNavigate } from "react-router";

function AdminUserDetails() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { selectedUser } = useAppSelector((state) => state.user);
    const { currentUser } = useAppSelector((state: StoreState) => state.auth);
    const { t } = useTranslation();

    const translations = {
        FirstName: t("First Name"),
        LastName: t("Last Name"),
        Email: t("Email"),
        UserRoles: t("User Roles"),
        PersonType: t("Person Type"),
        UserCreated: t("User Created"),
        Edit: t("Edit"),
        goback: t("Go back"),
        DocumentTitle: t(`${process.env.REACT_APP_STERIS_TITLE} - User Details`),
    };
    const handleEdit = () => {
        dispatch(setDataMode(DataModes.Edit));
    };

    /**
     * Handles the go back button click.
     * Sets data mode to view, and returns to the grid.
     */
    const handleGoBack = () => {
        dispatch(setHasUnsavedChanges(false)); //clear the changes flag.
        dispatch(setDataMode(DataModes.View)); //reset the to view mode here.
        navigate("/admin/users");
    };

    useEffect(() => {
        document.title = translations.DocumentTitle;
    }, [translations.DocumentTitle]);

    return (
        <Grid
            data-testid="user-view-mode"
            container>
            {/* First Name */}
            <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                xl={2}>
                <Box
                    display="flex"
                    gap="60px"
                    mb={1}
                    mt={2}>
                    <TextArea
                        color="font.gray"
                        label={translations.FirstName}
                        value={selectedUser?.firstName}
                    />
                </Box>
            </Grid>

            {/* Last Name */}
            <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                xl={2}>
                <Box
                    display="flex"
                    gap="60px"
                    mb={1}
                    mt={2}>
                    <TextArea
                        color="font.gray"
                        label={translations.LastName}
                        value={selectedUser?.lastName}
                    />
                </Box>
            </Grid>

            {/* Email */}
            <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}>
                <Box
                    display="flex"
                    gap="60px"
                    mb={1}
                    mt={2}>
                    <TextArea
                        color="font.gray"
                        label={translations.Email}
                        value={selectedUser?.email}
                        valueTextTransform={"lowercase"}
                    />
                </Box>
            </Grid>

            {/* Person Type */}
            <Grid
                item
                xs={12}
                sm={6}
                md={2}
                lg={2}
                xl={2}>
                <Box
                    display="flex"
                    gap="55px"
                    mb={1}
                    mt={2}>
                    <TextArea
                        color="font.gray"
                        label={translations.PersonType}
                        value={selectedUser?.personType}
                    />
                </Box>
            </Grid>

            {/* User Created */}
            <Grid
                item
                xs={12}
                lg={2}
                xl={2}>
                <Box
                    display="flex"
                    gap="50px"
                    mb={1}
                    mt={2}>
                    <TextArea
                        color="font.gray"
                        label={translations.UserCreated}
                        value={FormatDate(selectedUser?.userCreated)}
                    />
                </Box>
            </Grid>

            {/* User Roles */}
            <Grid
                item
                xs={12}
                md={12}
                lg={4}
                xl={4}>
                <Box
                    display="flex"
                    gap="60px"
                    mb={1}
                    mt={2}
                    mr={2}>
                    <FormControl fullWidth={true}>
                        <Typography variant={"detailLabel"}>{translations.UserRoles}</Typography>
                        {selectedUser?.roles?.map((role) => {
                            return (
                                <FormControlLabel
                                    key={role}
                                    control={
                                        <Checkbox
                                            value={role}
                                            checked={true}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant={"detailLabel"}
                                            color={"font.dark"}
                                            fontWeight={"regular"}
                                            textTransform={"capitalize"}>
                                            {role}
                                        </Typography>
                                    }
                                    disabled={true}
                                />
                            );
                        })}
                    </FormControl>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}>
                <Grid
                    container
                    columnGap={2}
                    justifyContent="flex-end">
                    <Button
                        data-testid="goback-button"
                        variant="Cancel"
                        onClick={handleGoBack}>
                        {translations.goback}
                    </Button>
                    <Button
                        data-testid="edit-btn"
                        aria-label={translations.Edit}
                        disabled={currentUser?.personId === selectedUser?.personId}
                        variant="contained"
                        color="info"
                        onClick={handleEdit}>
                        {translations.Edit}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AdminUserDetails;
