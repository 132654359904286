import TableauViz from "../../components/tableau/TableauViz";
import { TableauConstants, TableauSettings } from "../../common/TableauConstants";

function ObservedDamage() {
    const { relativeUrl, friendlyReportName } = TableauSettings.observedDamage;
    const url = `${TableauConstants.uriPrefix}${relativeUrl}`;

    return (
        <TableauViz
            url={url}
            relativeUrl={relativeUrl}
            reportName={friendlyReportName}
        />
    );
}

export default ObservedDamage;
