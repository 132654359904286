import { Box, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ISwitchProps {
    checked: boolean;
    handleOnChange: (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => any;
    label: string;
    labelColor: any;
    disabled: boolean;
}

export default function SwitchWithLabel(props: Readonly<ISwitchProps>) {
    const { checked, handleOnChange, label, labelColor, disabled } = props;

    return (
        <>
            <Switch
                checked={checked}
                onChange={handleOnChange}
                color="success"
                disabled={disabled}
            />
            <Typography
                textTransform={'uppercase'}
                color={labelColor}
                sx={{ fontWeight: 'bold' }}
            >
                {label}
            </Typography>
        </>
    );
}

interface SettingSwitchWithLabelProps {
    checked: boolean;
    handleChangeSetting: (event: { target: { checked: boolean } }, label: string) => void;
    label: string;
    category: string;
    editMode: boolean;
    disabled: boolean;
}
export function SettingSwitchWithLabel(props: Readonly<SettingSwitchWithLabelProps>) {
    const { t } = useTranslation();
    const { checked, handleChangeSetting, label, category, editMode, disabled } = props;
    const translations = {
        hide: t("Hide"),
        show: t("Show"),
    };
    return (
        <Box
            display={"flex"}
            alignItems={"center"}>
            {editMode && (
                <>
                    <Typography width={50} color="font.gray" variant="body2">{!checked && translations.hide}</Typography>
                    <Switch
                        checked={checked}
                        onChange={(e) => handleChangeSetting(e, category)}
                        disabled={disabled}
                    />
                    <Typography width={50} color="font.gray" variant="body2">{checked && translations.show}</Typography>
                </>
            )}
            {!editMode && (
                <Typography
                    variant="body2"
                    color="font.gray"
                    width={50}>
                    {checked ? "Show" : "Hide"}
                </Typography>
            )}
            <Typography variant="h6">{label}</Typography>
        </Box>
    );
}
