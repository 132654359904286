import { useCallback, useEffect, useState } from "react";
import { MaintenanceRecords, defaultMaintenanceRecordState } from "../../models/serviceDelivery/MaintenanceRecords";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareCustomers } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { blue, text, widget } from "../../theme/colors";
import {
    Box,
    Divider,
    Typography,
    Button,
    Stack,
    Grid,
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BarChart, AxisConfig } from "@mui/x-charts";
import ErrorMessage from "./ErrorMessage";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

export default function TrayMaintenance() {
    const { t, i18n } = useTranslation();
    const { get } = useFetch();
    const navigate = useNavigate();
    const [renderChart, setRenderChart] = useState<boolean>(true);
    const [trayMaintenanceRecord, setTrayMaintenanceRecord] =
        useState<MaintenanceRecords>(defaultMaintenanceRecordState);
    const [totalCount, setTotalCount] = useState<string>();
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);

    const translations = {
        ChartTitle: t("Tray Maintenance Status"),
        TrayMaintenance: t("Tray Maintenance"),
        UpToDate: t("Up to Date"),
        DueForService: t("Due for Service"),
        OverdueForService: t("Overdue for Service"),
        ErrorMessage: t("Data is unavailable or there is not enough data to display this metric."),
        TrayWithNoPreviousService: t("Trays with no previous service record"),
        view: t("View"),
    };

    /**
     * Populates the bar chart data state variable, and renders our chart with api data.
     * @param {TrayMaintenanceRecords} trayMaintenanceRecord - Api response data.
     */
    const populateBarChartData = useCallback(
        (trayMaintenanceRecord: MaintenanceRecords) => {
            setTrayMaintenanceRecord(trayMaintenanceRecord);
            setTotalCount(
                Intl.NumberFormat(i18n.language).format(
                    trayMaintenanceRecord.upToDateCount +
                        trayMaintenanceRecord.dueForServiceCount +
                        trayMaintenanceRecord.overDueForServiceCount
                )
            );
            setRenderChart(true);
        },
        [i18n.language]
    );

    /**
     * Calls tray records api to get chart data.
     */
    const getTrayMaintenanceRecords = useCallback(async () => {
        const response = await get<MaintenanceRecords>(requestConnectCareCustomers.TrayMaintenance, true);
        if (response) {
            populateBarChartData(response);
        }
    }, [get, populateBarChartData]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getTrayMaintenanceRecords(); //Get the data from the api.
            setInitialDataLoaded(true); //Once the widget renders rely on selectedFacilitiesChanged to update our charts.
        }
    }, [getTrayMaintenanceRecords, initialDataLoaded]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities]);

    const getSeries = () => [
        {
            label: translations.UpToDate,
            data: [trayMaintenanceRecord.upToDateCount],
            color: widget.green,
        },
        {
            label: translations.DueForService,
            data: [trayMaintenanceRecord.dueForServiceCount],
            color: widget.yellow,
        },
        {
            label: translations.OverdueForService,
            data: [trayMaintenanceRecord.overDueForServiceCount],
            color: widget.red,
        },
    ];
    /**
     * displayGraph function renders based on neverServicedCount property if the value is 0 then error message dipslays else graph is rendered
     */
    const displayGraph = () => {
        if (trayMaintenanceRecord.neverServicedCount > 0) {
            return (
                <>
                    <CardContent>
                        <Stack direction="row">
                            <BarChart
                                height={300}
                                margin={{
                                    top: 0,
                                    bottom: 90,
                                }}
                                series={getSeries()}
                                xAxis={[
                                    {
                                        scaleType: "band",
                                        data: [`${translations.TrayMaintenance} (${totalCount})`],
                                        categoryGapRatio: 0,
                                    } as AxisConfig<"band">,
                                ]}
                                slotProps={{
                                    legend: {
                                        direction: "column",
                                        position: { vertical: "bottom", horizontal: "middle" },
                                        padding: 0,
                                        itemMarkWidth: 45,
                                        itemMarkHeight: 12,
                                        markGap: 5,
                                        labelStyle: {
                                            fontSize: 14,
                                            marginTop: 10,
                                        },
                                    },
                                }}
                                leftAxis={null}
                                bottomAxis={null}
                            />
                        </Stack>
                        <Typography
                            variant="body2"
                            color={text.textgray2}
                            my={2}>
                            {`${trayMaintenanceRecord.neverServicedCount} ${translations.TrayWithNoPreviousService}`}
                        </Typography>
                        <Divider />
                    </CardContent>
                    <CardActions
                        sx={{
                            justifyContent: "center",
                            mb: 1,
                        }}>
                        <Button
                            aria-label={t("View")}
                            data-testid="tray-maintenance-view"
                            variant="outlined2"
                            onClick={() => {
                                navigate(`/assets/dueforpm/tray`);
                            }}>
                            {translations.view}
                        </Button>
                    </CardActions>
                </>
            );
        } else {
            return (
                <Box mt={20}>
                    <ErrorMessage message={translations.ErrorMessage} />
                </Box>
            );
        }
    };

    return (
        <Grid
            data-testid="tray-maintenance-graph"
            container
            minHeight={"90%"}
            textAlign={"center"}
            border={1}
            color={blue.lightGrayishBlue}
            my={2}>
            {renderChart && (
                <Grid
                    item
                    xs>
                    <Card
                        key="tray-maintenance"
                        sx={{ boxShadow: 0 }}>
                        <CardHeader
                            title={
                                <Typography
                                    variant="detailLabel"
                                    color={widget.blackishblue}
                                    mt={"8px"}>
                                    {translations.ChartTitle}
                                </Typography>
                            }
                        />
                    </Card>
                    {displayGraph()}
                </Grid>
            )}
        </Grid>
    );
}
