import { Box, Grid, LinearProgress, Link } from "@mui/material";
import {
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridRowModel,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatCurrency, FormatDate } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { ServiceRequestCell } from "../../component-library/ServiceRequestCell";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { requestConnectCarePurchaseOrdersRequired } from "../../services/apiPaths";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { CustomFooterTotal } from "./CustomFooterTotal";
import { validators } from "../../utils/validators";
import { UpsertPurchaseOrderNumberRequestDto } from "../../models/orders/UpsertPurchaseOrderNumberRequestDto";
import { PoUpdateLevel } from "../../models/orders/PoUpdateLevel";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { customSortComparators } from "../../utils/customSortComparators";
import { useFetch } from "../../services/useFetch";
import { StoreState } from "../../redux/store";
import { setGridColumns, setGridFilter, setGridSort } from "../../redux/reducers/orders/purchaseOrdersSlice";

type FooterTotal = number;
declare module "@mui/x-data-grid-pro" {
    interface FooterPropsOverrides {
        total: FooterTotal;
    }
}

/**
 * This grid is unique. It has an additional feature where you can update a purchase order via the grid.
 * If an update happens and additional messaging system via MUI snackbar (aka toasts).
 */
export default function PurchaseOrdersRequiredGrid() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { gridColumns, data, isLoading, initialGridState } = useSelector(
        (state: StoreState) => state.purchaseOrders
    );
    const apiRef = useGridApiRef();
    const [total, setTotal] = useState<FooterTotal>(0);
    const [showTotal, setShowTotal] = useState<boolean>(true);
    const [rows, setRows] = useState<GridValidRowModel[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isPoDisabled, setIsPoDisabled] = useState<boolean>(false);
    const [filteredProperties, setFilteredProperties] = useState<Set<string>>();
    const dispatch = useDispatch();
    const { post } = useFetch();
    const translations = {
        id: t("Id"),
        srn: t("SRN"),
        customer: t("Customer"),
        department: t("Department"),
        order: t("Order"),
        purchaseOrder: t("PO"),
        reference: t("Reference"),
        ordered: t("Ordered"),
        completed: t("Completed"),
        amount: t("Amount"),
        total: t("Total"),
        enterPO: t("Add PO (Double Click)"),
        enterValidPO: t("Enter a valid PO number."),
        poError: t("PO number cannot exceed 50 characters or contain special characters such as &, #, and +."),
        poFailure: t("Purchase Order saving failed."),
        poSuccess: t("Purchase Order successfully saved."),
    };

    /**
     * Updates a purchase order.
     */
    const savePurchaseOrder = useCallback(
        async (data: GridRowModel): Promise<boolean> => {
            if (!data.purchaseOrderNumber?.trim()) {
                dispatch(
                    setToast({
                        toastMessage: translations.enterValidPO,
                        toastType: ToastTypes.Error,
                    })
                );
                return false;
            }

            const validationResult = validators.pONumber(data.purchaseOrderNumber);

            if (!validationResult) {
                dispatch(
                    setToast({
                        toastMessage: translations.poError,
                        toastType: ToastTypes.Error,
                    })
                );
                return false;
            }

            setIsSaving(true);
            const requestData: UpsertPurchaseOrderNumberRequestDto = {
                OrderHeaderId: data.id,
                UpdateLevel: PoUpdateLevel.HEADER,
                PurchaseOrderNumber: data.purchaseOrderNumber.trim(),
                ShouldOverwriteNonMatchingHeader: true,
            };

            const uriOrder = `${requestConnectCarePurchaseOrdersRequired.UpsertPurchaseOrderNumber}`;
            //The response for this is purely a http status code, take note of this for mocking fetch calls.
            const resp = await post<UpsertPurchaseOrderNumberRequestDto>(uriOrder, requestData, true, () => {
                dispatch(
                    setToast({
                        toastMessage: translations.poFailure,
                        toastType: ToastTypes.Error,
                    })
                );

                setIsSaving(false);
                return false;
            });

            const response = resp as Response;

            if (response.ok) {
                setIsSaving(false);

                dispatch(
                    setToast({
                        toastMessage: translations.poSuccess,
                        toastType: ToastTypes.Success,
                    })
                );
                return true;
            }
            return false;
        },
        [
            dispatch,
            post,
            translations.enterValidPO,
            translations.poError,
            translations.poFailure,
            translations.poSuccess,
        ]
    );

    /**
     * Handles a grid cell double click to edit the PO. You must have claim 5.3.2 EnterOrEditPo.   
     */
    const handleDoubleClick = (param: GridCellParams, event: any) => {
        if (!isPoDisabled) {
            event.defaultMuiPrevented = true;

            dispatch(
                setToast({
                    showToast: false,
                })
            );

            if (
                apiRef.current.getCellMode(param.row.id, param.field) === "view" &&
                AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
            ) {
                apiRef.current.startCellEditMode({ id: param.row.id, field: param.field });
            }
        }
    };

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "orderNumber",
                headerName: translations.order,
                renderHeader: () => translations.order,
                minWidth: 100,
                renderCell: (params) => (
                    <Link
                        onClick={() => {
                            navigate(`/orders/${params.row.id}`);
                        }}>
                        {params.row.orderNumber}
                    </Link>
                ),
                flex: 1,
            },
            {
                field: "srn",
                headerName: translations.srn,
                renderHeader: () => translations.srn,
                minWidth: 100,
                renderCell: (params: GridRenderCellParams) => <ServiceRequestCell value={params.value} />,
                flex: 1,
            },
            {
                field: "purchaseOrderNumber",
                headerName: translations.purchaseOrder,
                renderHeader: () => translations.purchaseOrder,
                minWidth: 120,
                sortable: true,
                type: "string",
                headerAlign: "left",
                editable: AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && !isPoDisabled,
                renderCell: (params) =>
                    !params.row.isIndirectFiltered &&
                    AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && (
                        <Box
                            component="span"
                            sx={{
                                color:
                                    !params.row.purchaseOrderNumber &&
                                    AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
                                        ? "red"
                                        : "inherit",
                            }}>
                            {params.row.purchaseOrderNumber || AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
                                ? params.row.purchaseOrderNumber
                                : translations.enterPO}
                        </Box>
                    ),
                flex: 1,
            },
            {
                field: "reference",
                headerName: translations.reference,
                renderHeader: () => translations.reference,
                minWidth: 150,
                sortable: true,
                type: "string",
                headerAlign: "left",
                flex: 1,
            },
            {
                field: "customer",
                headerName: translations.customer,
                renderHeader: () => translations.customer,
                minWidth: 150,
                sortable: true,
                type: "string",
                headerAlign: "left",
                flex: 1,
            },
            {
                field: "departmentDescription",
                headerName: translations.department,
                renderHeader: () => translations.department,
                minWidth: 150,
                sortable: true,
                type: "string",
                headerAlign: "left",
                flex: 1,
            },
            {
                field: "orderDate",
                headerName: translations.ordered,
                renderHeader: () => translations.ordered,
                minWidth: 100,
                sortable: true,
                type: "date",
                headerAlign: "left",
                align: "right",
                valueFormatter: ({ value }) => FormatDate(value),
                sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
                flex: 1,
            },
            {
                field: "completedDate",
                headerName: translations.completed,
                renderHeader: () => translations.completed,
                minWidth: 100,
                sortable: true,
                type: "date",
                headerAlign: "left",
                align: "right",
                valueFormatter: ({ value }) => FormatDate(value),
                sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
                flex: 1,
            },
            {
                field: "amount",
                headerName: translations.amount,
                renderHeader: () => translations.amount,
                minWidth: 120,
                sortable: true,
                type: "number",
                headerAlign: "left",
                align: "right",
                flex: 1,
                renderCell: (params: GridRenderCellParams) => FormatCurrency(params.row?.amount),
                valueGetter: ({ row }) => {
                    return row.amount;
                },
                sortComparator: (v1, v2) => customSortComparators.sortByAlphanumerics(v1, v2),
            },
        ],
        [
            navigate,
            isPoDisabled,
            translations.amount,
            translations.completed,
            translations.customer,
            translations.department,
            translations.enterPO,
            translations.order,
            translations.ordered,
            translations.purchaseOrder,
            translations.reference,
            translations.srn,
        ]
    );

    /**
     * If PO is edited inline, update the record, and update row state. This is quicker than making an api call.
     * The updated grid state is to remove the editted row from the grid.
     * @param newRow - The row  that has been updated with Purchase order.
     * @param oldRow - The old  row
     * @returns The updated row to save back.
     */
    const processRowUpdate = useCallback(
        (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
            if (newRow.purchaseOrderNumber !== oldRow.purchaseOrderNumber) {
                savePurchaseOrder(newRow).then((result) => {
                    if (result) {
                        const updatedItemsRow = { ...newRow, isNew: false };
                        const gridWithoutPo = rows.filter((x) => x.id !== newRow.id); //Remove the newly saved grid item.
                        setRows(gridWithoutPo); //reset the grid.

                        //do not do this:
                        //const gridState = gridWithoutPo as PurchaseOrdersRequired[]; //dispatch the update to our grid state.
                        //dispatch(dataReceived(gridState)); //we should update state here and not let the component maintain a state out of sync with the db. This will change with RTK.

                        return updatedItemsRow;
                    }
                });
            }
            return oldRow; //if something went wrong, return the old row back.
        },
        [rows, savePurchaseOrder]
    );

    useEffect(() => {
        const propertyNames = new Set<string>([]);
        data.forEach((x) => x.filteredPropertyNames.forEach((y) => propertyNames.add(y.toUpperCase())));
        setFilteredProperties(propertyNames);

        //Hide the footer totals.
        if (propertyNames.has("AMOUNT")) {
            setShowTotal(false);
        }
    }, [columns, data]);

    /**
     * Find the first instance where indirect filtering has been applied. If so, disable po.
     */
    useEffect(() => {
        const disabled = data.some((item) => item.isIndirectFiltered);
        setIsPoDisabled(disabled);
    }, [data]);

    /**
     * Set the state of the grid on first load.
     * This is a little awkward because of how the po save works and the api call is setting data in the parent component. 
     * TODO: review this when RTK happens.
     */
    useEffect(() => {        
        setRows(data);
    }, [data]);

    /**
     * Returns a list of togglable columns.
     * If our api data contains filtered property names, then hide those columns.
     * @param columns The grid columns model.
     * @returns {string[]} of the column names you wish to display.
     */
    const getTogglableColumns = (columns: GridColDef[]): string[] => {
        if (filteredProperties) {
            const newColumns = columns
                .filter((column) => !filteredProperties.has(column.field.toUpperCase()))
                .map((column) => column.field);
            return newColumns;
        }
        return columns.map((column) => column.field);
    };

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="porequired">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={initialGridState}
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        columnHeaderHeight={70}
                        processRowUpdate={processRowUpdate}
                        onCellDoubleClick={handleDoubleClick}
                        editMode="cell"
                        density="compact"
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.poRequired),
                            loadingOverlay: LinearProgress,
                            footer: showTotal ? CustomFooterTotal : undefined,
                            filterPanel: StyledFilterPanel,
                        }}
                        slotProps={{
                            columnsPanel: {
                                getTogglableColumns,
                            },
                            footer: showTotal ? { total } : undefined,
                        }}
                        loading={isLoading || isSaving}
                        columnBuffer={10}
                        onStateChange={() => {
                            const res = rows.filter((item) => item.amount);
                            const sum = res.map((item) => item.amount).reduce((a, b) => a + b, 0);
                            setTotal(sum);
                        }}
                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                        columnVisibilityModel={gridColumns}
                        onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
