import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    Alert,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { TaskAlt } from "@mui/icons-material";
import { requestConnectCareUsers } from "../services/apiPaths";
import { useTranslation } from "react-i18next";
import { ApiErrorCodes } from "../config/ApiErrorCodes";
import getQueryStringParam from "../utils/getQueryStringParam";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { theme } from "../theme/theme";
import { formWidget, text } from "../theme/colors";
import { LoadingButton } from "../component-library/LoadingButton";

export interface INewUserProps {
    openSignUpForm: boolean;
    setOpenSignUpForm: Dispatch<SetStateAction<boolean>>;
}

function FormNewUser({ openSignUpForm, setOpenSignUpForm }: Readonly<INewUserProps>) {
    const { t } = useTranslation();
    const translations = {
        labelFirstName: t("First Name"),
        requiredFirstName: t("First Name is required!"),
        labelLastName: t("Last Name"),
        requiredLastName: t("Last Name is required!"),
        labelEmail: t("Email"),
        requiredEmail: t("Email is required!"),
        requiredMessageEmail: t("Please enter a valid email address."),
        labelEmailConfirm: t("Confirm Email"),
        requiredEmailConfirm: t("Confirmation Email is required!"),
        validateMessageEmailConfirm: t("The email addresses provided do not match. Please try again."),
        labelPrimaryFacilityName: t("Primary Facility Name"),
        requiredPrimaryFacilityName: t("Primary Facility Name is required!"),
        labelPrimaryFacilityStreetName: t("Primary Facility Street Address"),
        requiredPrimaryFacilityStreetName: t("Primary Facility Street Address is required!"),
        labelPrimaryFacilityCity: t("Primary Facility City"),
        requiredPrimaryFacilityCity: t("Primary Facility City is required!"),
        labelPrimaryFacilityState: t("Primary Facility State"),
        requiredPrimaryFacilityState: t("Primary Facility State is required!"),
        labelPrimaryFacilityZip: t("Primary Facility Postal Code"),
        requiredPrimaryFacilityZip: t("Primary Facility Postal Code is required!"),
        labelImsNumber: t("Customer Number"),
        requiredIMSNumber: t("Customer number is required!"),
        labelNotes: t("Notes"),
        newUserRequest: t("New User Request"),
        newUserSubmitError: t("There was an error submitting the new user request."),
        missingUserMsg: t(
            "Please complete this form for access to STERIS Instrument Management Services in ConnectCare, or call Customer Care at 1-800-783-9251."
        ),
        newUser: t("New User"),
        newUserPrimaryFacility: t("New User's Primary Facility"),
        optionalNotes: t("Optional Notes"),
    };
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isMissingUserMsg, setIsMissingUserMsg] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [reCaptchaToken, setReCaptchaToken] = useState("");
    const SITE_KEY = `${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;

    useEffect(() => {
        const errorCode = getQueryStringParam("errorCode");
        errorCode?.length && errorCode === ApiErrorCodes.MissingIdentityUser.toString()
            ? setIsMissingUserMsg(true)
            : setIsMissingUserMsg(false);
    }, [isMissingUserMsg]);

    type Inputs = {
        firstname: string;
        lastname: string;
        email: string;
        emailconfirm: string;
        password: string;
        primaryfacilityname: string;
        primaryfacilitystreetname: string;
        primaryfacilitycity: string;
        primaryfacilitystate: string;
        primaryfacilityzip: string;
        imsnumber: string;
        notes: string;
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm<Inputs>({
        defaultValues: {
            firstname: "",
            lastname: "",
            email: "",
            emailconfirm: "",
            primaryfacilityname: "",
            primaryfacilitystreetname: "",
            primaryfacilitycity: "",
            primaryfacilitystate: "",
            primaryfacilityzip: "",
            imsnumber: "",
            notes: "",
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async (formData) => {
        const formDataBody = {
            firstName: formData.firstname,
            lastName: formData.lastname,
            email: formData.email,
            primaryFacilityName: formData.primaryfacilityname,
            primaryFacilityAddress: {
                streetAddress: formData.primaryfacilitystreetname,
                addressLocality: formData.primaryfacilitycity,
                addressRegion: formData.primaryfacilitystate,
                postalCode: formData.primaryfacilityzip,
            },
            imsNumber: formData.imsnumber,
            notes: formData.notes,
        };

        setIsError(false);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                googleRecaptchaToken: reCaptchaToken,
            },
            body: JSON.stringify(formDataBody),
        };

        await fetch(requestConnectCareUsers.NewUserRequest, requestOptions).then((response) => {
            if (response.ok) {
                setIsSubmitted(!isSubmitted);
            } else {
                setErrorMsg(translations.newUserSubmitError);
                setIsError(true);
            }
        });
    };

    const handleOpenForm = () => {
        setOpenSignUpForm(true);
    };

    const handleCloseForm = () => {
        setOpenSignUpForm(false);
    };

    const handleClick = () => {
        setIsLoading(true);
        handleSubmit(onSubmit)();
        if (!isValid) {
            setIsLoading(false);
        }
    };

    return (
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
            <GoogleReCaptcha
                onVerify={(token: string) => {
                    setReCaptchaToken(token);
                    if (token !== null) {
                        setIsButtonDisabled(false);
                    }
                }}
            />
            <Dialog
                id="sign-up-modal"
                data-testid="sign-up-modal"
                aria-labelledby="sign-up-modal"
                open={openSignUpForm}
                fullWidth={true}>
                <DialogTitle
                    id="sign-up-modal-title"
                    textTransform={"uppercase"}
                    textAlign={"center"}
                    color={"font.darkBlue"}
                    fontSize={20}>
                    {translations.newUserRequest}
                </DialogTitle>
                <DialogContent id="sign-up-modal-content">
                    {!isSubmitted && (
                        <Box
                            data-testid="form-new-user"
                            paddingX={2}>
                            {isMissingUserMsg && (
                                <Typography
                                    align="center"
                                    variant="errorMessageBold">
                                    {translations.missingUserMsg}
                                </Typography>
                            )}
                            <form
                                data-testid="signup-form"
                                onSubmit={handleSubmit(onSubmit)}
                                //noValidate
                                autoComplete="off">
                                <Stack spacing={2}>
                                    {/* ** New User Section  ** */}
                                    <Box>
                                        <Typography
                                            pl={1}
                                            fontWeight={700}
                                            color={text.textdarkgray}>
                                            {translations.newUser}
                                        </Typography>
                                        <Grid container>
                                            {/* ** firstname  ** */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={6}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: "#3E8EDE40" }}
                                                    {...register("firstname", {
                                                        required: translations.requiredFirstName,
                                                    })}
                                                    error={!!errors?.firstname}
                                                    fullWidth
                                                    name="firstname"
                                                    label={translations.labelFirstName}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.firstname?.message}
                                                </Typography>
                                            </Grid>
                                            {/* lastname */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={6}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: "#3E8EDE40" }}
                                                    {...register("lastname", {
                                                        required: translations.requiredLastName,
                                                    })}
                                                    error={!!errors?.lastname}
                                                    fullWidth
                                                    name="lastname"
                                                    label={translations.labelLastName}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.lastname?.message}
                                                </Typography>
                                            </Grid>
                                            {/* ** email ** */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={6}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: "#3E8EDE40" }}
                                                    {...register("email", {
                                                        required: translations.requiredEmail,
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: translations.requiredMessageEmail,
                                                        },
                                                    })}
                                                    error={!!errors.email}
                                                    fullWidth
                                                    name="email"
                                                    label={translations.labelEmail}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.email?.message}
                                                </Typography>
                                            </Grid>

                                            {/* ** emailconfirm ** */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={6}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: "#3E8EDE40" }}
                                                    {...register("emailconfirm", {
                                                        required: translations.requiredEmailConfirm,
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: translations.requiredMessageEmail,
                                                        },
                                                        validate: (val: string) => {
                                                            if (watch("email") !== val) {
                                                                return translations.validateMessageEmailConfirm;
                                                            }
                                                        },
                                                    })}
                                                    error={!!errors.emailconfirm}
                                                    fullWidth
                                                    name="emailconfirm"
                                                    label={translations.labelEmailConfirm}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.emailconfirm?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* ** New User's Primary Facility Section  ** */}
                                    <Box>
                                        <Typography
                                            pl={1}
                                            fontWeight={700}
                                            color={text.textdarkgray}>
                                            {translations.newUserPrimaryFacility}
                                        </Typography>
                                        <Grid container>
                                            {/* PRIMARY FACILITY NAME */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={12}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: "#3E8EDE40" }}
                                                    {...register("primaryfacilityname", {
                                                        required: translations.requiredPrimaryFacilityName,
                                                    })}
                                                    error={!!errors.primaryfacilityname}
                                                    fullWidth
                                                    name="primaryfacilityname"
                                                    label={translations.labelPrimaryFacilityName}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.primaryfacilityname?.message}
                                                </Typography>
                                            </Grid>

                                            {/* PRIMARY FACILITY STREET NAME */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={12}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: "#3E8EDE40" }}
                                                    {...register("primaryfacilitystreetname", {
                                                        required: translations.requiredPrimaryFacilityStreetName,
                                                    })}
                                                    error={!!errors.primaryfacilitystreetname}
                                                    fullWidth
                                                    name="primaryfacilitystreetname"
                                                    label={translations.labelPrimaryFacilityStreetName}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.primaryfacilitystreetname?.message}
                                                </Typography>
                                            </Grid>

                                            {/* PRIMARY FACILITY CITY */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={6}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: "#3E8EDE40" }}
                                                    {...register("primaryfacilitycity", {
                                                        required: translations.requiredPrimaryFacilityCity,
                                                    })}
                                                    error={!!errors.primaryfacilitycity}
                                                    fullWidth
                                                    name="primaryfacilitycity"
                                                    label={translations.labelPrimaryFacilityCity}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.primaryfacilitycity?.message}
                                                </Typography>
                                            </Grid>

                                            {/* PRIMARY FACILITY State */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={6}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: formWidget.blue }}
                                                    {...register("primaryfacilitystate", {
                                                        required: translations.requiredPrimaryFacilityState,
                                                    })}
                                                    error={!!errors.primaryfacilitystate}
                                                    fullWidth
                                                    name="primaryfacilitystate"
                                                    label={translations.labelPrimaryFacilityState}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.primaryfacilitystate?.message}
                                                </Typography>
                                            </Grid>

                                            {/* *** PRIMARY FACILITY ZIP *** */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={6}>
                                                <TextField
                                                    size="small"
                                                    sx={{ backgroundColor: "#3E8EDE40" }}
                                                    {...register("primaryfacilityzip", {
                                                        required: translations.requiredPrimaryFacilityZip,
                                                    })}
                                                    error={!!errors.primaryfacilityzip}
                                                    fullWidth
                                                    name="primaryfacilityzip"
                                                    label={translations.labelPrimaryFacilityZip}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.primaryfacilityzip?.message}
                                                </Typography>
                                            </Grid>

                                            {/* *** IMS CUSTOMER NUMBER *** */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={6}>
                                                <TextField
                                                    size="small"
                                                    {...register("imsnumber", {
                                                        required: translations.requiredIMSNumber,
                                                    })}
                                                    error={!!errors.imsnumber}
                                                    fullWidth
                                                    name="imsnumber"
                                                    label={translations.labelImsNumber}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.imsnumber?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* ** Optional Notes Section  ** */}
                                    <Box>
                                        <Typography
                                            pl={1}
                                            fontWeight={700}
                                            color={text.textdarkgray}>
                                            {translations.optionalNotes}
                                        </Typography>

                                        <Grid container>
                                            {/* *** OPTIONAL NOTES *** */}
                                            <Grid
                                                item
                                                padding={theme.spacing(1)}
                                                md={12}>
                                                <TextField
                                                    size="small"
                                                    {...register("notes")}
                                                    error={!!errors.notes}
                                                    fullWidth
                                                    name="notes"
                                                    label={translations.labelNotes}
                                                    variant="outlined"
                                                />
                                                <Typography
                                                    variant="inherit"
                                                    color="error"
                                                    sx={{ fontSize: 12 }}>
                                                    {errors.notes?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </form>
                        </Box>
                    )}
                    {isError && (
                        <Alert severity="error">
                            <Typography
                                variant="body2"
                                color="error">
                                {errorMsg}
                            </Typography>
                        </Alert>
                    )}
                    {isSubmitted && !isError && (
                        <Box textAlign="center">
                            <Box sx={{ mt: 3, mb: 2 }}>
                                <TaskAlt sx={{ color: "#4B90CD", fontSize: "80px" }} />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="center">
                                <Box sx={{ width: 300 }}>
                                    <Typography fontSize="sm">
                                        {t(
                                            "Request submitted! Our Customer Care Team is setting up your User account. \n" +
                                                "You will receive an email with a link to login shortly. \n" +
                                                "Have a more urgent need? Call us: 1-800-783-9251"
                                        )}
                                    </Typography>
                                </Box>
                            </Box>

                            <Button
                                aria-label={t("Close")}
                                onClick={handleCloseForm}
                                variant="contained"
                                size="small"
                                sx={{ width: 200, padding: 1, borderRadius: 8, mt: 4, mb: 3 }}>
                                {t("Close")}
                            </Button>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    {!isSubmitted && (
                        <Grid
                            pb={1}
                            container
                            columnGap={2}
                            justifyContent="center">
                            <Grid
                                item
                                mb={1}>
                                <Button
                                    aria-label={t("Cancel")}
                                    sx={{
                                        width: 140,
                                        borderRadius: 8,
                                        backgroundColor: "#CCCCCC",
                                        "&:hover": {
                                            backgroundColor: "#b7b7b7",
                                            color: "white",
                                        },
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        if (isMissingUserMsg) {
                                            const urlParams = new URLSearchParams(window.location.search);
                                            urlParams.delete("errorCode");
                                            const url = window.location.origin + window.location.pathname;
                                            const size = urlParams.toString().length; //There is a new urlParams .size that's not recognized by ts.
                                            window.location.href = size === 0 ? url : `${url}?${urlParams.toString()}`;
                                        }
                                        handleOpenForm();
                                    }}>
                                    {t("Cancel")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    buttonText={t("Submit")}
                                    isButtonDisabled={isButtonDisabled}
                                    loading={isLoading}
                                    handleClick={handleClick}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogActions>
            </Dialog>
        </GoogleReCaptchaProvider>
    );
}

export default FormNewUser;
