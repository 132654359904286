import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import { validators } from "../../utils/validators";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

type EditPoDialogProps = {
    open: boolean;
    onSubmit: (poNumber: string) => void;
    onCancel: () => void;
    progressIndicator?: boolean;
    editPoNumber: string;
};

export const EditPoDialog = (props: EditPoDialogProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [poNumber, setPoNumber] = useState<string>(props.editPoNumber ?? "");
    const [isPoNumberError, setIsPoNumberError] = useState<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const translations = {
        dialogHeader: props.editPoNumber != null ? t("Edit PO number") : t("Enter PO number"),
        history: t("Tracking"),
        poTextFieldLabel: props.editPoNumber != null ? t("Edit PO") : t("Enter PO"),
        poErrorMessage: t("PO number cannot exceed 50 characters or contain special characters such as &, #, and +"),
        cancel: t("Cancel"),
        submit: t("Submit"),
    };

    const internalSubmit = () => {
        setPoNumber(poNumber.trim());
        let validationResult = validators.pONumber(poNumber);
        setIsPoNumberError(!validationResult);

        if (validationResult) {
            props.onSubmit(poNumber);
        } else {
            dispatch(
                setToast({
                    toastMessage: translations.poErrorMessage,
                    toastType: ToastTypes.Error,
                })
            );
        }
    };

    const internalCancel = () => {
        if (translations.dialogHeader === "Enter PO number") {
            setPoNumber("");
        }
        props.onCancel();
    };

    useEffect(() => {
        setPoNumber(props.editPoNumber ?? "");
    }, [props.editPoNumber]);

    return (
        <Dialog
            open={props.open}
            fullScreen={fullScreen}
            fullWidth={true}>
            <DialogTitle>{translations.dialogHeader}</DialogTitle>
            <DialogContent>
                <Grid
                    display="flex"
                    justifyContent="space-between"
                    sx={{ paddingTop: "10px" }}>
                    <Box>
                        <TextField
                            disabled={props.progressIndicator}
                            size="small"
                            variant="outlined"
                            data-testid="enter-po-dialog-textfield"
                            sx={{ bgcolor: "bg.white" }}
                            label={translations.poTextFieldLabel}
                            InputLabelProps={{ shrink: true }}
                            value={poNumber}
                            onChange={(e) => setPoNumber(e.target.value)}
                            error={isPoNumberError}
                        />
                    </Box>
                    <Box>
                        {props.progressIndicator && (
                            <CircularProgress
                                size={30}
                                data-testid="enter-po-dialog-loader"
                            />
                        )}
                    </Box>
                </Grid>
            </DialogContent>
            <DialogActions sx={{marginBottom: isMobile ? 5 : 0}}>
                <Button
                    variant="Cancel"
                    data-testid="enter-po-dialog-cancel"
                    onClick={internalCancel}
                    disabled={props.progressIndicator}>
                    {translations.cancel}
                </Button>
                <Button
                    variant="contained"
                    data-testid="enter-po-dialog-submit"
                    onClick={internalSubmit}
                    disabled={props.progressIndicator}>
                    {translations.submit}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
