import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../../component-library/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import AssetAutocomplete from "../AssetAutocomplete";
import { useFetch } from "../../../services/useFetch";
import { requestConnectCareCustomers, requestConnectCareInventory } from "../../../services/apiPaths";
import { SystemItem } from "../../../models/assets/SystemItem";
import { IOracleDepartment } from "../../../models/serviceRequest/CreateSrnModels";
import { validators } from "../../../utils/validators";
import { AddAssetToInventory } from "../../../models/assets/AddAssetToInventory";
import { AddAssetItem } from "../../../models/assets/AddAssetItem";
import SelectedAsset from "./SelectedAsset";
import SelectedAssetDetails from "./SelectedAssetDetails";
import DetailSection from "./DetailSection";
import { useDispatch, useSelector } from "react-redux";
import { ToastTypes } from "../../../models/toast/ToastTypes";
import { setToast } from "../../../redux/reducers/toastSlice";
import HoverTooltip from "../../../component-library/HoverTooltip";
import { HoverTooltipText } from "../../../component-library/HoverTooltipData";
import { setShowAddAsset } from "../../../redux/reducers/assets/assetsSlice";
import { AuthLibrary } from "../../../redux/actions/AuthRedux";
import { claimTypes } from "../../../config/claimTypes";
import { Facility } from "../../../models/facility/Facility";
import { StoreState } from "../../../redux/store";

const AddAsset = () => {
    const { t } = useTranslation();
    const { postUnique } = useFetch();
    const navigate = useNavigate();
    const translations = {
        addToInventory: t("Add to Asset Inventory"),
        facility: t("Facility"),
        item: t("Item"),
        details: t("Details"),
        selectFacility: t("Select a Facility"),
        selectDepartment: t("Select a Department"),
        serial: t("Serial"),
        requiredError: t("Required"),
        categoryFilter: t("Category Filter"),
        department: t("Department"),
        allCategories: t("All Categories"),
        cancel: t("cancel"),
        save: t("save"),
        cancelAddAsset: t("CANCEL ADD ASSET"),
        yes: t("Yes"),
        no: t("No"),
        cancelAddAssetMessage: t("Are you sure you want to cancel adding this asset?"),
        manufacturer: t("Manufacturer"),
        deviceType: t("Device Type"),
        model: t("Model"),
        saveFailed: t("An error occurred. The asset was not saved."),
    };
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
    const [assetDepartments, setAssetDepartments] = useState<IOracleDepartment[]>([]);
    const [isSaveAttempted, setIsSaveAttempted] = useState<boolean>(false);
    const { get } = useFetch();
    const dispatch = useDispatch();

    const initialAsset: AddAssetItem = {
        customerAccountId: (selectedFacilities.length === 1 ? selectedFacilities[0]?.custAccountId : 0) ?? 0,
        serial: "",
        categoryId: 0,
        departmentId: 0,
    };
    const [newAsset, setNewAsset] = useState<AddAssetItem>(initialAsset);
    const [isLoading, setIsLoading] = useState(false);

    const populateDepartments = useCallback(
        async (customerAccountId: number) => {
            const res = await get<IOracleDepartment[]>(
                `${requestConnectCareCustomers.OracleDepartments}/${customerAccountId}`
            );
            if (res) {
                setAssetDepartments(res);
            }
        },
        [get]
    );

    const resetSelectedItem = () => {
        setNewAsset((prev) => {
            return {
                ...prev,
                selectedItem: undefined,
            };
        });
    };

    useEffect(() => {
        resetSelectedItem();
    }, [newAsset.categoryId]);

    useEffect(() => {
        if (newAsset.customerAccountId !== 0) {
            populateDepartments(newAsset.customerAccountId).catch((e) => console.error(e));
        }
        if (!selectedFacilities.some((x: Facility) => x.custAccountId === newAsset.customerAccountId)) {
            setNewAsset((prev) => ({
                ...prev,
                customerAccountId: 0,
            }));
        }
        if (selectedFacilities.length === 1) {
            setNewAsset((prev) => ({
                ...prev,
                customerAccountId: selectedFacilities[0]?.custAccountId ?? 0,
            }));
        }
    }, [newAsset.customerAccountId, populateDepartments, selectedFacilities]);

    useEffect(() => {
        setNewAsset((prev) => ({
            ...prev,
            departmentId: 0,
        }));
    }, [newAsset.customerAccountId]);

    const handleCancel = () => {
        dispatch(setShowAddAsset(false));
        navigate("/assets");
    };

    const handleSaveClick = async () => {
        setIsLoading(true);
        setIsSaveAttempted(true);
        const uri = `${requestConnectCareInventory.AddItemToInventory}`;
        if (!validateInputs()) {
            return;
        }
        const body: AddAssetToInventory = {
            inventoryItemId: newAsset.selectedItem?.inventoryItemId ?? 0,
            custAccountId: newAsset.customerAccountId,
            serial: newAsset.serial,
            departmentId: newAsset.departmentId,
        };
        const res = await postUnique<AddAssetToInventory, void>(uri, body, true, handleSaveError);
        if ((res as Response)?.ok && newAsset.selectedItem) {
            setIsSaveAttempted(false);
            navigate(
                `/assets/${newAsset.customerAccountId}/${newAsset.selectedItem.inventoryItemId}/${newAsset.serial}?tab=${"Details"}`
            );
            dispatch(setShowAddAsset(false));
        }
        setIsLoading(false);
    };

    const handleSaveError = () => {
        dispatch(
            setToast({
                showToast: true,
                toastType: ToastTypes.Error,
                toastMessage: translations.saveFailed,
            })
        );
    };

    const validateInputs = (): boolean => {
        return validators.addAssetToInventory(newAsset);
    };

    return (
        <Dialog
            open={true}
            fullWidth={true}>
            <Box
                data-testid="add-asset"
                p={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid
                            container
                            justifyContent="flex-end"
                            p={2}>
                            <Grid
                                item
                                xs={12}
                                pr={2}>
                                <Typography
                                    variant="h1"
                                    color="blue.darker"
                                    textTransform="uppercase">
                                    {translations.addToInventory}
                                    <HoverTooltip
                                        tooltip={HoverTooltipText.AddToAssetInventory}
                                        placement="right"
                                    />
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                pt={2}
                                pr={1}>
                                <Grid
                                    item
                                    xs={12}
                                    mb={2}>
                                    {!newAsset.selectedItem && (
                                        <AssetAutocomplete
                                            categoryCode={newAsset.categoryId}
                                            error={isSaveAttempted && !newAsset.selectedItem}
                                            data-testid="asset-typeahead"
                                            onSelectItem={(x: SystemItem) =>
                                                setNewAsset((prev) => (x ? { ...prev, selectedItem: x } : initialAsset))
                                            }
                                        />
                                    )}
                                    {newAsset.selectedItem && (
                                        <SelectedAsset
                                            item={newAsset.selectedItem}
                                            resetSelectedItem={resetSelectedItem}
                                            data-testid="selected-asset"
                                        />
                                    )}
                                    {isSaveAttempted && !newAsset.selectedItem && (
                                        <Typography
                                            data-testid="asset-typeahead-error"
                                            variant="errorMessage">
                                            {translations.requiredError}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    mb={2}>
                                    <DetailSection
                                        newAsset={newAsset}
                                        onSerialUpdated={(serial) =>
                                            setNewAsset((prev) => ({
                                                ...prev,
                                                serial: serial,
                                            }))
                                        }
                                        isSaveAttempted={isSaveAttempted}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    mb={2}>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        disabled={selectedFacilities.length === 1}
                                        error={isSaveAttempted && newAsset.customerAccountId === 0}>
                                        <InputLabel id="facility-label">{translations.facility}</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="facility-label"
                                            inputProps={{ "data-testid": "facility-dropdown" }}
                                            value={newAsset.customerAccountId}
                                            label={translations.facility}
                                            onChange={(e) =>
                                                setNewAsset((prev) => ({
                                                    ...prev,
                                                    customerAccountId: +e.target.value,
                                                }))
                                            }>
                                            <MenuItem value={0}>{translations.selectFacility}</MenuItem>
                                            {selectedFacilities.map((fac: Facility) => (
                                                <MenuItem
                                                    key={fac.custAccountId}
                                                    value={fac.custAccountId}>
                                                    {fac.displayName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    mb={2}>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        disabled={newAsset.customerAccountId === 0}
                                        error={isSaveAttempted && newAsset.departmentId === 0}>
                                        <InputLabel id="department-label">{translations.department}</InputLabel>
                                        <Select
                                            labelId="department-label"
                                            value={newAsset.departmentId}
                                            label={translations.department}
                                            inputProps={{ "data-testid": "department-dropdown" }}
                                            onChange={(e) =>
                                                setNewAsset((prev) => ({
                                                    ...prev,
                                                    departmentId: +e.target.value,
                                                }))
                                            }>
                                            <MenuItem value={0}>{translations.selectDepartment}</MenuItem>
                                            {assetDepartments.map((dep) => (
                                                <MenuItem
                                                    key={dep.custDeptId}
                                                    value={dep.custDeptId}>
                                                    {dep.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                py={2}
                                pl={2}>
                                {newAsset.selectedItem && (
                                    <Grid
                                        item
                                        xs={12}
                                        mt={5}>
                                        <SelectedAssetDetails item={newAsset.selectedItem} />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                columnSpacing={1}
                                justifyContent="flex-end"
                                mt={4}
                                xs={12}>
                                <Grid item>
                                    <Button
                                        aria-label={translations.cancel}
                                        data-testid="cancel-button"
                                        variant="Cancel"
                                        onClick={() => setShowCancelDialog(true)}>
                                        {translations.cancel}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        aria-label={translations.save}
                                        data-testid="save-button"
                                        variant="contained"
                                        disabled={!validateInputs() || isLoading || !AuthLibrary.hasAnyClaim([claimTypes.CreateAsset])}
                                        onClick={handleSaveClick}>
                                        {isLoading ? <CircularProgress size={20} /> : translations.save}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            {showCancelDialog && (
                <ConfirmationModal
                    data-testid="confirmationModal"
                    show={showCancelDialog}
                    onSecondaryButtonClick={() => setShowCancelDialog(false)}
                    onPrimaryButtonClick={handleCancel}
                    title={translations.cancelAddAsset}
                    contentMessage={translations.cancelAddAssetMessage}
                    secondaryButtonText={translations.no}
                    primaryButtonText={translations.yes}
                />
            )}
        </Dialog>
    );
};
export default AddAsset;
