import { Grid } from "@mui/material";
import EducationEventsByStatus from "./EducationEventsByStatus";
import EducationAllCEEvents from "./EducationAllCEEvents";

export default function EducationInsights({ fromDate, toDate }: { readonly fromDate: string; readonly toDate: string }) {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            data-testid="education-insights">
            <EducationEventsByStatus fromDate={fromDate} toDate={toDate} />
            <EducationAllCEEvents fromDate={fromDate} toDate={toDate} isCompletedCEEvents={true } />
            <EducationAllCEEvents fromDate={fromDate} toDate={toDate} isCompletedCEEvents={false} />
        </Grid>
    );
}
