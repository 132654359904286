import { Box, ClickAwayListener, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppMenuItem } from '../../models/navigation/AppMenuItemResponse';
import CloseIcon from "@mui/icons-material/Close";
import { AppMenuListData } from "../../config/menus";


/**
 * Props for the DynamicSubMenu component
 */
export interface DynamicSubMenuProps {
    /** Title of submenu - used for keys & ids */
    submenuTitle?: number;
    /** List of AppMenuItems to be displayed in the submenu */
    childMenuItems: AppMenuItem[];
    /** Function to close submenu */
    closeMenu: () => void;
    /** Flag indicating whether submenu was opened by side nav click */
    isSideNavClick: boolean;
}


/** A type representing a grouped set of menu items, keyed by their group name. */
type GroupedMenuItems = {
    [key: string]: AppMenuItem[];
};


/**
 * @summary Component to render submenu items based on the provided menu items.
 * @param props - topLabel, childMenuItems, closeMenu, isSideNavClick
 * @returns DynamicSubMenu component
 */
export const DynamicSubMenu = (props: DynamicSubMenuProps) => {
    const location = useLocation();
    const { t } = useTranslation();

    // group the child menu items by their group name
    const groupedMenuItems: GroupedMenuItems = props.childMenuItems.reduce((groups: GroupedMenuItems, item: AppMenuItem) => {
        const groupName = item.groupDetails.name;
        if (!groups[groupName]) {
            groups[groupName] = [];

        }
        groups[groupName]?.push(item);
        return groups;
    }, {} as GroupedMenuItems);



    return (
        <Grid
            container
            justifyContent="flex-start"
            data-testid={`submenu-title-${props.submenuTitle}`}
            position="absolute"
            zIndex="10"
            ml="100px"
            mt="-84px"
            width="285px"
            height="100vh"
            bgcolor="bg.whiteSmoke"
            boxShadow={3}>
            <Box
                sx={{
                    px: 0,
                    backgroundColor: "bg.whiteSmoke",
                    height: "100vh",
                    width: "18rem",
                    boxShadow: 3,
                }}>
                <Grid
                    container
                    p={1}
                    sx={{ justifyContent: "end" }}>
                    <Grid
                        data-testid={`${props.submenuTitle}-sub-menu-close`}
                        item
                        xs={1}
                        onClick={() => props.closeMenu()}
                        sx={{ cursor: "pointer" }}
                    >
                        <CloseIcon sx={{ color: "#1F3767" }} />
                    </Grid>
                </Grid>
                <ClickAwayListener
                    onClickAway={() => {
                        !props.isSideNavClick && props.closeMenu();
                    }}>
                    <Box>
                        {Object.entries(groupedMenuItems).map(([groupName, items]) => (
                            <div key={groupName}>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "800",
                                        ml: 3,
                                        py: 1,
                                    }}>
                                    {t(groupName)}
                                </ Typography>
                                {
                                    items.map((item: AppMenuItem) => {
                                        const submenuData = AppMenuListData.find((x) => x.id === item.id)!;
                                        return (
                                            <Link
                                                component={RouterLink}
                                                to={submenuData.nav}
                                                key={`${item.groupDetails.name}-${item.id}`}
                                                underline="none"
                                                onClick={() => props.closeMenu()}
                                                data-testid={`${item.name}-sub-menu-link`}>
                                                <Box
                                                    px={5}
                                                    py={1}
                                                    sx={{
                                                        backgroundColor:
                                                            location.pathname === submenuData.nav ? "blue.connectCare2" : "",
                                                    }}>
                                                    <Typography
                                                        variant="subMenuItem"
                                                        sx={{
                                                            color:
                                                                location.pathname === submenuData.nav
                                                                    ? "white"
                                                                    : "blue.connectCare2",
                                                        }}>
                                                        {t(item.name)}
                                                    </Typography>
                                                </Box>
                                            </Link>
                                        );
                                    })
                                }
                            </div>
                        ))}
                    </Box>
                </ClickAwayListener>
            </Box >
        </Grid >
    );
};
