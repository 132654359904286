import { Alert, Box, Button, Grid, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormatCurrency, FormatDate } from "../../common/Formatters";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { AssetDetail as AssetDetailDto } from "../../models/assets/AssetDetail";
import { SectionField, SectionFieldValue, SectionTitle } from "../../theme/theme";
import { useFetch } from "../../services/useFetch";

export interface IAssetDetails {
    customerAccountId?: string;
    assetDetailTab: AssetDetailDto | undefined;
    setAssetDetailTab: Dispatch<React.SetStateAction<AssetDetailDto | undefined>>;
    setEditDetailsTab: Dispatch<SetStateAction<boolean>>;
    serialNumber: string;
    inventoryItemId?: string;
    setPageKey: Dispatch<SetStateAction<number>>;
    isDetailTabReloaded: boolean;
    setIsDetailTabReloaded: Dispatch<SetStateAction<boolean>>;    
}

const AssetDetail = (assetDetails: IAssetDetails) => {
    const { t } = useTranslation();
    const { get } = useFetch();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [purchaseNewValue, setPurchaseNewValue] = useState("");
    const translations = {
        location: t("Location"),
        department: t("Department"),
        specialty: t("Specialty"),
        equipmentId: t("Equipment Id"),
        purchase: t("Purchase"),
        date: t("Date"),
        vendor: t("Vendor"),
        price: t("Price"),
        po: t("PO"),
        purchasedNew: t("Purchased New"),
        apiError: t("System Error: API is not available at this time!"),
        backToHomePage: t("Back to Home Page"),
        editButton: t("Edit"),
    };

    const getAssetDetail = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);
        const uri = `${requestConnectCareInventory.AssetDetail}?custAccountId=${assetDetails.customerAccountId
            }&inventoryItemId=${assetDetails.inventoryItemId}&serialNumber=${encodeURIComponent(
                assetDetails.serialNumber
            )}`;
        const response = await get<AssetDetailDto>(uri, true);
        if (response) {
            assetDetails.setAssetDetailTab(response);
            assetDetails.setIsDetailTabReloaded(true);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [assetDetails, get]);

    useEffect(() => {
        if (!assetDetails.isDetailTabReloaded) {
            getAssetDetail();
        }
        if (assetDetails.assetDetailTab?.newPurchase) {
            if (assetDetails.assetDetailTab?.newPurchase === "Y") {
                setPurchaseNewValue("Yes");
            } else {
                setPurchaseNewValue("No");
            }
        }
    }, [getAssetDetail, assetDetails.isDetailTabReloaded, assetDetails.assetDetailTab?.newPurchase]);

    return (
        <Box
            bgcolor="bg.white"
            minHeight="31vh"
            data-testid="asset-detail"
            sx={{ top: "336px", left: "128px", width: "100%" }}>
            {isLoading && <LinearProgress />}
            {!isLoading && isError && (
                <Grid
                    item
                    xs={12}
                    mx={0}
                    mb={2}>
                    <br />
                    <Alert severity="error">{translations.apiError}</Alert>
                </Grid>
            )}
            {!isLoading && !isError && (
                <>
                    <Grid
                        container
                        sx={{ pl: 11, pt: 6 }}>
                        <Box paddingRight={2}>
                            <SectionTitle>{translations.location}</SectionTitle>
                        </Box>
                        <Grid
                            container
                            sx={{ pt: "19px" }}>
                            <Box paddingRight={4}>
                                <SectionField>{translations.department}</SectionField>
                                <SectionFieldValue>
                                    {assetDetails.assetDetailTab?.departmentDescription}
                                </SectionFieldValue>
                            </Box>
                            <Box paddingRight={4}>
                                <SectionField>{translations.specialty}</SectionField>
                                <SectionFieldValue>
                                    {assetDetails.assetDetailTab?.specialty ?? ""}
                                </SectionFieldValue>
                            </Box>
                            <Box>
                                <SectionField>{translations.equipmentId}</SectionField>
                                <SectionFieldValue>
                                    {assetDetails.assetDetailTab?.equipmentId ?? ""}
                                </SectionFieldValue>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{ pl: 11, pt: 6 }}>
                        <Box paddingRight={3}>
                            <SectionTitle>{translations.purchase}</SectionTitle>
                        </Box>
                        <Grid
                            container
                            sx={{ pt: "19px" }}>
                            <Box paddingRight={3}>
                                <SectionField>
                                    {translations.purchase} {translations.date}
                                </SectionField>
                                <SectionFieldValue>
                                    {FormatDate(assetDetails.assetDetailTab?.purchaseDate)}
                                </SectionFieldValue>
                            </Box>
                            <Box paddingRight={8}>
                                <SectionField>{translations.vendor}</SectionField>
                                <SectionFieldValue>
                                    {assetDetails.assetDetailTab?.purchaseVendor}
                                </SectionFieldValue>
                            </Box>
                            {
                                !assetDetails.assetDetailTab?.isIndirectFiltered &&
                                <Box paddingRight={10}>
                                    <SectionField>{translations.price}</SectionField>
                                        <SectionFieldValue>
                                            {FormatCurrency(Number(assetDetails.assetDetailTab?.purchaseAmount))}
                                        </SectionFieldValue>
                                </Box>
                            }
                            {
                                !assetDetails.assetDetailTab?.isIndirectFiltered &&
                                <Box paddingRight={10}>
                                    <SectionField>{translations.po}</SectionField>
                                    <SectionFieldValue>{assetDetails.assetDetailTab?.purchasePo}</SectionFieldValue>
                                </Box>
                            }
                            <Box>
                                <SectionField>{translations.purchasedNew}</SectionField>
                                <SectionFieldValue>{purchaseNewValue}</SectionFieldValue>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{ pl: 11, pt: 6, pb: 3 }}>
                        <Button
                            variant="text"
                            style={{
                                fontSize: "12px",
                                color: "#FFFFFF",
                                width: "89px",
                                height: "24px",
                                backgroundColor: "#3E8EDE",
                            }}
                            onClick={() => assetDetails.setEditDetailsTab(true)}>
                            {translations.editButton}
                        </Button>
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default AssetDetail;
