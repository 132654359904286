import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LoanerReturnDialogContent from "./LoanerReturnDialogContent";
import { LoanersDue } from "../../models/serviceSummary/LoanersDue";
import { useState } from "react";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { ToastTypes } from "../../models/toast/ToastTypes";
import LoanerActionButton from "../../component-library/LoanerActionButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";

export interface ILoanerReturnDialogProps {
    show: boolean;
    loanerDue?: LoanersDue;
    isProcessingReturn: boolean;
    onCancelClick: () => void;
    onReturnClick: (loanerDue: LoanersDue, isDisinfected: boolean) => void;
}

const LoanerReturnDialog = (props: ILoanerReturnDialogProps) => {
    const { show, loanerDue, isProcessingReturn, onCancelClick, onReturnClick } = props;
    const [itemsDisinfected, setItemsDisinfected] = useState<boolean | null>(null);
    const [isPrinting, setIsPrinting] = useState<boolean>(false);
    const { getBlob } = useFetch();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const translations = {
        title: t("returning a loaner"),
        cancel: t("cancel"),
        return: t("return loaner"),
        error: t("An error occurred."),
        print: t("Print"),
        actions: t("Actions"),
    };
    const handleUpdateItemsDisinfected = (val: boolean | null) => {
        setItemsDisinfected(val);
    };
    const handlePrintError = () => {
        dispatch(
            setToast({
                showToast: true,
                toastType: ToastTypes.Error,
                toastMessage: translations.error,
            })
        );
    };
    const handlePrintClick = () => {
        setIsPrinting(true);
        printLabel(loanerDue?.returnOrderId as number).finally(() => setIsPrinting(false));
    };
    const printLabel = async (returnOrderId: number) => {
        const uri = `${requestConnectCareOrders.Print(returnOrderId.toString())}`;

        const res = await getBlob(uri, true, handlePrintError);
        if (res) {
            window.open(window.URL.createObjectURL(res));
        }
    };

    return (
        <Dialog
            data-testid="loaner-return-dialog"
            open={show}
            fullWidth={true}>
            <DialogTitle id="loaner-return-dialog-title">
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}>
                    <Typography
                        variant="h2"
                        textTransform={"uppercase"}>
                        {translations.title}
                    </Typography>
                    {isPrinting ? (
                        <CircularProgress
                            data-testid={"print-loading"}
                            size={34}
                        />
                    ) : (
                        loanerDue?.returnOrderId && (
                            <LoanerActionButton
                                printShipping={handlePrintClick}
                                isPrinting={isPrinting}
                            />
                        )
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                {loanerDue && (
                    <LoanerReturnDialogContent
                        loanerDue={loanerDue}
                        itemsDisinfected={itemsDisinfected}
                        updateItemsDisinfected={handleUpdateItemsDisinfected}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justifyContent={"flex-end"}
                    columnSpacing={2}>
                    <Grid item>
                        <Button
                            aria-label={translations.cancel}
                            variant="Cancel"
                            size="small"
                            onClick={onCancelClick}>
                            {translations.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        {loanerDue && !loanerDue?.returnOrderId && (
                            <Button
                                aria-label={translations.return}
                                data-testid="return-button"
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={
                                    !AuthLibrary.hasAnyClaim([claimTypes.CreateSrn]) ||
                                    itemsDisinfected === null ||
                                    isProcessingReturn
                                }
                                onClick={() => onReturnClick(loanerDue, itemsDisinfected ?? false)}
                                autoFocus>
                                {translations.return}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
export default LoanerReturnDialog;
