import { ApiErrorCodeTypes } from "../config/ApiErrorCodes";


/**
 * Handles errors throw by fetch. If an apiErrorCode is thrown from the backend with a 400/422 we will handle it here.
 * @param response - The fetch response.
 * @example 
 *  const response = await fetch(uri, requestOptions);
 *  await handleError(response);
 *  return await response.json();
 * @throws {Error} Will throw an error if response status is 400 or 422. Expecting a problem details object. If an api
 * @returns {Promise<Response>} - If the reponse is ok then return a promise to response.
 * @link https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
 */
export const handleErrorResponse = async (response: Response): Promise<Response> => {
    if (response.status === 400 || response.status === 422) { // custom error response format handling
        const errorInfos = await response.json();

        let errorInfo = Array.isArray(errorInfos) ? errorInfos[0] : errorInfos;
        //Note: we are passing back a standard error message.
        //We throw from the exception handling middleware a standardize problem helper. https://datatracker.ietf.org/doc/html/rfc7807
        //Review: log errorinfo with application insights? maybe console.error(errorInfo)
        if (errorInfo.apiErrorCode) {
            const e = new Error(errorInfo.apiErrorCode.concat(" " + errorInfo.detail)); //pass the api error code back to be handled on the frontend.
            e.name = ApiErrorCodeTypes.ApiErrorCode;
            throw e;
        }

        if (errorInfo.title) { //This comes from the problemhelper standard.            
            const e = new Error(errorInfo.title); //pass the api error code back to be handled on the frontend.
            e.name = ApiErrorCodeTypes.Title;
            throw e;
        }
        const e = new Error("An error was thrown."); //pass the api error code back to be handled on the frontend.
        e.name = ApiErrorCodeTypes.Unhandled;
        throw e;
    }
    else if (!response.ok) {
        const e = new Error(response.statusText);
        e.name = response.status.toString()                
        throw e;
    }
    return response;
}