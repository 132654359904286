import { Box, Button, Card, CardContent, Grid, LinearProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormatDateTime } from "../../common/Formatters";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { ConnectCareActionButton, IActionMenuItems } from "../../component-library/ConnectCareActionButton";
import { claimTypes } from "../../config/claimTypes";
import { ccLocalstorage } from "../../config/data";
import { ServiceRequestDetail } from "../../models/assets/ServiceRequestDetail";
import { IOrder } from "../../models/orders/IOrder";
import { OrderStatuses } from "../../models/orders/OrderStatuses";
import { PoUpdateLevel } from "../../models/orders/PoUpdateLevel";
import { UpsertPurchaseOrderNumberRequestDto } from "../../models/orders/UpsertPurchaseOrderNumberRequestDto";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { setToast } from "../../redux/reducers/toastSlice";
import { requestConnectCareOrders, requestConnectCarePurchaseOrdersRequired } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { EditPoDialog } from "../orders/EditPoDialog";
import ServiceRequestCustomerDetails from "./ServiceRequestCustomerDetails";
import { SrnDetailItem } from "./SrnDetailItem";
import { StoreState } from "../../redux/store";

const ServiceRequestDetails = () => {
    const params = useParams<{
        serviceRequestId?: string;
    }>();
    const { get } = useFetch();
    const { post } = useFetch();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [srnDetails, setSrnDetails] = useState<ServiceRequestDetail | undefined>(undefined);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [orderDetail, setOrderDetail] = useState<IOrder | undefined>(undefined);
    const [isPrintClickRunning, setPrintClickRunning] = useState<boolean>(false);
    const [openPoEnterDialog, setOpenPoEnterDialog] = useState<boolean>(false);
    const [isPoNumberSaving, setIsPoNumberSaving] = useState<boolean>(false);
    const { hasIndirectAccounts } = useSelector((state: StoreState) => state.facility);
    const element = useRef<HTMLDivElement>(null);
    const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);

    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.srnDetails)}`,
        pageName: t("Service Request Details"),
        serviceRequestHeader: t("Service Request"),
        serviceRequestNumber: t("SRN #"),
        poNumber: t("PO #"),
        estimate: t("Estimate Required"),
        item: t("Item "),
        serial: t("Serial"),
        description: t("Description"),
        quantity: t("Qty"),
        disinfected: t("Disinfected"),
        problem: t("Problem"),
        createdLabel: t("Created"),
        byLabel: t("by"),
        srnNotFound: t("SRN not found for your selected facilities"),
        poNotFound: t("Customer PO not found for SRN #"),
        actions: t("Actions"),
        reprint: t("Reprint"),
        track: t("Track"),
        enterPO: t("Enter PO"),
        editPO: t("Edit PO"),
        viewOrder: t("View Order"),
        preapproved: t("Preapproved"),
        estimateRequired: t("Estimate Required"),
        notDisinfected: t("Not Disinfected"),
        success: t("Successful Equipment Request Submitted"),
        requestEquipment: t("Request Equipment"),
        viewEquipment: t("View Recent Requests"),
        poFailure: t("Purchase Order saving failed."),
        poSuccess: t("Purchase Order successfully saved."),
    };

    const loadData = useCallback(async () => {
        setIsLoading(true);
        const uri = `${requestConnectCareOrders.ServiceRequestDetails}/${params.serviceRequestId}`;
        const response = await get<ServiceRequestDetail>(uri, true);
        if (response) {
            setSrnDetails(response);
            setIsLoading(false);
        } else {
            dispatch(
                setToast({
                    showToast: true,
                    toastType: ToastTypes.Error,
                    toastMessage: translations.srnNotFound,
                })
            );
            setIsLoading(false);
            setHasError(true);
        }

        // get is left out of the dependency array on purpose
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.serviceRequestId]);

    useEffect(() => {
        if (showSuccessToast) {
            dispatch(
                setToast({
                    toastMessage: translations.success,
                    toastType: ToastTypes.Success,
                })
            );
            setShowSuccessToast(false);
        }
    }, [showSuccessToast, translations.success]);

    const handlePrintClick = (orderId: string | undefined) => {
        setPrintClickRunning(true);
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareOrders.Print(orderId!)}`; //Change;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token} `,
            },
        };
        fetch(uri, requestOptions)
            .then((response) => response.blob())
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                window.open(file);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setPrintClickRunning(false);
            });
    };

    /**
     * Fetches customer po# based on OrderId.
     * @param orderId The order Id from SRN#.
     * @returns "[]" if null or empty
     */
    const getCustomerPoNumber = useCallback(async () => {
        setIsLoading(true);
        if (srnDetails?.orderHeaderId) {
            const uriOrder = `${requestConnectCareOrders.OrderSearch}/${srnDetails?.orderHeaderId}`;
            const response = await get<IOrder>(uriOrder, true);
            if (response) {
                setOrderDetail(response);
                setIsLoading(false);
            } else {
                dispatch(
                    setToast({
                        toastType: ToastTypes.Error,
                        toastMessage: translations.poNotFound,
                    })
                );
                setIsLoading(false);
                setHasError(true);
            }
        }
    }, [srnDetails?.orderHeaderId, get, dispatch, translations.poNotFound]);

    useEffect(() => {
        loadData();
        if (srnDetails?.orderHeaderId) {
            getCustomerPoNumber();
        }
        document.title = translations.documentTitle;
        if (hasError) {
            navigate(-1);
        }
    }, [loadData, getCustomerPoNumber, hasError, navigate, translations.documentTitle, srnDetails?.orderHeaderId]);

    /**
     * Formats the customer po.
     * @param customerPo The customer po number.
     * @returns "-" if null or empty, otherwise decodeURIComponent(customerPo)
     */
    const formatCustomerPo = (customerPo: string) => {
        return customerPo ? decodeURIComponent(customerPo) : "-";
    };

    const [expanded, setExpanded] = useState<boolean>(false);

    const actionButtons: IActionMenuItems[] = [
        {
            name: translations.reprint,
            onClick: () => handlePrintClick(params.serviceRequestId),
            disable: isPrintClickRunning,
            hide: srnDetails?.isStatusClosed,
        },
        {
            name: translations.track,
            onClick: () => {
                window.open(
                    `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${srnDetails?.trackingNumber}`,
                    "_blank"
                );
            },
            disable: srnDetails?.trackingNumber === "" || srnDetails?.trackingNumber === null,
        },
        {
            name: orderDetail?.customerPo !== null ? translations.editPO : translations.enterPO,
            onClick: () => {
                setOpenPoEnterDialog(true);
            },
            hide:
                srnDetails?.orderStatus === OrderStatuses.Closed ||
                hasIndirectAccounts ||
                srnDetails?.orderHeaderId === null ||
                !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]),
        },
        {
            name: translations.viewOrder,
            onClick: () => {
                navigate(`/orders/${srnDetails?.orderHeaderId}`); // Navigate to corresponding order for service request
            },
            disable: srnDetails?.orderHeaderId === null,
        },
    ];

    const handlePoSave = (poNumber: string) => {
        savePONumber(poNumber);
    };

    const savePONumber = useCallback(
        async (poNumber: string) => {
            setIsPoNumberSaving(true);

            const requestData: UpsertPurchaseOrderNumberRequestDto = {
                OrderHeaderId: Number(srnDetails?.orderHeaderId),
                UpdateLevel: PoUpdateLevel.HEADER,
                PurchaseOrderNumber: poNumber,
                ShouldOverwriteNonMatchingHeader: true,
            };

            let result = await post(
                requestConnectCarePurchaseOrdersRequired.UpsertPurchaseOrderNumber,
                requestData,
                true,
                () => {
                    dispatch(
                        setToast({
                            toastMessage: translations.poFailure,
                            toastType: ToastTypes.Error,
                        })
                    );

                    setIsPoNumberSaving(false);
                }
            );

            if ((result as Response).ok) {
                dispatch(
                    setToast({
                        toastMessage: translations.poSuccess,
                        toastType: ToastTypes.Success,
                    })
                );

                setOpenPoEnterDialog(false);
                setIsPoNumberSaving(false);
                loadData();
                getCustomerPoNumber();
            }
        },
        [
            srnDetails?.orderHeaderId,
            post,
            dispatch,
            translations.poFailure,
            translations.poSuccess,
            loadData,
            getCustomerPoNumber,
        ]
    );

    useEffect(() => {
        /*
         * scrollIntoView() method scrolls an element into the visible area of the browser window.
         * whenever "see more" is clicked, then element ref is used as a reference to scroll to that element which get expanded.
         */
        element?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    });
    return (
        <>
            <BreadCrumb breadCrumbs={BreadCrumbList.srnDetails} />
            <Box p={4}>
                <Box
                    p={2}
                    pt={4}>
                    <Grid container>
                        <Grid
                            data-testid="detail-card"
                            item
                            xs={12}>
                            {isLoading ? (
                                <LinearProgress />
                            ) : (
                                <Grid>
                                    <Grid my={2}>
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography
                                                variant="SRNh2"
                                                pt={1}
                                                pl="2px">
                                                {srnDetails?.customerName}
                                            </Typography>
                                            <Typography
                                                variant="SRNh1"
                                                color={"font.darkBlue"}
                                                textTransform={"uppercase"}>
                                                {translations.serviceRequestHeader}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Card>
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    flexWrap: "wrap",
                                                }}>
                                                <Box>
                                                    <Typography
                                                        variant="h5"
                                                        color={"font.darkBlue"}
                                                        textTransform={"uppercase"}
                                                        mb={0}
                                                        pt={1}>
                                                        {translations.serviceRequestNumber}
                                                        {params.serviceRequestId}
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        color={"font.darkBlue"}
                                                        textTransform={"uppercase"}
                                                        mb={0}
                                                        pt={1}>
                                                        {translations.poNumber}
                                                        {formatCustomerPo(orderDetail?.customerPo!)}
                                                    </Typography>
                                                    <Button
                                                        sx={{ textTransform: "capitalize", padding: 0 }}
                                                        onClick={() => setExpanded(!expanded)}>
                                                        {expanded === true ? "See Less" : "See More"}
                                                    </Button>
                                                </Box>
                                                <Box>
                                                    <Box height={0.5}>{isPrintClickRunning && <LinearProgress />}</Box>
                                                    {!srnDetails?.isStatusClosed && (
                                                        <ConnectCareActionButton menuItems={actionButtons} />
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    justifyContent: "flex-start",
                                                }}>
                                                {srnDetails?.items.map((item) => (
                                                    <SrnDetailItem
                                                        key={item.orderLineId}
                                                        translations={translations}
                                                        item={item}
                                                        srnDetails={srnDetails}
                                                        setShowSuccessToast={setShowSuccessToast}
                                                    />
                                                ))}
                                            </Box>
                                            {expanded && (
                                                <Box
                                                    pt={2}
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px 0px",
                                                    }}
                                                    ref={element}>
                                                    <ServiceRequestCustomerDetails srnDetails={srnDetails} />
                                                </Box>
                                            )}
                                            <br />
                                            <Typography
                                                variant="footerValue"
                                                textTransform={"uppercase"}
                                                paddingLeft={0}>
                                                {translations.createdLabel}: {FormatDateTime(srnDetails?.creationDate)}{" "}
                                                {translations.byLabel} {srnDetails?.createdBy}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <EditPoDialog
                                        open={openPoEnterDialog}
                                        onCancel={() => setOpenPoEnterDialog(false)}
                                        onSubmit={(poNumber) => {
                                            handlePoSave(poNumber);
                                        }}
                                        progressIndicator={isPoNumberSaving}
                                        editPoNumber={orderDetail?.customerPo!}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default ServiceRequestDetails;
