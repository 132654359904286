import { Box, Button, CircularProgress, Grid, SvgIconTypeMap, Typography } from "@mui/material";
import { useMemo } from "react";
import { DashboardCardTypes } from "../../models/orders/DashboardCardData";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface IDashboardCard {
    id: number;
    onClick?: () => void;
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    };
    type: DashboardCardTypes;
    title: string;
    value: number;
    isLoading: boolean;
}

/**
This function helps to display the orders tiles in the dashboard page.
@param {string} title - text displayed at the top of the tile.
@param {string} buttonTitle - value or text to the button.
@param {number} value - the count displayed in a tile.
@param {DashboardCardTypes} type - "Action" or "Info"; affects the tile's color.
@param {MouseEventHandler<HTMLButtonElement>} [onClick] - event to be triggered on click of button

@example
Lest assume below is the values for each variable
type : DashboardCardTypes.Action, value: 4, title: "ESTIMATES READY FOR REVIEW", buttonTitle: "REVIEW", path: "" 

calling function...
DashboardCard(title1, title2, buttonTitle, value, type === DashboardCardTypes.Action, onClick, uri)
*/
export default function DashboardCard({ title, value, type, Icon, onClick, isLoading }: IDashboardCard) {
    // TODO: reference MUI theme colors
    const cardRed = "#E45052";
    const cardBlue = "#3E8EDE";
    const cardGray = "#DFE0EB";

    const cardColor = useMemo((): string => {
        if (value === 0) {
            return cardGray;
        }
        return type === DashboardCardTypes.Action ? cardRed : cardBlue;
    }, [value, type]);

    return (
        <Button
            variant="dashboardCard"
            sx={{
                borderColor: cardColor,
                color: cardColor,
                cursor: value ? "pointer" : "default",
            }}
            onClick={value && onClick ? onClick : () => {}}
        >
            <Box display="flex" height="100%" width="100%">
                <div
                    style={{
                        height: "100%",
                        width: "10px",
                        borderTopLeftRadius: "30px",
                        borderBottomLeftRadius: "30px",
                        background: cardColor,
                    }}
                >
                </div>
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Grid container paddingX="10px" paddingTop="10px">
                        <Grid item xs={9} zIndex={2}>
                            <Typography sx={{ color: cardColor}} variant="dbcTitle">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="right">
                            <Icon style={{ color: cardGray }} />
                        </Grid>
                    </Grid>
                    <Box>
                        {isLoading ? (
                            <Box paddingLeft="10px" paddingBottom="10px">
                                <CircularProgress style={{ height: "100%" }} />
                            </Box>
                        ) : (
                            <>
                                <Box paddingLeft="10px">
                                    <Typography sx={{color: cardColor}} variant="dbcCount">
                                        {value}
                                    </Typography>
                                </Box>
                                <Typography visibility={value ? "visible" : "hidden"} variant="dbcView">
                                    View
                                </Typography>
                            </>
                        )}
                    </Box>
                </div>
            </Box>
        </Button>
    );
}
