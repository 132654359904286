import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { useTranslation } from "react-i18next";
import { Alert, Card, CardContent, CardHeader, CardMedia, CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { MissingPhoto } from "../photos/MissingPhoto";
import { InspectionDetailPhotoCard } from "../../models/assets/InspectionDetailPhotoCard";
interface IAssetsDevicePMInspectionLinePhotos {
    readonly headerId: number | undefined;
    readonly customerAccountId: string | undefined;
}

export default function AssetsDevicePMInspectionLinePhotos({
    headerId,
    customerAccountId,
}: IAssetsDevicePMInspectionLinePhotos) {
    const { t } = useTranslation();
    const [inspectionDetailPhotoCards, setInspectionDetailPhotoCards] = useState<InspectionDetailPhotoCard[]>();
    const { get } = useFetch();
    const translations = {
        apiError: t("System Error: API is not available at this time!"),
        photosNotFound: t("No Photos Found!"),
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const getinspectionDetailPhotoCards = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        const uri = `${requestConnectCareInventory.InspectionDetailsPhotos}?headerId=${headerId}&customerAccountId=${encodeURIComponent(
            customerAccountId ?? ""
        )}`;
        const response = await get<InspectionDetailPhotoCard[]>(uri, true);
        const result = response as InspectionDetailPhotoCard[];
        if (result) {
            setInspectionDetailPhotoCards(result);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [headerId, customerAccountId, get]);

    useEffect(() => {
        getinspectionDetailPhotoCards();
    }, [getinspectionDetailPhotoCards]);

    return (
        <Box
            data-testid="inspection-details-photos-tab"
            width="100%"
            p={2}
            display={"flex"}
            height={inspectionDetailPhotoCards && inspectionDetailPhotoCards.length > 0 ? "575px" : "inherit"}>
            {isLoading && <CircularProgress />}
            {!isLoading && isError && <Alert severity="error">{translations.apiError}</Alert>}
            {!isLoading && !isError && (
                <>
                    {inspectionDetailPhotoCards?.length ? (
                        <Grid
                            paddingTop="20px"
                            paddingLeft="20px"
                            sx={{ flexGrow: 1, overflowY: "auto" }}
                            paddingBottom="10px"
                            spacing={2}
                            container>
                            {inspectionDetailPhotoCards?.map((photo) => {
                                return (
                                    <Card
                                        variant="elevation"
                                        key={`photoCard_${photo.id}`}>
                                        <CardHeader
                                            sx={{ paddingBottom: 0 }}
                                            title={<Typography fontSize={12}>{photo.lineNumber}</Typography>}
                                        />
                                        <CardHeader
                                            title={
                                                <Typography
                                                    textTransform={"uppercase"}
                                                    fontSize={12}
                                                    fontWeight={600}>
                                                    {photo.description}
                                                </Typography>
                                            }
                                            sx={{ paddingTop: 0 }}
                                        />
                                        <Typography
                                            textTransform={"uppercase"}
                                            gutterBottom
                                            fontSize={15}
                                            fontWeight={700}
                                            align="center"
                                            component="h3">
                                            {photo.photoState}
                                        </Typography>
                                        {photo.photoUrl.includes("/images/noimage") && <MissingPhoto />}
                                        {!photo.photoUrl.includes("/images/noimage") && (
                                            <CardMedia
                                                onClick={() =>
                                                    window.open(photo.photoUrl, "_blank", "noopener,noreferrer")
                                                }
                                                image={photo.photoUrl}
                                                component="img"
                                                height="185px"
                                            />
                                        )}
                                        <CardContent>
                                            <Typography
                                                fontWeight={300}
                                                gutterBottom
                                                component="h3"
                                                fontSize={12}>
                                                {photo.comments}
                                            </Typography>
                                        </CardContent>
                                        {photo.category && (
                                            <Typography
                                                gutterBottom
                                                component="h3"
                                                variant="detailLabel"
                                                fontSize={10}
                                                align="left">
                                                {photo.category}
                                            </Typography>
                                        )}
                                    </Card>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Alert severity="info">{translations.photosNotFound}</Alert>
                    )}
                </>
            )}
        </Box>
    );
}
