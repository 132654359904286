/**
 * Defining pre-typed react-redux hooks
 * {@link -https://redux.js.org/tutorials/essentials/part-2-app-structure#defining-pre-typed-react-redux-hooks }
 */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../redux/store";

/**
 * Typed redux hook for the redux useDispatch hook
 * @example
 * Toast Example:
 * const dispatch = useAppDispatch();
 * ...
 * const toast = { toastMessage: "Hello!", toastType: ToastTypes.Error }
 * dispatch(setToast(toast));
 */
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
/**
 * Typed redux hook for the redux useSelector hook
 * @example
 * Orders Grid Example:
 * const { isLoading, data, gridColumns, gridFilter, gridSort } = useAppSelector(state => state.orders);
 */
export const useAppSelector = useSelector.withTypes<StoreState>();
