import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { Alert, Grid, LinearProgress, TableHead } from "@mui/material";
import { Box } from "@mui/system";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";

interface IAssetsDevicePMInspectionLineDetails {
    test: string;
    comments: string;
    result: string;
}

export default function AssetsDevicePMInspectionLineDetails({ pmiLineId }: { pmiLineId: number }) {
    const { t } = useTranslation();
    const { get } = useFetch();
    const [assetsDevicePMInspectionLineDetails, setAssetsDevicePMInspectionLineDetails] =
        useState<IAssetsDevicePMInspectionLineDetails[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const translations = {
        apiError: t("System Error: API is not available at this time!"),
        noDetails: t("There are currently no details to display"),
        test: t("Test"),
        result: t("Result"),
        comment: t("Comment"),
    };
    const getAssetsDevicePMInspectionDetails = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);
        const uriOrder = `${requestConnectCareInventory.AssetDevicePMInspectionLineDetails}?pmiLineId=${pmiLineId}`;
        const response = await get<IAssetsDevicePMInspectionLineDetails[]>(uriOrder, true);
        if (response) {
            setAssetsDevicePMInspectionLineDetails(response);
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }, [pmiLineId, get]);

    useEffect(() => {
        getAssetsDevicePMInspectionDetails();
    }, [getAssetsDevicePMInspectionDetails]);

    const StyledTableRow = styled(TableRow)(() => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#F7F7F7",
        },
    }));
    const colorValue = (result: string) => (): string => {
        switch (result) {
            case "PASS":
                return "font.materialGreen";
            case "FAIL":
                return "font.materialRed";
            default:
                return "font.darkGray";
        }
    };

    return (
        <Box
        bgcolor="bg.light"
        paddingX={4}
        paddingBottom={4}
            data-testid="assets-device-pm-inspection-details">
            <Box>
                <Grid
                    container
                    bgcolor="bg.light"
                    border={1}
                    borderColor="font.gray2"
                    borderRadius={1}
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    data-testid="assets-device-pm-line-details"
                    >
                    <Grid
                        item
                        width="100%">
                        {isLoading && <LinearProgress></LinearProgress>}
                        {!isLoading && isError && (
                            <Grid
                                marginX={4}
                                marginY={1}>
                                <Alert severity="error">{translations.apiError}</Alert>
                            </Grid>
                        )}
                        {!isLoading &&
                            !isError &&
                            assetsDevicePMInspectionLineDetails &&
                            assetsDevicePMInspectionLineDetails?.length > 0 && (
                                <TableContainer>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{translations.test}</TableCell>
                                                <TableCell>{translations.result}</TableCell>
                                                <TableCell>{translations.comment}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assetsDevicePMInspectionLineDetails &&
                                                assetsDevicePMInspectionLineDetails.map((a) => (
                                                    <StyledTableRow key={a.test}>
                                                        <TableCell>{a.test}</TableCell>
                                                        <TableCell>
                                                            <Box>
                                                                <Box
                                                                    display={"flex"}
                                                                    alignItems="center">
                                                                    <FiberManualRecordRoundedIcon
                                                                        fontSize="small"
                                                                        sx={{
                                                                            color: colorValue(a.result),
                                                                            verticalAlign: "bottom",
                                                                        }}
                                                                    />
                                                                    {a.result}
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>{a.comments}</TableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        {!isLoading && !isError && (!assetsDevicePMInspectionLineDetails || assetsDevicePMInspectionLineDetails?.length == 0) && (
                            <Grid
                                item
                                xs={12}
                                mx={0}
                                mb={2}>
                                <br />
                                <Alert severity="info">{translations.noDetails}</Alert>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
