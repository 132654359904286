import { Grid } from "@mui/material";
import { DateRange, DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Dayjs } from "dayjs";

export interface DateRangeProps {
    dateRange: DateRange<Dayjs>;
    onChange: (date: DateRange<Dayjs>) => void;
    /**
     * The label adornment to the start date textfield
     * Default: "Start Date"
     */
    startDateLabel?: string;
    /**
     * The label adornment to the end date textfield
     * Default: "End Date"
     */
    endDateLabel?: string;
}

/**
 * Adds a date range picker with small textfields and "to" as the fieldSeparator.
 * @param props {@link DateRangeProps}
 * @returns JSX.Element
 */
export default function HeaderDateRangePicker(props: Readonly<DateRangeProps>) {
    const { t } = useTranslation();
    const startString = props.startDateLabel ?? t("Start Date");
    const endString = props.endDateLabel ?? t("End Date");
   
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid
                item
                data-testid="header-daterange-picker">
                <DateRangePicker
                    localeText={{ start: startString, end: endString }}
                    value={props.dateRange}
                    onChange={(date) => props.onChange(date)}
                    slotProps={{
                        textField: { size: "small" },
                        fieldSeparator: { children: "to" },
                    }}
                />
            </Grid>
        </LocalizationProvider>
    );
}
