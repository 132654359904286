import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { pageKeysInvoices } from "../../config/data";
import InvoiceAgingList from "../../components/invoices/InvoiceAgingList";
import InvoiceAgingDetailsGrid from "../../components/invoices/InvoiceAgingDetailsGrid";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageTitles } from "../../common/SiteMap";
import { GridBackgroundColor } from "../../theme/theme";

export default function Invoices() {    
    const [pageKey, setPageKey] = useState(pageKeysInvoices.InvoiceAgingList);
    const params = useParams<{ id?: string; groupId?: string }>();
    const { t } = useTranslation();
    const theme = useTheme();
    
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.invoices)}`,
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        const pageKey = params.id ? pageKeysInvoices.InvoiceAgingDetails : pageKeysInvoices.InvoiceAgingList;
        setPageKey(pageKey);
    }, [params]);

    return (
        <Box
            data-testid="connect-care-invoices"
            style={GridBackgroundColor}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            {!params.id && pageKey === pageKeysInvoices.InvoiceAgingList && <InvoiceAgingList />}
            {params.id && pageKey === pageKeysInvoices.InvoiceAgingDetails && <InvoiceAgingDetailsGrid />}
        </Box>
    );
}
