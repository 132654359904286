import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomerSupportRequest from "../../components/customerSupportRequest/CustomerSupportRequest";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";

function Support() {
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.support)}`,
        textareaPlaceholderText: "Here is some text for a support request.",
        submit: "Submit",
        cancel: "Cancel",
        validationText: "Minimum 3 characters required or Maximum 500 characters exceeded",
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <Box
            id="ConnectCareCustomerSupport"
            data-testid="connectcare-support">
            {/* *** Bread Crumbs *** */}
            <BreadCrumb breadCrumbs={BreadCrumbList.support} />

            {/* * ** Page Body *** */}
            <CustomerSupportRequest />
        </Box>
    );
}

export default Support;
