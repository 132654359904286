import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const NotSubscribedDisplay = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const translations = {
        goToDashboard: t("Go to Dashboard"),
        notIncluded: t("This feature is not included in your facility's subscription."),
        contact: t("Contact your Account Manager to learn how you can access all features in ConnectCare!"),
    };

    const handleClickLabel = () => {
        navigate("/dashboard");
    };

    return (
        <Box
            bgcolor={"bg.lightGray"}
            height="calc(100vh - 122px)"
            p={2}>
            <Card
                variant="outlined"
                sx={{ height: "100%" }}>
                <CardContent>
                    <Grid
                        container
                        justifyContent={"center"}
                        mt={15}>
                        <Grid
                            item
                            xs={12}>
                            <Typography textAlign={"center"}>
                                <Lock />
                            </Typography>
                            <Typography
                                variant="h4"
                                textAlign={"center"}
                                marginTop={1}
                                marginBottom={3}>
                                {translations.notIncluded}
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign={"center"}>
                                {translations.contact}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                color="blue.connectCare2"
                                textAlign={"center"}>
                                <Box mt={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={handleClickLabel}>
                                        {translations.goToDashboard}
                                    </Button>
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};
