import { Box, Grid, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InvoiceHeader, InvoiceSummary } from "../../models/invoices/InvoiceAging";
import { FormatCurrency } from "../../common/Formatters";
import { StyledTableCell } from "../../theme/theme";

function InvoiceAgingItemsSummary({
    summary,
    headerLine,
}: {
    summary: InvoiceSummary | null | undefined;
    headerLine: InvoiceHeader | null | undefined;
}) {
    const { t } = useTranslation();
    const translations = {
        subtotal: t("Subtotal"),
        tax: t("Tax"),
        shipping: t("Shipping"),
        handling: t("Handling"),
        total: t("Total"),
        paymentsAndCredits: t("Payments And Credits"),
        balance: t("Balance"),
    };

    return (headerLine && summary && (
        <Grid
            container
            data-testid="invoice-aging-items-summary"
            paddingBottom={4}>
            <Grid
                item
                xl={9}
                lg={9}
                md={9}
                sm={8}
                xs={6}>
                <Box marginLeft={6}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Remit Payment To:</Typography>
                    <Typography variant="body2" >STERIS Instrument Management Services, Inc.</Typography>
                    <Typography variant="body2" >PO Box 531809</Typography>
                    <Typography variant="body2" >Atlanta, GA 30353-1809</Typography>
                </Box>
            </Grid>
            <Grid
                item
                xl={3}
                lg={3}
            >
                <TableContainer>
                    <Table aria-label="customized table" size="small" sx={{ padding: "0" }}>
                        <TableBody >
                            <TableRow>
                                <StyledTableCell>{translations.subtotal}</StyledTableCell>
                                <StyledTableCell>
                                    {FormatCurrency(summary?.subTotal, headerLine?.transactionCurrencyCode)}
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>{translations.tax}</StyledTableCell>
                                <StyledTableCell>
                                    {FormatCurrency(summary?.tax, headerLine?.transactionCurrencyCode)}
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>{translations.shipping + "/" + translations.handling}</StyledTableCell>
                                <StyledTableCell>
                                    {FormatCurrency(summary?.shippingHandling, headerLine?.transactionCurrencyCode)}
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>{translations.total}</StyledTableCell>
                                <StyledTableCell>
                                    {FormatCurrency(summary?.total, headerLine?.transactionCurrencyCode)}
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>{translations.paymentsAndCredits}</StyledTableCell>
                                <StyledTableCell>
                                    {FormatCurrency(headerLine?.paymentsAndCredits, headerLine?.transactionCurrencyCode)}
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>{translations.balance}</StyledTableCell>
                                <StyledTableCell>
                                    {FormatCurrency(headerLine?.balance, headerLine?.transactionCurrencyCode)}
                                </StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
     )
    );
}
export default InvoiceAgingItemsSummary;
