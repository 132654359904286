import { Box, Grid, Typography, Button, FormControl, TextField, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";

import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { UserService } from "../../services/user/UserService";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { CreateCustomerSupportRequest } from "../../models/admin/CreateCustomerSupportRequest";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { StoreState } from "../../redux/store";

function CustomerSupportRequest() {
    const { t } = useTranslation();
    const translations = {
        textareaPlaceholderText: "Enter your technical support question or need.",
        submit: "Submit",
        cancel: "Cancel",
        validationText: "Minimum 3 characters required",
        maximumValidation: "Maximum 500 characters exceeded",
        sucessMessage: "Success! Support Request submitted.",
        errorMessage: "Problem loading the page",
    };
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateCustomerSupportRequest>({ mode: "onChange" });
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);

    const handleCancel = () => {
        navigate("/dashboard");
    };

    const onSubmit: SubmitHandler<CreateCustomerSupportRequest> = (formData: CreateCustomerSupportRequest) => {
        setDisableSubmit(true);
        setIsLoading(true);
        new UserService()
            .createCustomerSupportRequest(formData.note, getCustAccountIds(selectedFacilities))
            .then((ret) => {
                if (ret && ret > 0) {
                    dispatch(
                        setToast({
                            showToast: true,
                            toastType: ToastTypes.Success,
                            toastMessage: translations.sucessMessage,
                        })
                    );
                } else {
                    dispatch(
                        setToast({
                            showToast: true,
                            toastType: ToastTypes.Error,
                            toastMessage: translations.errorMessage,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(
                    setToast({
                        showToast: true,
                        toastType: ToastTypes.Error,
                        toastMessage: error.message,
                    })
                );
            })
            .finally(() => {
                navigate(`/dashboard`);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box padding={2}>
                <Box>
                    <Typography
                        variant="h1"
                        color="font.darkBlue">
                        {t("How can we help you?")}
                    </Typography>
                </Box>
                <hr />
                {isLoading && (
                    <CircularProgress
                        data-testid="progress-spinner"
                        size={40}
                    />
                )}
                <Box
                    marginBottom={2}
                    marginTop={2}>
                    <Typography
                        variant="h3"
                        fontSize={14}>
                        {t(
                            "Please enter your technical support question and a support agent will respond to you by email."
                        )}
                    </Typography>
                </Box>

                <Grid container>
                    <Grid
                        bgcolor="bg.light"
                        borderColor="font.gray2"
                        borderRadius={1}
                        item
                        xs={7}>
                        <>
                            <FormControl
                                fullWidth
                                variant="outlined">
                                <TextField
                                    fullWidth
                                    inputProps={{ "data-testid": "support-request-input" }}
                                    {...register("note", {
                                        required: translations.validationText,
                                        maxLength: { value: 500, message: translations.maximumValidation },
                                        validate: (value: string) => {
                                            setDisableSubmit(false);
                                            let trimmedValue = value.trim();
                                            let errorMessage =
                                                (trimmedValue && trimmedValue.length >= 3) ||
                                                translations.validationText;
                                            if (!errorMessage) {
                                                errorMessage =
                                                    (trimmedValue && trimmedValue.length <= 500) ||
                                                    translations.maximumValidation;
                                            }

                                            return errorMessage;
                                        },
                                    })}
                                    placeholder={translations.textareaPlaceholderText}
                                    sx={{ boxShadow: 3, backgroundColor: "#FFFFFF", width: "100%" }}
                                    multiline
                                    rows={5}
                                    error={!!errors.note}
                                />
                            </FormControl>
                            <Typography
                                variant="inherit"
                                data-testid={"describe-problem-min-max-error"}
                                color="error"
                                fontWeight="bold"
                                fontSize={12}>
                                {errors.note?.message}
                            </Typography>
                        </>
                    </Grid>

                    <Grid
                        item
                        xs={7}
                        my={2}>
                        <Box
                            display="flex"
                            justifyContent="flex-end">
                            <Button
                                aria-label={translations.cancel}
                                data-testid="cancelButton"
                                variant="Cancel"
                                onClick={handleCancel}>
                                {translations.cancel}
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                aria-label={translations.submit}
                                data-testid="submitButton"
                                variant="contained"
                                disabled={!!errors.note || disableSubmit}
                                type="submit">
                                {translations.submit}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
}

export default CustomerSupportRequest;
