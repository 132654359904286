import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomerDetail from "../../components/customers/customerDetail/CustomerDetail";
import { useParams } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";

export default function CustomerDetails() {
    const { t } = useTranslation();
    const { customeraccountid = "" } = useParams<{ customeraccountid: string }>();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const translations = {
        detailPageName: t("Admin"),
        detailPageName2: t("Customers"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.adminCustomerDetails)}`,
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <Box
            data-testid="customer-detail-page"
            bgcolor="bg.lightGray"
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <BreadCrumb breadCrumbs={BreadCrumbList.adminCustomerDetails} />
            <CustomerDetail customerAccountId={customeraccountid} />
        </Box>
    );
}
