import { useCallback, useEffect, useState } from "react";
import { MaintenanceRecords, defaultMaintenanceRecordState } from "../../models/serviceDelivery/MaintenanceRecords";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareCustomers } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { text, widget } from "../../theme/colors";
import ChartAnnotation from "chartjs-plugin-annotation";
import { Chart as ChartJS, Title, Tooltip, Legend } from "chart.js";
import { Divider, Typography, Button, Card, CardContent, CardHeader, CardActions, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BarChart, AxisConfig } from "@mui/x-charts";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

ChartJS.register(Title, Tooltip, Legend, ChartAnnotation);

export default function FlexScopeMaintenance() {
    const { t, i18n } = useTranslation();
    const { get } = useFetch();
    const navigate = useNavigate();
    const [renderChart, setRenderChart] = useState<boolean>(true);
    const [flexScopeMaintenanceRecord, setFlexScopeMaintenanceRecord] =
        useState<MaintenanceRecords>(defaultMaintenanceRecordState);
    const [totalCount, setTotalCount] = useState<string>();
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);

    const translations = {
        ChartTitle: t("Flex Scope Maintenance Status"),
        UpToDate: t("Up to Date"),
        DueForService: t("Due for Service"),
        OverdueForService: t("Overdue for Service"),
        DevicesWithNoPreviousService: t("Devices with no previous service record"),
        ErrorMessage: t("Data is unavailable or there is not enough data to display this metric."),
        View: t("View"),
    };

    const populateBarChartData = useCallback((flexScopeMaintenanceRecord: MaintenanceRecords) => {
        setFlexScopeMaintenanceRecord(flexScopeMaintenanceRecord);
        setTotalCount(
            Intl.NumberFormat(i18n.language).format(
                flexScopeMaintenanceRecord.upToDateCount +
                    flexScopeMaintenanceRecord.dueForServiceCount +
                    flexScopeMaintenanceRecord.overDueForServiceCount
            )
        );
        setRenderChart(true);
    }, []);

    const getFlexScopeMaintenanceRecords = useCallback(async () => {
        const response = await get<MaintenanceRecords>(requestConnectCareCustomers.FlexScopeMaintenance, true);
        if (response) {
            populateBarChartData(response);
        }
    }, [get, populateBarChartData]);

    useEffect(() => {
        if (!initialDataLoaded) {
            getFlexScopeMaintenanceRecords();
            setInitialDataLoaded(true);
        }
    }, [getFlexScopeMaintenanceRecords, initialDataLoaded]);

    useEffect(() => {
        setInitialDataLoaded(false);
    }, [selectedFacilities]);

    const getSeries = () => [
        {
            label: translations.UpToDate,
            data: [flexScopeMaintenanceRecord.upToDateCount],
            color: widget.green,
        },
        {
            label: translations.DueForService,
            data: [flexScopeMaintenanceRecord.dueForServiceCount],
            color: widget.yellow,
        },
        {
            label: translations.OverdueForService,
            data: [flexScopeMaintenanceRecord.overDueForServiceCount],
            color: widget.red,
        },
    ];

    return (
        <Grid
            data-testid="flex-scope-maintenance-graph"
            container
            textAlign={"center"}
            my={2}>
            {renderChart && (
                <Grid
                    item
                    xs>
                    <Card
                        key="flex-scope-maintenance"
                        sx={{ border: "1px solid #DFE0EB", boxShadow: 0 }}>
                        <CardHeader
                            title={
                                <Typography
                                    variant="detailLabel"
                                    color={widget.blackishblue}
                                    mt={"8px"}>
                                    {translations.ChartTitle}
                                </Typography>
                            }
                        />
                        <CardContent>
                            <BarChart
                                height={300}
                                margin={{
                                    top: 0,
                                    bottom: 90,
                                }}
                                series={getSeries()}
                                xAxis={[
                                    {
                                        scaleType: "band",
                                        data: [`${translations.ChartTitle} (${totalCount})`],
                                        categoryGapRatio: 0,
                                    } as AxisConfig<"band">,
                                ]}
                                slotProps={{
                                    legend: {
                                        direction: "column",
                                        position: { vertical: "bottom", horizontal: "middle" },
                                        padding: 0,
                                        itemMarkWidth: 45,
                                        itemMarkHeight: 12,
                                        markGap: 5,
                                        labelStyle: {
                                            fontSize: 14,
                                            marginTop: 10,
                                        },
                                    },
                                }}
                                leftAxis={null}
                                bottomAxis={null}
                            />
                            <Typography
                                variant="body2"
                                color={text.textgray2}
                                my={2}>
                                {`${flexScopeMaintenanceRecord?.neverServicedCount} ${translations.DevicesWithNoPreviousService}`}
                            </Typography>
                            <Divider />
                        </CardContent>
                        <CardActions
                            sx={{
                                justifyContent: "center",
                                mb: 1,
                            }}>
                            <Button
                                aria-label={t("View")}
                                data-testid="flex-scope-maintenance-view"
                                variant="outlined2"
                                onClick={() => {
                                    navigate(`/assets/dueforpm/flex-scope`);
                                }}>
                                {translations.View}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            )}
        </Grid>
    );
}
