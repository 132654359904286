import { ItemsDisinfectedOptions } from "../models/serviceRequest/ItemsDisinfectedOptions";

const getItemDisinfectedValue = (itemsDisinfected: boolean | null | undefined) => {
    if (itemsDisinfected === null) {
        return ItemsDisinfectedOptions.Select;
    }
    return itemsDisinfected ? ItemsDisinfectedOptions.Yes : ItemsDisinfectedOptions.No;
};

export default getItemDisinfectedValue;
