import { useEffect } from "react";
import PurchaseOrdersRequiredList from "../../components/serviceSummary/PurchaseOrdersRequiredList";
import { useTranslation } from "react-i18next";
import { PageTitles } from "../../common/SiteMap";

function PORequired() {
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.porequired)}`,
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return <PurchaseOrdersRequiredList />;
}

export default PORequired;