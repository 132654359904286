import { Alert, Box, Grid, LinearProgress, Tooltip, Link } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DataGridPro, GridColDef, DataGridProProps, useGridApiRef } from "@mui/x-data-grid-pro";
import { ccLocalstorage } from "../../config/data";
import { requestConnectCareInvoices } from "../../services/apiPaths";
import { FormatCurrency } from "../../common/Formatters";
import { InvoiceAgingDetailLine } from "../../models/invoices/InvoiceAging";
import { useNavigate } from "react-router-dom";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

/**
 * InvoiceAgingItemsLines component displays the invoice details line item(s) into a data grid.
 * @param id - The invoice id.
 * @param transactionCurrencyCode - The currency code of the transaction.
 * @param invoiceNumber - The invoice number.
 * @returns A component to display the invoice details line item(s).
 */
function InvoiceAgingItemsLines({
    id,
    transactionCurrencyCode,
    invoiceNumber,
}: Readonly<{ id: any; transactionCurrencyCode: string | undefined; invoiceNumber: string | undefined }>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [gridRows, setGridRows] = useState<InvoiceAgingDetailLine[]>([]);
    const [isGridLoading, setIsGridLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [groupingExpansionDepth, setGroupingExpansionDepth] = useState(0);
    const apiRef = useGridApiRef();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [showExpandAll, setShowExpandAll] = useState(false);
    const translations = {
        invoiceDetailsLineNoData: t("You have no invoice detail lines"),
        line: t("Line"),
        description: t("Description"),
        expandAll: t("Expand All"),
        collapseAll: t("Collapse All"),
        apiError: t("System Error: API is not available at this time!"),
        extendedPrice: t("Extended Price"),
        itemPrice: t("Item Price"),
        quantity: t("Qty"),
        unitPrice: t("Unit Price"),
    };

    /**
     * getInvoiceDetailsGrid fetches the invoice details line item(s) from the API.
     * @returns {void}
     */
    const getInvoiceDetailsGrid = useCallback(() => {
        setIsError(false);
        setIsGridLoading(true);
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        const uriInvoice = `${requestConnectCareInvoices.InvoiceDetailsLines}/${id}`;
        fetch(uriInvoice, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGridRows(data);
                calculateExtendedPrice(data);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsGridLoading(false);
            });
    }, [id, selectedFacilities]);

    useEffect(() => {
        getInvoiceDetailsGrid();
    }, [getInvoiceDetailsGrid]);

    /**
     * calculateExtendedPrice calculates the extended price for each of the invoice details line item(s).
     * @param gridRows - The array of invoice details line data.
     * @returns {void}
     */
    const calculateExtendedPrice = (gridRows: InvoiceAgingDetailLine[]) => {
        if (gridRows.length > 0) {
            gridRows.forEach(function (e) {
                let parent = 0;
                let price = 0;
                let filtered_rows: any = [];
                if (e?.parentChildIds.length === 1) {
                    parent = e?.parentChildIds[0] ?? 0;
                    filtered_rows = gridRows.filter((z) => z.parentChildIds[0] === parent);
                    filtered_rows
                        .filter((z: any) => z.parentChildIds.length === 2)
                        .forEach(function (en: any) {
                            price = price + en.extPrice;
                        });
                    if (price !== 0)
                        filtered_rows.filter((z: any) => z.parentChildIds.length === 1)[0].extPrice = price;
                }
            });

            /**
             * parentChildRowLength calculates the number of parent rows having at least one child row. This is used to determine if the "Expand All" button should be displayed or not.
             *
             * @param gridRows - The array of invoice details line data.
             * @returns {number} The number of parent rows having at least one child.
             * If the number of parent rows having at least one child is greater than 1, the "Expand All" button should be set to true.
             */
            const parentChildRowLength = gridRows.filter(
                (a, i) =>
                    gridRows.findIndex(
                        (s) => s.parentChildIds.length > 1 && a.parentChildIds[0] === s.parentChildIds[0]
                    ) === i
            ).length;

            if (parentChildRowLength > 1) {
                setShowExpandAll(true);
            } else {
                setShowExpandAll(false);
            }
        }
    };

    /**
     * handleExpandAllClick handles the click event of the "Expand All" button.
     * @returns {void}
     */
    const handleExpandAllClick = () => {
        let parentHasAtleastOneChild: InvoiceAgingDetailLine[] = [];
        // to get the list of parents having atleast one child
        gridRows.forEach(function (val) {
            if (val.lineNumber >= 1) {
                let childrenCount = gridRows.filter((x) => x.parentChildIds[0] === val.parentChildIds[0]).length;

                if (childrenCount > 1) {
                    parentHasAtleastOneChild.push(val);
                }
            }
        });

        // "Expand All" should only be visible if there are more than one parent invoice items having at least one child item
        parentHasAtleastOneChild.forEach(function (val) {
            const expand = groupingExpansionDepth === 0; //Expand the children
            const depth = expand ? 1 : 0; //set the depth of expansion

            if (val.id > 0) {
                apiRef.current.setRowChildrenExpansion(val.id, expand);
            }
            setGroupingExpansionDepth(depth);
        });
    };

    const columns: GridColDef[] = [
        {
            field: "lineNumber",
            width: 100,
            valueGetter: (params) => {
                const parentChildIds = params.row.parentChildIds as string[];
                if (parentChildIds.length === 1) {
                    return parentChildIds[parentChildIds.length - 1];
                } else {
                    return "";
                }
            },
            renderCell: (params) => {
                // If the Invoice if for a Capitation Agreement, the line number should NOT be a link to the order page -- CAP Invoices will never map 1:1 to an order headerId
                // See Wiki: https://dev.azure.com/imsrepair/ConnectCare/_wiki/wikis/ConnectCare.wiki/254/CAP-Invoices
                const lineNumber = params.row.lineNumber > 0 ? params.row.lineNumber : "";
                if (!invoiceNumber?.startsWith("CAP")) {
                    return (
                        <Link
                            data-testid={"orderslink"}
                            onClick={() => {
                                navigate(`/orders/${params.row.headerId}?line=${params.row.lineNumber}`);
                            }}>
                            {lineNumber}
                        </Link>
                    );
                } else {
                    return lineNumber;
                }
            },
            renderHeader: () => translations.line,
        },
        {
            field: "description",
            renderHeader: () => translations.description,
            width: 450,
            align: "left",
            headerAlign: "left",
            renderCell: (params: any) => (
                <Tooltip title={params.row.description}>
                    <Box
                        component="span"
                        sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", align: "left" }}>
                        {params.row.description}
                    </Box>
                </Tooltip>
            ),
        },
        {
            field: "qty",
            renderHeader: () => translations.quantity,
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "unitPrice",
            renderHeader: () => translations.unitPrice,
            minWidth: 100,
            maxWidth: 100,
            flex: 1,
            align: "right",
            headerAlign: "center",
            valueGetter: ({ row }) => {
                return FormatCurrency(row.unitPrice, transactionCurrencyCode);
            },
        },
        {
            field: "extPrice",
            renderHeader: () => translations.extendedPrice,
            minWidth: 130,
            maxWidth: 130,
            flex: 1,
            align: "right",
            headerAlign: "center",
            valueGetter: ({ row }) => {
                return FormatCurrency(row.extPrice, transactionCurrencyCode);
            },
        },
        {
            field: "itemPrice",
            renderHeader: () => translations.itemPrice,
            minWidth: 100,
            maxWidth: 100,
            flex: 1,
            align: "right",
            headerAlign: "center",
            valueGetter: ({ row }) => {
                return FormatCurrency(row.itemPrice, transactionCurrencyCode);
            },
        },
    ];

    const getTreeDataPath = (row: any) => row.parentChildIds;
    const groupingColDef: DataGridProProps["groupingColDef"] = {
        renderHeader: () => (
            <>
                {showExpandAll && (
                    <Link
                        data-testid="expandall-link"
                        onClick={() => handleExpandAllClick()}
                        style={{ textTransform: "uppercase" }}>
                        {groupingExpansionDepth === 0 ? translations.expandAll : translations.collapseAll}
                    </Link>
                )}
            </>
        ),
        hideDescendantCount: true,
        valueFormatter: () => "",
        width: 140,
        align: "center",
    };

    return (
        <Grid
            item
            xs>
            <Box
                bgcolor="bg.light"
                data-testid="invoice-aging-items-line"
                sx={{ pt: 4, ml: 1, mr: 1, "& .text-transform-header": { textTransform: "uppercase" } }}>
                {isError && (
                    <Grid
                        item
                        xs={12}
                        sx={{ mx: 2, mb: 2 }}>
                        <Alert severity="error">{translations.apiError}</Alert>
                    </Grid>
                )}
                {
                    <DataGridPro
                        treeData
                        rows={gridRows}
                        columns={columns}
                        getRowId={(row) => row.id}
                        getTreeDataPath={getTreeDataPath}
                        defaultGroupingExpansionDepth={groupingExpansionDepth}
                        groupingColDef={groupingColDef}
                        sx={{
                            ".MuiDataGrid-columnSeparator": {
                                display: "none",
                            },
                            "&.MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                mt: 2,
                            },
                            "& .MuiDataGrid-columnHeaderTitleContainer": {
                                textTransform: "uppercase",
                                fontWeight: "bold",
                                color: "grey",
                            },
                        }}
                        density="compact"
                        hideFooterRowCount={true}
                        autoHeight
                        columnHeaderHeight={52}
                        slots={{
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => NoRowsOverlay({ text: translations.invoiceDetailsLineNoData }),
                        }}
                        loading={isGridLoading}
                        apiRef={apiRef}
                        columnBuffer={12}
                    />
                }
            </Box>
        </Grid>
    );
}
export default InvoiceAgingItemsLines;
