import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

/**
 * Displays order number with a link to order details or /orders/{headerid} 
 * order number and order header id are not null.
 * @param {number} orderNumber The order number. This is what is displayed.
 * @param {number} orderHeaderId The order header id. This is where to link to.
 */
export function OrderDetailLinkCell(props: { orderNumber: number, orderHeaderId: number }) {
    const navigate = useNavigate();
    const { orderNumber, orderHeaderId } = props;
  
    return (
        <> 
            <Link
                data-testid="orderDetailLink"
                onClick={() => {
                    navigate(`/orders/${orderHeaderId}`);
                }}>
                {orderNumber}
            </Link>            
        </>
    );
}
