import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../common/BaseGridState";
import { baseGridInitialState, baseGridReducers } from "./baseGridsSlice";
import { User } from "../../models/admin/User";
import { DataModes } from "../../models/DataModes";
import { BaseApiState } from "../common/BaseApiState";
import { setDataReceivedWithError, setDataRequested } from "./sharedReducers";

export interface UserState extends BaseGridState, BaseApiState<User[]> {
    selectedUser: User;
    dataMode: DataModes;
    hasUnsavedChanges: boolean;
    adminResults: User[];
    adminSearch: string;
}

const initialState: UserState = {
    ...baseGridInitialState,
    dataMode: DataModes.View,
    selectedUser: {} as User,
    hasUnsavedChanges: false,
    /** 
     * Sets the results of any changes to users to refresh the admin grid. This will update the grid in Admin.tsx.
     * @deprecated
     */
    adminResults: [],
    adminSearch: "",
    initialDataLoaded: false,
    isLoading: false,
    isError: false,
    data: [],
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDataRequested,
        setDataReceivedWithError,
        setDataMode: (state, action: PayloadAction<DataModes>) => {
            state.dataMode = action.payload;
        },
        setSelectedUser: (state, action: PayloadAction<User>) => {
            state.selectedUser = action.payload;
        },
        setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.hasUnsavedChanges = action.payload;
        },
        /**
         * Sets the results of any changes to users to refresh the admin grid. This will update the grid in Admin.tsx.
         * @deprecated This is not necessary, we can just dispatch @see {@link dataReceived}
         * @param state 
         * @param action 
         */
        setAdminResults: (state, action: PayloadAction<User[]>) => {
            state.adminResults = action.payload;
        },
        setAdminSearch: (state, action: PayloadAction<string>) => {
            state.adminSearch = action.payload;
        },
        dataReceived: (state, action: PayloadAction<User[]>) => {
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.data = action.payload;
            state.isLoading = false;
        },
    },
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setDataMode,
    setSelectedUser,
    setHasUnsavedChanges,
    setAdminResults,
    setAdminSearch,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
    setDataRequested: dataRequested,
} = userSlice.actions;

export default userSlice.reducer;
