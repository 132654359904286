import AppHeader from "../components/navigation/AppHeader";
import { DynamicAppSideMenu } from "../components/navigation/DynamicAppSideMenu";
import { Box, useTheme, Collapse } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { headerHeight, headerHeightMobile, sideMenuWidth } from "../config/layout";
import { widget } from "../theme/colors";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/store";
import { PropsWithChildren } from "react";

function RootLayout(props: Readonly<PropsWithChildren>) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const bodyLeftMargin = isDesktop ? sideMenuWidth : "inherit";
    const appBarHeight = isDesktop ? headerHeight : headerHeightMobile;
    const leftNavState = useSelector((state: StoreState) => state.leftNavigation);

    return (
        <Box
            height="100vh"
            display="flex"
            sx={{ background: widget.white }}>
            <AppHeader />
            <Box sx={{ paddingTop: appBarHeight, display: "flex", flex: 1 }}>
                {isDesktop && <DynamicAppSideMenu />}
                {!isDesktop && (
                    <Collapse
                        orientation="horizontal"
                        in={leftNavState.isLeftNavigationOpen}>
                        <DynamicAppSideMenu />
                    </Collapse>
                )}
                <Box sx={{ backgroundColor: "white", width: "100%", p: 0, pl: bodyLeftMargin }}>{props.children}</Box>
            </Box>
        </Box>
    );
}

export default RootLayout;
