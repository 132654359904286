import React from "react";
import MuiTooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { IHoverTooltipProps, HoverTooltipText } from "./HoverTooltipData";

/**
 * This component can be used to render a tooltip when the user hovers over an information icon.
 * @param {HoverTooltipText} tooltip - An enum from the HoverTooltipText enumeration. These are predefined tooltips with
 *                  translation. Use HoverTooltipText.Custom and cutomTooltip to render a tooltip
 *                  that is not predefined
 * @param {string} customTooltip - The text to display if a predefined enum is not appropriate
 * @param {string} placement - The location of the hover in relation to the information icon. Default is right.
 * @param {string} margin - The margin of the information icon.
 * @returns {HoverTooltip} HoverTooltip which is a MUI tooltip component containing an information icon
 */

export default function HoverTooltip(props: IHoverTooltipProps) {
    const [open, setOpen] = React.useState(false);

    const toggleTooltip = () => {
        setOpen(!open);
    };

    const { tooltip, placement, customTooltip, margin } = props;
    const { t } = useTranslation();

    // Add a translation here and add an enum item to HoverTooltipText
    const translations = {
        ServiceDelivery: t(
            "This section displays service delivery metrics for your selected Facilities for the past 90 days. The data is refreshed daily."
        ),
        ServiceSummary: t(
            "This section displays a summary of services for your selected Facilities. You can conduct a search or click a tile below for more details."
        ),
        EstimateRequired: t(
            "Selecting 'Yes' means you require a service estimate review and approval prior to services being rendered. Selecting 'No' means the repair services are pre-approved and you do not require a service estimate."
        ),
        ItemsDisinfected: t(
            "Selecting 'Yes' means you are certifying that the equipment being shipped to STERIS for service has been cleaned and disinfected or sterilized, and that all federal, state and local laws and regulations regarding decontamination of medical equipment for shipment have been adhered to per your facility's protocol.\n\nSelecting 'No' means you are stating that the equipment being shipped to STERIS for service has not been cleaned and disinfected or sterilized. Any equipment received in biohazardous packaging in accordance with OSHA and DOT regulations, and/or with this service request will be treated as contaminated equipment, and a disinfection fee will be incurred by your facility."
        ),
        OrderItemsStandardRepair: t("Order total is $0.00 because this service is covered by your contract."),
        EducationInService: t(
            "This page displays education provided by STERIS for your selected Facilities. Note: this data excludes STERIS University activities."
        ),
        RepairCredits: t(
            "This page displays repair credits that are available for your selected Facilities. Contact your Account Manager to apply them to open orders or invoices."
        ),
        AddToAssetInventory: t(
            "This feature allows you to add an asset to your inventory by using the item's model number to populate known data. Contact your Account Manager if the model number is unknown."
        ),
        EquipmentRequestFormTitle: t(
            "This feature allows you to request equipment to use while your item is being repaired. Complete this form request a equipment."
        ),
    };

    let hoverText = "";
    switch (tooltip) {
        case HoverTooltipText.ServiceDelivery:
            hoverText = translations.ServiceDelivery;
            break;
        case HoverTooltipText.ServiceSummary:
            hoverText = translations.ServiceSummary;
            break;
        case HoverTooltipText.EstimateRequired:
            hoverText = translations.EstimateRequired;
            break;
        case HoverTooltipText.ItemsDisinfected:
            hoverText = translations.ItemsDisinfected;
            break;
        case HoverTooltipText.OrderItemsStandardRepair:
            hoverText = translations.OrderItemsStandardRepair;
            break;
        case HoverTooltipText.RepairCredits:
            hoverText = translations.RepairCredits;
            break;
        case HoverTooltipText.EducationInService:
            hoverText = translations.EducationInService;
            break;
        case HoverTooltipText.AddToAssetInventory:
            hoverText = translations.AddToAssetInventory;
            break;
        case HoverTooltipText.EquipmentRequestFormTitle:
            hoverText = translations.EquipmentRequestFormTitle;
            break;
        case HoverTooltipText.Custom:
        default:
            hoverText = t(`${customTooltip}`) ?? "";
            break;
    }

    return (
        <MuiTooltip
            arrow
            data-testid="tooltip-title"
            placement={placement}
            sx={{ color: "font.claret" }}
            title={hoverText}
            onClose={toggleTooltip}
            open={open}
            componentsProps={{
                tooltip: {
                    sx: {
                        color: "font.white",
                        backgroundColor: "font.claret",
                        fontSize: "1em",
                        borderRadius: "5px",
                        "& .MuiTooltip-arrow": {
                            color: "font.claret",
                        },
                        whiteSpace: "pre-wrap",
                    },
                },
            }}>
            <InfoIcon
                data-testid="InfoIcon"
                sx={{ marginLeft: "10px", verticalAlign: "middle", margin }}
                onClick={toggleTooltip}
            />
        </MuiTooltip>
    );
}
