import { LinearProgress, Link, Grid, Box } from "@mui/material";
import {
    GridCellParams,
    GridColDef,
    GridEditInputCell,
    GridRenderCellParams,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { OrderStatuses } from "../../models/orders/OrderStatuses";
import { FormatDate } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { ServiceRequestCell } from "../../component-library/ServiceRequestCell";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid, getRowClassName } from "../../theme/stripedTable";
import { GridCellWithPhotoIcon } from "../../component-library/GridCellWithPhotoIcon";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { setToast } from "../../redux/reducers/toastSlice";
import { requestConnectCarePurchaseOrdersRequired } from "../../services/apiPaths";
import { PoUpdateLevel } from "../../models/orders/PoUpdateLevel";
import { UpsertPurchaseOrderNumberRequestDto } from "../../models/orders/UpsertPurchaseOrderNumberRequestDto";
import { validators } from "../../utils/validators";
import { useFetch } from "../../services/useFetch";
import { setGridColumns, setGridFilter, setGridSort } from "../../redux/reducers/orders/ordersSlice";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";

export default function OrdersGrid() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { post } = useFetch();
  
    const { isLoading, data, gridColumns, initialGridState } = useAppSelector((state) => state.orders);
    const { hasIndirectAccounts } = useAppSelector((state) => state.facility);
  
    const [rows, setRows] = useState<GridValidRowModel[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const apiRef = useGridApiRef();

    const translations = {
        order: t("Order"),
        srn: t("Service Request"),
        customer: t("Customer"),
        dept: t("Department"),
        custPo: t("Purchase Order"),
        status: t("Status"),
        orderDate: t("Ordered"),
        photoFlag: t("Photos"),
        enterPO: t("Add PO (Double Click)"),
        editPO: t("Edit PO"),
        poFailure: t("Purchase Order saving failed."),
        poSuccess: t("Purchase Order successfully saved."),
        enterValidPo: t("Enter a Valid PO."),
    };

    //We need to set row data to a local state because of updates to PO below will update this state.
    useEffect(() => {
        setRows(data);
    }, [data]);

    const ordersColumn: GridColDef[] = [
        {
            field: "orderNumber",
            headerName: translations.order,
            renderHeader: () => <strong>{translations.order}</strong>,
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <Link
                    onClick={() => {
                        navigate(`/orders/${row.headerId}`);
                    }}>
                    {row.orderNumber}
                </Link>
            ),
        },
        {
            field: "photoFlag",
            headerName: translations.photoFlag,
            renderHeader: () => <strong>{translations.photoFlag}</strong>,
            flex: 1,
            minWidth: 120,
            renderCell: (params) =>
                params.row.photoFlag && <GridCellWithPhotoIcon path={`/orders/${params.row.headerId}`} />,
            valueGetter: (params) => {
                return params.row.photoFlag ? "true" : "";
            },
        },
        {
            field: "srn",
            headerName: translations.srn,
            renderHeader: () => <strong>{translations.srn}</strong>,
            minWidth: 170,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <ServiceRequestCell value={params.value} />,
        },
        {
            field: "customerName",
            headerName: translations.customer,
            renderHeader: () => <strong>{translations.customer}</strong>,
            flex: 1.5,
            minWidth: 150,
            renderCell: (params) => (
                <div>
                    {params.row.customerName} [{params.row.accountNumber}]
                </div>
            ),
        },
        {
            field: "department",
            headerName: translations.dept,
            renderHeader: () => <strong>{translations.dept}</strong>,
            flex: 1,
            minWidth: 170,
        },
        {
            field: "customerPo",
            headerName: translations.custPo,
            renderHeader: () => <strong>{translations.custPo}</strong>,
            flex: 1,
            minWidth: 140,
            editable: AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && !hasIndirectAccounts,
            renderCell: (params) => {
                return (
                    !params.row.isIndirectFiltered &&
                    AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && (
                        <Box
                            component="span"
                            sx={{
                                color:
                                    params.row.orderStatus !== OrderStatuses.Closed &&
                                    (!params.row.customerPo || params.row.customerPo === "N/A")
                                        ? "red"
                                        : "inherit",
                            }}>
                            {params.row.orderStatus === OrderStatuses.Closed || params.row.customerPo
                                ? params.row.customerPo
                                : translations.enterPO}
                        </Box>
                    )
                );
            },
            renderEditCell: (params) => {
                return <GridEditInputCell {...params} />;
            },
        },
        {
            field: "orderStatus",
            headerName: translations.status,
            renderHeader: () => <strong>{translations.status}</strong>,
            flex: 1,
            minWidth: 120,
        },
        {
            field: "orderDate",
            headerName: translations.orderDate,
            renderHeader: () => <strong>{translations.orderDate}</strong>,
            flex: 1,
            minWidth: 120,
            valueFormatter: ({ value }) => FormatDate(value),
        },
    ];

    /**
     * If PO is editted inline, update the record, and update the row state. This is quicker than making an api call and rerendering the entire grid.
     * @param newRow - The row that has been updated with PO.
     * @param oldRow - The old row
     * @returns The updated row to save back to state.
     */
    const processRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
        if (newRow.customerPo !== oldRow.customerPo) {
            savePo(newRow.customerPo, newRow.headerId).then((result) => {
                if (result) {
                    const updatedRow = { ...newRow, isNew: false };
                    setRows(rows.map((row) => (row.orderNumber === newRow.orderNumber ? updatedRow : row)));
                    return updatedRow;
                }
            });
        }
        return oldRow;
    };

    /**
     * If the field is PO and hasIndirectAccounts Or Order status is CLOSED, disable the edit action
     * @param param the parameter.
     * @returns {boolean} true if the cell is editable.
     */
    const isEditable = (param: GridCellParams): boolean => {
        if (
            (param.field === "customerPo" && hasIndirectAccounts) ||
            param.row.orderStatus === OrderStatuses.Closed ||
            !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
        ) {
            return false;
        } else {
            return true;
        }
    };

    const savePo = async (newPoNumber: string, orderNumber: number): Promise<boolean> => {
        if (!newPoNumber.trim()) {
            dispatch(
                setToast({
                    toastMessage: translations.enterValidPo,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }

        let validationResult = validators.pONumber(newPoNumber);

        if (!validationResult) {
            dispatch(
                setToast({
                    toastMessage: translations.poFailure,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }

        const requestData: UpsertPurchaseOrderNumberRequestDto = {
            PurchaseOrderNumber: newPoNumber.trim(),
            UpdateLevel: PoUpdateLevel.HEADER,
            ShouldOverwriteNonMatchingHeader: false,
            OrderHeaderId: orderNumber,
        };
        setIsSaving(true);

        const response = (await post<UpsertPurchaseOrderNumberRequestDto>(
            requestConnectCarePurchaseOrdersRequired.UpsertPurchaseOrderNumber,
            requestData,
            true
        )) as Response;

        setIsSaving(false);
        if (response.ok) {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.poSuccess,
                    toastType: ToastTypes.Success,
                })
            );
            return true;
        } else {
            dispatch(
                setToast({
                    showToast: true,
                    toastMessage: translations.poFailure,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }
    };


    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="order-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={initialGridState}
                        getRowId={(row) => row.orderNumber}
                        apiRef={apiRef}
                        processRowUpdate={processRowUpdate}
                        isCellEditable={(param) => isEditable(param)}
                        rows={rows}
                        columns={ordersColumn}
                        paginationModel={{ page: 1, pageSize: 10 }}
                        pageSizeOptions={[10]}
                        density="compact"
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.orders),
                            loadingOverlay: LinearProgress,
                            filterPanel: StyledFilterPanel,
                        }}
                        loading={isLoading || isSaving}
                        columnBuffer={8}
                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                        columnVisibilityModel={gridColumns}
                        onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                        getRowClassName={(params) => getRowClassName(params)}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
