enum pageKeys {
    AppHide = -1,
    AppExternalSignin = 0,
    AppConfirm = 2,
    AppRegister = 3,
    AppInactiveUser = 4,
    AppClose = 7,
}

enum actionMenuKeys {
    NotSelected = 0,
    RequestService = 1,
    Facilities = 2,
    Todo = 3,
    ContactUs = 4,
    Settings = 5,
    Profile = 6,
}

const ccLocalstorage = {
    connectCareAuthToken: "connectCareAuth",
    connectCareAuthExpiration: "connectCareAuthExpiration",
    connectCareTokenClaims: "connectCareTokenClaims",
    connectCareRefreshToken: "connectCareRefreshToken",
    connectCareRefreshTokenExpiration: "connectCareRefreshTokenExpiration",
    connectCareCustomerAccounts: "customerAccounts",
    connectCarePersistFilterInGrid: "connectCarePersistFilterInGrid",
    connectCareCustomerEventId: "connectCareCustomerEventId",
    connectCareUserProfile: "connectCareUserProfile",
    connectCareInvoices: "connectCareInvoices",
    connectCareUserSettings: "connectCareUserSettings",
};

/// Claims that are used to access ASP.NET Core Identity info from the JWT token
const identityClaimTypes = {
    ///<summary>Identifies the user id of the user</summary>
    subscriberClaim: "sub",
    ///<summary>Identifies the username of the user</summary>
    userNameClaim: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
    ///<summary>Identifies the email of the user</summary>
    emailClaim: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
    ///<summary>Identifies the role of the user</summary>
    roleClaim: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
};

const legalData = [
    {
        id: 99,
        value: "contactus",
        label: "Contact Us",
        url: "https://www.steris.com/contact/contact-information",
    },
    {
        id: 100,
        value: "termssale",
        label: "Terms of Sale",
        url: "https://www.steris-ims.com/terms-and-conditions-of-sale/",
    },
    {
        id: 200,
        value: "termsuse",
        label: "Terms of Use",
        url: "https://www.steris.com/about/business/terms",
    },
    {
        id: 300,
        value: "termspurchase",
        label: "Terms of Purchase",
        url: "https://www.steris-ims.com/purchase-order-terms-and-conditions/",
    },
    {
        id: 500,
        value: "privacy",
        label: "Privacy Policy",
        url: "https://www.steris.com/sustainability/corporate-governance/privacy",
    },
    {
        id: 600,
        value: "steris",
        label: "STERIS.com",
        url: "https://www.steris.com",
    },
];

const socialData = [
    {
        id: 100,
        iconImage: "email.png",
        label: "Contact Us",
        url: "https://www.steris.com/contact/contact-information",
    },
    {
        id: 400,
        iconImage: "facebook.png",
        label: "Facebook",
        url: "https://www.facebook.com/steriscorporate",
    },
    {
        id: 500,
        iconImage: "twitter.png",
        label: "Twitter",
        url: "https://twitter.com/STERIS",
    },
    {
        id: 200,
        iconImage: "linkedin.png",
        label: "LinkedIn",
        url: "https://www.linkedin.com/company/steris-corporation/",
    },
    {
        id: 300,
        iconImage: "youtube.png",
        label: "YouTube",
        url: "https://www.youtube.com/channel/UC_ZGVruqQ2fQUNnBqbNZYTg",
    },
    {
        id: 600,
        iconImage: "instagram.svg",
        label: "Instagram",
        url: "https://www.instagram.com/steris.healthcare.usa/",
    },
];

const AppMenuData = [
    {
        id: 100,
        icon: "ConnectCare_fab.png",
        label: "Equipment Services",
        url: process.env.REACT_APP_EQUIPMENT_SERVICES_URL,
    },
    {
        id: 200,
        icon: "Hexawave_alone.png",
        label: "SHOP Parts + Accessories",
        url: "https://shop.steris.com/en/us",
    },
    {
        id: 300,
        icon: "Hexawave_alone.png",
        label: "STERIS.com",
        url: "https://www.steris.com",
    },
    {
        id: 400,
        icon: "Hexawave_alone.png",
        label: "STERIS University",
        url: "https://university.steris.com/",
    },
];

const UserDetailsMenuData = [
    { id: 100, label: "My Access", nav: "/userdetails" },
    { id: 200, label: "My STERIS Team", nav: "/mysteristeam" },
];

const AdminSubMenuData = [
    { id: 100, label: "Users", nav: "/admin/users" },
    { id: 200, label: "Customers", nav: "/admin/customers" },
];

enum pageKeysInvoices {
    InoviceAgingHide = -1,
    InvoiceAgingList = 0,
    InvoiceAgingDetails = 1,
    InvoiceAgingSrnDetails = 2,
}

enum pageKeysEducations {
    EducationHide = -1,
    EducationList = 0,
    EducationDetails = 1,
}

enum pageKeysPurchaseOrdersRequired {
    PurchaseOrdersRequiredHide = -1,
    PurchaseOrdersRequiredList = 0,
}
enum pageKeysItemsInTransitToRepair {
    ItemsInTransitToRepairHide = -1,
    ItemsInTransitToRepairList = 0,
}

enum pageKeysShipmentsFromSteris {
    ShipmentsFromSterisHide = -1,
    ShipmentsFromSterisList = 0,
}

enum pageKeysItemsInRepair {
    ItemsInRepairHide = -1,
    ItemsInRepairList = 0,
}

enum pageKeysApprovalNeeded {
    ApprovalNeededList = 0,
}

enum pageKeysRecommendedForService {
    RecommendedForServiceHide = -1,
    RecommendedForServiceList = 0,
}

enum pageKeysAssets {
    AssetList = 0,
    AssetHistoryTab = 1,
    AssetDevicePM = 2,
    AssetDevicePMInsepctionDetails = 3,
}

enum srnTypeKeys {
    Unknown = 0,
    Serialized = 1,
    Nonserialized = 2,
}

enum educationEventType {
    BusinessReviews = "/events/businessreviews",
    Evaluation = "/events/evaluations",
}

enum EntitlementType {
    PriorityRepair = "PRIORITY REPAIR",
}

export {
    ccLocalstorage,
    legalData,
    socialData,
    AppMenuData,
    pageKeys,
    actionMenuKeys,
    identityClaimTypes,
    pageKeysInvoices,
    pageKeysEducations,
    pageKeysPurchaseOrdersRequired,
    pageKeysItemsInTransitToRepair,
    UserDetailsMenuData,
    pageKeysItemsInRepair,
    pageKeysShipmentsFromSteris,
    pageKeysApprovalNeeded,
    pageKeysRecommendedForService,
    pageKeysAssets,
    AdminSubMenuData,
    srnTypeKeys,
    educationEventType,
    EntitlementType,
};
