/**
 * ConnectCare reports route names and associated reportId
 */
export enum SsrsReport {
    repairHistory = "repairHistory",
    equipmentPlannerAemTray = "equipmentPlannerAemTray",
    equipmentPlannerAemDevices = "equipmentPlannerAemDevices",
    PmEventSummary = "PmEventSummary",
    PmEventDetails = "PmEventDetails",
}
