import { Grid, TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { setSearchValue } from "../../redux/reducers/orders/equipmentRequestReducer";

export const EquipmentRequestSearch = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { searchValue } = useSelector((state: StoreState) => state.equipmentRequest);

    const translations = {
        label: t("Equipment request Search"),
        placeholder: t("Search by Serial, Manufacturer, or Model."),
    };

    return (
        <Grid
            px={1}
            item
            sx={{ width: "100%", ".MuiOutlinedInput-notchedOutline": { border: "0px none" } }}>
            <TextField
                sx={{
                    width: "100%",
                    backgroundColor: "bg.lightBlue",
                    border: "none",
                    font: "lato",
                    fontWeight: "500",
                    fontSize: 13,
                    color: "font.textBlack",
                }}
                placeholder={translations.placeholder}
                size="small"
                id="equipment-request-search"
                autoComplete="off"
                value={searchValue}
                onChange={(e) => dispatch(setSearchValue(e.target.value))}
                inputProps={{
                    "aria-label": `${translations.label}`,
                    "data-testid": "equipment-request-search",
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    );
};
