import { useEffect, useState } from "react";
import { Grid, LinearProgress, Link, Stack, Box } from "@mui/material";
import {
    GridColDef,
    GridRenderCellParams,
    GridCellParams,
    GridEditInputCell,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { ToastTypes } from "../../../models/toast/ToastTypes";
import { setToast } from "../../../redux/reducers/toastSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { FormatDate } from "../../../common/Formatters";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { ServiceRequestCell } from "../../../component-library/ServiceRequestCell";
import { OrderDetailLinkCell } from "../../../component-library/OrderDetailLinkCell";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../../theme/stripedTable";
import { GridCellWithPhotoIcon } from "../../../component-library/GridCellWithPhotoIcon";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../redux/store";
import { OrderStatuses } from "../../../models/orders/OrderStatuses";
import { validators } from "../../../utils/validators";
import { customSortComparators } from "../../../utils/customSortComparators";
import { setGridFilter, setGridSort, setGridColumns } from "../../../redux/reducers/orders/ordersItemsSlice";
import { UpsertPurchaseOrderNumberRequestDto } from "../../../models/orders/UpsertPurchaseOrderNumberRequestDto";
import { requestConnectCarePurchaseOrdersRequired } from "../../../services/apiPaths";
import { PoUpdateLevel } from "../../../models/orders/PoUpdateLevel";
import { claimTypes } from "../../../config/claimTypes";
import { useFetch } from "../../../services/useFetch";
import { AuthLibrary } from "../../../redux/actions/AuthRedux";

export default function OrderItemsGrid() {
    const dispatch = useDispatch();
    const { hasIndirectAccounts } = useSelector((state: StoreState) => state.facility);
    const [rows, setRows] = useState<GridValidRowModel[]>([]);
    const apiRef = useGridApiRef();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { isLoading, data, gridColumns, gridFilter, gridSort, searchString } = useSelector(
        (state: StoreState) => state.orderItems
    );

    const { t } = useTranslation();
    const { post } = useFetch();
    const translations = {
        customer: t("Customer"),
        status: t("Status"),
        srn: t("SRN"),
        serial: t("Serial"),
        manufacturer: t("Manufacturer"),
        model: t("Model"),
        department: t("Department"),
        id: t("Id"),
        order: t("Order"),
        linenumber: t("Line"),
        po: t("PO"),
        poFailure: t("Purchase Order saving failed."),
        enterPO: t("Add PO (Double Click)"),
        poSuccess: t("Purchase Order successfully saved."),
        editPO: t("Edit PO"),
        enterValidPo: t("Enter a Valid PO."),
        ordered: t("Ordered"),
        category: t("Category"),
        reference: t("Reference"),
        noSearchDataFound: t(
            "We found no matches for {{searchString}} for SRN, Serial Number, Order Number, PO Number, Model Or Manufacturer.",
            { searchString: searchString }
        ),
        editYourSearch: t("Please edit your search and try again."),
        noDataFound: t("No Rows"),
        photoFlag: t("Photos"),
    };
    const navigate = useNavigate();

    //We need to set row data to a local state because of updates to PO below will update this state.
    useEffect(() => {
        setRows(data);
    }, [data]);

    const columns: GridColDef[] = [
        {
            field: "orderNumber",
            minWidth: 120,
            flex: 1,
            headerName: translations.order,
            renderHeader: () => translations.order,
            renderCell: (params: GridRenderCellParams) => (
                <OrderDetailLinkCell
                    orderNumber={params.row.orderNumber}
                    orderHeaderId={params.row.id}
                />
            ),
        },
        {
            field: "photoFlag",
            headerName: translations.photoFlag,
            minWidth: 120,
            flex: 1,
            renderHeader: () => <strong>{translations.photoFlag}</strong>,
            renderCell: (params) => params.row.photoFlag && <GridCellWithPhotoIcon path={`/orders/${params.row.id}`} />,
            valueGetter: (params) => {
                return params.row.photoFlag ? "true" : "";
            },
        },
        {
            field: "lineNumber",
            headerName: translations.linenumber,
            minWidth: 100,
            flex: 1,
            type: "number",
            headerAlign: "left",
            renderHeader: () => <strong style={{ textTransform: "uppercase" }}>{translations.linenumber}</strong>,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/orders/${params.row.id}?line=${params.row.lineNumber}`);
                    }}>
                    {params.row.lineNumber}
                </Link>
            ),
        },
        {
            field: "srn",
            minWidth: 120,
            flex: 1,
            headerName: translations.srn,
            renderHeader: () => translations.srn,
            renderCell: (params) => <ServiceRequestCell value={params.value} />,
        },
        {
            field: "serialNumber",
            headerName: translations.serial,
            minWidth: 140,
            flex: 1,
            renderHeader: () => translations.serial,
            renderCell: (params) => (
                <>
                    {!params.row.serialNumber.includes(".") && (
                        <Link
                            onClick={() => {
                                navigate(
                                    `/assets/${params.row.custAccountId}/${
                                        params.row.inventoryItemId
                                    }/${encodeURIComponent(params.row.serialNumber)}`
                                );
                            }}>
                            {params.row.serialNumber}
                        </Link>
                    )}
                    {params.row.serialNumber.includes(".") && params.row.serialNumber}
                </>
            ),
        },
        {
            field: "customerPo",
            headerName: translations.po,
            flex: 1,
            editable: AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && !hasIndirectAccounts,
            minWidth: 140,
            renderHeader: () => <strong>{translations.po}</strong>,
            renderCell: (params) => {
                return (
                    !params.row.isIndirectFiltered && AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) && (
                        <Box
                            component="span"
                            sx={{
                                color:
                                    params.row.status !== OrderStatuses.Closed &&
                                    (params.row.customerPo === "N/A" || !params.row.customerPo)
                                        ? "red"
                                        : "inherit",
                            }}>
                            {params.row.customerPo || params.row.status === OrderStatuses.Closed
                                ? params.row.customerPo
                                : translations.enterPO}
                        </Box>
                    )
                );
            },
            renderEditCell: (params) => {
                return <GridEditInputCell {...params} />;
            },
        },
        {
            field: "ordered",
            headerName: translations.ordered,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.ordered,
            type: "date",
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "status",
            minWidth: 120,
            flex: 1,
            headerName: translations.status,
            renderHeader: () => translations.status,
            hideable: true,
        },
        {
            field: "category",
            headerName: translations.category,
            minWidth: 150,
            flex: 1,
            renderHeader: () => translations.category,
        },
        {
            field: "manufacturer",
            headerName: translations.manufacturer,
            minWidth: 180,
            flex: 2,
            renderHeader: () => translations.manufacturer,
        },
        {
            field: "model",
            headerName: translations.model,
            minWidth: 150,
            flex: 1,
            renderHeader: () => translations.model,
        },
        {
            field: "displayCustomer",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 150,
            flex: 2,
            renderCell: (params) => `${params.row.customerName} [${params.row.accountNumber}]`,
        },
        {
            field: "department",
            headerName: translations.department,
            minWidth: 150,
            flex: 1,
            renderHeader: () => translations.department,
        },
        {
            field: "reference",
            headerName: translations.reference,
            minWidth: 200,
            flex: 2,
            renderHeader: () => translations.reference,
        },
    ];

    const noRowsOverlay = () => {
        if (searchString) {
            return (
                <Stack
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    color="font.darkBlue">
                    {translations.noSearchDataFound}
                    <br />
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        color="font.darkGray">
                        {translations.editYourSearch}
                    </Stack>
                </Stack>
            );
        }
        return (
            <Stack
                height="100%"
                alignItems="center"
                justifyContent="center">
                {translations.noDataFound}
            </Stack>
        );
    };

    /**
     * If PO is edited inline, update the record, and update  row state. This is quicker than making an api call.
     * @param newRow - The row  that has been updated with Purchase order.
     * @param oldRow - The old  row
     * @returns The updated row to save back.
     */
    const processRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
        if (newRow.customerPo !== oldRow.customerPo) {
            savePurchaseOrder(newRow.customerPo, newRow.id).then((result) => {
                if (result) {
                    const updatedItemsRow = { ...newRow, isNew: false };
                    setRows(
                        rows.map((row) =>
                            row.id + "_" + row.lineNumber === newRow.id + "_" + newRow.lineNumber
                                ? updatedItemsRow
                                : row
                        )
                    );
                    return updatedItemsRow;
                }
            });
        }
        return oldRow;
    };

    /**
     * If the field is Order Item PO  hasIndirectAccounts Or Order status is CLOSED, disable the edit action
     * @param param the  parameter.
     * @returns {boolean} true if the cell turns editable.
     */
    const isEditable = (param: GridCellParams): boolean => {
        if (
            (param.field === "customerPo" && hasIndirectAccounts) ||
            param.row.status === OrderStatuses.Closed ||
            !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo])
        ) {
            return false;
        } else {
            return true;
        }
    };

    const savePurchaseOrder = async (newPoNumber: string, orderNumber: number): Promise<boolean> => {
        if (!newPoNumber.trim()) {
            dispatch(
                setToast({
                    showToast: true,
                    toastType: ToastTypes.Error,
                    toastMessage: translations.enterValidPo,
                })
            );
            return false;
        }

        let validationItemsResult = validators.pONumber(newPoNumber);

        if (!validationItemsResult) {
            dispatch(
                setToast({
                    showToast: true,
                    toastType: ToastTypes.Error,
                    toastMessage: translations.poFailure,
                })
            );
            return false;
        }

        const requestPoData: UpsertPurchaseOrderNumberRequestDto = {
            UpdateLevel: PoUpdateLevel.HEADER,
            PurchaseOrderNumber: newPoNumber.trim(),
            OrderHeaderId: orderNumber,
            ShouldOverwriteNonMatchingHeader: false,
        };
        setIsSaving(true);

        const response = (await post<UpsertPurchaseOrderNumberRequestDto>(
            requestConnectCarePurchaseOrdersRequired.UpsertPurchaseOrderNumber,
            requestPoData,
            true
        )) as Response;

        setIsSaving(false);

        if (response.ok) {
            dispatch(
                setToast({
                    toastMessage: translations.poSuccess,
                    toastType: ToastTypes.Success,
                })
            );
            return true;
        } else {
            dispatch(
                setToast({
                    toastMessage: translations.poFailure,
                    toastType: ToastTypes.Error,
                })
            );
            return false;
        }
    };

    const initialGridState: GridInitialStatePro = {
        filter: {
            filterModel: gridFilter,
        },
        sorting: {
            sortModel: gridSort,
        },
    };

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="orderitem-grid-box">
                    <StripedDataGrid
                        initialState={initialGridState}
                        disableRowSelectionOnClick
                        getRowId={(row) => row.id + "_" + row.lineNumber}
                        processRowUpdate={processRowUpdate}
                        isCellEditable={(param) => isEditable(param)}
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.orderItems),
                            loadingOverlay: LinearProgress,
                            noRowsOverlay,
                            filterPanel: StyledFilterPanel,
                        }}
                        slotProps={{
                            columnsPanel: { sx: { textTransform: "capitalize" } },
                        }}
                        loading={isLoading || isSaving}
                        columnBuffer={14}
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        density="compact"
                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                        columnVisibilityModel={gridColumns}
                        onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
}
