import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/store";

export const LoadingSpinner = () => {
    const { showSpinner, spinnerText } = useSelector((state: StoreState) => state.loadingSpinner);

    return (
        <Backdrop
            data-testid="connectcare-loading-spinner"
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showSpinner}>
            <Box
                sx={{ display: "flex" }}
                flexDirection="column"
                justifyContent="center"
                gap={1}
                alignItems="center">
                <Box sx={{ justifyContent: "center" }}>
                    <CircularProgress color="inherit" />
                </Box>
                <Box>
                    <Typography
                        variant="h6"
                        color="inherit">
                        {spinnerText}
                    </Typography>
                </Box>
            </Box>
        </Backdrop>
    );
};
