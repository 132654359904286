import { createSlice } from "@reduxjs/toolkit";
import { BaseGridState } from "../../common/BaseGridState";
import { baseGridReducers, baseGridInitialState } from "../baseGridsSlice";

export interface LoanersDueForReturnState extends BaseGridState {}

const initialState: LoanersDueForReturnState = baseGridInitialState;

export const loanersDueForReturnSlice = createSlice({
    name: "loanersDueForReturn",
    initialState,
    reducers: baseGridReducers,
});

// Action creators are generated for each case reducer function
export const { setGridFilter, setGridSort, setGridColumns } = loanersDueForReturnSlice.actions;

export default loanersDueForReturnSlice.reducer;
