import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Alert, Grid, Link, TableHead } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { KeyboardArrowRight, CheckCircle, Cancel } from "@mui/icons-material";
import { widget } from "../../theme/colors";
import { UserAccessDetailAndClaims } from "../../models/user/UserAccessDetailAndClaims";
import { UserClaimDetail } from "../../models/user/UserClaimDetail";
import { ClaimsValueCell, ClaimsHeadingCell, ClaimsHeadingCellLeft } from "../../theme/theme";

function Row({ row, userRoleClaims }: { row: UserClaimDetail, userRoleClaims: string[] }) {
    const [open, setOpen] = useState(false);
    const element = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const translations = {
        line: t("Line"),
        description: t("Description"),
        unitPrice: t("Unit Price"),
        quantity: t("Qty"),
        extendedPrice: t("Extended Price"),
        view: t("View"),
        feature: t("Feature"),
        facilitySubscription: t("Facility Subscription"),
        myAccess: t("My Access"),

    };

    const claims = [
        t("Create SRN"),
        t("View All Reports"),
        t("View Assets"),
        t("View Education Events"),
        t("View Estimates"),
        t("View Invoices"),
        t("View Loaners"),
        t("View Orders"),
        t("View Profile"),
        t("View Shipments"),
        t("View Technical Support"),
    ];


    return (
        <>
            <TableRow style={{ backgroundColor: open ? "#E8F2FC" : "" }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        data-testid={`expand-collapase-${row.customerAccountId}`}
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{ display: Object.keys(row?.subscriptionClaims).length > 0 ? "" : "none" }}>
                        {open ? (
                            <div ref={element}>
                                <KeyboardArrowDownIcon />
                            </div>
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell
                    component="th"
                    scope="row" sx={{ textTransform: "uppercase" }}>
                    {row.customerName}
                </TableCell>
                <TableCell sx={{ textTransform: "uppercase" }}> {row.subscription} </TableCell>
                <TableCell> {row.sterisContactName}</TableCell>
                <TableCell>
                    <Link 
                        onClick={() => window.location.href = `mailto:${row.sterisContactEmailId}`}
                        sx={{
                            ml: 0.5,
                            fontSize: "14px",
                            color: "blue.connectCare2",
                            textDecoration: "none",
                        }}>
                            {row.sterisContactEmailId}
                    </Link>
                </TableCell>
            </TableRow>
            {open && Object.keys(row.subscriptionClaims) && (
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell 
                        style={{ padding: 0}}
                        colSpan={2}>
                        <Collapse
                            in={open}
                            timeout="auto"
                            unmountOnExit>
                            <Table
                                size="small" 
                                sx={{ margin: 1}}>
                                <TableHead>
                                    <TableRow>
                                        <ClaimsHeadingCellLeft>{translations.feature}</ClaimsHeadingCellLeft>
                                        <ClaimsHeadingCell>{translations.facilitySubscription}</ClaimsHeadingCell>
                                        <ClaimsHeadingCell>{translations.myAccess}</ClaimsHeadingCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {claims.map((key) => {
                                        let subscriptionClaimRecord = row.subscriptionClaims[key];
                                        let myAccess = userRoleClaims?.includes(key);

                                        return (
                                            <TableRow key={`${key}_${row.customerAccountId}`}>
                                                <ClaimsValueCell>{key}</ClaimsValueCell>
                                                <TableCell align="center">
                                                    {subscriptionClaimRecord ?
                                                        <CheckCircle sx={{ color: widget.green, width: "13px", height:"13px" }} />
                                                        :
                                                        <Cancel sx={{ color: widget.red, width: "13px", height: "13px" }} />
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{ verticalAlign: "top !important" }}>
                                                    {myAccess ?
                                                        <CheckCircle sx={{ color: widget.green, width: "13px", height: "13px" }} />
                                                        :
                                                        <Cancel sx={{ color: widget.red, width: "13px", height: "13px" }} />
                                                    }
                                                </TableCell>

                                            </TableRow>
                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

const UserAccessDetailAndClaimsGrid = ({ userAccessDetailAndClaims, }:
    { userAccessDetailAndClaims: UserAccessDetailAndClaims; }) => {

    const { t } = useTranslation();

    const translations = {
        subscription: t("Subscription"),
        customer: t("Customer"),
        accountManager: t("Account Manager"),
        email: t("Email"),
        noDetails: t("No Details found for the customer"),
    };

    return (
        <Box
            bgcolor="bg.light"
            paddingX={4}
            paddingBottom={4}
            data-testid="user-account-details-grid">
            <Grid
                container
                bgcolor="bg.light"
                border={1}
                borderColor="font.gray2"
                borderRadius={1}
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                overflow={"auto"}>


                <Grid item>
                    {userAccessDetailAndClaims && userAccessDetailAndClaims.userAccountDetailsAndClaims && userAccessDetailAndClaims.userAccountDetailsAndClaims.length > 0 ? (
                        <TableContainer>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={1}></TableCell>
                                        <TableCell>{translations.customer}</TableCell>
                                        <TableCell>{translations.subscription}</TableCell>
                                        <TableCell>{translations.accountManager}</TableCell>
                                        <TableCell>{translations.email}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userAccessDetailAndClaims.userAccountDetailsAndClaims?.map((a) => (
                                        <Row
                                            key={a.customerAccountId}
                                            row={a}
                                            userRoleClaims={userAccessDetailAndClaims.userRoleClaims}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            mx={0}
                            mb={2}>
                            <br />
                            <Alert severity="info">{translations.noDetails}</Alert>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserAccessDetailAndClaimsGrid;
