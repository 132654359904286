import { blue, widget } from "../theme/colors";

/*
 * Tracks a list of colors to randomly choose one in case we default.
 */
export const MetricsColor: string[] = [
    blue.connectCare2,
    widget.yellow,
    widget.orange,
    widget.blue,
    widget.green,
    widget.lightgreen,
    blue.steelBlue,
    blue.darker,
    widget.darkgray,
    widget.lightgray,
    blue.pink,
    blue.oasis,
    blue.materialBlue,
    widget.red,
    blue.honeydew,
];
