import { Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ccLocalstorage } from "../config/data";
import { useLocalStorage } from "../hooks/useLocalStorage";

export interface IProps {
    invoiceNumber: string;
    customerTransactionId?: number;
}

/**
 * Displays invoice number cell and Link.
 * @param props {@link IProps}
 */
export function InvoiceCell(props: Readonly<IProps>) {
    const navigate = useNavigate();
    const [, setConnectCareInvoices] = useLocalStorage(ccLocalstorage.connectCareInvoices, "");

    const setInvoiceNumber = (invoice: string) => {
        setConnectCareInvoices(invoice);
    };
           
    return (
        <>
        {!props.customerTransactionId && <>{props.invoiceNumber}</>}
        {props.customerTransactionId && (
            <Box data-testid="invoiceCell">
                {
                    <Link
                        data-testid="invoice-cell-link"
                        onClick={() => {
                            setInvoiceNumber(props.invoiceNumber);
                            navigate(`/invoices/${encodeURIComponent(props.customerTransactionId!)}`);
                        }}>
                        {props.invoiceNumber}
                    </Link>
                }
            </Box>)
        }
        </>
    );
}
