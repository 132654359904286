export const app = {
    "greyBackground": "#E5E5E5"
}

//Consolidate colors
export const blue = {
    "steris": "#6699CC",
    "link": "#017EFA",
    "connectCare": "#5091CD",
    "connectCare2": "#3E8EDE",
    "connectCareHover": "#1060B0",
    "materialBlue": "#2979FF",
    "dark": "#4466BB",
    "darker": "#133674",
    "tabBlue": "#F2F4FD",
    "field": "#F7F8FF",
    "lightGrayishBlue": "#DFE0EB",
    "steelBlue": "#97C1CF",
	"pink": "#FCD7D6",
    "oasis": "#FBF0BC",
    "honeydew": "#DBF2DC",
};
export const text = {
    "textdark": "#222222",
    "textlight": "#FEFEFF",
    "textlightblue": "#F7F8FF",
    "textgray": "#858585",
    "textgray2": "#CCCCCC",
    "textlightgray": "#F5F5F5",
    "textdarkgray": "#8E8E8E",
    "disabled": "#9E9E9E",
    "field": "#F7F8FF",
    "textBlack": "#000000",
    "textWhite": "#FFFFFF",
    "materialGreen": "#29CC97",
    "materialLightRed": "#E45052",
    "cancelHover": "#B7B7B7",
};
export const error = {
    "bg": "#FDEDED",
    "dark": "#CC3322",
    "light": "#F64D41",
};
export const warning = {
    "bg": "#FFF4E5",
    "dark": "#FF6600",
    "light": "#FFB547",
};
export const info = {
    "bg": "#F2F4FD",
    "dark": "#003A70",
    "light": "#64B6F7",
};
export const success = {
    "bg": "#EDF7ED",
    "dark": "#367C3B",
    "light": "#7BC67E",
};
export const widget = {
    "red": "#F25D5B",
    "yellow": "#FFD300",
    "green": "#71CA74",
    "lightgreen": "#76D3B3",
    "white": "#FFFFFF",
    "blue": "#4FB5FE",
    "blackishblue": "#252733",
    "lightblue": "#BECBE1",
    "grey": "#CCCCCC",
    "darkerblue": "#133674",
    "orange": "#FB9B0A",
    "darkgray": "#758390",
    "lightgray": "#BFBAB6",
    "border": "#D9D9D9" /* if a widget has a border */
};

/**
 * Sets the colors for our form widgets
 */
export const formWidget = {
    blue: "#3E8EDE40", //Textfield background color
    white: "white",    //helperText background color
    errorText: "red"   //helperText color
};

/**
 * Sidebar menus 
 */
export const sideBarMenu = {
    backgroundGrey: app.greyBackground,
    black: text.textBlack,
};

/**
 * Status color for education details button
 */
export const detailStatus = {
    "yelloworange": "#FFDA27",
    "mintgreen": "#29CC97",
    "bluegrey": "#606B75"
}
