import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { text } from "../../theme/colors";
import { BaseSyntheticEvent, ChangeEvent, InputHTMLAttributes, useCallback, useState } from "react";
import { EstimateDetail } from "../../models/estimate/EstimateDetail";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationModal from "../../component-library/ConfirmationModal";
import { setToast } from "../../redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

export type IApproveEstimateProps = {
    estimateDetail?: EstimateDetail;
    customerName?: string;
    setShowApproveEstimateModal: React.Dispatch<React.SetStateAction<boolean>>;
    getEstimateDetail: () => Promise<void>;
    customeraccountId: string;
    headerId?: string;
    lineId: string;
    getEstimateHeaderDetails: () => Promise<void>;
    showToast: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ApproveEstimatePopup(props: IApproveEstimateProps) {
    const {
        estimateDetail,
        customerName,
        setShowApproveEstimateModal,
        getEstimateDetail,
        customeraccountId,
        headerId,
        lineId,
        getEstimateHeaderDetails,
        showToast,
    } = props;
    const { t } = useTranslation();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [openPopup, setOpenPopup] = useState(true);
    const [comment, setComment] = useState(estimateDetail?.comments);
    const { put } = useFetch();
    const [disableButton, setDisableButton] = useState(false);
    const [showDeclineConfirmModal, setShowDeclineConfirmModal] = useState(false);
    const dispatch = useDispatch();

    const translations = {
        approve: t("Approve"),
        decline: t("Decline"),
        cancel: t("Cancel"),
        authorizedBy: t("I am authorized by"),
        toApproveEstimate: t("to approve or decline this estimate."),
        comment: t("Add Comment"),
        optional: t("Optional"),
        title: t("Approve Estimate"),
        goBack: t("No, Go Back"),
        yesDecline: t("Yes, Decline"),
        declineContentMessage: t("Are you sure you want to decline this estimate?"),
        failure: t("Estimate details failed to update, please try again."),
    };

    /** When clicking approve, the estimate status should change to approved */
    const handleApproveClick = () => {
        updateEstimateStatus("APPROVED");
    };

    /** When clicking decline, it should ask for confirmation and if user clicks on button "Yes, Decline" then the estimate status should change to rejected*/
    const handleDeclineClick = () => {
        setShowDeclineConfirmModal(true);
    };

    /** When user will click on I authorize checkbox, then only Approve/Decline button will get enable*/
    const handleCheckboxChange = (e: BaseSyntheticEvent) => {
        const { checked } = e.target;
        setIsAuthorized(checked);
    };

    /** When user will enter a comment then this handler will set the latest value to the comment input box.*/
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    /** This will help user to discard the decline modal*/
    const handleDiscard = () => {
        setShowApproveEstimateModal(false);
        setOpenPopup(false);
        setShowDeclineConfirmModal(false);
    };

    const updateEstimateStatus = useCallback(
        async (status: string) => {
            if (!estimateDetail) {
                return;
            }
            setDisableButton(true);
            const uriOrder = `${requestConnectCareOrders.UpdateEstimateStatus}?custAccountId=${customeraccountId}&headerId=${headerId}`;
            let payload: EstimateDetail = {
                ...estimateDetail,
                comments: comment ?? estimateDetail?.comments,
                status: status,
                lineId: Number(lineId),
            };

            const response: EstimateDetail = (await put<EstimateDetail>(uriOrder, payload)) as EstimateDetail;

            if (response.lineId) {
                setOpenPopup(false);
                setShowApproveEstimateModal(false);
                getEstimateHeaderDetails();
                getEstimateDetail();
                showToast(true);
            } else {
                dispatch(
                    setToast({
                        showToast: true,
                        toastType: ToastTypes.Error,
                        toastMessage: translations.failure,
                    })
                );
            }
            setDisableButton(false);
        },
        [
            estimateDetail,
            customeraccountId,
            headerId,
            lineId,
            put,
            comment,
            getEstimateDetail,
            setShowApproveEstimateModal,
            translations.failure,
            getEstimateHeaderDetails,
            dispatch,
            showToast,
        ]
    );

    /** When user click oustide the modal popup or user press ESC key, then popup will be hidden */
    const handleClose = (_event: {}, reason: string) => {
        if ((reason && reason === "backdropClick") || reason === "escapeKeyDown") {
            setOpenPopup(false);
            setShowApproveEstimateModal(false);
        }
    };

    return (
        <Box>
            <Dialog
                id="approve-estimate-modal"
                data-testid="approve-estimate-modal"
                aria-labelledby="approve-estimate-modal"
                open={openPopup}
                fullWidth={true}
                onClose={handleClose}>
                <DialogTitle
                    id="approve-estimate-modal-title"
                    textTransform={"uppercase"}>
                    <Box>
                        <ThumbUpOutlinedIcon sx={{ color: text.textgray2, float: "left" }} />
                        <Typography
                            component="span"
                            variant="subtitle2"
                            sx={{ paddingLeft: 2, color: "font.darkBlue" }}>
                            {translations.title}
                        </Typography>
                        <CloseIcon
                            data-testid="close-popup"
                            sx={{ color: text.textgray2, float: "right", cursor: "pointer" }}
                            onClick={() => {
                                setOpenPopup(false);
                                setShowApproveEstimateModal(false);
                            }}
                        />
                    </Box>
                </DialogTitle>
                <hr style={{ width: "99%" }}></hr>
                <DialogContent>
                    <Box sx={{ display: "flex" }}>
                        {
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputProps={
                                            {
                                                "data-testid": "is-authorized-checkbox",
                                            } as InputHTMLAttributes<HTMLInputElement>
                                        }
                                        checked={isAuthorized}
                                        onChange={(e) => handleCheckboxChange(e)}
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        {translations.authorizedBy + " "}
                                        <b>{customerName?.split("[")[0]}</b> {" " + translations.toApproveEstimate}
                                    </Typography>
                                }
                            />
                        }
                    </Box>
                    <br />
                    <TextField
                        inputProps={{
                            "data-testid": "comments-input",
                        }}
                        onChange={(e) => handleChange(e)}
                        size="small"
                        variant="outlined"
                        rows={3}
                        label={translations.comment}
                        placeholder={translations.optional}
                        multiline
                        fullWidth
                        sx={{ bgcolor: "bg.white" }}
                        InputLabelProps={{ shrink: true }}
                        value={comment}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        justifyContent={"flex-end"}
                        columnSpacing={2}>
                        <Grid item>
                            <Button
                                aria-label={translations.decline}
                                variant="contained"
                                style={{
                                    color: "#FFFFFF",
                                    backgroundColor: text.textgray2,
                                }}
                                size="small"
                                disabled={!isAuthorized || disableButton}
                                onClick={handleDeclineClick}
                                data-testid="decline-button">
                                {translations.decline}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                aria-label={translations.approve}
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={handleApproveClick}
                                disabled={!isAuthorized || disableButton}
                                autoFocus={true}
                                data-testid="approve-button">
                                {translations.approve}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {showDeclineConfirmModal && (
                <ConfirmationModal
                    show={showDeclineConfirmModal}
                    onSecondaryButtonClick={() => handleDiscard()}
                    onPrimaryButtonClick={() => updateEstimateStatus("REJECTED")}
                    title={translations.decline}
                    contentMessage={translations.declineContentMessage}
                    secondaryButtonText={translations.goBack}
                    primaryButtonText={translations.yesDecline}
                />
            )}
        </Box>
    );
}
