import { Box, Button, LinearProgress, Link } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { FormatCurrency } from "../../common/Formatters";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import GridHeader from "../../component-library/GridHeader";
import { ServiceRequestCell } from "../../component-library/ServiceRequestCell";
import { ApprovalNeeded } from "../../models/serviceSummary/ApprovalNeeded";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../common/SiteMap";
import { GridBackgroundColor, theme } from "../../theme/theme";
import { GridCellWithPhotoIcon } from "../../component-library/GridCellWithPhotoIcon";

interface ApprovalNeededProps {
    approvalNeededData: ApprovalNeeded[];
    isLoading: boolean;
    responseStatusCode: number;
}

const ApprovalNeededList = (props: ApprovalNeededProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const translations = {
        id: t("Id"),
        orderNumber: t("Order"),
        srn: t("SRN"),
        lineNumber: t("Line"),
        category: t("Category"),
        description: t("Description"),
        customer: t("Customer"),
        comments: t("Comments"),
        price: t("Price"),
        estimate: t("Estimate"),
        review: t("Review"),
        estimateApprovalNeeded: t("Estimate Approval Needed"),
        last90DaysOfEstimates: t("Last 90 Days of Estimates"),
        photoFlag: t("Photos"),
    };

    const columns: GridColDef[] = [
        {
            field: "estimate",
            headerName: translations.estimate,
            renderHeader: () => <strong>{translations.estimate}</strong>,
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <Button
                    aria-label="review-button"
                    data-testid={`review-button-${params.row.lineId}`}
                    variant="contained"
                    size="small"
                    onClick={() => {
                        navigate(
                            `/orders/estimate/${params.row.lineId}/${params.row.headerId}/${params.row.custAccountId}`
                        );
                    }}>
                    {translations.review}
                </Button>
            ),
            filterable: false,
            disableExport: true,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
        },
        {
            field: "orderNumber",
            headerName: translations.orderNumber,
            renderHeader: () => translations.orderNumber,
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/orders/${params.row.headerId}`);
                    }}>
                    {params.row.orderNumber}
                </Link>
            ),
        },
        {
            field: "photoFlag",
            headerName: translations.photoFlag,
            renderHeader: () => <strong>{translations.photoFlag}</strong>,
            minWidth: 120,
            flex: 1,
            renderCell: (params) =>
                params.row.photoFlag && (
                    <GridCellWithPhotoIcon
                        path={`/orders/estimate/${params.row.lineId}/${params.row.headerId}/${params.row.customerAccountId}`}
                    />
                ),
            valueGetter: (params) => {
                return params.row.photoFlag ? "true" : "";
            },
        },
        {
            field: "srn",
            headerName: translations.srn,
            minWidth: 120,
            flex: 1,
            renderHeader: () => translations.srn,
            renderCell: (params: GridRenderCellParams) => <ServiceRequestCell value={params.value} />,
        },
        {
            field: "lineNumber",
            headerName: translations.lineNumber,
            renderHeader: () => <strong>{translations.lineNumber}</strong>,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/orders/${params.row.headerId}?line=${params.row.lineNumber}`);
                    }}>
                    {params.row.lineNumber}
                </Link>
            ),
            minWidth: 100,
            flex: 1,
        },
        {
            field: "category",
            headerName: translations.category,
            renderHeader: () => <strong>{translations.category}</strong>,
            minWidth: 180,
            flex: 1,
        },
        {
            field: "description",
            headerName: translations.description,
            renderHeader: () => <strong>{translations.description}</strong>,
            minWidth: 350,
            flex: 2,
        },
        {
            field: "customerName",
            headerName: translations.customer,
            renderHeader: () => <strong>{translations.customer}</strong>,
            minWidth: 300,
            sortable: true,
            flex: 2,
        },
        {
            field: "comments",
            headerName: translations.comments,
            renderHeader: () => <strong>{translations.comments}</strong>,
            minWidth: 250,
            flex: 2,
        },
        {
            field: "customerPrice",
            headerName: translations.price,
            renderHeader: () => <strong>{translations.price}</strong>,
            sortable: true,
            minWidth: 120,
            flex: 1,
            align: "right",
            type: "number",
            headerAlign: "center",
            renderCell: (params: GridRenderCellParams) => FormatCurrency(params.row.customerPrice?.replace(",", "")),
            valueGetter: ({ row }) => {
                return row.customerPrice;
            },
            sortComparator: (v1, v2) =>
                Number(v1.toString().replace(/[^0-9.-]+/g, "")) - Number(v2.toString().replace(/[^0-9.-]+/g, "")),
        },
    ];
    return (
        <Box
            style={GridBackgroundColor}
            data-testid="approval-needed">
            <BreadCrumb breadCrumbs={BreadCrumbList.approvalneeded} />
            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.estimateApprovalNeeded}
                    subtitle={translations.last90DaysOfEstimates}
                    hasError={[404, 500].includes(props.responseStatusCode)}></GridHeader>

                <GridAreaLayout
                    py={theme.spacing(2)}
                    data-testid="approval-needed-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        getRowId={(row) => row.lineId}
                        rows={props.approvalNeededData}
                        columns={columns}
                        paginationModel={{ page: 1, pageSize: 10 }}
                        pageSizeOptions={[10]}
                        density="compact"
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.approvalNeeded),
                            loadingOverlay: LinearProgress,
                            filterPanel: StyledFilterPanel,
                        }}
                        slotProps={{
                            columnsPanel: { sx: { textTransform: "capitalize" } },
                        }}
                        loading={props.isLoading}
                        columnBuffer={12}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                        sortingOrder={["desc", "asc"]}
                    />
                </GridAreaLayout>
            </Box>
        </Box>
    );
};

export default ApprovalNeededList;
