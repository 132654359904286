import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ccLocalstorage } from "../../config/data";
import { Box } from "@mui/material";
import { requestTableau } from "../../services/apiPaths";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { TableauVizContent } from "./TableauVizContent";
import { StoreState } from "../../redux/store";
import { useSelector } from "react-redux";

type TableauVizProps = { url: string; relativeUrl: string; reportName: string };

function TableauViz(props: TableauVizProps) {
    const { url, relativeUrl, reportName } = props;
    const { t } = useTranslation();
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);

    const strings = {
        loadingTokenText: t("Loading Security Token..."),
    };

    const [isLoading, setIsLoading] = useState(true);

    const [isError, setIsError] = useState(false);
    const [tableauToken, setTableauToken] = useState("");

    const getTableauSecurityToken = () => {
        setIsLoading(true);

        const ccToken = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const dashboardName = "test"; // get this via param.
        const baseUri = `${requestTableau.GenerateToken}/${dashboardName}`;
        const uriGenerateToken = `${baseUri}?reportUrl=${relativeUrl}&reportName=${reportName}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${ccToken} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        fetch(uriGenerateToken, requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log(data);
                setTableauToken(data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsError(true);
                console.log("xxxxx ERROR: ", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getTableauSecurityToken();
        return () => {};
    }, []);

    return (
        <div data-testid={"tableau-viz-container"}>
            <Box
                minHeight="100vh"
                sx={{ backgroundColor: "#F5F5F5" }}>
                {isLoading ? (
                    <p>{strings.loadingTokenText}</p>
                ) : (
                    <TableauVizContent
                        src={url}
                        token={tableauToken}
                        error={isError}
                    />
                )}
            </Box>
        </div>
    );
}

export default TableauViz;
