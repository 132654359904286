import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FormatCurrency } from "../../common/Formatters";

/**
 * Displays a box at the bottom of a grid that displays props.total formatted as currency 
 * @param props {any} - expects props.total as a number.
 */
function CustomFooterTotal(props: any) {
    const { t } = useTranslation();
    return (
        <Grid container>
            <Grid
                item xs>
                <Box sx={{ padding: "10px", display: "flex", justifyContent: "right !important" }}>
                    <span style={{ color: "gray", textTransform: "uppercase" }}>{t("total")}</span>
                    <span style={{ fontWeight: "600", paddingLeft: "1.5%" }}>{FormatCurrency(props.total)}</span>
                </Box>
            </Grid>
        </Grid>
    );
}

CustomFooterTotal.propTypes = {
    total: PropTypes.number,
};

export { CustomFooterTotal };
