import { Button, CircularProgress } from "@mui/material";

export interface ILoadingButtonProps {
    /**
     * Text on the button
     */
    buttonText: string;
    /**
     * Flag to indicate whether the button should enabled or not
     */
    isButtonDisabled: boolean;
    /**
     * Flag to indicate whether the api call is finished or not
     */
    loading: boolean;
    /**
     * OnClick method triggers the form submission
     */
    handleClick: () => void;
}

export const LoadingButton = ({ buttonText, isButtonDisabled, loading, handleClick }: ILoadingButtonProps) => {
    return (
        <Button
            data-testid={`${buttonText}-button`}
            aria-label={buttonText}
            variant="contained"
            type="submit"
            sx={{
                width: 140,
                borderRadius: 8,
            }}
            disabled={isButtonDisabled || loading}
            onClick={handleClick}>
            {buttonText}
            {loading && (
                <CircularProgress
                    data-testid="circular-progress-spinner"
                    size={24}
                    sx={{
                        marginLeft: 1,
                    }}
                />
            )}
        </Button>
    );
};
