import { Box, Button, ClickAwayListener, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { headerHeight, headerHeightMobile } from "../../config/layout";
import { Close } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import SmsIcon from "@mui/icons-material/Sms";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useNavigate } from "react-router-dom";

type IProp = {
    title: string;
    setSelectedActionMenuKey: any;
};

function AppSideMenuContactUs({ title, setSelectedActionMenuKey }: IProp) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translations = {
        title: t(title),
        howCanWehelp: t("How can we help?"),
        sterisSales: t("To contact your dedicated STERIS Sales or Service Representatives:"),
        viewTeam: t("View Team"),
        contactTech: t("Contact Tech Support"),
        feedback: t("To provide application feedback and suggestions or to request technical support:"),
        speakWithCustomerCare: t("To speak with a Customer Care Specialist, call:"),
        northAmerica: t("North America"),
        northAmericaContactNumber: t("1-800-783-9251"),
        europe: t("Europe"),
        europeContactNumber: t("01332 387 100"),
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const topPosition = matches ? headerHeight : headerHeightMobile;

    const handleCloseContactUsPane = () => {
        setSelectedActionMenuKey(0);
    };

    const onClickViewTeam = () => {
        handleCloseContactUsPane();
        navigate("/mysteristeam");
    };

    const onClickContactTech = () => {
        handleCloseContactUsPane();
        window.open(`${process.env.REACT_APP_COGNITO_FORMS_URL}`, "_blank", "noopener,noreferrer");
    };

    return (
        <Grid
            container
            justifyContent="flex-end"
            zIndex={1}
            position="absolute"
            mt={topPosition}
            data-testid="contact-us-grid">
            <ClickAwayListener onClickAway={handleCloseContactUsPane}>
                <Box
                    px={4}
                    height="104vh"
                    width="26rem"
                    boxShadow={3}
                    display="flex"
                    sx={{ backgroundColor: "bg.lightGray", flexFlow: "column" }}>
                    {/* *** Header and close x *** */}
                    <Box
                        pt={2}
                        display="flex"
                        justifyContent="space-between">
                        <Box>
                            <Typography variant="h4">{translations.title}</Typography>
                        </Box>
                        <Button
                            aria-label={"translations.close"}
                            data-testid="close-button"
                            variant="close"
                            onClick={() => handleCloseContactUsPane()}>
                            <Close />
                        </Button>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <Box
                        data-testid="contact-us-box"
                        overflow="auto">
                        <Grid container>
                            <Grid
                                item
                                container
                                xs={12}>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        py={2}
                                        variant={"body2"}
                                        color={"blue.connectCare2"}>
                                        {translations.howCanWehelp}
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    display="flex"
                                    gap={2}>
                                    <Grid
                                        item
                                        xs={12}>
                                        <Grid
                                            display="flex"
                                            gap={2}
                                            p={2}>
                                            <GroupIcon
                                                fontSize="medium"
                                                sx={{
                                                    fontSize: "2rem",
                                                    color: "blue.connectCare2",
                                                }}
                                            />
                                            <Typography
                                                variant={"body2"}
                                                fontWeight={600}>
                                                {translations.sterisSales}
                                            </Typography>
                                        </Grid>
                                        <Grid pl={8}>
                                            <Button
                                                aria-label={translations.viewTeam}
                                                variant="subMenu"
                                                onClick={() => onClickViewTeam()}>
                                                {translations.viewTeam}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <Grid
                                            display="flex"
                                            gap={2}
                                            p={2}>
                                            <SmsIcon
                                                fontSize="medium"
                                                sx={{
                                                    fontSize: "2rem",
                                                    color: "blue.connectCare2",
                                                }}
                                            />
                                            <Typography
                                                variant={"body2"}
                                                fontWeight={600}>
                                                {translations.feedback}
                                            </Typography>
                                        </Grid>
                                        <Grid pl={8}>
                                            <Button
                                            sx={{width: 'auto'}}
                                                aria-label={translations.contactTech}
                                                data-testid="contact-tech-button"
                                                variant="subMenu"
                                                onClick={() => onClickContactTech()}>
                                                {translations.contactTech}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <Grid
                                            display="flex"
                                            gap={2}
                                            p={2}
                                            pb={0}>
                                            <SupportAgentIcon
                                                fontSize="medium"
                                                sx={{
                                                    fontSize: "2rem",
                                                    color: "blue.connectCare2",
                                                }}
                                            />
                                            <Typography
                                                variant={"body2"}
                                                fontWeight={600}>
                                                {translations.speakWithCustomerCare}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            pl={8}
                                            display="grid"
                                            gap={2}>
                                            <Grid>
                                                <Typography
                                                    variant={"body2"}
                                                    fontWeight={600}>
                                                    {translations.northAmerica}
                                                </Typography>
                                                <Typography
                                                    variant={"body2"}
                                                    fontWeight={600}>
                                                    {translations.northAmericaContactNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid>
                                                <Typography
                                                    variant={"body2"}
                                                    fontWeight={600}>
                                                    {translations.europe}
                                                </Typography>
                                                <Typography
                                                    variant={"body2"}
                                                    fontWeight={600}>
                                                    {translations.europeContactNumber}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ClickAwayListener>
        </Grid>
    );
}

export default AppSideMenuContactUs;
