import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect } from "react";
import { ICustomerContact, IServiceRequestItem } from "../models/serviceRequest/CreateSrnModels";
import { srnTypeKeys } from "../config/data";
import ServiceRequestItemQuantityInput from "../components/serviceRequest/ServiceRequestItemQuantityInput";
import { Delete } from "@mui/icons-material";
import { text, widget } from "../theme/colors";
import { useSelector } from "react-redux";
import { SrnCreationContext } from "../contexts/SrnCreationContext";
import { StoreState } from "../redux/store";
import { SrnAddItemGrid } from "../theme/theme";

export const AddInstrumentItem = () => {
    const { t } = useTranslation();
    const translations = {
        contact: t("Contact"),
        contactRequired: t("Contact is required."),
        delete: t("Delete"),
        department: t("Department"),
        describeProblem: t("Describe Problem"),
        departmentRequired: t("Department is required."),
        enterPoNumber: t("Enter PO Number"),
        item: t("Item"),
        itemRequired: t("Item is required."),
        itemMaxLength: t("Item must be 1 character minimum and 100 characters maximum."),
        maxLengthError: t("Maximum 50 characters."),
        problemMaxLength: t("Maximum 240 Characters."),
        instruments: t("Instruments"),
    };
    const {
        departmentList,
        setDepartmentList,
        contactList,
        setContactList,
        getServiceRequestContacts,
        getServiceRequestDepartments,
        addInstrumentItem,
        isLoading,
        updateItem,
        removeItem,
        singleFacility,
    } = useContext(SrnCreationContext);
    const { currentUser } = useSelector((state: StoreState) => state.auth);
    const productValidationMessage = (productType: string) => {
        if (productType.trim() === "") {
            return translations.itemRequired;
        } else if (productType.length > 100) {
            return translations.itemMaxLength;
        }
    };

    const loadDataNonSerialized = useCallback(async () => {
        const [departments, contacts] = await Promise.all([
            getServiceRequestDepartments(singleFacility?.custAccountId!),
            getServiceRequestContacts(singleFacility?.custAccountId!),
        ]);
        setDepartmentList(departments ?? []);
        setContactList(contacts ?? []);
    }, [
        getServiceRequestContacts,
        getServiceRequestDepartments,
        setContactList,
        setDepartmentList,
        singleFacility?.custAccountId,
    ]);

    useEffect(() => {
        if (!departmentList.length || !contactList.length) {
            loadDataNonSerialized();
        }
    }, [loadDataNonSerialized, contactList.length, departmentList.length]);

    const getContactIdDropdownValue = (currentItem: IServiceRequestItem, index: number) => {
        if (currentItem.contactId) {
            return currentItem.contactId;
        }

        let currentUserOption = contactList.find((x) => x.fullName === currentUser?.displayName);
        if (currentUserOption) {
            let updatedItem = { ...currentItem };
            updatedItem.contactId = currentUserOption.contactId;
            updateItem(updatedItem, index);
            return currentUserOption.contactId;
        }

        return 0;
    };

    return (
        <>
            {!isLoading &&
                addInstrumentItem?.map((item, index) => (
                    <Box
                        sx={{ margin: 3, border: `1px solid ${text["textgray2"]}` }}
                        key={item.id}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography
                                variant="h4"
                                sx={{ paddingLeft: 3, color: !item.productType ? widget.border : "initial" }}>
                                {!item.productType ? translations.instruments : item.productType}
                            </Typography>
                            {!!addInstrumentItem.length && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                    }}>
                                    <Button
                                        aria-label={translations.delete}
                                        data-testid="removeButton"
                                        variant="text"
                                        size="small"
                                        onClick={() => removeItem(index, srnTypeKeys.Nonserialized)}>
                                        <Delete />
                                    </Button>
                                </Box>
                            )}
                        </Box>

                        <Grid
                            container
                            sx={{ paddingLeft: 3 }}>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={1}
                                md={2}
                                sm={6}
                                lg={2}>
                                <ServiceRequestItemQuantityInput
                                    index={index}
                                    item={item}
                                    updateItem={updateItem}
                                />
                            </SrnAddItemGrid>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={2}
                                md={5}
                                sm={6}
                                lg={3}>
                                <FormControl
                                    fullWidth
                                    variant="outlined">
                                    <TextField
                                        inputProps={{ "data-testid": "item-input" }}
                                        label={translations.item}
                                        size="small"
                                        value={item.productType}
                                        error={item.productType === "" || item.productType.length > 100}
                                        helperText={productValidationMessage(item.productType)}
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.productType = e.target.value;
                                            updateItem(updatedItem, index);
                                        }}
                                    />
                                </FormControl>
                            </SrnAddItemGrid>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={3}
                                md={5}
                                sm={6}
                                lg={3}>
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    error={item.custDeptId === 0}>
                                    <InputLabel id="department-label">{translations.department}</InputLabel>
                                    <Select
                                        data-testid="department-dropdown-list"
                                        inputProps={{
                                            "data-testid": "department-dropdown",
                                            "aria-labelledby": "department-label",
                                        }}
                                        labelId="department-label"
                                        value={item?.custDeptId ?? 0}
                                        label={translations.department}
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.custDeptId = +e.target.value;
                                            updateItem(updatedItem, index);
                                        }}>
                                        <MenuItem value={0}>{t("Select a Department")}</MenuItem>
                                        {departmentList?.map((dept) => (
                                            <MenuItem
                                                key={dept.custDeptId}
                                                value={dept.custDeptId}>
                                                {dept.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {item.custDeptId === 0 && (
                                        <FormHelperText>{translations.departmentRequired}</FormHelperText>
                                    )}
                                </FormControl>
                            </SrnAddItemGrid>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={3}
                                md={6}
                                sm={6}
                                lg={4}>
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    error={getContactIdDropdownValue(item, index) === 0}>
                                    <InputLabel id="contact-label">{translations.contact}</InputLabel>
                                    <Select
                                        data-testid="contact-dropdown-list"
                                        inputProps={{
                                            "data-testid": "contact-dropdown",
                                            "aria-labelledby": "contact-label",
                                        }}
                                        labelId="contact-label"
                                        value={getContactIdDropdownValue(item, index)}
                                        label={translations.contact}
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.contactId = +e.target.value;
                                            updateItem(updatedItem, index);
                                        }}>
                                        <MenuItem value={0}>{t("Select a Contact")}</MenuItem>
                                        {contactList?.map((contact: ICustomerContact) => (
                                            <MenuItem
                                                key={contact.contactId}
                                                value={contact.contactId}>
                                                {contact.fullName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {getContactIdDropdownValue(item, index) === 0 && (
                                        <FormHelperText>{translations.contactRequired}</FormHelperText>
                                    )}
                                </FormControl>
                            </SrnAddItemGrid>
                            <SrnAddItemGrid
                                item
                                xs={12}
                                xl={3}
                                md={6}
                                sm={12}
                                lg={12}>
                                <FormControl
                                    fullWidth
                                    variant="outlined">
                                    <TextField
                                        inputProps={{ "data-testid": "poNumber-input" }}
                                        label={translations.enterPoNumber}
                                        size="small"
                                        error={item.poNumber.length > 50}
                                        helperText={item.poNumber.length > 50 ? translations.maxLengthError : ""}
                                        value={item.poNumber}
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.poNumber = e.target.value;
                                            updateItem(updatedItem, index);
                                        }}
                                    />
                                </FormControl>
                            </SrnAddItemGrid>
                        </Grid>

                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                paddingLeft: 3,
                            }}>
                            <Box sx={{ flexGrow: 5, paddingRight: 2, paddingBottom: 3 }}>
                                <FormControl
                                    fullWidth
                                    variant="outlined">
                                    <TextField
                                        inputProps={{
                                            "data-testid": "describe-problem-input",
                                            "aria-label": `${translations.describeProblem}`,
                                        }}
                                        variant="outlined"
                                        label={translations.describeProblem}
                                        size="small"
                                        multiline
                                        rows={2}
                                        error={item.describeProblem.length > 240}
                                        helperText={
                                            item.describeProblem.length > 240 ? translations.problemMaxLength : ""
                                        }
                                        onChange={(e) => {
                                            let updatedItem = { ...item };
                                            updatedItem.describeProblem = e.target.value;
                                            updateItem(updatedItem, index);
                                        }}
                                        value={item?.describeProblem}
                                    />
                                </FormControl>
                                {item.describeProblem.length > 240 && (
                                    <Typography
                                        data-testid={"describe-problem-240-error"}
                                        variant="errorMessage">
                                        {translations.problemMaxLength}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                ))}
        </>
    );
};
