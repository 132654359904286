import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InsightMetricsBarChart } from "../../component-library/InsightMetricsBarChart";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { InsightMetricsWithCount } from "../../component-library/InsightMetricsWithCount";

export default function InRepairInsights() {
    const { t } = useTranslation();
    const translations = {
        category: t("By Category"),
        status: t("By Status"),
        estimateNeedApproval: t("Estimates Need Approval"),
        categorySubTitle: t("Excluding Case Carts and Instrument Trays"),
        remainingCategories: t("items in repair are not assigned a category"),
        errorMessage: t("Data is unavailable or there is not enough data to display this metric."),
        review: t("Review"),
    };
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            data-testid="in-repair-metrics-insights">
            <InsightMetricsWithCount
                title={translations.estimateNeedApproval}
                subtitle={translations.review}
                errorMessage={translations.errorMessage}
                apiUrl={requestConnectCareOrders.ApprovalNeededCount}
                navigateUrl={"/orders/approvalneeded"}
            />
            <InsightMetricsBarChart
                title={translations.status}
                errorMessage={translations.errorMessage}
                apiUrl={requestConnectCareOrders.InRepairMetricsByStatus}
                showAll={false}
                includeUnkownItem={false}
            />
            <InsightMetricsBarChart
                title={translations.category}
                subtitle={translations.categorySubTitle}
                errorMessage={translations.errorMessage}
                remainingMetricsTitle={translations.remainingCategories}
                apiUrl={requestConnectCareOrders.InRepairMetricsByCategory}
                showAll={false}
                includeUnkownItem={false}
            />
        </Grid>
    );
}
