import { UserService } from "../../../services/user/UserService";
import { setLeftNavigation } from "../../reducers/authReducer";
import { showSpinner, hideSpinner } from "../../reducers/loadingSpinnerSlice";
import { RootState } from "../../store";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAccountSubscriptionClaims } from "../../reducers/accountSubscriptionClaimsSlice";
import { Facility } from "../../../models/facility/Facility";
import { ToastTypes } from "../../../models/toast/ToastTypes";
import { FacilityService } from "../../../services/facility/FacilityService";
import { setToast } from "../../reducers/toastSlice";
import { FacilityActionTypes } from "./facilityActionTypes";

const messages = {
    successToastMessage: "Selected facilities have been successfully saved.",
    errorToastMessage: "There was error saving selected facilities.",
    facilityChangeInProgressMessage: "Updating...",
};

export const updateLeftNavigation = createAsyncThunk<any, any, { state: RootState }>(
    FacilityActionTypes.UPDATE_LEFT_NAVIGATION,
    async (_, { dispatch, getState, rejectWithValue }) => {
        dispatch(showSpinner("Left navigation is loading. Please wait."));
        const { facility, auth } = getState();
        const selectedFacilityIds = facility.selectedFacilities.map((facility: any) => facility.custAccountId);
        if (!auth.isAuth || !selectedFacilityIds?.length) {
            return;
        }
        const leftNav = await new UserService().getLeftNavigation(selectedFacilityIds);
        if (leftNav.statusCode === 401) {
            return rejectWithValue(leftNav.statusCode);
        }
        dispatch(setLeftNavigation(leftNav));
        dispatch(hideSpinner());
        return leftNav.hasIndirectAccounts;
    }
);

export const customerAccountClaims = createAsyncThunk<any, any, { state: RootState }>(
    FacilityActionTypes.CUSTOMER_ACCOUNT_CLAIMS,
    async (_, { dispatch, getState }) => {
        const { auth, facility } = getState();
        const selectedFacilityIds = facility.selectedFacilities.map((facility) => facility.custAccountId);
        if (!auth.isAuth || !selectedFacilityIds?.length) {
            return;
        }
        const accountClaims = await new UserService().getAccountSubscriptionClaims(selectedFacilityIds);
        dispatch(setAccountSubscriptionClaims(accountClaims));
    }
);

export const updateAndSyncFacilities = createAsyncThunk<any, any, { state: RootState }>(
    FacilityActionTypes.UPDATE_SYNC_FACILITIES,
    async (selectedFacilities: Facility[], { dispatch }) => {
        dispatch(showSpinner(messages.facilityChangeInProgressMessage));
        const result = await new FacilityService()
            .updateSelectedFacilities(selectedFacilities)
            .then((data: Facility[]) => {
                dispatch(hideSpinner());
                if (data?.length) {
                    dispatch(hideSpinner());
                    dispatch(
                        setToast({
                            toastMessage: messages.successToastMessage,
                            toastType: ToastTypes.Success,
                        })
                    );
                    return data;
                } else {
                    dispatch(
                        setToast({
                            toastMessage: messages.errorToastMessage,
                            toastType: ToastTypes.Error,
                        })
                    );
                }
            })
            .catch(() => {
                dispatch(hideSpinner());
                dispatch(
                    setToast({
                        toastMessage: messages.errorToastMessage,
                        toastType: ToastTypes.Error,
                    })
                );
            });
        return result;
    }
);
