export interface IUserSettings {
    "dashboard"?: {
        "approvalNeeded": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "poRequired": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "recommendedForService": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "loanersDueForReturn": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "inTransitForReport": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "inRepair": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "shipmentsFromSteris": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "trayMaintenanceStatus": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "flexScopeMaintenanceStatus": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "surgicalDeviceMaintenanceStatus": {
            "isOn": boolean,
            "isDisabled": boolean
        },
        "preventiveMaintenanceResults": {
            "isOn": boolean,
            "isDisabled": boolean
        }
    }
}

export const defaultSettings: IUserSettings = {
    "dashboard": {
        "approvalNeeded": {
            "isOn": false,
            "isDisabled": false
        },
        "poRequired": {
            "isOn": false,
            "isDisabled": false
        },
        "recommendedForService": {
            "isOn": false,
            "isDisabled": false
        },
        "loanersDueForReturn": {
            "isOn": false,
            "isDisabled": false
        },
        "inTransitForReport": {
            "isOn": false,
            "isDisabled": false
        },
        "inRepair": {
            "isOn": true,
            "isDisabled": false
        },
        "shipmentsFromSteris": {
            "isOn": false,
            "isDisabled": false
        },
        "trayMaintenanceStatus": {
            "isOn": false,
            "isDisabled": false
        },
        "flexScopeMaintenanceStatus": {
            "isOn": false,
            "isDisabled": false
        },
        "surgicalDeviceMaintenanceStatus": {
            "isOn": false,
            "isDisabled": false
        },
        "preventiveMaintenanceResults": {
            "isOn": false,
            "isDisabled": false
        }
    }
}