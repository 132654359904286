import { ccLocalstorage } from "../../config/data";
import { EditAssetDetail } from "../../models/assets/AssetDetail";
import { AssertDepartmentResponse } from "../../models/assets/AssertDepartmentResponse";
import { requestConnectCareInventory } from "../apiPaths";
import { IAssetService } from "./IAssetService";

export class AssetService implements IAssetService {
  async editAssetDetails(asset: EditAssetDetail): Promise<boolean> {
    const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
    const uri = `${requestConnectCareInventory.AssetDetailEdit}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token} `,
      },
      body: JSON.stringify(asset),
    };

    return await fetch(uri, requestOptions)
      .then((response) => {
        //if success is not the response and response.body is loaded then return response in json format.
        if (!response.ok) {
          return response.json();
        }
        //if success response then return true.
        return true;
      })
      .then((data) => {
        //if response status is not OK then we recieve error details object which ExceptionHandlingMiddleware has thrown i.e "ProblemDetails" as result in response.
        if (data !== true) {
          throw { message: data.detail };
        }
        //if response status is OK then it will not go insexecute above if condition and it will return true.
        return true;
      });
  }

  async getDepartments(
    accountId: number[]
  ): Promise<AssertDepartmentResponse[]> {
    const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
    const uri = `${requestConnectCareInventory.AllDepartment}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token} `,
        SelectedCustAccountIds: accountId.toString(),
      },
    };

    return await fetch(uri, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      .catch(() => {
        throw new Error();
      });
  }

  async getSpecialty(): Promise<string[]> {
    const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
    const uri = `${requestConnectCareInventory.AllSpeciality}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token} `,
      },
    };

    return await fetch(uri, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      .catch(() => {
        throw new Error();
      });
  }
}
