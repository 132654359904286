import { useState, useCallback, useEffect } from "react";
import { ccLocalstorage } from "../../config/data";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { ApprovalNeeded } from "../../models/serviceSummary/ApprovalNeeded";
import ApprovalNeededList from "../../components/serviceSummary/ApprovalNeededList";
import { useTranslation } from "react-i18next";
import { getCustAccountIds } from "../../utils/getCustAccountIds";
import { PageTitles } from "../../common/SiteMap";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

export default function Approvals() {
    const { selectedFacilities } = useSelector((state: StoreState) => state.facility);
    const [approvalNeededData, setApprovalNeededData] = useState<ApprovalNeeded[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responseStatusCode, setResponseStatusCode] = useState<number>(0);
    const { t } = useTranslation();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.approvalneeded)}`,
    };

    const getApprovalNeeded = useCallback(() => {
        setIsLoading(true);
        const token = localStorage.getItem(ccLocalstorage.connectCareAuthToken);
        const uri = `${requestConnectCareOrders.ApprovalNeeded}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `bearer ${token} `,
                SelectedCustAccountIds: getCustAccountIds(selectedFacilities).toString(),
            },
        };

        fetch(uri, requestOptions)
            .then((response) => {
                setResponseStatusCode(response.status);
                return response.status === 200 ? response.json() : [];
            })
            .then((data) => {
                setApprovalNeededData(data);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectedFacilities]);

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    useEffect(() => {
        if (selectedFacilities.length) {
            getApprovalNeeded();
        }
    }, [selectedFacilities, getApprovalNeeded]);

    return (
        <ApprovalNeededList
            approvalNeededData={approvalNeededData}
            isLoading={isLoading}
            responseStatusCode={responseStatusCode}
        />
    );
}
