import { AddCircleOutline, Search } from "@mui/icons-material";
import { Box, Button, Grid, Link, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../services/user/UserService";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { DataModes } from "../../models/DataModes";
import GridHeader from "../../component-library/GridHeader";
import { StyledFilterPanel, GridAreaLayout, StripedDataGrid, getRowClassName } from "../../theme/stripedTable";
import { NoExportToolbar } from "../../common/GridNoExportToolBar";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor } from "../../theme/theme";
import { setToast } from "../../redux/reducers/toastSlice";
import { FormatDate } from "../../common/Formatters";
import { customSortComparators } from "../../utils/customSortComparators";
import {
    setDataMode,
    setGridSort,
    setGridColumns,
    setGridFilter,
    setAdminResults,
    setAdminSearch,
    dataRequested,
    dataReceived,
    dataReceivedWithError,
} from "../../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";

function Admin() {
    const dispatch = useAppDispatch();
    //TODO: Restore the use of data. There's an extra object in memory tracking changes to changes made setAdminResults.
    //We should be working from state.user.data. see User Story 27460: In admin.tsx we do not use the dispatch to data to store and display grid state.
    const { adminSearch, gridColumns, initialGridState, isLoading, adminResults } = useAppSelector(
        (state) => state.user
    );
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const [search, setSearch] = useState("");
    const [noData, setNoData] = useState(false);
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const translations = {
        locked: t("Locked"),
        online: t("Online"),
        firstName: t("First Name"),
        lastName: t("Last Name"),
        email: t("Email"),
        personType: t("Person Type"),
        personStatus: t("Status"),
        lastActive: t("Last Active"),
        pageName: t("Users"),
        userSearch: t("User Search"),
        searchPlaceholder: t("Search by Email, First Name, Last Name, Cust #..."),
        searchButton: t("Search"),
        users: t("Users"),
        addUserButton: t("Add User"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.adminUsers)}`,
        detailPageName: t("Admin"),
        jobTitle: t("Title"),
        createdDate: t("Created"),
        lastLoginDate: t("Last Login"),
        noSearchDataFound: t("We found no matches for {{ adminSearch }} user.", { adminSearch }),
        editYourSearch: t("Please edit your search and try again."),
        errorFromApi: t("There was error while retrieving the User."),
        noRows: t("No Rows"),
    };

    useEffect(() => {
        document.title = translations.documentTitle;
        setSearch(adminSearch);
    }, [adminSearch, translations.documentTitle]);

    /**
     * Sets the search state with the keyword to search by.
     * @param {string} [searchKeyword] The keyword to search by.
     */
    const handleSearch = (searchKeyword: string) => {
        setSearch(searchKeyword);
    };

    /**
     * Gets a list of users by an optional search keyword.
     * @param {string} [searchKeyword] The keyword to search by.
     */
    const getUsers = (searchKeyword: string) => {
        dispatch(
            setToast({
                showToast: false,
            })
        );

        dispatch(dataRequested());
        new UserService()
            .searchUsers(searchKeyword)
            .then((response) => {
                if (!response?.length) {
                    setNoData(true);
                }
                dispatch(dataReceived(response));
                dispatch(setAdminResults(response));
            })
            .catch(() => {
                dispatch(dataReceivedWithError());
                dispatch(
                    setToast({
                        toastMessage: translations.errorFromApi,
                        toastType: ToastTypes.Error,
                    })
                );
            });
    };

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (search) {
            dispatch(setAdminSearch(search));
            getUsers(search);
        }
    };

    const handleAddNew = () => {
        dispatch(setDataMode(DataModes.Create));
        navigate("/admin/userdetails");
    };

    const gridColumnDefs: GridColDef[] = [
        {
            field: "firstName",
            headerName: translations.firstName,
            width: 150,
            renderHeader: () => <strong>{translations.firstName}</strong>,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/admin/userdetails/${params.row.personId}`);
                    }}>
                    {params.row.firstName}
                </Link>
            ),
        },
        {
            field: "lastName",
            headerName: translations.lastName,
            width: 150,
            renderHeader: () => <strong>{translations.lastName}</strong>,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/admin/userdetails/${params.row.personId}`);
                    }}>
                    {params.row.lastName}
                </Link>
            ),
        },
        {
            field: "email",
            headerName: translations.email,
            width: 250,
            renderHeader: () => <strong>{translations.email}</strong>,
        },
        {
            field: "personType",
            headerName: translations.personType,
            width: 200,
            renderHeader: () => <strong>{translations.personType}</strong>,
        },
        {
            field: "personStatus",
            headerName: translations.personStatus,
            width: 150,
            renderHeader: () => <strong>{translations.personStatus}</strong>,
            renderCell: (params) => (
                <Box sx={{ color: params.value === "Inactive" ? "red" : "green" }}>{params.row.personStatus}</Box>
            ),
        },
        //TODO: In user story #25515 discuss where this is coming from steris id? Person.Person? Right now it does not align with
        // {
        //     field: "jobTitle",
        //     headerName: translations.jobTitle,
        //     renderHeader: () => <strong>{translations.jobTitle}</strong>,
        //     width: 150,
        // },
        {
            field: "lastLoginDate",
            headerName: translations.lastLoginDate,
            renderHeader: () => <strong>{translations.lastLoginDate}</strong>,
            width: 120,
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "createdDate",
            headerName: translations.createdDate,
            renderHeader: () => <strong>{translations.createdDate}</strong>,
            width: 120,
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
    ];

    return (
        <Box style={GridBackgroundColor}>
            <BreadCrumb breadCrumbs={BreadCrumbList.adminUsers} />
            <Box p={theme.spacing(2)}>
                <GridHeader
                    title={translations.users}
                    onFormSubmit={handleSubmit}>
                    <Grid
                        item
                        xs={12}
                        md={10}
                        lg={4}
                        sx={{ pr: 4 }}>
                        <TextField
                            label={translations.userSearch}
                            size="small"
                            id="userSearch"
                            autoComplete="off"
                            placeholder={translations.searchPlaceholder}
                            value={search}
                            onChange={(e) => handleSearch(e.target.value)}
                            sx={{ m: 0, width: "100%", mr: 0 }}
                            inputProps={{
                                "aria-label": `${translations.userSearch}`,
                                "data-testid": "admin-search-textfield",
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    {/* Desktop */}
                    {isDesktop && (
                        <>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={5}>
                                <Box sx={{ mr: 4, display: "flex", justifyContent: "left" }}>
                                    <Button
                                        data-testid="searchUserButton"
                                        disabled={!search}
                                        variant="contained"
                                        aria-label={translations.searchButton}
                                        type="submit">
                                        {translations.searchButton}
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={2}>
                                <Button
                                    data-testid="addUserButton"
                                    aria-label={translations.addUserButton}
                                    variant="contained"
                                    onClick={() => {
                                        handleAddNew();
                                    }}>
                                    <AddCircleOutline sx={{ mr: 1 }} />
                                    {translations.addUserButton}
                                </Button>
                            </Grid>
                        </>
                    )}
                    {/* Mobile */}
                    {!isDesktop && (
                        <>
                            <Grid
                                item
                                md={5}
                                xs={12}
                                sx={{ backgroundColor: "" }}>
                                <Box sx={{ mr: 0, display: "flex", justifyContent: "center" }}>
                                    <Button
                                        data-testid="mobileSearchUserButton"
                                        disabled={!search}
                                        variant="contained"
                                        aria-label={translations.searchButton}
                                        type="submit">
                                        {translations.searchButton}
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                sx={{ backgroundColor: "" }}>
                                <Box sx={{ mb: 1, display: "flex", justifyContent: "center" }}>
                                    <Button
                                        data-testid="mobileAddUserButton"
                                        variant="contained"
                                        aria-label={translations.addUserButton}
                                        type="submit">
                                        <AddCircleOutline sx={{ mr: 1 }} />
                                        {translations.addUserButton}
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                    )}
                </GridHeader>

                <Box py={theme.spacing(2)}>
                    <Grid container>
                        <Grid
                            item
                            xs>
                            <GridAreaLayout width={isDesktop ? "calc(100vw - 132px)" : "100vw"}>
                                <StripedDataGrid
                                    disableRowSelectionOnClick
                                    initialState={initialGridState}
                                    getRowId={(row) => row.personId}
                                    loading={isLoading}
                                    rows={adminResults}
                                    columns={gridColumnDefs}
                                    columnBuffer={8}
                                    paginationModel={{ page: 1, pageSize: 10 }}
                                    pageSizeOptions={[10]}
                                    slots={{
                                        toolbar: () => NoExportToolbar(),
                                        loadingOverlay: LinearProgress,
                                        noRowsOverlay: () =>
                                            NoRowsOverlay({
                                                text:
                                                    search && noData
                                                        ? translations.noSearchDataFound
                                                        : translations.noRows,
                                            }),
                                        filterPanel: StyledFilterPanel,
                                    }}
                                    density={"compact"}
                                    onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                                    onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                                    columnVisibilityModel={gridColumns}
                                    onColumnVisibilityModelChange={(columnModel) =>
                                        dispatch(setGridColumns(columnModel))
                                    }
                                    getRowClassName={(params) => getRowClassName(params)}
                                />
                            </GridAreaLayout>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

export default Admin;
