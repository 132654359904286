import { Autocomplete, debounce, TextField, ListItemText, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { SystemItem } from "../../models/assets/SystemItem";

const AssetAutocomplete = ({
    onSelectItem,
    categoryCode = 0,
    error = false,
}: {
    onSelectItem: any;
    categoryCode?: number;
    error?: boolean;
}) => {
    const { t } = useTranslation();
    const [results, setResults] = useState<SystemItem[]>([]);
    const { get } = useFetch();

    const translations = {
        autoCompleteLabel: t("Search Model Number"),
    };

    const handleSearch = async (searchTerm: string) => {
        const res = await get<SystemItem[]>(
            `${requestConnectCareInventory.SearchInventoryItems}?searchString=${searchTerm}&categoryCode=${categoryCode}`
        );
        if (res) {
            setResults(res);
        }
    };

    const onInputChange = (searchTerm: string) => {
        if (searchTerm.length < 3) {
            setResults([]);
            return;
        }
        debouncedSearch(searchTerm);
    };

    const debouncedSearch: (searchTerm: string) => void = debounce(handleSearch, 500);

    return (
        <Autocomplete
            data-testid="asset-autocomplete"
            disablePortal
            fullWidth
            size={"small"}
            options={results}
            getOptionLabel={(option) => `${option.make} ${option.model}`}
            filterOptions={(options) => options}
            onInputChange={(_event, value) => onInputChange(value)}
            onChange={(_event, value) => onSelectItem(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    inputProps={{ ...params.inputProps, "data-testid": "asset-typeahead-input" }}
                    InputLabelProps={{
                        shrink: undefined,
                    }} /* this is a hack due to a change in mui 5.14.2, where InputLabelProps are not compatible with TextField? See #17334
                        ...params.InputLabelProps will duplicate the build error.
                    */
                    label={translations.autoCompleteLabel}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <ListItem
                        {...props}
                        data-testid="select-option">
                        <ListItemText
                            disableTypography
                            primary={`${option.make}`}
                            secondary={
                                <>
                                    <Typography variant="body1">{option.model}</Typography>
                                    <Typography
                                        variant="body2"
                                        color={"font.gray"}>
                                        {option.description}
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                );
            }}
            isOptionEqualToValue={(option, value) => option.inventoryItemId === value.inventoryItemId}
        />
    );
};
export default AssetAutocomplete;
