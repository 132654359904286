import { createSlice } from "@reduxjs/toolkit";
import { EquipmentRequestRecordDto } from "../../../models/orders/EquipmentRequestRecordDto";
import { BaseGridState } from "../../common/BaseGridState";
import { baseGridReducers, baseGridInitialState } from "../baseGridsSlice";

export interface EquipmentRequestState extends BaseGridState {
    searchValue: string;
    rows: EquipmentRequestRecordDto[];
}

const initialState: EquipmentRequestState = {
    ...baseGridInitialState,
    initialGridState: {
        sorting: {
            sortModel: [{ field: "srn", sort: "desc" }],
        },
    },  
    searchValue: "",
    rows: [],
};

export const equipmentRequestReducer = createSlice({
    name: "equipmentRequest",
    initialState,
    reducers: {
        ...baseGridReducers,
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        setRows: (state, action) => {
            state.rows = action.payload;
        },
    },
});

export const { setGridFilter, setGridSort, setGridColumns, setSearchValue, setRows } = equipmentRequestReducer.actions;

export default equipmentRequestReducer.reducer;
