/**
 * Gets the a querystring parameter value.
 * @key {string} the key to search for.
 * @returns {string} the value of the key, if the key is unavailable, will return an empty string.
 */
export default function getQueryStringParam(key: string) {
    const urlParams = new URLSearchParams(window.location.search);
    const keyValue = urlParams.get(key);
    return keyValue ?? "";
};
