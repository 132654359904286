import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { FormatCurrency } from "../../common/Formatters";
import { useTranslation } from "react-i18next";

function CustomFooterTotal(props: any) {
    const { t } = useTranslation();
    return (
        <Grid
            container
            item
            sx={{ borderTop: "1px solid", minHeight: "52px" }}>
            <Grid
                item
                xl={10}
                lg={10}
                md={8}
                sm={8}
                xs={12}
                sx={{ minHeight: "52px", alignItems: "center", justifyContent: "right", display: "flex" }}>
                {t("Item Service Total")}: {FormatCurrency(props.totalPrice)}
            </Grid>
            <Grid
                item
                xl={2}
                lg={2}
                md={4}
                sm={4}
                xs={12}
                sx={{ minHeight: "52px", alignItems: "center", justifyContent: "right", display: "flex" }}>
                <span style={{ paddingRight: "16px" }}>
                    {t("Total Rows")}: {props.totalRows}
                </span>
            </Grid>
        </Grid>
    );
}

CustomFooterTotal.propTypes = {
    total: PropTypes.number,
};

export { CustomFooterTotal };
