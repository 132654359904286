import { useTranslation } from "react-i18next";
import { Box, Grid, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import GridHeader from "../../component-library/GridHeader";
import { FormEvent, useCallback, useEffect } from "react";
import BeyondRepairGrid from "../../components/assets/beyondRepair/BeyondRepairGrid";
import { requestConnectCareInventory } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { BeyondRepair } from "../../models/assets/BeyondRepair";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { GridBackgroundColor } from "../../theme/theme";
import { claimTypes } from "../../config/claimTypes";
import NotAuthorizeDisplay from "../../components/auth/NotAuthorizeDisplay";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import HeaderDateRangePicker from "../../component-library/HeaderDateRangePicker";
import {
    dataReceived,
    dataReceivedWithError,
    dataRequested,
    selectedDateRange,
    setSelectedDateRange,
} from "../../redux/reducers/assets/beyondRepairSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";

export default function BeyondRepairs() {
    const { get } = useFetch();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const { initialDataLoaded, startDate, endDate, isDateRangeValid } = useAppSelector((state) => state.beyondRepair);
    const dispatch = useAppDispatch();
    const dateRange = useAppSelector(selectedDateRange);
    const { selectedFacilities } = useAppSelector((state) => state.facility);

    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.beyondrepair)}`,
        assets: t("Assets"),
        trayMaintenance: t("Tray Maintenance"),
        beyondRepair: t("Beyond Repair"),
        search: t("Search"),
        invalidDate: t("Beyond Repair Date End must be after Beyond Repair Date Start"),
    };

    const hasAccessToBeyondRepair = () => {
        return (
            AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewBeyondRepairDevices) ||
            AuthLibrary.AccountSubscriptionHasClaim(claimTypes.ViewBeyondRepairInstruments)
        );
    };

    const getBeyondRepair = useCallback(async (): Promise<void> => {
        const uri = `${requestConnectCareInventory.BeyondRepair}?from=${startDate}&to=${endDate}`;

        dispatch(dataRequested());
        const response = await get<BeyondRepair[]>(uri, true);
        if (response) {
            dispatch(dataReceived(response));
        } else {
            dispatch(dataReceivedWithError(response));
        }
    }, [startDate, endDate, get, dispatch]);

    useEffect(() => {
        if (selectedFacilities?.length && !initialDataLoaded) {
            getBeyondRepair();
        }
    }, [selectedFacilities, getBeyondRepair, initialDataLoaded]);

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isDateRangeValid) {
            getBeyondRepair();
        }
    };
    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <Box
            style={GridBackgroundColor}
            data-testid="connectcare-beyond-repair"
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            <BreadCrumb breadCrumbs={BreadCrumbList.beyondrepair} />
            {!hasAccessToBeyondRepair() && <NotAuthorizeDisplay />}
            {hasAccessToBeyondRepair() && (
                <Box p={theme.spacing(2)}>
                    <GridHeader
                        title={translations.beyondRepair}
                        onFormSubmit={handleSearchSubmit}>
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <HeaderDateRangePicker
                                dateRange={dateRange}
                                onChange={(date) => dispatch(setSelectedDateRange(date))}></HeaderDateRangePicker>
                        </Grid>
                        <Grid
                            item
                            md="auto"
                            marginY={1}
                            paddingX={1}>
                            <Button
                                data-testid={"br-search-button"}
                                aria-label={translations.search}
                                type="submit"
                                variant="contained"
                                size="small"
                                disabled={!isDateRangeValid}
                                sx={{ marginTop: "5px" }}>
                                {translations.search}
                            </Button>
                        </Grid>
                        {!isDateRangeValid && (
                            <Grid
                                data-testid={"invalid-date-msg"}
                                item
                                md="auto"
                                marginY={1}
                                paddingX={1}>
                                <Typography
                                    pl={2}
                                    variant="body2"
                                    color={"error.dark"}>
                                    {translations.invalidDate}
                                </Typography>
                            </Grid>
                        )}
                    </GridHeader>
                    <Box
                        py={theme.spacing(2)}
                        data-testid="tray-serviced-main">
                        <BeyondRepairGrid />
                    </Box>
                </Box>
            )}
        </Box>
    );
}
