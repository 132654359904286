import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export interface DashboardCardData {
    id: number;
    type: DashboardCardTypes;
    title: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    };
    path: string;
    uri: string;
    value: number;
}

export enum DashboardCardTypes {
    Action = "Action",
    Info = "Info",
}
