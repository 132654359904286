import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGridState } from "../../common/BaseGridState";
import { BaseApiState } from "../../common/BaseApiState";
import { OrderItem } from "../../../models/orders/OrderItem";
import { DateRangeSearch } from "../../../models/IDateRangeSearch";
import { KeywordSearch } from "../../../models/IKeywordSearch";
import { dateRangeUntilNow } from "../../../utils/dateRange";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { baseGridReducers, baseGridInitialState } from "../baseGridsSlice";

import { RootState } from "../../store";
import { setDateRange, setDataRequested, setDataReceivedWithError, setKeywordSearch, setDataLoaded } from "../sharedReducers";

export interface OrderItemsState extends DateRangeSearch, KeywordSearch, BaseGridState, BaseApiState<OrderItem[]> {}

 const initDate = dateRangeUntilNow(30);

const initialState: OrderItemsState = {
    ...baseGridInitialState,
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
    searchString: "",
    startDate: initDate[0]!.format("YYYY-MM-DD"),
    endDate: initDate[1]!.format("YYYY-MM-DD"),
    isDateRangeValid: true,
};

export const orderItemsSlice = createSlice({
    name: "orderItems",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDateRange,
        setDataRequested,
        setDataReceivedWithError,
        setKeywordSearch,
        setDataLoaded,
        dataReceived: (state, action: PayloadAction<OrderItem[]>) => {
            state.data = action.payload;
            state.isLoading = false;
        },
    },
});

export const getStartDate = (state: RootState) => state.orderItems.startDate;
export const getEndDate = (state: RootState) => state.orderItems.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setKeywordSearch: setSearchString,
    setDateRange: setSelectedDateRange,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = orderItemsSlice.actions;

export default orderItemsSlice.reducer;
