import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Alert, Box, Grid, LinearProgress, Link } from "@mui/material";
import { StyledFilterPanel, StripedDataGrid, getRowClassName } from "../../../theme/stripedTable";
import { CustomToolbar } from "../../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../../common/ExportCSVFileName";
import { User } from "../../../models/admin/User";
import { useNavigate } from "react-router-dom";
import { requestConnectCareUsers } from "../../../services/apiPaths";
import { useFetch } from "../../../services/useFetch";
import { NoRowsOverlay } from "../../../component-library/NoRowsOverlay";
import {
    setGridSort,
    setGridColumns,
    setGridFilter,
    dataRequested,
    dataReceived,
    dataReceivedWithError,
} from "../../../redux/reducers/customerAdminSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/useReduxHooks";

export default function CustomerUserList({ customerAccountId }: { customerAccountId: number }) {
    const dispatch = useAppDispatch();
    const { gridColumns, initialGridState, isError, isLoading, data } = useAppSelector(
        (state) => state.customerAdmin
    );

    const navigate = useNavigate();
    const { get } = useFetch();

    const { t } = useTranslation();

    const translations = {
        firstName: t("first name"),
        lastName: t("last name"),
        email: t("email"),
        userRole: t("role"),
        personType: t("person type"),
        noDataFound: t("no rows"),
        apiError: t("System Error: API is not available at this time!"),
    };

    const customerUsersColumn: GridColDef[] = [
        {
            field: "firstName",
            headerName: translations.firstName,
            width: 150,
            renderHeader: () => translations.firstName,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/admin/userdetails/${params.row.personId}`);
                    }}>
                    {params.row.firstName}
                </Link>
            ),
        },
        {
            field: "lastName",
            headerName: translations.lastName,
            width: 150,
            renderHeader: () => translations.lastName,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/admin/userdetails/${params.row.personId}`);
                    }}>
                    {params.row.lastName}
                </Link>
            ),
        },
        {
            field: "email",
            headerName: translations.email,
            width: 250,
            renderHeader: () => translations.email,
        },
        {
            field: "userRole",
            headerName: translations.userRole,
            width: 200,
            renderHeader: () => translations.userRole,
        },
        {
            field: "personType",
            headerName: translations.personType,
            width: 200,
            renderHeader: () => translations.personType,
        },
    ];

    const getUsers = useCallback(
        async (custAccountId: number) => {
            const uri = `${requestConnectCareUsers.GetUsersListByCustomerAccountId}/${custAccountId}`;
            dispatch(dataRequested());
            const response = await get<User[]>(uri, true);
            if (response) {
                dispatch(dataReceived(response));
            } else {
                dispatch(dataReceivedWithError(response));
            }
        },
        [dispatch, get]
    );

    useEffect(() => {
        getUsers(customerAccountId);
    }, [customerAccountId, getUsers]);

    return (
        <Box bgcolor="bg.lightGray">
            <Box
                bgcolor="bg.light"
                data-testid="customer-user-list"
                height="calc(100vh - 260px)">
                {!isLoading && isError && (
                    <Grid
                        data-testid={"customer-api-error-grid"}
                        item
                        xs={12}
                        sx={{ mx: 2, mb: 2 }}>
                        <Alert severity="error">{translations.apiError}</Alert>
                    </Grid>
                )}
                <StripedDataGrid
                    disableRowSelectionOnClick
                    initialState={initialGridState}
                    rows={data}
                    columns={customerUsersColumn}
                    getRowId={(row) => row.personId + "_" + row.userRole}
                    slots={{
                        toolbar: () => CustomToolbar(exportExcelFileName.customerUsers),
                        loadingOverlay: LinearProgress,
                        noRowsOverlay: () => NoRowsOverlay({ text: translations.noDataFound }),
                        filterPanel: StyledFilterPanel,
                    }}
                    slotProps={{
                        columnsPanel: { sx: { textTransform: "capitalize" } },
                    }}
                    loading={isLoading}
                    columnBuffer={5}
                    onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                    onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                    columnVisibilityModel={gridColumns}
                    onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                    getRowClassName={(params) => getRowClassName(params)}
                />
            </Box>
        </Box>
    );
}
