import { createTheme, styled } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import { blue, error, warning, info, success, text, widget } from "./colors";
import { sideMenuWidth } from "../config/layout";
import { Typography, tableCellClasses, Box, ListItem, TableCell, Grid, Link, Stack, Card } from "@mui/material";

declare module "@mui/material/styles" {
    interface Palette {
        steris?: Palette["primary"];
        connectCare?: Palette["primary"];
        connectCare2?: Palette["primary"];
        connectCareHover?: Palette["primary"];
        materialBlue?: Palette["primary"];
        tabBlue?: Palette["primary"];
        dark?: Palette["primary"];
        darker?: Palette["primary"];
        field?: Palette["primary"];
        font?: Palette["primary"];
        textlight?: Palette["primary"];
        textgray?: Palette["primary"];
        disabled?: Palette["primary"];
    }

    interface PaletteOptions {
        blue?: PaletteOptions["primary"];
        connectCare?: PaletteOptions["primary"];
        connectCare2?: PaletteOptions["primary"];
        connectCareHover?: PaletteOptions["primary"];
        materialBlue?: PaletteOptions["primary"];
        tabBlue?: PaletteOptions["primary"];
        dark?: PaletteOptions["primary"];
        darker?: PaletteOptions["primary"];
        field?: PaletteOptions["primary"];
        font?: PaletteOptions["primary"];
        bg?: PaletteOptions["primary"];
        textdark?: PaletteOptions["primary"];
        textlight?: PaletteOptions["primary"];
        textgray?: PaletteOptions["primary"];
        disabled?: PaletteOptions["primary"];
        textBlack?: PaletteOptions["primary"];
        widget?: PaletteOptions["primary"];
        red?: PaletteOptions["primary"];
        yellow?: PaletteOptions["primary"];
        green?: PaletteOptions["primary"];
        white?: PaletteOptions["primary"];
    }

    interface PaletteColorOptions {
        steris?: string;
        link?: string;
        connectCare?: string;
        connectCare2?: string;
        connectCareHover?: string;
        materialBlue?: string;
        tabBlue?: string;
        main?: string;
        light?: string;
        dark?: string;
        darker?: string;
        field?: string;
        bg?: string;
        gray?: string;
        gray2?: string;
        lightGray?: string;
        darkGray?: string;
        lightBlue?: string;
        white?: string;
        disabled?: string;
        claret?: string;
        menu?: string;
        darkBlue?: string;
        lightGrayishBlue?: string;
        materialRed?: string;
        materialYellow?: string;
        textBlack?: string;
        materialLightYellow?: string;
        materialOrange?: string;
        materialGreen?: string;
        red?: string;
        yellow?: string;
        green?: string;
        grey?: string;
        blue?: string;
        blackishblue?: string;
        darkerblue?: string;
        lightblue?: string;
        materialLightRed?: string;
        whiteSmoke?: string;
    }
    interface TypographyVariants {
        resultLink: React.CSSProperties;
        breadcrumb1: React.CSSProperties;
        breadcrumb2: React.CSSProperties;
        muiBreadcrumb: React.CSSProperties;
        activeAssetTab: React.CSSProperties;
        inactiveAssetTab: React.CSSProperties;
        activeTab: React.CSSProperties;
        inActiveTab: React.CSSProperties;
        labelValue: React.CSSProperties;
        labelValueSecondary: React.CSSProperties;
        status: React.CSSProperties;
        detailLabel: React.CSSProperties;
        labelNonBoldCapitalize: React.CSSProperties;
        detailValue: React.CSSProperties;
        title: React.CSSProperties;
        detailValueWithColor: React.CSSProperties;
        errorMessage: React.CSSProperties;
        errorMessageBold: React.CSSProperties;
        underline: React.CSSProperties;
        footerLabel: React.CSSProperties;
        footerValue: React.CSSProperties;
        footerValueBold: React.CSSProperties;
        footerTextField: React.CSSProperties;
        dbcTitle: React.CSSProperties;
        dbcCount: React.CSSProperties;
        dbcView: React.CSSProperties;
        approvalText: React.CSSProperties;
        approvalHours: React.CSSProperties;
        subMenuItem: React.CSSProperties;
        appsHeader: React.CSSProperties;
        headerLabel: React.CSSProperties;
        //move to SRN theme File ??
        SRNh1: React.CSSProperties;
        SRNh2: React.CSSProperties;
        SRNConstructionIcon: React.CSSProperties;
        detailValueTitle: React.CSSProperties;
        detailValueNumber: React.CSSProperties;
        detailValueProblem: React.CSSProperties;
        complete: React.CSSProperties;
        //move to Login theme file ??
        loginH1: React.CSSProperties;
        loginH2: React.CSSProperties;
        loginH3: React.CSSProperties;
        loginH4: React.CSSProperties;
        loginSpan: React.CSSProperties;
        incomplete: React.CSSProperties;
        pending: React.CSSProperties;
        addItem: React.CSSProperties;
        reportTitle: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        resultLink?: React.CSSProperties;
        breadcrumb1?: React.CSSProperties;
        breadcrumb2?: React.CSSProperties;
        muiBreadcrumb?: React.CSSProperties;
        activeAssetTab?: React.CSSProperties;
        inactiveAssetTab?: React.CSSProperties;
        activeTab: React.CSSProperties;
        inActiveTab: React.CSSProperties;
        labelValue?: React.CSSProperties;
        labelValueSecondary?: React.CSSProperties;
        status?: React.CSSProperties;
        detailLabel?: React.CSSProperties;
        labelNonBoldCapitalize?: React.CSSProperties;
        detailValue?: React.CSSProperties;
        title: React.CSSProperties;
        detailValueWithColor?: React.CSSProperties;
        errorMessage?: React.CSSProperties;
        errorMessageBold?: React.CSSProperties;
        underline?: React.CSSProperties;
        footerLabel: React.CSSProperties;
        footerValue: React.CSSProperties;
        footerValueBold: React.CSSProperties;
        footerTextField: React.CSSProperties;
        dbcTitle?: React.CSSProperties;
        dbcCount?: React.CSSProperties;
        dbcView?: React.CSSProperties;
        approvalText?: React.CSSProperties;
        approvalHours?: React.CSSProperties;
        subMenuItem?: React.CSSProperties;
        appsHeader?: React.CSSProperties;
        headerLabel?: React.CSSProperties;
        //move to SRN theme File ??
        SRNh1?: React.CSSProperties;
        SRNh2?: React.CSSProperties;
        SRNConstructionIcon?: React.CSSProperties;
        detailValueTitle?: React.CSSProperties;
        detailValueNumber?: React.CSSProperties;
        detailValueProblem?: React.CSSProperties;
        complete?: React.CSSProperties;
        //move to Login theme file ??
        loginH1?: React.CSSProperties;
        loginH2?: React.CSSProperties;
        loginH3?: React.CSSProperties;
        loginH4?: React.CSSProperties;
        loginSpan?: React.CSSProperties;
        incomplete: React.CSSProperties;
        pending: React.CSSProperties;
        addItem: React.CSSProperties;
        reportTitle: React.CSSProperties;
    }
}
declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        topMenu: true;
        leftMenu: true;
        leftSubMenu: true;
        Submit: true;
        Cancel: true;
        buttonGroup: true;
        close: true;
        gridAction: true;
        secondary: true;
        outlined2: true;
        outlined3: true;
        dashboardCard: true;
        approve: true;
        decline: true;
        dropdown: true;
        help: true;
        subMenu: true;
        equipmentRequest: true;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        resultLink: true;
        breadcrumb1: true;
        breadcrumb2: true;
        muiBreadcrumb: true;
        activeAssetTab: true;
        inactiveAssetTab: true;
        activeTab: true;
        inActiveTab: true;
        labelValue: true;
        labelValueSecondary: true;
        detailLabel: true;
        labelNonBoldCapitalize: true;
        detailValue: true;
        title: true;
        detailValueWithColor: true;
        errorMessage: true;
        errorMessageBold: true;
        underline: true;
        footerLabel: true;
        footerValue: true;
        footerValueBold: true;
        footerTextField: true;
        dbcTitle: true;
        dbcCount: true;
        dbcView: true;
        approvalText: true;
        approvalHours: true;
        subMenuItem: true;
        appsHeader: true;
        headerLabel: true;
        //move to SRN theme file ??
        SRNh1: true;
        SRNh2: true;
        SRNConstructionIcon: true;
        detailValueTitle: true;
        detailValueNumber: true;
        detailValueProblem: true;
        complete: true;
        //move to Login theme file ??
        loginH1: true;
        loginH2: true;
        loginH3: true;
        loginH4: true;
        loginSpan: true;
        incomplete: true;
        pending: true;
        addItem: true;
        reportTitle: true;
    }
}
declare module "@mui/material/Chip" {
    interface ChipPropsVariantOverrides {
        status: true;
    }
}
//consolidate colors ??
export const theme = createTheme({
    palette: {
        blue: {
            steris: blue["steris"],
            link: blue["link"],
            connectCare: blue["connectCare"],
            connectCare2: blue["connectCare2"],
            connectCareHover: blue["connectCareHover"],
            materialBlue: blue["materialBlue"],
            tabBlue: blue["tabBlue"],
            dark: blue["dark"],
            darker: blue["darker"],
            main: blue["steris"],
            field: blue["field"],
            lightGrayishBlue: blue["lightGrayishBlue"],
        },
        font: {
            dark: text["textdark"],
            light: text["textlight"],
            gray: text["textgray"],
            gray2: text["textgray2"],
            lightGray: text["textlightgray"],
            darkGray: text["textdarkgray"],
            lightBlue: text["textlightblue"],
            disabled: text["disabled"],
            field: text["field"],
            white: "#ffffff",
            claret: "#1F3767",
            darkBlue: "#133674",
            materialRed: "#F25D5B",
            materialYellow: "#FFD300",
            textBlack: text["textBlack"],
            materialLightYellow: "#FFD300",
            materialOrange: "#FEA800",
            materialGreen: "#29CC97",
            materialLightRed: "#E45052",
        },
        bg: {
            dark: text["textdark"],
            light: text["textlight"],
            gray: text["textgray"],
            gray2: text["textgray2"],
            lightGray: text["textlightgray"],
            lightBlue: text["textlightblue"],
            disabled: text["disabled"],
            field: text["field"],
            white: "#ffffff",
            whiteSmoke: "#f8f8f8",
        },
        error: {
            bg: error["bg"],
            main: error["dark"],
            dark: error["dark"],
            light: error["light"],
        },
        warning: {
            bg: warning["bg"],
            main: warning["dark"],
            dark: warning["dark"],
            light: warning["light"],
        },
        info: {
            bg: info["bg"],
            main: blue["dark"],
            dark: blue["dark"],
            light: info["light"],
        },
        success: {
            bg: success["bg"],
            main: success["dark"],
            dark: success["dark"],
            light: success["light"],
        },
        widget: {
            red: widget["red"],
            yellow: widget["yellow"],
            green: widget["green"],
            grey: text["textgray2"],
            white: widget["white"],
            blue: widget["blue"],
            blackishblue: widget["blackishblue"],
            darkerblue: widget["darkerblue"],
            lightblue: widget["lightblue"],
        },
    },
    typography: {
        fontFamily: ["Lato, Arial, Sans-Serif", "sans-serif"].join(","),
        h1: {
            fontSize: 24,
            fontWeight: "Bold",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        h2: {
            fontSize: 24,
            fontWeight: "medium",
            letterSpacing: "-0.5px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        h3: {
            fontSize: 36,
            fontWeight: "medium",
            letterSpacing: "0px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        h4: {
            fontSize: 16,
            fontWeight: "medium",
            letterSpacing: "0.25px",
            padding: "10px",
            fontFamily: "Lato, Arial, Sans-Serif",
            textTransform: "uppercase",
        },
        h5: {
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: "10px",
            letterSpacing: "0px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        h6: {
            fontSize: 16,
            fontWeight: "medium",
            letterSpacing: "0.15px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        title: {
            fontSize: 18,
            fontWeight: "bold",
            letterSpacing: "0.15px",
            fontFamily: "Lato, Arial, Sans-Serif",
            color: "black",
        },
        body1: {
            fontSize: 16,
            fontWeight: "regular",
            letterSpacing: "0.15px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        body2: {
            fontSize: 14,
            fontWeight: "medium",
            letterSpacing: "0.15px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: "regular",
            letterSpacing: "0.15px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: "bold",
            letterSpacing: "0.1px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        button: {
            fontSize: 15,
            fontWeight: "medium",
            letterSpacing: "0.46px",
            transform: "All Caps",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        caption: {
            fontSize: 14,
            fontWeight: "regular",
            letterSpacing: "0.4px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        overline: {
            fontSize: 12,
            fontWeight: "regular",
            letterSpacing: "1px",
            transform: "All Caps",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        underline: {
            textDecoration: "underline",
            textUnderlineOffset: "4px",
        },
        detailLabel: {
            fontSize: 12,
            color: text["textdarkgray"],
            fontWeight: "bold",
            textTransform: "uppercase",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        labelNonBoldCapitalize: {
            fontSize: 12,
            color: text["textdarkgray"],
            textTransform: "capitalize",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        detailValue: {
            fontSize: 12,
            fontFamily: "Lato, Arial, Sans-Serif",
            textTransform: "uppercase",
        },
        detailValueWithColor: {
            fontSize: 12,
            fontFamily: "Lato, Arial, Sans-Serif",
            textTransform: "uppercase",
            color: blue.darker,
        },
        footerLabel: {
            display: "inline",
            color: text["textdarkgray"],
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: 500,
        },
        footerValue: {
            display: "inline",
            color: text["textdarkgray"],
            textTransform: "none",
            paddingLeft: "16px",
            fontSize: "12px",
            fontWeight: 500,
        },
        footerValueBold: {
            display: "inline",
            color: text.textBlack,
            textTransform: "none",
            paddingLeft: "16px",
            paddingTop: "4px",
            fontSize: "12px",
            fontWeight: 700,
            overflowWrap: "anywhere",
        },
        footerTextField: {
            display: "inline",
            paddingLeft: "16px",
            fontSize: "12px",
            fontWeight: 500,
        },
        resultLink: {
            textDecoration: "none",
            textDecorationColor: "white",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        breadcrumb1: {
            fontSize: 12,
            fontWeight: "400 !important",
            fontFamily: "Lato, Arial, Sans-Serif",
            letterSpacing: "0.6px",
        },
        breadcrumb2: {
            fontSize: 12,
            fontWeight: "bold !important",
            fontFamily: "Lato, Arial, Sans-Serif",
            letterSpacing: "0.6px",
        },
        muiBreadcrumb: {
            fontSize: 14,
            fontFamily: "Lato, Arial, Sans-Serif",
            letterSpacing: "0.6px",
            color: blue.darker,
        },
        labelValue: {
            fontSize: 12,
            color: "#133674", //Replace HEX with variable
            fontWeight: 500,
        },
        labelValueSecondary: {
            color: "#858585", //Replace HEX with variable
            fontSize: 14,
            fontWeight: "medium",
            letterSpacing: "0.15px",
            fontFamily: "Lato, Arial, Sans-Serif",
        },
        activeAssetTab: {
            fontFamily: "Lato",
            fontSize: "14px",
            textAlign: "center",
            fontWeight: "bold",
            position: "relative",
            top: "-0.3vh",
            color: "#133674", //Replace HEX with variable
        },
        inactiveAssetTab: {
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "400",
            textAlign: "center",
            position: "relative",
            top: "-0.3vh",
        },
        activeTab: {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: "700",
            position: "relative",
            top: "-0.3vh",
            color: text.textWhite,
        },
        inActiveTab: {
            fontFamily: "Lato",
            fontSize: "14px",
            lineHeight: 2,
            fontStyle: "normal",
            textAlign: "center",
            fontWeight: "400",
            position: "relative",
            top: "-0.3vh",
            color: text.textdarkgray,
        },
        errorMessage: {
            fontSize: 11,
            color: error["dark"],
        },
        errorMessageBold: {
            fontSize: 14,
            fontWeight: "bold",
            color: error["dark"],
            paddingBottom: 12,
        },
        dbcTitle: {
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "uppercase",
            float: "left",
            textAlign: "left",
        },
        dbcCount: {
            fontSize: 40,
            fontWeight: "bold",
            float: "left",
            clear: "both",
        },
        dbcView: {
            paddingLeft: "10px",
            paddingBottom: "10px",
            fontSize: "13px",
            float: "left",
            clear: "both",
        },
        approvalText: {
            fontFamily: "Lato",
            fontSize: "12px",
            color: "#000000",
        },
        approvalHours: {
            fontFamily: "Lato",
            fontSize: "12px",
            color: "#C14C4A", //Replace HEX with variable
        },
        subMenuItem: {
            fontSize: 17,
            fontWeight: "500",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        appsHeader: {
            fontWeight: 700,
            color: "#3E8EDE", //Replace HEX with variable
            fontSize: 17,
        },
        headerLabel: {
            fontSize: 14,
            color: "#000000", //Replace HEX with variable
        },
        SRNh1: {
            fontSize: "24px",
            fontWeight: "700",
            textTransform: "uppercase",
            color: "font.darkBlue",
        },
        SRNh2: {
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "uppercase",
            color: text.textgray2,
        },
        SRNConstructionIcon: {
            color: text.textgray2,
        },
        loginH1: {
            fontSize: "40px",
            fontWeight: "bold",
            color: blue.darker,
        },
        loginH2: {
            fontSize: "28px",
            color: blue.darker,
            fontWeight: "bold",
        },
        loginH3: {
            fontSize: "20px",
            fontWeight: "bold",
        },
        loginH4: {},
        loginSpan: {
            color: blue.dark,
        },
        detailValueTitle: {
            fontSize: "14px",
            fontWeight: "bold",
        },
        detailValueNumber: {
            fontSize: "14px",
            fontWeight: "bold",
            color: blue.dark,
        },
        detailValueProblem: {
            fontSize: "12px",
            fontWeight: "bold",
        },
        complete: {
            fontSize: "16px",
            color: text.materialGreen,
            textTransform: "uppercase",
            display: "flex",
            flexWrap: "initial",
        },
        incomplete: {
            fontSize: "16px",
            color: widget.red,
            textTransform: "uppercase",
            display: "flex",
            flexWrap: "initial",
        },
        pending: {
            fontSize: "16px",
            color: widget.yellow,
            textTransform: "uppercase",
            display: "flex",
            flexWrap: "initial",
        },
        addItem: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            color: blue.connectCare2,
            fontSize: 12,
            fontFamily: "Lato, Arial, Sans-Serif",
            textTransform: "uppercase",
            textAlign: "center",
        },
        reportTitle: {
            fontSize: 24,
            fontWeight: "Bold",
            fontFamily: "Lato, Arial, Sans-Serif",
            color: blue.darker,
            marginBottom: 2
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    resultLink: "inherit",
                    detailLabel: "p",
                    labelNonBoldCapitalize: "p",
                    detailValueWithColor: "p",
                    detailValue: "p",
                    title: "p",
                    errorMessage: "p",
                    errorMessageBold: "p",
                },
            },
        },

        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                    "& .MuiDataGrid-toolbarContainer > button": {
                        color: "#017EFA", //Replace HEX with variable
                        paddingLeft: 0,
                    },
                    "& .MuiDataGrid-cell > div > a:not(.MuiButton-root)": {
                        textDecoration: "none",
                        fontSize: "12px !important",
                        fontFamily: "Lato",
                        fontWeight: "400",
                        color: "rgb(1, 126, 250)", //Replace rgb with variable
                    },
                    "& .MuiDataGrid-cell > div > button > a:not(.MuiButton-root)": {
                        textDecoration: "none",
                        fontSize: "12px !important",
                        fontFamily: "Lato",
                        fontWeight: "400",
                        color: "rgb(1, 126, 250)", //Replace rgb with variable
                    },
                    "& .MuiDataGrid-cell > a:not(.MuiButton-root)": {
                        textDecoration: "none",
                        fontSize: "12px !important",
                        fontFamily: "Lato",
                        fontWeight: "400",
                        color: "rgb(1, 126, 250)", //Replace rgb with variable
                    },
                },
                cell: {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    paddingLeft: "20px",
                    fontSize: "12px",
                    fontFamily: "Lato",
                    color: "#000000", //Replace HEX with variable
                    fontWeight: 400,
                },
                columnHeader: {
                    paddingLeft: "20px",
                    color: "#000000", //Replace HEX with variable
                    fontSize: "12px",
                    fontFamily: "Lato",
                    fontWeight: "bold",
                    position: "sticky",
                    borderBottom: "1px solid #8E8E8E", //Replace HEX with variable
                },
                columnHeaderTitleContainer: {
                    textTransform: "uppercase",
                    fontWeight: "bold",
                },
                columnSeparator: {
                    visibility: "visible",
                },
                toolbarContainer: {
                    paddingLeft: "20px",
                    marginBottom: 2,
                },
                footerContainer: {
                    height: "65px",
                },
                virtualScroller: {
                    marginTop: "0 !important",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: "45px",
                    maxHeight: "41px",
                    background: text.textgray2,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                    color: text.textdarkgray,
                    minWidth: "175px",
                    border: `1px solid ${widget.grey}`,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid #F2F4FD",
                    paddingTop: "16px",
                    minHeight: "45px",
                    paddingLeft: "5.44vh",
                },
            },
            variants: [
                {
                    props: { variant: "fullWidth" },
                    style: {
                        width: "95%",
                    },
                },
            ],
        },
        MuiChip: {
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        color: "#4466BB", //Replace HEX with variable
                        fontSize: "12px",
                        fontWeight: "500",
                        mr: 1,
                        mb: 1,
                    },
                },
                {
                    props: { variant: "status" },
                    style: {
                        color: text["textlight"],
                        textTransform: "uppercase",
                        fontSize: "12px",
                        fontWeight: "600",
                        borderRadius: "0px",
                        padding: "0px 5px",
                    },
                },
            ],
        },
        MuiSwitch: {
            styleOverrides: {},
        },

        MuiButton: {
            variants: [
                {
                    props: { variant: "Cancel" },
                    style: {
                        backgroundColor: text["textgray"],
                        color: "#ffffff", //Replace HEX with variable
                        paddingLeft: 40,
                        paddingRight: 40,
                        fontSize: 12,
                        borderRadius: 20,
                        height: 30,
                        "&:hover": {
                            backgroundColor: text["cancelHover"],
                        },
                    },
                },
                {
                    props: { variant: "topMenu" },
                    style: {
                        margin: 0,
                        padding: 0,
                        borderRadius: 0,
                        width: "unset",
                        height: "100%",
                        boxShadow: "none",
                    },
                },
                {
                    props: { variant: "leftMenu" },
                    style: {
                        borderRadius: 0,
                        width: sideMenuWidth,
                        height: "unset",
                        boxShadow: "none",
                        padding: "12px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                    },
                },
                {
                    props: { variant: "leftSubMenu" },
                    style: {
                        borderRadius: 0,
                        width: sideMenuWidth,
                        height: "unset",
                        boxShadow: "none",
                        paddingLeft: "20px",
                        paddingRight: "0",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                    },
                },

                {
                    props: { variant: "contained" },
                    style: {
                        fontSize: 12,
                        borderRadius: 20,
                        px: 8,
                        mr: 4,
                        height: 30,
                        width: 130,
                        padding: "4px",
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        paddingLeft: 40,
                        paddingRight: 40,
                        fontSize: 12,
                        borderRadius: 20,
                        px: 8,
                        mr: 4,
                        height: 30,
                    },
                },
                {
                    props: { variant: "gridAction" },
                    style: {
                        backgroundColor: blue["connectCare2"],
                        color: text["textlight"],
                        paddingLeft: 20,
                        paddingRight: 20,
                        fontSize: 12,
                        height: 25,
                    },
                },
                {
                    props: { variant: "dashboardCard" },
                    style: {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderRadius: "12px",
                        height: "100%",
                        boxShadow: "3px",
                        padding: 0,
                        width: "100%",
                    },
                },
                {
                    props: { variant: "close" },
                    style: {
                        padding: 0,
                        borderRadius: 0,
                        width: "20px",
                        height: "30px",
                        minWidth: "unset",
                        "&:hover": {
                            backgroundColor: "unset",
                        },
                    },
                },
                {
                    props: { variant: "secondary" },
                    style: {
                        backgroundColor: text["textlightgray"],
                        color: text["textdark"],
                        paddingLeft: 40,
                        paddingRight: 40,
                        fontSize: 12,
                        borderRadius: 20,
                        px: 8,
                        mr: 4,
                        height: 30,
                    },
                },
                {
                    props: { variant: "subMenu" },
                    style: {
                        backgroundColor: blue.connectCare2,
                        color: text["textlight"],
                        "&:hover": {
                            backgroundColor: blue.connectCareHover,
                        },
                        fontSize: 13,
                        borderRadius: 20,
                        width: "40%",
                    },
                },
                {
                    props: { variant: "outlined2" },
                    style: {
                        color: widget["blue"],
                        fontSize: 12,
                        fontWeight: "500",
                        width: "65%",
                        marginTop: "16px",
                        border: "1px solid",
                        borderColor: widget["blue"],
                        paddingLeft: 40,
                        paddingRight: 40,
                        borderRadius: 20,
                        px: 8,
                        mr: 4,
                        height: 30,
                    },
                },
                {
                    props: { variant: "outlined3" },
                    style: {
                        color: blue.connectCare2,
                        fontSize: 12,
                        fontWeight: "700",
                        marginTop: "16px",
                        border: "1.5px solid",
                        borderColor: widget["blue"],
                        padding: 10,
                        borderRadius: 20,
                        px: 8,
                        mr: 4,
                        height: 25,
                    },
                },
                {
                    props: { variant: "approve" },
                    style: {
                        fontSize: "12px",
                        color: widget.white,
                        minWidth: "150px",
                        height: "25px",
                        marginTop: "5px",
                    },
                },
                {
                    props: { variant: "decline" },
                    style: {
                        fontSize: "12px",
                        color: blue.connectCare2,
                        minWidth: "150px",
                        height: "25px",
                        marginTop: "5px",
                        backgroundColor: widget.white,
                    },
                },
                {
                    props: { variant: "dropdown" },
                    style: {
                        backgroundColor: blue.connectCare2,
                        color: text["textlight"],
                        fontSize: 12,
                        borderRadius: 20,
                        px: 8,
                        height: 30,
                        "&:hover": {
                            backgroundColor: blue.connectCareHover,
                        },
                        "&:disabled": {
                            backgroundColor: text["textlightgray"],
                            color: text["textdarkgray"],
                        },
                    },
                },
                {
                    props: { variant: "help" },
                    style: {
                        textTransform: "capitalize",
                        color: "#1976d2", //Replace HEX with variable
                    },
                },
                {
                    props: { variant: "equipmentRequest" },
                    style: {
                        color: widget["blue"],
                        fontSize: 12,
                        fontWeight: "500",
                        border: "1px solid",
                        borderColor: widget["blue"],
                        borderRadius: 20,
                    },
                },
            ],
            styleOverrides: {
                root: {
                    borderRadius: 50,
                },
            },
        },
        MuiCard: {
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        borderRadius: "5px",
                        boxShadow: "1",
                    },
                },
                {
                    props: { variant: "elevation" },
                    style: {
                        width: "340px",
                        height: "388px",
                        border: "1px solid #D9D9D9", //Replace HEX with variable
                        paddingTop: "40px",
                        paddingBottom: "127px",
                        paddingLeft: "27px",
                        marginBottom: "20px",
                        paddingRight: "43px",
                        marginRight: "25px",
                    },
                },
            ],
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: "500 !important",
                    fontFamily: "Lato",
                    color: "blue.connectCare2",
                    cursor: "pointer",
                },
            },
        },
        MuiTextField: {
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        borderColor: text["field"],
                        borderRadius: "4px",
                        backgroundColor: text["field"],
                    },
                },
            ],
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // this is styles for the new variants
                    "&.outlined-white-bg": {
                        backgroundColor: "white",
                    },
                },
            },
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        borderColor: text["field"],
                        backgroundColor: text["field"],
                        borderRadius: "4px",
                    },
                },
            ],
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontWeight: "normal",
                    [`&.${tableCellClasses.head}`]: {
                        fontWeight: "700",
                        textTransform: "uppercase",
                    },
                    [`&.${tableCellClasses.body}`]: {
                        color: "#000000", //Replace HEX with variable
                        fontSize: "13px",
                        fontFamily: "Lato",
                        fontWeight: "500",
                    },
                },
            },
            variants: [
                {
                    props: { variant: "head" },
                    style: {
                        fontWeight: "700",
                        backgroundColor: widget["white"],
                        textTransform: "uppercase",
                    },
                },
                {
                    props: { variant: "body" },
                    style: {
                        color: text["textBlack"],
                        fontSize: "13px",
                        fontFamily: "Lato",
                        fontWeight: "500",
                    },
                },
            ],
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:last-child td, &:last-child th": { border: 0 },
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
    },
});
//utilize variants with existing components
export const SectionTitle = styled(Typography)({
    color: blue.darker,
    fontFamily: "Lato",
    fontSize: 12,
    fontWeight: 800,
    textTransform: "uppercase",
});
//utilize variants with existing components
export const SectionField = styled(Typography)({
    color: "#8E8E8E", //Replace HEX with variable
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
});
//utilize variants with existing components
export const SectionFieldValue = styled(Typography)({
    color: "#000000", //Replace HEX with variable
    fontStyle: "Lato",
    paddingTop: "10px",
    fontSize: 12,
    fontWeight: 400,
    textTransform: "uppercase",
});
//utilize variants with existing components
export const PastDueCellBox = styled(Box)({
    display: "inline",
    color: "white",
    backgroundColor: "#E45052", //Replace HEX with variable
    border: "none",
    padding: "2px"
});
//utilize variants with existing components
export const MuiListItem = styled(ListItem)({
    "&:hover": { backgroundColor: "#F4F8FD" }, //Replace HEX with variable
});

export const GridBackgroundColor = {
    backgroundColor: widget["white"],
};

/**
 * Sets the colors for checkCircle
 */
export const styledCheckCircle = {
    color: widget["green"],
};

export const StyledTableCell = styled(TableCell)({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: "none",
        textTransform: "uppercase",
        color: "#000000",
        fontWeight: "bold",
        textAlign: "right",
        paddingBottom: "0px",
        paddingTop: "0px",
    },
});

export const StyledTableCellGray = styled(TableCell)({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: "none",
        textTransform: "uppercase",
        color: "#8E8E8E",
    },
});

export const StyledTableCellBlack = styled(TableCell)({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: "none",
        textTransform: "uppercase",
        color: "#000000",
        fontWeight: "bold",
    },
});

export const ClaimsHeadingCell = styled(TableCell)({
    [`&.${tableCellClasses.head}`]: {
        textTransform: "uppercase",
        fontSize: "10px",
        textAlign: "center",
    },
});

export const ClaimsHeadingCellLeft = styled(TableCell)({
    [`&.${tableCellClasses.head}`]: {
        textTransform: "uppercase",
        fontSize: "10px",
        textAlign: "left",
    },
});

export const ClaimsValueCell = styled(TableCell)({
    [`&.${tableCellClasses.body}`]: {
        fontSize: "10px",
        align: "center",
    },
});

//utilize variants with existing components
export const SRNCustomerDetails = styled(Box)({
    display: "flex",
    gap: "0px 60px",
});

export const StyledBoxAlert = styled(Box)({
    backgroundColor: "#FBF1D5",
    fontSize: "12px",
    fontFamily: "Lato",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "4vh",
    paddingLeft: 1,
});

export const StyledAppSideMenuBox = styled(Box)({
    "&:hover": { backgroundColor: "#3E8EDE", color: "white" },
    cursor: "pointer",
    background: "",
    p: 2,
    color: "#1976d2",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 15,
});

/**
 * Specifically for Insight Metrics Grid and Insight Link Button
 */
export const InsightGrid = styled(Grid)({
    width: "100%",
    textAlign: "right",
    justifyContent: "end",
    alignItems: "end",
    display: "flex",
});

export const InsightLink = styled(Link)({
    textDecoration: "none",
    color: "blue.connectCare2",
    fontSize: "12px",
    paddingBottom: "2px",
});

/**
 * Specifically for Create SRN side menu dialog box
 */
export const ForwardBackwardArrow = styled(Link)({
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
});

export const SrnMenuItemSubGrid = styled(Grid)({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    border: "2px solid rgba(0, 0, 0, 0.12)",
});

export const SubtitleBox = styled(Box)({
    width: "30%",
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingTop: 2,
    flexWrap: "wrap",
});

export const AddButtonBox = styled(Box)({
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "normal",
    paddingRight: 2,
});

export const FacilityModalHeader = styled(Box)({
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingTop: 2,
    flexWrap: "wrap",
});

export const SrnMenuItemsIcon = styled(Box)({
    width: "7%",
    flexGrow: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

export const SrnMenuHeader = styled(Box)({
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
});

export const SrnMenuArrowButton = styled(Box)({
    borderRight: "2px solid rgba(0, 0, 0, 0.12)",
    zIndex: 50,
    backgroundColor: widget["white"],
});

export const PieChartStyledText = styled("text")({
    fill: "#133674",
    textAnchor: "end",
    dominantBaseline: "central",
    fontSize: 18,
    fontWeight: 600,
});

/** Used for orders that are missing photos */
export const MissingPhotoStack = styled(Stack)({
    padding: "50px 0",
    alignItems: "center",
    border: `1px solid ${widget["border"]}`,
});

export const SrnAddItemGrid = styled(Grid)({
    paddingRight: 16,
    paddingBottom: 24,
});

/** Used for displaying insight metrics */
export const MetricsCard = styled(Card)({
    maxHeight: "350px",
    minHeight: "350px",
    border: `1px solid ${blue.lightGrayishBlue}`,
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
});

export const MetricsBox = styled(Box)({
    textAlign: "left",
    minHeight: "350px",
    minWidth: "400px",
    marginLeft: "20px",
    marginTop: "16px",
    padding: "8px",
});
