import { Dispatch, SetStateAction } from "react";

export interface EducationGridModel {
    id: number;
    eventDate?: string;
    topicName?: string;
    eventType?: string;
    customer?: string;
    departmentName?: string;
    statusDescription?: string;
    ceCredits: number;
    requestedBy?: string;
    sponsor?: string;
    sterisSponsor?: string;
    instructor?: string;
    expectedAttendees?: string;
    registration?: string;
    createdDate?: string;
    updatedDate?: string;
}

export interface EducationByStatus {
    statusDescription: string;
    eventCount: number;
}

export interface EducationCEEvents {
    department: string;
    eventCount: number;
}

export interface EducationGridProps {
    educationData: EducationGridModel[];
    isError: boolean;
    isLoading: boolean;
    setPageKey: Dispatch<SetStateAction<number>>;
}

export interface EducationDetail {
    registration?: string;
    departmentName?: string;
    requestedBy?: string;
    sponsorName?: string;
    sterisSponsorName?: string;
    instructorName?: string;
    createdDate?: Date;
    updatedDate?: Date;
    expectedAttendees?: string;
    actualAttendees?: number;
    ceCredits?: string;
    notes?: string;
    agenda?: string;
    customer?: string;
    topicName?: string;
    subject?: string;
    eventDate?: Date;
    startTime?: string;
    endTime?: string;
    statusDescription?: string;
    id: number;
}

export interface EducationDetailTopic {
    title?: string;
    description?: string;
    id: number;
}

export interface EducationDetailAttendees {
    id: number;
    name?: string;
    email?: string;
    title?: string;
}

export interface EducationDetailAttendeesCount {
    count?: number;
    toastMessage?: string;
}


export enum EducationStatus {
    Requested = "Requested",
    Planned = "Planned",
    Scheduled = "Scheduled",
    InPlanning = "In Planning",
    Complete = "Complete",
}

export enum EducationDepartment {
    Endo = "ENDO",
    Or = "OR",
    Radiology = "RADIOLOGY",
    Spd = "SPD",
    Gi = "GI",
    SpdSterile = "SPD-STERILE PROC DPT",
    Aorn = "AORN",
    Ccoc = "CCOC",
    CentralService = "CENTRAL SERVICE",
    ChildrenHospital = "CHILDRENS HOSPITAL",
    Cls = "CLS",
    Cpd = "CPD",
    Cs = "CS",
    CSCentralSterile = "CS - CENTRAL STERILE",
    Csp = "CSP",
    Cspd = "CSPD",
    Csr = "CSR",
    CsrVan = "CSR-VAN",
    GeneralOr = "GENERAL OR",
    Sps = "SPS",
    Sterile = "STERILE",
    Cardiac = "CARDIAC",
    AccWest = "ACC WEST",
    SpecProc = "0311 - SPEC PROC/AMB",
    IntegratedServicesC = "INTEGRATED SERVICE C",
}


