import { DateRange } from "@mui/x-date-pickers-pro";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { DateRangeSearch } from "../../../models/IDateRangeSearch";
import { ReplacedInstrument } from "../../../models/assets/ReplacedInstrument";
import { BaseGridState } from "../../common/BaseGridState";
import { baseGridReducers, baseGridInitialState } from "../baseGridsSlice";
import { BaseApiState } from "../../common/BaseApiState";
import { dateRangeUntilNow } from "../../../utils/dateRange";
import { RootState } from "../../store";
import { setDateRange, setDataRequested, setDataReceivedWithError, setDataLoaded } from "../sharedReducers";

export interface ReplacedInstrumentsState extends DateRangeSearch, BaseGridState, BaseApiState<ReplacedInstrument[]> {}

const initDate = dateRangeUntilNow(90);

const initialState: ReplacedInstrumentsState = {
    ...baseGridInitialState,
    initialDataLoaded: false,
    isLoading: false,
    data: [],
    isError: false,
    startDate: initDate[0]!.format("YYYY-MM-DD"),
    endDate: initDate[1]!.format("YYYY-MM-DD"),
    isDateRangeValid: true,
};

export const replacedInstrumentsSlice = createSlice({
    name: "replacedInstruments",
    initialState,
    reducers: {
        ...baseGridReducers,
        setDateRange,
        setDataRequested,
        setDataReceivedWithError,
        setDataLoaded,      
        dataReceived: (state, action: PayloadAction<ReplacedInstrument[]>) => {
            if (!state.initialDataLoaded) {
                state.initialDataLoaded = true;
            }
            state.data = action.payload;
            state.isLoading = false;
        },
    },
});


export const getStartDate = (state: RootState) => state.replacedInstruments.startDate;
export const getEndDate = (state: RootState) => state.replacedInstruments.endDate;
/**
 * Gets state.startDate and state.endDate as a DateRange<Dayjs> object.
 */
export const selectedDateRange = createSelector([getStartDate, getEndDate], (startDate, endDate): DateRange<Dayjs> => {
    const dates = [dayjs(startDate), dayjs(endDate)];
    return dates as DateRange<Dayjs>;
});

// Action creators are generated for each case reducer function
export const {
    setGridFilter,
    setGridSort,
    setGridColumns,
    setDateRange: setSelectedDateRange,
    setDataRequested: dataRequested,
    setDataLoaded: setInitialDataLoaded,   
    dataReceived,
    setDataReceivedWithError: dataReceivedWithError,
} = replacedInstrumentsSlice.actions;

export default replacedInstrumentsSlice.reducer;
