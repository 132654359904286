import { Step, StepLabel, Stepper, stepIconClasses, stepLabelClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { text } from "../theme/colors";

export const SrnStepper = ({ active }: { active: number }) => {
    /**
     * This is used in Create Service Request Item page to show 3 stages of SRN Creation
     * Add Items, Verify Shipping, Confirmation
     */

    const CreateSrnStepLabel = styled(StepLabel)(() => ({
        [`& .${stepLabelClasses.label}`]: {
            [`&.${stepLabelClasses.completed}`]: {
                color: text["textdarkgray"],
            },
            [`&.${stepLabelClasses.active}`]: {
                color: text["materialGreen"],
            },
            [`&.${stepLabelClasses.disabled}`]: {
                color: text["textdarkgray"],
            },
        },
    }));

    /**
     * This is used in Create Service Request Item page to show 3 stages of SRN Creation
     * Add Items, Verify Shipping, Confirmation
     */
    const CreateSrnStep = styled(Step)(() => ({
        [`& .${stepIconClasses.root}`]: {
            color: text["textWhite"], // circle color (COMPLETED)
            border: "1px solid grey",
            borderRadius: "20px",
            width: "0.9em",
            height: "0.9em",

            [`&.${stepLabelClasses.completed}`]: {
                color: text["textdarkgray"],
                border: "none",
                width: "1em",
                height: "1em",
            },
            [`&.${stepLabelClasses.active}`]: {
                color: text["materialGreen"],
                border: "none",
                width: "1em",
                height: "1em",
            },
        },
    }));

    const RequestCreatedSrnStep = styled(Step)(() => ({
        [`& .${stepIconClasses.root}`]: {
            color: text["textWhite"], // circle color (COMPLETED)
            border: "1px solid grey",
            borderRadius: "20px",
            width: "0.9em",
            height: "0.9em",

            [`&.${stepLabelClasses.completed}`]: {
                color: text["materialGreen"],
                border: "none",
                width: "1em",
                height: "1em",
            },
        },
    }));

    const steps = ["Add Items", "Verify Shipping", "Request Created"];
    return (
        <Stepper
            activeStep={active}
            alternativeLabel
            data-testid="srn-stepper">
            {steps.map(
                (label) =>
                    (active === 3 && label === "Request Created" && (
                        <RequestCreatedSrnStep key={label}>
                            <CreateSrnStepLabel>{label}</CreateSrnStepLabel>
                        </RequestCreatedSrnStep>
                    )) || (
                        <CreateSrnStep key={label}>
                            <CreateSrnStepLabel>{label}</CreateSrnStepLabel>
                        </CreateSrnStep>
                    )
            )}
        </Stepper>
    );
};
