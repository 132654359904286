import { Box, Grid, Typography, Card, CardContent, Button, CardHeader } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { analyticsInsightsData } from "../../models/reports/ReportCardData";
import { useNavigate } from "react-router-dom";
import { AuthLibrary } from "../../redux/actions/AuthRedux";

export const InteractiveDashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.interactiveDashboard)}`,
        analyticsInsights: t(PageTitles.interactiveDashboard),
        analyticalDashboard: t("Below are interactive, analytical dashboards available for your selected facilities."),
        openDashboard: t("Open Dashboard"),
        reportsAvailable: t("Below are the reports available for your selected facilities."),
        reports: t("Reports"),
        repairHistory: t("Repair History"),
        view: t("View"),
    };

    useEffect(() => {
        document.title = translations.documentTitle;
    }, [translations.documentTitle]);

    return (
        <Box data-testid="reports-component">
            <BreadCrumb breadCrumbs={BreadCrumbList.interactiveDashboard} />
            {/* Analytic Cards */}
            <Box padding={2}>
                <Box>
                    <Typography
                        variant="h1"
                        color="font.darkBlue">
                        {translations.analyticsInsights}
                    </Typography>
                </Box>
                <hr />
                <Box marginBottom={2}>
                    <Typography variant="body2">{translations.analyticalDashboard}</Typography>
                </Box>
                <Box marginBottom={2}>
                    <Grid
                        container
                        spacing={2}>
                        {analyticsInsightsData.map(
                            (insight) =>
                                insight.requiredClaim &&
                                AuthLibrary.hasAnyClaim([insight.requiredClaim]) &&
                                AuthLibrary.AccountSubscriptionHasClaim(insight.requiredClaim) && (
                                    <Grid
                                        item
                                        key={`insight_${insight.title}`}>
                                        <Card
                                            sx={{
                                                minWidth: 275,
                                                maxWidth: 400,
                                                height: "100%",
                                                textAlign: "center",
                                                border: 1,
                                                boxShadow: 1,
                                                borderColor: "white",
                                            }}>
                                            <CardHeader
                                                sx={{ paddingBottom: 0 }}
                                                title={
                                                    <Typography
                                                        variant="h5"
                                                        color={"font.darkBlue"}>
                                                        {insight.title}
                                                    </Typography>
                                                }
                                            />
                                            <CardContent>
                                                {insight.imageSvg &&
                                                    <Box
                                                        display="flex"
                                                        mb={3}>
                                                        <insight.imageSvg />
                                                    </Box>
                                                }    
                                                <Box
                                                    display={"flex"}
                                                    sx={{ height: "10vh" }}>
                                                    <Typography
                                                        mb={3}
                                                        variant="body2"
                                                        color={"font.darkBlue"}>
                                                        {insight.description}
                                                    </Typography>
                                                </Box>
                                                <Button
                                                    aria-label={translations.openDashboard}
                                                    sx={{ mt: 4 }}
                                                    variant="contained"
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(insight.href);
                                                    }}>
                                                    {translations.openDashboard}
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                        )}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};
