import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";

/**
 * Generates a mui DateRange from start date until now.
 * @param from - The start date (default is 30 days)
 * @returns a DateRange from start date until now.
 */
export const dateRangeUntilNow = (startDate: number = 30): DateRange<Dayjs> => {
    let date = new Date();
    const dateRange: DateRange<Dayjs> = [
        dayjs(date.setDate(date.getDate() - startDate)),
        dayjs(new Date()),
    ];
    return dateRange;
};