import { Box, Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { FacilityActions } from "../../redux/actions/facility/FacilityActions";

export default function SelectedFacilities() {
    const { selectedTempFacilities } = useSelector((state: StoreState) => state.facility);
    return (
        selectedTempFacilities && (
            <Box pt={1}>
                {selectedTempFacilities?.map((s) => (
                    <Chip
                        sx={{ backgroundColor: "white" }}
                        key={s.accountId}
                        label={s.displayName}
                        variant="outlined"
                        onDelete={() => FacilityActions.handleRemoveSelect(s.accountId)}
                    />
                ))}
            </Box>
        )
    );
}
