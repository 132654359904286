import { Search } from "@mui/icons-material";
import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { setCustomerSearch } from "../../redux/reducers/customersSlice";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";

export default function CustomerSearch() {
    const { searchString: customerSearch } = useSelector((state: StoreState) => state.customers);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const translations = {
        label: t("Search"),
        placeholder: t("Search by Customer Name or Number..."),
    };

    return (
        <Grid
            data-testid="customer-search"
            item
            xs={12}
            sm={10}
            md={8}
            lg={8}>
            <Box sx={{ mr: 4, display: "flex", justifyContent: "left" }}>
                <TextField
                    fullWidth
                    aria-label={translations.label}
                    placeholder={translations.placeholder}
                    size="small"
                    data-testid="customerSearch"
                    id="customerSearch"
                    variant="outlined"
                    autoComplete="off"
                    value={customerSearch}
                    onChange={(e) => dispatch(setCustomerSearch(e.target.value))}
                    inputProps={{
                        "aria-label": `${translations.label}`,
                        "data-testid": "customer-search-textfield",
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={{ ml: 10 }}>
                    <Button
                        aria-label={translations.label}
                        data-testid="customer-search-button"
                        disabled={!customerSearch}
                        variant="contained"
                        type="submit">
                        {translations.label}
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
}
