import { Alert, Box, Chip, Grid, LinearProgress, Typography, Link } from "@mui/material";
import { claimTypes } from "../../config/claimTypes";
import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { EstimateHeaderDetail, EstimateDetail } from "../../models/estimate/EstimateDetail";
import { Search, Build, FormatListBulletedRounded } from "@mui/icons-material";
import { useParams, useLocation } from "react-router-dom";
import { FormatCurrency, FormatDate } from "../../common/Formatters";
import EstimateRecommendedTab from "./EstimateRecommendedTab";
import EstimateObserved from "./EstimateObserved";
import { useFetch } from "../../services/useFetch";
import EstimatePhotos from "./EstimatePhotos";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { text } from "../../theme/colors";
import { GridBackgroundColor } from "../../theme/theme";
import { EstimateObserved as EstimateObservedDto } from "../../models/estimate/EstimateObserved";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EstimateDetailsTab from "./EstimateDetailsTab";
import ApproveEstimatePopup from "./ApproveEstimatePopup";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { ConnectCareActionButton, IActionMenuItems } from "../../component-library/ConnectCareActionButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { TabLoader } from "../../component-library/TabLoader";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";

export default function EstimateTabs() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const hasViewEstimates = AuthLibrary.checkClaim(claimTypes.ViewEstimateApprovalNeeded);
    const [estimateHeaderDetail, setEstimateHeaderDetail] = useState<EstimateHeaderDetail>();
    const [photosCount, setPhotosCount] = useState<number>(0);
    const [isHeaderLoading, setIsHeaderLoading] = useState(false);
    const [isHeaderDetailError, setIsHeaderDetailError] = useState(false);
    const [isPhotosCountError, setIsPhotosCountError] = useState(false);
    const params = useParams<{ lineid: string; headerid: string; customeraccountid: string }>();
    const { get } = useFetch();
    const [estimateDetail, setEstimateDetail] = useState<EstimateDetail>();
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const [isDetailError, setIsDetailError] = useState(false);
    const [estimateObserved, setEstimateObserved] = useState<EstimateObservedDto[]>([]);
    const [isObservedLoading, setIsObservedLoading] = useState(true);
    const [isPhotosLoading, setIsPhotosLoading] = useState(false);
    const [isObservedError, setIsObservedError] = useState(false);
    const [detailsEditMode, setDetailsEditMode] = useState<boolean>(false);
    const [showApproveEstimateModal, setShowApproveEstimateModal] = useState(false);
    const dispatch = useDispatch();
    const [showToast, setShowToast] = useState<boolean>(false);
    const tabIds = { recommended: "Recommended", details: "Details", observed: "Observed", photos: "Photos" };

    const translations = {
        id: t("Id"),
        model: t("Model"),
        serial: t("Serial"),
        order: t("Order"),
        serviceEstimate: t("SERVICE ESTIMATE"),
        returnEstimate: t("Return shipping estimate "),
        returnEstimateMessage: t(" if approved in "),
        apiError: t("System Error: API is not available at this time!"),
        title: t("Estimate"),
        backToHomePage: t("DASHBOARD"),
        observed: t("Observed"),
        photos: t("Photos"),
        recommended: t("Recommended"),
        details: t("Details"),
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.estimateDetails)}`,
        problemConcern: t("Problem"),
        optional: t("Optional"),
        purchaseOrder: t("Purchase Order"),
        item: t("Item"),
        approveOrDecline: t("Approve/Decline"),
        viewOrder: t("View Order"),
        viewDisclaimer: t("View Disclaimer"),
        actions: t("Actions"),
        enterPO: t("Enter PO"),
        addReference: t("Add Reference"),
        successEstimateApproved: t("Success! Estimate approved."),
        successEstimateDeclined: t("Estimate status updated."),
    };

    const hasViewObservedTab = AuthLibrary.HasAccountSubscriptionAccessToClaim(
        claimTypes.ViewEstimateDetailsObservedTab,
        parseInt(params.customeraccountid!)
    );

    const hasViewPhotosTab = AuthLibrary.HasAccountSubscriptionAccessToClaim(
        claimTypes.ViewEstimatePhotos,
        parseInt(params.customeraccountid!)
    );

    const tabs: ITab[] = useMemo(
        () => [
            {
                tabId: tabIds.recommended,
                icon: <Build />,
                children: <>{translations.recommended}</>,
            },
            {
                tabId: tabIds.details,
                icon: <FormatListBulletedRounded />,
                children: <>{translations.details}</>,
            },
            {
                tabId: tabIds.photos,
                icon: <PhotoLibraryIcon />,
                children: (
                    <TabLoader
                        isLoading={isPhotosLoading}
                        labelText={translations.photos}
                        isApiError={isPhotosCountError}
                        count={photosCount}
                    />
                ),
                hide: !hasViewPhotosTab,
            },
        ],
        [
            isPhotosCountError,
            isPhotosLoading,
            photosCount,
            tabIds.details,
            tabIds.photos,
            tabIds.recommended,
            translations.details,
            translations.photos,
            translations.recommended,
            hasViewPhotosTab,
        ]
    );

    /**
     * Below state is to set up the initial tab to show while landing into Estimate Details page
     * if we're redirecting from any other page to this estimate details page,
     * location.state.tab will decide which tab to show,
     * if location.state.tab doesn't have any value on it, the default configured tab will get loaded
     */
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(
        tabs.find((x) => x.tabId?.toUpperCase() === location.state?.tab?.toUpperCase() && !x.hide) ?? tabs[0]
    );

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(tabs.find((x) => x.tabId === newValue && !x.hide));
    };

    const getEstimateObserved = useCallback(async () => {
        setIsObservedLoading(true);
        const uriOrder = `${requestConnectCareOrders.EstimateObserved}?lineid=${encodeURIComponent(
            params.lineid ?? ""
        )}&headerId=${params.headerid}&custAccountId=${params.customeraccountid}`;
        const response = await get<EstimateObservedDto[]>(uriOrder, true);
        if (response) {
            setEstimateObserved(response);
            /**
             * Below logic is to add [Observed] tab dynamically in Estimate Details page
             * along with other three tabs [Recommended, Details, Photos]
             */
            if (response.length > 0 && !tabs.find((x) => x.tabId === tabIds.observed)) {
                tabs.splice(2, 0, {
                    tabId: tabIds.observed,
                    icon: <Search />,
                    children: <>{translations.observed}</>,
                });
            }
        } else {
            setIsObservedError(true);
        }
        setIsObservedLoading(false);
    }, [params.lineid, params.headerid, params.customeraccountid, get, tabs, tabIds.observed, translations.observed]);

    useEffect(() => {
        if (hasViewObservedTab) {
            getEstimateObserved();
        }
    }, [getEstimateObserved, hasViewObservedTab]);

    useEffect(() => {
        dispatch(
            setToast({
                showToast: showToast,
            })
        );
    }, [showToast, dispatch]);

    const statusBgColor = useMemo((): string => {
        if (!estimateHeaderDetail?.status) {
            return "";
        }
        switch (estimateHeaderDetail.status.toLowerCase()) {
            case "approved":
                dispatch(
                    setToast({
                        showToast,
                        toastType: ToastTypes.Success,
                        toastMessage: translations.successEstimateApproved,
                    })
                );
                return "font.materialGreen";
            case "rejected":
                dispatch(
                    setToast({
                        showToast,
                        toastType: ToastTypes.Success,
                        toastMessage: translations.successEstimateDeclined,
                    })
                );
                return "font.materialRed";
            case "cancelled":
                return "font.darkGray";
            default:
                return "";
        }
    }, [
        estimateHeaderDetail?.status,
        showToast,
        dispatch,
        translations.successEstimateApproved,
        translations.successEstimateDeclined,
    ]);

    const shouldDisableBasedOnStatus = (actionButtonName: "EnterPO" | "Approve/Decline" | "AddReference"): boolean => {
        let result = false;

        if (
            actionButtonName === "EnterPO" ||
            actionButtonName === "Approve/Decline" ||
            actionButtonName === "AddReference"
        ) {
            if (["rejected", "cancelled", "approved"].includes(estimateHeaderDetail?.status.toLowerCase() || "")) {
                result = true;
            }
        }

        return result;
    };

    const getEstimateHeaderDetails = useCallback(async () => {
        setIsHeaderLoading(true);

        // Ideally we should not even come here in case if lineId or headerId or customeraccountid is undefined
        // in case if we do so, we will just pass empty string
        const uri = `${requestConnectCareOrders.Estimate}?lineid=${params.lineid}&headerId=${params.headerid}&custAccountId=${params.customeraccountid}`;
        const response = await get<EstimateHeaderDetail>(uri, true);
        if (response) {
            setEstimateHeaderDetail(response);
        } else {
            setIsHeaderDetailError(true);
        }
        setIsHeaderLoading(false);
    }, [params.lineid, params.headerid, params.customeraccountid, get]);

    const getEstimatedPhotosCount = useCallback(async () => {
        setIsPhotosCountError(false);
        setIsPhotosLoading(true);
        const uri = `${requestConnectCareOrders.EstimatePhotosCount}?lineid=${encodeURIComponent(params.lineid ?? "")}&custAccountId=${params.customeraccountid}`;
        const response = await get<number>(uri, true);
        const result = response as number;
        if (result >= 0) {
            setPhotosCount(result);
        } else {
            setIsPhotosCountError(true);
        }
        setIsPhotosLoading(false);
    }, [params.lineid, params.customeraccountid, get]);

    const getEstimateDetail = useCallback(async () => {
        setIsDetailLoading(true);
        setIsDetailError(false);
        const uriOrder = `${requestConnectCareOrders.EstimateDetail}?lineid=${encodeURIComponent(params.lineid ?? "")}`;
        const response = await get<EstimateDetail>(uriOrder, true);
        if (response) {
            setEstimateDetail(response);
        } else {
            setIsDetailError(true);
        }
        setIsDetailLoading(false);
    }, [params.lineid, get]);

    useEffect(() => {
        document.title = translations.documentTitle;
        getEstimateHeaderDetails();
        if (hasViewPhotosTab) {
            getEstimatedPhotosCount();
        }
        getEstimateDetail();
    }, [
        getEstimateHeaderDetails,
        hasViewPhotosTab,
        getEstimatedPhotosCount,
        getEstimateDetail,
        translations.documentTitle,
        estimateHeaderDetail?.custAccountId,
        tabIds.photos,
    ]);

    const handleApproveClick = () => {
        setShowApproveEstimateModal(true);
    };

    const actionButtons: IActionMenuItems[] = [
        {
            name: translations.approveOrDecline,
            onClick: handleApproveClick,
            disable: shouldDisableBasedOnStatus("Approve/Decline"),
            hide: !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) || estimateDetail?.isIndirectFiltered,
        },
        {
            name: translations.enterPO,
            onClick: () => {
                setDetailsEditMode(true);
                setCurrentTab(tabs[1]); //Details tab
            },
            hide: !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]) || estimateDetail?.isIndirectFiltered,
            disable: !!estimateDetail?.purchaseOrder || shouldDisableBasedOnStatus("EnterPO"),
        },
        {
            name: translations.addReference,
            onClick: () => {
                setDetailsEditMode(true);
                setCurrentTab(tabs[1]); //Details tab
            },
            hide: estimateDetail?.isIndirectFiltered,
            disable: !!estimateDetail?.reference || shouldDisableBasedOnStatus("AddReference"),
        },
        {
            name: translations.viewOrder,
            onClick: () => {
                navigate(`/orders/${params.headerid}`);
            },
        },
        {
            name: translations.viewDisclaimer,
            onClick: () => {
                window.open(`${process.env.REACT_APP_TERMS_OF_SALE}`, "_blank", "noopener,noreferrer");
            },
        },
    ];

    const estimateDetailHeader = (
        <Grid
            paddingLeft={4}
            paddingRight={4}>
            {isHeaderLoading && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <LinearProgress />
                </Box>
            )}

            {!isHeaderLoading && isHeaderDetailError && (
                <Grid
                    marginX={4}
                    marginY={1}>
                    <Alert severity="error">{translations.apiError}</Alert>
                </Grid>
            )}

            {!isHeaderLoading && !isHeaderDetailError && (
                <Grid
                    container
                    mb={2}
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}>
                    <Grid
                        item
                        md={8}>
                        <Grid
                            item
                            flex={1}>
                            <Typography
                                variant="body2"
                                color="font.gray"
                                marginBottom={2}>
                                {estimateHeaderDetail?.customerName ?? ""}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h1"
                                color="font.darkBlue"
                                marginBottom={1}>
                                {estimateHeaderDetail?.manufacturer ?? ""} {estimateHeaderDetail?.productType ?? ""}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                color="font.darkBlue">
                                {translations.model + " : " + (estimateHeaderDetail?.model ?? "")}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="font.darkBlue">
                                {estimateHeaderDetail?.serialNumber.includes(".") ? (
                                    translations.item + " : " + estimateHeaderDetail?.serialNumber
                                ) : (
                                    <>
                                        {translations.serial + " : "}
                                        {
                                            <Link
                                                variant="body2"
                                                color="blue.connectCare2"
                                                underline="none"
                                                onClick={() => {
                                                    navigate(
                                                        `/assets/${estimateHeaderDetail?.custAccountId}/${encodeURIComponent(
                                                            estimateHeaderDetail?.serialNumber ?? ""
                                                        )}/${estimateHeaderDetail?.inventoryItemId}`
                                                    );
                                                }}
                                                target="_blank">
                                                {estimateHeaderDetail?.serialNumber}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="font.darkBlue">
                                {translations.order + " : "}
                                <Link
                                    variant="body2"
                                    color="blue.connectCare2"
                                    underline="none"
                                    onClick={() => {
                                        navigate(`/orders/${params.headerid}`);
                                    }}
                                    target="_blank">
                                    {estimateHeaderDetail?.orderNumber}
                                </Link>
                            </Typography>
                            <Typography
                                variant="body2"
                                color="font.darkBlue">
                                {estimateDetail?.problem
                                    ? translations.problemConcern + " : " + estimateDetail?.problem
                                    : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{ display: "flex", justifyContent: "space-between" }}
                        md={4}>
                        <Grid
                            item
                            sx={{ float: "right", flex: 1 }}>
                            {statusBgColor &&
                                (estimateHeaderDetail?.status === "REJECTED" ? (
                                    <Chip
                                        variant={"status"}
                                        size={"small"}
                                        sx={{ bgcolor: statusBgColor, float: "right", marginTop: "23px" }}
                                        label="DECLINED"
                                    />
                                ) : (
                                    <Chip
                                        variant={"status"}
                                        size={"small"}
                                        sx={{ bgcolor: statusBgColor, float: "right", marginTop: "23px" }}
                                        label={estimateHeaderDetail?.status}
                                    />
                                ))}
                        </Grid>
                        <Grid
                            item
                            sx={{ textAlign: "left", float: "right", marginLeft: "30px" }}>
                            <Typography
                                variant="body2"
                                color="font.gray">
                                {translations.serviceEstimate}
                            </Typography>
                            <Typography
                                display={"block"}
                                variant="h1"
                                color="font.darkBlue">
                                {FormatCurrency(
                                    Number(estimateHeaderDetail?.serviceEstimate?.replace(/,/g, "")),
                                    estimateHeaderDetail?.transactionCurrencyCode
                                )}
                            </Typography>
                            <Grid
                                item
                                mt={4}>
                                <ConnectCareActionButton menuItems={actionButtons} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );

    const renderTabs = (tab?: string) => {
        switch (tab) {
            case tabIds.recommended:
                return <EstimateRecommendedTab lineNumber={params.lineid}></EstimateRecommendedTab>;
            case tabIds.details:
                return (
                    <EstimateDetailsTab
                        lineId={params.lineid!}
                        orderNumber={estimateHeaderDetail?.orderNumber!}
                        customeraccountId={params.customeraccountid!}
                        headerId={params.headerid}
                        estimateDetailData={estimateDetail}
                        isLoading={isDetailLoading}
                        isError={isDetailError}
                        getEstimateDetail={getEstimateDetail}
                        isEditMode={detailsEditMode}
                        setDetailsEditMode={setDetailsEditMode}
                    />
                );
            case tabIds.observed:
                return (
                    <EstimateObserved
                        isError={isObservedError}
                        isLoading={isObservedLoading}
                        estimateObserved={estimateObserved}
                    />
                );
            case tabIds.photos:
                return (
                    <EstimatePhotos
                        lineNumber={params.lineid}
                        custAccountId={params.customeraccountid!}
                    />
                );
        }
    };

    const estimateDetailTabs = (
        <Box>
            <ConnectCareTabs
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                tabs={tabs}
            />
            {renderTabs(currentTab?.tabId)}
        </Box>
    );

    return (
        <Box
            style={GridBackgroundColor}
            padding={2}>
            <BreadCrumb breadCrumbs={BreadCrumbList.estimateDetails} />
            {!hasViewEstimates ? (
                <NotAuthorizeDisplay />
            ) : (
                <Box
                    data-testid="orders-estimate-tabs-page"
                    bgcolor="bg.white"
                    border="1px solid #DFE0EB"
                    borderRadius={2}
                    marginTop="20px"
                    overflow={"hidden"}>
                    {estimateHeaderDetail &&
                        estimateHeaderDetail.status !== "REJECTED" &&
                        estimateHeaderDetail.estimateType !== "PRODUCTS" &&
                        estimateHeaderDetail.status !== "CANCELLED" && (
                            <Box
                                bgcolor="#FCFCFC"
                                fontSize="12px"
                                fontFamily="Lato"
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="center"
                                height="4vh"
                                paddingLeft={1}>
                                <LocalShippingIcon
                                    fontSize="small"
                                    sx={{ transform: "scaleX(-1)", color: text.textgray2 }}
                                />
                                <Typography
                                    style={{ paddingLeft: "5px" }}
                                    variant="approvalText">
                                    {translations.returnEstimate}
                                    {estimateHeaderDetail?.returnEstimate != null
                                        ? FormatDate(estimateHeaderDetail?.returnEstimate)
                                        : ""}
                                    {estimateHeaderDetail.status !== "APPROVED" && (
                                        <Typography variant="approvalText">
                                            {translations.returnEstimateMessage}
                                            <Typography variant="approvalHours">
                                                {estimateHeaderDetail?.approvedHours +
                                                    " hr " +
                                                    estimateHeaderDetail?.approvedMinutes +
                                                    " min"}
                                            </Typography>
                                        </Typography>
                                    )}
                                </Typography>
                            </Box>
                        )}
                    <Box paddingTop={"12px"}>
                        {estimateDetailHeader}
                        {estimateDetailTabs}
                    </Box>
                </Box>
            )}
            {showApproveEstimateModal && (
                <ApproveEstimatePopup
                    lineId={params.lineid!}
                    customeraccountId={params.customeraccountid!}
                    headerId={params.headerid}
                    customerName={estimateHeaderDetail?.customerName}
                    estimateDetail={estimateDetail}
                    setShowApproveEstimateModal={setShowApproveEstimateModal}
                    getEstimateDetail={getEstimateDetail}
                    getEstimateHeaderDetails={getEstimateHeaderDetails}
                    showToast={setShowToast}
                />
            )}
        </Box>
    );
}
