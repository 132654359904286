import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { NotSubscribedDisplay } from "../auth/NotSubscribedDisplay";
import NotAuthorizeDisplay from "../auth/NotAuthorizeDisplay";
import React, { useMemo } from "react";
import { AppMenuItem } from "../../models/navigation/AppMenuItemResponse";

export interface AuthorizeRouteProps {
    /**
     * The element to render if the user is authorized
     */
    element: React.ReactElement;

    /**
     * The menu item id (should match with records AppMenuListData in config/menus.tsx )
     */
    menuItemId: number;
}

/**
 * This component is used to authorize a route based on the menu item id and render the corresponding content or marketting page.
 * @param props The props for the component
 * @returns The component
 */
const AuthorizeRouteMemoized = (props: Readonly<AuthorizeRouteProps>) => {
    const { element, menuItemId } = props;
    const { leftNavigation } = useSelector((state: StoreState) => state.auth);

    const flatList = useMemo(() => {
        return leftNavigation.menuItems?.flatMap((x) => flatten(x));
    }, [leftNavigation]);

    const menuItem = flatList?.find((x) => x.id === menuItemId);

    const showContent = menuItem?.isVisible;
    const showMarketing = menuItem?.showMarketing;

    if (flatList?.length > 0) {
        if (showMarketing) {
            return <NotSubscribedDisplay />;
        } else if (showContent) {
            return element;
        } else {
            return <NotAuthorizeDisplay />;
        }
    } else {
        return <></>;
    }
};

export const AuthorizeRoute = React.memo(
    AuthorizeRouteMemoized,
    (prevProps: AuthorizeRouteProps, nextProps: AuthorizeRouteProps) => {
        return prevProps.menuItemId === nextProps.menuItemId && prevProps.element.type === nextProps.element.type;
    }
);

/**
 * Flattens a hierarchical structure of menu items into a single array.
 *
 * @param {AppMenuItem} item - The root menu item to start flattening from.
 * @param {AppMenuItem[]} [menuItems=[]] - An optional array to accumulate the flattened menu items.
 * @returns {AppMenuItem[]} - The flattened array of menu items.
 *
 * @example
 * const menu = {
 *   name: 'Parent',
 *   children: [
 *     { name: 'Child 1' },
 *     { name: 'Child 2' }
 *   ]
 * };
 * const flatMenu = flatten(menu);
 * console.log(flatMenu);
 * // Output: [
 * //   { name: 'Parent', children: [...] },
 * //   { name: 'Child 1' },
 * //   { name: 'Child 2', children: [...] },
 * //   { name: 'Grandchild 1' }
 * // ]
 */
const flatten = (item: AppMenuItem, menuItems: AppMenuItem[] = []): AppMenuItem[] => {
    menuItems.push(item);
    if (item.children) {
        item.children.forEach((child) => {
            flatten(child, menuItems);
        });
    }
    return menuItems;
};
