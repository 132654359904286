import { Autocomplete, debounce, TextField, ListItemText, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Facility } from "../models/facility/Facility";
import { requestConnectCareInventory } from "../services/apiPaths";
import { AssetDto } from "../models/assets/AssetDto";
import { useFetch } from "../services/useFetch";

const SerialAutocomplete = ({
    onSelectItem,
    selectedFacilities,
    singleCustomerAccountId,
}: {
    onSelectItem: any;
    selectedFacilities: Facility[];
    singleCustomerAccountId?: number;
}) => {
    const { t } = useTranslation();
    const { get } = useFetch();
    const [results, setResults] = useState<AssetDto[]>([]);

    const translations = {
        autoCompleteLabel: t("Start typing a Serial Number."),
        noItemFound: t("No item found in asset inventory"),
    };

    const handleSearch = async (searchTerm: string) => {
        const uriAsset = `${requestConnectCareInventory.SerialSearch}/?search=${encodeURIComponent(searchTerm)}`;
        const res = await get<AssetDto[]>(uriAsset, true);

        let results = singleCustomerAccountId
            ? res?.filter((item) => item.customerAccountId === singleCustomerAccountId)
            : res;

        setResults(results ?? []);
    };

    const onInputChange = (searchTerm: string) => {
        if (searchTerm.length < 3) {
            setResults([]);
            return;
        }
        debouncedSearch(searchTerm);
    };

    const debouncedSearch: (searchTerm: string) => void = debounce(handleSearch, 300);

    return (
        <Autocomplete
            data-testid="serial-autocomplete"
            disablePortal
            fullWidth
            size={"small"}
            options={results}
            getOptionLabel={(option) => `${option.serialNumber}`}
            filterOptions={(options) => options}
            onInputChange={(_event, value) => onInputChange(value)}
            onChange={(_event, value) => onSelectItem(value)}
            noOptionsText={translations.noItemFound}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, "data-testid": "serial-autocomplete-input" }}
                    InputLabelProps={{
                        shrink: undefined,
                    }} /* this is a hack due to a change in mui 5.14.2, where InputLabelProps are not compatable with TextField? See #17334 
                        ...params.InputLabelProps will duplicate the build error.
                    */
                    label={translations.autoCompleteLabel}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <ListItem
                        {...props}
                        data-testid="select-option">
                        <ListItemText
                            disableTypography
                            primary={`${option.serialNumber}`}
                            secondary={
                                <>
                                    {selectedFacilities.length > 1 && (
                                        <Typography variant="body1">{option.customer}</Typography>
                                    )}
                                    <Typography
                                        variant="body2"
                                        color={"font.gray"}>
                                        {option.description}
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                );
            }}
            isOptionEqualToValue={(option, value) => option.serialNumber === value.serialNumber}
        />
    );
};
export default SerialAutocomplete;
