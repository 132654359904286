import { createSlice } from "@reduxjs/toolkit";
import { BaseGridState } from "../../common/BaseGridState";
import { baseGridReducers, baseGridInitialState } from "../baseGridsSlice";

export interface ItemsInTransitToRepairState extends BaseGridState {}

const initialState: ItemsInTransitToRepairState = {
    ...baseGridInitialState,
    gridColumns: {
        id: false,
        equipmentId: false,
    },
};

export const itemsInTransitToRepairSlice = createSlice({
    name: "itemsInTransitToRepair",
    initialState,
    reducers: baseGridReducers,
});

// Action creators are generated for each case reducer function
export const { setGridFilter, setGridSort, setGridColumns } = itemsInTransitToRepairSlice.actions;

export default itemsInTransitToRepairSlice.reducer;
