import { Box, Button, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { blue, text } from "../../theme/colors";
import ConstructionIcon from "@mui/icons-material/Construction";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GppBadRoundedIcon from "@mui/icons-material/GppBadRounded";
import { ServiceRequestDetail, ServiceRequestItemDto } from "../../models/assets/ServiceRequestDetail";
import LocalPoliceRoundedIcon from "@mui/icons-material/LocalPoliceRounded";
import GppMaybeRoundedIcon from "@mui/icons-material/GppMaybeRounded";
import { EquipmentRequestForm } from "./EquipmentRequestForm";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SrnCreationProvider } from "../../contexts/SrnCreationContext";
import { EquipmentRequestFormDto } from "../../models/orders/EquipmentRequestFormDto";
import { useNavigate } from "react-router-dom";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { claimTypes } from "../../config/claimTypes";
import { useFetch } from "../../services/useFetch";
import { requestConnectCareOrders } from "../../services/apiPaths";
import { PoValidationStatusDto } from "../../models/orders/PoValidationStatusDto";

type SrnDetailItemProps = {
    translations: any;
    item: ServiceRequestItemDto;
    srnDetails: ServiceRequestDetail;
    setShowSuccessToast: Dispatch<SetStateAction<boolean>>;
};

export const SrnDetailItem = (props: SrnDetailItemProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const [equipmentRequested, setEquipmentRequested] = useState<boolean>(props.item?.equipmentRequested);
    const [openEquipmentRequestFormDialog, setOpenEquipmentRequestFormDialog] = useState<boolean>(false);
    const [equipmentRequestData, setEquipmentRequestData] = useState<EquipmentRequestFormDto>();
    const [poValidationRequired, setPoValidationRequired] = useState<boolean>(true);

    const navigate = useNavigate();
    const { get } = useFetch();

    const hasAccountSubscriptionAccessToClaim = AuthLibrary.HasAccountSubscriptionAccessToClaim(
        claimTypes.RequestEquipment,
        props.srnDetails.custAccountId
    );

    const getPoValidationRequirement = async (custAccountId: number) => {
        const uri = `${requestConnectCareOrders.GetPoValidationStatus}?custAccountId=${custAccountId}`;
        const response = await get<PoValidationStatusDto>(uri);
        if (response) {
            setPoValidationRequired(response.isEnabled);
        }
    };

    const handleRequestEquipment = () => {
        getPoValidationRequirement(props.srnDetails.custAccountId);
        setOpenEquipmentRequestFormDialog(true);
    };

    const handleClose = () => {
        setOpenEquipmentRequestFormDialog(false);
    };

    useEffect(() => {
        props.setShowSuccessToast(equipmentRequested);
    }, [props, equipmentRequested]);

    useEffect(() => {
        setEquipmentRequestData({
            custAccountId: props.srnDetails.custAccountId,
            orderLineId: props.item.orderLineId,
            orderId: props.srnDetails.orderId,
            productLine: props.item.productLine,
            serialNumber: props.item.serial!,
            equipmentEntitlementValue: props.item.equipmentEntitlementValue,
            deptDescription: props.srnDetails.department,
            equipmentDescription: props.item.equipmentDescription,
            accountNumber: props.srnDetails.customerNumber,
            accountName: props.srnDetails.customerName,
            departmentId: props.srnDetails.departmentId,
            inventoryItemId: props.item.inventoryItemId,
            srn: props.srnDetails.srn,
        });
    }, [props]);

    return (
        <Box
            sx={{ display: "flex", flexBasis: matches ? "30%" : "100%" }}
            pt={4}
            data-testid={`srn-detail-item-${props.item.orderLineId}`}>
            <Box
                sx={{ display: "flex" }}
                borderRight={8}
                borderColor={text.textgray2}>
                {matches && (
                    <Typography variant="SRNConstructionIcon">
                        <ConstructionIcon
                            className="SRNConstructionIcon"
                            sx={{ fontSize: "50px", padding: "20px" }}
                        />
                    </Typography>
                )}
            </Box>
            <Box paddingX={2}>
                <Typography
                    data-testid="detail-value"
                    variant="detailValueTitle"
                    pt={1}>
                    {props.item.equipmentDescription?.replace(/,\s*$/, "")}
                </Typography>
                {props.item.serial && (
                    <Box>
                        <Typography
                            data-testid="detail-value"
                            variant="detailValueNumber"
                            color={blue.connectCare2}
                            pt={1}>
                            {props.item.serial}
                        </Typography>
                    </Box>
                )}
                {!props.item.serial && (
                    <Box>
                        <Typography
                            data-testid="detail-value"
                            variant="detailValueTitle"
                            pt={1}>
                            {props.translations.quantity}: {props.item.quantity}
                        </Typography>
                    </Box>
                )}
                <Typography
                    data-testid="detail-value"
                    variant="detailValueProblem"
                    pt={1}>
                    {props.item.problem}
                </Typography>
                <Box
                    mt={1}
                    sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                    {props.srnDetails.itemsDisinfected && (
                        <Typography variant="complete">
                            <VerifiedUserIcon sx={{ fontSize: "20px", transform: "scale(1.2, 1.1)" }} />
                            {props.translations.disinfected}
                        </Typography>
                    )}
                    {!props.srnDetails.itemsDisinfected && (
                        <Typography variant="incomplete">
                            <GppBadRoundedIcon sx={{ fontSize: "20px", transform: "scale(1.2, 1.1)" }} />
                            {props.translations.notDisinfected}
                        </Typography>
                    )}
                    {props.srnDetails.estimateRequired && (
                        <Typography variant="pending">
                            <GppMaybeRoundedIcon sx={{ fontSize: "20px", transform: "scale(1.2, 1.1)" }} />
                            {props.translations.estimateRequired}
                        </Typography>
                    )}
                    {!props.srnDetails.estimateRequired && (
                        <Typography variant="complete">
                            <LocalPoliceRoundedIcon sx={{ fontSize: "20px", transform: "scale(1.2, 1.1)" }} />
                            {props.translations.preapproved}
                        </Typography>
                    )}
                </Box>
                {props.item.entitlementTypeMeaning && (
                    <Typography
                        variant="detailValueTitle"
                        textTransform="uppercase">
                        {props.item.entitlementTypeMeaning}
                    </Typography>
                )}

                {hasAccountSubscriptionAccessToClaim && (
                    <Box sx={{ pt: 1 }}>
                        {/**
                         * We need to display Request Equipment button for SERIALIZED ITEM and for that we need to check
                         * inventoryItemId and serial as not null and equipemnt is not requested before.
                         */}
                        {!!props.item?.inventoryItemId && !!props.item?.serial && !equipmentRequested && (
                            <Button
                                data-testid="equipment-request"
                                variant="equipmentRequest"
                                fullWidth
                                type="submit"
                                sx={{
                                    width: "auto",
                                }}
                                aria-label={props.translations.requestEquipment}
                                color="primary"
                                onClick={handleRequestEquipment}>
                                {props.translations.requestEquipment}
                            </Button>
                        )}
                        {equipmentRequested && (
                            <Link
                                data-testid="view-equipment-request"
                                onClick={() => navigate("/orders/equipmentrequest")}>
                                {props.translations.viewEquipment}
                            </Link>
                        )}
                    </Box>
                )}
            </Box>
            {!equipmentRequested && openEquipmentRequestFormDialog && (
                <SrnCreationProvider>
                    <EquipmentRequestForm
                        onClose={handleClose}
                        equipmentRequest={equipmentRequestData}
                        setEquipmentRequested={setEquipmentRequested}
                        poValidationRequired={poValidationRequired}
                    />
                </SrnCreationProvider>
            )}
        </Box>
    );
};
