import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageIcon from '@mui/icons-material/Image';
import { MissingPhotoStack } from "../../theme/theme";
import { text } from "../../theme/colors";

/**
 * Displays an outlined box with an icon and some caption text to indicate that an photo is missing from Azure storage.
 */
export const MissingPhoto = () => {
    const { t } = useTranslation();
    const translations = {           
        imageUnavailable: t("Image Unavailable"),
    };

    return (
        <MissingPhotoStack data-testid="missing-photo-stack">
            <ImageIcon
                data-testid="missing-photo-stack-icon"
                fontSize="large"
                sx={{ color: text.textdarkgray }}
            />
            <Typography>{translations.imageUnavailable}</Typography>
        </MissingPhotoStack>
    );
}