import { Box, Typography } from "@mui/material";

export interface IHeaderDetailsUserInfoProps {
    name: string;
    personType?: string;
}

export default function HeaderDetailsUserInfo(props: Readonly<IHeaderDetailsUserInfoProps>) {
    const { name, personType } = props;

    return (
        <Box>
            <Typography
                variant={"h5"}
                color="blue.darker"
                textTransform={"capitalize"}>
                {name}
            </Typography>
            {personType && <Typography variant={"detailValue"}>{personType}</Typography>}
        </Box>
    );
}
