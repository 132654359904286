import { Box, Link, Typography } from "@mui/material";
import { SystemItem } from "../../../models/assets/SystemItem";
import { useTranslation } from "react-i18next";

const SelectedAsset = ({ item, resetSelectedItem }: { item: SystemItem; resetSelectedItem: () => void }) => {
    const { t } = useTranslation();
    const translations = {
        reset: t("Reset"),
    };

    return (
        <Box
            data-testid="selected-asset"
            mt={3}
            px={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Typography variant="body1">
                {item.make} {item.model}
            </Typography>
            <Link
                data-testid={"reset-link"}
                variant="body2"
                underline="hover"
                onClick={() => resetSelectedItem()}>
                {translations.reset}
            </Link>
        </Box>
    );
};

export default SelectedAsset;
