import { Helmet } from "react-helmet";

export const TableauVizContent = (props: { src: string; token: string; error: boolean }) =>
    props.error ? (
        <p data-testid={"loading-error"}>Error Loading Token!!</p>
    ) : (
        <div data-testid={"tableau-viz-content"}>
            <Helmet>
                <script
                    type="module"
                    src="https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"></script>
            </Helmet>
            <tableau-viz
                id="tableauViz"
                src={props.src}
                token={props.token}
            />
        </div>
    );
