import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { IServiceRequestItem } from "../../models/serviceRequest/CreateSrnModels";
import { useTranslation } from "react-i18next";

export interface IProps {
    index: number;
    item: IServiceRequestItem;
    updateItem: (item: IServiceRequestItem, index: number) => void;
}

export function ServiceRequestItemQuantityInput(props: {
    index: number;
    item: IServiceRequestItem;
    updateItem: (item: IServiceRequestItem, index: number) => void;
}) {
    const { t } = useTranslation();
    const translations = {
        qty: t("Qty"),
        qtyRequired: t("Quantity is required."),
        qtyMaxLength: t("999 quantity max."),
    };
    const isQtyEmptyError = !props.item.quantity;
    const isQtyMaxError = props.item.quantity > 999;

    return (
        <>
            <Grid
                item
                xs={12}>
                {props.item.isSummaryView ? (
                    <>
                        <Typography variant="detailLabel">{translations.qty}</Typography>
                        <Typography variant="detailValue">{props.item.quantity}</Typography>
                    </>
                ) : (
                    <>
                        <FormControl
                            fullWidth
                            variant="outlined">
                            <TextField
                                inputProps={{ "data-testid": "qty-input" }}
                                label={translations.qty}
                                size="small"
                                value={props.item.quantity}
                                error={isQtyEmptyError || isQtyMaxError}
                                type="number"
                                onChange={(e) => {
                                    let updatedItem = { ...props.item };
                                    updatedItem.quantity = +e.target.value;
                                    if (updatedItem.quantity < 0) return;
                                    props.updateItem(updatedItem, props.index);
                                }}
                            />
                        </FormControl>
                        {isQtyEmptyError && (
                            <Typography
                                data-testid={"quantity-blank-error"}
                                variant="errorMessage">
                                {translations.qtyRequired}
                            </Typography>
                        )}
                        {isQtyMaxError && (
                            <Typography
                                data-testid={"qty-over-999-error"}
                                variant="errorMessage">
                                {translations.qtyMaxLength}
                            </Typography>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
}

export default ServiceRequestItemQuantityInput;
