import { Grid, Typography, Button, styled, useMediaQuery, useTheme, Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CableIcon from "@mui/icons-material/Cable";
import { blue, text } from "../theme/colors";
import MaximizeOutlinedIcon from "@mui/icons-material/MaximizeOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import {
    AddButtonBox,
    SrnMenuItemSubGrid,
    ForwardBackwardArrow,
    SubtitleBox,
    SrnMenuItemsIcon,
    SrnMenuHeader,
    SrnMenuArrowButton,
} from "../theme/theme";
import { srnTypeKeys } from "../config/data";
import { useNavigate } from "react-router-dom";
import { SterisInstrumentIcon } from "../icons/steris/SterisInstumentIcon";
import { Dispatch, SetStateAction, useEffect } from "react";

export type ServiceRequestItemListProps = {
    handleAddInput: (typeOfItem: srnTypeKeys) => void;
    isLoading: boolean;
    showSidePanelMenuItem: boolean;
    setShowSidePanelMenuItem: Dispatch<SetStateAction<boolean>>;
    disableAddItemButton: boolean;
};

export const ServiceRequestItemList = ({
    handleAddInput,
    isLoading,
    showSidePanelMenuItem,
    setShowSidePanelMenuItem,
    disableAddItemButton,
}: ServiceRequestItemListProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    /**
     * If device width is below 1600px then we need to show full width of Left SRN menu items
     * If device width is more than 1600px then we will set the width to 30% for Left SRN menu items
     */
    const widthIsAbove1600 = !useMediaQuery(theme.breakpoints.between(0, 1600));
    const hideSidePanel = useMediaQuery(theme.breakpoints.up("md"));
    const translations = {
        sterisInstrumentIcon: t("STERIS Instrument"),
        serializedItem: t("Serialized Item"),
        fromMyAssetInventory: t("from my asset inventory"),
        exampleSerializedItem: t("Examples: Flexible or rigid endoscopes, Power"),
        instruments: t("Instruments"),
        add: t("Add"),
        help: t("Help"),
        other: t("Other"),
        unsure: t("I'm unsure"),
        needHelp: t("and need help"),
        itemsNeedService: t("What item needs service?"),
        addItems: t("Add Items"),
        subtitle: t("Click ADD to build your Service Request."),
        viewServiceRequests: t("View Recent Service Requests"),
    };

    useEffect(() => {
        if (!widthIsAbove1600) {
            setShowSidePanelMenuItem(false);
        } else {
            setShowSidePanelMenuItem(true);
        }
    }, [widthIsAbove1600, setShowSidePanelMenuItem]);

    const RightIcon = styled(ChevronRightIcon)({
        paddingLeft: "16px",
        paddingRight: "8px",
    });

    const iconStyle = { fontSize: "3rem", color: "blue.connectCare2", fill: blue.connectCare2 };

    const requestServiceList = [
        {
            icon: <Inventory2OutlinedIcon sx={iconStyle} />,
            title: translations.serializedItem,
            subtitle: translations.fromMyAssetInventory,
            example: translations.exampleSerializedItem,
            buttonText: translations.add,
            buttonIcon: <RightIcon />,
            onClick: () => {
                handleAddInput(srnTypeKeys.Serialized);
                !widthIsAbove1600 && setShowSidePanelMenuItem((prev) => !prev);
            },
            disable: disableAddItemButton,
        },
        {
            icon: <SterisInstrumentIcon sx={iconStyle}></SterisInstrumentIcon>,
            title: translations.instruments,
            subtitle: "",
            example: "",
            buttonText: translations.add,
            buttonIcon: <RightIcon />,
            onClick: () => {
                handleAddInput(srnTypeKeys.Nonserialized);
                !widthIsAbove1600 && setShowSidePanelMenuItem((prev) => !prev);
            },
            disable: disableAddItemButton,
        },
        {
            icon: <CableIcon sx={iconStyle} />,
            title: translations.other,
            subtitle: "",
            example: "",
            buttonText: translations.add,
            buttonIcon: <RightIcon />,
            onClick: () => {
                handleAddInput(srnTypeKeys.Unknown);
                !widthIsAbove1600 && setShowSidePanelMenuItem((prev) => !prev);
            },
            disable: disableAddItemButton,
        },
        {
            icon: <HelpOutlineOutlinedIcon sx={iconStyle} />,
            title: translations.unsure,
            subtitle: translations.needHelp,
            example: "",
            buttonText: translations.help,
            buttonIcon: <RightIcon />,
            onClick: () => {
                !widthIsAbove1600 && setShowSidePanelMenuItem((prev) => !prev);
                navigate("/mysteristeam");
            },
            disable: false,
        },
    ];

    return (
        <>
            {showSidePanelMenuItem && (
                <SrnMenuArrowButton
                    overflow="auto"
                    sx={{
                        /**
                         * widthIsAbove1600:- is used to detect whether the device width is more than large (>1200px) then we need to
                         * display the menu item with width 30% or else ince case of devices with lower width(<1200px) then menu item will be displayed
                         * on full screen. When on full screen mode or lower width mode, at that time we need to make the position as absolute so that we can apply
                         * z-index, to hide all the things behind the menu item
                         */
                        width: widthIsAbove1600 ? "30%" : "100%",
                    }}
                    data-testid="service-request-item-show">
                    <ForwardBackwardArrow
                        data-testid="backward-arrow"
                        sx={{
                            justifyContent: "flex-end",
                        }}
                        onClick={() => {
                            setShowSidePanelMenuItem((prev) => !prev);
                        }}>
                        <MaximizeOutlinedIcon
                            sx={{ transform: "rotate(90deg)", color: "blue.connectCare2" }}></MaximizeOutlinedIcon>
                        <ArrowBackOutlinedIcon
                            sx={{ color: "blue.connectCare2", marginLeft: -0.6 }}></ArrowBackOutlinedIcon>
                    </ForwardBackwardArrow>
                    <SrnMenuHeader
                        py={2}
                        pb={5}>
                        <Typography
                            variant="h1"
                            color={"font.darkBlue"}
                            paddingLeft={2}>
                            {translations.itemsNeedService}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={text.textdarkgray}
                            paddingLeft={2}>
                            {translations.subtitle}
                        </Typography>
                    </SrnMenuHeader>
                    <Grid container>
                        {requestServiceList.map((item, index) => {
                            return (
                                <Grid
                                    item
                                    p={1}
                                    container
                                    key={item.title}
                                    data-testid={item.title}>
                                    <SrnMenuItemSubGrid
                                        item
                                        xs={12}>
                                        <SrnMenuItemsIcon
                                            pt={2}
                                            py={1}>
                                            {item.icon}
                                        </SrnMenuItemsIcon>
                                        <SubtitleBox>
                                            <Typography
                                                variant="title"
                                                textTransform="uppercase">
                                                {item.title}
                                            </Typography>
                                            <Typography variant="caption">{item.subtitle}</Typography>
                                            <Typography variant="caption">{item.example}</Typography>
                                        </SubtitleBox>
                                        <AddButtonBox>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ pr: 3, maxWidth: "100px" }}
                                                onClick={item.onClick}
                                                disabled={isLoading || item.disable}
                                                data-testid={"btn_" + index}>
                                                {item.buttonText}
                                                {item.buttonIcon}
                                            </Button>
                                        </AddButtonBox>
                                    </SrnMenuItemSubGrid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Box
                        px={3}
                        pt={2}
                        pb={1}>
                        <Link
                            data-testid="serviceRequestsLink"
                            onClick={() => navigate("../../orders/intransit")}>
                            {translations.viewServiceRequests}
                        </Link>
                    </Box>
                </SrnMenuArrowButton>
            )}
            {!showSidePanelMenuItem && (
                <SrnMenuArrowButton
                    overflow="auto"
                    sx={{
                        width: hideSidePanel ? "8%" : "20%",
                    }}
                    data-testid="service-request-item-hide">
                    <ForwardBackwardArrow
                        data-testid="forward-arrow"
                        onClick={() => {
                            setShowSidePanelMenuItem((prev) => !prev);
                        }}>
                        <MaximizeOutlinedIcon
                            sx={{ transform: "rotate(90deg)", color: "blue.connectCare2" }}></MaximizeOutlinedIcon>
                        <ArrowForwardOutlinedIcon
                            sx={{ color: "blue.connectCare2", marginLeft: -0.6 }}></ArrowForwardOutlinedIcon>
                    </ForwardBackwardArrow>
                    <Typography variant="addItem">{translations.addItems}</Typography>
                </SrnMenuArrowButton>
            )}
        </>
    );
};
