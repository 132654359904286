import { useState } from "react";
/**
useLocalStorage is a hook that allows for syncing local state of a component to local storage
@param {string} key - sets the name of the local storage key
@param {any} defaultValue - sets the value of a local storage key
@returns {[any, function]} an array containing 2 elements, a value of which could be anything, and a function to set the value
*/
export const useLocalStorage = (key: string, defaultValue: any) => {
    const [localStorageValue, setLocalStorageValue] = useState(() => {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            } else {
                localStorage.setItem(key, JSON.stringify(defaultValue));
                return defaultValue;
            }
        } catch (error) {
            localStorage.setItem(key, JSON.stringify(defaultValue));
            return defaultValue
        }
    })
    const setLocalStorageStateValue = (valueOrFn: any) => {
        const newValue = (typeof valueOrFn === 'function') ? valueOrFn(localStorageValue) : valueOrFn;
        localStorage.setItem(key, JSON.stringify(newValue));
        setLocalStorageValue(newValue);
    }
    return [localStorageValue, setLocalStorageStateValue];
}