import { Search } from "@mui/icons-material";
import { Button, Grid, InputAdornment, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { setSearchString, setSelectedDateRange } from "../../redux/reducers/orders/ordersItemsSlice";

export default function DashboardSearch() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { searchString } = useSelector((state: StoreState) => state.orderItems);
    const { t } = useTranslation();
    const translations = {
        label: t("Dashboard Search"),
        placeholder: t("Search by SRN, Serial, Order, PO, Model Or Manufacturer"),
    };
    const navigate = useNavigate();
    const handleSearchSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        dispatch(setSelectedDateRange([null, null]));
        navigate("/assets/findmyitem");
    };

    return (
        <form onSubmit={handleSearchSubmit}>
            <Grid
                container
                spacing={2}
                alignItems={"center"}
                my={2}>
                <Grid
                    item
                    data-testid="dashboard-search"
                    xs={12}
                    md={6}
                    lg={5}
                    xl={4}>
                    <TextField
                        variant={"outlined"}
                        fullWidth
                        placeholder={translations.placeholder}
                        size="small"
                        id="dashboardSearch"
                        autoComplete="off"
                        value={searchString}
                        onChange={(e) => dispatch(setSearchString(e.target.value))}
                        inputProps={{
                            "data-testid": "dashboard-search-textfield",
                            "aria-label": `${translations.label}`,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}></TextField>
                </Grid>
                {!isMobile && (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={5}
                        xl={4}>
                        <Button
                            data-testid="dashboard-search-submit-btn"
                            aria-label={t("Find My Item")}
                            type="submit"
                            variant="contained">
                            {t("Find My Item")}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
}
