import { useContext } from "react";
import ConfirmedShipment from "../../components/serviceRequest/ConfirmedShipment";
import { SrnCreationContext } from "../../contexts/SrnCreationContext";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { BreadCrumbList } from "../../common/SiteMap";
import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { SrnStepper } from "../../component-library/SrnStepper";

export const CreateSrnConfirmedShipment = () => {
    const { singleFacility } = useContext(SrnCreationContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translations = {
        serviceRequest: t("Service Request"),
        viewServiceRequests: t("View Recent Service Requests"),
    };
    return (
        <>
            <BreadCrumb breadCrumbs={BreadCrumbList.newServiceRequest} />
            <Box
                sx={{ width: "100%" }}
                data-testid="confirmed-shipment">
                <Box
                    sx={{
                        height: "20vh",
                        bgcolor: "bg.lightBlue",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: 3,
                        flexGrow: 1,
                    }}>
                    <Box>
                        <Typography
                            variant="h2"
                            sx={{ color: "blue.connectCare2" }}>
                            {translations.serviceRequest}
                        </Typography>
                        {singleFacility?.name && (
                            <Typography variant="detailValueWithColor">
                                {singleFacility?.name} [{singleFacility?.number}]
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <SrnStepper active={3} />
                    </Box>
                </Box>
            </Box>
            <Box
                px={3}
                pt={2}
                pb={1}>
                <Link
                    data-testid="serviceRequestsLink"
                    onClick={() => navigate("../../orders/intransit")}>
                    {translations.viewServiceRequests}
                </Link>
            </Box>
            <Box sx={{ padding: 3 }}>
                <ConfirmedShipment />
            </Box>
        </>
    );
};
