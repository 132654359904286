import { Grid, LinearProgress, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { Asset } from "../../models/assets/Asset";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setGridColumns, setGridFilter, setGridSort } from "../../redux/reducers/assets/assetsSlice";
import { FormatDate } from "../../common/Formatters";
import { GridAreaLayout, StripedDataGrid } from "../../theme/stripedTable";
import { NoRowsOverlay } from "../../component-library/NoRowsOverlay";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { customSortComparators } from "../../utils/customSortComparators";
import { MaintenancePlanStatus } from "../../models/assets/MaintenancePlanStatus";

export interface FindMyItemAssetProps {
    loading?: boolean;
    noSearchDataText?: string;
    assetData: Asset[];
}

export const FindMyItemAssetsGrid = (props: Readonly<FindMyItemAssetProps>) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { gridColumns, gridFilter, gridSort } = useSelector((state: StoreState) => state.assets);
    const { t } = useTranslation();

    const translations = {
        id: t("Id"),
        serial: t("Serial"),
        category: t("Category"),
        description: t("Description"),
        manufacturer: t("Manufacturer"),
        maintenancePlan: t("Maintenance Plan"),
        model: t("Model"),
        lastService: t("Last Service"),
        nextService: t("Next Service"),
        apiError: t("System Error: API is not available at this time!"),
        title: t("Assets"),
        productLine: t("Product Line"),
        subProductLine: t("SubProduct Line"),
        customer: t("Customer"),
        department: t("Department"),
        speciality: t("Speciality"),
        search: t("Search"),
        addItem: t("Add Item"),
        noDataFound: t("No Rows"),
    };

    const columns: GridColDef[] = [
        {
            field: "serialNumber",
            minWidth: 120,
            headerName: translations.serial,
            renderHeader: () => translations.serial,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(
                            `/assets/${params.row.customerAccountId}/${params.row.inventoryItemId}/${encodeURIComponent(
                                params.row.serialNumber
                            )}`
                        );
                    }}>
                    {params.row.serialNumber}
                </Link>
            ),
            flex: 1,
            headerClassName: "padding",
        },
        {
            field: "plannerActive",
            renderHeader: () => translations.maintenancePlan,
            headerName: translations.maintenancePlan,
            valueFormatter: (params) => {
                return params?.value ? MaintenancePlanStatus.Active : MaintenancePlanStatus.Inactive;
            },
            minWidth: 200,
            flex: 1,
        },
        {
            field: "category",
            minWidth: 150,
            headerName: translations.category,
            renderHeader: () => translations.category,
            flex: 1,
        },
        {
            field: "description",
            headerName: translations.description,
            renderHeader: () => translations.description,
            minWidth: 170,
            flex: 1,
        },
        {
            field: "manufacturer",
            minWidth: 180,
            headerName: translations.manufacturer,
            renderHeader: () => translations.manufacturer,
            flex: 1,
        },
        {
            field: "model",
            headerName: translations.model,
            renderHeader: () => translations.model,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "productLine",
            headerName: translations.productLine,
            renderHeader: () => translations.productLine,
            minWidth: 170,
            hideable: true,
        },
        {
            field: "subProductLine",
            headerName: translations.subProductLine,
            renderHeader: () => translations.subProductLine,
            minWidth: 190,
            hideable: true,
        },
        {
            field: "customer",
            headerName: translations.customer,
            renderHeader: () => translations.customer,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "departmentDescription",
            minWidth: 160,
            headerName: translations.department,
            renderHeader: () => translations.department,
            flex: 1,
        },
        {
            field: "speciality",
            headerName: translations.speciality,
            renderHeader: () => translations.speciality,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "lastServiceDate",
            headerName: translations.lastService,
            minWidth: 160,
            sortable: true,
            renderHeader: () => translations.lastService,
            type: "date",
            valueGetter: ({ row }) => {
                return row.lastServiceDate ? new Date(FormatDate(row.lastServiceDate)) : row.lastServiceDate;
            },
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            flex: 1,
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
        {
            field: "nextServiceDate",
            type: "date",
            renderHeader: () => translations.nextService,
            headerName: translations.nextService,
            minWidth: 160,
            sortable: true,
            valueGetter: ({ row }) => {
                return row.nextServiceDate ? new Date(FormatDate(row.nextServiceDate)) : row.nextServiceDate;
            },
            valueFormatter: (params) => {
                return FormatDate(params?.value);
            },
            flex: 1,
            sortComparator: (v1, v2) => customSortComparators.sortByTime(v1, v2),
        },
    ];

    return (
        <Grid container>
            <Grid
                item
                xs>
                <GridAreaLayout data-testid="asset-list-grid-box">
                    <StripedDataGrid
                        disableRowSelectionOnClick
                        initialState={{
                            filter: {
                                filterModel: gridFilter,
                            },
                            sorting: {
                                sortModel: gridSort,
                            },
                        }}
                        density="compact"
                        rows={props.assetData}
                        columns={columns}
                        columnBuffer={13}
                        slots={{
                            toolbar: () => CustomToolbar(exportExcelFileName.assets),
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () =>
                                NoRowsOverlay({
                                    text: props.noSearchDataText ? props.noSearchDataText : translations.noDataFound,
                                }),
                        }}
                        slotProps={{
                            columnsPanel: { sx: { textTransform: "capitalize" } },
                        }}
                        loading={props.loading}
                        onFilterModelChange={(model) => dispatch(setGridFilter(model))}
                        onSortModelChange={(sortModel) => dispatch(setGridSort(sortModel))}
                        columnVisibilityModel={gridColumns}
                        onColumnVisibilityModelChange={(columnModel) => dispatch(setGridColumns(columnModel))}
                        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                    />
                </GridAreaLayout>
            </Grid>
        </Grid>
    );
};
