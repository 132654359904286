import { Box, Grid } from "@mui/material";
import { useMemo, useState } from "react";
import FacilitiesPane from "./FacilitiesPane";
import { FacilityClassCode } from "../../models/facility/FacilityClassCode";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../component-library/ConfirmationModal";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { setCurrentFacilityClass, setHasUnsavedChanges } from "../../redux/reducers/facility/facilitySlice";

export interface ITabbedFacilitiesPaneProps {
    closeFacilityPane: () => void;
}

export default function TabbedFacilitiesPane(props: ITabbedFacilitiesPaneProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { hasUnsavedChanges, selectedFacilityClass } = useSelector((state: StoreState) => state.facility);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const tabIds = { sterisServices: "STERIS Services", otherProviders: "Other Providers" };
    const translations = {
        SterisServices: t("STERIS Services"),
        OtherProviders: t("Other Providers"),
        confirmationModalTitle: t("Unsaved Changes"),
        confirmationModalContent: t("Do you want to keep editing or discard changes?"),
        confirmationModalDiscardChanges: t("Discard Changes"),
        confirmationModalKeepEditing: t("Keep Editing"),
    };

    const tabs: ITab[] = useMemo(
        () => [
            {
                tabId: tabIds.sterisServices,
                icon: <></>,
                children: <>{translations.SterisServices}</>,
            },
            {
                tabId: tabIds.otherProviders,
                icon: <></>,
                children: <Box sx={{ textTransform: "capitalize" }}>{translations.OtherProviders}</Box>,
            },
        ],
        [tabIds.otherProviders, tabIds.sterisServices, translations.OtherProviders, translations.SterisServices]
    );

    const [currentTab, setCurrentTab] = useState<ITab | undefined>(
        selectedFacilityClass === FacilityClassCode.DIRECT || selectedFacilityClass === FacilityClassCode.HOSPITALS
            ? tabs[0]
            : tabs[1]
    );

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        if (hasUnsavedChanges) {
            setShowConfirmModal(hasUnsavedChanges);
        } else {
            setCurrentTab(tabs.find((x) => x.tabId === newValue));
            setCurrentFacilityClass(
                currentTab?.tabId === tabIds.sterisServices ? FacilityClassCode.DIRECT : FacilityClassCode.INDIRECT
            );
        }
    };

    const handleDiscard = () => {
        setCurrentTab(tabs.find((x) => x.tabId !== currentTab?.tabId));
        setShowConfirmModal(false);
        dispatch(setHasUnsavedChanges(false));
    };

    const handleEdit = () => {
        setShowConfirmModal(false);
    };

    return (
        <>
            <Grid
                data-testid="tabbed-facilities-pane"
                item
                xs={12}>
                <Box
                    borderBottom={1}
                    borderColor="divider">
                    <ConnectCareTabs
                        currentTab={currentTab}
                        handleTabChange={handleTabChange}
                        tabs={tabs}
                        sx={{ pl: 1 }}
                    />
                </Box>
                <Box>
                    {currentTab?.tabId === tabIds.sterisServices && (
                        <FacilitiesPane
                            facilitiesClass={FacilityClassCode.DIRECT}
                            closeFacilityPane={props.closeFacilityPane}
                        />
                    )}
                    {currentTab?.tabId === tabIds.otherProviders && (
                        <FacilitiesPane
                            facilitiesClass={FacilityClassCode.INDIRECT}
                            closeFacilityPane={props.closeFacilityPane}
                        />
                    )}
                </Box>
            </Grid>

            {showConfirmModal && (
                <ConfirmationModal
                    show={showConfirmModal}
                    onSecondaryButtonClick={handleDiscard}
                    onPrimaryButtonClick={() => handleEdit()}
                    title={translations.confirmationModalTitle}
                    contentMessage={translations.confirmationModalContent}
                    secondaryButtonText={translations.confirmationModalDiscardChanges}
                    primaryButtonText={translations.confirmationModalKeepEditing}
                />
            )}
        </>
    );
}
