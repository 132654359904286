import { Box, Button, Grid, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccountAutocomplete from "./AccountAutocomplete";
import { useCallback, useEffect, useMemo, useState } from "react";
import DepartmentSelect from "./DepartmentSelect";
import { AccountSearchResponseDto } from "../../models/admin/AccountSearchResponseDto";
import { UserService } from "../../services/user/UserService";
import { ToastTypes } from "../../models/toast/ToastTypes";
import { AddCircleOutline, Info } from "@mui/icons-material";
import { DataGridPro, GridActionsCellItem, GridColDef } from "@mui/x-data-grid-pro";
import { PersonAccountResponse } from "../../models/admin/PersonAccountResponse";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { setToast } from "../../redux/reducers/toastSlice";
import ConfirmationModal from "../../component-library/ConfirmationModal";
import { CustomToolbar } from "../../common/GridCustomToolBar";
import { FacilityService } from "../../services/facility/FacilityService";
import { useNavigate } from "react-router-dom";
import { StyledFilterPanel } from "../../theme/stripedTable";
import { exportExcelFileName } from "../../common/ExportCSVFileName";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { User } from "../../models/admin/User";
import { setSelectedUser } from "../../redux/reducers/userSlice";

export default function AdminCustomerAccounts() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { selectedUser } = useAppSelector((state) => state.user);
    const { currentUser } = useAppSelector((state) => state.auth);
    const [detail, setDetail] = useState<AccountSearchResponseDto | undefined>(undefined);
    const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<number[]>([]);
    const [isAddCustomerMode, setIsAddCustomerMode] = useState<boolean>(false);
    const [rows, setRows] = useState<PersonAccountResponse[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [removePersonAccountId, setRemovePersonAccountId] = useState<number>(0);
    const [modalMessage, setModalMessage] = useState<string>("");
    const facilityService = useMemo(() => new FacilityService(), []);
    const userService = useMemo(() => new UserService(), []);
    const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
    const translations = {
        customerAccount: t("Customer"),
        custClass: t("Customer Class"),
        customer: t("Customer"),
        customerNumber: t("Customer Number"),
        status: t("status"),
        accountManager: t("Account Manager"),
        accountManagerAbbv: t("Account Mgr"),
        actions: t("actions"),
        department: t("Department"),
        addAccount: t("Add Account"),
        cancel: t("Cancel"),
        save: t("save"),
        gridMessage: t('The customer accounts listed below are what populate this User\'s "Facilities" list.'),
        confirmationModalTitle: t("Remove Customer Account"),
        confirmationModalContent: t("Are you sure you want to remove this Account from this User?"),
        confirmationModalCancel: t("No, Cancel"),
        confirmationModalRemove: t("Yes, Remove"),
        customerAccountsError: t("An error occurred when trying to get customer accounts."),
        customerAccountsErrorNotRemoved: t(
            "An error occurred when trying to remove the customer account. The Customer Account was not removed."
        ),
    };

    const getUserDetail = useCallback(
        async (personId: number) => {
            await userService
                .getUserDetail(personId)
                .then((data: User) => {
                    dispatch(setSelectedUser(data));
                })
                .catch(() => {
                    dispatch(
                        setToast({
                            toastMessage: t("There was error retrieving the User Detail."),
                            toastType: ToastTypes.Error,
                        })
                    );
                });
        },
        [dispatch, t, userService]
    );

    const selectedAccount = (data: AccountSearchResponseDto) => {
        setDetail(data);
    };

    const onDepartmentSelected = (departmentId: number[]) => {
        setSelectedDepartmentIds(departmentId);
    };

    const addAccount = async (detail: AccountSearchResponseDto, selectedDepartmentIds: number[]) => {
        if (selectedUser.personId === currentUser?.personId) {
            dispatch(
                setToast({
                    toastMessage: t("You cannot add a Customer Account to your own user account."),
                    toastType: ToastTypes.Error,
                })
            );
            return;
        }
        await userService
            .addCustomerAccount(selectedUser.personId, detail.id, selectedDepartmentIds)
            .then(() => {
                dispatch(
                    setToast({
                        toastMessage: t("The Customer Account was added successfully."),
                        toastType: ToastTypes.Success,
                    })
                );
                setDetail(undefined);
                setSelectedDepartmentIds([]);
                setIsAddCustomerMode(false);
                getUserDetail(selectedUser.personId);
                getCustomerAccountsOnLoad();
            })
            .catch(() => {
                dispatch(
                    setToast({
                        toastMessage: t(
                            "An error occurred when trying to add the customer account. The Customer Account was not added."
                        ),
                        toastType: ToastTypes.Error,
                    })
                );
            })
            .finally(() =>
                dispatch(
                    setToast({
                        showToast: true,
                    })
                )
            );
    };

    const removeCustomerAccount = async () => {
        if (selectedUser.personId === currentUser?.personId) {
            dispatch(
                setToast({
                    toastMessage: t("You cannot remove a Customer Account from your own user account."),
                    toastType: ToastTypes.Error,
                })
            );
            return;
        }
        await userService
            .removeCustomerAccount(selectedUser.personId, removePersonAccountId)
            .then(() => {
                dispatch(
                    setToast({
                        toastMessage: t("The Customer Account was removed successfully."),
                        toastType: ToastTypes.Success,
                    })
                );
                getUserDetail(selectedUser.personId);
                getCustomerAccountsOnLoad();
            })
            .catch(() => {
                dispatch(
                    setToast({
                        toastMessage: translations.customerAccountsErrorNotRemoved,
                        toastType: ToastTypes.Error,
                    })
                );
            })
            .finally(() => {
                setShowConfirmModal(false);
            });
    };

    /**
     * Edit action is clicked from the menu. It goes to the customer account screen with the ability to edit the department ddl.
     * @param customerNumber - The customer number.
     * @param personAccountId - The person account id.
     */
    const editCustomerAccount = (customerNumber: number, personAccountId: number) => {
   
        const searchTerm = customerNumber.toString();
        facilityService
            .searchFacilities(searchTerm, selectedUser.personId, personAccountId, true)
            .then((response: AccountSearchResponseDto[]) => {
                const detail = response.find((a) => a.number === customerNumber);
                setIsAddCustomerMode(true); //show the account selector ddl
                detail ? setDetail(detail) : setDetail(undefined);
                setSelectedDepartmentIds(detail ? detail.selectedDepartments.map((x) => Number(x.id)) : []);
            });
    };

    const startRemoveCustomerAccount = (personAccountId: number, customerName: string) => {
        if (currentUser?.personId === selectedUser.personId) {
            dispatch(
                setToast({
                    toastMessage: t("You cannot remove a Customer Account from your own user account."),
                    toastType: ToastTypes.Error,
                })
            );
            return;
        }
        setShowConfirmModal(true);
        setRemovePersonAccountId(personAccountId);
        setModalMessage(`${translations.confirmationModalContent}\n ${customerName}`);
    };

    const cancelRemoveCustomerAccount = () => {
        setShowConfirmModal(false);
        setRemovePersonAccountId(0);
    };

    const getCustomerAccountsOnLoad = useCallback(async () => {
        if (selectedUser?.personId) {
            await userService
                .getCustomerAccounts(selectedUser.personId)
                .then((response) => {                   
                    setRows(response);
                })
                .catch(() => {
                    dispatch(
                        setToast({
                            toastMessage: translations.customerAccountsError,
                            toastType: ToastTypes.Error,
                        })
                    );
                });
        }
    }, [dispatch, selectedUser.personId, translations.customerAccountsError, userService]);

    /**
     * Hides the customer number column in the column visibility panel
     * @param columns The grid columns.
     * @returns a list of toggleable columns.
     * @see {@link https://mui.com/x/react-data-grid/column-visibility/#customize-the-list-of-columns-in-panel}
     */
    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== "customerNumber").map((column) => column.field);
    };

    const columns: GridColDef[] = [
        {
            field: "customerNumber",
            headerName: translations.customerNumber,
            flex: 1,
        },
        {
            field: "customerName",
            headerName: translations.customer,
            flex: 0.8,
            renderCell: (params) => (
                <Link
                    onClick={() => {
                        navigate(`/admin/customer/${params.row.customerAccountId}`);
                    }}>
                    {params.row.customerName}
                </Link>
            ),
            hideable: false,
        },
        {
            field: "customerClass",
            headerName: translations.custClass,
            flex: 0.6,
        },
        {
            field: "customerStatus",
            headerName: translations.status,
            flex: 0.5,
        },
        {
            field: "accountManagerName",
            headerName: translations.accountManagerAbbv,
            flex: 0.7,
        },
        {
            field: "actions",
            type: "actions",
            headerName: translations.actions,
            flex: 0.5,
            getActions: (params) => [
                <GridActionsCellItem
                    key={params.id}
                    disabled={currentUser?.personId === selectedUser.personId}
                    data-testid="edit-customer-accounts"
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => editCustomerAccount(params.row.customerNumber, params.row.id)}
                />,
                <GridActionsCellItem
                    key={params.id}
                    disabled={currentUser?.personId === selectedUser.personId}
                    icon={<DeleteIcon />}
                    data-testid="delete-customer-accounts"
                    label="Delete"
                    onClick={() => startRemoveCustomerAccount(params.row.id, params.row.customerName)}
                />,
            ],
            hideable: false,
        },
    ];

    useEffect(() => {
        if (!initialDataLoaded) {
            setInitialDataLoaded(true);
            getCustomerAccountsOnLoad();
        }
    }, [getCustomerAccountsOnLoad, initialDataLoaded]);

    return (
        <>
            <Grid container>
                <Grid
                    item
                    xs>
                    <Grid
                        container
                        justifyContent={"space-between"}>
                        {!isAddCustomerMode && (
                            <>
                                <Grid
                                    item
                                    data-testid="add-account-mode"
                                    mb={1}
                                    xs={12}
                                    sm={6}>
                                    <Button
                                        disabled={selectedUser.personId === currentUser?.personId}
                                        data-testid="addAccountBtn"
                                        variant="contained"
                                        fullWidth
                                        size={"small"}
                                        onClick={() => setIsAddCustomerMode(true)}>
                                        <>
                                            <AddCircleOutline />
                                            {translations.addAccount}
                                        </>
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}>
                                    <Typography
                                        variant="body2"
                                        color={"bg.gray"}
                                        ml={1}
                                        align={"right"}>
                                        <Info fontSize={"small"} /> {translations.gridMessage}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <Box
                                        mt={1}
                                        p={0}
                                        height="calc(100vh - 450px)">
                                        <DataGridPro
                                            data-testid="customer-accounts-grid"
                                            rows={rows}
                                            columns={columns}
                                            columnBuffer={6}
                                            slots={{
                                                toolbar: () => CustomToolbar(exportExcelFileName.customerAccounts),
                                                filterPanel: StyledFilterPanel,
                                            }}
                                            slotProps={{
                                                columnsPanel: {
                                                    getTogglableColumns,
                                                    sx: {
                                                        textTransform: "capitalize",
                                                    },
                                                },
                                            }}
                                            density={"compact"}
                                        />
                                    </Box>
                                </Grid>
                            </>
                        )}
                        {isAddCustomerMode && (
                            <Grid
                                data-testid="add-customer-mode"
                                item
                                xs={12}
                                md={9}
                                lg={7}
                                xl={5}
                                mb={2}>
                                <Typography
                                    variant="h6"
                                    mb={1}
                                    color="blue.connectCare2">
                                    {translations.customer}
                                </Typography>
                                {detail ? (
                                    <Typography variant="body1">{`${detail?.number} - ${detail?.name}`}</Typography>
                                ) : (
                                    <AccountAutocomplete
                                        selectedAccount={selectedAccount}
                                        personId={selectedUser.personId}
                                    />
                                )}
                            </Grid>
                        )}
                        {detail && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    lg={2}
                                    xl={2}
                                    mb={2}>
                                    <Typography
                                        variant="h6"
                                        mb={1}>
                                        {translations.custClass}
                                    </Typography>
                                    <Typography variant="body1">{detail?.customerClassCodeOracle}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    mb={2}>
                                    <Typography
                                        variant="h6"
                                        mb={1}>
                                        {translations.accountManager}
                                    </Typography>
                                    <Typography variant="body1">{detail?.accountManagerName}</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                {detail?.departmentRequired && (
                    <Grid
                        item
                        mt={2}
                        xs={12}
                        md={6}
                        lg={4}>
                        <Grid container>
                            <Typography
                                variant="h6"
                                mb={1}
                                color="blue.connectCare2">
                                {translations.department}
                            </Typography>
                            <DepartmentSelect
                                selectedDepartments={selectedDepartmentIds}
                                onDepartmentSelected={onDepartmentSelected}
                                accountId={detail?.id}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid
                    item
                    mt={2}
                    xs={12}>
                    <Grid
                        container
                        justifyContent={"flex-end"}
                        columnSpacing={2}>
                        {detail && (
                            <>
                                <Grid item>
                                    <Button
                                        data-testid="cancel-changes"
                                        variant="Cancel"
                                        size={"small"}
                                        onClick={() => setDetail(undefined)}>
                                        {translations.cancel}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        data-testid="save-changes"
                                        variant="contained"
                                        color="primary"
                                        disabled={detail?.departmentRequired && selectedDepartmentIds.length === 0}
                                        size={"small"}
                                        onClick={async () => await addAccount(detail, selectedDepartmentIds)}>
                                        {translations.save}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {showConfirmModal && (
                <ConfirmationModal
                    show={showConfirmModal}
                    onSecondaryButtonClick={cancelRemoveCustomerAccount}
                    onPrimaryButtonClick={removeCustomerAccount}
                    title={translations.confirmationModalTitle}
                    contentMessage={modalMessage}
                    secondaryButtonText={translations.confirmationModalCancel}
                    primaryButtonText={translations.confirmationModalRemove}
                />
            )}
        </>
    );
}
