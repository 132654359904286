import { Box } from "@mui/material";
import { PastDueCellBox } from "../theme/theme";
import { useTranslation } from "react-i18next";

export interface IProps {
    remainingBalance?: string;
    daysPastDue: number;
}
/**
 * Displays PastDue info cell
 * @param props {@link IProps}
 */
export function PastDueCell(props: Readonly<IProps>) {
    const { t } = useTranslation();

    const translations = {
        pastDue: t("Past Due"),
    };
    return (
        <Box>
            {props.remainingBalance && parseFloat(props.remainingBalance) > 0 && props.daysPastDue > 0 && (
                <PastDueCellBox data-testid="past-due-cell">{translations.pastDue}</PastDueCellBox>
            )}
        </Box>
    );
}
