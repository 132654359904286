import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { pageKeysEducations } from "../../config/data";
import EducationList from "../../components/education/EducationList";
import EducationDetails from "../../components/education/EducationDetails";
import { useParams } from "react-router-dom";

const Education = () => {
    const [pageKey, setPageKey] = useState(pageKeysEducations.EducationList);

    const params = useParams<{ id?: string; groupId?: string }>();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
        if (params.id) {
            setPageKey(pageKeysEducations.EducationDetails);
        } else {
            setPageKey(pageKeysEducations.EducationList);
        }
    }, [params]);

    return (
        <Box
            data-testid={"education-container"}
            sx={{ backgroundColor: "bg.lightGray" }}
            width={`calc(100vw - ${matches ? "132px" : "18px"})`}>
            {!params.id && pageKey === pageKeysEducations.EducationList && <EducationList />}
            {params.id && pageKey === pageKeysEducations.EducationDetails && (
                <EducationDetails educationId={params.id} />
            )}
        </Box>
    );
};

export default Education;
