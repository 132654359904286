/**
  Display Text represent the text/status to be displayed on the screen
  Icon Color to be shown from theme.ts based on the condition/status
  showLink validates if the text needs to be displayed or not, if not only the icon is displayed
*/
export interface IRoundIconLookup {
    displayText: string;
    iconColor: string;
    showLink: boolean;
}

/**
Collection of Display text/status next to Round Icon
*/
export const displayTextList = {
    daysLate90: "90+ Days Late",
    daysLate60: "60+ Days Late",
    daysLate30: "30+ Days Late",
    noCustomerAdmin: "There are no Customer Admin Users for this Customer Account.",
    noSterisUser: "There are no STERIS Users for this Customer Account.",
    noCustomerAdminNorSterisUser:
        "There are no Customer Admin Users for this Customer Account. There are no STERIS Users for this Customer Account.",
    noUsers: "There are no Users for this Customer Account.",
    criticalPriority: "Critical Priority",
    immediateService: "Immediate Service",
    preventiveService: "Preventive Service",
    dueForService: "Due for Service",
    neverServiced: "Never Serviced",
    oK: "OK",
    invOnly: "INV ONLY",
    notApplicable: "N/A",
};

/**
Collection of round icon lookup list which is getting passed from component to 
show the Color icon and relavant text/status dynamically
*/
export const RoundIconLookupList: IRoundIconLookup[] = [
    { displayText: displayTextList.criticalPriority, iconColor: "font.materialRed", showLink: true },
    { displayText: displayTextList.daysLate90, iconColor: "font.materialRed", showLink: false },
    { displayText: displayTextList.noCustomerAdmin, iconColor: "font.materialRed", showLink: false },
    { displayText: displayTextList.noSterisUser, iconColor: "font.materialRed", showLink: false },
    { displayText: displayTextList.noUsers, iconColor: "font.materialRed", showLink: false },
    { displayText: displayTextList.daysLate60, iconColor: "font.materialOrange", showLink: false },
    { displayText: displayTextList.immediateService, iconColor: "font.materialYellow", showLink: true },
    { displayText: displayTextList.daysLate30, iconColor: "font.materialYellow", showLink: false },
    { displayText: displayTextList.preventiveService, iconColor: "blue.materialBlue", showLink: true },
    { displayText: displayTextList.dueForService, iconColor: "blue.materialBlue", showLink: true },
    { displayText: displayTextList.neverServiced, iconColor: "font.darkGray", showLink: false },
    { displayText: displayTextList.notApplicable, iconColor: "font.darkGray", showLink: false },
    { displayText: displayTextList.oK, iconColor: "font.materialGreen", showLink: true },
    { displayText: displayTextList.invOnly, iconColor: "font.materialGray", showLink: true },
    {
        displayText: displayTextList.noCustomerAdminNorSterisUser,
        iconColor: "font.materialRed",
        showLink: false,
    },
];
