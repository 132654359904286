import { Autocomplete, Box, Button, FormControl, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SerialAutocomplete from "./SerialAutocomplete";
import { AssetDto } from "../models/assets/AssetDto";
import { useCallback, useContext, useEffect, useState } from "react";
import { Facility } from "../models/facility/Facility";
import { ICustomerContact, IOracleDepartment, IServiceRequestItem } from "../models/serviceRequest/CreateSrnModels";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../redux/reducers/toastSlice";
import { ToastTypes } from "../models/toast/ToastTypes";
import { srnTypeKeys } from "../config/data";
import { Delete } from "@mui/icons-material";
import { text, widget } from "../theme/colors";
import { SrnCreationContext } from "../contexts/SrnCreationContext";
import { StoreState } from "../redux/store";
import { v4 as uuid } from "uuid";
import { SrnAddItemGrid } from "../theme/theme";
import { AssetHeaderDetail } from "../models/assets/AssetHeaderDetail";
export interface AddSerializedItemProps {
    selectedFacilities: Facility[];
    params: Readonly<
        Partial<{
            customerAccountId: string;
            serial: string;
            inventoryitemid: string;
            initialSrnType: string;
        }>
    >;
}

export const AddSerializedItem = ({ selectedFacilities, params }: AddSerializedItemProps) => {
    const { t } = useTranslation();
    const translations = {
        describeProblem: t("Describe Problem"),
        enterPoNumber: t("Enter PO Number"),
        department: t("Department"),
        contact: t("Contact"),
        problemMaxLength: t("Maximum 240 Characters."),
        maxLengthError: t("Maximum 50 characters."),
        contactRequired: t("Contact is required."),
        departmentRequired: t("Department is required."),
        serialNumber: t("Serial Number"),
        serializeItem: t("Serialized Item"),
        delete: t("Delete"),
        serialAlreadyExists: t("Item already exists in this Service Request."),
    };
    const dispatch = useDispatch();
    const {
        addSerializedItem,
        setAddSerializedItem,
        departmentList,
        setDepartmentList,
        contactList,
        setContactList,
        userContactId,
        setUserContactId,
        isLoading,
        setIsLoading,
        getAssetDetails,
        getContactId,
        getServiceRequestContacts,
        getServiceRequestDepartments,
        updateItem,
        removeItem,
        singleFacility,
    } = useContext(SrnCreationContext);
    const { currentUser } = useSelector((state: StoreState) => state.auth);
    const [departmentIsRequired, setDepartmentIsRequired] = useState<boolean>(true);
    /**
     * To reduce Cognitive Complexity of this page, we created a function that will take original value and default value as arguments
     */
    const getDefaultStringValue = (currentValue: string | null | undefined, defaultValue: string): string => {
        return currentValue ?? defaultValue;
    };

    /**
     * Set dept dropdown to the value that comes back from the asset detail call.
     * Also set contactId dropdown to the existing user.
     * @param index It's the index position for which we have to update the department and contact Id
     * @param asset It's the asset detail that will help us to update the department and contact Id with latest values
     */
    const syncDefaultSelections = useCallback(
        async (index: number, asset: AssetHeaderDetail) => {
            const department = departmentList?.find((d) => d.department === asset?.departmentDescription);
            if (department && setAddSerializedItem) {
                setAddSerializedItem((prev) => {
                    const updatedItems = [...prev];
                    if (updatedItems.length >= index + 1) {
                        updatedItems[index]!.custDeptId = department.custDeptId;
                        updatedItems[index]!.contactId = userContactId;
                    }
                    return updatedItems;
                });
            }
        },
        [departmentList, setAddSerializedItem, userContactId]
    );

    /**
     * Here we will initialize the Serialized item with default values or the values we are getting from the param assetDetails
     * @param index It's the index position for which we have to update the values for the serialized item
     * @param assetDetails It's the asset detail that will help us to update the initial values with latest values
     */
    const serializedItemInitialization = useCallback(
        async (assetDetails: AssetHeaderDetail, index: number) => {
            const initialItem: IServiceRequestItem = {
                id: "",
                manufacturer: getDefaultStringValue(assetDetails.manufacturer, ""),
                productType: getDefaultStringValue(assetDetails.productType, ""),
                model: getDefaultStringValue(assetDetails.model, ""),
                category: getDefaultStringValue(assetDetails.category, ""),
                categoryCode: getDefaultStringValue(assetDetails.categoryCode, ""),
                description:
                    assetDetails.category === "Instrument - Tray"
                        ? assetDetails.customerItemDescription
                        : `${getDefaultStringValue(assetDetails.manufacturer, "")} ${
                              assetDetails.productType
                          } ${getDefaultStringValue(assetDetails.model, "")}`,
                serial: getDefaultStringValue(assetDetails.serialNumber, ""),
                inventoryItemId: assetDetails.inventoryItemId,
                contactId: 0,
                custDeptId: assetDetails.departmentId ?? 0,
                describeProblem: "",
                isSummaryView: false,
                quantity: 1,
                poNumber: "",
                srnType: srnTypeKeys.Serialized,
                productLine: getDefaultStringValue(assetDetails.productLine, ""),
                subProductLine: "",
            };
            if (addSerializedItem.length > 0) {
                setAddSerializedItem((prev) => {
                    const updatedItems = [...prev];
                    if (updatedItems.length >= index + 1) {
                        initialItem.id = updatedItems[index]!.id;
                        updatedItems[index] = initialItem;
                    }
                    return updatedItems;
                });
            } else {
                initialItem.id = uuid();
                setAddSerializedItem([initialItem]);
            }
        },
        [setAddSerializedItem, addSerializedItem]
    );

    const loadDataSerialized = useCallback(
        async (serial: string, inventoryItemId: number, index: number) => {
            setIsLoading(true);
            const [assetDetails, departments, contacts, contactId] = await Promise.all([
                getAssetDetails(singleFacility?.custAccountId!, serial, inventoryItemId),
                getServiceRequestDepartments(singleFacility?.custAccountId!),
                getServiceRequestContacts(singleFacility?.custAccountId!),
                getContactId(singleFacility?.custAccountId!),
            ]);
            if (assetDetails) {
                serializedItemInitialization(assetDetails, index);
                setDepartmentList(departments ?? []);
                setContactList(contacts ?? []);
                setUserContactId(contactId ?? 0);
                setIsLoading(false);
            }

            if (assetDetails && departments) {
                await syncDefaultSelections(index, assetDetails);
            }
            setIsLoading(false);
        },
        [
            getAssetDetails,
            getServiceRequestContacts,
            getServiceRequestDepartments,
            getContactId,
            setIsLoading,
            syncDefaultSelections,
            setContactList,
            setDepartmentList,
            setUserContactId,
            singleFacility?.custAccountId,
            serializedItemInitialization,
        ]
    );

    /**
     * Only on page load, when Serial Number, Customer Acoount Id and Inventory Item Id is present in the URL as params, then only
     * I have to run this useEffect
     */
    useEffect(() => {
        if (params?.serial && params?.customerAccountId && params?.inventoryitemid) {
            let serializedItem = addSerializedItem.find(
                (x) => x.serial === params?.serial && x.inventoryItemId?.toString() === params?.inventoryitemid
            );
            if (!serializedItem) {
                loadDataSerialized(params?.serial, parseInt(params?.inventoryitemid ?? ""), 0);
            }
        }

        // only want to load this useEffect when url params get change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.serial && params?.customerAccountId && params?.inventoryitemid]);

    const getContactIdDropdownValue = (
        currentItem: IServiceRequestItem,
        index: number
    ): ICustomerContact | undefined => {
        if (currentItem.contactId) {
            return contactList?.find((x) => x.contactId === currentItem?.contactId);
        }

        let currentUserOption = contactList.find((x) => x.fullName === currentUser?.displayName);
        if (currentUserOption) {
            let updatedItem = { ...currentItem };
            updatedItem.contactId = currentUserOption.contactId;
            updateItem(updatedItem, index);
        }

        return currentUserOption;
    };

    const getDefaultDepartmentValue = (currentItem: IServiceRequestItem): IOracleDepartment | undefined => {
        return departmentList?.find((x) => x.custDeptId === currentItem?.custDeptId);
    };

    return (
        <>
            {!isLoading &&
                addSerializedItem?.map((item, index) => (
                    <Box
                        sx={{ margin: 3, border: `1px solid ${text["textgray2"]}` }}
                        key={item.id}>
                        <Box>
                            <Typography
                                variant="h4"
                                color={!item.serial ? widget.border : "initial"}
                                textTransform={"uppercase"}>
                                {item.serial ? item.description : translations.serializeItem}
                            </Typography>
                            {!!addSerializedItem.length && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                    }}>
                                    <Button
                                        aria-label={translations.delete}
                                        data-testid="removeButton"
                                        variant="text"
                                        size="small"
                                        onClick={() => removeItem(index, srnTypeKeys.Serialized)}>
                                        <Delete />
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                paddingLeft: 3,
                            }}>
                            {!item.serial && (
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        paddingRight: 2,
                                        paddingBottom: 3,
                                        flexDirection: "row",
                                        display: "flex",
                                    }}>
                                    <SerialAutocomplete
                                        onSelectItem={async (x: AssetDto) => {
                                            /**
                                             * Here we are checking whether user has alread selected that serial number previously
                                             * or not. If yes then we are not allowing user to select the same serial number again.
                                             */
                                            const validateSerialNumberExist = addSerializedItem?.find(
                                                (d) => d.serial === x.serialNumber
                                            );
                                            if (!validateSerialNumberExist) {
                                                item.serial = x.serialNumber!;
                                                item.inventoryItemId = x.inventoryItemId!;
                                                item.productLine = x.productLine;
                                                item.subProductLine = x.subProductLine;
                                                loadDataSerialized(item.serial, item.inventoryItemId, index);
                                            } else {
                                                dispatch(
                                                    setToast({
                                                        toastType: ToastTypes.Error,
                                                        toastMessage: translations.serialAlreadyExists,
                                                    })
                                                );
                                            }
                                        }}
                                        selectedFacilities={selectedFacilities}
                                        singleCustomerAccountId={singleFacility?.custAccountId}
                                    />
                                </Box>
                            )}
                            {item.serial && (
                                <Grid container>
                                    <SrnAddItemGrid
                                        item
                                        xs={12}
                                        xl={3}
                                        md={7}
                                        sm={12}
                                        lg={5}>
                                        <Typography variant="detailLabel">{translations.serialNumber}</Typography>
                                        <Typography variant="detailLabel">{item.serial}</Typography>
                                    </SrnAddItemGrid>

                                    <SrnAddItemGrid
                                        item
                                        xs={12}
                                        xl={3}
                                        md={5}
                                        sm={6}
                                        lg={3}
                                        sx={{
                                            flexGrow: 1,
                                            paddingRight: 2,
                                            paddingBottom: 3,
                                        }}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            error={item.custDeptId === 0}>
                                            <Autocomplete
                                                data-testid="department-dropdown-list"
                                                disablePortal
                                                size={"small"}
                                                options={departmentList}
                                                defaultValue={getDefaultDepartmentValue(item)}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.custDeptId === value.custDeptId
                                                }
                                                onInputChange={(_event, newValue) => {
                                                    if (newValue) {
                                                        let updatedItem = { ...item };
                                                        updatedItem.custDeptId =
                                                            getDefaultDepartmentValue(item)?.custDeptId ?? 0;
                                                        updateItem(updatedItem, index);
                                                    } else {
                                                        setDepartmentIsRequired(true);
                                                    }
                                                }}
                                                getOptionLabel={(option) => option.description}
                                                onChange={(_event, newValue) => {
                                                    if (newValue) {
                                                        let updatedItem = { ...item };
                                                        updatedItem.custDeptId = +newValue.custDeptId;
                                                        updateItem(updatedItem, index);
                                                        setDepartmentIsRequired(false);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={"Department"}
                                                    />
                                                )}
                                            />
                                            {departmentIsRequired && item?.custDeptId === 0 && (
                                                <FormHelperText>{translations.departmentRequired}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </SrnAddItemGrid>
                                    <SrnAddItemGrid
                                        item
                                        xs={12}
                                        xl={3}
                                        md={6}
                                        sm={6}
                                        lg={4}
                                        sx={{
                                            flexGrow: 1,
                                            paddingRight: 2,
                                            paddingBottom: 3,
                                        }}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            error={!getContactIdDropdownValue(item, index)}>
                                            <Autocomplete
                                                data-testid="contact-dropdown-list"
                                                disablePortal
                                                size={"small"}
                                                options={contactList}
                                                getOptionLabel={(option) => option.fullName}
                                                defaultValue={getContactIdDropdownValue(item, index)}
                                                onChange={(_event, newValue) => {
                                                    if (newValue) {
                                                        let updatedItem = { ...item };
                                                        updatedItem.contactId = +newValue.contactId;
                                                        updateItem(updatedItem, index);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={translations.contact}
                                                    />
                                                )}
                                            />
                                            {!getContactIdDropdownValue(item, index) && item?.contactId === 0 && (
                                                <FormHelperText>{translations.contactRequired}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </SrnAddItemGrid>
                                    <SrnAddItemGrid
                                        item
                                        xs={12}
                                        xl={3}
                                        md={6}
                                        sm={12}
                                        lg={12}
                                        sx={{
                                            flexGrow: 1,
                                            paddingRight: 2,
                                            paddingBottom: 3,
                                        }}>
                                        <FormControl
                                            fullWidth
                                            variant="outlined">
                                            <TextField
                                                inputProps={{ "data-testid": "poNumber-input" }}
                                                label={translations.enterPoNumber}
                                                size="small"
                                                error={item.poNumber.length > 50}
                                                helperText={
                                                    item.poNumber.length > 50 ? translations.maxLengthError : ""
                                                }
                                                value={item.poNumber}
                                                onChange={(e) => {
                                                    let updatedItem = { ...item };
                                                    updatedItem.poNumber = e.target.value;
                                                    updateItem(updatedItem, index);
                                                }}
                                            />
                                        </FormControl>
                                    </SrnAddItemGrid>
                                </Grid>
                            )}
                        </Box>
                        {item.serial && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    flexDirection: "row",
                                    paddingLeft: 3,
                                }}>
                                <Box sx={{ flexGrow: 1, paddingRight: 2, paddingBottom: 3 }}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined">
                                        <TextField
                                            inputProps={{
                                                "data-testid": "describe-problem-input",
                                                "aria-label": `${translations.describeProblem}`,
                                            }}
                                            variant="outlined"
                                            label={translations.describeProblem}
                                            size="small"
                                            multiline
                                            rows={2}
                                            error={item.describeProblem.length > 240}
                                            helperText={
                                                item.describeProblem.length > 240 ? translations.problemMaxLength : ""
                                            }
                                            onChange={(e) => {
                                                let updatedItem = { ...item };
                                                updatedItem.describeProblem = e.target.value;
                                                updateItem(updatedItem, index);
                                            }}
                                            value={item?.describeProblem}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        )}
                    </Box>
                ))}
        </>
    );
};
