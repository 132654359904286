import { Close } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'

type IProp = {
  title: string
  setSelectedActionMenuKey: any
}

function AppSideMenuTodo({ title, setSelectedActionMenuKey }: IProp) {
  return (
    <Grid container justifyContent="flex-end" sx={{ zIndex: 1, position: "absolute", mt: "85px" }}>
      <Box sx={{ px: 4, backgroundColor: "#F8F8F8", height: "104vh", width: "26rem", boxShadow: 3 }}>
        {/* *** Header and close x *** */}
        <Box sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ fontWeight: "bold" }}>
            {title}
          </Box>

          <Box sx={{ cursor: "pointer" }} onClick={() => setSelectedActionMenuKey(0)}  >
            <Close />
          </Box>

        </Box>

        {/* *** Request Services ***** */}


      </Box>
    </Grid>
  )
}

export default AppSideMenuTodo