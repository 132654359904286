export const customSortComparators = {
    /**
     * Sorts device events. Critical Priority is the highest.
     * @param v1 - The first item to compare.
     * @param v2 - The second item to compare.
     * @returns a list of items sorted by priority.
     */
    sortByDeviceEvent: (first: string, second: string) => {
        const sortSequence = ["Critical Priority", "Immediate Service", "Preventive Service", "OK", "N/A"];
        return sortSequence.indexOf(first) - sortSequence.indexOf(second);
    },

    /**
     * Converts params to date objects, and subtracts v1 time from v2 time.
     * @param v1 - The first item to compare.
     * @param v2 - The second item to compare.
     * @returns a number in milliseconds indicating if v1 < v2;
     */
    sortByTime: (v1: number | string | Date, v2: number | string | Date) =>
        new Date(v1).getTime() - new Date(v2).getTime(),

    /**
     * Converts a string to a number and subtracts those values.
     * @param v1 - The first item to compare.
     * @param v2 - The second item to compare.
     * @returns a number in indicating if v1 < v2;
     */
    sortByAlphanumerics: (v1: any, v2: any) =>
        Number(v1.toString().replace(/[^0-9.-]+/g, "")) - Number(v2.toString().replace(/[^0-9.-]+/g, "")),
};