import { Box, CircularProgress, Grid, Typography, Alert } from "@mui/material";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IOrder } from "../../models/orders/IOrder";
import { requestConnectCareOrders, requestConnectCarePurchaseOrdersRequired } from "../../services/apiPaths";
import { useFetch } from "../../services/useFetch";
import { OrderStatuses } from "../../models/orders/OrderStatuses";
import { FormatCurrency, FormatDate } from "../../common/Formatters";
import { FormatListBulletedRounded, WatchLaterRounded } from "@mui/icons-material";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import OrderDetailsPhotos from "./OrderDetailsPhotos";
import OrderHistoryDetails from "./OrderHistoryDetails";
import OrderDetailsGrid from "./OrderDetailsGrid";
import { validators } from "../../utils/validators";
import { ToastTypes } from "../../models/toast/ToastTypes";
import HoverTooltip from "../../component-library/HoverTooltip";
import { HoverTooltipText } from "../../component-library/HoverTooltipData";
import { GridBackgroundColor } from "../../theme/theme";
import { BreadCrumbList, PageTitles } from "../../common/SiteMap";
import { BreadCrumb } from "../../component-library/BreadCrumb";
import { UpsertPurchaseOrderNumberRequestDto } from "../../models/orders/UpsertPurchaseOrderNumberRequestDto";
import { PoUpdateLevel } from "../../models/orders/PoUpdateLevel";
import { ConnectCareActionButton, IActionMenuItems } from "../../component-library/ConnectCareActionButton";
import { EditPoDialog } from "./EditPoDialog";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../redux/reducers/toastSlice";
import { TabLoader } from "../../component-library/TabLoader";
import { ConnectCareTabs, ITab } from "../../component-library/ConnectCareTabs";
import { claimTypes } from "../../config/claimTypes";
import { AuthLibrary } from "../../redux/actions/AuthRedux";
import { StoreState } from "../../redux/store";

function OrderDetails() {
    const { t } = useTranslation();
    const { get } = useFetch();
    const navigate = useNavigate();
    const { post } = useFetch();
    const location = useLocation();
    const { id } = useParams<{ id?: string; groupId?: string }>();
    const [isHeaderDetailLoading, setIsHeaderDetailLoading] = useState<boolean>(false);
    const [isPhotosLoading, setIsPhotosLoading] = useState<boolean>(false);
    const [orderDetail, setOrderDetail] = useState<IOrder | undefined>(undefined);
    const [isPhotosCountApiError, setIsPhotosCountApiError] = useState(false);
    const [photosCount, setPhotosCount] = useState<number>(0);
    const [isPoNumberSaving, setIsPoNumberSaving] = useState<boolean>(false);
    const [openPoEnterDialog, setOpenPoEnterDialog] = useState<boolean>(false);
    const { hasIndirectAccounts } = useSelector((state: StoreState) => state.facility);
    const dispatch = useDispatch();
    const [hidePhotosTab, setHidePhotosTab] = useState<boolean>(false);
    const tabIds = { items: "Items", tracking: "Tracking", photos: "Photos" };

    const translations = {
        documentTitle: `${t(PageTitles.sterisTitle)} - ${t(PageTitles.orderDetails)}`,
        ordersPageName: t("Orders"),
        orderDetailsPageName: t("Order Details"),
        errorLoadingOrder: t("There was a problem loading the order"),
        poFailure: t("Purchase Order saving failed."),
        poSuccess: t("Purchase Order successfully saved."),
        poTextFieldLabel: t("Enter PO"),
        poTextFieldEditLabel: t("Edit PO"),
        items: t("Items"),
        tracking: t("Tracking"),
        photos: t("Photos"),
    };

    const tabs: ITab[] = [
        {
            tabId: tabIds.items,
            icon: <FormatListBulletedRounded />,
            children: <>{translations.items}</>,
        },
        {
            tabId: tabIds.tracking,
            icon: <WatchLaterRounded />,
            children: <>{translations.tracking}</>,
        },
        {
            tabId: tabIds.photos,
            icon: <PhotoLibraryIcon />,
            children: (
                <TabLoader
                    isLoading={isPhotosLoading}
                    labelText={translations.photos}
                    isApiError={isPhotosCountApiError}
                    count={photosCount}
                />
            ),
            hide: hidePhotosTab,
        },
    ];

    /**
     * Below state is to set up the initial tab to show while landing into Order Details page
     * if we're redirecting from any other page to this order details page,
     * location.state.tab will decide which tab to show,
     * if location.state.tab doesn't have any value on it, the default configured tab will get loaded
     */
    const [currentTab, setCurrentTab] = useState<ITab | undefined>(
        tabs.find((x) => x.tabId?.toUpperCase() === location.state?.tab?.toUpperCase()) ?? tabs[0]
    );

    const getOrdersDetail = useCallback(async () => {
        setIsPhotosLoading(true);
        setIsHeaderDetailLoading(true);
        const uriOrder = `${requestConnectCareOrders.OrderSearch}/${id}`;
        const response = await get<IOrder>(uriOrder, true);
        if (response) {
            setOrderDetail(response);
        }
        setIsHeaderDetailLoading(false);
    }, [id, get]);

    const getOrderDetailsPhotosCount = useCallback(async () => {
        setIsPhotosLoading(true);
        setIsPhotosCountApiError(false);
        const uri = `${
            requestConnectCareOrders.OrderDetailPhotosCount
        }?headerId=${orderDetail?.headerId}&srn=${encodeURIComponent(orderDetail?.srn ?? "")}`;
        const response = await get<number>(uri, true);
        const result = response as number;
        if (result >= 0) {
            setPhotosCount(result);
        } else {
            setIsPhotosCountApiError(true);
        }
        setIsPhotosLoading(false);
    }, [orderDetail?.headerId, orderDetail?.srn, get]);

    useEffect(() => {
        document.title = translations.documentTitle;
        getOrdersDetail();
    }, [translations.documentTitle, getOrdersDetail]);

    const hasAccessToPhotosTab = useCallback(
        (claimType: string) => {
            return AuthLibrary.HasAccountSubscriptionAccessToClaim(
                claimType,
                parseInt(orderDetail?.custAccountId ?? "0")
            );
        },
        [orderDetail?.custAccountId]
    );

    useEffect(() => {
        if (orderDetail) {
            const isSrnStartsWithV = orderDetail?.srn.startsWith("V");
            const photosAccess = hasAccessToPhotosTab(
                isSrnStartsWithV ? claimTypes.ViewOrderPhotosOnLocation : claimTypes.ViewOrderPhotosLab
            );

            if (photosAccess) {
                setHidePhotosTab(false);
                getOrderDetailsPhotosCount();
            } else {
                setHidePhotosTab(true);
            }
        }
    }, [getOrderDetailsPhotosCount, hasAccessToPhotosTab, orderDetail]);

    const orderStatus = useMemo((): string => {
        if (!orderDetail) return "";
        switch (orderDetail.orderStatus) {
            case OrderStatuses.Closed:
            case OrderStatuses.Invoiced:
            case OrderStatuses.InvoiceHold:
                return t("CLOSED");
            case OrderStatuses.Cancelled:
                return t(OrderStatuses.Cancelled);
            case OrderStatuses.Booked:
            case OrderStatuses.Entered:
            case OrderStatuses.Approved:
            case OrderStatuses.Rejected:
                return t("OPEN");
            default:
                return "";
        }
    }, [orderDetail, t]);

    const orderStatusColor = useMemo((): string => {
        switch (orderDetail?.orderStatus) {
            case OrderStatuses.Booked:
            case OrderStatuses.Entered:
            case OrderStatuses.Rejected:
            case OrderStatuses.Approved:
                return "font.materialGreen";
            default:
                return "bg.gray";
        }
    }, [orderDetail?.orderStatus]);

    const orderStandRepairType = useMemo((): boolean => {
        return (
            orderDetail?.orderTypeName === "STANDARD REPAIR - CAPITATION" &&
            orderDetail?.orderStatus !== OrderStatuses.Cancelled
        );
    }, [orderDetail?.orderTypeName, orderDetail?.orderStatus]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(tabs.find((x) => x.tabId === newValue));
    };

    const handlePoSave = (poNumber: string) => {
        savePoNumber(poNumber);
    };

    const savePoNumber = useCallback(
        async (poNumber: string) => {
            setIsPoNumberSaving(true);

            const requestData: UpsertPurchaseOrderNumberRequestDto = {
                OrderHeaderId: Number(orderDetail?.headerId),
                UpdateLevel: PoUpdateLevel.HEADER,
                PurchaseOrderNumber: poNumber,
                ShouldOverwriteNonMatchingHeader: true,
            };

            let result = await post(
                requestConnectCarePurchaseOrdersRequired.UpsertPurchaseOrderNumber,
                requestData,
                true,
                () => {
                    dispatch(
                        setToast({
                            toastMessage: translations.poFailure,
                            toastType: ToastTypes.Error,
                        })
                    );

                    setIsPoNumberSaving(false);
                }
            );

            if ((result as Response).ok) {
                dispatch(
                    setToast({
                        toastMessage: translations.poSuccess,
                        toastType: ToastTypes.Success,
                    })
                );

                setOpenPoEnterDialog(false);
                setIsPoNumberSaving(false);
                getOrdersDetail();
            }
        },
        [dispatch, getOrdersDetail, orderDetail?.headerId, post, translations.poFailure, translations.poSuccess]
    );

    const menuItems: IActionMenuItems[] = [
        {
            name: orderDetail?.customerPo !== null ? translations.poTextFieldEditLabel : translations.poTextFieldLabel,
            onClick: () => {
                setOpenPoEnterDialog(true);
            },
            hide:
                !orderDetail?.canEnterPoNumber ||
                orderDetail?.orderStatus === OrderStatuses.Closed ||
                hasIndirectAccounts ||
                !AuthLibrary.hasAnyClaim([claimTypes.EnterOrEditPo]),
        },
        {
            name: "View service request",
            onClick: () => {
                navigate(`/orders/srn/${orderDetail?.srn}`);
            },
            disable: !validators.srnLink(orderDetail?.srn ?? ""),
        },
    ];

    /**
     * Formats the customer po.
     * @param customerPo The customer po number.
     * @returns "-" if null or empty, otherwise decodeURIComponent(customerPo)
     */
    const formatCustomerPo = (customerPo: string) => {
        return customerPo ? decodeURIComponent(customerPo) : "-";
    };

    const orderHeaderDetail = (
        <Grid
            paddingLeft={4}
            paddingRight={4}>
            {isHeaderDetailLoading && (
                <Box
                    textAlign={"center"}
                    mt={3}>
                    <CircularProgress />
                </Box>
            )}

            {!isHeaderDetailLoading && (
                <Grid
                    container
                    mb={2}>
                    {!!orderDetail && (
                        <Grid
                            container
                            display="flex"
                            justifyContent="between">
                            <Grid
                                item
                                flex={1}>
                                <Typography
                                    variant="body2"
                                    color="font.gray"
                                    marginBottom={2}>
                                    {orderDetail.customerName} [{orderDetail.accountNumber}] - {orderDetail.department}
                                </Typography>
                                <Typography
                                    variant="h1"
                                    color="font.darkBlue"
                                    marginBottom={1}>
                                    {`${t("Order")} #${orderDetail.orderNumber}`}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="font.darkBlue">
                                    {t("Ordered")}: {FormatDate(orderDetail.orderDate)}
                                </Typography>
                                {!!orderDetail.salesOrderReference && (
                                    <Typography
                                        variant="body2"
                                        color="font.darkBlue">
                                        {t("Reference")}: {orderDetail.salesOrderReference}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                display="flex">
                                <Grid
                                    container
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-end"
                                    gap="4px 0px">
                                    <Grid
                                        item
                                        bgcolor={orderStatusColor}
                                        textAlign="center"
                                        paddingInline={5}
                                        color="font.white"
                                        mb={1}>
                                        {orderStatus}
                                    </Grid>
                                    <Grid
                                        container
                                        display="flex"
                                        justifyContent="space-between"
                                        gap="0px 15px">
                                        <Grid
                                            item
                                            display="flex"
                                            alignItems="center"
                                            color={"font.claret"}>
                                            {!orderDetail.isIndirectFiltered && orderStandRepairType && (
                                                <HoverTooltip
                                                    tooltip={HoverTooltipText.OrderItemsStandardRepair}
                                                    placement="bottom"
                                                    margin="6px"
                                                />
                                            )}
                                            <Typography
                                                variant="body2"
                                                color="font.gray">
                                                {t("ORDER TOTAL")}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            display="flex">
                                            <Typography
                                                variant="h1"
                                                color="font.darkBlue">
                                                {FormatCurrency(
                                                    orderDetail.orderTotal,
                                                    orderDetail.transactionalCurrCode
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        display="flex"
                                        justifyContent="space-between"
                                        gap="0px 15px">
                                        <Grid
                                            item
                                            display="flex"
                                            alignItems="center">
                                            <Typography
                                                variant="body2"
                                                color="font.gray">
                                                {t("PO NUMBER")}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            display="flex">
                                            <Typography
                                                variant="h1"
                                                color="font.darkBlue">
                                                {formatCustomerPo(orderDetail.customerPo)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        display="flex"
                                        mt={2}>
                                        <ConnectCareActionButton menuItems={menuItems} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {!orderDetail && <Alert severity="error">{t("There was a problem loading that order.")}</Alert>}
                </Grid>
            )}
        </Grid>
    );

    const orderDetailTabs = (
        <Grid>
            <ConnectCareTabs
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                tabs={tabs}
            />
            {!!orderDetail && (
                <>
                    {currentTab?.tabId === tabIds.items && (
                        <OrderDetailsGrid
                            transactionalCurrCode={orderDetail.transactionalCurrCode}
                            orderLines={orderDetail.orderLines}
                            custAccountId={orderDetail.custAccountId}
                            headerId={orderDetail.headerId}
                            srn={orderDetail.srn}
                        />
                    )}
                    {currentTab?.tabId === tabIds.tracking && (
                        <OrderHistoryDetails headerId={orderDetail.headerId}></OrderHistoryDetails>
                    )}
                    {currentTab?.tabId === tabIds.photos && !hidePhotosTab && (
                        <OrderDetailsPhotos
                            headerId={orderDetail.headerId}
                            srn={orderDetail.srn}
                        />
                    )}
                </>
            )}
            {!isHeaderDetailLoading && !orderDetail && <Alert severity="error">{translations.errorLoadingOrder}</Alert>}
        </Grid>
    );

    return (
        <Box
            style={GridBackgroundColor}
            padding={2}
            overflow={"auto"}>
            <BreadCrumb breadCrumbs={BreadCrumbList.orderDetails} />
            <Box
                data-testid="orders-details-tabs-page"
                bgcolor="bg.light"
                border="1px solid #DFE0EB"
                borderRadius={2}
                paddingTop={"12px"}
                marginTop="20px"
                overflow={"hidden"}>
                {orderHeaderDetail}
                {orderDetailTabs}
                <EditPoDialog
                    open={openPoEnterDialog}
                    onCancel={() => setOpenPoEnterDialog(false)}
                    onSubmit={(poNumber) => {
                        handlePoSave(poNumber);
                    }}
                    progressIndicator={isPoNumberSaving}
                    editPoNumber={orderDetail?.customerPo!}
                />
            </Box>
        </Box>
    );
}
export default OrderDetails;
