export interface DeviceMaintenanceRecords {
    upToDateCount: number,
    dueForInspectionCount: number,
    overDueForInspectionCount: number,
    neverInspectedCount: number
}

/**
* This allows for the creation of the charts on the dashboard without any null values
*/
export const defaultDeviceMaintenanceRecordState: DeviceMaintenanceRecords = {
    upToDateCount: 0,
    dueForInspectionCount: 0,
    overDueForInspectionCount: 0,
    neverInspectedCount: 0,
};